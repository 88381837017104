import { gql } from '@apollo/client';

export const GET_SCOPING_SESSION_BY_ID_QUERY = gql`
  query GetScopingSessionById($getScopingSessionByIdId: ID!) {
    getScopingSessionById(id: $getScopingSessionByIdId) {
      createdBy
      createdOn
      customerId
      id
      name
      notes
      projectItemId
      updatedBy
      updatedOn
      section {
        name
        order
        questions {
          createdBy
          createdOn
          scopeQuestionId
          value
        }
      }
    }
  }
`;

import { useMutation, useQuery } from '@apollo/client';
import { ProjectStatusHistory } from '@cdw-selline/common/types';
import {
  GET_OPPORTUNITIES_QUERY,
  GET_PROJECT_QUERY,
  GET_PROJECT_STATUS_QUERY,
  UPDATE_PROJECT_STATUS_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';

import { useApolloErrors } from './useApolloErrors';
import { useNavigate } from 'react-router-dom';

export interface UseProjectStatus {
  id?: string;
  locationToRoute?: string
}

export const useProjectStatus = ({ id, locationToRoute }: UseProjectStatus) => {
  const alertState = useAlertsState();
  const { handleErrorResponse } = useApolloErrors();
  const navigate = useNavigate();

  const { loading, error, data, refetch } = useQuery<{
    getProjectStatus: ProjectStatusHistory[];
  }>(GET_PROJECT_STATUS_QUERY, {
    skip: !id,
    variables: { getProjectStatusId: id },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Project Status!'),
  });

  const [
    updateProjectStatus,
    { loading: updateProjectStatusLoading, error: updateProjectStatusError },
  ] = useMutation(UPDATE_PROJECT_STATUS_MUTATION, {
    refetchQueries: [
      GET_OPPORTUNITIES_QUERY,
      GET_PROJECT_QUERY,
      GET_PROJECT_STATUS_QUERY,
    ],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to update project status'),
    onCompleted: (data) => {
      if (data.updateProjectStatus.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully Updated Project Status',
        });
      }
    },
  });

  const [
    deleteOpportunity,
    { loading: deleteOpportunityLoading, error: deleteOpportunityError },
  ] = useMutation(UPDATE_PROJECT_STATUS_MUTATION, {
    refetchQueries: [
      GET_OPPORTUNITIES_QUERY,
      GET_PROJECT_QUERY,
      GET_PROJECT_STATUS_QUERY,
    ],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to delete opportunity'),
    onCompleted: (data) => {
      if (data.updateProjectStatus.success) {
        navigate(locationToRoute);
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully deleted the opportunity',
        });
      }
    },
  });

  return {
    data: data?.getProjectStatus ?? null,
    error,
    loading,
    updateProjectStatusLoading,
    updateProjectStatusError,
    updateProjectStatus,
    deleteOpportunity,
    refetch,
  };
};

export default useProjectStatus;

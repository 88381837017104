import { useProjectStatus } from '@cdw-selline/ui/hooks';
import { Typography } from '@mui/material';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import MoneyIcon from '@mui/icons-material/Money';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SellIcon from '@mui/icons-material/Sell';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { capitalCase } from 'text-case';

export interface ProjectPageHistoryProps {
  drawerState?: boolean;
}

export const ProjectPageHistory = (props: ProjectPageHistoryProps) => {
  const { id } = useParams<{ id: string }>();
  const { data, loading, error, refetch } = useProjectStatus({ id });

  if (props.drawerState) refetch();
  if (error) return <>Error Loading Status History</>;
  if (loading) return <>Loading...</>;
  if (!data) return null;

  const getIcon = (status: string) => {
    const iconMap = {
      projectStarted: <PlayArrowIcon />,
      scope: <LaptopMacIcon />,
      peerReview: <PeopleAltIcon />,
      bidAssurance: <SellIcon />,
      won: <MoneyIcon />,
      lost: <MoodBadIcon />,
      didNotPurchase: <CancelPresentationIcon />,
    };
    return iconMap[status] || <LaptopMacIcon />;
  };

  return (
    <Timeline position="alternate">
      {Array.isArray(data) &&
        data.map((d, idx) => {
          return idx % 2 !== 0 ? (
            <TimelineItem key={`project-history-${idx}`}>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                variant="body2"
                color="text.secondary"
              >
                {new Date(d.timestamp).toLocaleString()}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="secondary">{getIcon(d.status)}</TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                  {capitalCase(d.status)}
                </Typography>
                <Typography>{d.lastModifiedByUsername}</Typography>
              </TimelineContent>
            </TimelineItem>
          ) : (
            <TimelineItem key={`project-history-${idx}`}>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                variant="body2"
                color="text.secondary"
              >
                <Typography variant="h6" component="span">
                  {capitalCase(d.status)}
                </Typography>
                <Typography>{d.lastModifiedByUsername}</Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary">{getIcon(d.status)}</TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                {new Date(d.timestamp).toLocaleString()}
              </TimelineContent>
            </TimelineItem>
          );
        })}
    </Timeline>
  );
};

import { gql } from '@apollo/client';

export const ADD_PROJECT_TASK_GROUP_MUTATION = gql`
  mutation Mutation(
    $addProjectItemEstimatorTasksByTaskGroupTaskGroupId: String!
    $addProjectItemEstimatorTasksByTaskGroupProjectId: String!
    $addProjectItemEstimatorTasksByTaskGroupSite: String!
    $addProjectItemEstimatorTasksByTaskGroupaddress: String
    $addProjectItemEstimatorTasksByTaskGroupfloor: String
  ) {
    addProjectItemEstimatorTasksByTaskGroup(
      taskGroupId: $addProjectItemEstimatorTasksByTaskGroupTaskGroupId
      projectItemId: $addProjectItemEstimatorTasksByTaskGroupProjectId
      site: $addProjectItemEstimatorTasksByTaskGroupSite
      address: $addProjectItemEstimatorTasksByTaskGroupaddress
      floor: $addProjectItemEstimatorTasksByTaskGroupfloor
    ) {
      projectItemId
    }
  }
`;

import { gql } from '@apollo/client';

export const UPDATE_CISCO_ANNUITY_CONTACT_MUTATION = gql`
  mutation updateCiscoAnnuityContactById(
    $ciscoAnnuityId: String!
    $ciscoAnnuityContactId: String!
    $ciscoAnnuityContact: CiscoAnnuityContactInput!
  ) {
    updateCiscoAnnuityContactById(
      ciscoAnnuityId: $ciscoAnnuityId
      ciscoAnnuityContactId: $ciscoAnnuityContactId
      ciscoAnnuityContact: $ciscoAnnuityContact
    ) {
      success
    }
  }
`;

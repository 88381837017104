import { gql } from '@apollo/client';

export const ADD_PROJECT_CATEGORY_TASK_MUTATION = gql`
  mutation Mutation(
    $addTasksByCategoryCategoryId: String!
    $addTasksByCategoryProjectId: String!
    $addTasksByCategorySite: String!
    $addProjectItemEstimatorTasksByTaskGroupaddress: String
    $addProjectItemEstimatorTasksByTaskGroupfloor: String
  ) {
    addTasksByCategory(
      category: $addTasksByCategoryCategoryId
      projectItemId: $addTasksByCategoryProjectId
      site: $addTasksByCategorySite
      address: $addProjectItemEstimatorTasksByTaskGroupaddress
      floor: $aaddProjectItemEstimatorTasksByTaskGroupfloor
    ) {
      projectItemId
    }
  }
`;

import { ApolloError } from '@apollo/client';
import { pruneEmpty } from '@cdw-selline/ui/common';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  useOpenState,
  useProjectItems,
  useProjectItemsTable,
  useProjectItemEstimatorAddItem,
  useAddProjectItemQuote,
  useCiscoAnnuityAddItem,
} from '@cdw-selline/ui/hooks';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ROUTES } from '@cdw-selline/ui/constants';
import { useLocation } from 'react-router-dom';
import omitDeep from 'omit-deep-lodash';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CollectionsDataTable } from '../..';
import {
  GridRowModel,
  GridColDef,
} from '@mui/x-data-grid';
import { Link, Checkbox, Tooltip } from '@mui/material';
import NewProjectItemForm from './NewProjectItemForm';
import { formatCurrency } from '@cdw-selline/ui/helpers';
import { ProjectItem } from '@cdw-selline/common/types';

export interface ProjectItemsTableProps {
  loading?: boolean;
  error?: ApolloError;
  isReadOnly?: boolean;
}

export function ProjectItemsTable(props: ProjectItemsTableProps) {
  const {
    rows,
    loading,
    error,
    handleAddPrjectItem,
    handleRemoveProjectItem,
    vendors,
    solutions,
  } = useProjectItemsTable();
  const {
    isOpen: newProjectItemModalOpen,
    handleClose: handleProjectItemModalClose,
    handleOpen: handleProjectItemModalOpen,
  } = useOpenState();
  const { createProjectItem, updateProjectItem } = useProjectItems();
  const { addProjectItemEstimator } = useProjectItemEstimatorAddItem();
  const { handleAddProjectItemQuote } = useAddProjectItemQuote();
  const { handleAddCiscoAnnuityItem } = useCiscoAnnuityAddItem();
  const { id } = useParams<{ id: string }>();
  const [ProjectItemFormData, setProjectItemFormData] = useState({});
  const handleProjectItemSubmit = async (formData) => {
    if (formData.id) {
      handleRowEditSave(omitDeep(formData, ['servicesTotal']));
    } else {
      const projectItem = await createProjectItem({
        variables: { params: { ...formData, projectId: id } },
      });
      if (formData.type === 'Estimator') {
        addProjectItemEstimator({
          variables: {
            params: {
              projectItemId: projectItem.data.addProjectItem.id,
            },
          },
        });
      }
      if (formData.type === 'Quote') {
        handleAddProjectItemQuote({
          projectItemId: projectItem.data.addProjectItem.id,
        });
      }
      if (formData.type === 'Cisco Annuity') {
        handleAddCiscoAnnuityItem({
          projectItemId: projectItem.data.addProjectItem.id,
        });
      }
    }
    handleProjectItemModalClose();
  };

  const handleRowEditSave = (formData) => {
    updateProjectItem({
      variables: {
        params: omitDeep(pruneEmpty(formData), [
          'total',
          '__typename',
          'costTotal',
          'priceTotal',
          'hwsw',
        ]),
      },
    });
  };
  const navigate = useNavigate();
  const location = useLocation();
  const handleSowUpdate = (e) => {
    updateProjectItem({
      variables: {
        params: {
          includeInSow: e.target.checked,
          includeInProjectTotal: e.target.checked,
          id: e.target.parentNode.dataset.id,
        },
      },
    });
  };

  const handleClick = async (item: GridRowModel<ProjectItem>) => {
    switch (item.type.toLowerCase()) {
      case 'estimator':
        navigate(
          {
            pathname: `${ROUTES.PROJECT}/${id}/estimator/${item.id}`,
          },
          { state: { from: location.state?.from ?? ROUTES.HOME } }
        );
        break;
      case 'cisco annuity':
        navigate(
          {
            pathname: `${ROUTES.PROJECT}/${id}/ciscoAnnuity/${item.id}`,
          },
          { state: { from: location.state?.from ?? ROUTES.HOME } }
        );
        break;
      case 'quote':
        navigate(
          {
            pathname: `${ROUTES.PROJECT}/${id}/quote/${item.id}`,
          },
          { state: { from: location.state?.from ?? ROUTES.HOME } }
        );
        break;
      case 'subscription':
        handleProjectItemModalOpen();
        setProjectItemFormData(item);
        break;
      case 'services':
      case 'product':
        handleProjectItemModalOpen();
        setProjectItemFormData(item);
        break;
      default:
        console.log('Not Yet Implemented');
        break;
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'includeInProjectTotal',
      headerName: '',
      width: 80,
      renderCell: (params) => (
        <Tooltip enterDelay={300} title="Include in Project Total?">
          <Checkbox
            data-id={params.id}
            defaultChecked={params.value ? true : false}
            onChange={handleSowUpdate}
          ></Checkbox>
        </Tooltip>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 180,
      flex: 1,
      editable: true,
      renderCell: (params) => (
        <Link
          underline="always"
          style={{ cursor: 'pointer' }}
          data-testid={`project-item-link-${params.value}`}
          onClick={() => {
            handleClick(params.row);
          }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 220,
    },
    {
      field: 'vendor',
      headerName: 'Vendor',
      width: 200,
      flex: 1,
      editable: false,
    },
    {
      field: 'priceTotal',
      headerName: 'Total',
      width: 220,
      flex: 1,
      valueFormatter: (value) => {
        return `${formatCurrency(value) ?? 0.0}`;
      },
      type: 'number',
    },
  ];

  return (
    <div>
      <CollectionsDataTable
        columns={columns}
        rows={rows}
        handleAdd={handleProjectItemModalOpen}
        handleDelete={handleRemoveProjectItem}
        handleSave={handleProjectItemSubmit}
        editMode="row"
        loading={loading}
        error={error}
        gridMargin={0}
        parent="ProjectItemTable"
        saveOnEditStop={true}
        allowFilter={false}
        isReadOnly={props.isReadOnly}
      />
      <NewProjectItemForm
        isOpen={newProjectItemModalOpen}
        handleClose={handleProjectItemModalClose}
        handleSubmit={handleProjectItemSubmit}
        projectId={id}
        ProjectItemFormData={ProjectItemFormData}
        setProjectItemFormData={setProjectItemFormData}
        vendors={vendors}
        solutions={solutions}
      />
    </div>
  );
}

export default ProjectItemsTable;

import {
  GlobalQuoteSettingsFields,
  ProjectItemQuote,
} from '@cdw-selline/common/types';
import omitDeep from 'omit-deep-lodash';
import { useUpdateProjectItemQuote } from '..';

export const useGlobalSettingsTable = (quoteData: ProjectItemQuote) => {
  const { handleUpdateProjectItemQuote, loading, error } =
    useUpdateProjectItemQuote();
  const { TAX_SETTINGS } = GlobalQuoteSettingsFields;

  const handleGlobalSettingsChange = (
    setting: GlobalQuoteSettingsFields,
    rowName: string,
    newValue: string
  ) => {
    const handleUpdateQuoteSettings = (subProp: string) => {
      const getModifiedQuote = () => {
        const mutatingQuote = omitDeep(quoteData, '__typename');
        if (!mutatingQuote[setting]) {
          if (setting === TAX_SETTINGS) {
            mutatingQuote[setting] = { shippingTaxRate: '', adderTaxRate: '' };
          } else {
            mutatingQuote[setting] = { margin: '', markup: '', discount: '' };
          }
        }
        mutatingQuote[setting][subProp] = newValue;
        return mutatingQuote;
      };
      const modifiedQuote = getModifiedQuote();
      handleUpdateProjectItemQuote(modifiedQuote);
    };

    switch (rowName) {
      case 'Shipping Tax %':
        handleUpdateQuoteSettings('shippingTaxRate');
        break;
      case 'Adder Tax %':
        handleUpdateQuoteSettings('adderTaxRate');
        break;
      case 'Margin %':
        handleUpdateQuoteSettings('margin');
        break;
      case 'Markup %':
        handleUpdateQuoteSettings('markup');
        break;
      case 'Discount %':
        handleUpdateQuoteSettings('discount');
        break;
      default:
        console.log(`Unexpected row name: ${rowName} - no updates made`);
        break;
    }
  };

  return { handleGlobalSettingsChange, loading, error };
};

import { useMutation, useQuery } from '@apollo/client';
import { DefaultMutationResponse, TaskGroup } from '@cdw-selline/common/types';
import { ROUTES } from '@cdw-selline/ui/constants';
import {
  GET_TASK_GROUPS_QUERY,
  GET_TASK_GROUP_BY_ID_QUERY,
  UPDATE_TASK_GROUP_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useApolloErrors } from './useApolloErrors';

export const useAdminTaskGroupDetail = (id) => {
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();
  const navigate = useNavigate();
  const {
    loading: currentTaskGroupLoading,
    error: currentTaskGroupError,
    data: currentTaskGroup,
  } = useQuery<{ getTaskGroupById: TaskGroup }>(GET_TASK_GROUP_BY_ID_QUERY, {
    fetchPolicy: 'no-cache',
    skip: !id,
    variables: { id: id },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch task group!'),
  });

  const [
    updateTaskGroup,
    { loading: updateTaskGroupLoading, error: updateTaskGroupError },
  ] = useMutation<{ updateTaskGroup: DefaultMutationResponse }>(
    UPDATE_TASK_GROUP_MUTATION,
    {
      refetchQueries: [GET_TASK_GROUPS_QUERY],
      awaitRefetchQueries: true,
      onError: (error) =>
        handleErrorResponse(error, 'Failed to save new task group'),
      onCompleted: (data) => {
        if (data.updateTaskGroup.success) {
          navigate(ROUTES.ADMIN_TASK_GROUPS);
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully saved task group',
          });
        }
      },
    }
  );

  const [state, setState] = useState(
    currentTaskGroup?.getTaskGroupById ?? null
  );

  const handleEditorChange = (params) => {
    setState((s) => ({
      ...s,
      ...params,
    }));
  };

  const handleSubmit = (e) => {
    updateTaskGroup({
      variables: {
        params: { ...state, id },
      },
    });
  };

  return {
    currentTaskGroupLoading,
    currentTaskGroupError,
    currentTaskGroup: { ...(currentTaskGroup?.getTaskGroupById ?? {}) },
    updateTaskGroup,
    updateTaskGroupLoading,
    updateTaskGroupError,
    handleSubmit,
    handleEditorChange,
    state,
  };
};

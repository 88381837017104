import { gql } from '@apollo/client';

export const ADD_CISCO_ANNUITY_PART_NUMBER_MUTATION = gql`
  mutation addCiscoAnnuityPartNumber($params: CiscoAnnuityPartNumberInput!) {
    addCiscoAnnuityPartNumber(params: $params) {
      success
      id
    }
  }
`;

import { useMutation } from '@apollo/client';
import {
  TaskGroup,
  DefaultAddResponse,
  DefaultMutationResponse,
} from '@cdw-selline/common/types';
import {
  ADD_TASK_GROUP_MUTATION,
  GET_TASK_GROUPS_QUERY,
  GET_TASK_GROUP_BY_ID_QUERY,
  REMOVE_ADMIN_TASK_GROUP_MUTATION,
  UPDATE_TASK_GROUP_MUTATION,
  DUPLICATE_TASK_GROUP_MUTATION,
  CREATE_TEST_VERSION_TASK_GROUP_MUTATION,
  REMOVE_TASK_GROUP_TEST_VERSION_MUTATION,
  APPROVE_TASK_GROUP_TEST_VERSION_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import {
  GridColDef,
  GridPaginationModel,
  GridFilterModel,
} from '@mui/x-data-grid';
import { useState } from 'react';
import { useApolloErrors } from './useApolloErrors';
import { useTaskGroups } from './useTaskGroups';
import omitDeep from 'omit-deep-lodash';
import { useOpenState } from './estimator/useOpenState';
import {
  getFilters,
  getFilterModelFromLocalStorage,
} from '@cdw-selline/ui/helpers';
import moment from 'moment';
import { PAGES } from '@cdw-selline/ui/constants';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Task Group ID',
    width: 200,
    editable: false,
    flex: 1,
  },
  { field: 'name', headerName: 'Task Group Name', width: 250, flex: 1 },
  { field: 'practice', headerName: 'Practice ID', width: 200, flex: 1 },
  { field: 'published', headerName: 'Published', width: 180, flex: 1 },
  {
    field: 'createdAt',
    headerName: 'Created Date',
    type: 'dateTime',
    width: 180,
    editable: false,
    valueGetter: (value) => value && !isNaN(value) && new Date(Number(value)),
    valueFormatter: (value) =>
      value ? moment(value).format('MM/DD/YYYY') : '',
  },
  { field: 'createdBy', headerName: 'Created By', width: 180, flex: 1 },
];
export const useAdminTaskGroups = () => {
  const [sortState, setSortState] = useState<any>();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  const filterModel: GridFilterModel = getFilterModelFromLocalStorage(`${PAGES.TASK_GROUPS_COLLECTION}-filter`);

  const { data, loading, error, refetch } = useTaskGroups({
    filters: getFilters(PAGES.TASK_GROUPS_COLLECTION),
    offset: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize,
    sort: sortState,
  });
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();

  const [
    addTaskGroup,
    { loading: addTaskGroupLoading, error: addTaskGroupError },
  ] = useMutation<{ addTaskGroup: DefaultAddResponse }>(
    ADD_TASK_GROUP_MUTATION,
    {
      refetchQueries: [GET_TASK_GROUPS_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to create new task group'),
      onCompleted: (data) => {
        if (data.addTaskGroup.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully created Task Group',
          });
        }
      },
    }
  );

  const [
    removeTaskGroup,
    { loading: removeTaskGroupLoading, error: removeTaskGroupError },
  ] = useMutation<{ removeTaskGroup: DefaultMutationResponse }>(
    REMOVE_ADMIN_TASK_GROUP_MUTATION,
    {
      refetchQueries: [GET_TASK_GROUPS_QUERY, GET_TASK_GROUP_BY_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to remove task group'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.removeTaskGroup.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully removed Task Group',
          });
        }
      },
    }
  );

  const [
    removeTaskGroupTestVersion,
    {
      loading: removeTaskGroupTestVersionLoading,
      error: removeTaskGroupTestVersionError,
    },
  ] = useMutation<{ removeTaskGroupTestVersion: DefaultMutationResponse }>(
    REMOVE_TASK_GROUP_TEST_VERSION_MUTATION,
    {
      refetchQueries: [GET_TASK_GROUPS_QUERY, GET_TASK_GROUP_BY_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to remove task group test version'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.removeTaskGroupTestVersion.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully removed Task Group Test Version',
          });
        }
      },
    }
  );

  const [
    approveTaskGroupTestVersion,
    {
      loading: approveTaskGroupTestVersionLoading,
      error: approveTaskGroupTestVersionError,
    },
  ] = useMutation<{ approveTaskGroupTestVersion: DefaultMutationResponse }>(
    APPROVE_TASK_GROUP_TEST_VERSION_MUTATION,
    {
      refetchQueries: [GET_TASK_GROUPS_QUERY, GET_TASK_GROUP_BY_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to approve task group test version'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.approveTaskGroupTestVersion.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully approved Task Group Test Version',
          });
        }
      },
    }
  );

  const [
    updateTaskGroup,
    { loading: updateTaskGroupLoading, error: updateTaskGroupError },
  ] = useMutation<{ updateTaskGroup: DefaultMutationResponse }>(
    UPDATE_TASK_GROUP_MUTATION,
    {
      refetchQueries: [GET_TASK_GROUPS_QUERY],
      awaitRefetchQueries: true,
      onError: (error) =>
        handleErrorResponse(error, 'Failed to save new task group'),
      onCompleted: (data) => {
        if (data.updateTaskGroup.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully saved task group',
          });
        }
      },
    }
  );

  const [
    duplicateTaskGroup,
    { loading: duplicateTaskGroupLoading, error: duplicateTaskGroupError },
  ] = useMutation<{ duplicateTaskGroup: DefaultMutationResponse }>(
    DUPLICATE_TASK_GROUP_MUTATION,
    {
      refetchQueries: [GET_TASK_GROUPS_QUERY],
      awaitRefetchQueries: true,
      onError: (error) =>
        handleErrorResponse(error, 'Failed to duplicate task group'),
      onCompleted: (data) => {
        if (data.duplicateTaskGroup.success) {
          alertState.setAlert({
            type: ALERT_TYPE.MODAL,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully duplicated task group',
          });
        }
      },
    }
  );

  const [
    createTestVersionByTaskGroup,
    { loading: testVersionTaskGroupLoading, error: testVersionTaskGroupError },
  ] = useMutation<{ createTestVersionByTaskGroup: DefaultMutationResponse }>(
    CREATE_TEST_VERSION_TASK_GROUP_MUTATION,
    {
      refetchQueries: [GET_TASK_GROUPS_QUERY],
      awaitRefetchQueries: true,
      onError: (error) =>
        handleErrorResponse(error, 'Failed to create test version task group'),
      onCompleted: (data) => {
        if (data.createTestVersionByTaskGroup.success) {
          alertState.setAlert({
            type: ALERT_TYPE.MODAL,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully created test version task group',
          });
        }
      },
    }
  );

  const handleDelete = (id: string) =>
    removeTaskGroup({
      variables: { id: id },
    });

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    const pageSize = model?.pageSize ?? paginationModel?.pageSize;
    const page = model?.page ?? paginationModel?.page;
    setPaginationModel({
      page,
      pageSize,
    });
  };

  const onFilterModelChange = (filterModel) => {
    refetch({ filters: getFilters(PAGES.TASK_GROUPS_COLLECTION) });
  };

  const [editTaskGroupId, setEditTaskGroupId] = useState(null);

  const {
    isOpen: taskGroupFormOpen,
    handleClose: closeTaskGroupForm,
    handleOpen: openTaskGroupForm,
  } = useOpenState();

  const handleAdd = () => {
    openTaskGroupForm();
  };

  const handleTaskGroupFormClose = () => {
    setEditTaskGroupId(null);
    closeTaskGroupForm();
  };

  const handleEdit = (id: string) => {
    setEditTaskGroupId(id);
    openTaskGroupForm();
  };

  const handleTaskGroupFormSave = (taskGroup: TaskGroup) => {
    if (!taskGroup.id) {
      addTaskGroup({
        variables: {
          params: omitDeep(taskGroup, ['__typename', 'createdAt', 'version']),
        },
      });
    }
    if (taskGroup.id) {
      updateTaskGroup({
        variables: {
          params: omitDeep(taskGroup, ['__typename', 'createdAt', 'version']),
        },
      });
    }
  };

  const handleTaskGroupFormDuplicate = (
    taskGroup: TaskGroup,
    includeAllTasks: boolean
  ) => {
    duplicateTaskGroup({
      variables: {
        taskGroup: omitDeep(taskGroup, ['__typename', 'createdAt', 'version']),
        includeAllTasks,
      },
    });
  };

  const handleTaskGroupFormTestVersion = (taskGroupId: string) => {
    createTestVersionByTaskGroup({
      variables: {
        taskGroupId,
      },
    });
  };

  const handleTaskGroupFormDeleteTestVersion = (id: string) =>
    removeTaskGroupTestVersion({
      variables: { id },
    });

  const handleTaskGroupFormApproveTestVersion = (id: string) =>
    approveTaskGroupTestVersion({
      variables: { id },
    });

  const handleSort = (sortModel) => {
    let newSort;
    if (sortModel.length) newSort = { [sortModel[0].field]: sortModel[0].sort };
    setSortState(newSort);
  };

  const returnValue = {
    columns,
    rows: data.taskGroups || [],
    handleAdd,
    handleDelete,
    handleEdit,
    handlePaginationModelChange,
    onFilterModelChange,
    handleSort,
    filterModel: filterModel,
    taskgroups: data.taskGroups,
    addTaskGroupLoading,
    addTaskGroupError,
    removeTaskGroupLoading,
    removeTaskGroupError,
    loading: loading || addTaskGroupLoading || removeTaskGroupLoading,
    error,
    rowCount: data.count,
    taskGroupFormOpen,
    handleTaskGroupFormClose,
    handleTaskGroupFormSave,
    handleTaskGroupFormDuplicate,
    handleTaskGroupFormTestVersion,
    handleTaskGroupFormDeleteTestVersion,
    handleTaskGroupFormApproveTestVersion,
    editTaskGroupId,
  };

  if (loading || addTaskGroupLoading || removeTaskGroupLoading) {
    return {
      ...returnValue,
      paginationModel: { pageSize: 100, page: 0 },
      rowCount: 0,
      rows: [],
    };
  } else {
    return { ...returnValue, paginationModel };
  }
};

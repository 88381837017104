import { FormText, FormCheckbox, FormAutocomplete, FormAutocompleteOption, FormTextControlled } from '../formHelperFunctions';

import React, { Dispatch, SetStateAction } from 'react';

export interface TertiaryRoleOverrideIncludeProps {
  setFormData: Dispatch<SetStateAction<Record<string, unknown>>>;
  formData: Record<string, string>;
  isCustom: boolean;
  roleOptions: FormAutocompleteOption[];
  setTaskFormData: Dispatch<SetStateAction<Record<string, unknown>>>;
  taskFormData: Record<string, string>;
}

export function TertiaryRoleOverrideInclude({
  setFormData,
  formData,
  isCustom,
  roleOptions,
  setTaskFormData,
  taskFormData

}: TertiaryRoleOverrideIncludeProps) {
  return (
    <>
      <FormAutocomplete
        name="tertiaryRole"
        label="Tertiary Role"
        options={roleOptions}
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      <FormText
        name="tertiaryPercent"
        label="Tertiary Hours %"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      <FormTextControlled
        name="tertiaryRoleRateOverride"
        label="Tertiary Role Rate Override"
        type="number"
        setFormData={setFormData}
        formData={formData}
      />
      {!isCustom && (
        <FormText
          name="tertiaryRoleRateOverrideFormula"
          label="Tertiary Role Rate Override Formula"
          setFormData={setFormData}
          formData={formData}
        />
      )}
      {!isCustom && (
        <FormCheckbox
          name="allowModifyTertiaryRoleRate"
          label={'Allow Modify Tertiary Role Rate'}
          setFormData={setFormData}
          formData={formData}
        />
      )}
    </>
  );
}

export default TertiaryRoleOverrideInclude;

import { gql } from '@apollo/client';

export const GET_SOW_SECTION_BY_ID_AND_VERSION_QUERY = gql`
  query getSowSectionByIdAndVersionQuery($id: ID!, $version: Int!) {
    getSowSectionByIdAndVersion(id: $id, version: $version) {
      id
      name
      help
      highLevel
      scope
      customerResponsibilities
      customerResponsibilitiesGlobal
      assumptions
      assumptionsGlobal
      outOfScope
      outOfScopeGlobal
      version
      title
      owner {
        email
      }
      coOwners {
        email
      }
      testers {
        email
      }
      status
      originalSowSectionId
      inTestingSowSectionId
    }
  }
`;
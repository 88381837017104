import { ApolloError } from '@apollo/client';
import { cloneDeep } from 'lodash';
import { VolumePricing } from '@cdw-selline/common/types';

import TableTemplate from '../tables/handson-table/TableTemplate';
import { useOpenState, useHandsonTable } from '@cdw-selline/ui/hooks';
import {
  Button,
  CircularProgress,
  Stack,
  Typography,
  Box,
} from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import DialogConfirm from '../dialog-confirm/DialogConfirm';

export interface VolumePricingTableProps {
  volumePricingData: VolumePricing[];
  loading?: boolean;
  error?: ApolloError;
  setFormData: Dispatch<SetStateAction<Record<string, unknown>>>;
  formData: Record<string, string>;
}

export function VolumePricingTable({
  volumePricingData,
  loading,
  error,
  setFormData,
  formData,
}: VolumePricingTableProps) {
  const columnFields = {
    1: 'minQuantity',
    2: 'maxQuantity',
    3: 'cost',
  };

  const columnTitles = ['Min Quantity', 'Max Quantity', 'Cost'];

  const volumePricingDataCopy = cloneDeep(volumePricingData);

  const {
    handleRemoveSelectedRows,
    handleLineChange,
    handleAddEmptyLine,
    getColumns,
    tableRows,
    setSelections,
    tableRef,
  } = useHandsonTable(
    volumePricingDataCopy,
    columnFields,
    setFormData,
    formData,
    'volumePricing',
    true
  );

  const { isOpen, handleClose, handleOpen } = useOpenState();
  const handleConfirmRemoveRows = () => {
    handleRemoveSelectedRows();
    handleClose();
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography>Error Fetching Volume Pricing</Typography>;
  return (
    <Stack spacing={1} sx={{ width: '100%' }}>
      <Box>
        <Button
          variant="contained"
          onClick={handleAddEmptyLine}
          data-testid="add-volume-pricing-line"
          sx={{ margin: '2px' }}
        >
          Add Volume Pricing Line
        </Button>
        <Button
          variant="contained"
          onClick={handleOpen}
          data-testid="remove-volume-pricing-line"
          sx={{ margin: '2px' }}
        >
          Remove Selected Rows
        </Button>
      </Box>
      <DialogConfirm
        {...{
          isOpen,
          handleClose,
          title: 'Remove Rows',
          handleYes: handleConfirmRemoveRows,
        }}
      >
        <Typography>Remove Selected Rows?</Typography>
      </DialogConfirm>
      <Box sx={{ width: '100%', overflow: 'hidden', height: '300px' }}>
        <TableTemplate
          {...{
            tableData: tableRows,
            tableRef: tableRef,
            handleCellDataChange: handleLineChange,
            handleCurrentSelection: setSelections,
            customSettings: {
              colHeaders: columnTitles,
              columns: getColumns(columnTitles),
              rowHeaders: true,
              hiddenColumns: {},
              selectionMode: 'multiple',
              outsideClickDeselects: false,
              width: '100%',
              stretchH: 'all',
            },
          }}
        />
      </Box>
    </Stack>
  );
}

export default VolumePricingTable;

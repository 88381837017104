import { DialogActions, Button } from '@mui/material';
import React from 'react';
import DialogModal from '../dialog-modal/DialogModal';

export interface DialogConfirmProps {
  children?: JSX.Element | JSX.Element[];
  isOpen: boolean;
  handleClose: () => void;
  handleYes: (event) => void;
  title: string;
}

export function DialogConfirm({
  children,
  isOpen,
  handleClose,
  handleYes,
  title,
}: DialogConfirmProps) {
  return (
    <DialogModal {...{ isOpen, handleClose, title }}>
      <>
        {children}
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            data-testid="no-confirm-button"
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={handleYes}
            data-testid="yes-confirm-button"
          >
            Yes
          </Button>
        </DialogActions>
      </>
    </DialogModal>
  );
}

export default DialogConfirm;

import { CircularProgress, Grid, Typography } from '@mui/material';
import React from 'react';
import { useNotFoundPage } from './useNotFoundPage';

export const NotFoundPage = () => {
  useNotFoundPage();
  const pageTitle = '404 Page Not Found';

  return (
    <Grid
      item
      xs={12}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      >
      <Grid item xs={12} sx={{ display: 'flex', marginTop: '1em' }}>
        <Typography data-test-id="appTitle" variant="h1">
          {pageTitle}
        </Typography>
      </Grid>
      <Grid item sx={{ display: 'flex' }} xs={12}>
        <CircularProgress />
        Redirecting to login in 5 seconds...
      </Grid>
    </Grid>
  );
};

export default NotFoundPage;

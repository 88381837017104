import { gql } from '@apollo/client';

export const ADD_CISCO_SMART_ACCOUNT_MUTATION = gql`
  mutation AddCiscoSmartAccountMutation(
    $ciscoSmartAccount: CiscoSmartAccountInput
    $customerId: String
  ) {
    addCiscoSmartAccount(
      ciscoSmartAccount: $ciscoSmartAccount
      customerId: $customerId
    ) {
      success
    }
  }
`;

import { useQuery } from '@apollo/client';
import { GetAgiloftTravelNoticeTypesResponse } from '@cdw-selline/common/types';
import { GET_AGILOFT_TRAVEL_NOTICE_TYPES_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from '../useApolloErrors';

export interface UseAgiloftTravelNoticeTypes {
  filters?: any;
  offset?: number;
  limit?: number;
  sort?: unknown;
}

export const useAgiloftTravelNoticeTypes = ({
  filters,
  offset,
  limit,
  sort,
}: UseAgiloftTravelNoticeTypes) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getAgiloftTravelNoticeTypes: GetAgiloftTravelNoticeTypesResponse;
  }>(GET_AGILOFT_TRAVEL_NOTICE_TYPES_QUERY, {
    variables: {
      offset: offset ?? 0,
      limit: limit ?? 1000,
      filters: filters ?? {},
      sort: sort ?? { updatedAt: -1 },
    },
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Agiloft Travel Notice Types!'),
  });

  return {
    data: data?.getAgiloftTravelNoticeTypes ?? {
      agiloftTravelNoticeTypes: [],
      count: 0,
    },
    error,
    loading,
    refetch,
  };
};

export const useAgiloftTravelNoticeTypeById = (id: string) => {
  const { handleErrorResponse } = useApolloErrors();

  const { loading, error, data, refetch } = useQuery(
    GET_AGILOFT_TRAVEL_NOTICE_TYPES_QUERY,
    {
      variables: {
        filters: { id },
      },
      skip: !id,
      fetchPolicy: 'cache-and-network',
      onError: (error) =>
        handleErrorResponse(error, 'Failed to fetch Agiloft Expense Type!'),
    }
  );

  return {
    data: data?.getAgiloftTravelNoticeTypes.agiloftTravelNoticeTypes[0] ?? {},
    loading,
    error,
    refetch,
  };
};

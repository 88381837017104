import { gql } from '@apollo/client';

export const GET_SITE_BREAKDOWN_QUERY = gql`
  query getSiteBreakdownQuery($id: ID!) {
    getSiteBreakdownByProjectId(projectId: $id) {
      site
      taskHours
      pmHours
      totalHours
      servicesTotal
      thirdPartyServices
      total
    }
  }
`;

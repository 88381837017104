import { useQuery } from '@apollo/client';
import { GetTaskGroupsResponse } from '@cdw-selline/common/types';
import { GET_TASK_GROUPS_WITH_CATS_QUERY } from '@cdw-selline/ui/queries';
import { UseTaskGroups } from '..';

import { useApolloErrors } from '../useApolloErrors';

export const useTaskGroupsWithCategories = (params?: UseTaskGroups) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getTaskGroups: GetTaskGroupsResponse;
  }>(GET_TASK_GROUPS_WITH_CATS_QUERY, {
    variables: params ?? {},
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Task Groups!'),
  });

  return {
    data: data?.getTaskGroups ?? { taskGroups: [], count: 0 },
    error,
    loading,
    refetch,
  };
};

export default useTaskGroupsWithCategories;

// eslint-disable-next-line @nx/enforce-module-boundaries
import { useUserAccess } from '@cdw-selline/ui/hooks';
import { Grid } from '@mui/material';
import * as React from 'react';
import { CollectionsDataTable } from '../..';
import { UserAccessForm } from './UserAccessForm';
import { useOutletContext } from 'react-router-dom';
import type { ProjectPageOutletContext } from '@cdw-selline/common/types';

export interface UserAccessTableProps {
  isReadOnly?: boolean;
}

export const UserAccessTable = ({ isReadOnly }: UserAccessTableProps) => {
  const { project, updateProjectError, updateProjectLoading } =
    useOutletContext<ProjectPageOutletContext>();
  const {
    columns,
    rows,
    userAccessFormOpen,
    handleUserAccessFormClose,
    openUserAccessForm,
    handleRemoveUser,
    handleRowEditSave,
    handleSave,
  } = useUserAccess(project);

  return (
    <Grid
      container
      sx={{
        height: 'calc(80vh - 5em)',
        width: 'calc(100vw - 5em)',
      }}
    >
      <CollectionsDataTable
        columns={columns}
        rows={rows}
        editMode="row"
        gridMargin={0}
        parent="UserAccessTable"
        saveOnEditStop={true}
        handleSave={handleRowEditSave}
        handleAdd={openUserAccessForm}
        handleDeleteRowDetail={handleRemoveUser}
        allowFilter={false}
        limit={25}
        density="compact"
        isReadOnly={isReadOnly}
        allowAddNew={true}
      />
      <UserAccessForm
        isOpen={userAccessFormOpen}
        handleClose={handleUserAccessFormClose}
        {...{
          project,
          updateProjectError,
          updateProjectLoading,
          handleSave,
        }}
      />
    </Grid>
  );
};

import { AdminConfigSettings } from '@cdw-selline/common/types';
import React, { useEffect, useState, useRef } from 'react';
import DialogModal from '../../dialog-modal/DialogModal';
import { Grid, Typography, Box, Button } from '@mui/material';
import {
  FormText,
  getActionButtons,
} from '../../formHelperFunctions';
import { HotTable } from '@handsontable/react';
import { cloneDeep } from 'lodash';

export interface AdminConfigSettingsFormProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (adminConfigSettings: AdminConfigSettings) => void;
  adminConfigSettings?: AdminConfigSettings | null;
}

export function AdminConfigSettingsForm({
  isOpen,
  handleClose,
  handleSubmit,
  adminConfigSettings,
}: AdminConfigSettingsFormProps) {

  const [formData, setFormData] = useState(adminConfigSettings);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const tableRef = useRef(null);
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    if (adminConfigSettings?.key) {
        setFormData(adminConfigSettings);
        if (adminConfigSettings.valueType === 'array') {
          setTableData(adminConfigSettings.value && adminConfigSettings.value?.map((val)=>[val]) || [Array(1)]);
        }
    }
  }, [adminConfigSettings]);

  const handleFormClose = () => {
    setFormData({});
    setError(false);
    handleClose();
  };

  const handleSaveClick = () => {
    let updatedProperties = cloneDeep(formData);

    if (formData.valueType==='array') {
      const values = tableRef.current?.hotInstance.getDataAtCol(0);

      if (!values?.length || values.flat().some(v=>!v)) {
          setErrorMessage("Please add all required field values !");
          setError(true);
          return;
      }

      updatedProperties = {...updatedProperties, value: values};
    }

    if (formData.valueType==='string' && !formData?.value) {
        setErrorMessage("Please add all required field values !");
        setError(true);
        return;
    }

    handleSubmit(updatedProperties);
    handleClose();
  };

  const handleAddOption = ()=> {
    setTableData([...tableData, Array(1)]);
  };

  const handleDeleteOption = ()=> {
    const copyArray = cloneDeep(tableData);
    copyArray.splice(Number(selectedRow) - 1, 1);
    setTableData(copyArray);
    setSelectedRow(null);
  };

  const getHotTable = (adminConfig: AdminConfigSettings) => {
    return <Grid item xs={12} sx={{ width: '100%' }}>
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Grid container alignItems={'flex-end'}>
          <Button onClick={handleAddOption}>ADD</Button>
          {selectedRow && <Button onClick={handleDeleteOption}>DELETE</Button>}
        </Grid>
      </Box>
      <Box
        sx={{
        overflow: 'hidden',
        width: '100vw - 5px',
        height: '84vh',
        bgcolor: 'background.paper',
        }}
    ><HotTable
          {...{
            id: `option-table-${adminConfig.key}`,
            rowHeaders: true,
            autoColumnSize: false,
            autoRowSize: false,
            colHeaders: [
              'Option Name',
            ],
            manualColumnFreeze: true,
            contextMenu: true,
            stretchH: 'all',
            manualRowMove: true,
            manualColumnResize: true,
            manualColumnHide: true,
            ref: tableRef,
            data: tableData,
            licenseKey: 'non-commercial-and-evaluation',
            tableClassName: 'table-template',
            className: 'table-cell',
            afterSelection: function(r, c, e) {
              setSelectedRow(r+1);
            },
          }}
        />
    </Box>
    </Grid>
  };

  return (
    <DialogModal
      fullWidth={true}
      isOpen={isOpen}
      handleClose={handleClose}
      title={'Edit Admin Config Settings'}
      action={getActionButtons(handleFormClose, handleSaveClick)}
      maxWidthProp={'lg'}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="row"
        spacing={1}
        mt={1}
        sx={{ height: '50vh' }}
        data-testid="config-grid"
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="column"
          spacing={1}
          sx={{ width: '100%' }}
        >
          {error && (
            <Box sx={{ padding: '15px' }}>
              <Typography color="error" variant="h6">{errorMessage}</Typography>
            </Box>
          )}
          <FormText
            name="category"
            label="Category"
            setFormData={setFormData}
            formData={formData}
            disabled={true}
          />
          <FormText
            name="key"
            label="Key"
            setFormData={setFormData}
            formData={formData}
            disabled={true}
          />
          {formData.valueType === 'string' && <FormText
            name="value"
            label="Value"
            setFormData={setFormData}
            formData={formData}
          />}
          {formData.valueType === 'array' && getHotTable(formData)}
        </Grid>
      </Grid>
    </DialogModal>
  );
}

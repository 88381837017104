import { gql } from '@apollo/client';

export const GET_ADMIN_TIERS_SCHEMA_QUERY = gql`
  query getTiersSchemaQuery($getSchemaId: ID!) {
    getSchema(id: $getSchemaId) {
      uiSchema: uiSchemaV2
      schema {
        type
        title
        required
        description
        properties {
          ... on AdminTierProperties {
            name {
              type
              title
            }
          }
        }
      }
    }
  }
`;

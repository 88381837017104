import { gql } from '@apollo/client';

export const REMOVE_PROJECT_TASKS_BY_SITES_MUTATION = gql`
  mutation Mutation(
    $removeSiteAndTasksBySiteProjectId: String!
    $removeSiteAndTasksBySiteSites: [String]!
  ) {
    removeSiteAndTasksBySite(
      projectItemId: $removeSiteAndTasksBySiteProjectId
      sites: $removeSiteAndTasksBySiteSites
    ) {
      success
    }
  }
`;
import { CircularProgress, Grid } from '@mui/material';
import React from 'react';
// import './CDWFallback.css.scss';

/* eslint-disable-next-line */
export interface CDWFallbackProps {}

export const CDWFallback = (props: CDWFallbackProps) => {
  return <CircularProgress />;
  // return (
  //   <Grid container>
  //     <div className="fullscreen f-center">
  //       <div
  //         className="logo-stack f-one f-center"
  //         style={{ position: 'absolute', top: '25em' }}
  //       >
  //         <div className="logo-stack-lvl-0 f-one f-center">
  //           <svg
  //             width="210mm"
  //             height="297mm"
  //             viewBox="0 0 210 297"
  //             version="1.1"
  //             id="svg123"
  //             xmlns="http://www.w3.org/2000/svg"
  //             xmlnsXlink="http://www.w3.org/2000/svg"
  //             style={{
  //               maxHeight: '15em',
  //               top: '-130px',
  //               left: '-25em',
  //               position: 'absolute',
  //             }}
  //           >
  //             <defs id="defs120" />
  //             <g id="layer1">
  //               <path
  //                 d="m 97.569556,147.74368 h 1.83457 c 3.019154,0 4.553734,1.11536 5.330634,3.01916 0.82691,1.97303 0.50768,5.32295 -0.46921,9.92283 -0.9769,4.59989 -2.08072,7.94981 -3.74991,9.91899 -1.584579,1.90764 -3.592222,3.023 -6.611378,3.023 h -1.838416 z m -26.164739,36.64525 h 23.360962 c 14.591951,0 25.818591,-8.85747 28.860831,-23.18019 2.98838,-14.05735 -0.90383,-24.22633 -19.89951,-24.22633 H 81.481491 L 71.404817,184.38893 M 255.1426,140.2631 v -1.03844 h 0.98459 c 0.4346,0 0.90767,0 0.90767,0.46922 0,0.45768 -0.18077,0.58076 -0.6846,0.56922 z m 0,0.84229 h 0.65767 l 1.00767,1.86918 h 1.15382 l -1.10767,-1.86918 c 0.80768,-0.10385 1.24228,-0.43845 1.24228,-1.2769 0,-0.47306 -0.12307,-0.97689 -0.5346,-1.23458 -0.33845,-0.19999 -0.84229,-0.21153 -1.23459,-0.21153 h -2.19225 v 4.59219 h 1.00767 z m 0.75767,3.90759 c 2.30764,0 4.21144,-1.89226 4.21144,-4.21143 0,-2.31918 -1.9038,-4.21144 -4.21144,-4.21144 -2.32686,0 -4.20759,1.89226 -4.20759,4.21144 0,2.31917 1.88073,4.21143 4.20759,4.21143 m 0,-7.30366 c 1.62688,0 3.09223,1.28843 3.09223,3.09223 0,1.79226 -1.46535,3.09223 -3.09223,3.09223 -1.64227,0 -3.08838,-1.29997 -3.08838,-3.09223 0,-1.8038 1.44611,-3.09223 3.08838,-3.09223 M 72.88555,165.3971 c -3.838367,14.49579 -15.522695,20.21104 -27.295481,20.21104 -16.638051,0 -22.184068,-10.11514 -18.995686,-25.11862 2.673011,-14.74579 13.611203,-24.72631 29.653115,-24.72631 11.972782,0 21.08025,4.85757 17.97648,21.13794 H 56.974404 c 1.09228,-7.74212 -0.657677,-8.79209 -2.961466,-8.76132 -4.861418,0 -6.376766,4.96526 -7.988264,12.54584 -1.526886,7.18829 -2.273021,12.54585 2.457631,12.54585 2.43071,0 4.376815,-1.43843 6.757526,-7.83442 H 72.88555 m 54.89865,-28.41469 h 16.81497 L 142.28,166.73552 h 0.13076 l 10.72666,-29.75311 h 19.44183 l -1.92303,29.75311 h 0.13077 l 10.33051,-29.75311 h 16.81497 l -20.32258,47.39499 h -20.36103 l 2.13456,-30.74925 h -0.13077 l -10.93434,30.74925 h -20.36104 z"
  //                 id="path11"
  //                 style={{ strokeWidth: '3.84606' }}
  //               />
  //             </g>
  //           </svg>
  //         </div>
  //         <div className="logo-stack-lvl-1 f-one f-center" style={{ top: 50 }}>
  //           <div className="cdw-ball"></div>
  //         </div>
  //         <div className="logo-stack-lvl-2 f-one f-center">
  //           <div className="cdw-oval-cutout"></div>
  //         </div>
  //         <div className="logo-stack-lvl-3 f-one f-center">
  //           <div className="cdw-oval"></div>
  //         </div>
  //       </div>
  //     </div>
  //   </Grid>
  // );
};

export default CDWFallback;

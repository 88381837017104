import { Opportunity, OpportunityStatusEnum } from '@cdw-selline/common/types';
import { useMutation } from '@apollo/client';
import {
  GET_OPPORTUNITIES_QUERY,
  UPDATE_PROJECT_STATUS_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';

import { useApolloErrors } from './useApolloErrors';
import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material';

export const useProjectsKanban = ({
  projects,
}: {
  projects: Opportunity[];
}) => {
  const theme = useTheme();
  const alertState = useAlertsState();
  const { handleErrorResponse } = useApolloErrors();
  const [forceReload, setForceReload] = useState(false);
  const [
    updateProjectStatus,
    { loading: updateProjectStatusLoading, error: updateProjectStatusError },
  ] = useMutation(UPDATE_PROJECT_STATUS_MUTATION, {
    refetchQueries: [GET_OPPORTUNITIES_QUERY],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to update project status'),
    onCompleted: (data) => {
      if (data.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully Updated Project Status',
        });
      }
    },
  });

  const laneStyle = useRef({
    boxShadow: '2px 2px 4px 0px rgba(0,0,0,0.75)',
    backgroundColor: theme.palette.grey[200],
    width: '25%',
    maxWidth: 400,
  });

  const empytLanes = {
    lanes: [
      {
        id: 'Discovery',
        title: 'Discovery',
        style: laneStyle.current,
        disallowAddingCard: true,
        label: '',
        cards: [],
      },
      {
        id: 'Solution',
        title: 'Solution',
        style: laneStyle.current,
        disallowAddingCard: true,
        label: '',
        cards: [],
      },
      {
        id: 'Propose',
        title: 'Propose',
        style: laneStyle.current,
        disallowAddingCard: true,
        label: '',
        cards: [],
      },
      {
        id: 'Close',
        title: 'Close',
        style: laneStyle.current,
        disallowAddingCard: true,
        label: '',
        cards: [],
      },
    ],
  };

  const [projectData, setProjectData] = useState(empytLanes);
  useEffect(() => {
    const getCardsByStatus = (status: OpportunityStatusEnum) =>
      Array.isArray(projects)
        ? projects
            .filter((o) => o.status === status || o.closedStatus === status)
            .map((o) => ({
              id: o.id,
              title: o.name,
              label: o.customerName,
              editable: status === OpportunityStatusEnum.Discovery,
              ...o,
            }))
        : [];

    const discoveryCards = getCardsByStatus(OpportunityStatusEnum.Discovery);
    const proposeCards = getCardsByStatus(OpportunityStatusEnum.Propose);
    const solutionCards = getCardsByStatus(OpportunityStatusEnum.Solution);
    const closedCards = getCardsByStatus(OpportunityStatusEnum.Close);

    setProjectData({
      lanes: [
        {
          id: 'Discovery',
          title: 'Discovery',
          style: laneStyle.current,
          disallowAddingCard: true,
          label: `${discoveryCards.length}`,
          cards: [...discoveryCards],
        },
        {
          id: 'Solution',
          title: 'Solution',
          style: laneStyle.current,
          disallowAddingCard: true,
          label: `${solutionCards.length}`,
          cards: [...solutionCards],
        },
        {
          id: 'Propose',
          title: 'Propose',
          style: laneStyle.current,
          disallowAddingCard: true,
          label: `${proposeCards.length}`,
          cards: [...proposeCards],
        },
        {
          id: 'Close',
          title: 'Close',
          style: laneStyle.current,
          disallowAddingCard: true,
          label: `${closedCards.length}`,
          cards: [...closedCards],
        },
      ],
    });

    setForceReload(false);
  }, [projects, forceReload, laneStyle]);

  return {
    projectData: projectData,
    updateProjectStatus,
    updateProjectStatusLoading,
    updateProjectStatusError,
    setForceReload,
  };
};

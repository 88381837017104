import { gql } from '@apollo/client';

export const GET_ADMIN_ROLES_SCHEMA_QUERY = gql`
  query getRolesSchemaQuery($getSchemaId: ID!) {
    getSchema(id: $getSchemaId) {
      uiSchema: uiSchemaV2
      schema {
        type
        title
        required
        description
        properties {
          ... on RolesProperties {
            practice {
              title
              type
              enumNames
              enum
            }
            projectManager {
              title
              type
            }
            cdwRateId {
              title
              type
            }
            rate {
              title
              type
            }
            name {
              title
              type
            }
          }
        }
      }
    }
  }
`;

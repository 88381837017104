import { gql } from '@apollo/client';

export const GET_PROFILE_NOTIFICATIONS_SCHEMA = gql`
  query Query($getSchemaId: ID!) {
    getSchema(id: $getSchemaId) {
      schema {
        title
        type
        description
        properties {
          ... on NotificationsProperties {
            email {
              items {
                enum
                type
              }
              title
              type
              uniqueItems
            }
            inApp {
              items {
                type
                enum
              }
              title
              type
              uniqueItems
            }
            sms {
              items {
                enum
                type
              }
              title
              type
              uniqueItems
            }
          }
        }
      }
      uiSchema: uiSchemaV2
    }
  }
`;

export default GET_PROFILE_NOTIFICATIONS_SCHEMA;

// Variables
// {"getSchemaId": "user-notifications"}

import { useMutation } from '@apollo/client';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { useApolloErrors } from '..';
import {
  GET_ESTIMATOR_INCLUDES_SUMMARY_TASKS_QUERY,
  GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY,
  REMOVE_ESTIMATOR_TASK_GROUP_FROM_SITE_MUTATION
} from '@cdw-selline/ui/queries';

export const useRemoveEstimatorTaskGroupFromSite = () => {
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();

  const [removeEstimatorTaskGroupFromSite, { loading, error, client }] = useMutation(
    REMOVE_ESTIMATOR_TASK_GROUP_FROM_SITE_MUTATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        GET_ESTIMATOR_INCLUDES_SUMMARY_TASKS_QUERY
      ],
      onError: (error) => {
        handleErrorResponse(error, 'Failed to remove Estimator task group from site!')
        client.refetchQueries({
          include: [
            GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY,
          ],
        });
      },
      onCompleted: () => {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully Removed Estimator Task group from site',
        });
      },
    }
  );

  const handleRemoveEstimatorTaskGroupFromSite = (projectItemId: string, siteId: string, taskGroupId: string, callback: () => void) => {
    removeEstimatorTaskGroupFromSite({
      variables: {
        projectItemId: projectItemId,
        siteId: siteId,
        taskGroupId: taskGroupId
      },
      onCompleted: () => {
      callback();
    }
    });
  };

  return {
    handleRemoveEstimatorTaskGroupFromSite,
    loading,
    error,
  };
};

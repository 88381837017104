import { useQuery } from '@apollo/client';
import { GetTotalSalesResponse } from '@cdw-selline/common/types';
import { GET_TOTAL_SALES_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from './useApolloErrors';

export const useTotalSales = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery<{
    getTotalSales: GetTotalSalesResponse;
  }>(GET_TOTAL_SALES_QUERY, {
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Total Sales!'),
  });

  return {
    data: data?.getTotalSales ?? null,
    error,
    loading,
  };
};

export default useTotalSales;

/* eslint-disable @nx/enforce-module-boundaries */
import type { Opportunity } from '@cdw-selline/common/types';
import { useProjects, useProjectsKanban } from '@cdw-selline/ui/hooks';
import { useOpportunities } from '@cdw-selline/ui/hooks';
import {
  Backdrop,
  CircularProgress,
  DialogActions,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import Board from 'react-trello';
import { useEffect, useState, SetStateAction } from 'react';
import { DashboardCard } from '../dashboard-cards/DashboardCards';
import DialogModal from '../dialog-modal/DialogModal';
import LoadingButton from '@mui/lab/LoadingButton';

export interface ProjectsKanbanProps {
  routeTo: (id: string, location: string) => void;
  userEmail: string;
  projects: Opportunity[];
  setUserFilter: React.Dispatch<SetStateAction<Record<string, unknown>>>;
}

export const ProjectsKanban = ({
  routeTo,
  userEmail,
  projects,
  setUserFilter,
}: ProjectsKanbanProps) => {
  const theme = useTheme();

  const [showClosedForm, setShowClosedForm] = useState(Boolean(false));
  const [closedStatus, setClosedStatus] = useState('');
  const [cardId, setCardId] = useState('');
  const [closedReason, setClosedReason] = useState('');

  useEffect(() => {
    document.body.style.setProperty('overflow', 'unset', 'important');

    return () => {
      document.body.style.removeProperty('overflow');
    };
  });

  useEffect(() => {
    setUserFilter({
      $or: [
        { projectTeam: { $elemMatch: { email: userEmail } } },
        { projectOwner: userEmail },
      ],
    });
  }, [setUserFilter, userEmail]);

  const handleStatusChange = (e: SelectChangeEvent) => {
    setClosedStatus(e.target.value);
  };

  const handleReasonChange = (value) => {
    setClosedReason(value);
  };

  const handleCompleteForm = (): void => {
    updateProjectStatus({
      variables: {
        updateProjectStatusParams: {
          id: cardId,
          status: 'Close',
          closedReason: closedReason,
          closedStatus: closedStatus,
        },
      },
    });

    setShowClosedForm(false);
    setClosedStatus('');
    setClosedReason('');
    setCardId('');
  };

  const handleCancelForm = () => {
    setShowClosedForm(false);
    setClosedStatus('');
    setClosedReason('');
    setCardId('');
    setForceReload(true);
  };

  const { projectData, updateProjectStatus, setForceReload } =
    useProjectsKanban({ projects });

  return (
    <>
      <Board
        className="boardContainer"
        dragHandle=".react-trello-lane"
        style={{
          backgroundColor: theme.palette.background.paper,
          width: '100%',
          flexDirection: 'column',
        }}
        components={{
          Card: (p) => {
            return (
              <DashboardCard routeTo={routeTo} opportunity={p as Opportunity} />
            );
          },
        }}
        data={projectData}
        handleDragEnd={(cardId, sourceLaneId, targetLaneId, position, card) => {
          if (targetLaneId === 'Close') {
            setCardId(cardId);
            setShowClosedForm(true);
          }

          if (targetLaneId !== 'Close') {
            setClosedStatus('');
            setClosedReason('');
            updateProjectStatus({
              variables: {
                updateProjectStatusParams: {
                  id: cardId,
                  status: targetLaneId,
                  closedReason: '',
                  closedStatus: '',
                },
              },
            });
          }
        }}
      />
      <Paper>
        <DialogModal
          disableScrollLock={true}
          title="Close Opportunity"
          isOpen={showClosedForm === true}
          handleClose={handleCancelForm}
          fullWidth={true}
          maxWidth="xs"
        >
          <Select
            labelId="closedStatus"
            label={'Stage'}
            value={closedStatus || '-1'}
            onChange={handleStatusChange}
            sx={{ margin: '1em 0', width: '100%' }}
          >
            <MenuItem value={'-1'} key={'-1'}>
              Select Option
            </MenuItem>

            <MenuItem value={'ClosedWon'} key={'ClosedWon'}>
              Closed Won
            </MenuItem>

            <MenuItem value={'ClosedLost'} key={'ClosedLost'}>
              Closed Lost
            </MenuItem>
            <MenuItem
              value={'ClosedDidNotPurchase'}
              key={'ClosedDidNotPurchase'}
            >
              Closed Did Not Purchase
            </MenuItem>
          </Select>

          {closedStatus !== 'ClosedWon' && (
            <TextField
              label={'Close Reason'}
              type="text"
              sx={{ width: '100%' }}
              data-testid={`close-project-input`}
              onChange={(e) => handleReasonChange(e.target.value)}
            />
          )}

          <DialogActions>
            <LoadingButton
              color="primary"
              onClick={handleCancelForm}
              variant="contained"
              data-testid="new-closed-project-cancel-button"
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              color="primary"
              type="submit"
              onClick={handleCompleteForm}
              variant="contained"
              data-testid="new-closed-project-submit-button"
            >
              Save
            </LoadingButton>
          </DialogActions>
        </DialogModal>
      </Paper>
    </>
  );
};

export default ProjectsKanban;

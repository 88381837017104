import { gql } from '@apollo/client';

export const REMOVE_PROJECT_CUSTOM_TASK_BY_ID_MUTATION = gql`
  mutation removeEstimatorCustomTaskByIdMutation(
    $projectItemId: ID!
    $site: String!
    $removeTaskId: String
  ) {
    removeEstimatorCustomTaskById(
      projectItemId: $projectItemId
      site: $site
      id: $removeTaskId
    ) {
      success
    }
  }
`;

// VARIABLES
// {
//   "removeTaskId": "id"
// }

import { gql } from '@apollo/client';

export const GET_AGILOFT_ROLES_QUERY = gql`
  query getAgiloftRoles {
    getAgiloftRoles {
      roles {
        id
        name
      }
      count
    }
  }
`;

export default GET_AGILOFT_ROLES_QUERY;

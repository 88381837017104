import { useMutation } from '@apollo/client';
import { TaskNote } from '@cdw-selline/common/types';
import {
  REMOVE_TASK_NOTE_MUTATION,
  GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY,
  GET_PROJECT_TASK_NOTES_BY_TASK_ID,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { useApolloErrors } from '..';

export const useRemoveTaskNote = () => {
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();

  const [removeEstimatorTaskNote, { loading, error }] = useMutation(
    REMOVE_TASK_NOTE_MUTATION,
    {
      refetchQueries: [
        GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY,
        GET_PROJECT_TASK_NOTES_BY_TASK_ID,
      ],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to remove Task Note!'),
      onCompleted: (data) => {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully removed Task Note!',
        });
      },
    }
  );

  const handleRemoveTaskNote = (taskNote: TaskNote, userEmail: string) => {
    if (userEmail !== taskNote.createdBy) return;
    removeEstimatorTaskNote({
      variables: {
        removeEstimatorTaskNoteId: taskNote.id,
      },
    });
  };

  return {
    handleRemoveTaskNote,
    loading,
    error,
  };
};

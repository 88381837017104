import { gql } from '@apollo/client';

export const ADD_SITE_INFO_MUTATION = gql`
  mutation addSiteInfoMutation($projectItemId: ID!, $siteInfo: SiteInfoInput!) {
    addSiteInfo(projectItemId: $projectItemId, siteInfo: $siteInfo) {
      success
    }
  }
`;

// SAMPLE VARIABLES
// {
//   "projectId": "61805a86e6047cfe04ae4085",
//   "siteInfo": {
//     "site": "HQ3-test",
//     "address": "123 des st",
//     "floor": "4"
//   }
// }

import { gql } from '@apollo/client';

export const GET_TRAVEL_EXPENSE_SUMMARY_QUERY = gql`
  query getTravelExpenseSummaryQuery($id: ID!) {
    getTravelExpenseSummaryByEstimatorId(projectItemId: $id) {
      travelExpenseTotal
      isExpenseTotal
      travelTimeRole {
        id
        name
        unit
        rate
        total
        type
      }
      travelTimeTotal {
        id
        name
        unit
        rate
        total
        type
      }
    }
  }
`;

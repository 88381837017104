import { useQuery } from '@apollo/client';
import { GetTasksResponse } from '@cdw-selline/common/types';
import { GET_TASKS_QUERY } from '@cdw-selline/ui/queries';
import { useParams } from 'react-router-dom';

import { useApolloErrors } from './useApolloErrors';

export interface UseTasks {
  filters?: any;
  offset?: number;
  limit?: number;
  sort?: any;
}

export const useTasks = ({ filters, offset, limit, sort }: UseTasks) => {
  const { id } = useParams<{ id: string }>();
  const { handleErrorResponse } = useApolloErrors();

  const { loading, error, data, refetch } = useQuery<{
    getTasks: GetTasksResponse;
  }>(GET_TASKS_QUERY, {
    variables: {
      offset: offset ?? 0,
      limit: limit ?? 100,
      filters: filters ?? {},
      sort: sort ?? {},
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    skip: !!id,
    onError: (error) => handleErrorResponse(error, 'Failed to fetch Tasks!'),
  });

  return {
    data: data?.getTasks ?? { tasks: [], count: 0 },
    error,
    loading,
    refetch,
  };
};

export default useTasks;

import { useScopeGroups } from '@cdw-selline/ui/hooks';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import React from 'react';

export interface ScopeGroupsSelectProps {
  selectedScopeGroupId: string;
  handleScopeGroupSelect: (e: SelectChangeEvent) => void;
}

export function ScopeGroupsSelect({
  selectedScopeGroupId,
  handleScopeGroupSelect,
}: ScopeGroupsSelectProps) {
  const {
    data: scopeGroupData,
    loading: scopeGroupsLoading,
    error: scopeGroupsError,
  } = useScopeGroups({});

  return (
    <FormControl fullWidth>
      <InputLabel id="scope-group-select-label">Scope Group</InputLabel>
      {scopeGroupsLoading ? (
        <CircularProgress />
      ) : scopeGroupsError ? (
        <Typography>Error loading scope groups</Typography>
      ) : (
        <Select
          labelId="scope-group-select-label"
          id="scope-group-select"
          label="Scope Group"
          value={selectedScopeGroupId ?? ''}
          onChange={handleScopeGroupSelect}
          inputProps={{ 'data-testid': 'scope-item-scope-group-select' }}
        >
          {scopeGroupData.scopeGroups.length > 0 &&
            scopeGroupData.scopeGroups.map((scopeGroup) => (
              <MenuItem
                key={scopeGroup.id}
                value={scopeGroup.id}
                data-testid="scope-group-select-option"
              >
                {scopeGroup.name}
              </MenuItem>
            ))}
        </Select>
      )}
    </FormControl>
  );
}

export default ScopeGroupsSelect;

import { ApolloError } from '@apollo/client';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';

export const useApolloErrors = () => {
  const alerts = useAlertsState();

  const handleErrorResponse = (
    error: ApolloError,
    message = 'An Error Occurred',
    alertType?: ALERT_TYPE
  ) => {
    alerts.setAlert({
      type: alertType || ALERT_TYPE.SNACKBAR,
      severity: ALERT_SEVERITY.ERROR,
      message,
    });
    console.error('Apollo Error:', message, error);
  };

  return {
    handleErrorResponse,
  };
};

import { gql } from '@apollo/client';

export const GET_PROJECT_PROPOSALS_BY_PROJECT_ID_QUERY = gql`
  query GetProjectProposalsByProjectId($projectId: ID!) {
    getProjectProposalsByProjectId(projectId: $projectId) {
      content
      id
      name
      projectId
      estimatorId
    }
  }
`;

import { gql } from '@apollo/client';

export const GET_SERVICE_CATEGORIES_QUERY = gql`
  query getServiceCategories {
    getServiceCategories {
      serviceCategories {
        id
        name
      }
      count
    }
  }
`;

export default GET_SERVICE_CATEGORIES_QUERY;

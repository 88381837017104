import { useQuery } from '@apollo/client';
import { GetScopeGroupsResponse } from '@cdw-selline/common/types';
import { GET_SCOPE_GROUPS_QUERY } from '@cdw-selline/ui/queries';
import { useParams } from 'react-router-dom';

import { useApolloErrors } from './useApolloErrors';

export interface UseScopeGroups {
  filters?: any;
  offset?: number;
  limit?: number;
  sort?: unknown;
}

export const useScopeGroups = ({
  filters,
  offset,
  limit,
  sort,
}: UseScopeGroups) => {
  const { handleErrorResponse } = useApolloErrors();
  const { data, loading, error, refetch } = useQuery<{
    getScopeGroups: GetScopeGroupsResponse;
  }>(GET_SCOPE_GROUPS_QUERY, {
    variables: {
      offset: offset ?? 0,
      limit: limit ?? 500,
      filters: filters ?? {},
      sort: sort ?? {},
    },
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch scope groups!'),
  });
  return {
    data: data?.getScopeGroups ?? { count: 0, scopeGroups: [] },
    loading,
    error,
    refetch,
  };
};

export default useScopeGroups;

import { gql } from '@apollo/client';

export const SEARCH_TASK_GROUPS_QUERY = gql`
  query SearchTaskGroups($searchTerm: String) {
    searchTaskGroups {
      taskGroups(searchTerm: $searchTerm) {
        id
        name
        createdBy
        createdAt
        fixedFee
        lastReviewedBy
        lastReviewedOn
        order
        practice
        published
        riskPercent
        sowScopeFooter
        sowScopeHeader
        outcomeSummary
        businessOutcome
        technicalOutcome
        taskGroupCategories {
          id
          name
        }
        version
      }
    }
  }
`;

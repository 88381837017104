import { gql } from '@apollo/client';

export const ADD_SCOPE_ITEM_QUESTION_MUTATION = gql`
  mutation Mutation($params: QuestionInput!) {
    addScopeItemQuestion(params: $params) {
      id
      success
    }
  }
`;

import { useNavTabs } from '@cdw-selline/ui/hooks';
import { useUserState } from '@cdw-selline/ui/state';
import { Backdrop, SvgIconProps, Box, styled, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AppBarBase from './AppBarBase';
import { AppDrawer } from './AppDrawer';

const BaseContainer = styled(Box)(({ theme }) => ({
  height: `calc(100vh - 56px)`,
  [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
    height: `calc(100vh - 48px)`,
  },
  [theme.breakpoints.up('sm')]: {
    height: `calc(100vh - 10em)`,
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '10em',
  },
  background: theme.palette.background.default,
}));

type Menu = {
  icon?: (props: SvgIconProps) => JSX.Element;
  label?: string | number;
  path?: string;
}[];

/* eslint-disable-next-line */
export interface AppBaseContainerProps {
  isLoggedIn: boolean;
  menus: any;
  appBarTitle?: string;
  setTheme?: React.Dispatch<React.SetStateAction<'light' | 'dark'>>;
  theme?: 'light' | 'dark';
  children: React.ReactNode;
}

export const AppBaseContainer: React.FunctionComponent<AppBaseContainerProps> = ({
  children,
  isLoggedIn,
  menus,
  appBarTitle,
  setTheme,
  theme,
}) => {
  const { menu, topMenu } = menus;
  const userState = useUserState();
  const location = useLocation();
  const {
    projectModalOpen,
    handleProjectClick,
    setProjectModalOpen,
    handleProjectSubmit,
    createProjectLoading,
    leadModalOpen,
    handleLeadClick,
    setLeadModalOpen,
    handleLeadSubmit,
    createLeadLoading,
  } = useNavTabs();

  const [open, setOpen] = useState(
    String(localStorage.appDrawerOpen) === 'true' ? true : false
  );

  const toggleDrawer = (forceClose = false) => {
    setOpen((prevOpen) => {
      const openState = forceClose ? false : !prevOpen;
      localStorage.setItem('appDrawerOpen', String(openState));
      return openState;
    });
  };

  const [isSignIn, setSignIn] = React.useState(
    location.pathname?.includes?.('sign-in') || false
  );

  React.useEffect(() => {
    if (!isLoggedIn || location.pathname?.includes?.('sign-in')) {
      setSignIn(true);
    } else {
      setSignIn(false);
    }
  }, [location.pathname, isLoggedIn]);

  return (
    <Box
      sx={{
        height: isSignIn ? '100vh' : 'calc(100vh - 45em)',
        marginTop: isSignIn ? 0 : '.5em',
      }}
    >
      {!isSignIn && (
        <Box>
          <AppBarBase
            showSearch={true}
            appBarTitle={appBarTitle}
            toggleDrawer={toggleDrawer}
            handleProjectClick={handleProjectClick}
            projectModalOpen={projectModalOpen}
            setProjectModalOpen={setProjectModalOpen}
            handleProjectSubmit={handleProjectSubmit}
            handleClose={() => setProjectModalOpen(false)}
            createProjectLoading={createProjectLoading}
            handleLeadClick={handleLeadClick}
            leadModalOpen={leadModalOpen}
            setLeadModalOpen={setLeadModalOpen}
            handleLeadSubmit={handleLeadSubmit}
            createLeadLoading={createLeadLoading}
            setTheme={setTheme}
            theme={theme}
          />
          <AppDrawer
            menuItems={menu}
            topMenuItems={topMenu}
            open={open}
            toggleDrawer={toggleDrawer}
          />
        </Box>
      )}
      <BaseContainer sx={{ marginTop: isSignIn ? 0 : '4em' }}>
        <Backdrop
          open={open && !isSignIn}
          sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
        />
        {children}
      </BaseContainer>
      <Typography style={{ position: 'fixed', left: '10px', bottom: '5px' }}>
        Build: {process.env.REACT_APP_BUILD_DATE}
      </Typography>
    </Box>
  );
};

export default AppBaseContainer;

import { gql } from '@apollo/client';

export const GET_MY_USER = gql`
  query Me {
    me {
      admin
      department
      displayName
      email
      employeeId
      managerEmail
      name
      organization
      picture
      sub
      title
      user_id
      username
      managedServicesAdmin
      contentAdmin
    }
  }
`;

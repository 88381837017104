import { gql } from '@apollo/client';

export const UPDATE_ADMIN_CONFIG_SETTINGS_MUTATION = gql`
  mutation updateAdminConfigSettings(
    $params: AdminConfigSettingsInput!
  ) {
    updateAdminConfigSettings(params: $params) {
      success
    }
  }
`;
import { TinyMCEEditorOptions } from '@cdw-selline/ui/helpers';
import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useRef, useState } from 'react';
import { Editor as EditorClass } from 'tinymce';

export interface RichTextEditorProps {
  documentId: string;
  handleChange: (document: { [key: string]: string }) => void;
  currentValue?: string;
  disabled?: boolean;
  height?: string | number;
  width?: string | number;
  customEditorOptions?: TinyMCEEditorOptions;
}

const defaultEditorOptions: TinyMCEEditorOptions = {
  width: '100%',
  height: 500,
  menubar: 'edit insert view format table tools',
  fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt',
  toolbar:
    'save copy | ' +
    'undo redo | ' +
    'formatselect fontselect fontsizeselect | ' +
    'bold italic | ' +
    'alignleft aligncenter ' +
    'alignright alignjustify | ' +
    'bullist numlist outdent indent | ' +
    'image | forecolor backcolor | paste |' +
    'fullscreen |',
  statusbar: false,
  plugins: [
    'print',
    'advlist',
    'code',
    'lists',
    'link',
    'image',
    'charmap',
    'pagebreak',
    'paste',
    'visualblocks',
    'insertdatetime',
    'help',
    'wordcount',
    'save',
    'table',
    'fullscreen',
    'nonbreaking',
    'noneditable',
  ],
  table_class_list: [
    {title: 'None', value: ' '},
    {title: 'No Blank Rows', value: 'table-no-blank-rows'},
    {title: 'No Cleanup', value: 'table-no-cleanup'},
  ],
  browser_spellcheck: true,
  contextmenu: '',
  paste_data_images: true,
  paste_as_text: true,
  save_onsavecallback: function () {
    return false;
  },
  init_instance_callback: (editor: EditorClass) => {
    editor.on('ExecCommand', (e) => {
      if (
        e.command === 'mceFullScreen' &&
        e.target.plugins.fullscreen.isFullscreen()
      ) {
        editor.notificationManager.open({
          text: `Press CMD + SHIFT + F to exit fullscreen mode`,
          type: 'info',
          timeout: 5000,
        });
      }
    });
  },
};

export function RichTextEditor({
  documentId,
  handleChange,
  currentValue,
  disabled,
  height = 500,
  width = 'calc(80vw - 5em)',
  customEditorOptions,
}: RichTextEditorProps) {
  const [editorState, setEditorState] = useState(currentValue);
  const handleEditorChange = (value: unknown, editor: EditorClass) => {
    if (typeof value !== 'string') {
      return;
    }
    setEditorState(value);
    handleChange({ [documentId]: value });
  };

  useEffect(() => {
    setEditorState(currentValue);
  }, [currentValue]);

  const elId = `rich-text-editor-${documentId}`;

  //TODO: Move addButtons to be located with other addButtons
  const editorOptions: TinyMCEEditorOptions = {
    ...defaultEditorOptions,
    height,
    ...customEditorOptions,
    ...(customEditorOptions?.showNbspSpaceHandlerButtons && {
      toolbar: `${customEditorOptions.toolbar} removeSpaceButton highlightSpaceButton unHighlightSpaceButton`,
    }),
    ...(customEditorOptions?.showNbspSpaceHandlerButtons && {
      setup: (editor) => {
        customEditorOptions.setup && customEditorOptions.setup(editor);
        editor.ui.registry.addButton('removeSpaceButton', {
          text: 'Remove &NBSP;',
          onAction: handleRemoveNbsp,
        });
        editor.ui.registry.addButton('highlightSpaceButton', {
          text: 'Highlight &NBSP;',
          onAction: handleHighlightNbsp,
        });
        editor.ui.registry.addButton('unHighlightSpaceButton', {
          text: 'UnHighlight &NBSP;',
          onAction: handleUnHighlightNbsp,
        });
      },
    }),
  };

  const editorRef = useRef(null);

  const handleRemoveNbsp = () => {
    const editor = editorRef.current.target;
    let content = editor.getContent();
    content = content.replace(/<mark>&nbsp;&amp;nbsp<\/mark>/gi, '');
    content = content.replace(/<mark>&amp;nbsp<\/mark>/gi, '');
    content = content.replace(/&nbsp;/g, '');
    content = content.replace(/^\s*$(?:\r\n?|\n)/gm, '');
    content = content.replace(/^\s*(<br\s*\/?>|\s*<\/?p\s*\/?>)*\s*$/gmi, '');
  
    editor.setContent(content);
  };

  const handleHighlightNbsp = async () => {
    editorRef.current.target.focus();
    const editor = editorRef.current.target;
    const content = editor.getContent().trim();
    let updatedContent =  content.replace(/&nbsp;/g, '<mark>&nbsp</mark>');
    updatedContent = updatedContent.replace(/^\s*&nbsp;\s*$/gm, '<mark>&nbsp;</mark>');
    updatedContent = updatedContent.replace(/ {2,}/g, match => '<mark>&nbsp;</mark>'.repeat(match.length));
    updatedContent = updatedContent.replace(/^\s*$/gm, '<mark>&nbsp;</mark>');
    editor.setContent(updatedContent);
  };

  const handleUnHighlightNbsp = async () => {
    editorRef.current.target.focus();
    const editor = editorRef.current.target;
    const content = editor.getContent().trim();
    const updatedContent = content.replace(/<mark>&amp;nbsp<\/mark>/gi, '&nbsp;');
    editor.setContent(updatedContent);
  };

  return (
      <Editor
        apiKey={process.env.NX_TINY_MCE_API_KEY}
        onInit={( editor) => {
          editorRef.current = editor;
        }}
        initialValue={currentValue ? null : editorState}
        init={editorOptions}
        onEditorChange={handleEditorChange}
        onSaveContent={(value, editor) => handleEditorChange(value, editor)}
        value={editorState}
        disabled={!!disabled}
        id={elId}
        data-testid={elId}
      />
  );
}

export default RichTextEditor;

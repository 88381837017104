import { useMutation, useQuery } from '@apollo/client';
import {
  GET_ESTIMATOR_BID_ASSURANCE_NOTES_QUERY,
  SAVE_ESTIMATOR_BID_ASSURANCE_NOTE_MUTATION,
  REMOVE_ESTIMATOR_BID_ASSURANCE_NOTE_MUTATION,
} from '@cdw-selline/ui/queries';
import { useApolloErrors } from '../useApolloErrors';

export const useBidAssuranceNotes = (
  projectItemId: string,
  { skip = true } = {}
) => {
  const { handleErrorResponse } = useApolloErrors();

  const { loading, error, data } = useQuery(
    GET_ESTIMATOR_BID_ASSURANCE_NOTES_QUERY,
    {
      fetchPolicy: 'network-only', // Used for first execution
      nextFetchPolicy: 'cache-first', // Used for subsequent executions
      variables: {
        projectItemId: projectItemId,
      },
      skip: !projectItemId || skip,
      onError: (error) =>
        handleErrorResponse(
          error,
          'Failed to get estimator bid assurance notes!'
        ),
    }
  );

  const [
    saveBidAssuranceNote,
    {
      loading: saveBidAssuranceNoteLoading,
      error: saveBidAssuranceNoteError,
    },
  ] = useMutation(SAVE_ESTIMATOR_BID_ASSURANCE_NOTE_MUTATION, {
    refetchQueries: [GET_ESTIMATOR_BID_ASSURANCE_NOTES_QUERY],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to save bid assurance note!'),
  });

  const [
    removeBidAssuranceNote,
    {
      loading: removeBidAssuranceNoteLoading,
      error: removeBidAssuranceNoteError,
    },
  ] = useMutation(REMOVE_ESTIMATOR_BID_ASSURANCE_NOTE_MUTATION, {
    refetchQueries: [GET_ESTIMATOR_BID_ASSURANCE_NOTES_QUERY],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to delete bid assurance note!'),
  });

  return {
    bidAssuranceNotes: data?.getEstimatorBidAssuranceNotes ?? [],
    bidAssuranceNotesLoading: loading,
    bidAssuranceNotesError: error,
    removeBidAssuranceNote,
    saveBidAssuranceNote,
  };
};

// eslint-disable-next-line @nx/enforce-module-boundaries
import { StateUserData } from '@cdw-selline/ui/state';
import { Avatar, AvatarGroup, Stack, Tooltip } from '@mui/material';
import * as React from 'react';

function stringToColor(string) {
  let hash = 0;
  let i: number;
  for (i = 0; i < string?.length; i += 1) {
    hash = string?.charCodeAt?.(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value?.toString?.(16)}`?.substr?.(-2);
  }
  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: name
      ? `${name?.split(' ')[0][0]}${
          name?.split(' ').length > 1 ? name.split(' ')[1][0] : ''
        }`
      : '',
  };
}

/* eslint-disable-next-line */
export interface ConnectedUsersListProps {
  connectedUsers: StateUserData[];
}

export const ConnectedUsersList = (props: ConnectedUsersListProps) => {
  const { connectedUsers = [] } = props;
  return (
    <Stack direction="row" spacing={1}>
      <AvatarGroup max={4}>
        {connectedUsers.map((user, idx) => (
          <Tooltip
            key={`project-sow-connected-user-${user.uid}-${idx}`}
            title={`${user.displayName} is connected`}
          >
            <Avatar
              {...stringAvatar(user.displayName)}
              alt={user.displayName}
              src={user.photoURL}
            />
          </Tooltip>
        ))}
      </AvatarGroup>
    </Stack>
  );
};

export default ConnectedUsersList;

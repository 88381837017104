import { gql } from '@apollo/client';

export const GET_CISCO_CCW_QUOTES_BY_DEAL_ID_QUERY = gql`
  query GetCiscoCcwQuotesByDealId(
    $ciscoDealId: String!
    $ciscoAnnuityId: String!
  ) {
    getCiscoCcwQuotesByDealId(
      ciscoDealId: $ciscoDealId
      ciscoAnnuityId: $ciscoAnnuityId
    ) {
      error
      quotes {
        id
        billingCycle
        dealId
        name
        quoteId
        start
        status
        term
        lineTerms {
          partNumber
          line
          term
          billingCycle
          autoRenew
        }
      }
      success
    }
  }
`;

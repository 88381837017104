import { gql } from '@apollo/client';

export const GET_SERVICE_SUMMARY_QUERY = gql`
  query getServiceSummaryQuery($id: ID!) {
    getServiceSummaryByEstimatorId(projectItemId: $id) {
      pmPercent
      riskMitigation
      roles {
        id
        name
        unit
        rate
        total
        type
        costTotal
      }
    }
  }
`;

import type { OpportunityTable } from '@cdw-selline/common/types';
import {
  probabilityOfClose,
  ROUTES,
  statusSteps,
} from '@cdw-selline/ui/constants';
import { formatCurrency } from '@cdw-selline/ui/helpers';
import { useDashboard, useOrganizations } from '@cdw-selline/ui/hooks';
import {
  Backdrop,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  DialogActions,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  MenuItem,
  Select,
  Switch,
  useTheme
} from '@mui/material';
import {
  getGridNumericOperators,
  GridColDef,
  GridRenderCellParams
} from '@mui/x-data-grid';
import moment from 'moment';
import React, { SetStateAction, useState } from 'react';
import { CollectionsDataTable, ProjectsKanban } from '../..';

export interface DashboardTableProps {
  routeTo: (id: string, location: string) => void;
  setUserFilter: React.Dispatch<SetStateAction<Record<string, unknown>>>;
  userFilter: any;
  userState: boolean;
  userEmail: string;
}

export type PipelinesColumn = GridColDef & {
  field: keyof OpportunityTable;
}

export const DashboardTable = (props: DashboardTableProps) => {
  const theme = useTheme();
  const dataTableProps = useDashboard(props.userFilter);
  const { organizations } = useOrganizations();

  const probabilityOptions = probabilityOfClose.map((probability) => {
    return probability.value;
  });

  const orgOptions = organizations.map((org) => {
    return org.name;
  });

  const statusOptions = statusSteps.map((status) => {
    return status;
  });

  const columns: PipelinesColumn[] = [
    {
      field: 'name',
      headerName: 'Opportunity',
      editable: true,
      minWidth: 300,
      flex: 1,
      renderCell: (params) => (
        <Link
          underline="hover"
          onClick={() => {
            handleClick(params.row);
          }}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'id',
      headerName: 'ID',
      editable: false,
      flex: 1,
    },
    {
      field: 'customerName',
      headerName: 'Customer',
      editable: false,
      filterable: true,
      flex: 1,
    },
    {
      field: 'projectOwnerDisplayName',
      headerName: 'Opportunity Owner',
      flex: 1,
      editable: false,
    },
    {
      field: 'estClose',
      headerName: 'Est. Close',
      flex: 1,
      editable: true,
      type: 'date',
      valueParser: (date) => {
        return date === null
          ? new Date().toISOString()
          : new Date(date).toISOString();
      },
      valueGetter: (value) => value && new Date(String(value)),
      renderCell: (params) => {
        const date = params.value ? new Date(String(params.value)) : null;
        const today = new Date();
        const diff = moment(params.value).diff(today.toISOString(), 'days');
        let bgColor: 'success' | 'warning' | 'error' = 'success';
        if (diff > 7) {
          bgColor = 'success';
        } else if (diff <= 7 && diff >= 0) {
          bgColor = 'warning';
        } else if (diff < 0) {
          bgColor = 'error';
        }
        return params.value ? (
          <Chip
            color={bgColor}
            size="small"
            label={date ? date.toLocaleDateString('en-us') : null}
          ></Chip>
        ) : (
          ''
        );
      },
    },
    {
      field: 'probability',
      headerName: 'Probability',
      type: 'singleSelect',
      valueOptions: probabilityOptions,
      editable: true,
      flex: 1,
      filterOperators: getGridNumericOperators().filter(({ value }) =>
        ['=', '>=', '<='].includes(value)
      ),
      valueFormatter: (value) => {
        return value ? `${value}%` : null;
      },
      getOptionLabel: (value: string) => {
        return value ? `${value}%` : null;
      }
    },
    {
      field: 'serviceTotal',
      headerName: 'Service Total',
      editable: false,
      flex: 1,
      filterable: false,
      valueFormatter: (value) => {
        return value ? formatCurrency(value) : formatCurrency(0);
      },
    },
    {
      field: 'productTotal',
      headerName: 'Product Total',
      editable: false,
      flex: 1,
      filterable: false,
      valueFormatter: (value) => {
        return value ? formatCurrency(value) : formatCurrency(0);
      },
    },
    {
      field: 'subscriptionTotal',
      headerName: 'Subscription Total',
      editable: false,
      flex: 1,
      filterable: false,
      valueFormatter: (value) => {
        return value ? formatCurrency(value) : formatCurrency(0);
      },
    },
    {
      field: 'opportunityTotal',
      headerName: 'Opportunity Total',
      editable: false,
      flex: 1,
      filterable: false,
      valueFormatter: (value) => {
        return value ? formatCurrency(value) : formatCurrency(0);
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      editable: false,
      flex: 1,
      type: 'singleSelect',
      valueOptions: statusOptions,
      renderCell: (params) => (
        <Chip color="primary" size="small" label={params.value} />
      ),
    },
    {
      field: 'solutionArchitect',
      headerName: 'Solution Architect',
      editable: false,
      hideable: false,
    },
    {
      field: 'salesRep',
      headerName: 'Sales Rep',
      editable: false,
      hideable: false,
    },
    {
      field: 'organization',
      headerName: 'Organization',
      editable: false,
      flex: 1,
      type: 'singleSelect',
      valueOptions: orgOptions,
    },
  ];

  const handleToggleChange = (e) => {
    if (e.target.checked) {
      props.setUserFilter({});
      localStorage.setItem(`OpportunityView-toggle`, JSON.stringify({}));
    } else {
      props.setUserFilter({
        $or: [
          { projectTeam: { $elemMatch: { email: props.userEmail } } },
          { projectOwner: props.userEmail },
        ],
      });
      localStorage.setItem(
        `OpportunityView-toggle`,
        JSON.stringify({
          $or: [
            { projectTeam: { $elemMatch: { email: props.userEmail } } },
            { projectOwner: props.userEmail },
          ],
        })
      );
    }
  };

  const localValue = JSON.parse(
    localStorage.getItem(`OpportunityView-projectView`)
  ) as number;
  const [projectView, setProjectView] = useState(localValue ?? 10);

  const handleClick = (opportunity: GridRenderCellParams) => {
    props.routeTo(`${opportunity.id}`, ROUTES.PIPELINES);
  };

  const handleChange = (event) => {
    setProjectView(Number(event.target.value));
    localStorage.setItem(
      `OpportunityView-projectView`,
      JSON.stringify(Number(event.target.value))
    );
  };

  if (dataTableProps.loading && projectView === 20) {
    return (
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <CircularProgress />
      </Backdrop>
    );
  }

  if (dataTableProps.error) return <>Error</>;

  return (
    <Card
      style={{
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        height: '90vh',
      }}
    >
      <CardHeader
        title="Opportunities"
        action={
          <DialogActions>
            <Grid
              sx={{
                width: '140px',
                backgroundColor: theme.palette.error.main,
                color: theme.palette.error.contrastText,
                p: '.2em',
              }}
            >
              Past Estimated Close
            </Grid>
            <Grid
              sx={{
                width: '200px',
                backgroundColor: theme.palette.warning.main,
                color: theme.palette.warning.contrastText,
                p: '.2em',
              }}
            >
              Estimated Close within 7 Days
            </Grid>
            <Grid
              sx={{
                width: '225px',
                backgroundColor: theme.palette.success.main,
                color: theme.palette.success.contrastText,
                mr: '2em',
                p: '.2em',
              }}
            >
              Estimated Close more than 7 Days
            </Grid>
            <FormControlLabel
              control={
                <Switch
                  checked={projectView === 10 ? props.userState : false}
                  onChange={handleToggleChange}
                  disabled={projectView === 10 ? false : true}
                />
              }
              label={
                props.userState
                  ? 'View My Opportunities'
                  : 'View all Opportunities'
              }
            />
            <FormControlLabel
              control={
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={projectView}
                  label="Pipeline"
                  onChange={handleChange}
                  size="small"
                >
                  <MenuItem value={10}>Table</MenuItem>
                  <MenuItem value={20}>Kanban</MenuItem>
                </Select>
              }
              label=""
            />
          </DialogActions>
        }
        sx={{ width: '100%' }}
      />

      <Divider />
      {projectView === 10 ? (
        <CollectionsDataTable
          {...dataTableProps}
          columns={columns}
          editMode="row"
          gridMargin={0}
          parent="OpportunityView"
          saveOnEditStop={true}
          paginationMode="server"
          sortingMode="server"
          height="95%"
        />
      ) : (
        <ProjectsKanban
          projects={dataTableProps.rows}
          routeTo={props.routeTo}
          userEmail={props.userEmail}
          setUserFilter={props.setUserFilter}
        />
      )}
    </Card>
  );
};

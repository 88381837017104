import { Task } from '@cdw-selline/common/types';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { labelStrings } from '@cdw-selline/ui/constants';
import {
  Button,
  Box,
  DialogActions,
  Grid,
  Typography,
  Tooltip,
} from '@mui/material';
import React from 'react';

import DialogModal from '../../../../dialog-modal/DialogModal';
import { ContentEditor } from '../../../../content-editor/ContentEditor';
import detailedSow from '../../../../project-sow/detailed-sow.str.css';
import { RichTextEditorNoState } from '../../../../rich-text-editor/RichTextEditorNoState';

export interface TextTaskDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  currentTask: Task;
  handleTaskSave: (task: Task) => void;
}

export function TextTaskDialog({
  isOpen,
  handleClose,
  currentTask,
  handleTaskSave,
}: TextTaskDialogProps) {
  const [updatedText, setUpdatedText] = React.useState(currentTask.text ?? '');
  const [error, setError] = React.useState('');

  const handleTextTaskSave = () => {
    if (!currentTask.allowBlankText && !updatedText.length) {
      setError(
        'Text input is required. Please complete this field to continue.'
      );
    } else {
      setError('');
      handleTaskSave({
        id: currentTask.id,
        text: updatedText,
      });
    }
  };

  const handleEditorChange = (params) => {
    setUpdatedText(params['text-editor'] ?? '');
  };

  const contentStyle = detailedSow;
  return (
    <DialogModal
      fullWidth={true}
      disableEnforceFocus={true}
      {...{ isOpen, handleClose, maxWidthProp: 'lg' }}
      title={`${currentTask.name} - Content`}
    >
      <Grid container sx={{ paddingTop: '15px' }}>
        <Grid item xs={12} sx={{ paddingBottom: '15px' }}>
          {currentTask.instructionsText && (
            <div id="textTaskInstructions">
              <Box sx={{ marginTop: 2, marginBottom: 1 }}>
                <Typography variant="h6">Content Guidelines</Typography>
              </Box>
              <ContentEditor
                documentId="text-instructions"
                contentStyle={contentStyle}
                currentValue={currentTask.instructionsText}
              />
              <br />
            </div>
          )}
          {error.length > 0 && (
            <Typography color="error" variant="h6">
              {error}
            </Typography>
          )}
          {currentTask.textDisabled ? (
            <Tooltip title="Read only - no edits">
              <div>
                <ContentEditor
                  documentId="text-editor"
                  contentStyle={contentStyle}
                  currentValue={currentTask.text}
                />
              </div>
            </Tooltip>
          ) : (
            <RichTextEditorNoState
              width="100%"
              documentId={'text-editor'}
              customEditorOptions={{
                content_style: contentStyle,
                body_class: 'projectSowScope',
              }}
              editorState={updatedText}
              currentValue={currentTask.text}
              setCurrentValue={setUpdatedText}
              handleChange={handleEditorChange}
              height="300px"
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <DialogActions>
            {!currentTask.textDisabled && (
              <Button
                variant="contained"
                onClick={handleTextTaskSave}
                aria-label="apply task changes"
                data-testid="apply-changes"
              >
                {labelStrings.APPLY_CHANGES}
              </Button>
            )}
            <Button
              onClick={handleClose}
              aria-label="exit without changes"
              data-testid="cancel-changes"
            >
              {currentTask.textDisabled ? 'EXIT' : labelStrings.EXIT_NO_CHANGES}
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </DialogModal>
  );
}

export default TextTaskDialog;

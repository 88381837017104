import { ApolloError } from '@apollo/client';
import { CDWFallback } from '@cdw-selline/ui/components';
import { Backdrop, Typography } from '@mui/material';
import React from 'react';

export interface CollectionDetailPageProps {
  loading: boolean;
  error: ApolloError;
  collectionForm: JSX.Element;
}

export function CollectionDetailPage({
  loading,
  error,
  collectionForm,
}: CollectionDetailPageProps) {
  if (loading)
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open
      >
        <CDWFallback />
      </Backdrop>
    );

  if (error)
    return (
      <Typography color="error">
        {' '}
        Error loading form! {error.message}
      </Typography>
    );

  return collectionForm;
}

export default CollectionDetailPage;

import { gql } from '@apollo/client';

export const ADD_CISCO_ANNUITY_CONTACT_MUTATION = gql`
  mutation addCiscoAnnuityContact(
    $ciscoAnnuityId: String!
    $ciscoAnnuityContact: CiscoAnnuityContactInput!
  ) {
    addCiscoAnnuityContact(
      ciscoAnnuityId: $ciscoAnnuityId
      ciscoAnnuityContact: $ciscoAnnuityContact
    ) {
      success
    }
  }
`;

import {
  Divider,
  IconButton,
  Typography,
  Box,
  Drawer,
  DrawerProps,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as React from 'react';
import { ProjectPageHistory } from './ProjectPageHistory';

export interface ProjectPageDrawerProps {
  toggleDrawer: (open: boolean) => void;
  drawerState: boolean;
}

export const ProjectPageDrawer = (props: ProjectPageDrawerProps) => {
  const { toggleDrawer, drawerState } = props;

  const list = () => (
    <Box
      sx={{
        width: 400,
        marginTop: '5em',
      }}
      role="presentation"
    >
      <Box
        sx={{
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}
        >
          <Box>
            <Typography variant="h6">Status History</Typography>
          </Box>
          <Box
            onClick={() => toggleDrawer(false)}
            sx={{
              justifyContent: 'flex-end',
              display: 'flex',
              right: 5,
              position: 'absolute',
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="close drawer"
              onClick={() => toggleDrawer(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box>
          <Divider variant="fullWidth" sx={{ color: '#444', mt: '1em' }} />
          <ProjectPageHistory drawerState={drawerState} />
        </Box>
      </Box>
    </Box>
  );

  return (
    <Drawer
      anchor={'right' as DrawerProps['anchor']}
      open={drawerState}
      onClose={() => toggleDrawer(false)}
      disableScrollLock={true}
    >
      {list()}
    </Drawer>
  );
};

import { useMutation, useQuery } from '@apollo/client';
import {
  GET_ESTIMATOR_HAS_BID_ASSURANCE_URL_QUERY,
  REQUEST_ESTIMATOR_BID_ASSURANCE_MUTATION,
  CANCEL_ESTIMATOR_BID_ASSURANCE_MUTATION,
  DENY_ESTIMATOR_BID_ASSURANCE_MUTATION,
  ESCALATE_ESTIMATOR_BID_ASSURANCE_MUTATION,
  GET_PROJECT_ITEM_ESTIMATORS_QUERY,
} from '@cdw-selline/ui/queries';
import { useApolloErrors } from '../useApolloErrors';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';

export const useBidAssurance = (
  projectItemId: string,
  { skip = false } = {}
) => {
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();

  const { loading, error, data } = useQuery(
    GET_ESTIMATOR_HAS_BID_ASSURANCE_URL_QUERY,
    {
      fetchPolicy: 'network-only', // Used for first execution
      nextFetchPolicy: 'cache-first', // Used for subsequent executions
      variables: {
        projectItemId: projectItemId,
      },
      skip: !projectItemId || skip,
      onError: (error) =>
        handleErrorResponse(
          error,
          'Failed to find if estimator had bid assurance!'
        ),
    }
  );

  const [
    requestEstimatorBidAssurance,
    {
      loading: requestBidAssuranceLoading,
      error: requestBidAssuranceError,
    },
  ] = useMutation(REQUEST_ESTIMATOR_BID_ASSURANCE_MUTATION, {
    variables: {
      projectItemId: projectItemId,
    },
    refetchQueries: [GET_PROJECT_ITEM_ESTIMATORS_QUERY],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to request bid assurance!'),
    onCompleted: () => {
      alertState.setAlert({
        type: ALERT_TYPE.MODAL,
        severity: ALERT_SEVERITY.SUCCESS,
        message: 'Bid Assurance has been requested for your project!',
      });
    },
  });

  const [
    cancelEstimatorBidAssurance,
    {
      loading: cancelBidAssuranceLoading,
      error: cancelBidAssuranceError,
    },
  ] = useMutation(CANCEL_ESTIMATOR_BID_ASSURANCE_MUTATION, {
    variables: {
      projectItemId: projectItemId,
    },
    refetchQueries: [GET_PROJECT_ITEM_ESTIMATORS_QUERY],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to cancel bid assurance!'),
    onCompleted: () => {
      alertState.setAlert({
        type: ALERT_TYPE.MODAL,
        severity: ALERT_SEVERITY.SUCCESS,
        message: 'Bid Assurance has been canceled for your project!',
      });
    },
  });

  const [
    denyEstimatorBidAssurance,
    {
      loading: denyBidAssuranceLoading,
      error: denyBidAssuranceError,
    },
  ] = useMutation(DENY_ESTIMATOR_BID_ASSURANCE_MUTATION, {
    variables: {
      projectItemId: projectItemId,
    },
    refetchQueries: [GET_PROJECT_ITEM_ESTIMATORS_QUERY],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to deny bid assurance!'),
    onCompleted: () => {
      alertState.setAlert({
        type: ALERT_TYPE.MODAL,
        severity: ALERT_SEVERITY.SUCCESS,
        message: 'Bid Assurance has been denied for your project!',
      });
    },
  });

  const [
    escalateEstimatorBidAssurance,
    {
      loading: escalateBidAssuranceLoading,
      error: escalateBidAssuranceError,
    },
  ] = useMutation(ESCALATE_ESTIMATOR_BID_ASSURANCE_MUTATION, {
    variables: {
      projectItemId: projectItemId,
    },
    refetchQueries: [GET_PROJECT_ITEM_ESTIMATORS_QUERY],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to escalate bid assurance!'),
    onCompleted: () => {
      alertState.setAlert({
        type: ALERT_TYPE.MODAL,
        severity: ALERT_SEVERITY.SUCCESS,
        message: 'Bid Assurance has been escalated for your project!',
      });
    },
  });

  return {
    estimatorHasBidAssurance: data?.getEstimatorHasBidAssuranceUrl ?? false,
    estimatorHasBidAssuranceLoading: loading,
    estimatorHasBidAssuranceError: error,
    requestEstimatorBidAssurance,
    requestBidAssuranceLoading,
    requestBidAssuranceError,
    cancelEstimatorBidAssurance,
    denyEstimatorBidAssurance,
    escalateEstimatorBidAssurance,
  };
};

import { Grid } from '@mui/material';
import React from 'react';

interface PerpariedByProps {
  name: string;
  title: string;
  organization: string;
  phone: string;
  email: string;
}

export const PreparedBy = ({
  name,
  title,
  organization,
  phone,
  email,
}: PerpariedByProps) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        {name}
      </Grid>
      <Grid item xs={12}>
        {title}
      </Grid>
      <Grid item xs={12}>
        {organization}
      </Grid>
      <Grid item xs={12}>
        {phone}
      </Grid>
      <Grid item xs={12}>
        {email}
      </Grid>
    </Grid>
  );
};

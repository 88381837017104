import { gql } from '@apollo/client';

export const UPDATE_PRACTICE_MUTATION = gql`
  mutation updatePracticeMutation($params: AdminPracticeInput) {
    updatePractice(params: $params) {
      success
    }
  }
`;

// VARIABLES
// {
//   "params": {}
// }

import { useQuery } from '@apollo/client';
import { GET_NEXT_TASK_ID_QUERY } from '@cdw-selline/ui/queries';
import { useApolloErrors } from '../';

export const useGetNextTaskId = (taskGroupId: string) => {
  const { handleErrorResponse } = useApolloErrors();

  const { loading, error, data, refetch } = useQuery(GET_NEXT_TASK_ID_QUERY, {
    variables: {
      taskGroupId,
    },
    skip: !taskGroupId,
    fetchPolicy: 'network-only',
    onError: (error) => handleErrorResponse(error, 'Failed to fetch new taskId!'),
  });

  return {
    nextTaskId: data?.getNextTaskId ?? 0,
    loading,
    error,
    refetch,
  };
};

import { useQuery } from '@apollo/client';
import { GET_TOTAL_CUSTOMERS_QUERY } from '@cdw-selline/ui/queries';
import { GetTotalCustomersResponse } from '@cdw-selline/common/types';

import { useApolloErrors } from './useApolloErrors';

export const useTotalCustomers = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery<{
    getTotalCustomers: GetTotalCustomersResponse;
  }>(GET_TOTAL_CUSTOMERS_QUERY, {
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Customers!'),
  });

  return {
    data: data?.getTotalCustomers ?? null,
    error,
    loading,
  };
};

export default useTotalCustomers;

import { useQuery } from '@apollo/client';
import { GET_PRACTICE_BY_ID_QUERY } from '@cdw-selline/ui/queries';
import { useApolloErrors } from '.';

export const usePracticeById = (id: string) => {
  const { handleErrorResponse } = useApolloErrors();

  const { loading, error, data, refetch } = useQuery(GET_PRACTICE_BY_ID_QUERY, {
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: 'network-only',
    onError: (error) => handleErrorResponse(error, 'Failed to fetch Practice!'),
  });

  return {
    data: data?.getPracticeById ?? {},
    loading,
    error,
    refetch,
  };
};

import { useMutation } from '@apollo/client';
import { Site } from '@cdw-selline/common/types';
import {
  ADD_SITE_INFO_MUTATION,
  GET_ESTIMATOR_SITES_BY_PROJECT_ITEM_ID_QUERY,
  GET_ESTIMATOR_TOTALS_BY_ITEM_ID,
  GET_PROJECT_QUERY,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';

import { useApolloErrors } from '..';

export const useAddSiteInfo = () => {
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();

  const [addSiteInfo, { loading, error, client }] = useMutation(
    ADD_SITE_INFO_MUTATION,
    {
      refetchQueries: [
        GET_ESTIMATOR_TOTALS_BY_ITEM_ID,
        GET_ESTIMATOR_SITES_BY_PROJECT_ITEM_ID_QUERY,
      ],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to add Site Info!'),
      onCompleted: (data) => {
        client.refetchQueries({
          include: [GET_PROJECT_QUERY],
        });
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully Added Site Info',
        });
      },
    }
  );

  const handleAddSite = (projectItemId: string, site: Site) => {
    addSiteInfo({
      variables: {
        projectItemId,
        siteInfo: {
          ...site,
        },
      },
    });
  };

  return {
    handleAddSite,
    loading,
    error,
  };
};

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { MansEnrollmentSummaryResponse } from '@cdw-selline/common/types';
import { GET_MANS_ENROLLMENT_SUMMARY_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from '../..';

export const useMansEnrollmentSummary = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { projectItemId } = useParams<{ projectItemId: string }>();
  const { loading, error, data } = useQuery<{
    getMansEnrollmentSummaryByEstimatorId: MansEnrollmentSummaryResponse;
  }>(GET_MANS_ENROLLMENT_SUMMARY_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: { id: projectItemId },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Enrollment Summary!'),
  });

  return {
    enrollmentSummary: data?.getMansEnrollmentSummaryByEstimatorId ?? {
      roles: [],
      pmPercent: 0,
      riskMitigation: 0,
    },
    error,
    loading,
  };
};

import {
  createTheme,
  responsiveFontSizes,
  ThemeOptions,
} from '@mui/material/styles';
import { grey, blue } from '@mui/material/colors';
import merge from 'ts-deepmerge';

export const themeGlobals = {
  containerMargins: {
    marginLeft: '12px',
    marginRight: '12px',
    marginTop: '76px',
    flexDirection: 'column',
    justifyContent: 'center',
    display: 'flex',
  },
};

const typography: Pick<ThemeOptions, 'typography'> = {
  typography: {
    htmlFontSize: 16,
    fontSize: 12,
    h1: {
      fontSize: '2.7rem',
    },
    h2: {
      fontSize: '2.2rem',
    },
    h3: {
      fontSize: '1.6rem',
      lineHeight: 1,
    },
  },
};

const components: Pick<ThemeOptions, 'components'> = {
  components: {
    MuiScopedCssBaseline: {
      styleOverrides: {
        root: {
          html: {},
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '.5rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '1rem',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button':
            {
              WebkitAppearance: 'none',
              margin: 0,
            },
          'input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      },
    },
  },
};

const lightPalette: ThemeOptions = {
  name: 'light',
  typography: {
    allVariants: {
      color: '#000',
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: blue[400],
      contrastText: grey[100],
    },
    secondary: {
      main: blue[400],
      contrastText: grey[100],
    },
    background: {
      paper: '#fff',
      default: blue[100],
    },
  },
};

const darkPalette: ThemeOptions = {
  name: 'dark',
  palette: {
    mode: 'dark',
    grey: {
      50: '#f7fafc',
      100: '#edf2f7',
      200: '#e2e8f0',
      300: '#cbd5e0',
      400: '#a0aec0',
      500: '#718096',
      600: '#4a5568',
      700: '#2d3748',
      800: '#1a202c',
      900: '#171923',
    },

  },
};

export const themeLight: ThemeOptions = merge(
  typography,
  components,
  lightPalette
);
export const themeDark: ThemeOptions = merge(
  typography,
  components,
  darkPalette
);

export const getMuiTheme = (mode?: 'light' | 'dark') => {
  let theme = responsiveFontSizes(
    createTheme(mode === 'dark' ? themeDark : themeLight)
  );

  theme = createTheme(theme, {
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          tag: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
        },
      },
    },
  });

  return theme;
};

// MODULE DECLARATION
declare module '@mui/material' {
  interface Theme {
    name: string;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    name?: string;
  }
  export interface Palette {
    backdrops: Palette['primary'];
  }
  export interface PaletteOptions {
    backdrops?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    poster: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    poster?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    poster: true;
  }
}

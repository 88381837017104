import { AgiloftTravelNoticeType } from '@cdw-selline/common/types';
import { upperFirst } from 'lodash';
import { ValidationError, array, object, string } from 'yup';

const agiloftTravelNoticeTypeBaseSchema = object({
  travelNoticeTypeName: string().required(),
  travelNoticeClauses: array().of(string().required()).min(1),
  agiloftDefaultTravelNoticeClause: string().required(),
});

export const useValidateAgiloftTravelNoticeType = () => {
  const validateAgiloftTravelNoticeType = async (agiloftTravelNoticeType: AgiloftTravelNoticeType) => {
    const errorArray = [];
    try {
      await agiloftTravelNoticeTypeBaseSchema.validate(agiloftTravelNoticeType, { abortEarly: false });
    } catch (error) {
      if (error && error instanceof ValidationError) {
        error.inner.forEach((err) => {
          errorArray.push(upperFirst(err.message));
        });
      }
    }

    return errorArray;
  };

  return {
    validateAgiloftTravelNoticeType,
  };
};

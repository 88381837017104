import { CollectionsDataTable, PracticeForm } from '@cdw-selline/ui/components';
import { useAdminPractices, usePracticeById } from '@cdw-selline/ui/hooks';
import { Box } from '@mui/material';
import React from 'react';

/* eslint-disable-next-line */
export interface PracticesCollectionPageProps {}

export const PracticesCollectionPage = (
  props: PracticesCollectionPageProps
) => {
  const dataTableProps = useAdminPractices();

  const { data: practice } = usePracticeById(dataTableProps.editPracticeId);

  const openForm =
    dataTableProps.practiceFormOpen &&
    ((dataTableProps.editPracticeId && practice.id) ||
      !dataTableProps.editPracticeId);

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <CollectionsDataTable
        {...dataTableProps}
        height="calc(100vh - 13em)"
        parent="PracticesCollectionPage"
        paginationMode="server"
        sortingMode="server"
        onRowClick={(o) => dataTableProps.handleEdit(o.id)}
      />
      {openForm && (
        <PracticeForm
          isOpen={dataTableProps.practiceFormOpen}
          handleClose={dataTableProps.handlePracticeFormClose}
          handleSubmit={dataTableProps.handlePracticeFormSave}
          practice={practice}
        />
      )}
    </Box>
  );
};

export default PracticesCollectionPage;

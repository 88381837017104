import { gql } from '@apollo/client';

export const GET_CISCO_SMART_ACCOUNTS_BY_CUSTOMER_ID_QUERY = gql`
  query getCiscoSmartAccountsByCustomerId($customerId: String!) {
    getCiscoSmartAccountsByCustomerId(customerId: $customerId) {
        name
        virtualAccounts {
          name
        }
      }
    }
`;

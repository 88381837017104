import { gql } from '@apollo/client';

export const CREATE_PROJECT_MUTATION = gql`
  mutation createProject($createProjectParams: ProjectInput!) {
    createProject(params: $createProjectParams) {
      success
      id
    }
  }
`;

export default CREATE_PROJECT_MUTATION;

// Variables
// "createProjectParams": {
//   "projectName": "Yet Another Test Project",
//   "customerDescription": "...",
//   ...
// }

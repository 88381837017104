import { gql } from '@apollo/client';

export const UPDATE_ESTIMATOR_TASK_MUTATION = gql`
  mutation UpdateEstimatorTask($params: TaskInput, $estimatorAdminUpdate: Boolean) {
    updateEstimatorTask(params: $params, estimatorAdminUpdate: $estimatorAdminUpdate) {
      success
      updatedTask {
        id
        taskId
        name
        siteId
        custom
      }
    }
  }
`;

import { ProjectComment } from '@cdw-selline/common/types';
import { useGetProjectCommentsByProjectId } from './useGetProjectComments';
import { useApolloErrors } from '../../';
import {
  ADD_PROJECT_COMMENT_MUTATION,
  GET_PROJECT_COMMENTS_BY_PROJECT_ID_QUERY,
  REMOVE_PROJECT_COMMENT_MUTATION,
  UPDATE_PROJECT_COMMENT_MUTATION,
} from '@cdw-selline/ui/queries';
import { useMutation } from '@apollo/client';

export const useProjectComments = (
  projectId: string,
  completeCallback?: () => void
) => {
  const { handleErrorResponse } = useApolloErrors();
  const {
    data: projectComments,
    loading: projectCommentsLoading,
    error: projectCommentsError,
  } = useGetProjectCommentsByProjectId(projectId);

  const [addProjectComment, { loading: addLoading, error: addError }] =
    useMutation(ADD_PROJECT_COMMENT_MUTATION, {
      refetchQueries: [GET_PROJECT_COMMENTS_BY_PROJECT_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to add project comment!'),
      onCompleted: (data) => {
        completeCallback?.();
      },
    });

  const [updateProjectComment, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_PROJECT_COMMENT_MUTATION, {
      refetchQueries: [GET_PROJECT_COMMENTS_BY_PROJECT_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update project comment!'),
      onCompleted: (data) => {
        completeCallback?.();
      },
    });

  const [removeProjectComment, { loading: removeLoading, error: removeError }] =
    useMutation(REMOVE_PROJECT_COMMENT_MUTATION, {
      refetchQueries: [GET_PROJECT_COMMENTS_BY_PROJECT_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to remove project comment!'),
      onCompleted: (data) => {
        completeCallback?.();
      },
    });

  const handleAddProjectComment = (
    projectId: string,
    comment: ProjectComment
  ) => {
    addProjectComment({
      variables: {
        projectId: projectId,
        comment: comment,
      },
    });
  };

  const handleEditProjectComment = (
    projectId: string,
    comment: ProjectComment
  ) => {
    updateProjectComment({
      variables: {
        projectId: projectId,
        commentId: comment.id,
        comment: comment,
      },
    });
  };

  const handleRemoveProjectComment = (projectId: string, commentId: string) => {
    removeProjectComment({
      variables: {
        projectId: projectId,
        commentId: commentId,
      },
    });
  };

  return {
    projectComments,
    projectCommentsLoading,
    projectCommentsError,
    handleAddProjectComment,
    handleEditProjectComment,
    handleRemoveProjectComment,
    addLoading,
    addError,
    updateLoading,
    updateError,
    removeLoading,
    removeError,
  };
};

import { gql } from '@apollo/client';

export const ADD_QUOTE_LINES_MUTATION = gql`
  mutation Mutation(
    $projectItemId: ID!
    $quoteLines: [ProjectItemQuoteLineInput]
  ) {
    addQuoteLines(projectItemId: $projectItemId, quoteLines: $quoteLines) {
      success
    }
  }
`;

/* VARIABLES
{
  "projectItemId": "6220f12100fbfcda32ab82ec",
  "quoteLines": [
      // array of quote lines to be added
  ]
}
*/

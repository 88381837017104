import { Grid } from '@mui/material';
import React from 'react';
import { SystemProposalView } from '../../proposal/system/SystemProposalView';
import { useParams } from 'react-router-dom';
import { useProjectConfigSettings } from '@cdw-selline/ui/hooks';

export interface CiscoAnnuityOrderFormProps {
  loading?: boolean;
}

export function CiscoAnnuityOrderForm(props: CiscoAnnuityOrderFormProps) {
  const { projectItemId, id } = useParams<{ projectItemId: string, id: string }>();
  const { data, loading } = useProjectConfigSettings({filters: {key: ['COF Template Id']}});
  const proposalTemplateId = data.adminConfigSettings[0]?.value;

  if(loading || !proposalTemplateId) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <SystemProposalView
          proposalTemplateId={proposalTemplateId}
          projectItemId={projectItemId}
          projectId={id}
        />
      </Grid>
    </Grid>
  );
}

export default CiscoAnnuityOrderForm;

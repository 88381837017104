import { ApolloError } from '@apollo/client';
import { TaskGroup } from '@cdw-selline/common/types';
import {
  useAdminTaskGroupDetail,
  useAdminTaskGroupVersion,
  useAdminTaskGroupVersionList,
  useCustomEditorOptions,
  useOpenState,
  usePractices,
  useRoles,
  useServiceCategories,
  useValidateTaskGroup
} from '@cdw-selline/ui/hooks';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { cloneDeep, debounce, startCase } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { AdminAccessTable } from '../../admin-access-table/AdminAccessTable';
import { DialogConfirm } from '../../dialog-confirm/DialogConfirm';
import DialogModal from '../../dialog-modal/DialogModal';
import {
  FormAutocomplete,
  FormCheckbox,
  FormMultiSelectAutocomplete,
  FormRichTextEditor,
  FormText,
  FormTextControlled,
  getActionButtons
} from '../../formHelperFunctions';
import { SiteTableModal } from '../../project-sow/sitetable-modal/SiteTableModal';
import { TaskModal } from '../../project-sow/sow-modal/TaskModal';
import { ItemsProvidedToCustomers } from '../sow-sections/items-provided-to-customers/ItemsProvidedToCustomers';
import { TaskGroupSowSections } from './TaskGroupSowSections';

import { TASK_STATUS } from '@cdw-selline/ui/constants';
import { proposalMaxWidth } from '@cdw-selline/ui/helpers';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import detailedSow from '../../project-sow/detailed-sow.str.css';
import outcomeSow from '../../project-sow/outcome-sow.str.css';
import TaskGroupComparisonTable from './TaskGroupComparisonTable';
import TaskGroupProposalTemplates from './TaskGroupProposalTemplates';

export interface TaskGroupFormProps {
  isOpen: boolean;
  taskGroup?: TaskGroup | null;
  handleClose: () => void;
  handleSubmit: (taskGroup: TaskGroup) => void;
  handleDuplicate: (taskGroup: TaskGroup, includeAllTasks: boolean) => void;
  handleTestVersion: (taskGroupId: string) => void;
  handleDeleteTestVersion: (taskGroupId: string) => void;
  handleApproveTestVersion: (taskGroupId: string) => void;
  taskGroupLoading?: boolean;
  taskGroupError?: ApolloError;
}

const newTaskGroupDefaults: TaskGroup = {
  businessOutcome: '',
  businessOutcomeHelp: '',
  executiveSummaryDrivers: '',
  executiveSummaryDriversHelp: '',
  executiveSummaryOverview: '',
  executiveSummaryOverviewHelp: '',
  fixedFee: false,
  id: '',
  itemsProvidedToCustomers: [],
  name: '',
  order: '',
  outcomeIncluded: false,
  outcomeProjectScope: '',
  outcomeProjectScopePhases: '',
  outcomeProjectScopePhasesHelp: '',
  outcomeSowFooter: '',
  outcomeSowHeader: '',
  outcomeSummary: '',
  practice: '',
  published: false,
  riskPercent: '',
  sowScopeFooter: '',
  sowScopeHeader: '',
  taskGroupTeam: [],
  technicalOutcome: '',
  technicalOutcomeHelp: '',
  version: 1,
  executiveSummaryOverviewIncluded: true,
  executiveSummaryDriversIncluded: true,
  businessOutcomeIncluded: true,
  technicalOutcomeIncluded: true,
  outcomeProjectScopeIncluded: true,
  outcomeProjectScopePhasesIncluded: true,
  proposalContent: '',
  status: TASK_STATUS.IN_TESTING,
};

export function TaskGroupForm({
  isOpen,
  taskGroup,
  handleClose,
  handleSubmit,
  handleDuplicate,
  handleTestVersion,
  handleDeleteTestVersion,
  handleApproveTestVersion,
  taskGroupLoading,
  taskGroupError,
}: TaskGroupFormProps) {
  const [editor, setEditor] = React.useState(null);
  const [taskSub, setTaskSub] = React.useState(false);
  const [isSowSection, setIsSowSection] = React.useState(false);
  const [isDynamicTableForSite, setIsDynamicTableForSite] = React.useState(false);
  const [includeAllTasks, setIncludeAllTasks]= React.useState(false);
  const alertState = useAlertsState();
  const [rowsTeam, setRowsTeam] = useState([]);
  const [rowsOwner, setRowsOwner] = useState([]);
  const [rowsCoOwners, setRowsCoOwners] = useState([]);
  const [rowsTesters, setRowsTesters] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(taskGroup.version);
  const { validateTaskGroup } = useValidateTaskGroup();
  const [errorArray, setErrorArray] = useState([]);
  const taskGroupFormRef = useRef(null);

  const [error, setError] = React.useState('');
  const [options, setOptions] = useState([
    { label: 'CSOW', key: '1' },
    { label: 'Services Proposal', key: '2' }
  ]);

  const {
    isOpen: taskOpen,
    handleClose: handleTaskClose,
    handleOpen: handleTaskOpen,
  } = useOpenState();

  const {
    isOpen: siteTableOpen,
    handleClose: handleSiteTableClose,
    handleOpen: handleSiteTableOpen,
  } = useOpenState();

  const taskModalState = async (
    editor,
    taskSub: boolean,
    isSowSection: boolean
  ) => {
    setEditor(editor);
    setTaskSub(taskSub);
    setIsSowSection(isSowSection);
    handleTaskOpen();
  };

  const siteModalState = async (editor) => {
    setEditor(editor);
    handleSiteTableOpen();
  };

  const { customEditorOptions } = useCustomEditorOptions(taskModalState, siteModalState);
  
  const [formData, setFormData] = useState(taskGroup.id ? taskGroup : newTaskGroupDefaults);
  const {
    currentTaskGroup,
  } = useAdminTaskGroupDetail(taskGroup.inTestingTaskGroupId || taskGroup.originalTaskGroupId)
  const {
    taskGroup: currentTaskGroupVersion,
    loading: currentTaskGroupVersionLoading,
  } = useAdminTaskGroupVersion(taskGroup.id, Number(selectedVersion ?? 1));
  const { versionList } = useAdminTaskGroupVersionList(taskGroup.id);

  const setTaskGroupAdmins=(taskGroup: TaskGroup)=> {
    setRowsTeam(mapAdminUsers(taskGroup.taskGroupTeam));
    setRowsOwner(mapAdminUsers(taskGroup.owner));
    setRowsCoOwners(mapAdminUsers(taskGroup.coOwners));
    setRowsTesters(mapAdminUsers(taskGroup.testers));
  }

  useEffect(() => {
    if (taskGroup.id) {
      setFormData({
        ...taskGroup,
      });
      setTaskGroupAdmins(taskGroup);
    }
  }, [taskGroup.id]);

  useEffect(() => {
    if (currentTaskGroup.status && taskGroup.status !== currentTaskGroup.status) {
      currentTaskGroup.status = currentTaskGroup.status ||
        (currentTaskGroup.originalTaskGroupId ? TASK_STATUS.IN_TESTING : TASK_STATUS.PUBLISHED );
      const selectedTaskGroup = currentTaskGroup.status === formData.status ? cloneDeep(currentTaskGroup) : taskGroup;
      if (selectedTaskGroup.id) {
        setFormData({
          ...selectedTaskGroup,
        });
        setTaskGroupAdmins(selectedTaskGroup);
      }
    }
  }, [formData.status]);

  useEffect(() => {
    if (currentTaskGroupVersion?.id && currentTaskGroupVersion?.version !== taskGroup.version) {
      setFormData({
        ...currentTaskGroupVersion,
      });
      setTaskGroupAdmins(currentTaskGroupVersion);
    } else {
      setFormData({
        ...taskGroup,
      });
      setTaskGroupAdmins(taskGroup);
    }
  }, [currentTaskGroupVersion.version]);

  const mapAdminUsers = (users)=> {
    return users && users.map((p, i) => ({
      id: i,
      email: p.email,
      name: p.email,
      taskGroupId: taskGroup?.id,
    })) || [];
  };

  const {
    data: practices,
    loading: practiceLoading,
    error: practiceError,
    refetch,
  } = usePractices({});
  const practiceOptions =
    practices.practices.map((practice) => {
      return {
        id: practice.id,
        label: practice.name,
      };
    }) ?? [];

  const {
    data: serviceCategories,
    loading: serviceCategoriesLoading,
    error: serviceCategoriesError,
  } = useServiceCategories();
  const serviceCategoriesOptions =
    serviceCategories.map((sc) => {
      return {
        id: sc.name,
        label: sc.name,
      };
    }) ?? [];

  const {
    data: roles,
    loading: rolesLoading,
    error: rolesError,
  } = useRoles({ filters: { projectManager: true } });
  const roleOptions =
    roles.roles.map((role) => {
      return {
        id: role.id,
        label: `${role.name}  (${role.practiceName})`,
      };
    }) ?? [];

  const handleEditorChange = (params) => {
    setFormData((s) => ({
      ...s,
      ...params,
    }));
  };

  const debouncedHandleEditorChange = debounce(handleEditorChange, 1000);

  const handleTaskGroupTeamSave = (params, usersType: string) => {
    if (formData[usersType]?.some((item) => item.email === params.email)) {
      alertState.setAlert({
        type: ALERT_TYPE.MODAL,
        severity: ALERT_SEVERITY.SUCCESS,
        message: 'This user already exists!',
      });
      return;
    }
    const taskGroupTeam = cloneDeep(formData[usersType] || []);
    taskGroupTeam.unshift(params);

    setFormData((s) => ({
      ...s,
      [usersType]: taskGroupTeam,
    }));

    switch (usersType) {
      case 'taskGroupTeam':
        setRowsTeam(mapAdminUsers(taskGroupTeam));
        break;
      case 'owner':
        setRowsOwner(mapAdminUsers(taskGroupTeam));
        break;
      case 'coOwners':
        setRowsCoOwners(mapAdminUsers(taskGroupTeam));
        break;
      case 'testers':
        setRowsTesters(mapAdminUsers(taskGroupTeam));
        break;
    }
  };

  const handleTaskGroupTeamDelete = (userEmail: string, usersType: string) => {
    const taskGroupTeam = cloneDeep(formData[usersType] || []);
    const updatedGroupTeam = taskGroupTeam.filter((t) => t.email !== userEmail);

    setFormData((s) => ({
      ...s,
      [usersType]: updatedGroupTeam,
    }));
    switch (usersType) {
      case 'taskGroupTeam':
        setRowsTeam(mapAdminUsers(updatedGroupTeam));
        break;
      case 'owner':
        setRowsOwner(mapAdminUsers(updatedGroupTeam));
        break;
      case 'coOwners':
        setRowsCoOwners(mapAdminUsers(updatedGroupTeam));
        break;
      case 'testers':
        setRowsTesters(mapAdminUsers(updatedGroupTeam));
        break;
    }
  };

  const handleFormClose = () => {
    setFormData({});
    handleClose();
  };

  const handleSaveClick = async () => {
    const validationMessages = await validateTaskGroup(formData);
    if (validationMessages?.length > 0) {
      setErrorArray(validationMessages);
      taskGroupFormRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      return;
    }
      
    handleSubmit(formData);
    handleClose();   
  };

  const handleDuplicateConfirm = () => {
    handleDuplicate(formData, includeAllTasks);
    handleClose();
  };

  const handleTestVersionConfirm = () => {
    handleTestVersion(formData.id);
    handleClose();
  };

  const handleDeleteConfirm = ()=> {
    handleDeleteTestVersion(formData.id);
    handleClose();
  };

  const handleApprovalConfirm =() => {
    handleApproveTestVersion(formData.id);
    handleClose();
  };

  const buildOutcomeSection = (label: string, property: string, helpProperty: string, inludeProperty: string) => {
    return (
      <>
        <Grid item xs={12} sx={{ width: '100%' }}>
            <FormCheckbox
              name={inludeProperty}
              label={`Include ${label} ?`}
              setFormData={setFormData}
              formData={formData}
            />
        </Grid>
        {formData[inludeProperty] && 
        <>
          {helpProperty && <FormRichTextEditor
            name={helpProperty}
            customEditorOptions={{
              ...customEditorOptions,
              content_style: contentOutcomeStyle,
              body_class: 'projectSowScope',
              showNbspSpaceHandlerButtons: true
            }}
            value={taskGroup[helpProperty]}
            handleEditorChange={debouncedHandleEditorChange}
          />}
          <FormRichTextEditor
            name={property}
            label={label}
            customEditorOptions={{
              ...customEditorOptions,
              content_style: contentOutcomeStyle,
              body_class: 'projectSowScope',
              showNbspSpaceHandlerButtons: true
            }}
            value={taskGroup[property]}
            handleEditorChange={debouncedHandleEditorChange}
          />
        </>}
      </>
    );
  };

  const {
    isOpen: confirmDuplicate,
    handleClose: handleDuplicateConfirmClose,
    handleOpen: handleDuplicateConfirmOpen,
  } = useOpenState();

  const {
    isOpen: confirmShowChanges,
    handleClose: handleShowChangesConfirmClose,
    handleOpen: handleShowChangesConfirmOpen,
  } = useOpenState();

  const {
    isOpen: confirmTestVersion,
    handleClose: handleTestVersionConfirmClose,
    handleOpen: handleTestVersionConfirmOpen,
  } = useOpenState();

  const {
    isOpen: confirmApproval,
    handleClose: handleApprovalConfirmClose,
    handleOpen: handleApprovalConfirmOpen,
  } = useOpenState();

  const {
    isOpen: confirmDelete,
    handleClose: handleDeleteConfirmClose,
    handleOpen: handleDeleteConfirmOpen,
  } = useOpenState();
  const {
    isOpen: confirmRestore,
    handleClose: handleRestoreConfirmClose,
    handleOpen: handleRestoreConfirmOpen,
  } = useOpenState();

  const loading = taskGroupLoading || practiceLoading || serviceCategoriesLoading || currentTaskGroupVersionLoading;
  if (loading)
    return <Typography>Loading form</Typography>;

  if (taskGroupError || practiceError || serviceCategoriesError)
    return <Typography>Error loading form</Typography>;

  const contentOutcomeStyle = outcomeSow;

  const handleToggleChange = (e) => {
    setFormData({
      ...formData,
      status: e.target.checked ? TASK_STATUS.IN_TESTING : TASK_STATUS.PUBLISHED,
    })
  };

  const handleChangeVersion = (event)=> {
    setSelectedVersion(event.target.value);
  };

  const handleRestoreVersion = ()=> {
    handleSubmit({
      ...formData,
      id: taskGroup.id,
      version: taskGroup.version
    });
    handleClose();
  };
  const getTitle = ()=> {
    return (
      <>
      Task Group
      <Typography variant="caption" sx={{ ml: 1 }}>
        (Current Version: {taskGroup?.version})
      </Typography>
        {(formData?.originalTaskGroupId || formData?.inTestingTaskGroupId) ?
        ( <FormControlLabel
                    control={
                      <Switch
                        checked={formData.status === TASK_STATUS.IN_TESTING}
                        onChange={handleToggleChange}
                        data-testid="in-testing-toggle"
                      />
                    }
                    sx={{marginLeft: '3px'}}
                    label="In Testing Version"
                  />
          ) : versionList.length > 0 &&
          (
            <>
            <FormControlLabel
                sx={{marginLeft: '3px'}}
                label="Versions:"
                labelPlacement='start'
                control={
                  <Select
                    value={selectedVersion}
                    sx={{
                      ml: 1,
                      '& .css-lelgx8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
                        {
                          paddingTop: 0.5,
                          paddingBottom: 0.5,
                        },
                    }}
                      onChange={handleChangeVersion}
                    >
                      {[taskGroup.version, ...versionList].map((version) => (
                        <MenuItem key={version} value={version}>
                          {version}
                        </MenuItem>
                      ))}
                    </Select>
                }
              />
              {formData.version !== taskGroup.version &&
                <Button sx={{marginLeft: '10px'}} onClick={handleRestoreConfirmOpen}>Restore</Button>}
            </>
          )}
      </>
    );
  };

  const allowTestVersion = ()=>{
    if (formData.status === TASK_STATUS.PUBLISHED && !formData?.inTestingTaskGroupId) {
      return handleTestVersionConfirmOpen;
    }
  }

  const allowDuplicate = ()=>{
    if (formData?.status === TASK_STATUS.PUBLISHED ||
      (formData?.status === TASK_STATUS.IN_TESTING && !formData?.originalTaskGroupId)) {
      return handleDuplicateConfirmOpen;
    }
  }

  const allowApprove = ()=>{
    if (formData?.status === TASK_STATUS.IN_TESTING && formData?.originalTaskGroupId) {
      return handleApprovalConfirmOpen;
    }
  }

  const allowDelete = ()=>{
    if (formData?.status === TASK_STATUS.IN_TESTING && formData?.originalTaskGroupId) {
      return handleDeleteConfirmOpen;
    }
  }

  const allowShowChanges = () => {
    if (process.env.NX_PROJECT_ID === 'selline-dev' && formData?.status === TASK_STATUS.IN_TESTING) {
      return handleShowChangesConfirmOpen;
    }
  };
  return (
    <DialogModal
      fullWidth={true}
      isOpen={isOpen}
      handleClose={handleClose}
      title={getTitle()}
      action={getActionButtons(
        handleFormClose,
        handleSaveClick,
        allowDuplicate(),
        allowShowChanges(),
        allowTestVersion(),
        allowDelete(),
        allowApprove()
      )}
      maxWidthProp={'lg'}
      disableEnforceFocus={true}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="row"
        spacing={1}
        mt={1}
        sx={{ height: '90vh' }}
      >
        {!loading && (
          <Grid
            container
            alignItems="flex-start"
            justifyContent="flex-start"
            direction="column"
            spacing={1}
            sx={{ width: '100%' }}
            ref={taskGroupFormRef}
          >
            {errorArray && (
              <Box sx={{ padding: '5px' }}>
                {errorArray.map((error) => (
                  <Typography key={`${error}`} color="error" variant="h6">
                    {startCase(error) ?? 'Please fill out missing field(s)'}
                  </Typography>
                ))}
              </Box>
            )}
            <FormTextControlled
              name="name"
              required
              label="Task Group Name"
              setFormData={setFormData}
              formData={formData}
            />
            <AdminAccessTable
              {...{
                title: 'Task Group Admins',
                rows: rowsTeam,
                handleSave: handleTaskGroupTeamSave,
                handleDelete: handleTaskGroupTeamDelete,
                usersType: 'taskGroupTeam',
              }}
            />
            <AdminAccessTable
              {...{
                title: 'Task Group Owner',
                rows: rowsOwner,
                handleSave: handleTaskGroupTeamSave,
                handleDelete: handleTaskGroupTeamDelete,
                solo: true,
                usersType: 'owner',
              }}
            />
            <AdminAccessTable
              {...{
                title: 'Task Group Co-Owners',
                rows: rowsCoOwners,
                handleSave: handleTaskGroupTeamSave,
                handleDelete: handleTaskGroupTeamDelete,
                usersType: 'coOwners',
              }}
            />
            <AdminAccessTable
              {...{
                title: 'Task Group Testers',
                rows: rowsTesters,
                handleSave: handleTaskGroupTeamSave,
                handleDelete: handleTaskGroupTeamDelete,
                usersType: 'testers',
              }}
            />
            <FormAutocomplete
              name="practice"
              required
              label="Practice"
              options={practiceOptions}
              setFormData={setFormData}
              formData={formData}
            />
            <FormText
              name="bidAssuranceUrl"
              label="Bid Assurance URL"
              setFormData={setFormData}
              formData={formData}
            />
            <FormText
              name="bidAssuranceGroup"
              label="Bid Assurance Group"
              setFormData={setFormData}
              formData={formData}
            />
            <FormCheckbox
              name="published"
              label="Published?"
              setFormData={setFormData}
              formData={formData}
            />
            <FormCheckbox
              name="fixedFee"
              label="Fixed?"
              setFormData={setFormData}
              formData={formData}
            />
            {formData.fixedFee && (
              <>
                <FormText
                  name="riskPercent"
                  label="Risk Percent"
                  setFormData={setFormData}
                  formData={formData}
                  disabled={formData.fixedFeeSellPrice ? true : false}
                />
                <FormText
                  name="fixedFeeSellPrice"
                  label="Fixed Fee Sell Price"
                  setFormData={setFormData}
                  formData={formData}
                  disabled={formData.riskPercent ? true : false}
                />
              </>
            )}

            <FormCheckbox
              name="setPm"
              label="Set PM?"
              setFormData={setFormData}
              formData={formData}
            />
            {formData.setPm && (
              <>
                <FormAutocomplete
                  name="pmRole"
                  label="PM Role"
                  options={roleOptions}
                  setFormData={setFormData}
                  formData={formData}
                />
                <FormText
                  type="number"
                  name="pmPercent"
                  label="PM Percent"
                  setFormData={setFormData}
                  formData={formData}
                />
                <FormText
                  type="number"
                  name="minPmHours"
                  label="Min PM Hours"
                  setFormData={setFormData}
                  formData={formData}
                />
              </>
            )}
            <FormAutocomplete
              name="agiloftServiceCategory"
              label="Agiloft Service Category"
              options={serviceCategoriesOptions}
              setFormData={setFormData}
              formData={formData}
              freeSolo={true}
            />
            <FormText
              name="agiloftDefaultPrintTemplate"
              label="Agiloft Default Print Template"
              setFormData={setFormData}
              formData={formData}
            />
            <FormMultiSelectAutocomplete
              name="agiloftPrintTemplateTypeOptions"
              label="Agiloft Print Template Type Options"
              options={options}
              formData={formData}
              setFormData={setFormData}
            />
            <FormText
              name="agiloftScopeType"
              label="Agiloft Scope Type"
              setFormData={setFormData}
              formData={formData}
            />
            <FormText
              name="agiloftManagedScopeType"
              label="Agiloft Managed Scope Type"
              setFormData={setFormData}
              formData={formData}
            />
            <FormText
              name="agiloftBlendedScopeType"
              label="Agiloft Blended Scope Type"
              setFormData={setFormData}
              formData={formData}
            />
            <FormText
              name="agiloftOutcomeScopeType"
              label="Agiloft Outcome Scope Type"
              setFormData={setFormData}
              formData={formData}
            />
            <FormCheckbox
              name="outcomeIncluded"
              label="Include Outcome Based SOW?"
              setFormData={setFormData}
              formData={formData}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Stack
                alignContent={'center'}
                sx={{
                  width: `${proposalMaxWidth}`,
                }}
              >
                <FormRichTextEditor
                  name="proposalContent"
                  label="Proposal Content"
                  customEditorOptions={{
                    ...customEditorOptions,
                    content_style: detailedSow,
                    body_class: 'projectSowScope',
                    showNbspSpaceHandlerButtons: true,
                  }}
                  value={formData.proposalContent}
                  handleEditorChange={debouncedHandleEditorChange}
                />
              </Stack>
            </Box>
            <FormRichTextEditor
              name="sowScopeHeader"
              label="Sow Scope Header"
              customEditorOptions={{
                ...customEditorOptions,
                content_style: detailedSow,
                body_class: 'projectSowScope',
                showNbspSpaceHandlerButtons: true,
              }}
              value={formData.sowScopeHeader}
              handleEditorChange={debouncedHandleEditorChange}
            />
            <FormRichTextEditor
              name="sowScopeFooter"
              label="Sow Scope Footer"
              customEditorOptions={{
                ...customEditorOptions,
                content_style: detailedSow,
                body_class: 'projectSowScope',
                showNbspSpaceHandlerButtons: true,
              }}
              value={formData.sowScopeFooter}
              handleEditorChange={debouncedHandleEditorChange}
            />
            <FormRichTextEditor
              name="agiloftRecurringServicesFees"
              label="Agiloft Recurring Services Fees"
              customEditorOptions={{
                ...customEditorOptions,
                content_style: detailedSow,
                body_class: 'projectSowScope',
                showNbspSpaceHandlerButtons: true,
              }}
              value={formData.agiloftRecurringServicesFees}
              handleEditorChange={debouncedHandleEditorChange}
            />
            <FormRichTextEditor
              name="agiloftClauses"
              label="Agiloft Clauses"
              customEditorOptions={{
                ...customEditorOptions,
                content_style: detailedSow,
                body_class: 'projectSowScope',
                showNbspSpaceHandlerButtons: true,
              }}
              value={formData.agiloftClauses}
              handleEditorChange={debouncedHandleEditorChange}
            />
            <FormRichTextEditor
              name="agiloftExhibits"
              label="Agiloft Exhibits"
              customEditorOptions={{
                ...customEditorOptions,
                content_style: detailedSow,
                body_class: 'projectSowScope',
                showNbspSpaceHandlerButtons: true,
              }}
              value={formData.agiloftExhibits}
              handleEditorChange={debouncedHandleEditorChange}
            />

            {formData.outcomeIncluded && (
              <>
                <FormRichTextEditor
                  name="outcomeSowHeader"
                  label="Outcome Sow Header"
                  customEditorOptions={{
                    ...customEditorOptions,
                    content_style: contentOutcomeStyle,
                    body_class: 'projectSowScope',
                  }}
                  value={formData.outcomeSowHeader}
                  handleEditorChange={debouncedHandleEditorChange}
                />
                <FormRichTextEditor
                  name="outcomeSowFooter"
                  label="Outcome Sow Footer"
                  customEditorOptions={{
                    ...customEditorOptions,
                    content_style: contentOutcomeStyle,
                    body_class: 'projectSowScope',
                    showNbspSpaceHandlerButtons: true,
                  }}
                  value={formData.outcomeSowFooter}
                  handleEditorChange={debouncedHandleEditorChange}
                />
                {buildOutcomeSection(
                  'Executive Summary Overview',
                  'executiveSummaryOverview',
                  'executiveSummaryOverviewHelp',
                  'executiveSummaryOverviewIncluded'
                )}
                {buildOutcomeSection(
                  'Executive Summary Drivers',
                  'executiveSummaryDrivers',
                  'executiveSummaryDriversHelp',
                  'executiveSummaryDriversIncluded'
                )}
                {buildOutcomeSection(
                  'Business Outcome',
                  'businessOutcome',
                  'businessOutcomeHelp',
                  'businessOutcomeIncluded'
                )}
                {buildOutcomeSection(
                  'Technical Outcome',
                  'technicalOutcome',
                  'technicalOutcomeHelp',
                  'technicalOutcomeIncluded'
                )}
                {buildOutcomeSection(
                  'Outcome Project Scope',
                  'outcomeProjectScope',
                  null,
                  'outcomeProjectScopeIncluded'
                )}
                {buildOutcomeSection(
                  'Outcome Project Scope Phases',
                  'outcomeProjectScopePhases',
                  'outcomeProjectScopePhasesHelp',
                  'outcomeProjectScopePhasesIncluded'
                )}
              </>
            )}
            {!taskGroupLoading ? (
              <>
                <ItemsProvidedToCustomers
                  currentTaskGroup={formData}
                  handleEditorChange={handleEditorChange}
                />
                <TaskGroupSowSections
                  currentTaskGroup={formData}
                  handleEditorChange={handleEditorChange}
                />
                <TaskGroupProposalTemplates
                  currentTaskGroup={formData}
                  handleEditorChange={handleEditorChange}
                />
              </>
            ) : (
              ''
            )}

            <SiteTableModal
              {...{
                isOpen: siteTableOpen,
                handleClose: handleSiteTableClose,
                handleOpen: handleSiteTableOpen,
                editor: editor,
              }}
            />
            <TaskModal
              {...{
                isOpen: taskOpen,
                handleClose: handleTaskClose,
                handleOpen: handleTaskOpen,
                editor: editor,
                taskSub: taskSub,
                isSowSection,
              }}
            />
          </Grid>
        )}
      </Grid>
      {confirmDuplicate && (
        <DialogConfirm
          title="Duplicate?"
          isOpen={confirmDuplicate}
          handleClose={handleDuplicateConfirmClose}
          handleYes={handleDuplicateConfirm}
        >
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name="includeAllTasks"
                  checked={includeAllTasks}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setIncludeAllTasks(Boolean(e.target.checked))
                  }
                />
              }
              label={'Would you like to clone all tasks as well?'}
            />
          </FormGroup>
        </DialogConfirm>
      )}
      {process.env.NX_PROJECT_ID === 'selline-dev' && confirmShowChanges && (
        <TaskGroupComparisonTable
          {...{
            originalTaskGroup: taskGroup,
            changedTaskGroup: formData,
            roleOptions: roleOptions,
            confirmShowChanges: confirmShowChanges,
            handleShowChangesConfirmClose: handleShowChangesConfirmClose,
          }}
        />
      )}
      {confirmTestVersion && (
        <DialogConfirm
          title="Create Test Version?"
          isOpen={confirmTestVersion}
          handleClose={handleTestVersionConfirmClose}
          handleYes={handleTestVersionConfirm}
        >
          <Typography>
            This action will create test version of this task group including
            all tasks and sow sections. Are you sure ?
          </Typography>
        </DialogConfirm>
      )}
      {confirmDelete && (
        <DialogConfirm
          title="Delete Test Version?"
          isOpen={confirmDelete}
          handleClose={handleDeleteConfirmClose}
          handleYes={handleDeleteConfirm}
        >
          <Typography>
            This action will delete test version of this task group including
            all tasks and sow sections. Are you sure ?
          </Typography>
        </DialogConfirm>
      )}
      {confirmApproval && (
        <DialogConfirm
          title="Approve Test Version?"
          isOpen={confirmApproval}
          handleClose={handleApprovalConfirmClose}
          handleYes={handleApprovalConfirm}
        >
          <Typography>
            This action will replace published version of this task group
            including all tasks and sow sections. Are you sure ?
          </Typography>
        </DialogConfirm>
      )}
      {confirmRestore && (
        <DialogConfirm
          title="Restore?"
          isOpen={confirmRestore}
          handleClose={handleRestoreConfirmClose}
          handleYes={handleRestoreVersion}
        >
          <Typography variant="caption" sx={{ ml: 1 }}>
            Would you like to restore this task group version?
          </Typography>
        </DialogConfirm>
      )}
    </DialogModal>
  );
}

import { gql } from '@apollo/client';

export const UPDATE_PROJECT_TEAM_MEMBER_MUTATION = gql`
  mutation updateProjectTeamMemberMutation(
    $projectId: ID!
    $projectTeam: ProjectTeamInput!
  ) {
    updateProjectTeamMember(projectId: $projectId, projectTeam: $projectTeam) {
      success
    }
  }
`;

export default UPDATE_PROJECT_TEAM_MEMBER_MUTATION;

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { SkillSummary } from '@cdw-selline/common/types';
import { GET_SKILL_SUMMARY_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from '../..';

export const useSkillSummary = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { projectItemId } = useParams<{ projectItemId: string }>();

  const { loading, error, data } = useQuery<{
    getSkillSummaryByEstimatorId: SkillSummary;
  }>(GET_SKILL_SUMMARY_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: { id: projectItemId },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Skill Summary!'),
  });

  return {
    skillSummary: data?.getSkillSummaryByEstimatorId ?? {},
    error,
    loading,
  };
};

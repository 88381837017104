import { CDWFallback } from '@cdw-selline/ui/components';
import { useOpenState, useProjectComments } from '@cdw-selline/ui/hooks';
import {
  Backdrop,
  Button,
  Box,
  Grid,
  IconButton,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputAdornment,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import React, { Fragment, useEffect, useState } from 'react';
import { ProjectComment } from '@cdw-selline/common/types';
import { formatDateDistanceToNow } from '@cdw-selline/ui/helpers';
import { DialogConfirm } from '@cdw-selline/ui/components';

export interface ProjectCommentsProps {
  projectId: string;
}

export const ProjectComments = ({ projectId }: ProjectCommentsProps) => {
  const {
    projectComments,
    projectCommentsLoading,
    projectCommentsError,
    handleAddProjectComment,
    handleEditProjectComment,
    handleRemoveProjectComment,
  } = useProjectComments(projectId);

  const {
    isOpen: deleteDialogConfirmOpen,
    handleClose: handleDeleteDialogConfirmClose,
    handleOpen: handleDeleteDialogConfirmOpen,
  } = useOpenState();

  const [removeCommentId, setRemoveCommentId] = useState<string | null>(null);

  const [commentText, setCommentText] = useState('');

  const handleNewCommentSaveClick = () => {
    const comment: ProjectComment = {
      comment: commentText,
    };
    if (comment.comment) {
      handleAddProjectComment(projectId, comment);
    }
    handleNewCommentCancelClick();
  };

  const handleNewCommentCancelClick = () => {
    setCommentText('');
  };

  const handleDeleteDialogClose = () => {
    setRemoveCommentId(null);
    handleDeleteDialogConfirmClose();
  };

  const handleDialogDelete = (commentId: string) => {
    if (removeCommentId) {
      handleRemoveProjectComment(projectId, removeCommentId);
      handleDeleteDialogClose();
    }
  };

  const handleRemoveCommentClick = (
    event: React.MouseEvent,
    commentId: string
  ) => {
    event.stopPropagation();
    setRemoveCommentId(commentId);
    handleDeleteDialogConfirmOpen();
  };

  if (projectCommentsError)
    return (
      <Typography color="error">
        {' '}
        Error loading form! {projectCommentsError?.message}
      </Typography>
    );

  if (projectCommentsLoading)
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
        open
      >
        <CDWFallback />
      </Backdrop>
    );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sx={{ maxHeight: '50vh', overflow: 'auto' }}>
        <List>
          {projectComments.map((comment: ProjectComment) => (
            <>
              <ListItem>
                <ListItemText>
                  <Grid container>
                    <Grid item xs={12} sx={{ height: '1em' }}>
                      <Grid container spacing={0}>
                        <Grid item xs={11}>
                          <Typography variant="body2">
                            {comment.createdBy}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <DeleteIcon
                            sx={{ ml: 10, cursor: 'pointer', height: '1em' }}
                            onClick={(event) =>
                              handleRemoveCommentClick(event, comment.id)
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        {formatDateDistanceToNow(comment.createdAt)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">{comment.comment}</Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <Divider variant="inset" component="li" sx={{ marginLeft: 0 }} />
            </>
          ))}
        </List>
      </Grid>
      <Grid item xs={12}>
        <TextField
          required={true}
          name=""
          onChange={(e) => setCommentText(e.target.value)}
          value={commentText}
          label="Write a comment..."
          multiline={true}
          placeholder="Write a comment..."
          style={{ width: '100%' }}
        />

        <Grid container mt={1} mb={2} justifyContent="flex-end">
          <Button onClick={handleNewCommentSaveClick}>Add Comment</Button>
          <Button onClick={handleNewCommentCancelClick}>Cancel</Button>
        </Grid>
      </Grid>
      <DialogConfirm
        title="Remove Comment?"
        isOpen={deleteDialogConfirmOpen}
        handleClose={handleDeleteDialogClose}
        handleYes={handleDialogDelete}
      />
    </Grid>
  );
};

import {
  Box,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import React from 'react';

export interface DrawerRightProps {
  isOpen: boolean;
  handleDrawerClose: () => void;
  title: string;
  children: JSX.Element | JSX.Element[];
  loading?: boolean;
}

export function DrawerRight({
  isOpen,
  handleDrawerClose,
  title,
  children,
  loading,
}: DrawerRightProps) {
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleDrawerClose}
      disableScrollLock={true}
    >
      <Box
        sx={{
          width: 400,
          marginTop: '5em',
        }}
        role="presentation"
      >
        <Box
          sx={{
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box
            sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}
          >
            <Box>
              <Typography variant="h6">{title}</Typography>
            </Box>
            <Box
              onClick={handleDrawerClose}
              sx={{
                justifyContent: 'flex-end',
                display: 'flex',
                right: 5,
                position: 'absolute',
              }}
            >
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="close drawer"
                onClick={handleDrawerClose}
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Divider variant="fullWidth" sx={{ color: '#444', mt: '1em' }} />
            {loading ? <CircularProgress /> : children}
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}

export default DrawerRight;

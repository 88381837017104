import { useQuery } from '@apollo/client';
import {
  GET_ESTIMATOR_SITES_BY_PROJECT_ITEM_ID_QUERY,
  GET_ESTIMATOR_TOTALS_BY_ITEM_ID,
  GET_PROJECT_QUERY,
  GET_ACTIVE_SITES_BY_PROJECT_ITEM_ID_QUERY,
} from '@cdw-selline/ui/queries';

import { useApolloErrors } from '../useApolloErrors';

export const useProjectSitesAndTasks = (
  projectItemId: string,
  { skip = false } = {}
) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery(
    GET_ESTIMATOR_SITES_BY_PROJECT_ITEM_ID_QUERY,
    {
      fetchPolicy: 'network-only', // Used for first execution
      nextFetchPolicy: 'cache-first', // Used for subsequent executions
      variables: {
        projectItemId: projectItemId,
      },
      skip: !projectItemId || skip,
      onError: (error) =>
        handleErrorResponse(
          error,
          'Failed to fetch Estimator Sites and Tasks!'
        ),
    }
  );

  return {
    refetch,
    sites: data?.getEstimatorSitesByProjectItemId ?? [],
    sitesLoading: loading,
    sitesError: error,
  };
};

export const useActiveSitesAndTasks = (projectItemId: string) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery(
    GET_ACTIVE_SITES_BY_PROJECT_ITEM_ID_QUERY,
    {
      fetchPolicy: 'network-only', // Used for first execution
      nextFetchPolicy: 'cache-first', // Used for subsequent executions
      variables: {
        projectItemId,
      },
      skip: !projectItemId,
      onError: (error) =>
        handleErrorResponse(
          error,
          'Failed to fetch Active Estimator Sites and Tasks!'
        ),
    }
  );

  return {
    refetch,
    activeSiteTasks: data?.getActiveSitesAndTasksByProjectItemId ?? [],
    sitesLoading: loading,
    sitesError: error,
  };
};

export const useProjectSitesAndTasksTotals = (projectId: string) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch, client } = useQuery(
    GET_ESTIMATOR_TOTALS_BY_ITEM_ID,
    {
      fetchPolicy: 'no-cache',
      variables: {
        itemId: projectId,
      },
      // onError: (error) =>
      //   handleErrorResponse(error, 'Failed to fetch Project Totals!'),
      onCompleted: () => {
        client.refetchQueries({
          include: [GET_PROJECT_QUERY],
        });
      },
    }
  );

  return {
    refetch,
    error,
    loading,
    estimateTotals: {
      serviceEstimate: data?.getEstimatorTotalsByItemId?.serviceEstimate ?? 0,
      taskHours: data?.getEstimatorTotalsByItemId?.taskHours ?? 0,
      pmHours: data?.getEstimatorTotalsByItemId?.pmHours ?? 0,
      managedServices: data?.getEstimatorTotalsByItemId?.managedServices ?? 0,
      cdwCost: data?.getEstimatorTotalsByItemId?.cdwCost ?? 0,
      grossProfit: data?.getEstimatorTotalsByItemId?.grossProfit ?? 0,
      onetimeService: data?.getEstimatorTotalsByItemId?.onetimeService ?? 0,
      isMinPmMet: data?.getEstimatorTotalsByItemId?.isMinPmMet ?? true,
      minPmHours: data?.getEstimatorTotalsByItemId?.minPmHours ?? 0,
    },
  };
};

import { gql } from '@apollo/client';

export const UPDATE_LEAD_MUTATION = gql`
  mutation UpdateLeadMutation($updateLeadParams: UpdateLeadInput!) {
    updateLead(params: $updateLeadParams) {
      success
    }
  }
`;

export default UPDATE_LEAD_MUTATION;

// Variables
// "updateLeadParams": {
//   "id": "uuid",
//   ...
// }

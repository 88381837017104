import { gql } from '@apollo/client';

export const GET_PROJECT_ITEM_BY_ID = gql`
  query GetProjectItem($getProjectItemId: ID!) {
    getProjectItemByTaskId(projectId: $getProjectItemId) {
      vendor
      type
      terms
      quantity
      projectId
      price
      name
      mrrPrice
      mrrCost
      includeInSow
      includeInProposal
      includeInProjectTotal
      id
      description
      createdOn
      createdBy
      cost
      billingyCycle
      ciscoEA
      hwsw
      numberOfPhoneSeatsAgents
      primaryBOMVendor
      primaryTech
      product
      servicesTotal
      solution
      solutionType
    }
  }
`;

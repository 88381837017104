import { ApolloError } from '@apollo/client';
import { SowSection } from '@cdw-selline/common/types';
import { getDisplayValue } from '@cdw-selline/ui/helpers';
import {
  useAdminSowSectionVersion,
  useAdminSowSectionVersionList,
  useCustomEditorOptions,
  useOpenState,
  useSowSectionById,
  useTaskGroups,
  useTasks,
} from '@cdw-selline/ui/hooks';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { cloneDeep, debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { AdminAccessTable } from '../../admin-access-table/AdminAccessTable';
import { DialogConfirm } from '../../dialog-confirm/DialogConfirm';
import DialogModal from '../../dialog-modal/DialogModal';
import {
  FormCheckbox,
  FormRichTextEditor,
  FormTextControlled,
  getActionButtons,
} from '../../formHelperFunctions';
import { TaskModal } from '../../project-sow/sow-modal/TaskModal';

import { TASK_STATUS } from '@cdw-selline/ui/constants';
import detailedSow from '../../project-sow/detailed-sow.str.css';
import EditTaskForm from './EditTaskForm';
import EditTaskGroupForm from './EditTaskGroupForm';
import SiteTableModal from '../../project-sow/sitetable-modal/SiteTableModal';

export interface SowSectionFormProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (sowSection: SowSection) => void;
  sowSection?: SowSection | null;
  sowSectionLoading?: boolean;
  sowSectionError?: ApolloError;
}

const newSowSectionDefaults: SowSection = {
  assumptions: '',
  assumptionsGlobal: false,
  customerResponsibilities: '',
  customerResponsibilitiesGlobal: false,
  help: '',
  helpGlobal: '',
  highLevel: '',
  name: '',
  outOfScope: '',
  outOfScopeGlobal: false,
  outcomeUnit: '',
  outcomeUnitDescription: '',
  scope: '',
  title: '',
  version: '',
  status: TASK_STATUS.PUBLISHED
};

export function SowSectionForm({
  isOpen,
  handleClose,
  handleSubmit,
  sowSection,
}: SowSectionFormProps) {
  const [sowSectionFormData, setSowSectionFormData] = useState(
    sowSection ?? newSowSectionDefaults
  );
  const [editor, setEditor] = React.useState(null);
  const [taskSub, setTaskSub] = React.useState(false);
  const [isSowSection, setIsSowSection] = React.useState(true);
  const [error, setError] = useState(false);
  const [rowsOwner, setRowsOwner] = useState([]);
  const [rowsCoOwners, setRowsCoOwners] = useState([]);
  const [rowsTesters, setRowsTesters] = useState([]);
  const alertState = useAlertsState();
  const [editTaskId, setEditTaskId] = useState<string | undefined>(undefined);
  const [editTaskGroupId, setEditTaskGroupId] = useState<string | undefined>(
    undefined
  );
  const [selectedVersion, setSelectedVersion] = useState(sowSection.version);
  const {
    isOpen: taskOpen,
    handleClose: handleTaskClose,
    handleOpen: handleTaskOpen,
  } = useOpenState();
  const { data: tasksData } = useTasks({
    filters: { sowSectionId: sowSection.id },
  });
  const { data: taskGroupsData } = useTaskGroups({
    filters: { sowSections: sowSection.id },
  });

  let {
    data: currentSowSection
  } = useSowSectionById(sowSection.inTestingSowSectionId || sowSection.originalSowSectionId);

  const {
    sowSection: currentSowSectionVersion,
    loading: currentSowSectionVersionLoading,
  } = useAdminSowSectionVersion(sowSection.id, Number(selectedVersion ?? 1));

  const { versionList } = useAdminSowSectionVersionList(sowSection.id);

  const {
    isOpen: confirmRestore,
    handleClose: handleRestoreConfirmClose,
    handleOpen: handleRestoreConfirmOpen,
  } = useOpenState();

  useEffect(() => {
    currentSowSection = {
      ...currentSowSection,
      status: currentSowSection.status || (currentSowSection.originalTaskId ? TASK_STATUS.IN_TESTING : TASK_STATUS.PUBLISHED)
    };
    const selectedSowSection = currentSowSection.status === sowSectionFormData.status ? cloneDeep(currentSowSection) : sowSection;
    if (selectedSowSection.id) {
      setSowSectionFormData(selectedSowSection);
      setRowsOwner(mapAdminUsers(selectedSowSection.owner));
      setRowsCoOwners(mapAdminUsers(selectedSowSection.coOwners));
      setRowsTesters(mapAdminUsers(selectedSowSection.testers));
    }
  }, [sowSectionFormData.status]);

  useEffect(() => {
    if (sowSection && sowSection.id) {
      sowSection = {
        ...sowSection,
        status: sowSection.status || (sowSection.originalSowSectionId ? TASK_STATUS.IN_TESTING: TASK_STATUS.PUBLISHED )
      };
      setSowSectionFormData(sowSection);
      setRowsOwner(mapAdminUsers(sowSection.owner));
      setRowsCoOwners(mapAdminUsers(sowSection.coOwners));
      setRowsTesters(mapAdminUsers(sowSection.testers));
    }
  }, [sowSection.id]);

  useEffect(() => {
    if (currentSowSectionVersion?.id && currentSowSectionVersion?.version !== sowSection.version) {
      setSowSectionFormData({
        ...currentSowSectionVersion,
      });
    } else {
      setSowSectionFormData({
        ...sowSection,
      });
    }
  }, [currentSowSectionVersion.id]);

  const sowSectionModalState = async (
    editor: unknown,
    isTaskSub: boolean,
    isSowSection: boolean
  ) => {
    setEditor(editor);
    setTaskSub(isTaskSub);
    setIsSowSection(isSowSection);
    handleTaskOpen();
  };

  const {
    isOpen: siteTableOpen,
    handleClose: handleSiteTableClose,
    handleOpen: handleSiteTableOpen,
  } = useOpenState();

  const siteModalState = async (editor) => {
    setEditor(editor);
    handleSiteTableOpen();
  };

  const { customEditorOptions } = useCustomEditorOptions(sowSectionModalState, siteModalState);

  const handleChangeVersion = (event)=> {
    setSelectedVersion(event.target.value);
  };

  const getTitle = () => {
    return (sowSectionFormData.id && <>
      Edit Sow Section
      <Typography variant="caption" sx={{ ml: 1 }}>
        (Version: {sowSectionFormData?.version})
      </Typography>
      <FormControlLabel
          sx={{marginLeft: '3px'}}
          label="Versions:"
          labelPlacement='start'
          control={
            <Select
              value={selectedVersion}
              sx={{
                ml: 1,
                '& .css-lelgx8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
                  {
                    paddingTop: 0.5,
                    paddingBottom: 0.5,
                  },
              }}
                onChange={handleChangeVersion}
              >
                {[sowSection.version, ...versionList].map((version) => (
                  <MenuItem key={version} value={version}>
                    {version}
                  </MenuItem>
                ))}
              </Select>
          }
        />
        {sowSectionFormData.version !== sowSection.version &&
          <Button sx={{marginLeft: '10px'}} onClick={handleRestoreConfirmOpen}>Restore</Button>}
    </>) || 'Add Sow Section';
  };

  const handleFormClose = () => {
    setSowSectionFormData({});
    handleClose();
  };

  const handleSaveClick = () => {
    if (sowSectionFormData.name) {
      handleSubmit(sowSectionFormData);
      handleClose();
    } else {
      setError(true);
    }
  };

  const handleDuplicateClick = () => {
    if (sowSectionFormData.name) {
      delete sowSectionFormData.id;
      handleSubmit(sowSectionFormData);
      handleClose();
    } else {
      setError(true);
    }
  };

  const handleEditorChange = (params) => {
    setSowSectionFormData((s) => ({
      ...s,
      ...params,
    }));
  };
  const debouncedHandleEditorChange = debounce(handleEditorChange, 1000);
  const contentStyle = detailedSow;

  const mapAdminUsers = (users)=> {
    return users && users.map((p, i) => ({
      id: i,
      email: p.email,
      name: p.email,
      sowSectionId: sowSection.id,
    })) || [];
  };

  const handleSowSectionTeamSave = (params, usersType: string) => {
    if (sowSectionFormData[usersType]?.some((item) => item.email === params.email)) {
      alertState.setAlert({
        type: ALERT_TYPE.MODAL,
        severity: ALERT_SEVERITY.SUCCESS,
        message: 'This user already exists!',
      });
      return;
    }
    const sowSectionTeam = cloneDeep(sowSectionFormData[usersType] || []);
    sowSectionTeam.unshift(params);

    setSowSectionFormData((s) => ({
      ...s,
      [usersType]: sowSectionTeam,
    }));

    switch (usersType) {
      case 'owner':
        setRowsOwner(mapAdminUsers(sowSectionTeam));
        break;
      case 'coOwners':
        setRowsCoOwners(mapAdminUsers(sowSectionTeam));
        break;
      case 'testers':
        setRowsTesters(mapAdminUsers(sowSectionTeam));
        break;
    }
  };

  const handleSowSectionTeamDelete = (userEmail: string, usersType: string) => {
    const sowSectionTeam = cloneDeep(sowSectionFormData[usersType] || []);
    const updatedSowSectionTeam = sowSectionTeam.filter((t) => t.email !== userEmail);

    setSowSectionFormData((s) => ({
      ...s,
      [usersType]: updatedSowSectionTeam,
    }));
    switch (usersType) {
      case 'owner':
        setRowsOwner(mapAdminUsers(updatedSowSectionTeam));
        break;
      case 'coOwners':
        setRowsCoOwners(mapAdminUsers(updatedSowSectionTeam));
        break;
      case 'testers':
        setRowsTesters(mapAdminUsers(updatedSowSectionTeam));
        break;
    }
  };

  const handleRestoreVersion = ()=> {
    handleSubmit({
      ...sowSectionFormData,
      id: sowSection.id,
      version: sowSection.version
    });
    handleClose();
  };

  return (
    <DialogModal
      fullWidth={true}
      isOpen={isOpen}
      handleClose={handleClose}
      title={getTitle()}
      action={getActionButtons(handleFormClose, handleSaveClick, handleDuplicateClick)}
      maxWidthProp={'lg'}
      disableEnforceFocus={true}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="row"
        spacing={1}
        mt={1}
        sx={{ height: '90vh' }}
        data-testid="sow-section-grid"
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="column"
          spacing={1}
          sx={{ width: '100%' }}
        ></Grid>
        {error && (
          <Box sx={{ padding: '15px' }}>
            <Typography color="error" variant="h6">
              Please add all required field values
            </Typography>
          </Box>
        )}
        <FormTextControlled
          name="name"
          label="Sow Section Name"
          required={true}
          setFormData={setSowSectionFormData}
          formData={sowSectionFormData}
        />
          <AdminAccessTable
            {...{
              title: 'Sow Section Owner',
              rows: rowsOwner,
              handleSave: handleSowSectionTeamSave,
              handleDelete: handleSowSectionTeamDelete,
              solo: true,
              usersType: 'owner',
            }}
          />
          <AdminAccessTable
            {...{
              title: 'Sow Section Co-Owners',
              rows: rowsCoOwners,
              handleSave: handleSowSectionTeamSave,
              handleDelete: handleSowSectionTeamDelete,
              usersType: 'coOwners',
            }}
          />
          <AdminAccessTable
            {...{
              title: 'Sow Section Testers',
              rows: rowsTesters,
              handleSave: handleSowSectionTeamSave,
              handleDelete: handleSowSectionTeamDelete,
              usersType: 'testers',
            }}
          />
        <FormCheckbox
          name="customerResponsibilitiesGlobal"
          label="Global Customer Responsibilities"
          setFormData={setSowSectionFormData}
          formData={sowSectionFormData}
        />
        <FormCheckbox
          name="assumptionsGlobal"
          label="Global Assumptions"
          setFormData={setSowSectionFormData}
          formData={sowSectionFormData}
        />
        <FormCheckbox
          name="outOfScopeGlobal"
          label="Global Out Of Scope"
          setFormData={setSowSectionFormData}
          formData={sowSectionFormData}
        />
        <FormTextControlled
          name="title"
          label="Title"
          setFormData={setSowSectionFormData}
          formData={sowSectionFormData}
        />
        <FormRichTextEditor
          name="help"
          label="Help Notes"
          customEditorOptions={{
            ...customEditorOptions,
            content_style: contentStyle,
            body_class: 'projectSowScope',
            showNbspSpaceHandlerButtons: true
          }}
          value={getDisplayValue(sowSectionFormData.help)}
          handleEditorChange={debouncedHandleEditorChange}
        />
        <FormRichTextEditor
          name="highLevel"
          label="High Level Scope Statement"
          customEditorOptions={{
            ...customEditorOptions,
            content_style: contentStyle,
            body_class: 'projectSowScope',
            showNbspSpaceHandlerButtons: true
          }}
          value={getDisplayValue(sowSectionFormData.highLevel)}
          handleEditorChange={debouncedHandleEditorChange}
        />
        <FormRichTextEditor
          name="scope"
          label="Scope"
          customEditorOptions={{
            ...customEditorOptions,
            content_style: contentStyle,
            body_class: 'projectSowScope',
            showNbspSpaceHandlerButtons: true
          }}
          value={getDisplayValue(sowSectionFormData.scope)}
          handleEditorChange={debouncedHandleEditorChange}
        />
        <FormRichTextEditor
          name="customerResponsibilities"
          label="Customer Responsibilities"
          customEditorOptions={{
            ...customEditorOptions,
            content_style: contentStyle,
            body_class: 'projectSowScope',
            showNbspSpaceHandlerButtons: true
          }}
          value={getDisplayValue(sowSectionFormData.customerResponsibilities)}
          handleEditorChange={debouncedHandleEditorChange}
        />
        <FormRichTextEditor
          name="assumptions"
          label="Assumptions"
          customEditorOptions={{
            ...customEditorOptions,
            content_style: contentStyle,
            body_class: 'projectSowScope',
            showNbspSpaceHandlerButtons: true
          }}
          value={getDisplayValue(sowSectionFormData.assumptions)}
          handleEditorChange={debouncedHandleEditorChange}
        />
        <FormRichTextEditor
          name="outOfScope"
          label="Out Of Scope"
          customEditorOptions={{
            ...customEditorOptions,
            content_style: contentStyle,
            body_class: 'projectSowScope',
            showNbspSpaceHandlerButtons: true
          }}
          value={getDisplayValue(sowSectionFormData.outOfScope)}
          handleEditorChange={debouncedHandleEditorChange}
        />
        {sowSection?.id && !!tasksData?.tasks?.length && (
          <Grid item xs={12} sx={{ width: '100%' }}>
            <Typography variant="h3" display="block" mt={2}>
              Tasks
            </Typography>
            {tasksData.tasks.map((row) => (
              <Box key={row.id}>
                <Button onClick={() => setEditTaskId(row.id)}>
                  {row.taskId} - {row.name}
                </Button>
              </Box>
            ))}
          </Grid>
        )}
        {sowSection?.id && !!taskGroupsData?.taskGroups?.length && (
          <Grid item xs={12} sx={{ width: '100%' }}>
            <Typography variant="h3" display="block" mt={2}>
              Task Groups
            </Typography>
            {taskGroupsData.taskGroups.map((row) => (
              <Box>
                <Button onClick={() => setEditTaskGroupId(row.id)}>
                  {row.id} - {row.name}
                </Button>
              </Box>
            ))}
          </Grid>
        )}
        {editTaskId && (
          <EditTaskForm taskId={editTaskId} setTaskId={setEditTaskId} />
        )}
        {editTaskGroupId && (
          <EditTaskGroupForm
            taskGroupId={editTaskGroupId}
            setTaskGroupId={setEditTaskGroupId}
          />
        )}
      {confirmRestore && (
        <DialogConfirm
          title="Restore?"
          isOpen={confirmRestore}
          handleClose={handleRestoreConfirmClose}
          handleYes={handleRestoreVersion}
        >
          <Typography variant="caption" sx={{ ml: 1 }}>
            Would you like to restore this task version?
          </Typography>
        </DialogConfirm>
      )}
      {taskOpen && <TaskModal
          {...{
            isOpen: taskOpen,
            handleClose: handleTaskClose,
            handleOpen: handleTaskOpen,
            editor: editor,
            taskSub: taskSub,
            isSowSection,
          }}
        />}

        <SiteTableModal
          {...{
            isOpen: siteTableOpen,
            handleClose: handleSiteTableClose,
            handleOpen: handleSiteTableOpen,
            editor: editor,
          }}
        />
      </Grid>
    </DialogModal>
  );
}

export default SowSectionForm;

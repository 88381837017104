import { gql } from '@apollo/client';

export const ADD_PRACTICE_MUTATION = gql`
  mutation addPracticeMutation($params: AdminPracticeInput) {
    addPractice(params: $params) {
      success
      id
    }
  }
`;

// VARIABLES
// {
//   "params": {}
// }

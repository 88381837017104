import { gql } from '@apollo/client';

export const REMOVE_PROJECT_PROPOSAL_MUTATION = gql`
  mutation RemoveProjectProposal($removeProjectProposalId: ID!) {
    removeProjectProposal(id: $removeProjectProposalId) {
      success
    }
  }
`;

/*
VARIABLES:
{
    removeProjectProposalId: "project-proposal-id-goes-here"
}
*/

/*
RETURNS: 
{
    data: {
        removeProjectProposal: {
            success: true
        }
    }
}
*/

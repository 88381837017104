import { gql } from '@apollo/client';

export const ADD_SCOPING_SESSION_MUTATION = gql`
  mutation Mutation($params: ScopingSessionInput!) {
    addScopingSession(params: $params) {
      id
      success
    }
  }
`;

import { gql } from '@apollo/client';

export const GET_ADMIN_SERVICE_PRACTICES = gql`
  query getServicePracticesQuery(
    $filters: MongoFilter
    $limit: Int
    $offset: Int
    $sort: MongoFilter
  ) {
    getServicePractices(
      filters: $filters
      limit: $limit
      offset: $offset
      sort: $sort
    ) {
      count(filters: $filters)
      servicePractices {
        id
        name
      }
    }
  }
`;
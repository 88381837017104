import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  DefaultMutationResponseWithId,
  ProjectItemEstimators,
} from '@cdw-selline/common/types';
import {
  ADD_PROJECT_ITEMS_ESTIMATOR_MUTATION,
  UPDATE_PROJECT_ITEMS_ESTIMATOR_MUTATION,
  GET_PROJECT_ITEM_ESTIMATORS_QUERY,
  GET_SERVICE_SUMMARY_QUERY,
  GET_PROJECT_QUERY,
  GET_INVESTMENT_SUMMARY_QUERY,
  GET_PROJECT_PRACTICES_RATES,
  COPY_PROJECT_ITEM_ESTIMATOR,
  UPDATE_PROJECT_ITEMS_ESTIMATOR_VARIABLES_MUTATION,
  GET_PSA_BREAKDOWN_QUERY,
  GET_SITE_BREAKDOWN_QUERY,
} from '@cdw-selline/ui/queries';

import { useApolloErrors } from '../';
import { useNavigate } from 'react-router-dom';

import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { useEstimatorCalculation } from './useEstimatorCalculation';

export const useProjectItemEstimator = () => {
  const alertState = useAlertsState();
  const { handleErrorResponse } = useApolloErrors();
  const { id, projectItemId } =
    useParams<{ id: string; projectItemId: string }>();
  const navigate = useNavigate();

  const {
    loading: getProjectItemEstimatorLoading,
    error: getProjectItemEstimatorError,
    data,
  } = useQuery(GET_PROJECT_ITEM_ESTIMATORS_QUERY, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    variables: {
      projectItemId,
    },
    skip: !projectItemId,
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Estimator Tasks!'),
  });

  const { updateProjectItemEstimatorCalculation } = useEstimatorCalculation();

  const [
    updateProjectItemEstimator,
    {
      loading: updateProjectItemEstimatorLoading,
      error: updateProjectItemEstimatorError,
      client,
    },
  ] = useMutation<{
    updateProjectItem: DefaultMutationResponseWithId;
  }>(UPDATE_PROJECT_ITEMS_ESTIMATOR_MUTATION, {
    refetchQueries: [
      GET_PROJECT_PRACTICES_RATES,
      GET_PROJECT_ITEM_ESTIMATORS_QUERY,
      GET_SERVICE_SUMMARY_QUERY,
      GET_INVESTMENT_SUMMARY_QUERY,
      GET_PSA_BREAKDOWN_QUERY,
      GET_SITE_BREAKDOWN_QUERY,
    ],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to updateProject Item Estimator'),
    onCompleted: () => {
      client.refetchQueries({
        include: [GET_PROJECT_QUERY],
      });
    },
  });

  const [
    updateProjectItemEstimatorVariables,
    {
      loading: updateEstimatorVariablesLoading,
      error: updateEstimatorError,
      client: estimatorVariablesClient,
    },
  ] = useMutation<{
    updateProjectItemEstimatorVariables: DefaultMutationResponseWithId;
  }>(UPDATE_PROJECT_ITEMS_ESTIMATOR_VARIABLES_MUTATION, {
    refetchQueries: [
      GET_PROJECT_PRACTICES_RATES,
      GET_PROJECT_ITEM_ESTIMATORS_QUERY,
    ],
    onError: (error) =>
      handleErrorResponse(
        error,
        'Failed to updateProject Item Estimator Variables'
      ),
    onCompleted: () => {
      estimatorVariablesClient.refetchQueries({
        include: [GET_PROJECT_QUERY],
      });

      updateProjectItemEstimatorCalculation({
        variables: {
          params: {
            projectItemId,
          },
        },
      });
    },
  });

  const [
    copyProjectItemEstimator,
    {
      loading: copyProjectItemEstimatorLoading,
      error: copyProjectItemEstimatorError,
    },
  ] = useMutation(COPY_PROJECT_ITEM_ESTIMATOR, {
    refetchQueries: [],
    onError: (error) =>
      handleErrorResponse(
        error,
        `Failed to copy project item estimator: ${error.message}`,
        ALERT_TYPE.MODAL
      ),
    onCompleted: (data) => {
      if (data.copyProjectItemEstimator.success) {
        navigate(
          `/project/${id}/estimator/${data.copyProjectItemEstimator.id}`
        );
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully copied Project Item estimator',
        });
      }
    },
  });

  const handleCopyProjectItemEstimator = async () => {
    copyProjectItemEstimator({
      variables: {
        projectItemId,
      },
    });
  };

  return {
    updateProjectItemEstimatorLoading,
    updateProjectItemEstimatorError,
    updateProjectItemEstimator,
    updateProjectItemEstimatorVariables,
    getProjectItemEstimatorLoading,
    getProjectItemEstimatorError,
    data:
      (data?.getProjectItemEstimatorByItemId as ProjectItemEstimators) ?? {},
    handleCopyProjectItemEstimator,
    copyProjectItemEstimatorLoading,
    copyProjectItemEstimatorError,
  };
};

export const useProjectItemEstimatorAddItem = () => {
  const alertState = useAlertsState();
  const { handleErrorResponse } = useApolloErrors();

  const [addProjectItemEstimator, { loading, error }] = useMutation<{
    addProjectItem: DefaultMutationResponseWithId;
  }>(ADD_PROJECT_ITEMS_ESTIMATOR_MUTATION, {
    onError: (error) =>
      handleErrorResponse(
        error,
        'Failed to create a new Project Item Estimator'
      ),
    onCompleted: (data) => {
      alertState.setAlert({
        type: ALERT_TYPE.SNACKBAR,
        severity: ALERT_SEVERITY.SUCCESS,
        message: 'Successfully Created New Project Item Estimator',
      });
    },
  });
  return {
    error,
    loading,
    addProjectItemEstimator,
  };
};

import React from 'react';
import { RmsGpAnalysisTableSites } from '@cdw-selline/common/types';
import {
  Box,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Paper,
} from '@mui/material';
import { RmsGPAnalysisLineItem } from './RmsGPAnalysisLineItem';
import { formatCurrency } from '@cdw-selline/ui/helpers';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

interface RmsGPAnalysisTableProps {
  items: RmsGpAnalysisTableSites[];
  totalMonthlyCharges: number;
  unitGPUpdate: (updatedTask) => void;
  isManagedServiceAdmin: boolean;
  theme: Theme;
  isReadOnly?: boolean;
}

export const RmsGPAnalysisTable = ({
  items,
  totalMonthlyCharges,
  unitGPUpdate,
  isManagedServiceAdmin,
  theme,
  isReadOnly,
}: RmsGPAnalysisTableProps) => {
  const useStyles = makeStyles({
    sectionHeader: {
      backgroundColor: theme.palette.primary.dark,
      padding: '5px',
      minHeight: '25px',
    },
    siteHeader: {
      backgroundColor: theme.palette.primary.dark,
      padding: '5px',
      textTransform: 'uppercase',
      border: '1px solid black',
    },
    border1: {
      border: '1px solid black',
      padding: '5px',
    },
    border2: {
      border: '2px solid black',
      padding: '5px',
    },
    category: {
      transformText: 'uppercase',
      fontWeight: 'bold',
    },
  });

  const classes = useStyles();
  const categoriesLength = items.length;

  return (
    <>
      <Box sx={{ mt: 2, border: '1px solid #000000', borderRadius: '5px' }}>
        <Box className={classes.sectionHeader}>
          <Typography variant="h6" color="#FFFFFF">
            Recurring Monthly Cost:
          </Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: 650,
            }}
            aria-label="monthy-cost-table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.border1}
                  sx={{ width: '40%' }}
                ></TableCell>
                <TableCell
                  className={classes.border1}
                  sx={{ width: '8%', textAlign: 'center' }}
                >
                  SKU
                </TableCell>
                <TableCell
                  className={classes.border1}
                  sx={{ width: '8%', textAlign: 'center' }}
                >
                  Unit Cost
                </TableCell>
                <TableCell
                  className={classes.border1}
                  sx={{ width: '7%', textAlign: 'center' }}
                >
                  Unit GP
                </TableCell>
                <TableCell
                  className={classes.border1}
                  sx={{ width: '7%', textAlign: 'center' }}
                >
                  Unit GP %
                </TableCell>
                <TableCell
                  className={classes.border1}
                  sx={{ width: '7%', textAlign: 'center' }}
                >
                  Unit Price
                </TableCell>
                <TableCell
                  className={classes.border1}
                  sx={{ width: '4%', textAlign: 'center' }}
                >
                  QTY
                </TableCell>
                <TableCell
                  className={classes.border1}
                  sx={{ width: '7%', textAlign: 'center' }}
                >
                  Total Cost
                </TableCell>
                <TableCell
                  className={classes.border1}
                  sx={{ width: '7%', textAlign: 'center' }}
                >
                  Total GP
                </TableCell>
                <TableCell
                  className={classes.border1}
                  sx={{ width: '7%', textAlign: 'center' }}
                >
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.border1} colSpan={10}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    MANAGED SERVICES
                  </Typography>
                </TableCell>
              </TableRow>
              {items.map((site, idx) => (
                <React.Fragment key={`${site.siteId}`}>
                  {categoriesLength > 1 && (
                    <TableRow sx={{ background: '#D3D3D3' }}>
                      <TableCell
                        className={classes.siteHeader}
                        colSpan={10}
                        align="left"
                      >
                        <Typography
                          variant="body1"
                          className={classes.category}
                          color="#FFFFFF"
                        >
                          {site.siteName}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {site.categories.map((category, idx) => (
                    <React.Fragment key={`${category.category}-${idx}`}>
                      <TableRow sx={{ background: '#D3D3D3' }}>
                        <TableCell
                          className={classes.border1}
                          colSpan={10}
                          align="left"
                        >
                          <Typography
                            variant="body1"
                            className={classes.category}
                          >
                            {category.category}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {category.lineItems.map((lineItem, idx) => (
                        <RmsGPAnalysisLineItem
                          key={`${lineItem.name}-${idx}`}
                          lineItem={lineItem}
                          unitGPUpdate={unitGPUpdate}
                          classes={classes}
                          isManagedServiceAdmin={isManagedServiceAdmin}
                          siteId={site.siteId}
                          isReadOnly={isReadOnly}
                        />
                      ))}
                    </React.Fragment>
                  ))}
                  {categoriesLength > 1 && (
                    <TableRow>
                      <TableCell
                        className={classes.border1}
                        colSpan={7}
                        align="left"
                      >
                        <Typography
                          variant="body1"
                          className={classes.category}
                        >
                          {`${site?.siteName ?? ''} - Total`}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={classes.border1}
                        align="right"
                        colSpan={3}
                      >
                        <Typography
                          variant="body1"
                          className={classes.category}
                        >
                          {formatCurrency(site?.totalSellPrice ?? 0)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ textAlign: 'right', mt: 1 }}>
            <Typography variant="h6">
              Total Monthly Charges: {formatCurrency(totalMonthlyCharges)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default RmsGPAnalysisTable;

import { gql } from '@apollo/client';

export const GET_LEAD_QUERY = gql`
  query getLead($getLeadId: ID!) {
    getLead(id: $getLeadId) {
      id
      customerId
      name
      status
      owner
      primarySa
      secondarySa
      primarySales
      secondarySales
      additionalInformation
      associatedProjects
    }
  }
`;

export default GET_LEAD_QUERY;

// Variables
// {"getLeadId": "uuid"}

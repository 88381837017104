import { useQuery } from '@apollo/client';
import { GetUsersResponse } from '@cdw-selline/common/types';
import { GET_USERS_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from '../useApolloErrors';

export const useUsers = (filters?) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getUsers: GetUsersResponse;
  }>(GET_USERS_QUERY, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    variables: { filters },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch project users!'),
  });

  return {
    data: data?.getUsers.users ?? { users: [], count: 0 },
    error,
    loading,
    refetch,
  };
};

export default useUsers;

import { useMutation } from '@apollo/client';
import { Task } from '@cdw-selline/common/types';
import {
  GET_ESTIMATOR_SUMMARY_TASKS_QUERY,
  GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY,
  UPDATE_ESTIMATOR_TASK_MUTATION,
  UPDATE_ESTIMATOR_TASKS_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { isArray, omit } from 'lodash';

import { useApolloErrors } from '..';

export const useUpdateEstimatorTask = (completionCallback?: () => void) => {
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();
  const omitAttributes = ['__typename', 'allowAdminEdit'];

  const [updateEstimatorTask, { client }] = useMutation(
    UPDATE_ESTIMATOR_TASK_MUTATION,
    {
      awaitRefetchQueries: true,
      onError: (error) => {
        alertState.setAlert({
          type: ALERT_TYPE.MODAL,
          severity: ALERT_SEVERITY.WARNING,
          message: error.message,
        });
      },
      onCompleted: (data) => {
        const isSummary = data.updateEstimatorTask.updatedTask.siteId.includes('summary');

        if (isSummary) {
          client.refetchQueries({
            include: [
              GET_ESTIMATOR_SUMMARY_TASKS_QUERY,
              GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY,
            ],
          });
        }

        if(!isSummary) {
          client.refetchQueries({
            include: [
              GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY,
            ],
          });
        }

        completionCallback();
      },
    }
  );

  const [updateEstimatorTasks] = useMutation(UPDATE_ESTIMATOR_TASKS_MUTATION, {
    refetchQueries: [
      GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY,
      GET_ESTIMATOR_SUMMARY_TASKS_QUERY,
    ],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to update Project Task!'),
    onCompleted: (data) => {
      if (data.updateEstimatorTasks.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully saved estimator task(s)',
        });
      }
      completionCallback && completionCallback();
    }
  });

  const handleTaskUpdate = (updatedTask: Task | Task[] | []) => {
    if (!isArray(updatedTask)) {
      updateEstimatorTask({
        variables: {
          params: omit(updatedTask, omitAttributes),
        },
      });
    }

    if (isArray(updatedTask) && updatedTask.length === 1) {
      updateEstimatorTask({
        variables: {
          params: omit(updatedTask[0], omitAttributes),
        },
      });
    }

    if (isArray(updatedTask) && updatedTask.length > 1) {
      updateEstimatorTasks({
        variables: {
          params: updatedTask.map(task=>omit(task, omitAttributes)),
        },
      });
    }
  };

  return {
    handleTaskUpdate,
    updateEstimatorTasks,
    updateTaskLoading: false,
    updateTaskError: false,
  };
};

import { useQuery } from '@apollo/client';
import { GET_ESTIMATOR_TASK_BY_ID_QUERY } from '@cdw-selline/ui/queries';
import { useApolloErrors } from '..';
import { TASK_COST_TYPES } from '@cdw-selline/ui/constants';
import { Task } from '@cdw-selline/common/types';

export const useEstimatorTaskById = (id: string) => {
  const { handleErrorResponse } = useApolloErrors();
  let estimatorTask = {} as Task;

  const { loading, error, data, refetch } = useQuery(
    GET_ESTIMATOR_TASK_BY_ID_QUERY,
    {
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
      skip: !id,
      onError: (error) => handleErrorResponse(error, 'Failed to fetch Task!'),
    }
  );

  if (data?.getEstimatorTaskById) {
    estimatorTask = data?.getEstimatorTaskById;
    if (estimatorTask?.costType !== TASK_COST_TYPES.MISC_PER_UNIT && estimatorTask?.sellPrice) {
      delete estimatorTask.sellPrice;
    }
  }

  return {
    data: estimatorTask,
    loading,
    error,
    refetch,
  };
};

import { Box, Button, Grid, MenuItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DialogModal from '../dialog-modal/DialogModal';
import { FormText, FormSelect, FormAutocomplete } from '../formHelperFunctions';
export interface ProjectItemFormProps {
  isOpen;
  handleClose;
  handleSubmit;
  projectId: string | null;
  ProjectItemFormData;
  setProjectItemFormData;
  vendors: string[] | null;
  solutions: string[] | null;
}

export function ProjectItemForm({
  isOpen,
  handleClose,
  handleSubmit,
  projectId,
  ProjectItemFormData,
  setProjectItemFormData,
  vendors,
  solutions,
}: ProjectItemFormProps) {
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [isOpen]);

  const handleFormClose = () => {
    setProjectItemFormData({});
    handleClose();
  };

  const handleSaveClick = () => {
    setError(false);
    if (!ProjectItemFormData.name || !ProjectItemFormData.type) {
      setError(true);
      return;
    }
    if (
      ProjectItemFormData.type === 'Product' ||
      ProjectItemFormData.type === 'Services'
    ) {
      if (
        Number(ProjectItemFormData.quantity) <= 0 ||
        Number.isNaN(ProjectItemFormData.cost) ||
        Number.isNaN(ProjectItemFormData.price)
      ) {
        setError(true);
        return;
      }
    }

    if (ProjectItemFormData.type === 'Subscription') {
      if (
        !ProjectItemFormData.billingyCycle ||
        !ProjectItemFormData.terms ||
        !ProjectItemFormData.quantity
      ) {
        setError(true);
        return;
      }
    }

    handleSubmit(ProjectItemFormData);
    handleFormClose();
  };

  const getActionButtons = () => {
    return (
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFormClose}
            data-testid="new-project-item-cancel-button"
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveClick}
            data-testid="new-project-item-submit-button"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    );
  };

  const vendorOptions =
    vendors?.map((vendor) => {
      return { id: vendor, label: vendor };
    }) ?? [];

  const solutionOptions =
    solutions?.map((solution) => {
      return { id: solution, label: solution };
    }) ?? [];

  return (
    <DialogModal
      fullWidth={true}
      isOpen={isOpen}
      handleClose={handleClose}
      title={
        ProjectItemFormData['id']
          ? 'Edit Opportunity item'
          : 'Add Opportunity Item'
      }
      action={getActionButtons()}
      maxWidthProp={'sm'}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="row"
        spacing={1}
        mt={1}
        data-testid="add-project-item"
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="column"
          spacing={1}
          sx={{ width: '100%' }}
        >
          {error && (
            <Box sx={{ padding: '15px' }}>
              <Typography color="error" variant="h6">
                Please add all required fields
              </Typography>
            </Box>
          )}

          <FormText
            required={true}
            name="name"
            label="Name"
            setFormData={setProjectItemFormData}
            formData={ProjectItemFormData}
          />

          {!ProjectItemFormData['id'] && (
            <FormSelect
              required={true}
              name="type"
              label="Type"
              setFormData={setProjectItemFormData}
              formData={ProjectItemFormData}
            >
              <MenuItem sx={{ height: '30px' }} value={'Product'}>
                Product
              </MenuItem>
              <MenuItem sx={{ height: '30px' }} value={'Services'}>
                Services
              </MenuItem>
              <MenuItem sx={{ height: '30px' }} value={'Subscription'}>
                Subscription
              </MenuItem>
              <MenuItem sx={{ height: '30px' }} value={'Estimator'}>
                Estimator
              </MenuItem>
              <MenuItem sx={{ height: '30px' }} value={'Cisco Annuity'}>
                Cisco Annuity
              </MenuItem>
              {process.env.NX_PROJECT_ID === 'selline-dev' && (
                <MenuItem sx={{ height: '30px' }} value={'Quote'}>
                  Quote
                </MenuItem>
              )}
            </FormSelect>
          )}

          {['Product', 'Services'].includes(ProjectItemFormData['type']) && (
            <>
              <FormAutocomplete
                required={true}
                name="vendor"
                label="Vendor"
                options={vendorOptions}
                setFormData={setProjectItemFormData}
                formData={ProjectItemFormData}
                freeSolo={true}
              />

              <FormAutocomplete
                name="solution"
                label="Solution"
                options={solutionOptions}
                setFormData={setProjectItemFormData}
                formData={ProjectItemFormData}
                freeSolo={true}
              />

              <FormText
                required={true}
                setFormData={setProjectItemFormData}
                formData={ProjectItemFormData}
                name="quantity"
                label="Quantity"
                inputProps={{ inputProps: { min: 0 } }}
                type={'number'}
              />

              <FormText
                required={true}
                setFormData={setProjectItemFormData}
                formData={ProjectItemFormData}
                name="cost"
                label="Cost"
                inputProps={{ inputProps: { min: 0 } }}
                type={'number'}
              />

              <FormText
                required={true}
                setFormData={setProjectItemFormData}
                formData={ProjectItemFormData}
                name="price"
                label="Price"
                inputProps={{ inputProps: { min: 0 } }}
                type={'number'}
              />
            </>
          )}

          {ProjectItemFormData['type'] === 'Subscription' && (
            <>
              <FormAutocomplete
                required={true}
                name="vendor"
                label="Vendor"
                options={vendorOptions}
                setFormData={setProjectItemFormData}
                formData={ProjectItemFormData}
                freeSolo={true}
              />

              <FormText
                required={true}
                name="mrrPrice"
                label="MRR"
                setFormData={setProjectItemFormData}
                formData={ProjectItemFormData}
                type={'number'}
              />
              <FormSelect
                required={true}
                name="terms"
                label="Term"
                setFormData={setProjectItemFormData}
                formData={ProjectItemFormData}
              >
                <MenuItem sx={{ height: '30px' }} value={1}>
                  1 Year
                </MenuItem>
                <MenuItem sx={{ height: '30px' }} value={2}>
                  2 Year
                </MenuItem>
                <MenuItem sx={{ height: '30px' }} value={3}>
                  3 Year
                </MenuItem>
                <MenuItem sx={{ height: '30px' }} value={4}>
                  4 Year
                </MenuItem>
                <MenuItem sx={{ height: '30px' }} value={5}>
                  5 Year
                </MenuItem>
              </FormSelect>

              <FormSelect
                required={true}
                name="billingyCycle"
                label="Billing Cycle"
                setFormData={setProjectItemFormData}
                formData={ProjectItemFormData}
              >
                <MenuItem sx={{ height: '30px' }} value={'Monthly'}>
                  Monthly
                </MenuItem>
                <MenuItem sx={{ height: '30px' }} value={'Annually'}>
                  Annually
                </MenuItem>
                <MenuItem sx={{ height: '30px' }} value={'Prepaid'}>
                  Prepaid
                </MenuItem>
              </FormSelect>

              <FormText
                name="quantity"
                label="Quantity"
                setFormData={setProjectItemFormData}
                formData={ProjectItemFormData}
                type={'number'}
              />
            </>
          )}
        </Grid>
      </Grid>
    </DialogModal>
  );
}

export default ProjectItemForm;

import { gql } from '@apollo/client';

export const REMOVE_QUOTE_LINES_MUTATION = gql`
  mutation Mutation($projectItemId: ID!, $quoteLineIds: [String]) {
    removeQuoteLines(
      projectItemId: $projectItemId
      quoteLineIds: $quoteLineIds
    ) {
      success
    }
  }
`;

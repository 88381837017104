import { gql } from '@apollo/client';

export const GET_PROJECT_PROPOSAL_BY_ID_QUERY = gql`
  query GetProjectProposalById($getProjectProposalByIdId: ID!) {
    getProjectProposalById(id: $getProjectProposalByIdId) {
      id
      name
      projectId
      estimatorId
      content
      previewContent
    }
  }
`;

/*
VARIABLES:
{
  "getProjectProposalByIdId": "61c36a22727e20b961ebd407"
}
*/

/*
RETURNS:
{
  "data": {
    "getProjectProposalById": {
      "id": "61c36a22727e20b961ebd407",
      "name": "A New Name",
      "projectId": "1234"
      "content": "<p>shiny new content</p>",
      "previewContent": "<p>shiny new content</p>"
    }
  }
}
*/

import { useQuery } from '@apollo/client';
import { GetCollectionsResponse } from '@cdw-selline/common/types';
import { GET_COLLECTIONS_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from '.';

export const useCollectionsQuery = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery<{
    getCollections: GetCollectionsResponse;
  }>(GET_COLLECTIONS_QUERY, {
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Collections!'),
  });

  return {
    data: data?.getCollections ?? { collections: [], count: 0 },
    error,
    loading,
  };
};

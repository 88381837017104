  import React, { useEffect, useState } from 'react';
  import DialogModal from '../dialog-modal/DialogModal';
  import { Grid, Typography, Box, MenuItem } from '@mui/material';
  import {
    FormAutocomplete,
    FormText,
    FormCheckbox,
    getActionButtons,
    FormDateField,
    FormSelect,
  } from '../formHelperFunctions';
  import {
    usePractices,
    useTaskGroups,
    useRoles,
    useServiceCategories,
  } from '@cdw-selline/ui/hooks';
  import { ApolloError } from '@apollo/client';
  import { startCase } from 'lodash';
  
  export interface ContentRequestProps {
    isOpen: boolean;
    handleClose: () => void;
    handleSubmit: (request) => void;
  }
  
  const newContentRequest = {
    practice: '',
    taskGroupId: '',
    feeStructureAllowed: [],
    defaultFeeStructure: '',
    minimumPrice: 0,
    engineerRole: '',
    taskGroupLevelPM: '',
    agiloftServiceCategory: '',
    agiloftScopeType: '',
    primaryContact: '',
    expectedDate: '',
    notes: '',
  };

  export function ContentRequestForm({
    isOpen,
    handleClose,
    handleSubmit,
  }: ContentRequestProps) {
    const [contentRequest, setContentRequest] = useState(newContentRequest);
    const [error, setError] = useState(false);
    const [practiceRoles, setPracticeRoles] = useState([]);

    useEffect(() => {
        setPracticeRoles(roleOptions(roles.roles.filter(role=>role.practiceName === contentRequest.practice)));
        setContentRequest({
            ...contentRequest,
            engineerRole: '',
        })
      }, [contentRequest.practice]);

    const {
        data: practices,
        loading: practiceLoading,
        error: practiceError,
      } = usePractices({});
    
    const practiceOptions =
        practices.practices.map((practice) => {
            return {
                id: practice.name,
                label: practice.name,
            };
        }) ?? [];
    const {
        data: taskGroups,
        loading: taskGroupsLoading,
        error: taskGroupsError,
        } = useTaskGroups({ filters: {} });
        const taskGroupOptions =
    taskGroups.taskGroups.map((taskGroup) => {
        return {
            id: taskGroup.name,
            label: taskGroup.name,
        };
    }) ?? [];
    const {
        data: roles,
        loading: rolesLoading,
        error: rolesError,
      } = useRoles({ filters: {} });
    
    const roleOptions = (selectedRoles)=>{
        return selectedRoles.map((role) => {
            return {
                id: role.id,
                label: `${role.name}  (${role.practiceName})`,
            };
        }) ?? [];
    }

    const {
        data: serviceCategories,
        loading: serviceCategoriesLoading,
        error: serviceCategoriesError,
        } = useServiceCategories();
    const serviceCategoriesOptions =
        serviceCategories.map((sc) => {
        return {
            id: sc.name,
            label: sc.name,
        };
    }) ?? [];
    const handleFormClose = () => {
      setError(false);
      handleClose();
    };

    const convertToJiraFormat = ()=> {
        let requestValue = '';
        Object.keys(contentRequest).forEach(key=>{
            requestValue += `
            |*${startCase(key)}*|${contentRequest[key] || 'N/A'}|`;
        })
         return requestValue;
    };
  
    const handleSaveClick = () => {
        handleSubmit(convertToJiraFormat());
        setError(false);
        handleClose();
    };
  
  
    return (
      <DialogModal
        fullWidth={true}
        isOpen={isOpen}
        handleClose={handleClose}
        title={'Content Request'}
        action={getActionButtons(handleFormClose, handleSaveClick)}
        maxWidthProp={'lg'}
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="row"
          spacing={1}
          mt={1}
          sx={{ height: '50vh' }}
          data-testid="role-grid"
        >
          <Grid
            container
            alignItems="flex-start"
            justifyContent="flex-start"
            direction="column"
            spacing={1}
            sx={{ width: '100%' }}
          >
            {error && (
              <Box sx={{ padding: '15px' }}>
                <Typography color="error" variant="h6">
                  Please add all required field values
                </Typography>
              </Box>
            )}
            <FormAutocomplete
              name="practice"
              label="Practice *"
              options={practiceOptions}
              setFormData={setContentRequest}
              formData={contentRequest}
              error={error && !contentRequest.practice}
            />
            <FormAutocomplete
                name="taskGroupId"
                label="Task Group"
                options={taskGroupOptions}
                setFormData={setContentRequest}
                formData={contentRequest}
            />
            <FormSelect
                name="feeStructureAllowed"
                label="Fee Structure(s) Allowed"
                setFormData={setContentRequest}
                formData={contentRequest}
                multiple
            >
                {['T&M', 'Fixed Fee', 'Recurring', 'SprintBased'].map((fee) => (
                    <MenuItem key={fee} value={fee}>
                        {fee}
                    </MenuItem>
                ))}
            </FormSelect>
            <FormSelect
                name="defaultFeeStructure"
                label="Default Fee Structure"
                setFormData={setContentRequest}
                formData={contentRequest}
            >
                {contentRequest.feeStructureAllowed.map((fee) => (
                    <MenuItem key={fee} value={fee}>
                        {fee}
                    </MenuItem>
                ))}
            </FormSelect>
            <FormText
              name="minimumPrice"
              label="Minimum / Floor Price (Funding Uplift)"
              type="number"
              setFormData={setContentRequest}
              formData={contentRequest}
              decimal={2}
              onFocus={(event)=> event.target.select()}
            />
            <FormAutocomplete
                name="engineerRole"
                label="Engineer Role"
                options={practiceRoles}
                setFormData={setContentRequest}
                formData={contentRequest}
            />
            <FormText
                name="taskGroupLevelPM"
                label="Task-Group Level PM"
                setFormData={setContentRequest}
                formData={contentRequest}
            />
            <FormAutocomplete
                name="agiloftServiceCategory"
                label="Agiloft Service Category"
                options={serviceCategoriesOptions}
                setFormData={setContentRequest}
                formData={contentRequest}
                freeSolo={true}
            />
            <FormText
                name="agiloftScopeType"
                label="Agiloft Scope Type"
                setFormData={setContentRequest}
                formData={contentRequest}
            />
            <FormText
                name="primaryContact"
                label="Primary Contact"
                setFormData={setContentRequest}
                formData={contentRequest}
            />
            <FormDateField
                name="expectedDate"
                label="When do you expect it by ?"
                setFormData={setContentRequest}
                formData={contentRequest}
            />
            <FormText
                name="notes"
                label="Notes"
                multiline
                rows={3}
                setFormData={setContentRequest}
                formData={contentRequest}
            />
          </Grid>
        </Grid>
      </DialogModal>
    );
  }
  
import {
  CollectionsDataTable,
  TaskGroupForm,
} from '@cdw-selline/ui/components';
import { useAdminTaskGroups, useTaskGroupById } from '@cdw-selline/ui/hooks';
import React from 'react';
import { IconButton, Box } from '@mui/material';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@cdw-selline/ui/constants';

export const TaskGroupsCollectionPage = () => {
  const dataTableProps = useAdminTaskGroups();
  const navigate = useNavigate();

  const {
    data: taskGroup,
    loading: taskGroupLoading,
    error: taskGroupError,
  } = useTaskGroupById(dataTableProps.editTaskGroupId);

  const openForm =
    dataTableProps.taskGroupFormOpen &&
    ((dataTableProps.editTaskGroupId && taskGroup.id) ||
      !dataTableProps.editTaskGroupId);

  const handleTestTaskClick = (event: React.MouseEvent, taskGroupId) => {
    event.stopPropagation();
    navigate(`${ROUTES.ADMIN_LIST_TASKS}/${taskGroupId}`);
  };

  const adminTestTaskButton = (taskGroupId: string) => {
    return (
      <IconButton
        color="inherit"
        size="small"
        aria-label="view"
        onClick={(e) => handleTestTaskClick(e, taskGroupId)}
      >
        <TableRowsIcon fontSize="small" />
      </IconButton>
    );
  };

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <CollectionsDataTable
        {...dataTableProps}
        height="calc(100vh - 13em)"
        paginationMode="server"
        sortingMode="server"
        onRowClick={(o) => dataTableProps.handleEdit(o.id)}
        parent="TaskGroupsCollectionPage"
        customRowActions={adminTestTaskButton}
      />
      {openForm && (
        <TaskGroupForm
          isOpen={dataTableProps.taskGroupFormOpen}
          taskGroup={taskGroup}
          handleClose={dataTableProps.handleTaskGroupFormClose}
          handleSubmit={dataTableProps.handleTaskGroupFormSave}
          handleDuplicate={dataTableProps.handleTaskGroupFormDuplicate}
          handleTestVersion={dataTableProps.handleTaskGroupFormTestVersion}
          handleDeleteTestVersion={dataTableProps.handleTaskGroupFormDeleteTestVersion}
          handleApproveTestVersion={dataTableProps.handleTaskGroupFormApproveTestVersion}
          taskGroupLoading={taskGroupLoading}
          taskGroupError={taskGroupError}
        />
      )}
    </Box>
  );
};

export default TaskGroupsCollectionPage;

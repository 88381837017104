import { gql } from '@apollo/client';

export const GET_ZUORA_SUBSCRIPTION = gql`
  query GetZuoraSubscription($subscriptionId: String!) {
    getZuoraSubscription(subscriptionId: $subscriptionId) {
        zuoraId
        accountId
        accountNumber
        accountName
        invoiceOwnerAccountId
        invoiceOwnerAccountNumber
        invoiceOwnerAccountName
        subscriptionNumber
        termType
        invoiceSeparately
        contractEffectiveDate
        serviceActivationDate
        customerAcceptanceDate
        subscriptionStartDate
        subscriptionEndDate
        lastBookingDate
        termStartDate
        termEndDate
        initialTerm
        initialTermPeriodType
        currentTerm
        currentTermPeriodType
        autoRenew
        renewalSetting
        renewalTerm
        renewalTermPeriodType
        contractedMrr
        totalContractedValue
        notes
        status
        vendorSubscriptionId
        quoteNumber
        paymentTerm
        commissionable
        orderTypeService
        opportunityName
        billToMedia
        salesPerson
        invoiceComments
        contactSource
        customerPO
        paymentType
        serviceType
        marginPercentage
        cpqBundleJsonId
        subHubSubscriptionId
        quoteType
        shipToSource
        subscriptionUsageIdentifier
        billToEmailSource
        billToEmailAddress
        shipToReference
        quoteBusinessType
        shipFromLocation
        productHubLocation
        engagementId
        vendor
        minimumContractValue
        svarContractNumber
        invoiceTemplate
        daasType
        opportunityCloseDate
        paymentTermCode
        contactReference
        billToReference
        billToEmailAddress2
        vendorId
        billToSource
        department
        billToContact
        invoiceTemplateId
        invoiceTemplateName
        sequenceSetId
        sequenceSetName
        soldToContact
        isLatestVersion
        cancelReason
      ratePlans {
          productId
          productName
          productSku
          productRatePlanId
          productRatePlanNumber
          ratePlanName
          ratePlanUsageIdentifier
          subscriptionProductFeatures
          externallyManagedPlanId
          subscriptionRatePlanNumber
          ratePlanCharges {
            originalChargeId
            productRatePlanChargeId
            number
            name
            productRatePlanChargeNumber
            type
            model
            uom
            priceChangeOption
            priceIncreasePercentage
            currency
            chargeModelConfiguration
            inputArgumentId
            includedUnits
            overagePrice
            applyDiscountTo
            discountLevel
            discountClass
            billingDay
            listPriceBase
            specificListPriceBase
            billingPeriod
            specificBillingPeriod
            billingTiming
            ratingGroup
            billingPeriodAlignment
            quantity
            isStackedDiscount
            smoothingModel
            numberOfPeriods
            overageCalculationOption
            overageUnusedUnitsCreditOption
            unusedUnitsCreditRates
            usageRecordRatingOption
            segment
            effectiveStartDate
            effectiveEndDate
            processedThroughDate
            chargedThroughDate
            done
            triggerDate
            triggerEvent
            endDateCondition
            upToPeriodsType
            upToPeriods
            specificEndDate
            mrr
            dmrc
            tcv
            dtcv
            originalOrderDate
            amendedByOrderOn
            description
            shipToReference
            serialNumber
            shipFromLocation
            invoiceLineNumber
            partnerProvider
            notes
            externalProductID
            orderTypeService
            serviceID
            entitlementId
            ratePlanChargeUsageIdentifier
            invoiceLineComments
            subhubProductOrderId
            msrp
            marginPercentage
            adjustedSimCost
            shipToSource
            unitCost
            pricingTypeSVAR
            tiers {
              tier
              startingUnit
              endingUnit
              price
              priceFormat
            }
          }
        }
      }
  }
`;
import { gql } from '@apollo/client';

export const UPDATE_CISCO_ANNUITY_COMMENT_MUTATION = gql`
  mutation updateCiscoAnnuityCommentById(
    $ciscoAnnuityId: ID!
    $ciscoAnnuityCommentId: ID!
    $ciscoAnnuityComment: CiscoAnnuityCommentInput!
  ) {
    updateCiscoAnnuityCommentById(
      ciscoAnnuityId: $ciscoAnnuityId
      ciscoAnnuityCommentId: $ciscoAnnuityCommentId
      ciscoAnnuityComment: $ciscoAnnuityComment
    ) {
      success
    }
  }
`;
import { useQuery } from '@apollo/client';
import {
  ProposalTemplate,
  GetProposalTemplatesResponse,
} from '@cdw-selline/common/types';
import {
  GET_PROPOSAL_TEMPLATES_QUERY,
  GET_PROPOSAL_TEMPLATE_BY_ID_QUERY,
  GET_ESTIMATOR_PROPOSAL_TEMPLATES_QUERY
} from '@cdw-selline/ui/queries';
import { useApolloErrors } from '../useApolloErrors';

export interface UseProposalTemplates {
  filters?: any;
  offset?: number;
  limit?: number;
  sort?: unknown
}

export const useProposalTemplates = ({
  filters,
  offset,
  limit,
  sort,
}: UseProposalTemplates) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getProposalTemplates: GetProposalTemplatesResponse;
  }>(GET_PROPOSAL_TEMPLATES_QUERY, {
    variables: {
      offset: offset ?? 0,
      limit: limit ?? 500,
      filters: filters ?? {},
      sort: sort ?? {},
    },
    fetchPolicy: 'network-only',
    onError: (error) =>
      handleErrorResponse(error, 'Failed  to fetch Proposal Templates!'),
  });
  return {
    data: data?.getProposalTemplates ?? { proposalTemplates: [], count: 0 },
    loading,
    error,
    refetch,
  };
};

export const useProposalTemplateById = (id: string) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getProposalTemplateById: ProposalTemplate;
  }>(GET_PROPOSAL_TEMPLATE_BY_ID_QUERY, {
    variables: { id },
    skip: !id,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Proposal Template!'),
  });
  return {
    data: data?.getProposalTemplateById ?? {},
    loading,
    error,
    refetch,
  };
};

export const useEstimatorProposalTemplates = (projectItemId: string) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getEstimatorProposalTemplates: GetProposalTemplatesResponse;
  }>(GET_ESTIMATOR_PROPOSAL_TEMPLATES_QUERY, {
    variables: {
      projectItemId
    },
    fetchPolicy: 'network-only',
    onError: (error) =>
      handleErrorResponse(error, 'Failed  to fetch Estimator Proposal Templates!'),
  });
  return {
    data: data?.getEstimatorProposalTemplates ?? { proposalTemplates: [], count: 0 },
    loading,
    error,
    refetch,
  };
};

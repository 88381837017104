import { getFirebaseApp } from '@cdw-selline/ui/common';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { getDatabase, onValue, ref, update } from 'firebase/database';
import { useEffect, useState } from 'react';

export const useRealTimeDatabase = (
  projectId: string,
  docId: string,
  dbPath?: string
) => {
  const fbApp = getFirebaseApp();
  const db = getDatabase(fbApp);
  const dbRef = ref(db, dbPath ?? `/project-sows/${projectId}/${docId}`);
  const [doc, setDoc] = useState(null);
  const alerts = useAlertsState();

  const handleError = (err: Error) => {
    alerts.setAlert({
      type: ALERT_TYPE.SNACKBAR,
      severity: ALERT_SEVERITY.ERROR,
      message: err.message,
    });
  };

  useEffect(() => {
    const unSubscribe = onValue(
      dbRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data && doc?.content !== data?.content) {
          setDoc(data);
        }
      },
      (err) => {
        if (err) {
          console.error('error listening to RTDB', err);
          handleError(err);
        }
      }
    );
    return () => {
      unSubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbRef]);

  const handleDocUpdates = async (delta: Partial<Record<string, unknown>>) => {
    await update(dbRef, delta).catch((err) => {
      handleError(err);
    });
  };

  return {
    handleDocUpdates,
    doc,
  };
};

import { gql } from '@apollo/client';

export const ADD_TASK_MUTATION = gql`
  mutation addTaskMutation($params: TaskInput) {
    addTask(params: $params) {
      id
      success
    }
  }
`;

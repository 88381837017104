import { gql } from '@apollo/client';

export const GET_ESTIMATOR_BID_ASSURANCE_STATUS_HISTORY_QUERY = gql`
  query getEstimatorBidAssuranceStatusHistory($projectItemId: String!) {
    getEstimatorBidAssuranceStatusHistory(projectItemId: $projectItemId) {
        id
        note
        createdA
        createdBy
    }
  }
`;
import { useMutation } from '@apollo/client';
import {
  CiscoAnnuity,
  CiscoAnnuityContactInput,
  CiscoAnnuityCommentInput
} from '@cdw-selline/common/types';
import {
  GET_CISCO_ANNUITY_BY_PROJECT_ITEM_ID_QUERY,
  UPDATE_CISCO_ANNUITY_MUTATION,
  ADD_CISCO_ANNUITY_COMMENT_MUTATION,
  UPDATE_CISCO_ANNUITY_COMMENT_MUTATION,
  DELETE_CISCO_ANNUITY_COMMENT_MUTATION,
  REQUEST_WORD_COF_BY_ID_MUTATION,
} from '@cdw-selline/ui/queries';
import omitDeep from 'omit-deep-lodash';

import { useApolloErrors } from '..';
import { ALERT_SEVERITY, ALERT_TYPE, useAlertsState } from '@cdw-selline/ui/state';
import { Dispatch, SetStateAction } from 'react';

export const useCiscoAnnuityUpdate = (completionCallback?: () => void) => {
  const alertState = useAlertsState();
  const { handleErrorResponse } = useApolloErrors();

  const [updateCiscoAnnuity, { loading, error, client }] = useMutation(
    UPDATE_CISCO_ANNUITY_MUTATION,
    {
      refetchQueries: [GET_CISCO_ANNUITY_BY_PROJECT_ITEM_ID_QUERY],
      onError: (error) => {
        handleErrorResponse(error, 'Failed to update Cisco Annuity!')
        client.refetchQueries({
          include: [GET_CISCO_ANNUITY_BY_PROJECT_ITEM_ID_QUERY],
        });
      },
      onCompleted: completionCallback,
    }
  );

  const [addCiscoAnnuityComment, { loading: addCommentLoading, error: addCommentError }] = useMutation(
    ADD_CISCO_ANNUITY_COMMENT_MUTATION,
    {
      refetchQueries: [GET_CISCO_ANNUITY_BY_PROJECT_ITEM_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to add Cisco Annuity Comment!'),
      onCompleted: completionCallback,
    }
  );

  const [updateCiscoAnnuityComment, { loading: updateCommentLoading, error: updateCommentError }] = useMutation(
    UPDATE_CISCO_ANNUITY_COMMENT_MUTATION,
    {
      refetchQueries: [GET_CISCO_ANNUITY_BY_PROJECT_ITEM_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update Cisco Annuity Comment!'),
      onCompleted: completionCallback,
    }
  );

  const [deleteCiscoAnnuityComment, { loading: deleteCommentLoading, error: deleteCommentError }] = useMutation(
    DELETE_CISCO_ANNUITY_COMMENT_MUTATION,
    {
      refetchQueries: [GET_CISCO_ANNUITY_BY_PROJECT_ITEM_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to delete Cisco Annuity Comment!'),
      onCompleted: completionCallback,
    }
  );

  const [
    requestWordCofById,
    {
      loading: requestWordCofByIdLoading,
      error: requestWordCofByIdError,
    },
  ] = useMutation(REQUEST_WORD_COF_BY_ID_MUTATION, {
    onError: (error) =>
      handleErrorResponse(error, 'Failed to request Word COF!'),
    onCompleted: (data) => {
      if (data.requestWordCofById.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully requested Word COF',
        });
      } else {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.ERROR,
          message: 'Error requesting Word COF',
        });
      }
    },
  });

  const handleCiscoAnnuityUpdate = (
    updatedCiscoAnnuity: CiscoAnnuity,
    projectItemId
  ) => {
    updateCiscoAnnuity({
      variables: {
        params: {
          ...omitDeep(
            updatedCiscoAnnuity,
            '__typename',
            'lastModifiedAt',
            'ciscoQuotes'
          ),
          projectItemId: projectItemId,
        },
      },
    });
  };

  const handleSaveCiscoAnnuityComment = (
    ciscoAnnuityId: string,
    message: CiscoAnnuityCommentInput,
  ) => {
    if (!message.id) {
      addCiscoAnnuityComment({
        variables: {
          ciscoAnnuityId,
          ciscoAnnuityComment: message,
        },
      });
    } else {
      updateCiscoAnnuityComment({
        variables: {
          ciscoAnnuityId,
          ciscoAnnuityCommentId: message.id,
          ciscoAnnuityComment: message,
        },
      });
    }
  };

  const handleDeleteCiscoAnnuityComment = (ciscoAnnuityId: string, commentId: string) => {
    deleteCiscoAnnuityComment({
      variables: {
        ciscoAnnuityId: ciscoAnnuityId,
        ciscoAnnuityCommentId: commentId
      },
    });
  }

  const handleRequestWordCofById = (ciscoAnnuityId: string) => {
    requestWordCofById({
      variables: {
        ciscoAnnuityId: ciscoAnnuityId,
      },
    });
  }

  return {
    handleCiscoAnnuityUpdate,
    handleSaveCiscoAnnuityComment,
    handleDeleteCiscoAnnuityComment,
    handleRequestWordCofById,
    loading,
    error,
  };
};

import { useQuery } from '@apollo/client';
import { GetServiceCategoriesResponse } from '@cdw-selline/common/types';
import { GET_SERVICE_CATEGORIES_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from './useApolloErrors';

export const useServiceCategories = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery<{
    getServiceCategories: GetServiceCategoriesResponse;
  }>(GET_SERVICE_CATEGORIES_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Service Categories!'),
  });

  return {
    error,
    loading,
    data: data?.getServiceCategories.serviceCategories ?? [],
  };
};

export default useServiceCategories;

import { useMutation } from '@apollo/client';
import {
  GET_ADMIN_SERVICE_ITEMS,
  SYNC_SERVICE_ITEMS_MUTATION,
} from '@cdw-selline/ui/queries';
import { useState } from 'react';
import { useApolloErrors } from '..';
import {
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
} from '@mui/x-data-grid';
import {
  getFilters,
  getFilterModelFromLocalStorage,
} from '@cdw-selline/ui/helpers';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { useServiceItems } from './useServiceItems';
import { PAGES } from '@cdw-selline/ui/constants';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 200,
    editable: false,
  },
  {
    field: 'name',
    headerName: 'Service Item Name',
    width: 500,
  },
  {
    field: 'practice',
    headerName: 'Practice',
    width: 200,
  },
  {
    field: 'className',
    headerName: 'Class Name',
    width: 200,
  },
  {
    field: 'serviceItemNumber',
    headerName: 'Service Item ID',
    width: 200,
  },
  {
    field: 'externalId',
    headerName: 'External ID',
    width: 200,
  },
];

export const useAdminServiceItems = () => {
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [sortState, setSortState] = useState();

  const { data, loading, error, refetch } = useServiceItems({
    filters: getFilters(PAGES.SERVICE_ITEMS_COLLECTION),
    offset: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize,
    sort: sortState,
  });

  const [
    syncServiceItems,
    { loading: syncServiceItemsLoading, error: syncServiceItemsError },
  ] = useMutation(SYNC_SERVICE_ITEMS_MUTATION, {
    refetchQueries: [GET_ADMIN_SERVICE_ITEMS],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to sync Service Items!'),
    onCompleted: (data) => {
      if (data.syncServiceItems.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully synced Service Items!',
        });
      }
    },
  });

  const filterModel: GridFilterModel = getFilterModelFromLocalStorage(`${PAGES.SERVICE_ITEMS_COLLECTION}-filter`);

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    const pageSize = model?.pageSize ?? paginationModel?.pageSize;
    const page = model?.page ?? paginationModel?.page;
    setPaginationModel({
      page,
      pageSize,
    });
  };

  const onFilterModelChange = (filterModel) => {
    refetch({ filters: getFilters(PAGES.SERVICE_ITEMS_COLLECTION) });
  };

  const handleSort = (args) => {
    let newSort;
    if (args.length) newSort = { [args[0].field]: args[0].sort };
    setSortState(newSort);
  };

  const returnValue = {
    columns,
    rows: data?.serviceItems || [],
    handlePaginationModelChange,
    handleSort,
    onFilterModelChange,
    filterModel,
    serviceItems: data?.serviceItems,
    rowCount: data?.count || 0,
    loading: loading || syncServiceItemsLoading,
    error: error || syncServiceItemsError,
    syncServiceItems,
  };

  if (loading || syncServiceItemsLoading) {
    return {
      ...returnValue,
      paginationModel: { pageSize: 100, page: 0 },
      rowCount: 0,
      rows: [],
    };
  } else {
    return { ...returnValue, paginationModel };
  }
};

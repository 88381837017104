import { useQuery } from '@apollo/client';
import { GET_PROJECT_ITEM_VENDORS } from '@cdw-selline/ui/queries';

import { useApolloErrors } from '.';

export const useProjectItemVendors = () => {
  const { handleErrorResponse } = useApolloErrors();

  const { loading, error, data, refetch } = useQuery<{
    getDistinctVendors: string[];
  }>(GET_PROJECT_ITEM_VENDORS, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Project Item Vendors!'),
  });

  return {
    vendors: data?.getDistinctVendors ?? [],
    error,
    loading,
    refetch,
  };
};

import { gql } from '@apollo/client';

export const UPDATE_PROJECT_MUTATION = gql`
  mutation UpdateProjectMutation($updateProjectParams: UpdateProjectInput!) {
    updateProject(params: $updateProjectParams) {
      success
    }
  }
`;

export default UPDATE_PROJECT_MUTATION;

// Variables
// "updateProjectParams": {
//   "id": "uuid",
//   ...
// }

import { useQuery } from '@apollo/client';
import {
    GET_SOW_SECTION_VERSION_LIST_QUERY,
} from '@cdw-selline/ui/queries';

import { useApolloErrors } from './useApolloErrors';

export const useAdminSowSectionVersionList = (id) => {
  const { handleErrorResponse } = useApolloErrors();
  const {
    loading,
    error,
    data,
  } = useQuery<{ getSowSectionVersionList: Array<number> }>(GET_SOW_SECTION_VERSION_LIST_QUERY, {
    fetchPolicy: 'no-cache',
    skip: !id,
    variables: { id },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch sow section version list!'),
  });

  return {
    loading,
    error,
    versionList: data?.getSowSectionVersionList ?? [],
  };
};
import '@cdw-selline/ui/theme';
import { getFirebaseApp } from '@cdw-selline/ui/common';
import { LeadTable, Skeleton } from '@cdw-selline/ui/components';
import { useLeads } from '@cdw-selline/ui/hooks';
import { Box, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useUserState } from '@cdw-selline/ui/state';
import { getDatabase } from 'firebase/database';

/* eslint-disable-next-line */
export interface LeadsTabProps {}

export const LeadsTab = (props: LeadsTabProps) => {
  const fbApp = getFirebaseApp();
  const db = getDatabase(fbApp);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const userState = useUserState();

  const fbUser = userState.get() ?? {};

  const currentUser =
    (process.env.NX_CI_E2E_TEST || process.env.NX_CI_TEST) &&
    !userState.promised
      ? {}
      : JSON.parse(JSON.stringify(fbUser));

  const userEmail = currentUser ? currentUser.email : {};

  const [userFilter, setUserFilter] = useState({ owner: userEmail });

  const { loading, data, error } = useLeads({
    limit: 100,
    offset: 0,
    filters: userFilter,
  });

  if (error) return <>Error</>;

  return (
    <Box
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      ></Box>
      <Box>
        <LeadTable
          leads={data}
          setUserFilter={setUserFilter}
          userState={Object.keys(userFilter).length === 0 ? true : false}
          userEmail={userEmail}
        />
      </Box>
    </Box>
  );
};

export default LeadsTab;

import { columnFields, ProjectItemQuote } from '@cdw-selline/common/types';
import { HandsonTableSelectionData } from '@cdw-selline/common/types';
import {
  getAllQuoteLineUpdates,
  getSelectedQuoteLineIds,
  getTableRows,
} from '@cdw-selline/ui/helpers';
import { CellChange, ChangeSource } from 'handsontable/common';
import { useEffect, useRef, useState } from 'react';
import { useUpdateProjectItemQuote } from '..';

export const useQuotesTable = (quoteData: ProjectItemQuote) => {
  const tableRef = useRef(null);

  const {
    handleAddQuoteLines,
    handleUpdateQuoteLines,
    handleRemoveQuoteLines,
    loading,
    error,
  } = useUpdateProjectItemQuote();

  const [tableRows, setTableRows] = useState<
    { [columnIndex: number]: string | number }[]
  >([]);

  const [selections, setSelections] = useState<HandsonTableSelectionData[]>([]);

  useEffect(() => {
    setTableRows(getTableRows(quoteData));
  }, [quoteData]);

  const handleQuoteLineChange = (
    changeData: CellChange,
    source: ChangeSource
  ): void => {
    if (source === 'loadData') return;
    if (!changeData) return;

    const [change] = changeData;
    const columnNumber = change[1];

    const targetProperty = columnFields[columnNumber];
    if (!targetProperty) {
      console.log('did not find a property to update');
      return;
    }

    const newValue: string = change[3];

    const allQuoteLineUpdates = getAllQuoteLineUpdates(
      selections,
      tableRows,
      quoteData,
      newValue
    );

    handleUpdateQuoteLines(quoteData.projectItemId, allQuoteLineUpdates);
  };

  const handleAddEmptyLine = (): void => {
    handleAddQuoteLines(quoteData.projectItemId, [{}]);
  };

  const handleRemoveSelectedRows = () => {
    if (selections.length === 0) return;
    const selectedQuoteLineIds = getSelectedQuoteLineIds(selections, tableRows);
    handleRemoveQuoteLines(quoteData.projectItemId, selectedQuoteLineIds);
  };

  return {
    tableRows,
    tableRef,
    handleAddEmptyLine,
    handleRemoveSelectedRows,
    handleQuoteLineChange,
    setSelections,
    loading,
    error,
  };
};

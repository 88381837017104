import { gql } from '@apollo/client';

export const GET_STANDRD_RMS_PROPOSAL_TEMPLATE_QUERY = gql`
  query getStandardRmsProposalTemplateByItemId($projectItemId: String!) {
    getStandardRmsProposalTemplateByItemId(projectItemId: $projectItemId) {
      rmsProposalItems {
        siteId
        siteName
        categories {
          category
          lineItems {
            id
            order
            quantity
            sellPrice
            totalSellPrice
            tier
            tierId
            type
            costType
            name
            proposalNotes
            proposalDescription
          }
          totalSellPrice
        }
        totalSellPrice
      }
      totalMonthlyCharges
    }
  }
`;

import { useEstimatorTasksSowSections } from '@cdw-selline/ui/hooks';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ProjectSowComponent from '../project-sow/ProjectSow';

export interface EstimatorSowComponentProps {
  isReadOnly?: boolean;
}

export const EstimatorSowComponent = ({isReadOnly}: EstimatorSowComponentProps) => {
  const { id, projectItemId: estimatorId } =
    useParams<{ id: string; projectItemId: string }>();
  const estimatorSowData = useEstimatorTasksSowSections(id, estimatorId);
  const props = {...estimatorSowData, isReadOnly};

  return <ProjectSowComponent {...props} />;
};

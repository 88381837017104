import { useMutation } from '@apollo/client';
import {
  COPY_PROJECT_SITE_MUTATION,
  GET_PROJECT_ITEM_ESTIMATOR_TASKS_QUERY,
  GET_ESTIMATOR_SITES_BY_PROJECT_ITEM_ID_QUERY,
  GET_ESTIMATOR_TOTALS_BY_ITEM_ID,
  GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY,
  GET_ESTIMATOR_SUMMARY_TASKS_QUERY,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { useApolloErrors } from '..';

export const useCopyProjectSite = (includesSummaryTasks: boolean) => {
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();

  const [copyProjectSite, { loading, error, client }] = useMutation(
    COPY_PROJECT_SITE_MUTATION,
    {
      onError: (error) =>
        handleErrorResponse(error, 'Failed to copy Project Site!'),
      onCompleted: (data) => {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully copied Project Site',
        });

        if (includesSummaryTasks) {
          client.refetchQueries({
            include: [
              GET_ESTIMATOR_SITES_BY_PROJECT_ITEM_ID_QUERY,
              GET_ESTIMATOR_SUMMARY_TASKS_QUERY,
              GET_ESTIMATOR_TOTALS_BY_ITEM_ID,
            ],
          });
        } else {
          client.refetchQueries({
            include: [
              GET_ESTIMATOR_SITES_BY_PROJECT_ITEM_ID_QUERY,
              GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY,
              GET_ESTIMATOR_TOTALS_BY_ITEM_ID,
            ],
          });
        }

      },
    }
  );

  const handleCopyProjectSite = (
    projectItemId: string,
    site: string,
    siteName: string
  ) => {
    copyProjectSite({
      variables: {
        projectItemId,
        site,
        siteName,
      },
    });
  };

  return { handleCopyProjectSite, loading, error };
};

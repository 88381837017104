import { Grid } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useProjectConfigSettings } from '@cdw-selline/ui/hooks';
import { SystemProposalView } from '@cdw-selline/ui/components';

export interface ProjectAgiloftTabProps {
  projectId: string;
  projectItemId: string;
}

export function ProjectAgiloftTab({
  projectId,
  projectItemId,
}: ProjectAgiloftTabProps) {
  const { data, loading } = useProjectConfigSettings({
    filters: { key: ['SystemProposal ID'] },
  });
  const proposalTemplateId = data.adminConfigSettings[0]?.value;

  if (loading || !proposalTemplateId) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <SystemProposalView
          projectId={projectId}
          projectItemId={projectItemId}
          proposalTemplateId={proposalTemplateId}
        />
      </Grid>
    </Grid>
  );
}

export default ProjectAgiloftTab;

import type {
  State,
  StateValueAtRoot,
  PluginCallbacksOnSetArgument,
  Plugin,
} from '@hookstate/core';
import { omit, isFunction } from 'lodash';

// eslint-disable-next-line @typescript-eslint/ban-types
export class StatePersistance<S extends object> {
  constructor(private key: string, private omitKeys: Partial<keyof S>[] = []) {}

  public getPersistedState(newState: S): S {
    const persisted = localStorage?.getItem(this.key);
    if (!persisted) {
      this.savePersistedState(newState);
    }
    return persisted ? JSON.parse(persisted) : newState;
  }

  public savePersistedState(newState: S) {
    localStorage?.setItem(
      this.key,
      JSON.stringify(omit(newState, this.omitKeys))
    );
  }

  public clearPersistedState() {
    localStorage?.removeItem(this.key);
  }

  public attachPersistence(initialState: S | (() => S)) {
    return (): Plugin => ({
      id: Symbol(this.key),
      init: (s: State<StateValueAtRoot>) => {
        return {
          onSet: (p: PluginCallbacksOnSetArgument) => {
            if ('state' in p) {
              this.savePersistedState(p.state);
            } else {
              this.savePersistedState(
                isFunction(initialState) ? initialState() : initialState
              );
            }
          },
        };
      },
    });
  }
}

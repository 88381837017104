// eslint-disable-next-line @nx/enforce-module-boundaries
import { useOpportunities } from '@cdw-selline/ui/hooks';
import {
  Avatar,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { getCurrentUser } from '@cdw-selline/ui/state';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { useState } from 'react';
import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ActiveProjectsProps {}

export const ActiveProjects: React.FC<ActiveProjectsProps> = (props) => {
  const currentUser = getCurrentUser();
  const userEmail = currentUser ? currentUser.email : {};

  const [userFilter, setUserFilter] = useState({
    $or: [
      { projectTeam: { $elemMatch: { email: userEmail } } },
      { projectOwner: userEmail },
    ],
  });

  const { loading, data, error, project } = useOpportunities({
    limit: 1000,
    offset: 0,
    filters: userFilter,
  });
  const count = new Intl.NumberFormat('en-US', {
    maximumSignificantDigits: 3,
  }).format(data?.length ?? 0);
  const projectsSinceLastMonth = '100%';

  return (
    <Card sx={{ height: '100%' }} {...props}>
      <CardContent>
        {error && (
          <Typography color="error" variant="h6">
            {error.message}
          </Typography>
        )}
        {!error && (
          <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
            <Grid item>
              <Typography color="textSecondary" gutterBottom variant="button">
                TOTAL OPPORTUNITIES
              </Typography>
              <Typography color="textPrimary" variant="h6">
                {loading ? <CircularProgress /> : count}
              </Typography>
            </Grid>
            <Grid item>
              <Avatar
                sx={{
                  backgroundColor: red[600],
                  height: 46,
                  width: 46,
                }}
              >
                <AssignmentTurnedInIcon />
              </Avatar>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default ActiveProjects;

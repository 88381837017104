import { useQuery } from '@apollo/client';
import { ZuoraSubscription, CiscoAnnuityLine } from '@cdw-selline/common/types';
import { GET_ZUORA_SUBSCRIPTION } from '@cdw-selline/ui/queries';

import { useApolloErrors } from '..';

export const useZuoraSubscription = (subscriptionId: string) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getZuoraSubscription: ZuoraSubscription;
  }>(GET_ZUORA_SUBSCRIPTION, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    variables: { subscriptionId },
    skip: !subscriptionId ,
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Cisco Annuity Subscription!'),
  });

  return {
    subscription: data?.getZuoraSubscription,
    refetch,
    loading,
    error,
  };
};
import { gql } from '@apollo/client';

export const GET_RMS_PROPOSAL_QUERY = gql`
  query getRmsProposalByItemId($projectItemId: String!) {
    getRmsProposalByItemId(projectItemId: $projectItemId) {
      primaryName
      primaryTitle
      primaryEmail
      primaryOrg
      primaryPhone
      altName
      altTitle
      altEmail
      altOrg
      altPhone
      showReadinessVerbage
      isTierTemplate
      showOnboardingVerbage
      priceOverride
      bronze
      silver
      gold
      includeMonthlySubtotals
      includeMonthlyTotal
      pricingNotes
      term
    }
  }
`;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Card, IconButton, TextField } from '@mui/material';
import { Theme, createTheme } from '@mui/material/styles';
import {
  DataGrid,
  GridFilterModel,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { createStyles, makeStyles } from '@mui/styles';
import * as React from 'react';
import { ErrorOverlay } from '../tables/ErrorOverlay';
import { NoRowsOverlay } from '../tables/NoRowsOverlay';
import { ErrorBoundary } from 'react-error-boundary';

export const escapeRegExp = (value: string): string => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
      textField: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    }),
  { defaultTheme }
);

export interface QuickSearchToolbarProps {
  onChange: () => void;
  value: string;
  clearSearch: () => void;
}

export function QuickSearchToolbar(props: QuickSearchToolbarProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarFilterButton {...({} as any)} />
        <GridToolbarDensitySelector />
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

const columns = [
  {
    field: 'timestamp',
    headerName: 'Timestamp',
    width: 180,
    editable: false,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 180,
    editable: false,
  },
  {
    field: 'notification',
    headerName: 'Notification',
    width: 180,
    editable: false,
  },
  {
    field: 'notificationType',
    headerName: 'Notification Type',
    width: 220,
    editable: false,
  },
];

/* eslint-disable-next-line */
export interface NotificationsTableProps {}

export const NotificationsTable = (props: NotificationsTableProps) => {
  const error = false;
  const loading = false;
  const data = {
    rows: [],
    columns,
  };

  const [searchText, setSearchText] = React.useState('');
  const [rows, setRows] = React.useState<any[]>(data.rows);
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
  });

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = data.rows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row?.[field]?.toString?.());
      });
    });
    setRows(filteredRows);
  };

  // React.useEffect(() => {
  //   setRows(data.rows);
  // }, [data.rows]);

  return (
    <Card
      sx={{
        background: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        minHeight: 300,
        m: '2em',
      }}
    >
      <ErrorBoundary
        FallbackComponent={ErrorOverlay}
      >
        <DataGrid
          sx={{
            ".MuiDataGrid-columnHeaders, .MuiDataGrid-columnHeaders > div[role='row']": {
              backgroundColor: '#fff !important',
            },
          }}
          slots={{
            noRowsOverlay: NoRowsOverlay,
            toolbar: QuickSearchToolbar,
          }}
          columns={data.columns}
          // autoHeight
          // autoPageSize
          loading={loading}
          {...{ [error ? 'error' : 'undefined']: error }}
          style={{
            fontSize: '14px',
          }}
          rows={[]}
          slotProps={{
            toolbar: {
              value: searchText,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value),
              clearSearch: () => requestSearch(''),
            },
          }}
          filterModel={filterModel}
          onFilterModelChange={(model) => setFilterModel(model)}
        />
      </ErrorBoundary>
    </Card>
  );
};

export default NotificationsTable;

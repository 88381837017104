import { gql } from '@apollo/client';

export const GET_PROJECT_TASK_GROUP_SCOPE_QUERY = gql`
  query getProjectTaskGroupScope($projectId: ID!) {
    getProjectTaskGroupScope(projectId: $projectId) {
      sow
      rawSowContent
    }
  }
`;

import { gql } from '@apollo/client';

export const REMOVE_PROJECT_ITEM_QUOTE_MUTATION = gql`
  mutation Mutation($removeProjectItemQuoteId: ID!) {
    removeProjectItemQuote(id: $removeProjectItemQuoteId) {
      success
    }
  }
`;

/*
  VARIABLES:
    {
        "removeProjectItemQuoteId": "123456789"
    }
*/

import { gql } from '@apollo/client';

export const GET_PROPOSAL_TEMPLATES_QUERY = gql`
  query GetProposalTemplatesQuery(
    $limit: Int
    $offset: Int
    $filters: MongoFilter
    $sort: MongoFilter
  ) {
    getProposalTemplates(filters: $filters, limit: $limit, offset: $offset, sort: $sort) {
      count
      proposalTemplates {
        id
        name
        createdBy
        createdAt
        isSystem
      }
    }
  }
`;

export const GET_ESTIMATOR_PROPOSAL_TEMPLATES_QUERY = gql`
  query GetEstimatorProposalTemplatesQuery(
    $projectItemId: String
  ) {
    getEstimatorProposalTemplates(projectItemId: $projectItemId) {
      count
      proposalTemplates {
        id
        name
        createdBy
        createdAt
        isSystem
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const RESTORE_LATEST_GROUP_TASK_VERSION = gql`
  mutation Mutation($siteId: String!, $projectItemId: String!) {
    restoreToLatestGroupTaskVersion(
        siteId: $siteId,
        projectItemId: $projectItemId
    ) {
      success
    }
  }
`;
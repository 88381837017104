import { useMutation } from '@apollo/client';
import {
  GET_PROJECT_ITEM_ESTIMATOR_TASKS_QUERY,
  REMOVE_PROJECT_CUSTOM_TASK_BY_ID_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';

import { useApolloErrors } from './useApolloErrors';

export const useRemoveTaskById = () => {
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();

  const [removeTask, { loading: removeTaskLoading, error: removeTaskError }] =
    useMutation(REMOVE_PROJECT_CUSTOM_TASK_BY_ID_MUTATION, {
      refetchQueries: [GET_PROJECT_ITEM_ESTIMATOR_TASKS_QUERY],
      onError: (error) => handleErrorResponse(error, 'Failed to remove task'),
      onCompleted: (data) => {
        if (data.removeTask.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully removed task',
          });
        }
      },
    });

  const handleRemoveTask = (taskId, projectItemId, site) => {
    removeTask({
      variables: {
        removeTaskId: taskId,
        projectItemId,
        site,
      },
    });
  };

  return {
    handleRemoveTask,
    removeTaskLoading,
    removeTaskError,
  };
};

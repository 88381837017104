import { Grid, TextField } from '@mui/material';
import React,{ useEffect } from 'react';
import { ProposalTemplateOption, SystemProposalView } from '../../proposal/system/SystemProposalView';
import { useParams } from 'react-router-dom';
import { useProjectConfigSettings } from '@cdw-selline/ui/hooks';
import { CiscoAnnuity } from '@cdw-selline/common/types';
import { getCiscoAnnuityActiveQuote } from '@cdw-selline/ui/helpers';

export interface CiscoAnnuityProposalProps {
  loading?: boolean;
  ciscoAnnuity: CiscoAnnuity;
}

export function CiscoAnnuityProposal({ciscoAnnuity}: CiscoAnnuityProposalProps) {
  const activeQuote = getCiscoAnnuityActiveQuote(ciscoAnnuity);
  const proposalTemplateKeys = activeQuote?.change
    ? ['Proposal Template Id', 'Change Proposal Template Id']
    : ['Proposal Template Id'];

  const { projectItemId, id } =
    useParams<{ projectItemId: string; id: string }>();
  const { data, loading } = useProjectConfigSettings({
    filters: {
      category: 'Cisco Annuity',
      key: proposalTemplateKeys,
    },
  });
  const proposalTemplateId = data.adminConfigSettings.map((value) => {
    if(value.key === 'Proposal Template Id') {
      return { value: value.value, label: 'Proposal' };
    }

    if(value.key === 'Change Proposal Template Id') {
      return { value: value.value, label: 'Change Proposal' };
    }
  }) as ProposalTemplateOption[];

  useEffect(() => {
    document.title = projectItemId;
  }, [projectItemId]);
  
  if(loading || !proposalTemplateId) {
    return null;
  }
  
  return (
    <Grid container>
      <Grid item xs={12}>
        <SystemProposalView
          proposalTemplateId={proposalTemplateId}
          projectItemId={projectItemId}
          projectId={id}
        />
      </Grid>
    </Grid>
  );
}

export default CiscoAnnuityProposal;

import { Dispatch, SetStateAction } from 'react';
import { ProjectItemEstimators } from '@cdw-selline/common/types';
import { useAgiloftExpenseTypes } from '../agiloft-expense-types';
import { useAgiloftTravelNoticeTypes } from '../agiloft-travel-notice-types';

const managedServicesCustomerTypeOptions = ['New', 'Existing'];
const managedServicesDealTypeOptions = ['New', 'Modify', 'Renewal'];
const managedServicesTransitionFeeTypeOptions = [
  'One-time Transition Fee (OTF) only',
  'Monthly Transition Fee (MTF) only',
  'Both OTF and MTF (default)',
  'Base Monthly Recurring Fee (Assumes no OTF and no MTF)',
];

export const useAgiloftEstimatorVariables = (
    setExpenseClauses: Dispatch<SetStateAction<string[]>>,
    setShowExpenseMiles: Dispatch<SetStateAction<boolean>>,
    setTravelClauses: Dispatch<SetStateAction<string[]>>,
    setShowTravelPenaltyAmount: Dispatch<SetStateAction<boolean>>,
    setFormData: (value)=>void) => {

const updatePrintTemplateType = (
    agiloftPrintTemplateType: string,
    estimator: ProjectItemEstimators,
    outcomeBase: boolean
    ) => {

        switch (agiloftPrintTemplateType) {
            case 'CSOW':
            case 'Services Proposal':
                if (outcomeBase) {
                    setFormData({
                        id: estimator.id,
                        projectItemId: estimator.projectItemId,
                        agiloftPrintTemplateType: 'Outcome Based Services Proposal',
                    });
                }
            break;
            case 'Outcome Based Services Proposal':
            case 'Outcome Based CSOW':
                if (!outcomeBase) {
                    setFormData({
                        id: estimator.id,
                        projectItemId: estimator.projectItemId,
                        agiloftPrintTemplateType: 'Services Proposal',
                    });
                }
            break;
        };
};

const updateTravelClauses = (
  agiloftTravelType: string,
  estimator: ProjectItemEstimators
) => {
  const agiloftTravelNoticeClause = estimator.agiloftTravelClause;

  const {
    travelNoticeClauses = [],
    showTravelNoticePenaltyAmount = false,
    agiloftDefaultTravelNoticeClause = null,
  } = agiloftTravelNoticeTypesResult?.agiloftTravelNoticeTypes.find(
    (type) => type.travelNoticeTypeName === agiloftTravelType
  ) || {};

  if (!travelNoticeClauses.includes(agiloftTravelNoticeClause)) {
    setFormData({
      id: estimator.id,
      projectItemId: estimator.projectItemId,
      agiloftTravelType: agiloftTravelType,
      agiloftTravelClause:
        agiloftDefaultTravelNoticeClause ?? travelNoticeClauses[0],
    });
  }
  setTravelClauses(travelNoticeClauses);
  setShowTravelPenaltyAmount(showTravelNoticePenaltyAmount);
};

const updateExpenseClauses = (
  agiloftExpenseType: string,
  estimator: ProjectItemEstimators,
  includesManagedServices: boolean,
  isPracticeManagedServices: boolean
) => {
  const agiloftExpenseClause = estimator.agiloftExpenseClause;

  const {
    expenseClauses = [],
    showExpenseMiles = false,
    agiloftDefaultExpenseClause = null,
    managedServicesExpenseClause = null,
  } = agiloftExpenseTypesResult?.agiloftExpenseTypes.find(
    (type) => type.expenseTypeName === agiloftExpenseType
  ) || {};
  

  if (!expenseClauses.includes(agiloftExpenseClause)) {
    setFormData({
      id: estimator.id,
      projectItemId: estimator.projectItemId,
      agiloftExpenseType: agiloftExpenseType,
      agiloftExpenseClause: (includesManagedServices || isPracticeManagedServices) ? managedServicesExpenseClause : agiloftDefaultExpenseClause ?? expenseClauses[0],
    });
  }
  setExpenseClauses(expenseClauses);
  setShowExpenseMiles(showExpenseMiles);
};

const { data: agiloftExpenseTypesResult } = useAgiloftExpenseTypes({});

const expenseTypes: string[] =
  agiloftExpenseTypesResult.agiloftExpenseTypes.map(
    (expenseType) => expenseType.expenseTypeName
  );

const { data: agiloftTravelNoticeTypesResult } = useAgiloftTravelNoticeTypes({});

const travelNoticeTypes: string[] =
  agiloftTravelNoticeTypesResult.agiloftTravelNoticeTypes.map(
    (travelNoticeType) => travelNoticeType.travelNoticeTypeName
  );

  return { 
    updateExpenseClauses,
    updateTravelClauses,
    updatePrintTemplateType,
    managedServicesCustomerTypeOptions, 
    managedServicesDealTypeOptions, 
    managedServicesTransitionFeeTypeOptions,
    expenseTypes,
    travelNoticeTypes
 };
};
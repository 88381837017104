import { gql } from '@apollo/client';

export const REMOVE_ADMIN_PRACTICE_MUTATION = gql`
  mutation removeAdminPracticeMutation($removePracticeId: ID!) {
    removeAdminPractice(id: $removePracticeId) {
      success
    }
  }
`;

// VARIABLES
// { practiceId: 'uuid' }

import { gql } from '@apollo/client';

export const REMOVE_ADMIN_TASK_MUTATION = gql`
  mutation removeTaskMutation($removeTaskId: String) {
    removeTask(id: $removeTaskId) {
      success
    }
  }
`;

// VARIABLES
// {
//   "removeTaskId": "id"
// }

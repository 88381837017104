import { gql } from '@apollo/client';

export const REMOVE_SCOPE_ITEM_MUTATION = gql`
  mutation RemoveScopeItem($removeScopeItemId: ID!) {
    removeScopeItem(id: $removeScopeItemId) {
      success
    }
  }
`;

/*
VARIABLES
{
  "removeScopeItemId": "id-goes-here'
}
*/

import { ROUTES } from '@cdw-selline/ui/constants';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NotificationsIcon from '@mui/icons-material/Notifications';

export const useAdminMenu = () => {
  return {
    topMenu: [
      {
        icon: DashboardIcon,
        label: 'Home',
        path: ROUTES.ADMIN_DASHBOARD,
      },
      {
        icon: AccountCircleIcon,
        label: 'Profile',
        path: ROUTES.PROFILE,
      },
      {
        icon: NotificationsIcon,
        label: 'User Notifications',
        path: ROUTES.NOTIFICATIONS,
      },
    ],
    menu: [],
  };
};

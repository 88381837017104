import { useMutation, useQuery } from '@apollo/client';
import { Task } from '@cdw-selline/common/types';
import {
  GET_ESTIMATOR_SUMMARY_TASKS_QUERY,
  GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY,
  GET_LATEST_GROUP_TASK_VERSION_QUERY,
  RESTORE_LATEST_GROUP_TASK_VERSION,
} from '@cdw-selline/ui/queries';

import { useApolloErrors } from '..';
import {  Dispatch, SetStateAction } from 'react';

export const useRestoreEstimatorTask = (
    completionCallback?: () => void,
    siteId?: string, projectItemId?: string,
    setFullSiteLoad?: Dispatch<SetStateAction<boolean>>,
    setFullSummarySiteLoad?: Dispatch<SetStateAction<boolean>>
  ) => {
  const { handleErrorResponse } = useApolloErrors();

  const { loading: isLatestLoading, error: isLatestError, data } = useQuery(
    GET_LATEST_GROUP_TASK_VERSION_QUERY,
    {
      variables: {
        siteId,
        projectItemId
      },
      fetchPolicy: 'network-only',
      skip: !(siteId && projectItemId),
      onError: (error) => handleErrorResponse(error, 'Failed to fetch latest version for Task Group!'),
    }
  );

  const [restoreEstimatorGroupTask, { loading: groupTaskLoading, error: groupTaskError, client: restoreClient }] = useMutation(
    RESTORE_LATEST_GROUP_TASK_VERSION,
    {
      onError: (error) =>
        handleErrorResponse(error, 'Failed to restore Project Group Task version!'),
      onCompleted: () => {
        setFullSiteLoad(true);
        setFullSummarySiteLoad(true);
        completionCallback();
        restoreClient.refetchQueries({
          include: [
            GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY,
            GET_ESTIMATOR_SUMMARY_TASKS_QUERY,
            GET_LATEST_GROUP_TASK_VERSION_QUERY],
        });
      }
    }
  );

  const handleTaskGroupRestore = (siteId: string, projectItemId: string) => {
    restoreEstimatorGroupTask({
      variables: {
        siteId,
        projectItemId
      },
    });
  };

  return { handleTaskGroupRestore, isLatestVersionTaskGroup: data?.getLatestVersionTaskGroup, groupTaskLoading, groupTaskError };
};

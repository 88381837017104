import { gql } from '@apollo/client';

export const GET_CDW_RATE_BY_ID_QUERY = gql`
  query getCdwRateByIdQuery($id: ID!) {
    getCdwRateById(id: $id) {
      id
      name
      cost
      agiloftRole
    }
  }
`;

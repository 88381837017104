// eslint-disable-next-line @nx/enforce-module-boundaries
import type { Lead } from '@cdw-selline/common/types';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { GridColDef, GridFilterModel } from '@mui/x-data-grid';
import React, { SetStateAction } from 'react';
import { CollectionsDataTable } from '../..';
import { escapeRegExp } from '../notifications-table/NotificationsTable';

export interface LeadTableProps {
  leads: Lead[]; // TODO: generate types from graphql codegen
  setUserFilter: React.Dispatch<SetStateAction<Record<string, unknown>>>;
  userState: boolean;
  userEmail: string;
}

export type LeadsColumn = GridColDef & {
  field: keyof Lead;
}

const columns: LeadsColumn[] = [
  {
    field: 'name',
    headerName: 'Call To Action',
    width: 200,
  },
  {
    field: 'customerDescription',
    headerName: 'Customer',
    width: 200,
  },
  {
    field: 'owner',
    headerName: 'Assigned',
    width: 200,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 200,
  },
  {
    field: 'associatedProjects',
    headerName: 'Project(s)',
    width: 200,
  },
  {
    field: 'id',
    headerName: 'Actions',
    width: 400,
    renderCell: () => (
      <strong>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          style={{ marginLeft: 0 }}
        >
          Create Project
        </Button>

        <Button
          variant="outlined"
          color="secondary"
          size="small"
          style={{ marginLeft: 5 }}
          disabled={true}
        >
          Assign
        </Button>
      </strong>
    ),
  },
];

export const LeadTable = (props: LeadTableProps) => {
  const data = {
    rows: props.leads || [],
    columns,
  };

  const [searchText, setSearchText] = React.useState('');
  const [rows, setRows] = React.useState<Lead[]>(data.rows);
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
  });

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = data.rows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row?.[field]?.toString?.());
      });
    });
    setRows(filteredRows);
  };

  React.useEffect(() => {
    setRows(data.rows);
    const filterData = JSON.parse(
      localStorage.getItem('lead-pipeline-filters')
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    filterData ? setFilterModel(filterData) : null;
  }, [data.rows]);

  const handleToggleChange = (e) => {
    e.target.checked
      ? props.setUserFilter({})
      : props.setUserFilter({ owner: props.userEmail });
  };

  const onFilterModelChange = (filterModel) => {
    setFilterModel(filterModel);
    localStorage.setItem('lead-pipeline-filters', JSON.stringify(filterModel));
  };

  return (
    <Card
      style={{
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
    >
      <CardHeader
        title="Leads"
        action={
          <FormControlLabel
            control={
              <Switch checked={props.userState} onChange={handleToggleChange} />
            }
            label="View all leads"
          />
        }
        sx={{ width: '100%' }}
      />
      <Divider />
      <CollectionsDataTable
        columns={columns}
        rows={rows}
        editMode="row"
        gridMargin={0}
        parent="TabPanel"
        saveOnEditStop={true}
      />
    </Card>
  );
};

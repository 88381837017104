import { gql } from '@apollo/client';

export const GET_RMS_GP_ANALYSIS_QUERY = gql`
  query getRmsGPAnalysisByItemId($projectItemId: String!) {
    getRmsGPAnalysisByItemId(projectItemId: $projectItemId) {
      rmsItems {
        siteId
        siteName
        categories {
          category
          lineItems {
            taskId
            id
            name
            sku
            unitCost
            unitGP
            unitGPPercent
            unitPrice
            qty
            totalCost
            totalGP
            totalPrice
            minGrossProfit
            grossProfit
          }
          totalSellPrice
        }
        totalSellPrice
      }
      totalMonthlyCharges
    }
  }
`;

import { gql } from '@apollo/client';

export const ADD_ROLE_MUTATION = gql`
  mutation addRoleMutation($params: AdminRoleInput) {
    addRole(params: $params) {
      id
      success
    }
  }
`;

// VARIABLES
// {
//   ...AdminTaskInput
// }

import { gql } from '@apollo/client';

export const UPDATE_CISCO_ANNUITY_MUTATION = gql`
  mutation updateCiscoAnnuity($params: CiscoAnnuityInput) {
    updateCiscoAnnuity(params: $params) {
      success
    }
  }
`;

export const RESET_CISCO_ANNUITY_CATEGORIES_MUTATION = gql`
  mutation resetCiscoAnnuityCategories($ciscoAnnuityId: String!) {
    resetCiscoAnnuityCategories(ciscoAnnuityId: $ciscoAnnuityId) {
      success
    }
  }
`;


import { Task } from '@cdw-selline/common/types';
import { useState, useCallback, useEffect } from 'react';

interface TaskState extends Omit<Task, 'sellPrice'> {
  sellPrice?: number | string;
}

export const useTaskState = (
  task: TaskState,
  addTaskToWriteQueue: (task: Task) => void
) => {
  const [taskState, setTaskState] = useState(task);
  const [taskFocus, setTaskFocus] = useState(false);

  useEffect(() => {
    setTaskState(task);
  }, [task]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updateDbAndState = (
        targetProperty: string,
        newValue: string | boolean | null | undefined | number
      ) => {
        const updatedTask = {
          id: task.id,
          [targetProperty]: newValue,
        };

        if (
          ['primaryRole', 'secondaryRole', 'overtime'].includes(targetProperty)
        ) {
          updatedTask.updatedAt = 0;
        }

        if (targetProperty === 'sellPrice') {
          if (typeof taskState.sellPrice === 'number') {
            updatedTask['sellPrice'] = taskState.sellPrice;
          }

          if (typeof taskState.sellPrice === 'string') {
            updatedTask['sellPrice'] = parseFloat(taskState.sellPrice);
          }

          if (!taskState.sellPrice) {
            updatedTask['sellPrice'] = 0;
          }
        }

        setTaskState({ ...taskState, [targetProperty]: newValue });
        addTaskToWriteQueue({...updatedTask, siteId: taskState.siteId});
      };

      const value = getTargetValue(event.target);
      updateDbAndState(event.target.name, value);
    },
    [task?.id, taskState, addTaskToWriteQueue, taskFocus]
  );

  const handleFocus = useCallback(() => {
    setTaskFocus(true);
  }, []);

  const handleBlur = useCallback(() => {
    setTaskFocus(false);
  }, []);

  const getTargetValue = (target)=> {
    if (!target.type) {
      return target.value;;
    }
    if (target.type === 'checkbox' && target.name !== 'quantity') {
        return Boolean(target.checked);
    }
    if (target.type === 'checkbox' && target.name === 'quantity') {
        return target.checked ? 1 : 0;
    }
    if (target.type === 'number' && target.name !== 'sellPrice') {
        return Number(target.value);
    }
    return target.value;
};

  return {
    getTargetValue,
    setTaskState,
    taskState,
    handleChange,
    handleFocus,
    handleBlur,
  };
};

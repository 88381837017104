import { gql } from '@apollo/client';

export const REMOVE_ADMIN_SOW_SECTION_MUTATION = gql`
  mutation removeSowSectionMutation($removeSowSectionId: ID!) {
    removeSowSection(id: $removeSowSectionId) {
      success
    }
  }
`;

// VARIABLES
// {
//   "removeRole": "id"
// }

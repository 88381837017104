import { gql } from '@apollo/client';

export const REMOVE_PROJECT_TEAM_MEMBER_MUTATION = gql`
  mutation removeProjectTeamMemberMutation($email: String!, $projectId: ID!) {
    removeProjectTeamMember(email: $email, projectId: $projectId) {
      success
    }
  }
`;

export default REMOVE_PROJECT_TEAM_MEMBER_MUTATION;

import { Box, Grid, styled } from '@mui/material';
import MUISkeleton from '@mui/lab/Skeleton';
import React from 'react';

const SkeletonContainer = styled(Box)(
  ({ theme }) => `
  min-width: "20rem";
  max-width: "26rem";
  min-height: "22rem";
`
);

export interface SkeletonProps {
  loading?: boolean;
  children?: React.ReactNode;
  message?: string;
}

export const Skeleton = (props: SkeletonProps) => {
  return (
    <Grid container wrap="nowrap">
      <Box
        sx={{
          width: 210,
          marginRight: '-5em',
          my: 5,
          ml: '5em',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {props.message}
      </Box>
      {Array.from(new Array(3)).map((item, index) => (
        <Box
          key={index}
          sx={{ width: 210, marginRight: 0.5, my: 5, ml: '5em' }}
        >
          <Box>
            <MUISkeleton />
            <MUISkeleton />
            <MUISkeleton />
          </Box>
        </Box>
      ))}
    </Grid>
  );
};

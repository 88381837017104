import { useQuery } from '@apollo/client';
import { ProjectComment } from '@cdw-selline/common/types';
import { GET_PROJECT_COMMENTS_BY_PROJECT_ID_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from '../../';

export const useGetProjectCommentsByProjectId = (projectId: string) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery<{
    getProjectCommentsByProjectId: ProjectComment[];
  }>(GET_PROJECT_COMMENTS_BY_PROJECT_ID_QUERY, {
    variables: { projectId },
    skip: !projectId,
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Project Comments!'),
  });

  return {
    data: data?.getProjectCommentsByProjectId ?? [],
    loading,
    error,
  };
};

import { useQuery } from '@apollo/client';
import { GET_SALES_REPS_TRENDING_QUERY } from '@cdw-selline/ui/queries';
import { GetSalesRepsTrendingResponse } from '@cdw-selline/common/types';

import { useApolloErrors } from './useApolloErrors';

export const useSalesRepsTrending = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery<{
    getSalesRepsTrending: GetSalesRepsTrendingResponse;
  }>(GET_SALES_REPS_TRENDING_QUERY, {
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Sales Reps Trending!'),
  });

  return {
    data: data?.getSalesRepsTrending ?? { salesReps: [], count: 0 },
    error,
    loading,
  };
};

export default useSalesRepsTrending;

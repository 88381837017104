import { ROUTES } from '@cdw-selline/ui/constants';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useNotFoundPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate(ROUTES.SIGN_IN);
    }, 5000);
  });
};

import { Box, Button, DialogActions, FormControl as Form, FormControl, IconButton, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material';
import DialogModal from '../../dialog-modal/DialogModal';
import React from 'react';
import {
  DataGridPro,
  GridRenderCellParams,
  GridRowModel,
  GridRowOrderChangeParams,
} from '@mui/x-data-grid-pro';
import { SITE_TABLE_COLUMN_VALUES, taskGroupConstants } from '@cdw-selline/ui/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InfoIcon from '@mui/icons-material/Info';
import { Editor } from 'tinymce';
import { getActionButtons } from '../../formHelperFunctions';
import { v4 as uuidv4 } from 'uuid';
import LoadingButton from '@mui/lab/LoadingButton';

export interface SiteTableModalProps {
  isOpen: boolean;
  handleClose: () => void;
  editor?: Editor;
}

interface CustomRowModel extends GridRowModel {
  id: string;
  columnName: string;
  type: string;
  value: string;
}

export function SiteTableModal({
  isOpen,
  handleClose,
  editor
}: SiteTableModalProps) {
  const [errorMessage, setErrorMessage] = React.useState('');
  const [rows, setRows] = React.useState<CustomRowModel[]>([]);
  const [taskId, setTaskID] = React.useState('');
  const [taskField, setTaskField] = React.useState('');
  const [isTaskSubModalOpen, setIsTaskSubModalOpen] = React.useState(false);
  const [taskSubString, setTaskSubString] = React.useState('');
  const [selectedRowId, setSelectedRowId] = React.useState<string | null>(null);

  const handleFormClose = () => {
    setRows([]);
    setErrorMessage('');
    handleClose();
  };

  const handleFormSubmit = () => {
    if (rows.length === 0) {
      setErrorMessage('Please add at least one row!');
      return;
    }

    // Validate that required fields are filled
    const hasEmptyFields = rows.some(
      (row) =>
        !row.columnName?.trim() || !row.type || (row.type === 'Task Id' && !row.value?.trim())
    );

    if (hasEmptyFields) {
      setErrorMessage('Fill out all required fields!');
      return;
    } else {
      setErrorMessage('');
    }

    const valueMap = {
      'Site Name': 'site-name',
      'Site Address': 'site-address',
      'Site Floor': 'site-floor',
      'Site Quantity': 'site-quantity',
    };
    const shortcode = rows
      .map((row) => {
        if (row.columnName && row.type) {
          return `{{ site-table-column
                      name="${row.columnName.trim()}"
                      value="${row.type === 'Task Id' ? row.value.trim() : valueMap[row.type]}"
                      type="${row.type}"
                  }}`;
        }
      }).join(' ');

    if (shortcode.length > 0) {
      const fullShortcode = `<replace class="mceNonEditable">{{ site-table }} ${shortcode} {{ end-site-table }}</replace>`;
      editor.insertContent(fullShortcode);
    }
    handleFormClose();
  };

  const handleAddRow = () => {
    const newRow = {
      id: uuidv4(),
      columnName: '',
      value: '',
      type: '',
    };
    setRows([...rows, newRow]);
  };

  const handleDeleteRow = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    const newRows = updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows
    );
    setRows(newRows);
  };

  const updateRowPosition = (
    initialIndex: number,
    newIndex: number,
    rows: Array<CustomRowModel>
  ) => {
    const rowsClone = [...rows];
    const row = rowsClone.splice(initialIndex, 1)[0];
    rowsClone.splice(newIndex, 0, row);
    return rowsClone;
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRows = rows.map((row) =>
      (row as CustomRowModel).id === (newRow as CustomRowModel).id
        ? { ...row, ...newRow }
        : row
    );
    setRows(updatedRows);
    return newRow;
  };

  const renderColumnNameCell = (params: GridRenderCellParams<any, any>) => {
    return (
      <TextField
        type="text"
        required={true}
        data-testid="columnNameCell"
        sx={{ width: '100%', marginTop: '2px' }}
        value={params.value as string}
        onChange={(e) => {
          const newValue = e.target.value;
          params.api.setEditCellValue({
            id: params.id,
            field: 'columnName',
            value: newValue,
          });
        }}
      />
    );
  };

  const renderTypeCell = (params: GridRenderCellParams<any, any>) => {
    return (
      <Select
        value={params.row.type || ''}
        sx={{ width: '100%' }}
        onChange={(e) => {
          const updatedRows = rows.map((row) =>
            row.id === params.row.id ? { ...row, type: e.target.value } : row
          );
          setRows(updatedRows);
        }}
        data-testid="typeCell"
      >
        {SITE_TABLE_COLUMN_VALUES.map((obj) => (
          <MenuItem value={obj.value} key={obj.value}>
            {obj.value}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const renderValueCell = (params: GridRenderCellParams<any, any>) => {
    if (params.row.type === 'Task Id') {
      return (
        <Tooltip title={taskSubString} arrow classes={{ tooltip: 'tool-tip-fontsize' }}>
          <Typography sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            height: 'inherit',
          }}>
            {params.row.value}
          </Typography>
        </Tooltip>
      );
    }
  };

  const renderActionCell = (params) => {
    return (
      <div>
        <DeleteIcon
          sx={{ marginTop: 2, marginLeft: 1 }}
          onClick={() => handleDeleteRow(params.row.id)}
        />
        {params.row.type === 'Task Id' && <AddCircleIcon
          sx={{ marginTop: 2, marginLeft: 1 }}
          onClick={() => handleAddTaskId(params.row.id)}
        >
        </AddCircleIcon>}
      </div>
    )
  }

  const handleAddTaskId = (rowId: string) => {
    setSelectedRowId(rowId);
    setIsTaskSubModalOpen(true);
  };

  const handleTaskSubModalClose = () => {
    setIsTaskSubModalOpen(false);
  };

  const handleTaskSubModalSubmit = () => {
    if (!(taskField && taskId)) {
      setErrorMessage('Fill out all required fields!');
      return;
    }

    const taskSubString = `{{ tasksub field="${taskField}" id="${taskId}" }}`;

    const updatedRows = rows.map(row =>
      row.id === selectedRowId && row.type === 'Task Id'
        ? { ...row, value: taskSubString }
        : row
    );
    setTaskSubString(taskSubString);

    setRows(updatedRows);
    setErrorMessage('');
    setTaskID('');
    setTaskField('');
    setSelectedRowId(null);
    handleTaskSubModalClose();
  }

  const columns = [
    {
      field: 'columnName',
      headerName: 'Column Name',
      width: 200,
      editable: true,
      renderCell: renderColumnNameCell,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 200,
      renderCell: renderTypeCell,
    },
    {
      field: 'value',
      headerName: 'Value',
      width: 200,
      renderCell: renderValueCell,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: renderActionCell
    },
  ];

  return (
    <>
      <DialogModal
        isOpen={isOpen}
        handleClose={handleFormClose}
        title={'Site Table'}
        maxWidth="100%"
        maxWidthProp={'lg'}
        action={getActionButtons(
          handleFormClose,
          handleFormSubmit,
        )}
      >
        <Stack
          direction="row"
          justifyContent="end"
          alignItems="center"
          padding="2px"
          spacing={1}
        >
          <Button size="large" onClick={handleAddRow}>
            + ADD
          </Button>
        </Stack>
        <Box>
          {errorMessage && (
            <Box sx={{ color: 'red', marginBottom: 2 }}>{errorMessage}</Box>
          )}
          <DataGridPro
            rows={rows}
            columns={columns}
            rowReordering
            onRowOrderChange={handleRowOrderChange}
            processRowUpdate={processRowUpdate}
            rowHeight={60}
            hideFooter={true}
            sx={{ minHeight: '200px' }}
          />
        </Box>
      </DialogModal>

      <DialogModal
        isOpen={isTaskSubModalOpen}
        handleClose={handleTaskSubModalClose}
        title={'New Task Sub'}
        maxWidth="sm"
        disableEnforceFocus={true}
      >
        <Form data-testid="task-modal-container">
          {errorMessage && <Box sx={{ marginTop: 2, marginBottom: 1 }}>
            <Typography color='error'>{errorMessage}</Typography>
          </Box>}
          <FormControl sx={{ width: '100%' }}>
            <Box sx={{ marginTop: 2, marginBottom: 1 }}>
              <Typography>Task ID</Typography>
            </Box>
            <TextField
              required={true}
              data-testid="taskid"
              sx={{ marginBottom: 2 }}
              value={taskId}
              onChange={(e) => setTaskID(e.target.value)}
            />
          </FormControl>

          <FormControl sx={{ width: '100%' }}>
            <Box sx={{ marginBottom: 1 }}>
              <Typography>Task Field</Typography>
            </Box>
            <Select
              required={true}
              sx={{ marginBottom: 2 }}
              value={taskField}
              onChange={(e) => setTaskField(e.target.value)}
              data-testid="taskField"
            >
              <MenuItem sx={{ height: '30px' }} value={0}></MenuItem>
              {taskGroupConstants.TASK_FIELD.map((tt) => (
                <MenuItem value={tt.value} key={`task-field-${tt.value}`}>
                  {tt.field}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DialogActions>
            <Button
              color="secondary"
              onClick={handleTaskSubModalClose}
              data-testid="new-Task-cancel-button"
            >
              Cancel
            </Button>
            <LoadingButton
              color="primary"
              type="submit"
              variant="contained"
              data-testid="new-Task-submit-button"
              onClick={handleTaskSubModalSubmit}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </Form>
      </DialogModal>
    </>
  );
}

export default SiteTableModal;


import { gql } from '@apollo/client';

export const ADD_TIER_MUTATION = gql`
  mutation addTierMutation($params: AdminTierInput) {
    addTier(params: $params) {
      id
      success
    }
  }
`;

// VARIABLES
// {
//   ...TierInput
// }

import { format, formatDistanceToNow } from 'date-fns';
import React from 'react';

export const getDecimalCount = (number: number) => {
  if (number % 1 === 0) {
    return 0;
  }

  const decimalPlaces = number.toString().split('.')[1];

  return decimalPlaces.length;
};

export const formatCurrency = (value: number, digets = 2): React.ReactNode => {
  if (!value) value = 0;
  
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digets,
    maximumFractionDigits: digets,
  }).format(value);
};

export const formatPercent = (value: number): React.ReactNode => {
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 2,
  }).format(value / 100);
};

export const formatDate = (
  date: string,
  dateFormat: string
): React.ReactNode => {
  if(!date) {
    return '';
  }

  if (date.includes('T00:00:00.000')) {
    return format(new Date(date), dateFormat) ?? '';
  }
  return format(new Date(date + 'T00:00:00.000'), dateFormat) ?? '';
};

export const formatDateDistanceToNow = (date: string): React.ReactNode => {
  return formatDistanceToNow(new Date(date)) ?? '';
};

export const formatBoolean = (value: unknown): boolean => {
  const data = value?.toString().toLocaleLowerCase().trim();
  if (!data || data === 'false') {
    return false;
  }
  return true;
};

export const formatNumber = (value: number, decimalPlaces: number): React.ReactNode => {
  if (!value) {
    return 0;
  }

  if(getDecimalCount(value) === 0) {
    return value;
  }

  return value.toFixed(decimalPlaces);
}
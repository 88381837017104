import { useMutation } from '@apollo/client';
import {
  DefaultAddResponse,
  DefaultMutationResponse,
  Role,
} from '@cdw-selline/common/types';
import {
  ADD_ROLE_MUTATION,
  GET_ROLES_QUERY,
  GET_ROLE_BY_ID_QUERY,
  REMOVE_ADMIN_ROLE_MUTATION,
  UPDATE_ROLE_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import {
  GridColDef,
  GridPaginationModel,
  GridFilterModel,
} from '@mui/x-data-grid';
import { useState } from 'react';
import { useApolloErrors } from './useApolloErrors';
import { useRoles } from './useRoles';
import { useOpenState } from './estimator/useOpenState';
import omitDeep from 'omit-deep-lodash';
import {
  getFilters,
  getFilterModelFromLocalStorage,
} from '@cdw-selline/ui/helpers';
import moment from 'moment';
import { PAGES } from '@cdw-selline/ui/constants';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Role ID',
    width: 180,
    editable: false,
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Role Name',
    width: 180,
    flex: 1,
  },
  {
    field: 'cdwRateName',
    headerName: 'Rate Name',
    width: 180,
    flex: 1,
  },
  {
    field: 'practiceName',
    headerName: 'Practice',
    width: 220,
    flex: 1,
  },
  {
    field: 'cost',
    headerName: 'Cost',
    width: 220,
    flex: 1,
  },
  {
    field: 'rate',
    headerName: 'Rate',
    width: 220,
    flex: 1,
  },
  {
    field: 'projectManager',
    headerName: 'PM',
    type: 'boolean',
    width: 220,
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Created Date',
    type: 'dateTime',
    width: 180,
    editable: false,
    valueGetter: (value) => value && new Date(value),
    valueFormatter: (value) =>
      value ? moment(value).format('MM/DD/YYYY') : '',
  },
];

export const useAdminRoles = () => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  const [sortState, setSortState] = useState();

  const filterModel: GridFilterModel = getFilterModelFromLocalStorage(`${PAGES.ROLES_COLLECTION}-filter`);

  const { data, loading, error, refetch } = useRoles({
    filters: getFilters(PAGES.ROLES_COLLECTION),
    offset: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize,
    sort: sortState,
  });
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();
  const [editRoleId, setEditRoleId] = useState(null);
  const {
    isOpen: roleFormOpen,
    handleClose: closeRoleForm,
    handleOpen: openRoleForm,
  } = useOpenState();

  const [addRole, { loading: addRoleLoading, error: addRoleError }] =
    useMutation<{ addRole: DefaultAddResponse }>(ADD_ROLE_MUTATION, {
      refetchQueries: [GET_ROLES_QUERY, GET_ROLE_BY_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to create new role'),
      onCompleted: (data) => {
        if (data.addRole.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully added role',
          });
        }
      },
    });

  const [removeRole, { loading: removeRoleLoading, error: removeRoleError }] =
    useMutation<{ removeRole: DefaultMutationResponse }>(
      REMOVE_ADMIN_ROLE_MUTATION,
      {
        refetchQueries: [GET_ROLES_QUERY],
        onError: (error) => handleErrorResponse(error, 'Failed to remove role'),
        onQueryUpdated(observableQuery) {
          return observableQuery.refetch();
        },
        onCompleted: (data) => {
          if (data.removeRole.success) {
            alertState.setAlert({
              type: ALERT_TYPE.SNACKBAR,
              severity: ALERT_SEVERITY.SUCCESS,
              message: 'Successfully removed role',
            });
          }
        },
      }
    );

  const [updateRole, { loading: updateRoleLoading, error: updateRoleError }] =
    useMutation<{ updateRole: DefaultMutationResponse }>(UPDATE_ROLE_MUTATION, {
      refetchQueries: [GET_ROLES_QUERY, GET_ROLE_BY_ID_QUERY],
      awaitRefetchQueries: true,
      onError: (error) => handleErrorResponse(error, 'Failed to update role'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.updateRole.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully updated role',
          });
        }
      },
    });

  const handleAdd = () => {
    openRoleForm();
  };

  const handleRoleFormClose = () => {
    setEditRoleId(null);
    closeRoleForm();
  };

  const handleDelete = (pid: string) =>
    removeRole({
      variables: { removeRoleId: pid },
    });
  const handleEdit = (id: string) => {
    setEditRoleId(id);
    openRoleForm();
  };

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    const pageSize = model?.pageSize ?? paginationModel?.pageSize;
    const page = model?.page ?? paginationModel?.page;
    setPaginationModel({
      page,
      pageSize,
    });
  };

  const onFilterModelChange = (filterModel) => {
    refetch({ filters: getFilters(PAGES.ROLES_COLLECTION) });
  };

  const handleRoleFormSave = (role: Role) => {
    if (!role.id) {
      addRole({
        variables: {
          params: omitDeep(role, ['__typename', 'cost']),
        },
      });
    }

    if (role.id) {
      updateRole({
        variables: {
          params: omitDeep(role, ['__typename', 'cost']),
        },
      });
    }
  };

  const handleSort = (args) => {
    let newSort;
    if (args.length) newSort = { [args[0].field]: args[0].sort };
    setSortState(newSort);
  };
  const returnValue = {
    columns,
    rows: data.roles || [],
    handleAdd,
    handleDelete,
    handleEdit,
    handlePaginationModelChange,
    onFilterModelChange,
    handleSort,
    filterModel: filterModel,
    roles: data.roles,
    editRoleId,
    addRoleLoading,
    addRoleError,
    removeRoleLoading,
    removeRoleError,
    loading: loading || addRoleLoading || removeRoleLoading,
    error,
    roleFormOpen,
    handleRoleFormClose,
    handleRoleFormSave,
    rowCount: data.count,
  };

  if (loading || addRoleLoading || removeRoleLoading) {
    return {
      ...returnValue,
      paginationModel: { pageSize: 100, page: 0 },
      rowCount: 0,
      rows: [],
    };
  } else {
    return { ...returnValue, paginationModel };
  }
};

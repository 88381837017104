import { gql } from '@apollo/client';

export const GET_ADMIN_LIST_TASKS_QUERY = gql`
  query GetTasks(
    $filters: MongoFilter
    $limit: Int
    $offset: Int
    $sort: MongoFilter
  ) {
    getTasks {
      count(filters: $filters)
      tasks(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
        order

        id
        type
        practiceId

        taskId
        name
        estimatorLocation

        units
        category
        comment

        quantity
        quantityFormula
        quantityMin
        quantityMax
        disableQuantity
        disableQuantityFormula

        hours
        hoursFormula
        disableHours
        disableHoursFormula
        allowTotalHoursAdjustment
        totalHoursNoRounding
        hideHours

        listNames
        listValues
        dropDownIndex
        dropDownIndexFormula

        noValue
        yesValue

        textType
        text
        textFormula
        instructionsText
        textDisabled
        textDisabledFormula
        allowBlankText

        managedServices
        isManagedServicesCostCalculation
        managedServicesFteFormula
        managedServicesArcRrcFormula
        managedServicesTransitionFormula

        costType
        productType
        cost
        costFormula
        minGrossProfit
        grossProfit
        defaultGrossProfit
        disableCost
        disableCostFormula
        allowMarkup
        alwaysShowOnProposal
        volumePricingEnabled
        volumePricing {
          minQuantity
          maxQuantity
          cost
        }
        sellPrice
        sellPriceFormula
        
        proposalShowEmpty
        proposalNotes

        version

        tierId
        tier

        sowSectionId

        overtime
        isProjectManagement

        #primaryRoleName
        primaryRoleId
        primaryRole
        primaryPercent
        allowModifyPrimaryRoleRate
        primaryRoleRateOverride
        primaryRoleRateOverrideFormula

        #secondaryRoleName
        secondaryRoleId
        secondaryRole
        secondaryPercent
        allowModifySecondaryRoleRate
        secondaryRoleRateOverride
        secondaryRoleRateOverrideFormula

        #tertiaryRoleName
        tertiaryRoleId
        tertiaryRole
        tertiaryPercent
        allowModifyTertiaryRoleRate
        tertiaryRoleRateOverride
        tertiaryRoleRateOverrideFormula

        hide
        hideShowFormula

        exclude

        vendor

        enrollmentCost
        enrollmentCostFormula
        enrollmentHours
        enrollmentRole
        enrollmentHoursFormula

        sprint

        skill
        skillId
        skillFormula
        serviceItem
        serviceItemId
        serviceItemFormula

        sku
        edc
        isExpense
        isTravelTime
      }
    }
  }
`;

export default GET_ADMIN_LIST_TASKS_QUERY;

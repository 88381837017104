import { useMutation, useQuery } from '@apollo/client';
import {
  DefaultMutationResponse,
  CiscoAnnuityLine,
} from '@cdw-selline/common/types';
import {
  GET_CISCO_ANNUITY_QUOTE_BY_PROJECT_ITEM_ID_QUERY,
  UPDATE_CISCO_ANNUITY_LINES_MUTATION,
  GET_CISCO_ANNUITY_ADMIN_QUOTE_BY_PROJECT_ITEM_ID_QUERY,
  RESET_CISCO_ANNUITY_CATEGORIES_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { useEffect, useRef, useState } from 'react';
import { omit } from 'lodash';

import { useApolloErrors } from '..';

export const useAdminCiscoAnnuityLines = (projectItemId: string) => {
  const alertState = useAlertsState();
  const { handleErrorResponse } = useApolloErrors();

  const { loading, error, data: ciscoAnnuityLines } = useQuery<{
    getCiscoAnnuityAdminQuoteByProjectItemId: CiscoAnnuityLine[];
  }>(GET_CISCO_ANNUITY_ADMIN_QUOTE_BY_PROJECT_ITEM_ID_QUERY, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    variables: { projectItemId },
    skip: !projectItemId,
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Cisco Annuity!'),
  });

  const filterTotals = (lines: CiscoAnnuityLine[]) => {
    return lines.filter((line) => line.category !== 'totals');
  };
  // const tableColumns = useRef([
  //   'Annuity Audio',
  //   'Annuity Auto Renew Term',
  //   'Annuity Billing Cycle',
  //   'Annuity Term',
  //   'Audio Tier',
  //   'Category',
  //   'CDW Discount',
  //   'CDW Price',
  //   'CDW Total Price',
  //   'Credit Json',
  //   'Customer Discount',
  //   'Customer Price',
  //   'Customer Total Price',
  //   'Customer Term Total',
  //   'Description',
  //   'Discount',
  //   'Discount Json',
  //   'Extended Net Price',
  //   'Extention Datetime 0',
  //   'Extention Datetime 1',
  //   'Extention Number 0',
  //   'Extention Number 1',
  //   'Extention Value 0',
  //   'Extention Value 1',
  //   'Extention Value 2',
  //   'Extention Value 3',
  //   'Extention Value 4',
  //   'Extention Value 5',
  //   'GP',
  //   'id',
  //   'Line Id',
  //   'is Discount',
  //   'Lead Time',
  //   'Line',
  //   'Parent',
  //   'Part Number',
  //   'Quantity',
  //   'quote Id',
  //   'Service Duration',
  //   'Time Bound Credit Amount',
  //   'Time Bound Credit Duration',
  //   'Time Bound Credit Free To Customer',
  //   'Time Bound Credit From',
  //   'Time Bound Credit Keep',
  //   'Time Bound Credit Name',
  //   'Time Bound Credit Type',
  //   'Unit List Price',
  //   'Unit Net Price',
  //   'Unit Of Measure',
  //   'New Billing Amount',
  //   'Billing Amount Net Change',
  //   'Current Contract Amount',
  //   'New Contract Amount',
  //   'Contract Amount Net Change',
  //   'Old Quantity'
  // ]);

  const tableColumns = useRef([
    'Id',
    'Line',
    'Part Number',
    'Category',
    'Description',
    'Quantity',
    'Unit Of Measure',
    'Unit List Price',
    'Customer Discount',
    'Customer Price',
    'Customer Total Price',
    'Customer Term Total',
    'Discount',
    'CDW Discount',
    'CDW Price',
    'CDW Total Price',
    'Extended Net Price',
    'Unit Net Price',
    'GP',
    'Charge Type',
    'Term',
    'Start',
    'Billing Cycle',
  ]);

  const getTableRows = (lines: CiscoAnnuityLine[]) => {
    return lines
      ? lines.map((line) => [
          line.id,
          line.line,
          line.partNumber,
          line.category,
          line.description,
          line.quantity,
          line.unitOfMeasure,
          line.unitListPrice,
          line.customerDiscount,
          line.customerPrice,
          line.customerTotalPrice,
          line.customerTermTotal,
          line.discount,
          line.cdwDiscount?.toFixed(2),
          line.cdwPrice,
          line.cdwTotalPrice,
          line.extendedNetPrice,
          line.unitNetPrice,
          line.gp,
          line.chargeType,
          line.term,
          line.start,
          line.billingCycle,

          // line.annuityAudio,
          // line.annuityAutoRenewTerm,
          // line.annuityBillingCycle,
          // line.annuityTerm,
          // line.audioTier,
          // line.category,
          // line.cdwDiscount,
          // line.cdwPrice,
          // line.cdwTotalPrice,
          // line.creditJson,
          // line.customerDiscount,
          // line.customerPrice,
          // line.customerTotalPrice,
          // line.customerTermTotal,
          // line.description,
          // line.discount,
          // line.discountJson,
          // line.extendedNetPrice,
          // line.extentionDatetime0,
          // line.extentionDatetime1,
          // line.extentionNumber0,
          // line.extentionNumber1,
          // line.extentionValue0,
          // line.extentionValue1,
          // line.extentionValue2,
          // line.extentionValue3,
          // line.extentionValue4,
          // line.extentionValue5,
          // line.gp,
          // line.id,
          // line.lineId,
          // line.isDiscount,
          // line.leadTime,
          // line.line,
          // line.parent,
          // line.partNumber,
          // line.quantity,
          // line.quoteId,
          // line.serviceDuration,
          // line.timeBoundCreditAmount,
          // line.timeBoundCreditDuration,
          // line.timeBoundCreditFreeToCustomer,
          // line.timeBoundCreditFrom,
          // line.timeBoundCreditKeep,
          // line.timeBoundCreditName,
          // line.timeBoundCreditType,
          // line.unitListPrice,
          // line.unitNetPrice,
          // line.unitOfMeasure,
          // line.newBillingAmount,
          // line.billingAmountNetChange,
          // line.currentContractAmount,
          // line.newContractAmount,
          // line.contractAmountNetChange,
          // line.oldQuantity
        ])
      : [Array(tableColumns.current.length)];
  };

  const [tableData, setTableData] = useState(
    ciscoAnnuityLines?.getCiscoAnnuityAdminQuoteByProjectItemId ? getTableRows(filterTotals(ciscoAnnuityLines.getCiscoAnnuityAdminQuoteByProjectItemId)) : []
  );

  useEffect(() => {
    if (ciscoAnnuityLines?.getCiscoAnnuityAdminQuoteByProjectItemId.length > 0) {
      setTableData(getTableRows(filterTotals(ciscoAnnuityLines.getCiscoAnnuityAdminQuoteByProjectItemId)));
    }
  }, [ciscoAnnuityLines]);

  const [
    updateLines,
    { loading: updateLinesLoading, error: updateLinesError, client },
  ] = useMutation<{ updateCiscoAnnuityLines: DefaultMutationResponse }>(
    UPDATE_CISCO_ANNUITY_LINES_MUTATION,
    {
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update cisco annuity lines'),
      onCompleted: (ciscoAnnuityLines) => {
        if (ciscoAnnuityLines.updateCiscoAnnuityLines.success) {
          alertState.setAlert({
            type: ALERT_TYPE.MODAL,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully saved cisco annuity lines',
          });
        }
        client.refetchQueries({
          include: [
            GET_CISCO_ANNUITY_ADMIN_QUOTE_BY_PROJECT_ITEM_ID_QUERY,
          ],
        });
      },
    }
  );

  const handleLinesSave = (lines: CiscoAnnuityLine[]) => {
    updateLines({
      variables: {
        params: lines.map((line) => omit(line, ['__typename'])),
      },
    });
  };

  const [resetCategories, { loading: resetLoading, error: resetError }] = useMutation(
    RESET_CISCO_ANNUITY_CATEGORIES_MUTATION,
    {
      refetchQueries: [GET_CISCO_ANNUITY_ADMIN_QUOTE_BY_PROJECT_ITEM_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to reset categories for Cisco Annuity!'),
      onCompleted: (data) => {
        if (data.resetCiscoAnnuityCategories.success) {
          alertState.setAlert({
            type: ALERT_TYPE.MODAL,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully reset cisco annuity lines categories',
          });
        }
      },
    }
  );

  const handleResetCategories = (ciscoAnnuityId: string) => {
    resetCategories({
      variables: {
        ciscoAnnuityId,
      },
    });
  };

  return {
    lines: ciscoAnnuityLines?.getCiscoAnnuityAdminQuoteByProjectItemId ?? [],
    loading,
    error,
    tableData,
    tableColumns: tableColumns.current,
    handleLinesSave,
    handleResetCategories,
  };
};

import {
  CollectionsDataTable,
  ScopeGroupForm,
} from '@cdw-selline/ui/components';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { useAdminScopeGroups, useScopeGroupById } from '@cdw-selline/ui/hooks';

export function ScopeGroupsCollectionPage() {
  const scopeGroupsColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 180,
      editable: false,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Scope Group Name',
      width: 180,
      flex: 1,
    },
    {
      field: 'practiceId',
      headerName: 'Practice',
      width: 220,
      flex: 1,
    },
  ];

  const dataTableProps = useAdminScopeGroups();

  const {
    data: scopeGroup,
    loading: scopeGroupLoading,
    error: scopeGroupError,
  } = useScopeGroupById(dataTableProps.editScopeGroupId);

  const openForm =
    dataTableProps.scopeGroupFormOpen &&
    ((dataTableProps.editScopeGroupId && scopeGroup.id) ||
      !dataTableProps.editScopeGroupId);

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <CollectionsDataTable
        {...dataTableProps}
        height="calc(100vh - 13em)"
        paginationMode="server"
        sortingMode='server'
        onRowClick={(o) => dataTableProps.handleEdit(o.id)}
        parent="ScopeGroupCollectionPage"
      />
      {openForm && (
        <ScopeGroupForm
          isOpen={dataTableProps.scopeGroupFormOpen}
          handleClose={dataTableProps.handleScopeGroupFormClose}
          handleSubmit={dataTableProps.handleScopeGroupFormSave}
          scopeGroup={scopeGroup}
        />
      )}
    </Box>
  );
}

export default ScopeGroupsCollectionPage;

import { gql } from '@apollo/client';

export const ADD_AGILOFT_SOW_CONTENT_MUTATION = gql`
  mutation AddAgiloftSowContent($params: AgiloftSowConnectionInput!) {
    addAgiloftSowContent(params: $params) {
      success
    }
  }
`;

export const CLEAR_AGILOFT_FEE_TABLE_MUTATION = gql`
  mutation ClearAgiloftFeeTable($params: AgiloftSowConnectionInput!) {
    clearAgiloftFeeTable(params: $params) {
      success
    }
  }
`;

export const BUILD_AGILOFT_FEE_TABLE_MUTATION = gql`
  mutation BuildAgiloftFeeTable($params: AgiloftSowConnectionInput!) {
    buildAgiloftFeeTable(params: $params) {
      success
      buildIndex
      buildContinue
    }
  }
`;

export const VALIDATE_READY_FOR_AGILOFT = gql`
  mutation ValidateReadyForAgiloft($params: AgiloftSowConnectionInput!) {
    validateReadyForAgiloft(params: $params) {
      success
      error
    }
  }
`;

export const GENERATE_AGILOFT_DOCUMENTS = gql`
  mutation GenerateAgiloftDocuments($params: AgiloftSowConnectionInput!) {
    generateAgiloftDocuments(params: $params) {
      success
      error
    }
  }
`;

export const UPDATE_AGILOFT_FEE_SUMMARY_MUTATION = gql`
  mutation UpdateAgiloftFeeSummary($params: AgiloftSowConnectionInput!) {
    updateAgiloftFeeSummary(params: $params) {
      success
    }
  }
`;

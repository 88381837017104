import { gql } from '@apollo/client';

export const SEARCH_SERVICE_SKILLS_QUERY = gql`
  query SearchServiceSkills($searchTerm: String!) {
    searchServiceSkills(searchTerm: $searchTerm) {
      serviceSkills {
        id
        name
      }
    }
  }
`;

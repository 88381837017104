import { gql } from '@apollo/client';

export const GET_INVESTMENT_SUMMARY_QUERY = gql`
  query getInvestmentSummaryQuery($id: ID!) {
    getInvestmentSummaryByEstimatorId(projectItemId: $id) {
      hwsw
      servicesTotal
      managedServicesTotal
      managedServicesName
      tAndETotal
      total
    }
  }
`;

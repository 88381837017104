import { useMutation } from '@apollo/client';
import {
  UPDATE_PROJECT_ITEM_QUOTE_MUTATION,
  GET_PROJECT_ITEM_QUOTE_BY_ID_QUERY,
  GET_PROJECT_ITEM_QUOTES_BY_PROJECT_ITEM_ID_QUERY,
  REMOVE_QUOTE_LINES_MUTATION,
  ADD_QUOTE_LINES_MUTATION,
  UPDATE_QUOTE_LINES_MUTATION,
} from '@cdw-selline/ui/queries';
import { useApolloErrors } from '../useApolloErrors';
import {
  ProjectItemQuoteInput,
  ProjectItemQuoteLineInput,
} from '@cdw-selline/common/types';

export const useUpdateProjectItemQuote = (completeCallback?: () => void) => {
  const { handleErrorResponse } = useApolloErrors();

  const [updateProjectItemQuote, { loading, error }] = useMutation(
    UPDATE_PROJECT_ITEM_QUOTE_MUTATION,
    {
      refetchQueries: [
        GET_PROJECT_ITEM_QUOTE_BY_ID_QUERY,
        GET_PROJECT_ITEM_QUOTES_BY_PROJECT_ITEM_ID_QUERY,
      ],
      onError: (error) => handleErrorResponse(error, 'Failed to update Quote!'),
      onCompleted: (data) => {
        completeCallback?.();
      },
    }
  );

  const [
    addQuoteLines,
    { loading: addQuoteLinesLoading, error: addQuoteLinesError },
  ] = useMutation(ADD_QUOTE_LINES_MUTATION, {
    refetchQueries: [
      GET_PROJECT_ITEM_QUOTE_BY_ID_QUERY,
      GET_PROJECT_ITEM_QUOTES_BY_PROJECT_ITEM_ID_QUERY,
    ],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to add Quote Lines!'),
    onCompleted: (data) => {
      completeCallback?.();
    },
  });

  const [
    updateQuoteLines,
    { loading: updateQuoteLinesLoading, error: updateQuoteLinesError },
  ] = useMutation(UPDATE_QUOTE_LINES_MUTATION, {
    refetchQueries: [
      GET_PROJECT_ITEM_QUOTE_BY_ID_QUERY,
      GET_PROJECT_ITEM_QUOTES_BY_PROJECT_ITEM_ID_QUERY,
    ],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to update Quote Lines!'),
    onCompleted: (data) => {
      completeCallback?.();
    },
  });

  const [
    removeQuoteLines,
    { loading: removeQuoteLinesLoading, error: removeQuoteLinesError },
  ] = useMutation(REMOVE_QUOTE_LINES_MUTATION, {
    refetchQueries: [
      GET_PROJECT_ITEM_QUOTE_BY_ID_QUERY,
      GET_PROJECT_ITEM_QUOTES_BY_PROJECT_ITEM_ID_QUERY,
    ],
    onError: (error) => handleErrorResponse(error, 'Failed to add Quote Line!'),
    onCompleted: (data) => {
      completeCallback?.();
    },
  });

  const handleUpdateProjectItemQuote = (
    modifiedQuote: ProjectItemQuoteInput
  ) => {
    updateProjectItemQuote({
      variables: {
        params: modifiedQuote,
      },
    });
  };

  const handleAddQuoteLines = (
    projectItemId: string,
    quoteLines: ProjectItemQuoteLineInput[]
  ) => {
    addQuoteLines({
      variables: {
        projectItemId,
        quoteLines,
      },
    });
  };

  const handleUpdateQuoteLines = (
    projectItemId: string,
    updatedQuoteLines: ProjectItemQuoteLineInput[]
  ) => {
    updateQuoteLines({
      variables: {
        projectItemId,
        updatedQuoteLines,
      },
    });
  };

  const handleRemoveQuoteLines = (
    projectItemId: string,
    quoteLineIds: string[]
  ) => {
    removeQuoteLines({
      variables: {
        projectItemId,
        quoteLineIds,
      },
    });
  };

  return {
    handleUpdateProjectItemQuote,
    handleAddQuoteLines,
    handleUpdateQuoteLines,
    handleRemoveQuoteLines,
    loading:
      loading ||
      addQuoteLinesLoading ||
      updateQuoteLinesLoading ||
      removeQuoteLinesLoading,
    error:
      error ||
      addQuoteLinesError ||
      updateQuoteLinesError ||
      removeQuoteLinesError,
  };
};

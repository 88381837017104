import { SignIn } from '@cdw-selline/ui/components';
import firebase from 'firebase/auth';
import React from 'react';
import { useSignInPage } from './useLoginPage';

export interface LoginPageProps {
  user?: firebase.User;
}

export const LoginPage = (props?: LoginPageProps) => {
  const signInPageProps = useSignInPage();
  const pageProps = { ...props, ...signInPageProps };
  return <SignIn {...pageProps} />;
};

export default LoginPage;

import { CiscoQuote, CiscoAnnuityLineTerm } from '@cdw-selline/common/types';
import React, { useState } from 'react';
import DialogModal from '../../dialog-modal/DialogModal';
import {
    Grid,
    Radio,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@mui/material';
import {
  getActionButtons,
} from '../../formHelperFunctions';

export interface CiscoAnnuityLineTermsProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (quoteTerm: CiscoAnnuityLineTerm) => void;
  quote: CiscoQuote
}


export function CiscoAnnuityLineTerms({
  isOpen,
  handleClose,
  handleSubmit,
  quote,
}: CiscoAnnuityLineTermsProps) {
  const [selectedLineTerm, setSelectedLineTerm] = useState(quote.term);

  const handleSaveClick = () => {
    const quoteLineTerm = quote.lineTerms.find(lineTerm=> lineTerm.term ===selectedLineTerm);
    if (quoteLineTerm) {
        handleSubmit(quoteLineTerm);
        handleClose();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>)=> {
    setSelectedLineTerm(Number(event.target.value));
  }

  return (
    <DialogModal
      fullWidth={true}
      isOpen={isOpen}
      handleClose={handleClose}
      title={'Select Quote Term'}
      action={getActionButtons(handleClose, handleSaveClick)}
      maxWidthProp={'md'}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="row"
        spacing={1}
        mt={1}
        sx={{ width: '85vh' }}
        data-testid="line-term-grid"
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="column"
          spacing={1}
          sx={{ width: '100%', padding: 2}}
        >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell width={'5%'} align="center"></TableCell>
                      <TableCell width={'15%'} >Part Number</TableCell>
                      <TableCell width={'15%'}>Term</TableCell>
                      <TableCell width={'15%'}>Auto Renewal</TableCell>
                      <TableCell width={'15%'}>Billing Frequency</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {quote.lineTerms.map((lineTerm) => (
                        <TableRow hover key={lineTerm.term}>
                          <TableCell align="center">
                            <Radio
                                name='line-terms-radio'
                                checked={lineTerm.term === selectedLineTerm}
                                value={lineTerm.term}
                                onChange={handleChange}/>
                          </TableCell>
                          <TableCell>
                            {lineTerm.partNumber}
                          </TableCell>
                          <TableCell>
                            {lineTerm.term}
                          </TableCell>
                          <TableCell>
                            {lineTerm.autoRenew.toString()}
                          </TableCell>
                          <TableCell>
                            {lineTerm.billingCycle}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
        </Grid>
      </Grid>
    </DialogModal>
  );
}

import { gql } from '@apollo/client';

export const UPDATE_TIER_MUTATION = gql`
  mutation updateTierMutation($params: AdminTierInput) {
    updateTier(params: $params) {
      success
    }
  }
`;

// VARIABLES
// {
//   ...TierInput
// }

import { gql } from '@apollo/client';

export const GET_ESTIMATOR_BID_ASSURANCE_NOTES_QUERY = gql`
  query getEstimatorBidAssuranceNotes($projectItemId: String!) {
    getEstimatorBidAssuranceNotes(projectItemId: $projectItemId) {
        id
        note
        createdAt
        createdBy
    }
  }
`;
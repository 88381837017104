import { ScopeItemQuestionTypes } from '@cdw-selline/common/types';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React from 'react';

export interface QuestionTypesSelectProps {
  selectedType: ScopeItemQuestionTypes | '';
  handleTypeSelect: (e: SelectChangeEvent) => void;
}

export function QuestionTypesSelect({
  selectedType,
  handleTypeSelect,
}: QuestionTypesSelectProps) {
  const questionTypeOptions = Object.values(ScopeItemQuestionTypes);
  return (
    <FormControl fullWidth>
      <InputLabel id="question-type-select-label">Question Type</InputLabel>
      <Select
        labelId="question-type-select-label"
        id="question-type-select"
        label="Question Type"
        value={selectedType}
        onChange={handleTypeSelect}
      >
        {questionTypeOptions.map((questionType, idx) => (
          <MenuItem key={questionType + idx} value={questionType}>
            {questionType}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default QuestionTypesSelect;

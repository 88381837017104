import { useMutation } from '@apollo/client';
import {
  DefaultAddResponse,
  DefaultMutationResponse,
  Practice,
} from '@cdw-selline/common/types';
import {
  ADD_PRACTICE_MUTATION,
  GET_PRACTICES_QUERY,
  GET_PRACTICE_BY_ID_QUERY,
  REMOVE_ADMIN_PRACTICE_MUTATION,
  UPDATE_PRACTICE_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import {
  GridColDef,
  GridPaginationModel,
  GridFilterModel,
} from '@mui/x-data-grid';
import { useApolloErrors } from './useApolloErrors';
import { usePractices } from './usePractices';
import { useState } from 'react';
import { useOpenState } from './estimator/useOpenState';
import omitDeep from 'omit-deep-lodash';
import {
  getFilters,
  getFilterModelFromLocalStorage,
} from '@cdw-selline/ui/helpers';
import moment from 'moment';
import { PAGES } from '@cdw-selline/ui/constants';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Practice ID',
    width: 200,
    editable: false,
    flex: 1,
  },
  { field: 'name', headerName: 'Practice Name', width: 250 },
  {
    field: 'managedSvc',
    headerName: 'Is Managed SVC?',
    type: 'boolean',
    width: 200,
    editable: false,
    flex: 1,
  },
  {
    field: 'lastModified',
    headerName: 'Last Modified',
    type: 'dateTime',
    width: 180,
    editable: false,
    valueGetter: (value) => value && new Date(value),
    valueFormatter: (value) =>
      value ? moment(value).format('MM/DD/YYYY') : '',
  },
  {
    field: 'createdAt',
    headerName: 'Created Date',
    type: 'dateTime',
    width: 180,
    editable: false,
    valueGetter: (value) => value && !isNaN(value) && new Date(parseInt(value)),
    valueFormatter: (value) =>
      value ? moment(value).format('MM/DD/YYYY') : '',
  },
];

export const useAdminPractices = () => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [sortState, setSortState] = useState();

  const { data, loading, error, refetch } = usePractices({
    filters: getFilters(PAGES.PRACTICES_COLLECTION),
    offset: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize,
    sort: sortState,
  });
  const filterModel: GridFilterModel = getFilterModelFromLocalStorage(`${PAGES.PRACTICES_COLLECTION}-filter`);

  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();

  const [editPracticeId, setEditPracticeId] = useState(null);
  const {
    isOpen: practiceFormOpen,
    handleClose: closePracticeForm,
    handleOpen: openPracticeForm,
  } = useOpenState();

  const [
    addPractice,
    { loading: addPracticeLoading, error: addPracticeError },
  ] = useMutation<{ addPractice: DefaultAddResponse }>(ADD_PRACTICE_MUTATION, {
    refetchQueries: [GET_PRACTICES_QUERY],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to create new practice'),
    onCompleted: (data) => {
      if (data.addPractice.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully added practice',
        });
      }
    },
  });

  const [
    removePractice,
    { loading: removePracticeLoading, error: removePracticeError },
  ] = useMutation<{ removePractice: DefaultMutationResponse }>(
    REMOVE_ADMIN_PRACTICE_MUTATION,
    {
      refetchQueries: [GET_PRACTICES_QUERY, GET_PRACTICE_BY_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to remove practice'),
      onCompleted: (data) => {
        if (data.removePractice.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully removed practice',
          });
        }
      },
    }
  );

  const [
    updatePractice,
    { loading: updatePracticeLoading, error: updatePracticeError },
  ] = useMutation<{ updatePractice: DefaultMutationResponse }>(
    UPDATE_PRACTICE_MUTATION,
    {
      refetchQueries: [GET_PRACTICES_QUERY, GET_PRACTICE_BY_ID_QUERY],
      awaitRefetchQueries: true,
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update practice'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.updatePractice.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully updated practice',
          });
        }
      },
    }
  );

  const handleAdd = () => {
    openPracticeForm();
  };

  const handlePracticeFormClose = () => {
    setEditPracticeId(null);
    closePracticeForm();
  };

  const handleDelete = (pid: string) =>
    removePractice({
      variables: { removePracticeId: pid },
    });
  const handleEdit = (id: string) => {
    setEditPracticeId(id);
    openPracticeForm();
  };

  const handlePracticeFormSave = (practice: Practice) => {
    if (!practice.id) {
      addPractice({
        variables: {
          params: omitDeep(practice, ['__typename']),
        },
      });
    }

    if (practice.id) {
      updatePractice({
        variables: {
          params: omitDeep(practice, ['__typename']),
        },
      });
    }
  };

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    const pageSize = model?.pageSize ?? paginationModel?.pageSize;
    const page = model?.page ?? paginationModel?.page;
    setPaginationModel({
      page,
      pageSize,
    });
  };

  const onFilterModelChange = (filterModel) => {
    refetch({ filters: getFilters(PAGES.PRACTICES_COLLECTION) });
  };
  const handleSort = (args) => {
    let newSort;
    if (args.length) newSort = { [args[0].field]: args[0].sort };
    setSortState(newSort);
  };

  const rows = data.practices.map((p) => ({
    ...p,
    id: p.id ?? p.practiceId,
  }));

  const returnValue = {
    columns,
    rows: rows || [],
    handleAdd,
    handleDelete,
    handleEdit,
    handlePaginationModelChange,
    handleSort,
    onFilterModelChange,
    filterModel,
    practices: data.practices,
    editPracticeId,
    addPracticeLoading,
    addPracticeError,
    removePracticeLoading,
    removePracticeError,
    practiceFormOpen,
    handlePracticeFormClose,
    handlePracticeFormSave,
    rowCount: data.count,
    loading: loading || addPracticeLoading || removePracticeLoading,
  };

  if (loading || addPracticeLoading || removePracticeLoading) {
    return {
      ...returnValue,
      paginationModel: { pageSize: 100, page: 0 },
      rowCount: 0,
      rows: [],
    };
  } else {
    return { ...returnValue, paginationModel };
  }
};

import * as React from 'react';
import Logo from './vision-logo.png';
interface CdwLogoProps{
  height?:string
  className?:string
}
export function CdwSvgLogo(props: CdwLogoProps) {
  return <img alt='CDW Vision logo' src={Logo} {...props} />;
}
export default CdwSvgLogo;

import { useCollectionsQuery } from './useCollectionsQuery';

export const useAdminDashboard = () => {
  const { data, error, loading } = useCollectionsQuery();

  const mappedCollections = data.collections.map((collection) => ({
    ...collection,
    count: collection.count ?? 0,
    lastModified: new Date(collection.lastModified),
  }));

  return {
    collections: mappedCollections,
    error,
    loading,
  };
};

import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999, // Ensure it's above other content
  },
});

function LoadingOverlay() {
  const classes = useStyles();

  return (
    <div className={classes.overlay}>
      <CircularProgress color="secondary" />
    </div>
  );
}

export default LoadingOverlay;
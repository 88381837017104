import { gql } from '@apollo/client';

export const GET_ADMIN_SERVICE_ITEMS = gql`
  query getServiceItemsQuery(
    $filters: MongoFilter
    $limit: Int
    $offset: Int
    $sort: MongoFilter
  ) {
    getServiceItems(
      filters: $filters
      limit: $limit
      offset: $offset
      sort: $sort
    ) {
      count(filters: $filters)
      serviceItems {
        id
        name
        className
        practice
        externalId
        serviceItemNumber
      }
    }
  }
`;

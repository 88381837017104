import * as React from 'react';
import Logo from './agiloft-logo.png';
interface AgiloftLogoProps {
    height?: string
    className?: string,
    onClick?: any
}
export function AgiloftSvgLogo(props: AgiloftLogoProps) {
    return <img alt='Agiloft logo' src={Logo} {...props} />;
}
export default AgiloftSvgLogo;

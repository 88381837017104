import { useMutation } from '@apollo/client';
import { CiscoQuoteInput } from '@cdw-selline/common/types';
import {
  UPDATE_CISCO_ANNUITY_QUOTE_MUTATION,
  GET_CISCO_ANNUITY_BY_PROJECT_ITEM_ID_QUERY,
  GET_CISCO_ANNUITY_QUOTE_BY_PROJECT_ITEM_ID_QUERY,
} from '@cdw-selline/ui/queries';
import { pick } from 'lodash';

import { useApolloErrors } from '..';

export const useCiscoAnnuityQuote = (completionCallback?: () => void) => {
  const { handleErrorResponse } = useApolloErrors();

  const [updateCiscoAnnuityQuote, { loading, error }] = useMutation(
    UPDATE_CISCO_ANNUITY_QUOTE_MUTATION,
    {
      refetchQueries: [
        GET_CISCO_ANNUITY_BY_PROJECT_ITEM_ID_QUERY,
        GET_CISCO_ANNUITY_QUOTE_BY_PROJECT_ITEM_ID_QUERY,
      ],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update Cisco Annuity Quote!'),
      onCompleted: completionCallback,
    }
  );

  const handleCiscoAnnuityQuoteUpdate = (
    ciscoAnnuityId: string,
    ciscoActiveQuote: string,
    ciscoAnnuityQuote: CiscoQuoteInput
  ) => {
    updateCiscoAnnuityQuote({
      variables: {
        ciscoAnnuityId,
        ciscoActiveQuote,
        ciscoAnnuityQuote: pick(ciscoAnnuityQuote, [
          'name',
          'billingCycle',
          'term',
          'autoRenew',
          'hideCustomerViewTotal',
          'hideIncludedSkus',
          'hideMonthlyFeeBreakdown',
          'committedAudioMarkup',
          'removeBidAdders',
        ]),
      },
    });
  };

  return { handleCiscoAnnuityQuoteUpdate, loading, error };
};

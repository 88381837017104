import { CiscoAnnuityPartNumber } from '@cdw-selline/common/types';
import React, { useEffect, useState } from 'react';
import DialogModal from '../../dialog-modal/DialogModal';
import { Grid, Typography, Box } from '@mui/material';
import {
  FormText,
  getActionButtons,
  FormAutocomplete,
} from '../../formHelperFunctions';
import { ApolloError } from '@apollo/client';
import { useCiscoAnnuityCategories } from '@cdw-selline/ui/hooks';

export interface CiscoAnnuityPartNumberFormProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (partNumber: CiscoAnnuityPartNumber) => void;
  ciscoAnnuityPartNumber?: CiscoAnnuityPartNumber | null;
}

const newCiscoAnnuityPartNumberDefaults: CiscoAnnuityPartNumber = {
  id: '',
  partNumber: '',
  category: '',
};

export function CiscoAnnuityPartNumberForm({
  isOpen,
  handleClose,
  handleSubmit,
  ciscoAnnuityPartNumber,
}: CiscoAnnuityPartNumberFormProps) {
  const [ciscoAnnuityPartNumberFormData, setCiscoAnnuityPartNumberFormData] =
    useState(ciscoAnnuityPartNumber ?? newCiscoAnnuityPartNumberDefaults);

  useEffect(() => {
    if (ciscoAnnuityPartNumber && ciscoAnnuityPartNumber.id) {
      setCiscoAnnuityPartNumberFormData(ciscoAnnuityPartNumber);
    }
  }, [ciscoAnnuityPartNumber]);

  const {
    data: categories,
    loading: categoryLoading,
    error: categoryError,
  } = useCiscoAnnuityCategories();

  const categoryOptions =
    categories.map((category) => {
      return {
        id: category.category,
        label: category.category,
      };
    }) ?? [];

  const handleFormClose = () => {
    setCiscoAnnuityPartNumberFormData(newCiscoAnnuityPartNumberDefaults);
    handleClose();
  };

  const handleSaveClick = () => {
    handleSubmit(ciscoAnnuityPartNumberFormData);
    handleClose();
  };

  return (
    <DialogModal
      fullWidth={true}
      isOpen={isOpen}
      handleClose={handleClose}
      title={'Edit Cisco Annuity Part Number'}
      action={getActionButtons(handleFormClose, handleSaveClick)}
      maxWidthProp={'sm'}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="row"
        spacing={1}
        mt={1}
        sx={{ width: '60vh' }}
        data-testid="scope-grid"
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="column"
          spacing={1}
          sx={{ width: '100%' }}
        >
          <FormText
            name="partNumber"
            label="Part Number"
            disabled={ciscoAnnuityPartNumberFormData.id ? true : false}
            setFormData={setCiscoAnnuityPartNumberFormData}
            formData={ciscoAnnuityPartNumberFormData}
          />
          <FormAutocomplete
            name="category"
            label="Category"
            options={categoryOptions}
            setFormData={setCiscoAnnuityPartNumberFormData}
            formData={ciscoAnnuityPartNumberFormData}
          />
        </Grid>
      </Grid>
    </DialogModal>
  );
}

export type AlertSeverity = 'error' | 'warning' | 'info' | 'success';
export interface SnackbarSchema {
  severity: AlertSeverity;
  open: boolean;
  message: string;
  duration: number;
}
export const initialSnackbarState: SnackbarSchema = {
  severity: 'info',
  open: false,
  message: '',
  duration: 5000,
};

import { RoleAndRate } from '@cdw-selline/common/types';
import { Autocomplete, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { styled, lighten, darken } from '@mui/system';

export interface TaskRoleSelectProps {
  currentRolesAndRates: RoleAndRate[];
  value: string;
  name: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  label: string;
}

export function TaskRoleSelect({
  currentRolesAndRates,
  value,
  name,
  onChange,
  onBlur,
  label,
}: TaskRoleSelectProps) {
  const selectedRate = currentRolesAndRates.find(rate=>rate.id === value);
  const [selectedValue, setSelectedValue] = useState(selectedRate?.name ?? '');
  const [selectedInputValue, setSelectedInputValue] = useState(selectedRate?.name ?? '');

  useEffect(() => {
    setSelectedValue(selectedRate?.name ?? '');
    setSelectedInputValue(selectedRate?.name ?? '');
  }, [value]);

  const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
      theme.palette.mode === 'light'
        ? lighten(theme.palette.primary.light, 0.85)
        : darken(theme.palette.primary.main, 0.8),
  }));
  
  const GroupItems = styled('ul')({
    padding: 0,
  });

  const sortedOptions = [...currentRolesAndRates];
  sortedOptions.sort((a, b) => a.practiceName.localeCompare(b.practiceName));

  const handleOnChange=(event, newValue, reason)=> {
    if (reason === 'clear') {
      setSelectedValue('');
      return;
    }
    onChange({
      target: {
        type: 'autocomplete',
        name,
        value: newValue.id,
      },
    } as React.ChangeEvent<HTMLInputElement>);
  }

  const handleOnInputChange=(event, newValue)=> {
    setSelectedInputValue(newValue);
  }

  return (
    <Autocomplete
      size="small"
      id={name}
      value={selectedValue ?? ''}
      inputValue={selectedInputValue ?? ''}
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
      onBlur={onBlur}
      options={sortedOptions}
      groupBy={(roleAndRate) => roleAndRate.practiceName}
      getOptionLabel={(roleAndRate) => (roleAndRate as RoleAndRate)?.name || ''}
      sx={{ width: 250 }}
      renderInput={(params) => <TextField {...params} autoFocus label={label} />}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
  );
}

export default TaskRoleSelect;

import { gql } from '@apollo/client';

export const GET_CISCO_ANNUITY_BY_PROJECT_ITEM_ID_QUERY = gql`
  query GetCiscoAnnuityByProjectItemId($projectItemId: ID!) {
    getCiscoAnnuityByProjectItemId(projectItemId: $projectItemId) {
      activeCiscoQuote
      actualStartDate
      changeType
      ciscoCapital
      ciscoQuotes {
        id
        autoRenew
        billingCycle
        userBillingCycle
        dealId
        name
        quoteId
        start
        status
        term
        userTerm
        expires
        hideIncludedSkus
        hideCustomerViewTotal
        change
        subscriptionReferenceId
        newCofRequired
        disableAdders
        isEstimate
        hideMonthlyFeeBreakdown
        committedAudioMarkup
        removeBidAdders
        lineTerms {
          partNumber
          line
          term
          billingCycle
          autoRenew
        }
      }
      ciscoSubscriptionId
      cofCreatedAt
      cofCreatedBy
      cofRequestedAt
      cofRequestedBy
      cofSignedAt
      cofSignedBy
      cofDeniedReason
      comments {
        createdAt
        createdBy
        id
        note
      }
      contacts {
        id
        type
        name
        email
        phone
      }
      createdAt
      createdBy
      customerPo
      dealId
      id
      lastModifiedAt
      lastModifiedBy
      orderPlacedAt
      orderPlacedBy
      processVersion
      projectItemId
      quotedAt
      quotedBy
      requestWordCopy
      smartAccount
      stateContract
      status
      subscriptionNumber
      type
      virtualAccount
      webOrderId
      cdwBillingId
      statusHistory {
          status
          updatedBy
          updatedAt
          comment
        }
      ciscoSmartAccount
      ciscoVirtualAccount
      customerBillingAccount
      customerAdminSameAsBilling
      orderDeniedReason
      customerPurchaseOrder
    }
  }
`;

import { useMutation } from '@apollo/client';
import { CiscoAnnuityContactInput } from '@cdw-selline/common/types';
import {
  UPDATE_CISCO_ANNUITY_CONTACT_MUTATION,
  ADD_CISCO_ANNUITY_CONTACT_MUTATION,
  GET_CISCO_ANNUITY_BY_PROJECT_ITEM_ID_QUERY,
} from '@cdw-selline/ui/queries';
import omitDeep from 'omit-deep-lodash';
import { useApolloErrors } from '..';

export const useCiscoAnnuityContact = (completionCallback?: () => void) => {
  const { handleErrorResponse } = useApolloErrors();

  const [
    updateCiscoAnnuityContactById,
    {
      loading: updateCiscoAnnuityContactByIdLoading,
      error: updateCiscoAnnuityContactByIdError,
    },
  ] = useMutation(UPDATE_CISCO_ANNUITY_CONTACT_MUTATION, {
    refetchQueries: [GET_CISCO_ANNUITY_BY_PROJECT_ITEM_ID_QUERY],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to update Cisco Annuity!'),
    onCompleted: completionCallback,
  });

  const handleCiscoAnnuityContactUpdate = (
    ciscoAnnuityId: string,
    ciscoAnnuityContactId: string,
    updatedCiscoAnnuityContact: Partial<CiscoAnnuityContactInput>
  ) => {
    console.log(updatedCiscoAnnuityContact);
    updateCiscoAnnuityContactById({
      variables: {
        ciscoAnnuityId,
        ciscoAnnuityContactId,
        ciscoAnnuityContact: omitDeep(updatedCiscoAnnuityContact, [
          '__typename',
        ]),
      },
    });
  };

  const [
    addCiscoAnnuityContact,
    {
      loading: addCiscoAnnuityContactLoading,
      error: addCiscoAnnuityContactError,
    },
  ] = useMutation(ADD_CISCO_ANNUITY_CONTACT_MUTATION, {
    refetchQueries: [GET_CISCO_ANNUITY_BY_PROJECT_ITEM_ID_QUERY],
    onError: (error) =>
      handleErrorResponse(
        error,
        'Failed to create a new Cisco Annuity Contact'
      ),
  });

  const handleAddCiscoAnnuityContact = async (
    ciscoAnnuityId: string,
    ciscoAnnuityContact: Partial<CiscoAnnuityContactInput>
  ) => {
    const addCiscoAnnuityContactResponse = await addCiscoAnnuityContact({
      variables: {
        ciscoAnnuityId,
        ciscoAnnuityContact,
      },
    });
    return addCiscoAnnuityContactResponse;
  };

  return {
    handleCiscoAnnuityContactUpdate,
    updateCiscoAnnuityContactByIdLoading,
    updateCiscoAnnuityContactByIdError,
    handleAddCiscoAnnuityContact,
    addCiscoAnnuityContactLoading,
    addCiscoAnnuityContactError,
  };
};

/* eslint-disable @nx/enforce-module-boundaries */
import {
  useProjectItemEstimator,
  useServiceSummary,
} from '@cdw-selline/ui/hooks';
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import { formatCurrency } from '@cdw-selline/ui/helpers';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useState } from 'react';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';

export const ServiceSummary = () => {
  const [actualSellPrice, setActualSellPrice] = useState(0);
  const [actualSellPriceError, setActualSellPriceError] = useState('');
  const [useActualSellPrice, setUseActualSellPrice] = useState(false);
  const { serviceSummary, error, loading } = useServiceSummary();
  const alertState = useAlertsState();

  const {
    updateProjectItemEstimator,
    data: estimator,
    updateProjectItemEstimatorError,
  } = useProjectItemEstimator();

  const theme = useTheme();

  const roles =
    Array.isArray(serviceSummary.roles) &&
    serviceSummary.roles.filter(
      (data) =>
        data.unit > 0 &&
        ['standardRole', 'otRole'].includes(data.type) &&
        data.name
    );
  const miscPerUnits =
    Array.isArray(serviceSummary.roles) &&
    serviceSummary.roles.filter((data) => data.type === 'miscPerUnit');

  const totals =
    Array.isArray(serviceSummary.roles) &&
    serviceSummary.roles.filter((data) => data.type === 'total');

  const risks =
    Array.isArray(serviceSummary.roles) &&
    serviceSummary.roles.filter((data) => data.type === 'risk');

  const thirdParty =
    Array.isArray(serviceSummary.roles) &&
    serviceSummary.roles.filter(
      (data) => data.total > 0 && data.type === 'customService'
    );

  const thirdPartyTotal =
    Array.isArray(serviceSummary.roles) &&
    serviceSummary.roles.filter(
      (data) => data.total > 0 && data.type === 'customServiceTotal'
    );

  const travelTime =
    Array.isArray(serviceSummary.roles) &&
    serviceSummary.roles.filter(
      (data) => data.total > 0 && data.type === 'travelTime'
    );

  const travelTimeTotal =
    Array.isArray(serviceSummary.roles) &&
    serviceSummary.roles.filter(
      (data) => data.total > 0 && data.type === 'travelTimeTotal'
    );

  const expenseTotal =
    Array.isArray(serviceSummary.roles) &&
    serviceSummary.roles.filter(
      (data) => data.total > 0 && data.type === 'expenseTotal'
    );

  const projectTotal =
    Array.isArray(serviceSummary.roles) &&
    serviceSummary.roles.filter(
      (data) => data.total > 0 && data.type === 'projectTotal'
    );

  const handleUseActualSellPrice = () => {
    if (!useActualSellPrice) {
      setUseActualSellPrice(true);
    }
  };

  const handlSaveSellPriceChange = () => {
    updateProjectItemEstimator({
      variables: {
        params: {
          projectItemId: estimator.projectItemId,
          actualSellPrice: actualSellPrice,
        },
      },
    });
    setUseActualSellPrice(false);
  };

  const handleCancelSellPriceChange = () => {
    updateProjectItemEstimator({
      variables: {
        params: {
          projectItemId: estimator.projectItemId,
          actualSellPrice: 0,
        },
      },
    });

    setActualSellPrice(0);
    setUseActualSellPrice(false);
  };

  const handleActualSellPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setActualSellPrice(Number(event.target.value));
  };

  useEffect(() => {
    if (actualSellPriceError && actualSellPrice > 0) {
      alertState.setAlert({
        type: ALERT_TYPE.MODAL,
        severity: ALERT_SEVERITY.ERROR,
        message: actualSellPriceError,
      });

      setActualSellPrice(estimator?.actualSellPrice ?? 0);
      setActualSellPriceError('');
    }
  }, [
    actualSellPrice,
    alertState,
    estimator?.actualSellPrice,
    actualSellPriceError,
  ]);

  useEffect(() => {
    setActualSellPrice(estimator?.actualSellPrice ?? 0);
  }, [estimator?.actualSellPrice]);

  useEffect(() => {
    if (
      updateProjectItemEstimatorError &&
      updateProjectItemEstimatorError.message
    ) {
      setActualSellPriceError(updateProjectItemEstimatorError.message ?? '');
    }
  }, [updateProjectItemEstimatorError]);

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="Service Summary"
              sx={{
                backgroundColor: theme.palette.primary.dark,
                padding: '5px 15px',
              }}
              titleTypographyProps={{
                variant: 'h6',
                color: theme.palette.common.white,
              }}
            />
            <Divider />
            <Box sx={{ minWidth: 400 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell width={'50%'}>Role</TableCell>
                    <TableCell align="right">Unit Rate</TableCell>
                    <TableCell align="right">Billable Units</TableCell>
                    <TableCell align="right">Subtotal</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {error && (
                    <Typography color="error" variant="h6">
                      {error.message}
                    </Typography>
                  )}
                  {loading && (
                    <TableRow>
                      <TableCell>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                  {!error &&
                    !loading &&
                    roles.map((data, i) => (
                      <TableRow
                        hover
                        key={`services-${data.name}-${i}`}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>
                          {data.name !== null
                            ? data.name
                            : data.type === 'pmRole'
                            ? 'PM Role (No PM Selected)'
                            : ''}
                          {data.type === 'otRole'
                            ? ' - OT per hour'
                            : ' - per hour'}
                        </TableCell>
                        <TableCell align="right">
                          {formatCurrency(data.rate)}
                        </TableCell>
                        <TableCell align="right">{data.unit}</TableCell>
                        <TableCell align="right">
                          {formatCurrency(data.total)}
                        </TableCell>
                      </TableRow>
                    ))}
                  {!error &&
                    !loading &&
                    miscPerUnits.map((data, i) => (
                      <TableRow
                        hover
                        key={`services-${data.name}-${i}`}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>{data.name}</TableCell>
                        <TableCell align="right" />
                        <TableCell align="right">{data.unit}</TableCell>
                        <TableCell align="right">
                          {formatCurrency(data.total)}
                        </TableCell>
                      </TableRow>
                    ))}
                  {!error &&
                    !loading &&
                    totals.map((data, i) => (
                      <TableRow hover key={`${data.name}-${i}`}>
                        <TableCell>
                          <Typography
                            sx={{
                              color: theme.palette.primary.dark,
                              fontWeight: 'bold',
                            }}
                          >
                            CDW Estimated Total
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell align="right">
                          <Typography
                            sx={{
                              color: theme.palette.primary.dark,
                              fontWeight: 'bold',
                            }}
                          >
                            {data.unit}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            align="right"
                            sx={{
                              color: theme.palette.primary.dark,
                              fontWeight: 'bold',
                            }}
                          >
                            {formatCurrency(data.total)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}

                  {!error &&
                    !loading &&
                    risks.map((data, i) => (
                      <TableRow hover key={`${data.name}-${i}`}>
                        <TableCell>
                          <Typography
                            sx={{
                              color: theme.palette.primary.dark,
                              fontWeight: 'bold',
                            }}
                          >
                            {data.unit === 0 ? data.name : 'CDW Risk Uplift'}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            sx={{
                              color: theme.palette.primary.dark,
                              fontWeight: 'bold',
                            }}
                          >
                            {data.unit === 0 ? '' : formatCurrency(data.rate)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            sx={{
                              color: theme.palette.primary.dark,
                              fontWeight: 'bold',
                            }}
                          >
                            {data.unit === 0
                              ? ''
                              : Number(data.unit).toFixed(1)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            sx={{
                              color: theme.palette.primary.dark,
                              fontWeight: 'bold',
                            }}
                          >
                            {data.id === 'risk-total' &&
                              !useActualSellPrice && (
                                <IconButton
                                  onClick={handleUseActualSellPrice}
                                  title="Click to update Sell Price"
                                >
                                  <CurrencyExchangeIcon
                                    sx={{
                                      color:
                                        actualSellPrice > 0
                                          ? theme.palette.success.main
                                          : theme.palette.text.primary,
                                    }}
                                  />
                                </IconButton>
                              )}
                            {formatCurrency(data.total)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  {useActualSellPrice && (
                    <TableRow>
                      <TableCell align="right" colSpan={4}>
                        <TextField
                          name="actualSellPrice"
                          id="actualSellPrice"
                          value={actualSellPrice}
                          onChange={handleActualSellPriceChange}
                          onFocus={(event) => {
                            event.target.select();
                          }}
                          onKeyUp={(event) => {
                            if (event.key === 'Enter') {
                              handlSaveSellPriceChange();
                            }

                            if (event.key === 'Escape') {
                              setUseActualSellPrice(false);
                            }
                          }}
                          label="Set Sell Price"
                          type="number"
                          onWheel={(event) =>
                            (event.target as HTMLInputElement).blur()
                          }
                          size="small"
                          data-testid="actualSellPrice"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handlSaveSellPriceChange}
                                  title="Save"
                                >
                                  <SaveIcon />
                                </IconButton>
                                <IconButton
                                  onClick={handleCancelSellPriceChange}
                                  title="Reset"
                                >
                                  <CancelIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  )}

                  {!error && !loading && travelTimeTotal?.length > 0 && (
                    <TableRow
                      hover
                      key="travelTimeHeader"
                      sx={{
                        borderTop: `${theme.palette.primary.dark} 2px solid`,
                      }}
                    >
                      <TableCell>
                        <Typography
                          sx={{
                            color: theme.palette.primary.dark,
                            fontWeight: 'bold',
                          }}
                        >
                          Travel Time and Expense
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          sx={{
                            color: theme.palette.primary.dark,
                            fontWeight: 'bold',
                          }}
                        >
                          Units
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          sx={{
                            color: theme.palette.primary.dark,
                            fontWeight: 'bold',
                          }}
                        >
                          Cost
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          sx={{
                            color: theme.palette.primary.dark,
                            fontWeight: 'bold',
                          }}
                        >
                          Sell Price
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {!error &&
                    !loading &&
                    travelTime.map((data, i) => (
                      <TableRow
                        hover
                        key={`services-${data.name}-${i}`}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>
                          {data.name !== null
                            ? data.name
                            : data.type === 'pmRole'
                            ? 'PM Role (No PM Selected)'
                            : ''}
                          {data.type === 'otRole'
                            ? ' - OT per hour'
                            : ' - per hour'}
                        </TableCell>
                        <TableCell align="right">{data.unit}</TableCell>
                        <TableCell align="right">
                          {formatCurrency(data.rate)}
                        </TableCell>
                        <TableCell align="right">
                          {formatCurrency(data.total)}
                        </TableCell>
                      </TableRow>
                    ))}

                  {!error &&
                    !loading &&
                    expenseTotal.map((data, i) => (
                      <TableRow
                        hover
                        key={`services-${data.name}-${i}`}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>{data.name}</TableCell>
                        <TableCell align="right">{data.unit}</TableCell>
                        <TableCell align="right">
                          {formatCurrency(data.rate)}
                        </TableCell>
                        <TableCell align="right">
                          {formatCurrency(data.total)}
                        </TableCell>
                      </TableRow>
                    ))}

                  {!error &&
                    !loading &&
                    travelTimeTotal.map((data) => (
                      <TableRow hover key={data.rate}>
                        <TableCell>
                          <Typography
                            sx={{
                              color: theme.palette.primary.dark,
                              fontWeight: 'bold',
                            }}
                          >
                            {data.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="right" sx={{ border: 0 }}>
                          <Typography
                            sx={{
                              color: theme.palette.primary.dark,
                              fontWeight: 'bold',
                            }}
                          >
                            {data.unit}
                          </Typography>
                        </TableCell>
                        <TableCell align="right" sx={{ border: 0 }}>
                          <Typography
                            sx={{
                              color: theme.palette.primary.dark,
                              fontWeight: 'bold',
                            }}
                          >
                            {formatCurrency(data.costTotal)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            sx={{
                              color: theme.palette.primary.dark,
                              fontWeight: 'bold',
                            }}
                          >
                            {formatCurrency(data.total)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}

                  {!error && !loading && thirdParty.length > 0 && (
                    <TableRow
                      hover
                      key="thirdPartyHeader"
                      sx={{
                        borderTop: `${theme.palette.primary.dark} 2px solid`,
                      }}
                    >
                      <TableCell>
                        <Typography
                          sx={{
                            color: theme.palette.primary.dark,
                            fontWeight: 'bold',
                          }}
                        >
                          3rd Party Services
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          sx={{
                            color: theme.palette.primary.dark,
                            fontWeight: 'bold',
                          }}
                        >
                          Units
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          sx={{
                            color: theme.palette.primary.dark,
                            fontWeight: 'bold',
                          }}
                        >
                          Cost
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          sx={{
                            color: theme.palette.primary.dark,
                            fontWeight: 'bold',
                          }}
                        >
                          Sell Price
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {!error &&
                    !loading &&
                    thirdParty.map((data) => (
                      <TableRow hover key={data.rate}>
                        <TableCell>{data.name}</TableCell>
                        <TableCell align="right">{data.unit}</TableCell>
                        <TableCell align="right">
                          {formatCurrency(data.costTotal)}
                        </TableCell>
                        <TableCell align="right">
                          {formatCurrency(data.total)}
                        </TableCell>
                      </TableRow>
                    ))}
                  {!error &&
                    !loading &&
                    thirdPartyTotal.map((data) => (
                      <TableRow hover key={data.rate}>
                        <TableCell>
                          <Typography
                            sx={{
                              color: theme.palette.primary.dark,
                              fontWeight: 'bold',
                            }}
                          >
                            {data.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="right" sx={{ border: 0 }}>
                          <Typography
                            sx={{
                              color: theme.palette.primary.dark,
                              fontWeight: 'bold',
                            }}
                          >
                            {data.unit}
                          </Typography>
                        </TableCell>
                        <TableCell align="right" sx={{ border: 0 }}>
                          <Typography
                            sx={{
                              color: theme.palette.primary.dark,
                              fontWeight: 'bold',
                            }}
                          >
                            {formatCurrency(data.costTotal)}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            sx={{
                              color: theme.palette.primary.dark,
                              fontWeight: 'bold',
                            }}
                          >
                            {formatCurrency(data.total)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}

                  {!error &&
                    !loading &&
                    projectTotal.map((data) => (
                      <TableRow
                        hover
                        key={data.rate}
                        sx={{
                          borderTop: `${theme.palette.primary.dark} 2px solid`,
                        }}
                      >
                        <TableCell>
                          <Typography
                            sx={{
                              color: theme.palette.primary.dark,
                              fontWeight: 'bold',
                            }}
                          >
                            {data.name}
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell align="right">
                          <Typography
                            sx={{
                              color: theme.palette.primary.dark,
                              fontWeight: 'bold',
                            }}
                          >
                            {formatCurrency(data.total)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ServiceSummary;

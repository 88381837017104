import { gql } from '@apollo/client';

export const UPDATE_PROJECT_PROPOSAL_MUTATION = gql`
  mutation UpdateProjectProposal($params: ProjectProposalInput) {
    updateProjectProposal(params: $params) {
      content
      id
      name
      projectId
    }
  }
`;

/*
VARIABLES:
{
  "params": {
    "content": "<p>shiny new content</p>",
    "name": "A New Name",
    "id": "61c36a22727e20b961ebd407"
  }
}
*/

/*
RETURNS:
{
  "data": {
    "updateProjectProposal": {
      "content": "<p>shiny new content</p>",
      "id": "61c36a22727e20b961ebd407",
      "name": "A New Name",
      "projectId": "1234"
    }
  }
}
*/

import { Editor } from '@tinymce/tinymce-react';
import React from 'react';

export interface ContentEditorProps {
  documentId: string;
  currentValue?: string;
  height?: string | number;
  width?: string | number;
  contentStyle?: string;
  bodyClass?: string;
  printable?: boolean
  disabled?: boolean
}

export function ContentEditor({
  documentId,
  currentValue,
  contentStyle,
  bodyClass,
  printable,
  disabled = true,
}: ContentEditorProps) {
  const defaultEditorOptions = {
    width: '100%',
    menubar: false,
    toolbar: printable ? 'print' : 'false',
    statusbar: false,
    content_style: contentStyle,
    body_class: bodyClass || 'projectSowScope',
    noneditable_class: 'uneditable',
    browser_spellcheck: true,
    contextmenu: '',
    plugins: ['autoresize', 'image', 'print', 'noneditable'],
    autoresize_bottom_margin: -5,
    disabled: true,
  };
  const elId = `content-editor-${documentId}`;

  return (
    <Editor
      apiKey={process.env.NX_TINY_MCE_API_KEY}
      init={defaultEditorOptions}
      value={`<div>${currentValue}</div>`}
      id={elId}
      disabled={disabled}
      data-testid={elId}
    />
  );
}

export default ContentEditor;

import { useMutation } from '@apollo/client';
import { GENERATE_AGILOFT_DOCUMENTS } from '@cdw-selline/ui/queries';
import { AgiloftApiResponse } from '@cdw-selline/common/types';
import { useEffect, useState } from 'react';
import { useProjectItemEstimator } from '../../estimator';

export interface useGenerateAgiloftPdfDoc {
  progress: number;
  setProgress: (progress: number) => void;
  startProgress: number;
  endProgress: number;
  setProgressMessage: (message: string) => void;
  agiloftSOWId: string;
  projectId: string;
  projectItemId: string;
}

export const useGenerateAgiloftPdfDoc = ({
  progress,
  setProgress,
  startProgress,
  endProgress,
  setProgressMessage,
  agiloftSOWId,
  projectId,
  projectItemId,
}: useGenerateAgiloftPdfDoc) => {
  const { data: projectItemEstimator } = useProjectItemEstimator();
  const runGenerateDocuments =
    projectItemEstimator?.agiloftGenerateDocuments ?? false;
  const [generateAgiloftPdfDocRunning, setGenerateAgiloftPdfDocRunning] =
    useState(false);

  const [generateAgiloftDocuments, { error: generateAgiloftPdfDocError }] =
    useMutation<{
      generateAgiloftDocuments: AgiloftApiResponse;
    }>(GENERATE_AGILOFT_DOCUMENTS, {
      fetchPolicy: 'no-cache',
      onError: (error) => {
        setProgress(100);
        setGenerateAgiloftPdfDocRunning(false);
        setProgressMessage(error.message);
      },
      onCompleted: (data) => {
        if (!data.generateAgiloftDocuments.success) {
          setProgress(100);
          setGenerateAgiloftPdfDocRunning(false);
          setProgressMessage(data.generateAgiloftDocuments.error);

          return;
        }

        setProgress(endProgress);
        setGenerateAgiloftPdfDocRunning(false);
      },
    });

  useEffect(() => {
    if (progress === startProgress && !runGenerateDocuments) {
      setProgress(endProgress);
    }
  }, [progress, startProgress, setProgress, endProgress, runGenerateDocuments]);

  useEffect(() => {
    if (progress === startProgress && runGenerateDocuments) {
      setProgressMessage('Generating pdf document ..');
      setGenerateAgiloftPdfDocRunning(true);
      generateAgiloftDocuments({
        variables: {
          params: {
            id: agiloftSOWId,
            projectId: projectId,
            projectItemId: projectItemId,
            buildIndex: 2,
          },
        },
      });
    }
  }, [
    progress,
    startProgress,
    setProgressMessage,
    generateAgiloftDocuments,
    agiloftSOWId,
    projectId,
    projectItemId,
    runGenerateDocuments,
  ]);

  useEffect(() => {
    if (progress < 100 && generateAgiloftPdfDocRunning) {
      const timer = setTimeout(() => {
        if (generateAgiloftPdfDocRunning && progress < endProgress - 1) {
          setProgress(progress + 1);
        }
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [
    generateAgiloftPdfDocRunning,
    endProgress,
    progress,
    setProgress,
  ]);

  return {
    generateAgiloftPdfDocRunning,
    generateAgiloftPdfDocError,
  };
};

import { useMutation } from '@apollo/client';
import { TaskNoteInput } from '@cdw-selline/common/types';
import {
  ADD_TASK_NOTE_MUTATION,
  GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY,
  GET_PROJECT_TASK_NOTES_BY_TASK_ID,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { useApolloErrors } from '..';

export const useAddTaskNote = (completeCallback: () => void) => {
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();
  const [addProjectTaskNote, { loading, error, client }] = useMutation(
    ADD_TASK_NOTE_MUTATION,
    {
      refetchQueries: [GET_PROJECT_TASK_NOTES_BY_TASK_ID],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to add Task Note!'),
      onCompleted: (data) => {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully Added Task Note!',
        });
        client.refetchQueries({
          include: [GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY],
        });
        completeCallback();
      },
    }
  );

  const handleAddTaskNote = (taskNote: TaskNoteInput) => {
    addProjectTaskNote({
      variables: {
        taskId: taskNote.taskId,
        taskNote,
      },
    });
  };

  return {
    handleAddTaskNote,
    loading,
    error,
  };
};

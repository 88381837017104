import { ApolloError } from '@apollo/client';
import { Practice, RoleAndRate, ProjectItem } from '@cdw-selline/common/types';
import { Grid } from '@mui/material';
import * as React from 'react';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  ProjectItemEstimatorDetailsForm,
  RolesAndRatesTable,
} from '@cdw-selline/ui/components';

/* eslint-disable-next-line */
export interface EstimatorVariablesTabProps {
  projectItemId: string;
  currentPractices: Practice[];
  currentRolesAndRates: RoleAndRate[];
  practicesAndRatesLoading: boolean;
  practicesAndRatesError: ApolloError;
  projectItem: ProjectItem;
  isReadOnly: boolean;
}

export function EstimatorVariablesTab(props: EstimatorVariablesTabProps) {
  return (
    <Grid container>
      <Grid item xs={6}>
        <ProjectItemEstimatorDetailsForm
          currentRolesAndRates={props.currentRolesAndRates}
          practicesAndRatesLoading={props.practicesAndRatesLoading}
          practicesAndRatesError={props.practicesAndRatesError}
          practices={props.currentPractices}
          isReadOnly={props.isReadOnly}
        />
      </Grid>
      <Grid item xs={6}>
        <RolesAndRatesTable
          {...{
            projectItemId: props.projectItemId,
            currentPractices: props.currentPractices,
            currentRolesAndRates: props.currentRolesAndRates,
            practicesAndRatesLoading: props.practicesAndRatesLoading,
            practicesAndRatesError: props.practicesAndRatesError,
            projectItem: props.projectItem,
            isReadOnly: props.isReadOnly,
          }}
        />
      </Grid>
    </Grid>
  );
}

export default EstimatorVariablesTab;

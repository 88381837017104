import { useMutation, useQuery } from '@apollo/client';
import {
  DefaultMutationResponse,
  CiscoSmartAccount,
} from '@cdw-selline/common/types';
import {
  GET_CISCO_SMART_ACCOUNTS_BY_CUSTOMER_ID_QUERY,
  ADD_CISCO_SMART_ACCOUNT_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';

import { useApolloErrors } from '..';

export const useCiscoSmartAccounts = ({ customerId }) => {
  const alertState = useAlertsState();
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery<{
    getCiscoSmartAccountsByCustomerId: CiscoSmartAccount[];
  }>(GET_CISCO_SMART_ACCOUNTS_BY_CUSTOMER_ID_QUERY, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    variables: { customerId },
    skip: !customerId,
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Customer Cisco Smart Accounts!'),
  });

  const [
    addCiscoSmartAccount,
    { loading: uaddCiscoSmartAccountLoading, error: uaddCiscoSmartAccountError },
  ] = useMutation<{ addCiscoSmartAccount: DefaultMutationResponse }>(
    ADD_CISCO_SMART_ACCOUNT_MUTATION,
    {
      // refetchQueries: [GET_CISCO_SMART_ACCOUNTS_BY_CUSTOMER_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update Customer Cisco Smart Accounts'),
    }
  );

  return {
    ciscoSmartAccounts: data?.getCiscoSmartAccountsByCustomerId ?? [],
    addCiscoSmartAccount,
    loading,
    error,
  };
};
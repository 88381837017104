import { gql } from '@apollo/client';

export const UPDATE_TASK_GROUP_TEAM_MEMBER_MUTATION = gql`
  mutation updateTaskGroupTeamMemberMutation(
    $id: ID!
    $taskGroupTeam: TaskGroupTeamInput!
  ) {
    updateTaskGroupTeamMember(id: $id, taskGroupTeam: $taskGroupTeam) {
      success
    }
  }
`;

export default UPDATE_TASK_GROUP_TEAM_MEMBER_MUTATION;

import { getFirebaseApp } from '@cdw-selline/ui/common';
import { useUserState } from '@cdw-selline/ui/state';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import {
  getDatabase,
  onChildAdded,
  onChildRemoved,
  onDisconnect,
  onValue,
  ref,
  set,
} from 'firebase/database';
import { uniqBy } from 'lodash';
import { useEffect, useState } from 'react';

export const useProjectSowsConnectedState = (
  projectId: string,
  docId: string,
  dbPath?: string
) => {
  const fbApp = getFirebaseApp();
  const db = getDatabase(fbApp);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const userState = useUserState();
  const fbUser = userState.get() ?? {};
  const currentUser =
    (process.env.NX_CI_E2E_TEST || process.env.NX_CI_TEST) &&
    !userState.promised
      ? {}
      : JSON.parse(JSON.stringify(fbUser));
  const dbRef = ref(
    db,
    dbPath ??
      `/project-sows-connected-state/${projectId}/${docId}/${
        currentUser.uid ?? null
      }`
  );
  const [connectedUsers, setConnectedUsers] = useState([]);
  const alerts = useAlertsState();
  const rootRef = ref(
    db,
    dbPath ?? `/project-sows-connected-state/${projectId}/${docId}`
  );

  useEffect(() => {
    const unSubscribeFromOnValue = onValue(
      rootRef,
      (data) => {
        if (!connectedUsers.some((user) => user.uid === data.val().uid)) {
          const arr = [];
          data.forEach((snap) => {
            arr.push(snap.val());
          });
          setConnectedUsers(arr);
        }
      },
      { onlyOnce: true }
    );

    const unSubscribeFromChildAdded = onChildAdded(rootRef, (data) => {
      if (!connectedUsers.some((user) => user.uid === data.val().uid)) {
        setConnectedUsers((p) => [...p, data.val()]);
      }
    });

    const unSubscribeFromChildRemoved = onChildRemoved(rootRef, (data) => {
      setConnectedUsers((p) =>
        [...p].filter((user) => user.uid !== data.val().uid)
      );
    });
    return () => {
      unSubscribeFromOnValue();
      unSubscribeFromChildAdded();
      unSubscribeFromChildRemoved();
    };
  }, [projectId, docId]);

  useEffect(() => {
    (async () => {
      await set(dbRef, currentUser).catch((err) => {
        console.error('error writing user', err.message);
        alerts.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.ERROR,
          message: err.message,
        });
      });
    })();
    onDisconnect(dbRef).set(null);
    return () => {
      set(dbRef, null);
    };
  }, [projectId, docId]);

  return {
    connectedUsers: uniqBy([...connectedUsers, currentUser], 'uid').filter(
      Boolean
    ),
  };
};

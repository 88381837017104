import { gql } from '@apollo/client';

export const GET_TOTAL_CUSTOMERS_QUERY = gql`
  query getTotalCustomersQuery {
    getTotalCustomers {
      count
      customers {
        id
        name
      }
      customersSinceLastMonthPercent
    }
  }
`;

export default GET_TOTAL_CUSTOMERS_QUERY;

// SAMPLE RESPONSE:
// {
//   "data": {
//     "getTotalCustomers": {
//       "count": 0,
//       "customers": [
//         {
//           "id": "79465496-39ef-4b00-a52d-b35b4d042a0a",
//           "name": "Dropbox"
//         },
//         {
//           "id": "8cb91d9a-f636-41f9-9b87-c2a89f5edd3e",
//           "name": "Medium Corporation"
//         },
//         {
//           "id": "86daaa77-c17a-4aac-a091-966233c247f9",
//           "name": "Slack"
//         },
//         {
//           "id": "50c1947f-2bb9-4bca-b07e-fa208a0261bc",
//           "name": "Lyft"
//         },
//         {
//           "id": "911a9a88-cbcb-4941-8e7a-deeaa39fdeeb",
//           "name": "GitHub"
//         }
//       ]
//     }
//   }
// }

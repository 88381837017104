import { gql } from '@apollo/client';

export const ADD_CDW_RATE_MUTATION = gql`
  mutation addCdwRateMutation($params: CdwRateInput) {
    addCdwRate(params: $params) {
      id
      success
    }
  }
`;

// VARIABLES
// {
//   ...AdminTaskInput
// }

import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { DefaultMutationResponseWithId } from '@cdw-selline/common/types';
import {
  UPDATE_PROJECT_ITEMS_ESTIMATOR_MUTATION,
  GET_RMS_PROPOSAL_QUERY,
} from '@cdw-selline/ui/queries';

import { useApolloErrors } from '../';

import { useAlertsState } from '@cdw-selline/ui/state';

export const useRmsProposal = () => {
  const alertState = useAlertsState();
  const { handleErrorResponse } = useApolloErrors();
  const { projectItemId } = useParams<{ projectItemId: string }>();

  const {
    loading: rmsProposalLoading,
    error: rmsProposalError,
    data: rmsProposal,
  } = useQuery(GET_RMS_PROPOSAL_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      projectItemId,
    },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Estimator Tasks!'),
  });

  const [
    updateRmsProposal,
    {
      loading: updateRmsProposalLoading,
      error: updateRmsProposalError,
      client,
    },
  ] = useMutation<{
    updateProjectItem: DefaultMutationResponseWithId;
  }>(UPDATE_PROJECT_ITEMS_ESTIMATOR_MUTATION, {
    refetchQueries: [GET_RMS_PROPOSAL_QUERY],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to updateProject Item Estimator'),
  });
  return {
    updateRmsProposal,
    updateRmsProposalLoading,
    updateRmsProposalError,
    useRmsProposal,
    rmsProposalLoading,
    rmsProposalError,
    rmsProposal: rmsProposal?.getRmsProposalByItemId ?? {},
  };
};

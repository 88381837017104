import { useQuery } from '@apollo/client';
import { GET_AGILOFT_SOW_BY_ID_QUERY } from '@cdw-selline/ui/queries';
import { useApolloErrors } from '../../';

export const useAgiloftSowById = (id: string) => {
  const { handleErrorResponse } = useApolloErrors();

  const { loading, error, data, refetch } = useQuery(
    GET_AGILOFT_SOW_BY_ID_QUERY,
    {
      variables: {
        id,
      },
      skip: !id,
      fetchPolicy: 'cache-and-network',
      onError: (error) =>
        handleErrorResponse(error, 'Failed to fetch Agiloft Sow!'),
    }
  );

  return {
    data: data?.getAgiloftSOWById ?? {},
    loading,
    error,
    refetch,
  };
};

import { useMutation } from '@apollo/client';
import {
  REMOVE_PROJECT_PROPOSAL_MUTATION,
  GET_PROJECT_PROPOSALS_BY_ESTIMATOR_ID_QUERY,
  GET_PROJECT_PROPOSAL_BY_ID_QUERY,
} from '@cdw-selline/ui/queries';
import { useApolloErrors } from '../useApolloErrors';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';

export const useRemoveProjectProposal = (completeCallback?: () => void) => {
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();

  const [removeProjectProposal, { loading, error }] = useMutation(
    REMOVE_PROJECT_PROPOSAL_MUTATION,
    {
      refetchQueries: [
        GET_PROJECT_PROPOSALS_BY_ESTIMATOR_ID_QUERY,
      ],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to remove Project Proposal!'),
      onCompleted: (data) => {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully Removed Project Proposal!',
        });
        completeCallback?.();
      },
    }
  );

  const handleRemoveProjectProposal = (proposalId: string) => {
    removeProjectProposal({
      variables: {
        removeProjectProposalId: proposalId,
      },
    });
  };

  return {
    handleRemoveProjectProposal,
    loading,
    error,
  };
};

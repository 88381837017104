import { useQuery } from '@apollo/client';
import { SearchTaskGroupsResponse } from '@cdw-selline/common/types';
import { SEARCH_TASK_GROUPS_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from './useApolloErrors';

export const useSearchTaskGroups = (
  searchTerm: string,
  { skip = false } = {}
) => {
  const { handleErrorResponse } = useApolloErrors();
  const { data, loading, error } = useQuery<{
    searchTaskGroups: SearchTaskGroupsResponse;
  }>(SEARCH_TASK_GROUPS_QUERY, {
    variables: {
      searchTerm,
    },
    skip,
    onError: (error) =>
      handleErrorResponse(error, 'Failed to search for Task Groups!'),
  });
  return {
    data: data?.searchTaskGroups.taskGroups ?? [],
    loading,
    error,
  };
};

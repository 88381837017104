import { ALERT_SEVERITY, useAlertsState } from '@cdw-selline/ui/state';
import {
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  SlideProps,
  Snackbar,
  Alert as MuiAlert,
  AlertProps,
  useTheme,
} from '@mui/material';
import React from 'react';

const SnackAlert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="outlined" {...props} />;
});

function TransitionSnack(props: Omit<SlideProps, 'direction'>) {
  return <Slide {...props} direction="left" />;
}

/* eslint-disable-next-line */
export interface AlertsOverlayProps {}

export function AlertsOverlay(props: AlertsOverlayProps) {
  const theme = useTheme();
  const alertState = useAlertsState();

  const snackbarData = alertState?.currentSnackbar?.get?.();
  const snackVisible = Boolean(snackbarData?.id && !snackbarData?.hasViewed);

  const modalData = alertState?.currentModal?.get?.();
  const modalVisible = Boolean(modalData?.id && !modalData?.hasViewed);

  const handleClear = (id: string | undefined) => {
    id && alertState.clearAlert(id);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway' || !snackbarData?.id) {
      return;
    }
    alertState.clearAlert(snackbarData.id);
  };

  const handleDelete = (id: string | undefined) => {
    id && alertState.deleteAlert(id);
  };

  return (
    <Stack sx={{ width: '100%' }}>
      <Snackbar
        open={snackVisible}
        autoHideDuration={snackbarData?.lifespan || 5000}
        onClose={handleClose}
        TransitionComponent={TransitionSnack}
        TransitionProps={{
          onExited: () => handleDelete(snackbarData?.id),
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <SnackAlert
          onClose={handleClose}
          severity={
            !snackbarData || snackbarData?.severity === ALERT_SEVERITY.LOG
              ? undefined
              : snackbarData.severity
          }
          sx={{
            width: '100%',
            backgroundColor: theme.palette.background.paper,
            color: (theme) =>
              snackbarData.severity === ALERT_SEVERITY.ERROR
                ? 'red'
                : 'inherit',
          }}
        >
          {snackbarData?.message}
        </SnackAlert>
      </Snackbar>
      <Dialog
        open={modalVisible}
        onClose={() => handleClear(modalData?.id)}
        aria-labelledby="alert-dialog-message"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { minWidth: '280px' },
        }}
        TransitionProps={{
          onExited: () => handleDelete(modalData?.id),
        }}
      >
        {!modalData?.title ? null : (
          <DialogTitle id="alert-dialog-title">{modalData?.title}</DialogTitle>
        )}
        {modalData?.message ? (
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{
                whiteSpace: 'break-spaces',
                color:
                modalData.severity === ALERT_SEVERITY.ERROR
                    ? 'red'
                    : 'black',
              }}
            >
              {modalData?.message}
            </DialogContentText>
          </DialogContent>
        ) : null}
        <DialogActions>
          <Button onClick={() => handleClear(modalData?.id)} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}

export default AlertsOverlay;

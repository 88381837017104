import { AgiloftTravelNoticeType } from '@cdw-selline/common/types';
import React, { useEffect, useRef, useState } from 'react';
import DialogModal from '../../dialog-modal/DialogModal';
import { Grid, Typography, Box, MenuItem } from '@mui/material';
import {
  FormCheckbox,
  FormMultiSelectAutocomplete,
  FormSelect,
  FormText,
  getActionButtons,
} from '../../formHelperFunctions';
import { ApolloError } from '@apollo/client';
import { startCase } from 'lodash';
import { useValidateAgiloftTravelNoticeType } from '@cdw-selline/ui/hooks';

export interface AgiloftTravelNoticeTypeFormProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (agiloftTravelNoticeType: AgiloftTravelNoticeType) => void;
  agiloftTravelNoticeType?: AgiloftTravelNoticeType | null;
  agiloftTravelNoticeTypeLoading?: boolean;
  agiloftTravelNoticeTypeError?: ApolloError;
}

const newAgiloftTravelNoticeTypeDefaults: AgiloftTravelNoticeType = {
  id: '',
  travelNoticeTypeName: '',
  travelNoticeClauses: [], 
};

export function AgiloftTravelNoticeTypeForm({
  isOpen,
  handleClose,
  handleSubmit,
  agiloftTravelNoticeType,
  agiloftTravelNoticeTypeLoading,
  agiloftTravelNoticeTypeError,
}: AgiloftTravelNoticeTypeFormProps) {
  const [agiloftTravelNoticeTypeFormData, setAgiloftTravelNoticeTypeFormData] = useState(
    agiloftTravelNoticeType ?? newAgiloftTravelNoticeTypeDefaults
  );
  const [errorArray, setErrorArray] = useState([]);
  const { validateAgiloftTravelNoticeType } = useValidateAgiloftTravelNoticeType();
  const formRef = useRef(null);


  useEffect(() => {
    if (agiloftTravelNoticeType) {
      setAgiloftTravelNoticeTypeFormData({
        ...newAgiloftTravelNoticeTypeDefaults,
        ...agiloftTravelNoticeType,
        travelNoticeClauses: agiloftTravelNoticeType.travelNoticeClauses || [], 
      });
    }
  }, [agiloftTravelNoticeType]);

  const handleFormClose = () => {
    setAgiloftTravelNoticeTypeFormData(newAgiloftTravelNoticeTypeDefaults);
    setErrorArray([]);
    handleClose();
  };

  const handleSaveClick = async () => {
    const validationMessages = await validateAgiloftTravelNoticeType(agiloftTravelNoticeTypeFormData);
    if (validationMessages?.length > 0) {
      setErrorArray(validationMessages);
      formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      return;
    } else {
      setErrorArray([]);
      handleSubmit(agiloftTravelNoticeTypeFormData);
      handleClose();
    }
  };

  if (agiloftTravelNoticeTypeLoading) return <Typography>Loading form</Typography>;

  if (agiloftTravelNoticeTypeError)
    return <Typography>Error loading form</Typography>;

  const getTitle = () => {
    return (
      (agiloftTravelNoticeTypeFormData.id && `Edit Agiloft TravelNotice Type`) ||
      'Add Agiloft TravelNotice Type'
    );
  };

  return (
    <DialogModal
      fullWidth={true}
      isOpen={isOpen}
      handleClose={handleClose}
      title={getTitle()}
      action={getActionButtons(handleFormClose, handleSaveClick)}
      maxWidthProp={'lg'}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="row"
        spacing={1}
        mt={1}
        sx={{ height: '50vh' }}
        data-testid="agiloftTravelNoticeType-grid"
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="column"
          spacing={1}
          sx={{ width: '100%' }}
          ref={formRef}
        >
          {(errorArray && errorArray.length > 0 ) && (
            <Box sx={{ padding: '5px' }}>
              {errorArray.map((error) => (
                <Typography key={`${error}`} color="error" variant="h6">
                  {startCase(error) ?? 'Please fill out missing field(s)'}
                </Typography>
              ))}
            </Box>
          )}
          <FormText
            name="travelNoticeTypeName"
            label="Agiloft TravelNotice Type"
            required={true}
            setFormData={setAgiloftTravelNoticeTypeFormData}
            formData={agiloftTravelNoticeTypeFormData}
            error={errorArray.length > 0 && !agiloftTravelNoticeTypeFormData.travelNoticeTypeName}
          />
          <FormMultiSelectAutocomplete
            name="travelNoticeClauses"
            label="Agiloft TravelNotice Clause Options"
            required={true}
            setFormData={setAgiloftTravelNoticeTypeFormData}
            formData={agiloftTravelNoticeTypeFormData}
            options={(agiloftTravelNoticeTypeFormData?.travelNoticeClauses || []).map(val => (
              { value: val, label: val }
            ))}
            error={errorArray.length > 0 && (!agiloftTravelNoticeTypeFormData.travelNoticeClauses || agiloftTravelNoticeTypeFormData.travelNoticeClauses.length === 0)}
          >
            {(agiloftTravelNoticeTypeFormData?.travelNoticeClauses || []).map((val, k) => (
              <MenuItem key={k} value={val}>
                {val}
              </MenuItem>
            ))}
          </FormMultiSelectAutocomplete>
          <FormSelect
            name="agiloftDefaultTravelNoticeClause"
            label="Default Agiloft TravelNotice Clause"
            setFormData={setAgiloftTravelNoticeTypeFormData}
            formData={agiloftTravelNoticeTypeFormData}
            required={true}
            error={errorArray.length > 0 && !agiloftTravelNoticeTypeFormData.agiloftDefaultTravelNoticeClause}
            >
              {(agiloftTravelNoticeTypeFormData?.travelNoticeClauses || []).map((value, type) => (
                <MenuItem key={type} value={value}>
                  {value}
                </MenuItem>
              ))}
          </FormSelect>
          <FormCheckbox
            name="showTravelNoticePenaltyAmount"
            label="Show Travel Notice Penalty Amount?"
            setFormData={setAgiloftTravelNoticeTypeFormData}
            formData={agiloftTravelNoticeTypeFormData}
          />
        </Grid>
      </Grid>
    </DialogModal>
  );
}

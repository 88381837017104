import { gql } from '@apollo/client';

export const ADD_TASK_GROUP_MUTATION = gql`
  mutation addTaskGroupMutation($params: AdminTaskGroupInput) {
    addTaskGroup(params: $params) {
      success
      id
    }
  }
`;

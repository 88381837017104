import { gql } from '@apollo/client';

export const SEARCH_SOW_SECTIONS_QUERY = gql`
  query SearchSowSections($searchTerm: String) {
    searchSowSections {
      sowSections(searchTerm: $searchTerm) {
        assumptions
        assumptionsGlobal
        createdBy
        createdOn
        customerResponsibilities
        customerResponsibilitiesGlobal
        help
        helpGlobal
        highLevel
        id
        lastReviewedBy
        lastReviewedOn
        name
        outOfScope
        outOfScopeGlobal
        outcomeUnit
        outcomeUnitDescription
        scope
        title
        version
      }
    }
  }
`;

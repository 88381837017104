import { gql } from '@apollo/client';

export const REMOVE_ADMIN_ROLE_MUTATION = gql`
  mutation removeRoleMutation($removeRoleId: String) {
    removeRole(id: $removeRoleId) {
      success
    }
  }
`;

// VARIABLES
// {
//   "removeRole": "id"
// }

  import {
    useOpenState,
    useActiveSitesAndTasks,
    useBidAssurance,
    useBidAssuranceNotes,
    useProjectItemEstimator,
  } from '@cdw-selline/ui/hooks';
  import { ActiveSiteTask, Task, BidAssuranceStatusType } from '@cdw-selline/common/types';
  import {
    CircularProgress,
    Stack,
    Typography,
    Grid,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    useTheme,
    IconButton,
    Badge,
    Button,
    Tooltip,
    Card,
    Box,
   } from '@mui/material';
  import React from 'react';
  import { useParams } from 'react-router-dom';
  import CheckIcon from '@mui/icons-material/Check';
  import CommentIcon from '@mui/icons-material/Comment';
  import MenuOpenIcon from '@mui/icons-material/MenuOpen';
  import { TaskNoteDialog, MessageList, MessageInput } from '@cdw-selline/ui/components';
  import { ProjectBidAssuranceStatusHistory } from './ProjectBidAssuranceStatusHistory';
  
  /* eslint-disable-next-line */
  export const ActiveTasksTab = () => {
    const infoStrings = {
      ERROR: 'Error loading Active Tasks',
    };
  
    const [activeTask, setActiveTask] = React.useState<ActiveSiteTask>({});
    const theme = useTheme();
    const { id, projectItemId} = useParams<{ id: string; projectItemId: string }>();
    const [drawerState, setDrawerState] = React.useState(false);
    const {data: projectItemEstimator } = useProjectItemEstimator();
    const { activeSiteTasks, sitesLoading, sitesError } = useActiveSitesAndTasks(projectItemId);
    const {
      estimatorHasBidAssurance,
      estimatorHasBidAssuranceLoading,
      estimatorHasBidAssuranceError,
      requestEstimatorBidAssurance,
      requestBidAssuranceLoading,
      requestBidAssuranceError,
      cancelEstimatorBidAssurance,
      denyEstimatorBidAssurance,
      escalateEstimatorBidAssurance,
    } = useBidAssurance(projectItemId);

    const {
      bidAssuranceNotes,
      bidAssuranceNotesLoading,
      bidAssuranceNotesError,
      saveBidAssuranceNote,
      removeBidAssuranceNote,
    } = useBidAssuranceNotes(projectItemId,{skip: !estimatorHasBidAssurance});

    const {
      isOpen: notesOpen,
      handleOpen: handleNotesOpen,
      handleClose: handleNotesClose,
    } = useOpenState();

    const handleTaskNoteOpen = (currentTask: ActiveSiteTask) => {
      setActiveTask(currentTask);
      handleNotesOpen();
    };

    const handleRequestBidAssuranceClick = () => {
      requestEstimatorBidAssurance();
    };

    const handleCancelBidAssuranceClick = () => {
      cancelEstimatorBidAssurance();
    };

    const handleDenyBidAssuranceClick = () => {
      denyEstimatorBidAssurance();
    };

    const handleEscalateBidAssuranceClick = () => {
      escalateEstimatorBidAssurance();
    };

    const handleCompleteBidAssuranceClick = () => {
      requestEstimatorBidAssurance();
    };

    const toggleDrawer = (open: boolean): void => {
      setDrawerState(() => open);
    };

    const handleSaveNote = (message: MessageInput) => {
      saveBidAssuranceNote({
        variables: {
          projectItemId,
          params: message,
        },
      });
    };
  
    const handleDeleteNote = (note: string)=> {
      removeBidAssuranceNote({
        variables: {
          projectItemId,
          messageId: note,
        },
      });
    };
    return (
      <>
        {estimatorHasBidAssurance && (
          <>
          {projectItemEstimator?.bidAssuranceStatus !== BidAssuranceStatusType.Requested ? (
            <Button onClick={handleRequestBidAssuranceClick}
              sx={{ marginTop: '1em', marginLeft: '1em' }}
              variant="contained">
                Request Bid Assurance
            </Button>
            ): 
            (<>
              <Button onClick={handleDenyBidAssuranceClick}
                sx={{ marginTop: '1em', marginLeft: '1em' }}
                variant="contained">
                  Deny Bid Assurance
              </Button>
              <Button onClick={handleCancelBidAssuranceClick}
                sx={{ marginTop: '1em', marginLeft: '1em' }}
                variant="contained">
                  Cancel Bid Assurance
              </Button>
              <Button onClick={handleEscalateBidAssuranceClick}
                sx={{ marginTop: '1em', marginLeft: '1em' }}
                variant="contained">
                  Escalate Bid Assurance
              </Button>
              <Button onClick={handleCompleteBidAssuranceClick}
                sx={{ marginTop: '1em', marginLeft: '1em' }}
                variant="contained">
                  Complete Bid Assurance
              </Button>
            </>
            )}
              <Tooltip
                  title=" View Bid Assurance Status History"
                  sx={{ position: 'absolute', right: '1em', mt: '0' }}
                >
                <IconButton onClick={() => toggleDrawer(true)}>
                  <MenuOpenIcon />
                </IconButton>
              </Tooltip>
              <Card sx={{ width: '100%', p: '1em', mb: '.5em' }}>
                <Box sx={{ width: '100%' }}>
                  {drawerState && <ProjectBidAssuranceStatusHistory
                    drawerState={drawerState}
                    toggleDrawer={toggleDrawer}
                    history={projectItemEstimator?.bidAssuranceStatusHistories}
                  />}
                </Box>
              </Card>
          </>
        )}
        <Stack
          marginLeft="1em"
          marginRight="1em"
          marginTop="1em"
          marginBottom="1em"
          data-testid="active-tabs-view"
        >
          {sitesLoading ? (
            <CircularProgress />
          ) : sitesError ? (
            <Typography>{infoStrings.ERROR}</Typography>
          ) : activeSiteTasks.length ? (
            <Grid container justifyContent="flex-start" spacing={2}>
            <Grid item xs={estimatorHasBidAssurance ? 9 : 12}>
              {activeSiteTasks.map?.((site, ind) => (
                <Table size="small" data-testid="site-table" key={`${site.siteId}-${ind}-table`}>
                  <TableHead>
                    <TableRow
                      key={`${site.siteId}-${ind}-header`}
                      sx={{ backgroundColor: theme.palette.primary.dark }}
                    >
                      <TableCell
                        sx={{
                          color: theme.palette.common.white,
                          fontSize: '15px',
                        }}
                      >
                        {site.siteName}
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell
                        sx={{
                          color: theme.palette.common.white,
                          fontSize: '15px',
                        }}
                      >{`Site Total Hours: ${site.siteTotalHours}`}</TableCell>
                    </TableRow>
                    <TableRow
                      key={`${site.siteId}-${ind}`}
                      sx={{ backgroundColor: theme.palette.primary.dark }}
                    >
                      <TableCell sx={{ color: theme.palette.common.white }}>
                        Qty/Section
                      </TableCell>
                      <TableCell sx={{ color: theme.palette.common.white }}>
                        Notes
                      </TableCell>
                      <TableCell sx={{ color: theme.palette.common.white }}>
                        Category
                      </TableCell>
                      <TableCell sx={{ color: theme.palette.common.white }}>
                        Task
                      </TableCell>
                      <TableCell sx={{ color: theme.palette.common.white }}>
                        Primary Role
                      </TableCell>
                      <TableCell sx={{ color: theme.palette.common.white }}>
                        Secondary Role
                      </TableCell>
                      <TableCell sx={{ color: theme.palette.common.white }}>
                        Overtime
                      </TableCell>
                      <TableCell sx={{ color: theme.palette.common.white }}>
                        Hours
                      </TableCell>
                      <TableCell sx={{ color: theme.palette.common.white }}>
                        Total Hours
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {site.tasks?.map?.((task, index) => (
                        <TableRow
                          key={`${site.siteId}-${task.category}-${index}`}
                        >
                          <TableCell>{task.quantity}</TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => handleTaskNoteOpen(task)}
                              aria-label={
                                task.noteCount > 0
                                  ? `${task.noteCount} task notes`
                                  : 'add task note'
                              }
                            >
                              <Badge
                                badgeContent={task.noteCount}
                                color="primary"
                              >
                                <CommentIcon />
                              </Badge>
                            </IconButton>
                          </TableCell>
                          <TableCell>{task.category}</TableCell>
                          <TableCell>{task.name}</TableCell>
                          <TableCell>{task.primaryRole}</TableCell>
                          <TableCell>{task.secondaryRole}</TableCell>
                          <TableCell>
                            {task.overtime && <CheckIcon />}
                          </TableCell>
                          <TableCell>{task.hours}</TableCell>
                          <TableCell>{task.totalHours}</TableCell>
                        </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ))}
            </Grid>
            
            {estimatorHasBidAssurance && (
              <Grid item xs={3}>
                <MessageList
                      label='Bid Assurance Notes'
                      placeHolder='Enter Note...'
                      messages={bidAssuranceNotes}
                      saveComment={handleSaveNote}
                      deleteComment={handleDeleteNote}
                      />
                </Grid>)}
              </Grid>
          ) : null}
          <TaskNoteDialog
            {...{
              isOpen: notesOpen,
              handleClose: handleNotesClose,
              task: activeTask as unknown as Task,
              projectItemId,
            }}
          />
        </Stack>
      </>
    );
  }
  
  export default ActiveTasksTab;
  
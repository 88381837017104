import { gql } from '@apollo/client';

export const GET_MANS_ENROLLMENT_SUMMARY_QUERY = gql`
  query getMansEnrollmentSummaryQuery($id: ID!) {
    getMansEnrollmentSummaryByEstimatorId(projectItemId: $id) {
      pmPercent
      riskMitigation
      roles {
        id
        name
        unit
        rate
        total
        type
      }
    }
  }
`;

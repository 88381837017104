import { CollectionsDataTable } from '@cdw-selline/ui/components';
import {
  useCollectionPagination,
  useGetScopeItems,
} from '@cdw-selline/ui/hooks';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import React, { useState } from 'react';

export function ScopeItemsCollectionPage() {
  const scopeItemsColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 180,
      editable: false,
      flex: 1,
    },
    {
      field: 'title',
      headerName: 'Scope Item Title',
      width: 180,
      flex: 1,
    },
    {
      field: 'scopeGroupId',
      headerName: 'Scope Group',
      width: 180,
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created Date',
      type: 'dateTime',
      width: 180,
      editable: false,
      valueGetter: (value) => value && new Date(value),
      valueFormatter: (value) =>
        value? moment(value).format('MM/DD/YYYY'): '',  
    },
  ];
  const [sortState, setSortState] = useState();
  const {
    data,
    loading: scopeItemsLoading,
    error: scopeItemsError,
    refetch: scopeItemsRefetch,
  } = useGetScopeItems({ offset: 0, limit: 100, sort: sortState });

  const paginationProps = useCollectionPagination(scopeItemsRefetch);
  const handleSort = (args) => {
    let newSort ;
    if (args.length) newSort = { [args[0].field]: args[0].sort };
    setSortState(newSort);
  };
  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <CollectionsDataTable
        {...paginationProps}
        loading={scopeItemsLoading}
        error={scopeItemsError}
        columns={scopeItemsColumns}
        rows={data.scopeItems}
        handleSort={handleSort}
        rowCount={data.count}
        height="calc(100vh - 13em)"
        paginationMode="server"
        sortingMode="server"
        parent="ScopeItemsCollectionPage"
      />
    </Box>
  );
}

export default ScopeItemsCollectionPage;

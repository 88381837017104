import { gql } from '@apollo/client';

export const GET_SERVICE_LOCATIONS_QUERY = gql`
  query getServiceLocations( $sort: MongoFilter) {
    getServiceLocations(sort:$sort) {
      locations {
        id
        name
      }
      count
    }
  }
`;

export default GET_SERVICE_LOCATIONS_QUERY;

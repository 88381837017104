import { gql } from '@apollo/client';

export const GET_TASK_GROUPS_WITH_CATS_QUERY = gql`
  query getTaskGroupsQuery($limit: Int, $offset: Int, $filters: MongoFilter, $sort: MongoFilter) {
    getTaskGroups {
      count
      taskGroups(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
        name
        order
        practice
        published
        riskPercent
        sowScopeFooter
        sowScopeHeader
        taskGroupCategories {
          id
          name
        }
        lastReviewedOn
        lastReviewedBy
        id
        fixedFee
        createdAt
        createdBy
        outcomeSummary
        businessOutcome
        technicalOutcome
        executiveSummaryOverviewIncluded
        executiveSummaryDriversIncluded
        businessOutcomeIncluded
        technicalOutcomeIncluded
        outcomeProjectScopeIncluded
        outcomeProjectScopePhasesIncluded
        version
      }
    }
  }
`;

export default GET_TASK_GROUPS_WITH_CATS_QUERY;

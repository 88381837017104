import { ApolloClient, useMutation } from '@apollo/client';
import {
  GET_ESTIMATOR_TOTALS_BY_ITEM_ID,
  SET_SITE_INFO_MUTATION,
  GET_PROJECT_QUERY,
  GET_ESTIMATOR_SITES_BY_PROJECT_ITEM_ID_QUERY,
  GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY,
  CALCULATE_ESTIMATOR_TASKS_BY_SITE_ID_MUTATION,
  GET_ESTIMATOR_SUMMARY_TASKS_QUERY,
} from '@cdw-selline/ui/queries';
import { useApolloErrors } from '..';

export interface Site {
  name?: string;
  address?: string;
  floor?: string;
  quantity?: number;
  hideZeroQuantity?: boolean;
  expanded?: boolean;
}

const updateEstimatorSiteCalculation = async (
  client: ApolloClient<any>,
  siteId: string,
  summarySiteCollapsed: boolean,
  siteCollapsed: boolean,
  projectItemId: string,
  includesSummaryTasks: boolean,
) => {
  const refetchQueries = [];
  if (siteCollapsed) {
    await client.mutate({
      mutation: CALCULATE_ESTIMATOR_TASKS_BY_SITE_ID_MUTATION,
      variables: {
        siteId: siteId,
      }
    });
    if (!includesSummaryTasks || (includesSummaryTasks && summarySiteCollapsed)) {
      refetchQueries.push(GET_ESTIMATOR_TOTALS_BY_ITEM_ID);
    }
  } else {
    refetchQueries.push(GET_ESTIMATOR_SITES_BY_PROJECT_ITEM_ID_QUERY);
    refetchQueries.push(GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY);
  }
  if (includesSummaryTasks) {
    if (summarySiteCollapsed) {
      await client.mutate({
        mutation: CALCULATE_ESTIMATOR_TASKS_BY_SITE_ID_MUTATION,
        variables: {
          siteId: `summary-${projectItemId}`,
        }
      });
      if (siteCollapsed) {
        refetchQueries.push(GET_ESTIMATOR_TOTALS_BY_ITEM_ID);
      }
    }
  }

  refetchQueries.push(GET_PROJECT_QUERY);
  await client.refetchQueries({
    include: refetchQueries,
  });
}

export const useSetSiteInfo = () => {
  const { handleErrorResponse } = useApolloErrors();

  const [setSiteInfo, { loading, error, client }] = useMutation(
    SET_SITE_INFO_MUTATION,
    {
      onError: (error) =>
        handleErrorResponse(error, 'Failed to set Site Info!'),
    }
  );

  const [setSiteInfoNoReload, { loading: setSiteInfoNoReloadLoading, error: setSiteInfoNoReloadError }] = useMutation(
    SET_SITE_INFO_MUTATION,
    {
      onError: (error) =>
        handleErrorResponse(error, 'Failed to set Site Info!'),
      onCompleted: (data) => {
        client.refetchQueries({
          include: [GET_ESTIMATOR_SITES_BY_PROJECT_ITEM_ID_QUERY],
        });
      },
    }
  );

  const handleSetSite = (
    projectItemId: string,
    currentSite: string,
    { name, address, floor, quantity, hideZeroQuantity, expanded }: Site,
    includesSummaryTasks?: boolean,
  ) => {
    const quantityChange = quantity !== undefined;

    if (quantityChange) {
      if (quantity) {
        quantity = Number(quantity);
      }
      const localStorageKey = `estimator-summary-site-tasks-${projectItemId}}-expanded`;
      const summarySiteExpanded = String(localStorage.getItem(localStorageKey) ?? 'true') === 'true';

      setSiteInfo({
        variables: {
          projectItemId,
          currentSite,
          siteInfo: {
            name,
            address,
            floor,
            quantity,
            hideZeroQuantity,
            expanded,
          },
        },
        onCompleted: (data) => {
            if (!data?.setSiteInfo?.success) return;
            updateEstimatorSiteCalculation(
              client,
              currentSite,
              !summarySiteExpanded,
              !expanded,
              projectItemId,
              includesSummaryTasks,
            );
          }
        });
    } else {
      setSiteInfoNoReload({
        variables: {
          projectItemId,
          currentSite,
          siteInfo: {
            name,
            address,
            floor,
            quantity,
            hideZeroQuantity,
            expanded,
          },
        },
      });
    }
  };

  return {
    handleSetSite,
    loading: loading || setSiteInfoNoReloadLoading,
    error: error || setSiteInfoNoReloadError,
  };
};

import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  GET_RMS_GP_ANALYSIS_QUERY,
  UPDATE_ESTIMATOR_TASK_GP_MUTATION,
  RESET_ESTIMATOR_TASK_MIN_GP_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { Task } from '@cdw-selline/common/types';

import { useApolloErrors } from '../';

export const useRmsGPAnalysis = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { projectItemId } = useParams<{ projectItemId: string }>();
  const alertState = useAlertsState();

  const {
    loading: rmsGPAnalysisLoading,
    error: rmsGPAnalysisError,
    data: rmsGPAnalysis,
  } = useQuery(GET_RMS_GP_ANALYSIS_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      projectItemId,
    },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch GP Analysis!'),
  });

  const [updateEstimatorTaskGP] = useMutation(
    UPDATE_ESTIMATOR_TASK_GP_MUTATION,
    {
      refetchQueries: [GET_RMS_GP_ANALYSIS_QUERY],
      onError: (error) => handleErrorResponse(error, 'Failed to update GP'),
      onCompleted: (data) => {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully updated GP',
        });
      },
    }
  );

  const [resetEstimatorTaskMinGP] = useMutation(
    RESET_ESTIMATOR_TASK_MIN_GP_MUTATION,
    {
      refetchQueries: [GET_RMS_GP_ANALYSIS_QUERY],
      onError: (error) => handleErrorResponse(error, 'Failed to reset GP'),
      onCompleted: (data) => {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully reset GP',
        });
      },
    }
  );

  const handleTaskGPUpdate = (updatedTask: Task | Task[] | []) => {
    updateEstimatorTaskGP({
      variables: {
        params: updatedTask,
      },
    });
  };

  const handleResetTaskMinGP = () => {
    resetEstimatorTaskMinGP({
      variables: {
        projectItemId,
      },
    });
  };

  return {
    handleTaskGPUpdate,
    handleResetTaskMinGP,
    useRmsGPAnalysis,
    rmsGPAnalysisLoading,
    rmsGPAnalysisError,
    rmsGPAnalysis: rmsGPAnalysis?.getRmsGPAnalysisByItemId ?? {},
  };
};

import { gql } from '@apollo/client';

export const CREATE_JIRA_ISSUE_MUTATION = gql`
  mutation CreateJiraIssue (
    $params: ContentRequestInput!
  ) {
    createJiraIssue(
      params: $params
    ) {
      id
      success
    }
  }
`;
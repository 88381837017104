import { Customer } from '@cdw-selline/common/types';
import { upperFirst } from 'lodash';
import { ValidationError, object, string } from 'yup';

const customerBaseSchema = object({
  name: string().required(),
  customerCode: string().required(),
});

export const useValidateCustomer = () => {
  const validateCustomer = async (customer: Customer) => {
    const errorArray = [];
    try {
      await customerBaseSchema.validate(customer, { abortEarly: false });
    } catch (error) {
      if (error && error instanceof ValidationError) {
        error.inner.forEach((err) => {
          errorArray.push(upperFirst(err.message));
        });
      }
    }

    return errorArray;
  };

  return {
    validateCustomer,
  };
};

import React, { useState, useEffect } from 'react';
import { TaskGroup } from '@cdw-selline/common/types';
import { Button, Typography, Box, Autocomplete, TextField, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useProposalTemplates } from '@cdw-selline/ui/hooks';
import { keyBy } from 'lodash';

export interface TaskGroupProposalTemplatesProps {
  currentTaskGroup: TaskGroup;
  handleEditorChange: (params) => void;
}

export function TaskGroupProposalTemplates(props: TaskGroupProposalTemplatesProps) {
  const [taskGroupProposalTemplates, setTaskGroupProposalTemplates] = useState(
    props.currentTaskGroup?.proposalTemplateIds ?? []
  );
  const [proposalTemplatesOptions, setProposalTemplatesOptions] = useState([]);

  const {
    data: proposalTemplates,
    loading: proposalTemplatesLoading,
    error: proposalTemplatesError,
  } = useProposalTemplates({ filters: {
    isSystem: {$ne: true}
  }, offset: 0, limit: 10000 });

  const proposalTemplateMap = keyBy(proposalTemplates.proposalTemplates, 'id');

  useEffect(() => {
    const filteredTemplates = proposalTemplates.proposalTemplates
      .filter(template=>!taskGroupProposalTemplates.includes(template.id));
    const templatesOptions = filteredTemplates.map((proposalTemplate) => {
      return {
        id: proposalTemplate.id,
        label: proposalTemplate.name,
      };
    }) ?? [];
    setProposalTemplatesOptions(templatesOptions);
  }, [proposalTemplatesLoading, taskGroupProposalTemplates]);
  

  const addRow = () => {
    setTaskGroupProposalTemplates([...taskGroupProposalTemplates, '']);
  };

  const selectProposalTemplate = (index: number, value) => {
    const proposalTemplateCopy = [...taskGroupProposalTemplates];
    proposalTemplateCopy[index] = value.id;
    setTaskGroupProposalTemplates([...proposalTemplateCopy]);
    props.handleEditorChange({
      proposalTemplateIds: [...proposalTemplateCopy],
    });
  };

  const removeRow = (key: string) => {
    const proposalTemplateCopy = [...taskGroupProposalTemplates];
    const newProposalTemplate = proposalTemplateCopy.filter((item) => item !== key);
    setTaskGroupProposalTemplates([...newProposalTemplate]);
    props.handleEditorChange({
        proposalTemplateIds: [...newProposalTemplate],
    });
  };

  return (
    <>
      <Box sx={{ marginTop: 2, marginBottom: 2 }}>
        <Typography variant="h6">Proposal Templates</Typography>
      </Box>
      {!proposalTemplatesLoading && taskGroupProposalTemplates.map((proposalId, index) => (
        <Grid container xl={12} key={`${proposalId}`} sx={{ width: '100%',  marginBottom: 2 }} alignItems= 'space-between'>
            <Autocomplete
              sx={{ width: '95%' }}
              options={proposalTemplatesOptions ?? []}
              onChange={(event, newValue) => {
                selectProposalTemplate(index, newValue);
              }}
              value={proposalTemplateMap[proposalId]?.name}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Proposal Template"
                />
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={props.id}>
                    {option.label}
                  </li>
                );
              }}
            /> 
          <DeleteIcon
            sx={{ marginTop: 2, marginLeft: 1 }}
            onClick={()=>removeRow(proposalId)}
          />
        </Grid>
      ))}
      <Button
        variant="contained"
        sx={{ marginTop: 2, marginBottom: 2 }}
        onClick={addRow}
      >
        Add Proposal Template
      </Button>
    </>
  );
}

export default TaskGroupProposalTemplates;
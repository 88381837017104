import {
  Opportunity,
  Opportunity as Project,
  SalesforceDataInput,
} from '@cdw-selline/common/types';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  useProjects,
  useProjectSalesforceData,
  useServiceLocations,
} from '@cdw-selline/ui/hooks';
import { pick } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormText, FormSelect, FormDateField } from '../formHelperFunctions';
import {
  Grid,
  CircularProgress,
  MenuItem,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
  TextField,
} from '@mui/material';
import { CustomerAutoComplete } from '../add-project/CustomerAutoComplete';
import { probabilityOfClose, customerSegment } from '@cdw-selline/ui/constants';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { UsersAutocomplete } from '../users-autocomplete/UsersAutocomplete';
import {
  getSalesforceIDFromUrl,
  isValidSalesforceOpportunityUrl,
} from '@cdw-selline/ui/helpers';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';

export interface ProjectDetailsFormProps {
  project: Project;
}

export const ProjectDetailsForm = (props: ProjectDetailsFormProps) => {
  const { id } = useParams<{ id: string }>();
  const { updateProject, data, error, loading } = useProjects({ id });
  const {
    updateProjectSalesforceData,
    updateProjectSalesforceDataLoading,
    clearProjectSalesforceData,
    clearProjectSalesforceDataLoading,
  } = useProjectSalesforceData();
  const [changeCustomer, setChangeCustomer] = useState(false);
  const [changeSalesForceId, setChangeSalesforceId] = useState(false);
  const [invalidProjectOwner, setInvalidProjectOwner] = useState(false);
  const [invalidProjectName, setInvalidProjectName] = useState(false);
  const [parsedSalesforceId, setParsedSalesforceId] = useState(
    data.salesForceId || ''
  );
  const [salesforceUrl, setSalesforceUrl] = useState(data.salesForceUrl || '');
  const alertState = useAlertsState();

  const handleProjectUpdateAll = (formData: Partial<Opportunity>): void => {
    const updateRequest = pick({ ...formData, id }, [
      'name',
      'id',
      'description',
      'serviceLocation',
      'projectOwner',
      'estClose',
      'probability',
      'customerId',
      'cdwCustomerAccountNumber',
      'customerSegment',
      'serviceLocation',
      'salesForceId',
    ]);
    updateProject({
      variables: { updateProjectParams: updateRequest },
    });
  };

  const updateSalesForceData = (salesForceUrl) => {
    const isSalesForceUrlChanged = salesForceUrl !== data.salesForceUrl;

    if (!isSalesForceUrlChanged) {
      return;
    }

    if (!salesForceUrl) {
      clearSalesForceData();
      return;
    }

    if (!isValidSalesforceOpportunityUrl(salesForceUrl)) {
      alertState.setAlert({
        type: ALERT_TYPE.MODAL,
        severity: ALERT_SEVERITY.ERROR,
        message: 'Salesforce link must be for the opportunity only.',
      });
      return false;
    }

    const salesForceId = getSalesforceIDFromUrl(salesForceUrl) ?? '';
    setParsedSalesforceId(salesForceId);

    const salesforceData: SalesforceDataInput = {
      salesForceId: salesForceId,
      salesForceUrl: salesForceUrl,
    };

    updateProjectSalesforceData({
      variables: {
        projectId: data.id,
        salesforceData: salesforceData,
      },
    });
  };

  const clearSalesForceData = () => {
    clearProjectSalesforceData({
      variables: {
        projectId: data.id,
      },
    });
  };

  const handleClearSalesforceData = () => {
    setSalesforceUrl('');
    clearSalesForceData();
  };

  const setFormData = (updatedFormData) => {
    if (!updatedFormData.projectOwner) {
      setInvalidProjectOwner(true);
      return;
    }
    if (!updatedFormData.name) {
      setInvalidProjectName(true);
      return;
    }

    setInvalidProjectName(false);
    setInvalidProjectOwner(false);

    handleProjectUpdateAll(updatedFormData);
  };

  const updateCustomer = (updatedFormData) => {
    if (!updatedFormData.customerId) {
      return;
    }
    handleProjectUpdateAll(updatedFormData);
    setChangeCustomer(!changeCustomer);
  };

  const {
    data: serviceLocations,
    error: serviceLocationsError,
    loading: serviceLocationsLoading,
  } = useServiceLocations({ name: 'asc' });
  if (error || serviceLocationsError)
    return <>Error loading form: {error.message}</>;

  if (
    loading ||
    serviceLocationsLoading ||
    updateProjectSalesforceDataLoading ||
    clearProjectSalesforceDataLoading
  )
    return <CircularProgress />;

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="center"
      direction="row"
      spacing={1}
      mt={1}
      sx={{ height: '90vh' }}
      data-testid="project-details-grid"
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="center"
        direction="column"
        spacing={1}
        sx={{ width: '100%' }}
        data-testid="project-details-container"
      >
        {invalidProjectName && (
          <Typography color="error" sx={{ marginLeft: '5px' }}>
            Invalid project name
          </Typography>
        )}
        <FormText
          name="name"
          label="Opportunity Name"
          setFormData={setFormData}
          formData={data}
        />

        <Grid item xs={12} sx={{ width: '100%' }}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel htmlFor="project-details-customer">
              Salesforce Opportunity URL
            </InputLabel>
            <OutlinedInput
              id="salesForceUrl"
              value={salesforceUrl}
              disabled={false}
              type="url"
              notched={true}
              onChange={(e) => setSalesforceUrl(e.target.value)}
              onBlur={(e) => {
                updateSalesForceData(e.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  {data.salesForceUrl && (
                    <IconButton
                      data-testid="project-details-customer-clear-button"
                      aria-label="customer project customer"
                      title="Clear Salesforce Opportunity URL"
                      onClick={handleClearSalesforceData}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        {data.salesForceUrl ? (
          <Grid item xs={12} sx={{ width: '100%' }}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel htmlFor="project-details-customer">
                SalesForce ID
              </InputLabel>
              <OutlinedInput
                id="salesForceId"
                defaultValue={data.salesForceId}
                value={parsedSalesforceId}
                disabled={!changeSalesForceId}
                notched={true}
                onChange={(e) => setParsedSalesforceId(e.target.value)}
                onBlur={(e) => {
                  setFormData({ ...data, salesForceId: e.target.value });
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      data-testid="project-details-customer-edit-button"
                      aria-label="customer project customer"
                      title="Change SalesforceID"
                      onClick={() => setChangeSalesforceId(!changeSalesForceId)}
                    >
                      <EditIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        ) : (
          ''
        )}
        <FormText
          name="description"
          label="Description"
          setFormData={setFormData}
          formData={data}
          multiline={true}
        />
        {changeCustomer ? (
          <CustomerAutoComplete
            setProjectFormData={updateCustomer}
            projectFormData={data}
            onKeyUp={(event) => {
              if (event.key === 'Escape') {
                setChangeCustomer(!changeCustomer);
              }
            }}
          />
        ) : (
          <Grid item xs={12} sx={{ width: '100%' }}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel htmlFor="project-details-customer">
                Customer
              </InputLabel>
              <OutlinedInput
                id="project-details-customer"
                value={data.customerName}
                disabled={true}
                notched={true}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      data-testid="project-details-customer-edit-button"
                      aria-label="customer project customer"
                      title="Change Customer"
                      onClick={() => setChangeCustomer(!changeCustomer)}
                    >
                      <EditIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} sx={{ width: '100%' }}>
          <TextField
            label="CDW Customer Account #"
            name="cdwCustomerAccountNumber"
            value={data.cdwCustomerAccountNumber}
            disabled={true}
            sx={[{ width: '100%' }]}
          />
        </Grid>
        <Grid item xs={12} sx={{ width: '100%' }}>
          <TextField
            label="Customer Segment"
            name="customerSegment"
            value={data.customerSegment}
            disabled={true}
            sx={[{ width: '100%' }]}
          />
        </Grid>
        <FormSelect
          name="serviceLocation"
          label="Customer Service Location"
          setFormData={setFormData}
          formData={data}
        >
          {serviceLocations.map((location) => (
            <MenuItem key={location.id} value={location.id}>
              {location.name}
            </MenuItem>
          ))}
        </FormSelect>
        {invalidProjectOwner && (
          <Typography color="error" sx={{ marginLeft: '5px' }}>
            Invalid project owner
          </Typography>
        )}
        <UsersAutocomplete
          setProjectFormData={setFormData}
          projectFormData={data}
          label="Opportunity Owner"
          name="projectOwner"
        />
        <FormDateField
          name="estClose"
          label="Est. Close"
          setFormData={setFormData}
          formData={data}
        />
        <FormSelect
          name="probability"
          label="Probability of Close"
          setFormData={setFormData}
          formData={data}
        >
          {probabilityOfClose.map((probability) => (
            <MenuItem
              key={`probability-${probability.value}`}
              value={probability.value}
            >
              {probability.label}
            </MenuItem>
          ))}
        </FormSelect>

        {/* <FormCheckbox
          name="demoProvided"
          label="Demo Provided?"
          setFormData={setFormData}
          formData={data}
        />
        <FormCheckbox
          name="cewProvided"
          label="CEW Provided?"
          setFormData={setFormData}
          formData={data}
        /> */}
      </Grid>
    </Grid>
  );
};

import { gql } from '@apollo/client';

export const SET_SITE_INFO_MUTATION = gql`
  mutation setSiteInfoMutation(
    $currentSite: String!
    $projectItemId: ID!
    $siteInfo: SiteInfoInput!
  ) {
    setSiteInfo(
      currentSite: $currentSite
      projectItemId: $projectItemId
      siteInfo: $siteInfo
    ) {
      success
    }
  }
`;

import { useQuery } from '@apollo/client';
import { GET_ORGANIZATIONS_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from '../useApolloErrors';

export const useOrganizations = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getOrganizations;
  }>(GET_ORGANIZATIONS_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch organizations!'),
  });

  return {
    organizations: data?.getOrganizations ?? [],
    error,
    loading,
    refetch,
  };
};

export default useOrganizations;

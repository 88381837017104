import '@cdw-selline/ui/theme';
import {
  DashboardCardsContainer,
} from '@cdw-selline/ui/components';
import { useHealthCheck, useNavTabs } from '@cdw-selline/ui/hooks';
import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { themeGlobals } from '@cdw-selline/ui/theme';
import { ROUTES } from '@cdw-selline/ui/constants';

export const Dashboard = () => {
  useHealthCheck();
  const {
    projectModalOpen,
    handleProjectClick,
    setProjectModalOpen,
    handleProjectSubmit,
    createProjectLoading,
    leadModalOpen,
    handleLeadClick,
    setLeadModalOpen,
    handleLeadSubmit,
    createLeadLoading,
  } = useNavTabs();

  const navigate = useNavigate();

  const routeTo = (id: string, location: string) => {
    navigate({
      pathname: `/project/${id}`,
    }, { state: { from: location ?? ROUTES.HOME } });
  };

  const routeToArbitraryRoute = (route: string) => {
    navigate(route);
  };

  return (
    <Box sx={{ ...themeGlobals.containerMargins }}>
      <DashboardCardsContainer
        routeTo={routeTo}
        routeToArbitraryRoute={routeToArbitraryRoute}
        createProject={handleProjectClick}
        projectModalOpen={projectModalOpen}
        setProjectModalOpen={setProjectModalOpen}
        handleProjectSubmit={handleProjectSubmit}
        createProjectLoading={createProjectLoading}
        createLead={handleLeadClick}
        leadModalOpen={leadModalOpen}
        setLeadModalOpen={setLeadModalOpen}
        handleLeadSubmit={handleLeadSubmit}
        createLeadLoading={createLeadLoading}
      />
    </Box>
  );
};

export default Dashboard;

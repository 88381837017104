import { useAdminMenu } from '@cdw-selline/ui/hooks';

export const useApp = () => {
  const menu = useAdminMenu();

  return {
    menu,
    isLoggedIn: true, // TODO: add better auth check
  };
};

import { gql } from '@apollo/client';

export const GET_CISCO_ANNUITY_PART_NUMBERS = gql`
  query GetCiscoAnnuityPartNumbers($filters: MongoFilter) {
    getCiscoAnnuityPartNumbers(filters: $filters) {
      count
      partNumbers(filters: $filters) {
        id
        partNumber
        category
      }
    }
  }
`;

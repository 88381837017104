import { useLazyQuery } from '@apollo/client';
import { GetCiscoCcwEstimateByIdResponse } from '@cdw-selline/common/types';
import { GET_CISCO_CCW_ESTIMATE_BY_ID_QUERY, GET_CISCO_ANNUITY_BY_PROJECT_ITEM_ID_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from '..';

export const useCiscoCcwEstimates = () => {
  const { handleErrorResponse } = useApolloErrors();

  const [getCiscoCcwEstimateById, { loading, error, data, refetch, client }] =
    useLazyQuery<{
      getCiscoCcwEstimateById: GetCiscoCcwEstimateByIdResponse;
    }>(GET_CISCO_CCW_ESTIMATE_BY_ID_QUERY, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (error) => handleErrorResponse(error, error.message),
      onCompleted: (data) => {
        if (data.getCiscoCcwEstimateById.success) {
          client.refetchQueries({
            include: [GET_CISCO_ANNUITY_BY_PROJECT_ITEM_ID_QUERY],
          });
        }
      }
    });

  return {
    data: data?.getCiscoCcwEstimateById ?? {},
    loading,
    error,
    getCiscoCcwEstimateById,
  };
};

import { useQuery } from '@apollo/client';
import { GET_PROJECT_TASK_NOTES_BY_TASK_ID } from '@cdw-selline/ui/queries';

import { useApolloErrors } from '..';

export const useTaskNotes = (taskId: string) => {
  const { handleErrorResponse } = useApolloErrors();

  const { data, loading, error } = useQuery(GET_PROJECT_TASK_NOTES_BY_TASK_ID, {
    variables: {
      taskId,
    },
    skip: !taskId,
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Task Notes!'),
  });

  return {
    data: data?.getEstimatorTaskNotesByTaskId ?? [],
    loading,
    error,
  };
};

// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  useAdminCiscoAnnuityLines,
  useValidateCiscoAnnuityLines,
} from '@cdw-selline/ui/hooks';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { HyperFormula } from 'hyperformula';
import 'handsontable/dist/handsontable.full.min.css';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import {
  CiscoAnnuityLine,
  CiscoAnnuityLineInput,
} from '@cdw-selline/common/types';
import { isEqual, pick, cloneDeep } from 'lodash';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { getCurrentUser } from '@cdw-selline/ui/state';
import { ROUTES } from '@cdw-selline/ui/constants';

const hf = HyperFormula.buildEmpty({
  licenseKey: 'gpl-v3',
});

const sheetName = hf.addSheet('ciscoAnnuityLines');
const sheetId = hf.getSheetId(sheetName);

/* eslint-disable-next-line */
export interface AdminCiscoAnnuityLinesProps {
  quoteId: string;
}

export const AdminCiscoAnnuityLines = (props: AdminCiscoAnnuityLinesProps) => {
  const currentUser = getCurrentUser();
  const navigate = useNavigate();
  const { id, projectItemId } = useParams<{
    id: string;
    projectItemId: string;
  }>();

  const location = useLocation();
  if (!currentUser.admin) {
    navigate(
      {
        pathname: `/project/${id}`,
      },
      { state: { from: location?.state?.from ?? ROUTES.HOME } }
    );
  }

  registerAllModules();
  const alertState = useAlertsState();
  const tableRef = useRef(null);

  const {
    lines,
    loading,
    error,
    tableData,
    tableColumns,
    handleLinesSave,
    handleResetCategories,
  } = useAdminCiscoAnnuityLines(projectItemId);

  if (loading) {
    return (
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <CircularProgress />
      </Backdrop>
    );
  }

  if (error)
    return (
      <Typography>
        Error loading Cisco Annuity Lines for project id {projectItemId}
      </Typography>
    );

  hf.setCellContents(
    {
      row: 0,
      col: 0,
      sheet: sheetId,
    },
    tableData
  );

  const lineFieldsCheckList = [
    'line',
    'partNumber',
    'category',
    'description',
    'quantity',
    'unitOfMeasure',
    'unitListPrice',
    'customerDiscount',
    'discount',
    'cdwDiscount',
    'extendedNetPrice',
    'unitNetPrice',
  ];

  const getUpdatedLines = (
    lines: CiscoAnnuityLine[],
    newLines: CiscoAnnuityLineInput[]
  ): CiscoAnnuityLine[] | null => {
    const updatedLines = newLines.filter((newLine) => {
      const sourceLine = lines.find((line) => line.id === newLine.id);
      Object.keys(newLine).forEach((key) => {
        if (newLine[key] === null || newLine[key] === undefined) {
          newLine[key] = '';
        }
      });

      if (!sourceLine) {
        return false;
      }

      const sourceLineCopy = cloneDeep(sourceLine);
      Object.keys(sourceLineCopy).forEach((key) => {
        if (sourceLineCopy[key] === null || sourceLineCopy[key] === undefined) {
          sourceLineCopy[key] = '';
        }
      });

      if (
        !isEqual(
          pick(sourceLineCopy, lineFieldsCheckList),
          pick(newLine, lineFieldsCheckList)
        )
      ) {
        return true;
      }

      return false;
    });

    return updatedLines;
  };

  const handleSave = () => {
    const linesTableRef = tableRef.current?.hotInstance.getData();
    const updatedLines = linesTableRef.map((row) => {
      return {
        id: row[0],
        line: row[1],
        partNumber: row[2],
        category: row[3],
        description: row[4],
        quantity: Number(row[5]),
        unitOfMeasure: row[6],
        unitListPrice: Number(row[7]),
        customerDiscount: Number(row[8]),
        // customerPrice: Number(row[9]), //readonly
        // customerTotalPrice: row[10], //readonly
        // customerTermTotal: row[11], //readonly
        discount: Number(row[12]),
        cdwDiscount: Number(row[13]),
        // cdwPrice: row[14],//readonly
        // cdwTotalPrice: row[15],//readonly
        extendedNetPrice: Number(row[16]),
        unitNetPrice: Number(row[17]),
        // gp: row[18],//readonly
      } as CiscoAnnuityLineInput;
    });

    const linesToUpdate = getUpdatedLines(
      lines,
      updatedLines as CiscoAnnuityLineInput[]
    );

    if (linesToUpdate.length > 0) {
      handleLinesSave(linesToUpdate);
    } else {
      alertState.setAlert({
        type: ALERT_TYPE.MODAL,
        severity: ALERT_SEVERITY.WARNING,
        message: 'No changes to save!',
      });
    }
  };

  const resetCategories = () => {
    handleResetCategories(props.quoteId);
  };

  const handleExportClick = () => {
    const exportPlugin = tableRef.current?.hotInstance.getPlugin('exportFile');

    exportPlugin.downloadFile('csv', {
      bom: false,
      columnDelimiter: ',',
      columnHeaders: true,
      exportHiddenColumns: true,
      exportHiddenRows: true,
      fileExtension: 'csv',
      filename: 'Cisco Annuity Lines' + '-CSV-file_[YYYY]-[MM]-[DD]',
      mimeType: 'text/csv',
      rowDelimiter: '\r\n',
      rowHeaders: true,
    });
  };

  return (
    <Grid container sx={{ width: '100%' }}>
      {loading && (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress />
        </Backdrop>
      )}
      <Grid item xs={12}>
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <Grid container alignItems={'flex-end'}>
            <Button onClick={resetCategories}>Reset Categories</Button>
            <Button onClick={handleSave}>Save</Button>
            <Button onClick={handleExportClick}>Export</Button>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            overflow: 'hidden',
            width: '100vw - 5px',
            height: '84vh',
            bgcolor: 'background.paper',
          }}
        >
          <HotTable
            {...{
              id: 'admin-cisco-annuity-lines-table',
              rowHeaders: true,
              autoColumnSize: false,
              autoRowSize: false,
              colHeaders: tableColumns,
              manualColumnFreeze: true,
              contextMenu: true,
              stretchH: 'all',
              manualRowMove: true,
              manualColumnResize: true,
              manualColumnHide: true,
              hiddenColumns: true,
              persistentState: true,
              ref: tableRef,
              data: tableData,
              licenseKey: 'non-commercial-and-evaluation',
              tableClassName: 'table-template',
              className: 'table-cell',
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default AdminCiscoAnnuityLines;

import { gql } from '@apollo/client';

export const COPY_PROJECT_ITEM_ESTIMATOR = gql`
  mutation copyProjectItemEstimatorMutation($projectItemId: ID!) {
    copyProjectItemEstimator(projectItemId: $projectItemId) {
      success
      id
    }
  }
`;

import { useQuery } from '@apollo/client';
import { GET_DISTINCT_TIERS_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from './useApolloErrors';

export const useDistinctTiers = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getDistinctTiers: [string];
  }>(GET_DISTINCT_TIERS_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: (error) => handleErrorResponse(error, 'Failed to fetch Tiers!'),
  });

  return {
    data: data?.getDistinctTiers ?? [],
    error,
    loading,
    refetch,
  };
};

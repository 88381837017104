import { Task } from '@cdw-selline/common/types';
import { useRef, useCallback } from 'react';
import { useUpdateEstimatorTask } from './useUpdateProjectTask';

export const useTaskWriteQueue = () => {
  const taskWriteQueue = useRef([]);
  const { handleTaskUpdate: taskUpdate, updateTaskLoading } =
    useUpdateEstimatorTask(() => (taskWriteQueue.current = []));

  const addTaskToWriteQueue = useCallback(
    (updatedTask: Task) => taskUpdate(updatedTask),
    []
  );

  return {
    addTaskToWriteQueue,
    updateTaskLoading,
  };
};

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { ManagedServicesSummaryResponse } from '@cdw-selline/common/types';
import { GET_MANAGED_SERVICES_SUMMARY_QUERY, GET_PROJECT_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from '../..';

export const useManagedServicesSummary = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { projectItemId } = useParams<{ projectItemId: string }>();
  const { loading, error, data, client } = useQuery<{
    getManagedServicesSummaryByEstimatorId: ManagedServicesSummaryResponse;
  }>(GET_MANAGED_SERVICES_SUMMARY_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: { id: projectItemId },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Manages Services Summary!'),
    onCompleted: (data) => {
      client.refetchQueries({
        include: [GET_PROJECT_QUERY],
      });
    }
  });

  return {
    managedServicesSummary: data?.getManagedServicesSummaryByEstimatorId ?? {
      grossProfit: 0,
      grossProfitPercent: 0,
    },
    error,
    loading,
  };
};

import { CollectionsDataTable, TaskForm } from '@cdw-selline/ui/components';
import { useAdminTasks, useTaskById } from '@cdw-selline/ui/hooks';
import { Box } from '@mui/material';
import React from 'react';

export const TasksCollectionPage = () => {
  const dataTableProps = useAdminTasks();

  const {
    data: task,
    loading: taskLoading,
    error: taskError,
  } = useTaskById(dataTableProps.editTaskId);

  const openForm =
    dataTableProps.taskFormOpen &&
    ((dataTableProps.editTaskId && task.id) || !dataTableProps.editTaskId);

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <CollectionsDataTable
        {...dataTableProps}
        height="calc(100vh - 13em)"
        paginationMode="server"
        sortingMode="server"
        onRowClick={(o) => dataTableProps.handleEdit(o.id)}
        parent="TasksCollectionPage"
      />
      {openForm && (
        <TaskForm
          isOpen={dataTableProps.taskFormOpen}
          handleClose={dataTableProps.handleTaskFormClose}
          handleSubmit={dataTableProps.handleTaskFormSave}
          handleTestVersion={dataTableProps.handleTaskFormTestVersion}
          handleDelete={dataTableProps.handleTaskFormDelete}
          task={task}
          taskLoading={taskLoading}
        />
      )}
    </Box>
  );
};

export default TasksCollectionPage;

import { gql } from '@apollo/client';

export const RELOAD_CISCO_ANNUITY_QUOTE_BY_ID_MUTATION = gql`
  mutation ReloadCiscoAnnuityQuoteById(
    $ciscoAnnuityId: ID!
    $ciscoQuoteId: ID!
  ) {
    reloadCiscoAnnuityQuoteById(
      ciscoAnnuityId: $ciscoAnnuityId
      ciscoQuoteId: $ciscoQuoteId
    ) {
      success
    }
  }
`;

import { gql } from '@apollo/client';

export const SEARCH_USERS_QUERY = gql`
  query SearchUsers($searchTerm: String!) {
    searchUsers {
      users(searchTerm: $searchTerm) {
        _id
        displayName
        email
      }
    }
  }
`;

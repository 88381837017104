import { gql } from '@apollo/client';

export const GET_PROJECT_ITEM_ESTIMATOR_SCHEMA_QUERY = gql`
  query Query($getSchemaId: ID!, $projectItemId: ID) {
    getSchema(id: $getSchemaId) {
      schema {
        description
        properties {
          ... on ProjectItemEstimatorProperties {
            includeTAndEFixedFee {
              title
              type
            }
            overtimePercent {
              title
              type
            }
            pmPercent {
              title
              type
              default
            }
            pmRole(projectItemId: $projectItemId) {
              title
              type
              enum
              enumNames
            }
            riskMitigation {
              default
              title
              type
            }
            sellingAsFixedFee {
              title
              type
              default
            }
            tAndETotal {
              default
              title
              type
            }
            managedServicesContractLength {
              default
              title
              type
            }
          }
        }
        required
        title
        type
      }
      uiSchema: uiSchemaV2
    }
  }
`;

export default GET_PROJECT_ITEM_ESTIMATOR_SCHEMA_QUERY;

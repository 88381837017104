import {
  columnFields,
  GlobalQuoteSettingsFields,
  GlobalQuoteSettingsTableData,
  GlobalQuoteSettingsTableTypes,
  HandsonTableSelectionData,
  ProjectItemQuote,
  ProjectItemQuoteLine,
  ProjectItemQuoteLineInput,
  QuoteTableData,
} from '@cdw-selline/common/types';
import { flatMap } from 'lodash';
import omitDeep from 'omit-deep-lodash';

const { TAXES, HARDWARE, SOFTWARE, SERVICES, SUPPORT } =
  GlobalQuoteSettingsTableTypes;
const {
  TAX_SETTINGS,
  HARDWARE_SETTINGS,
  SOFTWARE_SETTINGS,
  SERVICES_SETTINGS,
  SUPPORT_SETTINGS,
} = GlobalQuoteSettingsFields;

export const getGlobalQuoteSettingsTableData = (
  quoteData: ProjectItemQuote
): GlobalQuoteSettingsTableData => {
  const emptyTableData = [];
  if (!quoteData) {
    return {
      addlCosts: emptyTableData,
      hardware: emptyTableData,
      software: emptyTableData,
      services: emptyTableData,
      support: emptyTableData,
    };
  }
  const getTableData = (
    type: GlobalQuoteSettingsTableTypes,
    targetProp: GlobalQuoteSettingsFields
  ): QuoteTableData => {
    if (targetProp === TAX_SETTINGS) {
      return [
        {
          tableName: type,
          rowName: 'Shipping Tax %',
          value: quoteData[targetProp]?.shippingTaxRate ?? '',
        },
        {
          tableName: type,
          rowName: 'Adder Tax %',
          value: quoteData[targetProp]?.adderTaxRate ?? '',
        },
      ];
    }
    return [
      {
        tableName: type,
        rowName: `Margin %`,
        value: quoteData[targetProp]?.margin ?? '',
      },
      {
        tableName: type,
        rowName: `Markup %`,
        value: quoteData[targetProp]?.markup ?? '',
      },
      {
        tableName: type,
        rowName: `Discount %`,
        value: quoteData[targetProp]?.discount ?? '',
      },
    ];
  };

  return {
    addlCosts: getTableData(TAXES, TAX_SETTINGS),
    hardware: getTableData(HARDWARE, HARDWARE_SETTINGS),
    software: getTableData(SOFTWARE, SOFTWARE_SETTINGS),
    services: getTableData(SERVICES, SERVICES_SETTINGS),
    support: getTableData(SUPPORT, SUPPORT_SETTINGS),
  };
};

const reduceLineItemCosts = (
  quoteData: ProjectItemQuote,
  targetProp: keyof ProjectItemQuoteLine
) => {
  if (!quoteData || !quoteData.quoteLines) return 0;
  return quoteData.quoteLines.reduce((acc, line) => {
    return acc + Number(line[targetProp]);
  }, 0);
};

// TODO confirm getTotalCDWCost and getTotalCustomerCost are looking at the right properties
export const getTotalCDWCost = (quoteData: ProjectItemQuote): number => {
  return reduceLineItemCosts(quoteData, 'totalCost');
};

export const getTotalCustomerCost = (quoteData: ProjectItemQuote): number => {
  return reduceLineItemCosts(quoteData, 'customerTotalCost');
};

export const getTableRows = (quoteData: ProjectItemQuote) => {
  return quoteData.quoteLines.map((line) => {
    const tableRow = {};
    columnFields.forEach((field, idx) => {
      tableRow[idx] = line[field];
    });
    return tableRow;
  });
};

const getValuesInRange = <T>(
  rangeLimit1: number,
  rangeLimit2: number,
  targetArray: Array<T>
): Array<T> => {
  const startPosition = rangeLimit1 < rangeLimit2 ? rangeLimit1 : rangeLimit2;
  const endPosition = rangeLimit1 < rangeLimit2 ? rangeLimit2 : rangeLimit1;
  const subArray = targetArray.slice(startPosition, endPosition + 1);
  return subArray;
};

export const getSelectedProperties = (
  selection: HandsonTableSelectionData
): string[] => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, startCol, __, endCol] = selection;
  const adjustedStart = startCol >= 0 ? startCol : 0;
  const adjustedEnd = endCol >= 0 ? endCol : 0;
  const selectedProperties = getValuesInRange(
    adjustedStart,
    adjustedEnd,
    columnFields
  );
  return selectedProperties;
};

export const getRowIds = (
  selection: HandsonTableSelectionData,
  tableRows: { [columnIndex: number]: string | number }[]
): string[] => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [startRow, _, endRow] = selection;
  const adjustedStart = startRow >= 0 ? startRow : 0;
  const adjustedEnd = endRow >= 0 ? endRow : 0;
  const selectedRows = getValuesInRange(adjustedStart, adjustedEnd, tableRows);
  const selectedRowIds = selectedRows.map((row) => row[0] as string);
  return selectedRowIds;
};

export const getSelectedQuoteLineIds = (
  selections: HandsonTableSelectionData[],
  tableRows: { [columnIndex: number]: string | number }[]
): string[] => {
  const selectedIds = flatMap(selections, (selection) =>
    getRowIds(selection, tableRows)
  );
  return [...new Set(selectedIds)];
};

const prepQuoteLineChange = (
  targetQuoteLine: ProjectItemQuoteLine,
  targetProperties: string[],
  newValue: string
): ProjectItemQuoteLineInput => {
  const targetLineAsInput = omitDeep(targetQuoteLine, '__typename');
  targetProperties.forEach((property) => {
    if (property === 'quantity' || property === 'discount') {
      const numberValue = Number(newValue);
      targetLineAsInput[property] = numberValue;
    } else {
      targetLineAsInput[property] = newValue;
    }
  });
  return targetLineAsInput;
};

export const getAllQuoteLineUpdates = (
  selections: HandsonTableSelectionData[],
  tableRows: { [columnIndex: number]: string | number }[],
  quoteData: ProjectItemQuote,
  newValue: string
): ProjectItemQuoteLineInput[] => {
  return flatMap(selections, (selection) => {
    const selectedQuoteLineIds = getRowIds(selection, tableRows);
    const selectedProperties = getSelectedProperties(selection);

    return selectedQuoteLineIds.map((id) => {
      const targetQuoteLine = quoteData.quoteLines.find(
        (line) => line.id === id
      );
      const updatedQuoteLine = prepQuoteLineChange(
        targetQuoteLine,
        selectedProperties,
        newValue
      );
      return updatedQuoteLine;
    });
  });
};

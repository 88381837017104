// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  useAdminListTasks,
  useServiceItems,
  useServiceSkills,
  useTaskGroups,
  useValidateTask,
  useOpenState,
} from '@cdw-selline/ui/hooks';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { HyperFormula, RawCellContent } from 'hyperformula';
import 'handsontable/dist/handsontable.full.min.css';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import { Task } from '@cdw-selline/common/types';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { formatBoolean } from '@cdw-selline/ui/helpers';
import { DialogConfirm } from '@cdw-selline/ui/components';
import { uniq } from 'lodash';

const hf = HyperFormula.buildEmpty({
  licenseKey: 'gpl-v3',
});

const sheets = ['taskGroupTest', 'estimator', 'other', 'global'];
const sheetName = hf.addSheet('taskGroupTest');
const taskSheetName = hf.addSheet('estimator');
const otherSheetName = hf.addSheet('other');
const globalSheetName = hf.addSheet('global');

const sheetId = hf.getSheetId(sheetName);
const taskSheetId = hf.getSheetId(taskSheetName);
const otherSheetId = hf.getSheetId(otherSheetName);
const globalSheetId = hf.getSheetId(globalSheetName);

/* eslint-disable-next-line */
export interface AdminListTasksPageProps {}

export const AdminListTasksPage = (props: AdminListTasksPageProps) => {
  registerAllModules();
  const alertState = useAlertsState();
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const { validateTasks } = useValidateTask();
  const { taskGroupId } = useParams<{ taskGroupId: string }>();
  const {
    data: taskGroupData,
    loading: taskGroupLoading,
    error: taskGroupError,
  } = useTaskGroups({ filters: { id: taskGroupId.toString() } });
  const taskGroup = taskGroupData?.taskGroups?.[0];
  const [calcDisabled, setCalcDisabled] = useState(false);
  const [taskIdsToDelete, setTaskIdsToDelete] = useState([]);

  const { data: serviceSkills, loading: serviceSkillsLoading } =
    useServiceSkills({});
  const { data: serviceItems, loading: serviceItemsLoading } = useServiceItems(
    {}
  );

  localStorage?.removeItem('admin-test-task-table_manualRowMove');

  const handleBack = () => {
    navigate(-1);
  };

  const {
    tasks,
    tasksLoading,
    tasksError,
    tableData,
    tableColumns,
    handleTasksSave,
    updateTasksLoading,
    getNewTaskId,
    handleTaskDelete,
    removeTasksLoading,
    removeTasksError,
    addNamedExpressions,
    emptyTask,
    taskGroupDependencies,
  } = useAdminListTasks(taskGroupId.toString(), hf);

  const {
    isOpen: confirmDelete,
    handleClose: handleDeleteConfirmClose,
    handleOpen: handleDeleteConfirmOpen,
  } = useOpenState();

  if (tasksLoading || removeTasksLoading) {
    return (
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <CircularProgress />
      </Backdrop>
    );
  }

  if (tasksError)
    return <Typography>Error loading task group {taskGroupId}</Typography>;

  addNamedExpressions(
    hf,
    sheetId,
    taskSheetName,
    otherSheetName,
    globalSheetName
  );

  hf.setCellContents(
    {
      row: 0,
      col: 0,
      sheet: sheetId,
    },
    tableData
  );

  const getTasksWithHyperFormula = () => {
    const taskTableArray = tableRef.current?.hotInstance.getData();
    const hyperformulaTaskArray = hf.getSheetSerialized(sheetId);
    const taskTableKeys = taskTableArray.map((row) => {
      return row[0];
    });

    //sort hfData based on index of dataKeys
    const sortedDataArray = hyperformulaTaskArray.sort((a, b) => {
      const aIndex = taskTableKeys.indexOf(a[0]);
      const bIndex = taskTableKeys.indexOf(b[0]);
      return aIndex - bIndex;
    });

    return sortedDataArray;
  };

  const getTasksWithHandsonTable = () => {
    const taskTableArray = tableRef.current?.hotInstance.getData();

    return taskTableArray;
  };

  const replaceDuplicateOrMissingTaskIds = (updatedTasks: Task[]): Task[] => {
    const taskIdSet = new Set();

    updatedTasks.forEach((task) => {
      if (
        taskIdSet.has(task.taskId) ||
        task.taskId === undefined ||
        task.taskId === '' ||
        task.taskId === null
      ) {
        task.taskId = getNewTaskId(updatedTasks);
      }

      taskIdSet.add(task.taskId);
    });

    return updatedTasks;
  };

  const normalizeTaskData = (updatedTasks: Task[]): Task[] => {
    return updatedTasks.map((task) => {
      for (const key in task) {
        const value = task[key];
        const type = typeof emptyTask[key];

        switch (type) {
          case 'number':
            task[key] = Number(value);
            break;
          case 'boolean':
            task[key] = formatBoolean(value);
            break;
          case 'object': {
            let objectValue = null;

            if (value) {
              try {
                objectValue = JSON.parse(value);
              } catch (error) {
                console.error('error parsing JSON', key, value, task.taskId);
              }
            }

            task[key] = objectValue || emptyTask[key];
            break;
          }
          default:
            break;
        }
      }

      return task;
    });
  };

  const getTaskObject = (
    row: RawCellContent[],
    columns: string[],
    order: number
  ) => {
    const task: Task = {};

    columns.forEach((key, index) => {
      task[key] = row[index] === undefined ? '' : row[index];
    });

    const { skill, skillId, serviceItem, serviceItemId } = task;

    if (skill) {
      const newSkillId = getServiceSkillId(skill);
      if (skillId !== newSkillId) {
        task.skillId = newSkillId;
      }
    } else {
      task.skillId = '';
    }

    if (serviceItem) {
      const newServiceItemId = getServiceItemId(serviceItem);
      if (serviceItemId !== newServiceItemId) {
        task.serviceItemId = newServiceItemId;
      }
    } else {
      task.serviceItemId = '';
    }
    return {
      ...task,
      previousOrder: task.order,
      order,
      taskGroupId: taskGroupId,
    } as Task;
  };

  const handleSave = async () => {
    const columns = tableRef.current?.hotInstance.getColHeader();
    let updatedTasks: Task[] = [];

    if (!calcDisabled) {
      updatedTasks = getTasksWithHyperFormula().map((row, index) => {
        return getTaskObject(row, tableColumns, index);
      });
    } else {
      updatedTasks = getTasksWithHandsonTable().map((row, index) => {
        return getTaskObject(row, columns, index);
      });
    }

    if (!updatedTasks.length) {
      alertState.setAlert({
        type: ALERT_TYPE.MODAL,
        severity: ALERT_SEVERITY.INFO,
        message: 'No changes to save!',
      });
      return;
    }

    const validationMessages = await validateTasks(updatedTasks, true);
    if (validationMessages?.length > 0) {
      alertState.setAlert({
        type: ALERT_TYPE.MODAL,
        title: 'Failed to save tasks!',
        severity: ALERT_SEVERITY.ERROR,
        message: validationMessages.join('\n'),
      });
      return;
    }

    updatedTasks = replaceDuplicateOrMissingTaskIds(updatedTasks as Task[]);

    let tasksToUpdate = normalizeTaskData(updatedTasks);

    tasksToUpdate = updatedTasks.filter((task, rowIndex) => {
      if (task.previousOrder !== task.order || !task.id) {
        return true;
      }

      const rowData = tableRef.current?.hotInstance.getCellMetaAtRow(
        task.order
      );

      return (
        rowData &&
        rowData.some((cell) => {
          return cell.className === 'changed-cell';
        })
      );
    });

    handleTasksSave(tasksToUpdate);
  };

  const handleAfterChange = (changes, source) => {
    if (source === 'UndoRedo.undo') {
      return;
    }

    if (source === 'loadData' || !changes) {
      return;
    }

    //allow single column update
    const columnsCount = uniq(changes.map((item) => item[1])).length;

    if (
      source === 'CopyPaste.paste' &&
      changes.length > 1 &&
      columnsCount > 1
    ) {
      return;
    }

    changes.forEach(([row, column, oldValue, newValue]) => {
      if (oldValue?.toString() === newValue) {
        return;
      }
      const visualColumn = tableRef.current?.hotInstance.propToCol(column);

      tableRef.current?.hotInstance.setCellMeta(
        row,
        visualColumn,
        'className',
        'changed-cell'
      );
      tableRef.current?.hotInstance.render();
    });
  };

  const handleRowMove = (
    movedRows,
    finalIndex,
    dropIndex,
    movePossible,
    orderChanged
  ) => {
    if (!orderChanged || movedRows?.length === tableData?.length) {
      return;
    }

    movedRows.forEach((rowIndex) => {
      const visualColumn = tableRef.current?.hotInstance.propToCol(0);

      if (visualColumn !== undefined) {
        tableRef.current?.hotInstance.setCellMeta(
          dropIndex,
          visualColumn,
          'className',
          'changed-cell'
        );
      }
    });
    tableRef.current?.hotInstance.render();
    localStorage?.removeItem('admin-test-task-table_manualRowMove');
  };

  const handleToggleClick = () => {
    setCalcDisabled(!calcDisabled);
  };

  const handleExportClick = () => {
    const exportPlugin = tableRef.current?.hotInstance.getPlugin('exportFile');

    exportPlugin.downloadFile('csv', {
      bom: false,
      columnDelimiter: ',',
      columnHeaders: true,
      exportHiddenColumns: true,
      exportHiddenRows: true,
      fileExtension: 'csv',
      filename: taskGroup.name + '-CSV-file_[YYYY]-[MM]-[DD]',
      mimeType: 'text/csv',
      rowDelimiter: '\r\n',
      rowHeaders: true,
    });
  };

  const handleResetColumns = () => {
    localStorage?.removeItem('admin-test-task-table_manualRowMove');
    localStorage?.removeItem('admin-test-task-table_manualColumnWidths');
    localStorage?.removeItem('admin-test-task-table_manualColumnMove');
    navigate(0);
  };

  const handleTaskDeleteConfirm = () => {
    handleTaskDelete(taskIdsToDelete.map((task) => task.id));
    handleDeleteConfirmClose();
  };
  const handleBeforeRemoveRow = (index, amount, physicalColumns) => {
    const taskTableArray = tableRef.current?.hotInstance.getData();

    const taskIds = [];
    physicalColumns.forEach((row) => {
      if (taskTableArray[row] && taskTableArray[row][1]) {
        taskIds.push({
          id: tasks[row].id ?? '',
          taskId: tasks[row].taskId ?? '',
        });
      }
    });

    if (taskIds.length > 0) {
      setTaskIdsToDelete(taskIds);
      handleDeleteConfirmOpen();
    }

    return true;
  };

  const formulasEngineConfig: any = {
    engine: hf,
    sheetName: sheetName,
  };

  const getServiceItemId = (value: string) => {
    return (
      serviceItems.serviceItems.find(
        (serviceItem) => serviceItem.name === value
      )?.id || ''
    );
  };

  const getServiceSkillId = (value: string) => {
    return (
      serviceSkills.serviceSkills.find(
        (serviceSkill) => serviceSkill.name === value
      )?.id || ''
    );
  };

  const getColumnType = (tableColumns) => {
    return tableColumns.map((column) => {
      switch (column.trim()?.toLowerCase()) {
        case 'skill':
          return {
            type: 'autocomplete',
            source: serviceSkills.serviceSkills.map((skill) => skill.name),
            strict: false,
            visibleRows: 5,
          };
        case 'serviceitem':
          return {
            type: 'autocomplete',
            source: serviceItems.serviceItems.map((item) => item.name),
            strict: false,
            visibleRows: 5,
          };
        case 'version':
          return {
            readOnly: true,
          };
        default: {
          return {};
        }
      }
    });
  };

  const handleBeforePaste = (data) => {
    data.forEach((val, key) => {
      data[key] =
        Array.isArray(data[key]) && data[key].length > 1
          ? handlePasteElementsBlank(data[key])
          : data[key];
    });
  };

  const handlePasteElementsBlank = (data) => {
    const elementsToEmpty = ['id', 'version'];
    data = tableColumns.map((val, idx) => {
      return elementsToEmpty.includes(val) ? '' : data[idx];
    });

    return data;
  };

  const validateTaskDependency = () => {
    let isValid = true;
    taskIdsToDelete.forEach((task) => {
      Object.keys(taskGroupDependencies).forEach((key) => {
        const referencedTaskIds = taskGroupDependencies[key];
        if (referencedTaskIds.includes(task.taskId) && task.id !== key) {
          isValid = false;
          return;
        }
      });
    });
    return isValid;
  };

  return (
    <Grid container>
      {updateTasksLoading && (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress />
        </Backdrop>
      )}

      <Grid item xs={12} mt={2} ml={1} mr={1}>
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <Grid container alignItems={'flex-end'}>
            <Button onClick={handleBack}>Back</Button>
            <Button data-testid="button-save" onClick={handleSave}>
              Save
            </Button>
            <Button onClick={handleToggleClick}>
              {calcDisabled ? 'Enable Calc' : 'Disable Calc'}
            </Button>
            <Button onClick={handleExportClick}>Export</Button>
            <Button onClick={handleResetColumns}>Reset Columns</Button>
            <Typography variant="h4" ml={8}>
              {taskGroup?.name}
            </Typography>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} mt={2} ml={1} mr={1}>
        <Box
          sx={{
            overflow: 'hidden',
            width: '100vw - 5px',
            height: '84vh',
            bgcolor: 'background.paper',
          }}
        >
          {!calcDisabled ? (
            <HotTable
              {...{
                id: 'admin-test-task-table',
                // colHeaders: true,
                rowHeaders: true,
                autoColumnSize: false,
                autoRowSize: false,
                colHeaders: tableColumns,
                manualColumnFreeze: true,
                contextMenu: true,
                stretchH: 'all',
                manualRowMove: true,
                manualColumnMove: true,
                manualColumnResize: true,
                manualColumnHide: true,
                hiddenColumns: {
                  columns: [0],
                },
                persistentState: true,
                formulas: formulasEngineConfig,
                ref: tableRef,
                data: tableData,
                licenseKey: 'non-commercial-and-evaluation',
                // afterChange: updateNamedExpressions,
                tableClassName: 'table-template',
                className: 'table-cell',
                beforeRemoveRow: handleBeforeRemoveRow,
                afterChange: handleAfterChange,
                afterRowMove: handleRowMove,
                columns: getColumnType(tableColumns),
                beforePaste: handleBeforePaste,
              }}
            />
          ) : (
            <div>
              <HotTable
                {...{
                  id: 'admin-test-task-table',
                  // colHeaders: true,
                  rowHeaders: true,
                  autoColumnSize: false,
                  autoRowSize: false,
                  colHeaders: tableColumns,
                  manualColumnFreeze: true,
                  contextMenu: true,
                  stretchH: 'all',
                  manualRowMove: true,
                  manualColumnMove: true,
                  manualColumnResize: true,
                  manualColumnHide: true,
                  hiddenColumns: true,
                  persistentState: true,
                  ref: tableRef,
                  data: tableData,
                  licenseKey: 'non-commercial-and-evaluation',
                  // afterChange: updateNamedExpressions,
                  tableClassName: 'table-template',
                  className: 'table-cell',
                  beforeRemoveRow: handleBeforeRemoveRow,
                  afterChange: handleAfterChange,
                  afterRowMove: handleRowMove,
                  columns: getColumnType(tableColumns),
                  beforePaste: handleBeforePaste,
                }}
              />
            </div>
          )}
        </Box>
      </Grid>
      {confirmDelete && (
        <DialogConfirm
          title="Delete this Task"
          isOpen={confirmDelete}
          handleClose={handleDeleteConfirmClose}
          handleYes={handleTaskDeleteConfirm}
        >
          <Typography variant="h6">
            Would you like to delete these task(s)?
            {!validateTaskDependency() && (
              <Typography
                sx={{ whiteSpace: 'break-spaces', mt: 1 }}
                color={'error'}
                fontWeight={'bold'}
              >
                BE AWARE ONE OR MORE OF THESE TASKS ARE USED IN OTHER TASK
                FORMULAS
              </Typography>
            )}
          </Typography>
        </DialogConfirm>
      )}
    </Grid>
  );
};

export default AdminListTasksPage;

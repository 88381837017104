import { gql } from '@apollo/client';

export const GET_CISCO_SUBSCRIPTION_LINES = gql`
  query GetCiscoSubscriptionLines($subscriptionId: String!) {
    getCiscoSubscriptionLinesBySubscriptionId(subscriptionId: $subscriptionId) {
        billingAmount
        chargeType
        deliveryOption
        description
        extendedNetPrice
        hostedOffer
        monthlySubscriptionCredit
        overConsumed
        overConsumedQuantity
        quantity
        status
        subscriptionId
        suite
        tfConsumptionQuantity
        tfEntitlement
        totalCredits
        totalDiscount
        typeOfQuantity
        unitListPrice
        unitNetPrice
        usageType
    }
}
`;
import { useQuery } from '@apollo/client';
import { SearchSowSectionsResponse } from '@cdw-selline/common/types';
import { SEARCH_SOW_SECTIONS_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from './useApolloErrors';

export const useSearchSowSections = (
  searchTerm: string,
  { skip = false } = {}
) => {
  const { handleErrorResponse } = useApolloErrors();
  const { data, loading, error } = useQuery<{
    searchSowSections: SearchSowSectionsResponse;
  }>(SEARCH_SOW_SECTIONS_QUERY, {
    variables: {
      searchTerm,
    },
    skip,
    onError: (error) =>
      handleErrorResponse(error, 'Failed to search for SoW Sections!'),
  });

  return {
    data: data?.searchSowSections?.sowSections ?? [],
    loading,
    error,
  };
};

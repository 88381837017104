import { ROUTES } from '@cdw-selline/ui/constants';
import { signInGoogle, signInMicrosoft } from '@cdw-selline/ui/state';
export const useSignInPage = () => {
  const handleGoogleClick = async () => {
    await signInGoogle().catch(console.error);
  };

  const handleMicrosoftClick = async () => {
    await signInMicrosoft().catch(console.error);
  };

  return {
    redirectUrl: ROUTES.HOME,
    handleGoogleClick,
    handleMicrosoftClick,
  };
};

export default useSignInPage;

import { gql } from '@apollo/client';

export const REMOVE_CDW_RATE_MUTATION = gql`
  mutation removeCdwRateMutation($removeCdwRateId: String) {
    removeCdwRate(id: $removeCdwRateId) {
      success
    }
  }
`;

// VARIABLES
// {
//   ...AdminTaskInput
// }

// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  Button,
  Grid,
  Paper,
  styled,
  TextField,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState } from 'react';
import {
  CiscoAnnuityComment,
  CiscoAnnuityCommentInput,
} from '@cdw-selline/common/types';
import { useOpenState } from '@cdw-selline/ui/hooks';
import DialogConfirm from '../../dialog-confirm/DialogConfirm';
import { formatDateDistanceToNow } from '@cdw-selline/ui/helpers';

export interface CiscoAnnuityMessagesProps {
  messages: CiscoAnnuityComment[];
  saveComment: (comment: CiscoAnnuityCommentInput) => void;
  deleteComment: (commentId: string) => void;
  isReadOnly?: boolean;
}

export const CiscoAnnuityMessages = ({
  messages,
  saveComment,
  deleteComment,
  isReadOnly,
}: CiscoAnnuityMessagesProps) => {
  const defaultMessage: CiscoAnnuityCommentInput = {
    id: '',
    note: '',
  };
  const [message, setMessage] = useState(defaultMessage);

  const {
    isOpen: dialogDeleteOpen,
    handleClose: handleDialogDeleteClose,
    handleOpen: handleDialogDeleteOpen,
  } = useOpenState();

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage({ ...message, note: event.target.value });
  };

  const handleSaveComment = () => {
    saveComment(message);
    setMessage(defaultMessage);
  };

  const handleCommentDelete = () => {
    deleteComment(message.id);
    setMessage(defaultMessage);
    handleDialogDeleteClose();
  };

  const handleDialogDelete = (comment: CiscoAnnuityCommentInput) => {
    setMessage(comment);
    handleDialogDeleteOpen();
  };

  return (
    <Grid container spacing={1} justifyContent="flex-start">
      <TextField
        name=""
        label="Additional information for Cloud Fulfillment and/or CSM’s"
        multiline={true}
        placeholder="Enter any information that will be required or helpful for others to know.  If this is not a simple, new SPK/WX order, how is it not?"
        fullWidth
        value={message.note}
        onChange={handleMessageChange}
        disabled={isReadOnly}
      />
      <Grid container mt={1} mb={2} justifyContent="flex-end">
        <Button onClick={handleSaveComment} disabled={isReadOnly}>
          Save
        </Button>
      </Grid>
      <Grid item xs={12} sx={{ maxHeight: '50vh', overflow: 'auto' }}>
        <List>
          {messages.map((message: CiscoAnnuityComment) => (
            <div key={message.id}>
              <ListItem>
                <ListItemText>
                  <Grid container>
                    <Grid item xs={12} sx={{ height: '1em' }}>
                      <Grid container spacing={0}>
                        <Grid item xs={10}>
                          <Typography variant="body2">
                            {message.createdBy}
                          </Typography>
                        </Grid>
                        {!isReadOnly && (
                          <Grid item xs={2}>
                            <DeleteIcon
                              sx={{ ml: 10, cursor: 'pointer', height: '1em' }}
                              onClick={(event) => handleDialogDelete(message)}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        {formatDateDistanceToNow(message.createdAt)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">{message.note}</Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <Divider variant="inset" component="li" sx={{ marginLeft: 0 }} />
            </div>
          ))}
        </List>
      </Grid>
      <DialogConfirm
        title="Delete comment?"
        isOpen={dialogDeleteOpen}
        handleClose={handleDialogDeleteClose}
        handleYes={handleCommentDelete}
      ></DialogConfirm>
    </Grid>
  );
};

import {
  usePractices,
  useCDWRates,
  useAgiloftRoles,
} from '@cdw-selline/ui/hooks';
import { CdwRate } from '@cdw-selline/common/types';
import React, { useEffect, useState } from 'react';
import DialogModal from '../../dialog-modal/DialogModal';
import { Grid, Typography, Box } from '@mui/material';
import {
  FormAutocomplete,
  FormText,
  FormCheckbox,
  getActionButtons,
  FormTextControlled,
} from '../../formHelperFunctions';
import { ApolloError } from '@apollo/client';

export interface CdwRateFormProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (cdwRate: CdwRate) => void;
  cdwRate?: CdwRate | null;
  cdwRateLoading?: boolean;
  cdwRateError?: ApolloError;
}

const newCdwRateDefaults: CdwRate = {
  id: '',
  name: '',
  cost: 0,
};

export function CdwRateForm({
  isOpen,
  handleClose,
  handleSubmit,
  cdwRate,
  cdwRateLoading,
  cdwRateError,
}: CdwRateFormProps) {
  const [cdwRateFormData, setCdwRateFormData] = useState(
    cdwRate ?? newCdwRateDefaults
  );
  const [cost, setCost] = useState(0);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (cdwRate && cdwRate.id) {
      setCdwRateFormData(cdwRate);
    }
  }, [cdwRate]);

  const handleFormClose = () => {
    setCdwRateFormData(newCdwRateDefaults);
    setError(false);
    handleClose();
  };

  const handleSaveClick = () => {
    if (cdwRateFormData.name && cdwRateFormData.cost) {
      handleSubmit(cdwRateFormData);
      setError(false);
      handleClose();
    } else {
      setError(true);
    }
  };

  const {
    data: agiloftRoles,
    loading: agiloftRoleLoading,
    error: agiloftRolesError,
  } = useAgiloftRoles();

  const agiloftRolesOptions =
    agiloftRoles.map((role) => {
      return {
        id: role.id,
        label: role.name,
      };
    }) ?? [];

  const {
    data: practices,
    loading: practiceLoading,
    error: practiceError,
  } = usePractices({});

  const practiceOptions =
    practices.practices.map((practice) => {
      return {
        id: practice.id,
        label: practice.name,
      };
    }) ?? [];

  const {
    data: rates,
    loading: ratesLoading,
    error: ratesError,
  } = useCDWRates({ sort: { name: 'asc' } });

  const cdwRatesOptions =
    rates.rates.map((rate) => {
      if (rate.id === cdwRateFormData.id && !cost) {
        setCost(rate.cost);
      }
      return {
        id: rate.id,
        label: rate.name,
        cost: rate.cost,
      };
    }) ?? [];

  useEffect(() => {
    setCost(
      cdwRatesOptions.find((r) => r.id === cdwRateFormData.id)?.cost ?? 0
    );
  }, [cdwRateFormData.id]);

  if (cdwRateLoading || practiceLoading || ratesLoading)
    return <Typography>Loading form</Typography>;

  if (cdwRateError || practiceError || ratesError)
    return <Typography>Error loading form</Typography>;

  const getTitle = () => {
    return (cdwRateFormData.id && `Edit CdwRate`) || 'Add CdwRate';
  };

  return (
    <DialogModal
      fullWidth={true}
      isOpen={isOpen}
      handleClose={handleClose}
      title={getTitle()}
      action={getActionButtons(handleFormClose, handleSaveClick)}
      maxWidthProp={'md'}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="row"
        spacing={1}
        mt={1}
        sx={{ height: '30vh' }}
        data-testid="cdwRate-grid"
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="column"
          spacing={1}
          sx={{ width: '100%' }}
        >
          {error && (
            <Box sx={{ padding: '15px' }}>
              <Typography color="error" variant="h6">
                Please add all required field values
              </Typography>
            </Box>
          )}
          <FormText
            name="name"
            label="CdwRate Name"
            required={true}
            setFormData={setCdwRateFormData}
            formData={cdwRateFormData}
            error={error && !cdwRateFormData.name}
          />
          <FormText
            name="cost"
            required={true}
            label="Cost"
            type="number"
            setFormData={setCdwRateFormData}
            formData={cdwRateFormData}
            error={error && !cdwRateFormData.cost}
          />
          <FormAutocomplete
            name="agiloftRole"
            label="Agiloft Role"
            options={agiloftRolesOptions}
            setFormData={setCdwRateFormData}
            formData={cdwRateFormData}
          />
        </Grid>
      </Grid>
    </DialogModal>
  );
}

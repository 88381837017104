import { Practice, RoleAndRate, Site } from '@cdw-selline/common/types';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useOpenState } from '@cdw-selline/ui/hooks';
import AddIcon from '@mui/icons-material/Add';
import { Box, Fab, Grid } from '@mui/material';
import React, { CSSProperties } from 'react';

import TaskDrawer from './task-drawer/TaskDrawer';

export interface EstimateControlsProps {
  projectItemId: string;
  currentPractices: Practice[];
  currentRolesAndRates: RoleAndRate[];
  style?: CSSProperties;
  loading?: boolean;
  handleNewSiteDialogOpen?: () => void;
  sites: Site[];
  drawerOpen: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  clickedSiteId: string;
}

export function EstimateControls({
  projectItemId,
  currentPractices,
  style,
  handleNewSiteDialogOpen,
  sites,
  drawerOpen,
  handleDrawerOpen,
  handleDrawerClose,
  clickedSiteId,
}: EstimateControlsProps) {
  return (
    <TaskDrawer
      isOpen={drawerOpen}
      handleDrawerOpen={handleDrawerOpen}
      handleDrawerClose={handleDrawerClose}
      projectItemId={projectItemId}
      practices={currentPractices}
      handleNewSiteDialogOpen={handleNewSiteDialogOpen}
      sites={sites}
      clickedSiteId={clickedSiteId}
    />
  );
}

export default EstimateControls;

import { gql } from '@apollo/client';

export const GET_PRACTICE_BY_ID_QUERY = gql`
  query getPracticeByIdQuery($id: ID!) {
    getPracticeById(id: $id) {
      bidAssurance
      id
      managedSvc
      name
      peerReviewEmail
      practiceId
      practiceUsernames
      psm
      serviceLocationId
      web
      salesForceId
    }
  }
`;

// VARIABLES
// { practiceId: "uuid" }

import { gql } from '@apollo/client';

export const GET_ROLES_QUERY = gql`
  query GetRolesQuery(
    $filters: MongoFilter
    $limit: Int
    $offset: Int
    $sort: MongoFilter
  ) {
    getRoles(filters: $filters, limit: $limit, offset: $offset, sort: $sort) {
      count
      roles {
        cdwRateId
        cost
        id
        locationId
        name
        otCost
        practice
        practiceName
        projectManager
        rate
        roleId
        createdAt
        cdwRateName
      }
    }
  }
`;

import { useQuery } from '@apollo/client';
import { ProjectProposal } from '@cdw-selline/common/types';
import {
  GET_PROJECT_PROPOSALS_BY_PROJECT_ID_QUERY,
  GET_PROJECT_PROPOSAL_BY_ID_QUERY,
  GET_PROJECT_PROPOSALS_BY_ESTIMATOR_ID_QUERY,
} from '@cdw-selline/ui/queries';

import { useApolloErrors } from '../useApolloErrors';

export const useGetProjectProposalsByProjectId = (projectId: string) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery<{
    getProjectProposalsByProjectId: ProjectProposal[];
  }>(GET_PROJECT_PROPOSALS_BY_PROJECT_ID_QUERY, {
    variables: { projectId },
    onError: (error) =>
      handleErrorResponse(
        error,
        'Failed to fetch Project Proposals by project id!'
      ),
  });
  return {
    data: data?.getProjectProposalsByProjectId ?? [],
    loading,
    error,
  };
};

export const useGetProjectProposalById = (proposalId: string) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery<{
    getProjectProposalById: ProjectProposal;
  }>(GET_PROJECT_PROPOSAL_BY_ID_QUERY, {
    skip: !proposalId,
    variables: { getProjectProposalByIdId: proposalId },
    fetchPolicy: 'network-only',
    onError: (error) => {
      console.log('err', error.message);
    },
    // handleErrorResponse(error, 'Failed to fetch Project Proposal by id!'),
  });
  return {
    data: data?.getProjectProposalById,
    loading,
    error,
  };
};

export const useGetProjectProposalsByEstimatorId = (
  projectId: string,
  estimatorId: string
) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery<{
    getProjectProposalsByEstimatorId: ProjectProposal[];
  }>(GET_PROJECT_PROPOSALS_BY_ESTIMATOR_ID_QUERY, {
    variables: { projectId, estimatorId },
    onError: (error) =>
      handleErrorResponse(
        error,
        'Failed to fetch Project Proposals by estimator id!'
      ),
  });
  return {
    data: data?.getProjectProposalsByEstimatorId ?? [],
    loading,
    error,
  };
};

import React from 'react';
import {
  Typography,
  MenuItem,
  FormControlLabel,
  Switch,
  Select,
  Button,
} from '@mui/material';
import { TASK_STATUS } from '@cdw-selline/ui/constants';

export function TaskFormHeader({
  taskFormData,
  task,
  handleToggleChange,
  versionList,
  selectedVersion,
  handleChangeVersion,
  handleRestoreConfirmOpen,
}) {
  return (
    <>
      Edit Task
      <Typography variant="caption" sx={{ ml: 1 }}>
        (Version: {task?.version})
      </Typography>
      {taskFormData.originalTaskId || taskFormData.inTestingTaskId ? (
        <FormControlLabel
          control={
            <Switch
              checked={taskFormData.status === TASK_STATUS.IN_TESTING}
              onChange={handleToggleChange}
              data-testid="in-testing-toggle"
            />
          }
          sx={{ marginLeft: '3px' }}
          label="In Testing Version"
        />
      ) : (
        versionList.length > 0 && (
          <>
            <FormControlLabel
              sx={{ marginLeft: '3px' }}
              label="Versions:"
              labelPlacement="start"
              control={
                <Select
                  value={selectedVersion}
                  sx={{
                    ml: 1,
                    '& .css-lelgx8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
                      {
                        paddingTop: 0.5,
                        paddingBottom: 0.5,
                      },
                  }}
                  onChange={handleChangeVersion}
                >
                  {[task.version, ...versionList].map((version) => (
                    <MenuItem key={version} value={version}>
                      {version}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
            {taskFormData.version !== task.version && (
              <Button
                sx={{ marginLeft: '10px' }}
                onClick={handleRestoreConfirmOpen}
              >
                Restore
              </Button>
            )}
          </>
        )
      )}
    </>
  );
}

export default TaskFormHeader;

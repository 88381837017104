import { ProjectSowComponent } from '@cdw-selline/ui/components';
import { Stack } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useProjectTasksSowSections } from '@cdw-selline/ui/hooks';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProjectSowTabProps {}

export function ProjectSowTab(props: ProjectSowTabProps) {
  const { id, projectItemId: estimatorId } =
    useParams<{ id: string; projectItemId: string }>();
  const projectSowData = useProjectTasksSowSections(id, estimatorId);

  return (
    <Stack>
      <ProjectSowComponent {...projectSowData} />
    </Stack>
  );
}

export default ProjectSowTab;

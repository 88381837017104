import { useMutation } from '@apollo/client';
import {
  UPDATE_PROJECT_PROPOSAL_MUTATION,
  GET_PROJECT_PROPOSALS_BY_ESTIMATOR_ID_QUERY,
  GET_PROJECT_PROPOSAL_BY_ID_QUERY,
} from '@cdw-selline/ui/queries';
import { useApolloErrors } from '../useApolloErrors';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { ProjectProposalInput } from '@cdw-selline/common/types';

export const useUpdateProjectProposal = (completeCallback?: () => void) => {
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();

  const [updateProjectProposal, { loading, error }] = useMutation(
    UPDATE_PROJECT_PROPOSAL_MUTATION,
    {
      refetchQueries: [
        GET_PROJECT_PROPOSALS_BY_ESTIMATOR_ID_QUERY,
        GET_PROJECT_PROPOSAL_BY_ID_QUERY,
      ],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update Project Proposal!'),
      onCompleted: (data) => {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully Updated Project Proposal!',
        });
        completeCallback?.();
      },
    }
  );

  const [
    updateProposalContent,
    { loading: loadingContent, error: errorContent },
  ] = useMutation(UPDATE_PROJECT_PROPOSAL_MUTATION, {
    onError: (error) =>
      handleErrorResponse(error, 'Failed to update Project Proposal!'),
  });

  const handleUpdateProjectProposal = (
    modifiedProposal: ProjectProposalInput,
    reload: boolean
  ) => {
    const variables = {
      params: modifiedProposal,
    };
    if (reload) {
      updateProjectProposal({ variables });
    } else {
      updateProposalContent({ variables });
    }
  };

  return {
    handleUpdateProjectProposal,
    loading,
    error,
  };
};

import { useMutation } from '@apollo/client';
import {
  DefaultAddResponse,
  DefaultMutationResponse,
  AgiloftTravelNoticeType,
} from '@cdw-selline/common/types';
import {
  ADD_AGILOFT_TRAVEL_NOTICE_TYPE_MUTATION,
  GET_AGILOFT_TRAVEL_NOTICE_TYPES_QUERY,
  REMOVE_AGILOFT_TRAVEL_NOTICE_TYPE_MUTATION,
  UPDATE_AGILOFT_TRAVEL_NOTICE_TYPE_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import {
  GridColDef,
  GridPaginationModel,
  GridRowsProp,
  GridFilterModel,
} from '@mui/x-data-grid';
import { useState } from 'react';

import { useApolloErrors } from '../useApolloErrors';
import { useAgiloftTravelNoticeTypes } from './useAgiloftTravelNoticeTypes';
import { useOpenState } from '../estimator/useOpenState';
import omitDeep from 'omit-deep-lodash';
import {
  getFilters,
  getFilterModelFromLocalStorage,
} from '@cdw-selline/ui/helpers';
import { PAGES } from '@cdw-selline/ui/constants';

const columns: GridColDef[] = [
  {
    field: 'travelNoticeTypeName',
    headerName: 'Agiloft TravelNotice Type',
    width: 180,
    flex: 1,
  },
  {
    field: 'travelNoticeClauses',
    headerName: 'Agiloft TravelNotice Clauses',
    width: 180,
    flex: 1,
  },
  {
    field: 'agiloftDefaultTravelNoticeClause',
    headerName: 'Default Agiloft TravelNotice Clauses',
    width: 180,
    flex: 1,
  },
];

export const useAdminAgiloftTravelNoticeTypes = () => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  const [sortState, setSortState] = useState();

  const filterModel: GridFilterModel = getFilterModelFromLocalStorage(`${PAGES.AGILOFT_TRAVEL_NOTICE_TYPES_COLLECTION}-filter`);

  const { data, loading, error, refetch } = useAgiloftTravelNoticeTypes({
    filters: getFilters(PAGES.AGILOFT_TRAVEL_NOTICE_TYPES_COLLECTION),
    offset: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize,
    sort: sortState,
  });
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();
  const [editAgiloftTravelNoticeTypeId, setEditAgiloftTravelNoticeTypeId] =
    useState(null);
  const {
    isOpen: agiloftTravelNoticeTypeFormOpen,
    handleClose: closeAgiloftTravelNoticeTypeForm,
    handleOpen: openAgiloftTravelNoticeTypeForm,
  } = useOpenState();

  const [
    addAgiloftTravelNoticeType,
    {
      loading: addAgiloftTravelNoticeTypeLoading,
      error: addAgiloftTravelNoticeTypeError,
    },
  ] = useMutation<{ addAgiloftTravelNoticeType: DefaultAddResponse }>(
    ADD_AGILOFT_TRAVEL_NOTICE_TYPE_MUTATION,
    {
      refetchQueries: [GET_AGILOFT_TRAVEL_NOTICE_TYPES_QUERY],
      onError: (error) =>
        handleErrorResponse(
          error,
          'Failed to create new Agiloft Travel Notice Type'
        ),
      onCompleted: (data) => {
        if (data.addAgiloftTravelNoticeType.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully added Agiloft Travel Notice Type',
          });
        }
      },
    }
  );

  const [
    removeAgiloftTravelNoticeType,
    {
      loading: removeAgiloftTravelNoticeTypeLoading,
      error: removeAgiloftTravelNoticeTypeError,
    },
  ] = useMutation<{ removeAgiloftTravelNoticeType: DefaultMutationResponse }>(
    REMOVE_AGILOFT_TRAVEL_NOTICE_TYPE_MUTATION,
    {
      refetchQueries: [GET_AGILOFT_TRAVEL_NOTICE_TYPES_QUERY],
      onError: (error) =>
        handleErrorResponse(
          error,
          'Failed to remove Agiloft Travel Notice Type'
        ),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.removeAgiloftTravelNoticeType.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully removed Agiloft Travel Notice Type',
          });
        }
      },
    }
  );

  const [
    updateAgiloftTravelNoticeType,
    {
      loading: updateAgiloftTravelNoticeTypeLoading,
      error: updateAgiloftTravelNoticeTypeError,
    },
  ] = useMutation<{ updateAgiloftTravelNoticeType: DefaultMutationResponse }>(
    UPDATE_AGILOFT_TRAVEL_NOTICE_TYPE_MUTATION,
    {
      refetchQueries: [GET_AGILOFT_TRAVEL_NOTICE_TYPES_QUERY],
      awaitRefetchQueries: true,
      onError: (error) =>
        handleErrorResponse(
          error,
          'Failed to update Agiloft Travel Notice Type'
        ),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.updateAgiloftTravelNoticeType.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully updated Agiloft Travel Notice Type',
          });
        }
      },
    }
  );

  const handleAdd = () => {
    openAgiloftTravelNoticeTypeForm();
  };

  const handleAgiloftTravelNoticeTypeFormClose = () => {
    setEditAgiloftTravelNoticeTypeId(null);
    closeAgiloftTravelNoticeTypeForm();
  };

  const handleDelete = (id: string) =>
    removeAgiloftTravelNoticeType({
      variables: { removeAgiloftTravelNoticeTypeId: id },
    });
  const handleEdit = (id: string) => {
    setEditAgiloftTravelNoticeTypeId(id);
    openAgiloftTravelNoticeTypeForm();
  };
  const handlePaginationModelChange = (model: GridPaginationModel) => {
    const pageSize = model?.pageSize ?? paginationModel?.pageSize;
    const page = model?.page ?? paginationModel?.page;
    setPaginationModel({
      page,
      pageSize,
    });
  };

  const onFilterModelChange = (filterModel) => {
    refetch({ filters: getFilters(PAGES.AGILOFT_TRAVEL_NOTICE_TYPES_COLLECTION) });
  };

  const handleAgiloftTravelNoticeTypeFormSave = (
    agiloftTravelNoticeType: AgiloftTravelNoticeType
  ) => {
    if (!agiloftTravelNoticeType.id) {
      addAgiloftTravelNoticeType({
        variables: {
          params: omitDeep(agiloftTravelNoticeType, ['__typename']),
        },
      });
    }

    if (agiloftTravelNoticeType.id) {
      updateAgiloftTravelNoticeType({
        variables: {
          params: omitDeep(agiloftTravelNoticeType, ['__typename']),
        },
      });
    }
  };

  const handleSort = (args) => {
    let newSort;
    if (args.length) newSort = { [args[0].field]: args[0].sort };
    setSortState(newSort);
  };
  const returnValue = {
    columns,
    rows: data.agiloftTravelNoticeTypes || ([] as GridRowsProp),
    handleAdd,
    handleDelete,
    handleEdit,
    handlePaginationModelChange,
    onFilterModelChange,
    handleSort,
    filterModel: filterModel,
    agiloftTravelNoticeTypes: data.agiloftTravelNoticeTypes,
    editAgiloftTravelNoticeTypeId,
    addAgiloftTravelNoticeTypeLoading,
    addAgiloftTravelNoticeTypeError,
    removeAgiloftTravelNoticeTypeLoading,
    removeAgiloftTravelNoticeTypeError,
    loading:
      loading ||
      addAgiloftTravelNoticeTypeLoading ||
      removeAgiloftTravelNoticeTypeLoading,
    error,
    agiloftTravelNoticeTypeFormOpen,
    handleAgiloftTravelNoticeTypeFormClose,
    handleAgiloftTravelNoticeTypeFormSave,
    rowCount: data.count,
  };

  if (
    loading ||
    addAgiloftTravelNoticeTypeLoading ||
    removeAgiloftTravelNoticeTypeLoading
  ) {
    return {
      ...returnValue,
      paginationModel: { pageSize: 100, page: 0 },
      rowCount: 0,
      rows: [],
    };
  } else {
    return { ...returnValue, paginationModel };
  }
};

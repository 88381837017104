import '@cdw-selline/ui/theme';
import { useOpportunities } from './useOpportunities';
import { getFilters } from '@cdw-selline/ui/helpers';
import { pick } from 'lodash';
import { pruneEmpty } from '@cdw-selline/ui/common';
import { datagridPagination, getFilterModelFromLocalStorage } from '@cdw-selline/ui/helpers';
import { useState } from 'react';
import { GridFilterModel } from '@mui/x-data-grid';
import { PAGES } from '@cdw-selline/ui/constants';

export const useDashboard = (userFilter) => {
  const [filterModel, setFilterModel] = useState<GridFilterModel>(() => getFilterModelFromLocalStorage(`${PAGES.OPPORTUNITY_VIEW}-filter`));

  const { loading, data, error, project, refetch, count } = useOpportunities(
    {
      offset: 0,
      limit: 100,
      filters: getFilters(PAGES.OPPORTUNITY_VIEW, userFilter),
      sort: { estClose: 1 },
    },
    true
  );

  const { handlePaginationModelChange, handleSort, paginationModel } =
    datagridPagination(refetch, getFilters(PAGES.OPPORTUNITY_VIEW, userFilter), {
      estClose: 1,
    });

  const handleProjectUpdate = (params) => {
    project.updateProject({
      variables: {
        updateProjectParams: pick(pruneEmpty(params), [
          'name',
          'id',
          'estClose',
          'probability',
        ]),
      },
    });
  };

  const onFilterModelChange = () => {
    refetch({ filters: getFilters(PAGES.OPPORTUNITY_VIEW, userFilter) });
  };

  const returnValue = {
    error,
    loading,
    rows: data ? data : [],
    handleSave: handleProjectUpdate,
    onFilterModelChange,
    filterModel,
    rowCount: count,
    handlePaginationModelChange,
    handleSort,
  };
  if (loading) {
    return {
      ...returnValue,
      paginationModel: { pageSize: 100, page: 0 },
      rowCount: 0,
      rows: [],
    };
  } else {
    return { ...returnValue, paginationModel };
  }
};

import { useMutation } from '@apollo/client';
import { VALIDATE_READY_FOR_AGILOFT } from '@cdw-selline/ui/queries';
import { AgiloftApiResponse } from '@cdw-selline/common/types';
import { useEffect, useState } from 'react';

export interface useValidateReadyForAgiloft {
  progress: number;
  setProgress: (progress: number) => void;
  startProgress: number;
  endProgress: number;
  setProgressMessage: (message: string) => void;
  agiloftSOWId: string;
  projectId: string;
  projectItemId: string;
}

export const useValidateReadyForAgiloft = ({
  progress,
  setProgress,
  startProgress,
  endProgress,
  setProgressMessage,
  agiloftSOWId,
  projectId,
  projectItemId,
}: useValidateReadyForAgiloft) => {

  const [validateReadyForAgiloftRunning, setValidateReadyForAgiloftRunning] =
    useState(false);

  const [validateReadyForAgiloft, { error: validateReadyForAgiloftError }] =
    useMutation<{
      validateReadyForAgiloft: AgiloftApiResponse;
    }>(VALIDATE_READY_FOR_AGILOFT, {
      onError: (error) => {
        setProgress(100);
        setValidateReadyForAgiloftRunning(false);
        setProgressMessage(error.message);
      },
      onCompleted: (data) => {
        if(!data.validateReadyForAgiloft.success) {
          setProgress(100);
          setValidateReadyForAgiloftRunning(false);
          setProgressMessage(data.validateReadyForAgiloft.error)

          return;
        }

        setProgress(endProgress);
        setValidateReadyForAgiloftRunning(false);
      },
    });

  useEffect(() => {
    if (progress === startProgress) {
      setProgressMessage('Validating project is ready for Agiloft ..');
      setValidateReadyForAgiloftRunning(true);
      validateReadyForAgiloft({
        variables: {
          params: {
            id: agiloftSOWId,
            projectId: projectId,
            projectItemId: projectItemId,
          },
        },
      });
    }
  }, [
    progress,
    startProgress,
    setProgressMessage,
    validateReadyForAgiloft,
    agiloftSOWId,
    projectId,
    projectItemId,
  ]);

  useEffect(() => {
    if (progress < 100 && validateReadyForAgiloftRunning) {
      const timer = setTimeout(() => {
        if (validateReadyForAgiloftRunning && progress < endProgress - 1) {
          setProgress(progress + 1);
        }
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [
    validateReadyForAgiloft,
    validateReadyForAgiloftRunning,
    endProgress,
    progress,
    setProgress,
  ]);

  return {
    validateReadyForAgiloftRunning,
    validateReadyForAgiloftError,
  };
};

// eslint-disable-next-line @nx/enforce-module-boundaries
import { labelStrings, titleStrings } from '@cdw-selline/ui/constants';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useNewSiteDialog } from '@cdw-selline/ui/hooks';
import { Button, DialogActions, Grid, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { FormEvent } from 'react';
import DialogModal from '../../../dialog-modal/DialogModal';

export interface NewSiteDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  projectItemId: string;
}

export function NewSiteDialog({
  isOpen,
  handleClose,
  projectItemId,
}: NewSiteDialogProps) {
  const { ADD_SITE } = titleStrings;
  const { CANCEL, SUBMIT, SITE_NAME, SITE_ADDRESS, SITE_FLOOR } = labelStrings;
  const {
    newSite,
    handleSiteNameChange,
    handleaddressChange,
    handlefloorChange,
    handleSiteSubmit,
    addSiteLoading,
  } = useNewSiteDialog(projectItemId);

  const handleFormSubmit = (e: FormEvent) => {
    handleSiteSubmit(e);
    handleClose();
  };

  const inputStyle = { margin: '1em 0' };

  return (
    <DialogModal
      {...{
        isOpen,
        handleClose,
        maxWidth: 'sm',
      }}
      title={ADD_SITE}
    >
      <form
        onSubmit={handleFormSubmit}
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
          <Grid item xs={12}>
            <TextField
              label={SITE_NAME}
              value={newSite.name}
              onChange={handleSiteNameChange}
              inputProps={{ 'aria-label': 'site name' }}
              sx={inputStyle}
              data-testid="add-site-name-input"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={SITE_ADDRESS}
              value={newSite.address}
              onChange={handleaddressChange}
              inputProps={{ 'aria-label': 'site address' }}
              sx={inputStyle}
              data-testid="add-site-address-input"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={SITE_FLOOR}
              value={newSite.floor}
              onChange={handlefloorChange}
              inputProps={{ 'aria-label': 'site floor' }}
              sx={inputStyle}
              data-testid="add-site-floor-input"
              fullWidth
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleClose}
            data-testid="add-site-cancel-button"
          >
            {CANCEL}
          </Button>
          <LoadingButton
            type="submit"
            aria-label="submit site info"
            data-testid="add-site-submit-button"
            variant="contained"
            loading={addSiteLoading}
          >
            {SUBMIT}
          </LoadingButton>
        </DialogActions>
      </form>
    </DialogModal>
  );
}

export default NewSiteDialog;

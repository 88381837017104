import {
  Plugin,
  PluginCallbacks,
  State,
  StateValueAtRoot,
} from '@hookstate/core';

const PluginID = Symbol('SessionPersistence');

export const Persistence = (sessionStorageKey: string) => {
  return (): Plugin => ({
    id: PluginID,
    init: (state: State<StateValueAtRoot>) => {
      const persisted = sessionStorage.getItem(sessionStorageKey);
      if (persisted !== null) {
        const result = JSON.parse(persisted);
        state.set(result);
      } else if (!state.promised && !state.error) {
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(state.value));
      }
      return {
        onSet: (p) => {
          if ('state' in p) {
            sessionStorage.setItem(sessionStorageKey, JSON.stringify(p.state));
          } else {
            sessionStorage.removeItem(sessionStorageKey);
          }
        },
      } as PluginCallbacks;
    },
  });
};

import { ROUTES } from '@cdw-selline/ui/constants';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessIcon from '@mui/icons-material/Business';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PeopleIcon from '@mui/icons-material/People';

export const useNavMenu = () => {
  if (process.env.NODE_ENV !== 'production') {
    return {
      topMenu: [
        {
          icon: DashboardIcon,
          label: 'Dashboard',
          path: ROUTES.HOME,
        },
        {
          icon: AccountCircleIcon,
          label: 'Profile',
          path: ROUTES.PROFILE,
        },
        {
          icon: NotificationsIcon,
          label: 'User Notifications',
          path: ROUTES.NOTIFICATIONS,
        },
      ],
      menu: [
        {
          icon: BusinessIcon,
          label: 'Opportunities',
          path: ROUTES.PIPELINES,
        },
        /* TEMP: process.env.NODE_ENV === 'production' */
        // {
        //   icon: PeopleIcon,
        //   label: 'Leads',
        //   path: ROUTES.LEADS,
        // },
        {
          icon: PeopleIcon,
          label: 'Kanban',
          path: ROUTES.KANBAN,
        },
        {
          icon: LocalActivityIcon,
          label: 'Activity',
          path: ROUTES.ACTIVITY,
        },
      ],
    };
  } else {
    return {};
  }
};

import { gql } from '@apollo/client';

export const GET_TIERS_QUERY = gql`
  query getTiersQuery($filters: MongoFilter, $limit: Int, $offset: Int, $sort: MongoFilter) {
    getTiers {
      count(filters: $filters)
      tiers(filters: $filters, limit: $limit, offset: $offset, sort: $sort) {
        id
        name
        createdAt
      }
    }
  }
`;

/* eslint-disable @nx/enforce-module-boundaries */
import { usePsaBreakdownSummary } from '@cdw-selline/ui/hooks';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Collapse,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { formatCurrency } from '@cdw-selline/ui/helpers';
import { FormSelect } from '../../formHelperFunctions';

export interface PsaBreakdownSummaryProps {
  handleUpdate: (e, fixed) => void;
  projectItemEstimator: any;
  isReadOnly: boolean;
}

export const PsaBreakdownSummary = ({
  handleUpdate,
  projectItemEstimator,
  isReadOnly,
}) => {
  const { estimateToPsaResponse, error, loading, estimateProducts } =
    usePsaBreakdownSummary();
  const multiEstimateProducts = estimateProducts?.length > 1;

  const theme = useTheme();

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleAll = () => {
    setIsExpanded((prevState) => !prevState);
  };

  useEffect(() => {
    const storedIsExpanded = localStorage.getItem('isPSAExpanded');
    setIsExpanded(storedIsExpanded === 'true' ? true : false);
  }, []);

  useEffect(() => {
    localStorage.setItem('isPSAExpanded', String(isExpanded));
  }, [isExpanded]);

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="PSA Breakdown Summary"
              sx={{
                backgroundColor: 'primary.dark',
                padding: '5px 15px',
              }}
              titleTypographyProps={{
                variant: 'h6',
                color: theme.palette.common.white,
              }}
              action={
                estimateToPsaResponse?.length > 1 ? (
                  <Button
                    variant="text"
                    size="small"
                    sx={{
                      mr: '1em',
                      mt: '.5em',
                      color: 'common.white',
                    }}
                    endIcon={
                      isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />
                    }
                    onClick={handleToggleAll}
                    data-testid="psa-breakdown-expand"
                  >
                    {isExpanded ? 'Collapse' : 'Expand'}
                  </Button>
                ) : null
              }
            />
            <Divider />
            <Box sx={{ width: '100%' }}>
              {loading && <Skeleton />}

              {!error?.message && !loading && estimateToPsaResponse?.length && (
                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        {multiEstimateProducts && (
                          <TableRow>
                            <TableCell colSpan={2}>
                              <Grid container>
                                <Grid item xs={4}>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        defaultChecked={
                                          projectItemEstimator?.psaSyncCombined ??
                                          false
                                        }
                                        onChange={(e) =>
                                          handleUpdate(
                                            e.target.value,
                                            e.target.checked
                                          )
                                        }
                                        value="psaSyncCombined"
                                        data-testid="psaSyncCombined"
                                      />
                                    }
                                    label="Use Single Product?"
                                  />
                                </Grid>
                                <Grid item xs={8}>
                                  {projectItemEstimator?.psaSyncCombined && (
                                    <FormSelect
                                      name="psaEstimateProduct"
                                      label="Estimate Product"
                                      setFormData={(estimator) => {
                                        handleUpdate(
                                          'psaEstimateProduct',
                                          estimator?.psaEstimateProduct
                                        );
                                      }}
                                      formData={projectItemEstimator}
                                      disabled={isReadOnly}
                                    >
                                      {estimateProducts.map((product) => {
                                        return (
                                          <MenuItem
                                            key={product}
                                            value={product}
                                          >
                                            {product}
                                          </MenuItem>
                                        );
                                      })}
                                    </FormSelect>
                                  )}
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell sx={{ textAlign: 'right' }}>
                            Bill Amount
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {estimateToPsaResponse?.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell
                              sx={{
                                pl: `${row.indent * 16 + 16}px`,
                              }}
                            >
                              {row.name}
                            </TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>
                              {row.cost ? formatCurrency(row.cost) : ''}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Collapse>
              )}

              {!loading && error?.message && (
                <Box sx={{ padding: '10px' }}>
                  {error.message.split('\n').map((error, index) => (
                    <Typography key={index} color="error" variant="h6">
                      {error}
                    </Typography>
                  ))}
                  <Typography color="error" variant="caption">
                    If you don't believe you should be getting these errors and
                    you are unsure how to resolve them, please click the provide
                    feedback below for assistance.
                  </Typography>
                </Box>
              )}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
export default PsaBreakdownSummary;

import { gql } from '@apollo/client';

export const CREATE_LEAD_MUTATION = gql`
  mutation createLead($createLeadParams: LeadInput!) {
    createLead(params: $createLeadParams) {
      success
      id
    }
  }
`;

export default CREATE_LEAD_MUTATION;

import { getFirebaseApp } from './app-firebase';
import {
  getRemoteConfig,
  fetchAndActivate,
  getAll,
  Value,
} from 'firebase/remote-config';

export async function getFeatureFlags(): Promise<Record<string, boolean>> {
  const app = getFirebaseApp();
  const remoteConfigs = getRemoteConfig(app);
  remoteConfigs.settings = {
    fetchTimeoutMillis: 60000,
    minimumFetchIntervalMillis: 1,
  };

  remoteConfigs.defaultConfig = {
    feature_flag_agiloft: false,
  };
  await fetchAndActivate(remoteConfigs);
  const allConfigs = getAll(remoteConfigs);

  const featureFlags = {};
  Object.keys(remoteConfigs.defaultConfig).forEach((key) => {
    featureFlags[key] = allConfigs[key].asBoolean();
  });
  return featureFlags;
}

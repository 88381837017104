import { gql } from '@apollo/client';

export const GET_PROJECT_ITEMS_QUERY = gql`
  query GetProjectItems($projectId: ID!, $offset: Int, $limit: Int) {
    getProjectItems(projectId: $projectId, offset: $offset, limit: $limit) {
      vendor
      type
      terms
      quantity
      projectId
      price
      name
      mrrPrice
      mrrCost
      includeInSow
      includeInProposal
      includeInProjectTotal
      id
      description
      createdOn
      createdBy
      cost
      billingyCycle
      ciscoEA
      hwsw
      numberOfPhoneSeatsAgents
      primaryBOMVendor
      primaryTech
      product
      servicesTotal
      solution
      solutionType
      priceTotal
      costTotal
    }
  }
`;

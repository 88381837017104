import { gql } from '@apollo/client';

export const GET_ESTIMATOR_TASK_GROUPS_BY_PROJECT_ITEM_ID = gql`
  query GetEstimatorTaskGroupsByProjectItemId($projectItemId: ID!) {
    getEstimatorTaskGroupsByProjectItemId(projectItemId: $projectItemId) {
      id
      name
      order
      outcomeIncluded
      executiveSummaryOverview
      executiveSummaryOverviewHelp
      executiveSummaryDrivers
      executiveSummaryDriversHelp
      businessOutcome
      businessOutcomeHelp
      technicalOutcome
      technicalOutcomeHelp
      outcomeProjectScopePhases
      outcomeProjectScopePhasesHelp
      agiloftServiceCategory
      agiloftScopeType
      executiveSummaryOverviewIncluded
      executiveSummaryDriversIncluded
      businessOutcomeIncluded
      technicalOutcomeIncluded
      outcomeProjectScopeIncluded
      outcomeProjectScopePhasesIncluded
    }
  }
`;

import { useMutation } from '@apollo/client';
import { DefaultMutationResponse } from '@cdw-selline/common/types';
import {
  GET_PROJECT_QUERY,
  UPDATE_PROJECT_SALESFORCE_DATA_MUTATION,
  CLEAR_PROJECT_SALESFORCE_DATA_MUTATION
} from '@cdw-selline/ui/queries';

import { useApolloErrors } from './useApolloErrors';

export const useProjectSalesforceData = () => {
  const { handleErrorResponse } = useApolloErrors();
  const [
    updateProjectSalesforceData,
    {
      loading: updateProjectSalesforceDataLoading,
    },
  ] = useMutation<DefaultMutationResponse>(UPDATE_PROJECT_SALESFORCE_DATA_MUTATION, {
    refetchQueries: [
      GET_PROJECT_QUERY,
    ],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to update project salesforce data'),
  });

  const [
    clearProjectSalesforceData,
    {
      loading: clearProjectSalesforceDataLoading,
    },
  ] = useMutation<DefaultMutationResponse>(CLEAR_PROJECT_SALESFORCE_DATA_MUTATION, {
    refetchQueries: [
      GET_PROJECT_QUERY,
    ],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to clear project salesforce data'),
  });

  return {
    updateProjectSalesforceData,
    updateProjectSalesforceDataLoading,
    clearProjectSalesforceData,
    clearProjectSalesforceDataLoading
  };
};

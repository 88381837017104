import { gql } from '@apollo/client';

export const GET_HEALTH_CHECK_QUERY = gql`
  query ExampleQuery {
    getUser {
      aud
      name
      email
      picture
      username
      user_id
      sub
      iss
      iat
      exp
      auth_time
    }
    you {
      id
    }
  }
`;

export default GET_HEALTH_CHECK_QUERY;

export const estimatorStrings = {
  FETCH_PRACTICE_ERROR: 'Could not fetch practices',
  FETCH_SITE_ERROR: 'Could not fetch sites',
  NEW_SITE_SELECT_PRACTICE: 'Please select a practice to add a new site',
  NEW_SITE_NO_TASK_GROUPS:
    'The selected practice has no task groups, choose another to add a site',
  TASK_GROUPS_SELECT_PRACTICE:
    'Select practices and add a site to view available task groups',
  TASK_COLUMNS: [
    'Latest?',
    'Qty/Selection',
    'Notes',
    'Task',
    'Units',
    'Primary Role',
    'Secondary Role',
    "Overtime/Add'l GP",
    'Hours/MSRP',
    'Total',
  ],
  ADD_NOTE_ERROR: 'Error adding note',
  FETCH_NOTE_ERROR: 'Error fetching task notes',
  NO_NOTES: 'No notes for this task',
  REMOVE_SITE_ERROR: 'Error removing site',
  UP_TO_DATE: 'Task version up to date',
  OUT_OF_DATE: 'Task version out of date',
  TASK_WAS_UPDATED: 'Task has been updated in Estimator',
  UPDATE_TASK_ERROR: 'Error updating task',
  UPDATES_AVAILABLE: 'One or more task has updates available',
  APPLY_CHANGES_PROMPT: 'Apply these changes?',
  SUMMARY_CHANGES_PROMPT: 'Click Update All Tasks button for the estimator site in order to refresh Summary Task version',
  CUSTOM_CATEGORY: 'Other',
};

export const titleStrings = {
  ADD_SITE: 'Add New Site to Project',
  TASKS: 'Tasks',
  UNCATEGORIZED: 'Uncategorized',
  SERVICE_ESTIMATE: 'Service Estimate',
  TASK_HOURS: 'Task Hours',
  PM_HOURS: 'PM Hours',
  TASK_NOTES: 'Task Notes',
  TASK_PROPOSAL_DESCRIPTION: 'Task Proposal Description',
  TASK_VERSION: 'Task(s) Version',
  COPY_OPPORTUNITY: 'Copy Opportunity',
};

export const labelStrings = {
  CLOSE: 'Close',
  CANCEL: 'Cancel',
  SUBMIT: 'Submit',
  SITE_NAME: 'Site Name',
  SITE_ADDRESS: 'Site Address',
  SITE_FLOOR: 'Site Floor',
  SITE: 'Site',
  SEARCH: 'Search',
  SEARCH_TASK_GROUPS: 'Search task groups',
  ADD_TASK_GROUP: 'Add all tasks in this group',
  VIEW_CATEGORIES: 'View all categories in this group',
  ADD_TASK_CATEGORY: 'Add all tasks in this category',
  NOTE_TYPE: 'Note Type',
  NOTE: 'Note',
  TASK_PROPOSAL_DESCRIPTION: 'Task Proposal Description',
  ADD_NOTE: 'Add Note',
  REMOVE: 'Remove',
  APPLY_CHANGES: 'Apply Changes',
  EXIT_NO_CHANGES: 'Exit Without Changes',
  REMOVE_SITE: 'Remove Site',
  COPY_SITE: 'Copy Site',
  UPDATE_ALL_TASKS: 'Update All Tasks',
};

export const NOTE_TYPES = [
  'General',
  'BA Reviewer',
  'BA Note',
  'Peer Reviewer',
];

export const FIXED_FEE = ['Name', 'Percent', 'Cost'];

export const ESTIMATOR_OUTPUT_FIELDS = {
  BILLING_TYPE: 'billingType',
  RISK_MITIGATION: 'riskMitigation',
  OVERTIME_PERCENT: 'overtimePercent',
  PM_PERCENT: 'pmPercent',
  PM_ROLE: 'pmRole',
  T_AND_E_TOTAL: 'tAndETotal',
};

export const BILLING_TYPES = {
  FIXED_FEE: 'Fixed Fee',
  TIME_MATERIALS: 'Time & Materials',
};

export const MANAGED_SERVICES_READINESS_ASSESSMENT = `CDW will conduct an audit to become familiar with your current design and architecture, and to ensure that all of your systems are functioning as designed and as expected. CDW will also ensure that all of your systems are compatible with CDW’s Managed Services operationalbest practices. Any gaps or discrepancies will be discussed with you and a mutually acceptable remediation plan will be determined. Remediation will be performed under a separate Statement of Work.`;

export const MANAGED_SERVICES_ONBOARDING = `Specific setup tasks include installation of the CDW monitoring appliance in your network, remote connectivity setup, tuning of monitoring profiles, enrollment of servers and devices into the appropriate level(s) of service, population of the CMDB in CDW's ServiceNow system, documentation, change and incident management configuration, web portal training, and other aspects to setup our service. CDW will dedicate a professional project manager and appropriate engineers for your Managed Services implementation engagement.`;

export const SOW_STATES = {
  INITIAL: "INITIAL",
  NON_INITIAL: "NON_INITIAL",
  NONE: "NONE",
}
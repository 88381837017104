import { gql } from '@apollo/client';

export const REQUEST_ESTIMATOR_BID_ASSURANCE_MUTATION = gql`
  mutation RequestEstimatorBidAssurance($projectItemId: String!) {
    requestEstimatorBidAssurance(projectItemId: $projectItemId) {
      success
    }
  }
`;

export const CANCEL_ESTIMATOR_BID_ASSURANCE_MUTATION = gql`
  mutation CancelEstimatorBidAssurance($projectItemId: String!) {
    cancelEstimatorBidAssurance(projectItemId: $projectItemId) {
      success
    }
  }
`;

export const DENY_ESTIMATOR_BID_ASSURANCE_MUTATION = gql`
  mutation DenyEstimatorBidAssurance($projectItemId: String!) {
    denyEstimatorBidAssurance(projectItemId: $projectItemId) {
      success
    }
  }
`;

export const ESCALATE_ESTIMATOR_BID_ASSURANCE_MUTATION = gql`
  mutation EscalateEstimatorBidAssurance($projectItemId: String!) {
    escalateEstimatorBidAssurance(projectItemId: $projectItemId) {
      success
    }
  }
`;

import { gql } from '@apollo/client';

export const GET_CDW_RATES_QUERY = gql`
  query getCdwRatesQuery(
    $limit: Int
    $offset: Int
    $sort: MongoFilter
    $filters: MongoFilter
  ) {
    getCdwRates(
      limit: $limit
      offset: $offset
      sort: $sort
      filters: $filters
    ) {
      count
      rates {
        id
        name
        cost
        agiloftRole
        createdAt
        practice
        practiceName
      }
    }
  }
`;

import React, { useState, SetStateAction, Dispatch, useEffect, useRef } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { Grid, IconButton, TableCell, TableRow, TextField } from '@mui/material';
import { FixedFeeMilestone } from '@cdw-selline/common/types';
import { formatCurrency } from '@cdw-selline/ui/helpers';

type ExtendedFixedFeeMilestone = FixedFeeMilestone & {
  isNewRowAdded: boolean;
}
export interface FixedFeeMilestoneLineItemProps {
  row: ExtendedFixedFeeMilestone;
  handleSave: (id, name, percent) => void;
  handleDeleteDialogConfirmOpen: () => void;
  setDeleteId: Dispatch<SetStateAction<string>>;
}

export function FixedFeeMilestoneLineItem({
  row,
  handleSave,
  handleDeleteDialogConfirmOpen,
  setDeleteId,
}: FixedFeeMilestoneLineItemProps) {
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState(null);
  const [percent, setPercent] = useState(null);
  const [autoFocusName, setAutoFocusName] = useState(false);
  const [autoFocusPercent, setAutoFocusPercent] = useState(false);
  const rowRef = useRef<HTMLTableRowElement>(null);

  const handleSaveClick = () => {
    handleSave(row.id, name, percent);
    handleCancelClick();
  };

  const handleCancelClick = () => {
    setAutoFocusName(false);
    setAutoFocusPercent(false);
    setEdit(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (rowRef.current && !rowRef.current.contains(event.target as Node)) {
        handleCancelClick();
      }
    };

    if (edit) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [edit]);

  const handleTextFocus = (e) => {
    e.target.select();
  }

  useEffect(() => {
    if (row.isNewRowAdded)
    {
      setEdit(true);
      setAutoFocusName(true);
      row.isNewRowAdded = false;
    }
    
  }, [row]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSaveClick();
    }
  };

  const handleDoubleClick = (field) => {
    if (field == 'name')
    {
      setAutoFocusName(true);
    }
    else
    {
      setAutoFocusPercent(true);
    }
    handleEditClick();
  }

  const handleEditClick = () => {
    setName(row.name);
    setPercent(row.percent);
    setEdit(true);
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    setDeleteId(row.id);
    handleDeleteDialogConfirmOpen();
  };

  return (
    <TableRow hover key={row.name} ref={rowRef} >
      <TableCell  onDoubleClick={() => handleDoubleClick('name')}>
        {edit ? (
          <TextField
            id="fixed-fee-name"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoFocus = {autoFocusName}
            onFocus={handleTextFocus}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <>
            {row.name}
          </>
        )}
      </TableCell>
      <TableCell align="right"  onDoubleClick={() => handleDoubleClick('percent')}>
        {edit ? (
          <TextField
            id="fixed-fee-percent"
            type="number"
            value={percent}
            onChange={(e) => setPercent(e.target.value)}
            onWheel={(event) => (event.target as HTMLInputElement).blur()}
            autoFocus={autoFocusPercent}
            onFocus={handleTextFocus}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <>
            {row.percent}
          </>
        )}
      </TableCell>
      <TableCell align="right">{formatCurrency(row.cost)}</TableCell>
      <TableCell align="right" sx={{ width: '100px' }}>

        {(edit) ?
          (
            <div>
              <IconButton title='Click to Save' size="small" onClick={handleSaveClick}>
                <SaveIcon fontSize="small" />
              </IconButton>
              <IconButton title='Click to Cancel' size="small" onClick={handleCancelClick}>
                <CancelIcon fontSize="small" />
              </IconButton>
            </div>
          ) :
          (
            <div>
              <IconButton title="Click to Edit" onClick={handleEditClick}>
                <EditIcon fontSize="small" />
              </IconButton>

              <IconButton title="Click to Delete" onClick={handleDeleteClick}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </div>
          )
        }
      </TableCell>
    </TableRow>
  );
}

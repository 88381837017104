import { gql } from '@apollo/client';

export const REMOVE_ADMIN_TASK_GROUP_MUTATION = gql`
  mutation removeAdminTaskGroupMutation($id: String) {
    removeTaskGroup(id: $id) {
      success
    }
  }
`;

// VARIABLES
// { practiceId: 'uuid' }

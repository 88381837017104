import { gql } from '@apollo/client';

export const GET_SCOPE_GROUPS_QUERY = gql`
  query GetScopeGroups($filters: MongoFilter, $limit: Int, $offset: Int, $sort: MongoFilter) {
    getScopeGroups {
      count(filters: $filters)
      scopeGroups(filters: $filters, limit: $limit, offset: $offset, sort: $sort) {
        id
        legacyId
        name
        practiceId
        createdAt
      }
    }
  }
`;

import {
  usePractices,
  useCDWRates,
  useAgiloftRoles,
} from '@cdw-selline/ui/hooks';
import { Practice, Role } from '@cdw-selline/common/types';
import React, { useEffect, useState } from 'react';
import DialogModal from '../../dialog-modal/DialogModal';
import { Grid, Typography, Box } from '@mui/material';
import {
  FormAutocomplete,
  FormText,
  FormCheckbox,
  getActionButtons,
  FormTextControlled,
} from '../../formHelperFunctions';
import { ApolloError } from '@apollo/client';
import { AdminAccessTable } from '../../admin-access-table/AdminAccessTable';
import { cloneDeep } from 'lodash';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';

export interface RoleFormProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (role: Role) => void;
  role?: Role | null;
  roleLoading?: boolean;
  roleError?: ApolloError;
}

const newRoleDefaults: Role = {
  id: '',
  name: '',
  practice: '',
  projectManager: false,
  cdwRateId: '',
  rate: '',
  owner: [],
  coOwners: [],
  testers: [],
};

export function RoleForm({
  isOpen,
  handleClose,
  handleSubmit,
  role,
  roleLoading,
  roleError,
}: RoleFormProps) {
  const [roleFormData, setRoleFormData] = useState(role ?? newRoleDefaults);
  const [cost, setCost] = useState(0);
  const [error, setError] = useState(false);
  const [rowsOwner, setRowsOwner] = useState([]);
  const [rowsCoOwners, setRowsCoOwners] = useState([]);
  const [rowsTesters, setRowsTesters] = useState([]);
  const alertState = useAlertsState();
  const [currentPractice, setCurrentPractice] = useState({} as Practice);

  const mapAdminUsers = (users)=> {
    return users && users.map((p, i) => ({
      id: i,
      email: p.email,
      name: p.email,
      roleId: role.id,
    })) || [];
  };

  useEffect(() => {
    if (role && role.id) {
      setRoleFormData(role);
      setRowsOwner(mapAdminUsers(role.owner));
      setRowsCoOwners(mapAdminUsers(role.coOwners));
      setRowsTesters(mapAdminUsers(role.testers));
    }
  }, [role]);

  const handleFormClose = () => {
    setRoleFormData(newRoleDefaults);
    setError(false);
    handleClose();
  };

  const handleSaveClick = () => {
    if (
      roleFormData.name &&
      roleFormData.practice &&
      roleFormData.cdwRateId &&
      roleFormData.rate
    ) {
      handleSubmit(roleFormData);
      setError(false);
      handleClose();
    } else {
      setError(true);
    }
  };

  const {
    data: agiloftRoles,
    loading: agiloftRoleLoading,
    error: agiloftRolesError,
  } = useAgiloftRoles();

  const agiloftRolesOptions =
    agiloftRoles.map((role) => {
      return {
        id: role.id,
        label: role.name,
      };
    }) ?? [];

  const {
    data: practices,
    loading: practiceLoading,
    error: practiceError,
  } = usePractices({});

  const practiceOptions =
    practices.practices.map((practice) => {
      return {
        id: practice.id,
        label: practice.name,
      };
    }) ?? [];

  useEffect(() => {
    if (roleFormData.practice) {
      const selectedPractice = practices.practices.find(
        (practice) => practice.id === roleFormData.practice
      );
      setCurrentPractice(selectedPractice);
    }
  }, [practices.practices, roleFormData.practice]);

  const filters = currentPractice?.salesForceId ? {
     $or: [
      {
        'relatedPractices.id': {
          $in: [currentPractice?.salesForceId ?? ''],
        },
      },
      {
        practice: currentPractice?.salesForceId ?? '',
      },
    ],
  } : {};
  const {
    data: rates,
    loading: ratesLoading,
    error: ratesError,
  } = useCDWRates({
    filters: filters,
    sort: { name: 'asc' },
  });

  const cdwRatesOptions =
    rates.rates.map((rate) => {
      if (rate.id === roleFormData.cdwRateId && !cost) {
        setCost(rate.cost);
      }
      return {
        id: rate.id,
        label: `${rate.name}${rate.practiceName ?  ' - (' + rate.practiceName + ')' : ''}`,
        cost: rate.cost,
      };
    }) ?? [];

  useEffect(() => {
    setCost(
      cdwRatesOptions.find((r) => r.id === roleFormData.cdwRateId)?.cost ?? 0
    );
  }, [roleFormData.cdwRateId]);

  if (roleLoading || practiceLoading || ratesLoading)
    return <Typography>Loading form</Typography>;

  if (roleError || practiceError || ratesError)
    return <Typography>Error loading form</Typography>;

  const getTitle = () => {
    return (roleFormData.id && `Edit Role`) || 'Add Role';
  };

  const handleRoleTeamSave = (params, usersType: string) => {
    if (roleFormData[usersType]?.some((item) => item.email === params.email)) {
      alertState.setAlert({
        type: ALERT_TYPE.MODAL,
        severity: ALERT_SEVERITY.SUCCESS,
        message: 'This user already exists!',
      });
      return;
    }
    const roleTeam = cloneDeep(roleFormData[usersType] || []);
    roleTeam.unshift(params);

    setRoleFormData((s) => ({
      ...s,
      [usersType]: roleTeam,
    }));

    switch (usersType) {
      case 'owner':
        setRowsOwner(mapAdminUsers(roleTeam));
        break;
      case 'coOwners':
        setRowsCoOwners(mapAdminUsers(roleTeam));
        break;
      case 'testers':
        setRowsTesters(mapAdminUsers(roleTeam));
        break;
    }
  };

  const handleRoleTeamDelete = (userEmail: string, usersType: string) => {
    const roleTeam = cloneDeep(roleFormData[usersType] || []);
    const updatedRoleTeam = roleTeam.filter((t) => t.email !== userEmail);

    setRoleFormData((s) => ({
      ...s,
      [usersType]: updatedRoleTeam,
    }));
    switch (usersType) {
      case 'owner':
        setRowsOwner(mapAdminUsers(updatedRoleTeam));
        break;
      case 'coOwners':
        setRowsCoOwners(mapAdminUsers(updatedRoleTeam));
        break;
      case 'testers':
        setRowsTesters(mapAdminUsers(updatedRoleTeam));
        break;
    }
  };

  return (
    <DialogModal
      fullWidth={true}
      isOpen={isOpen}
      handleClose={handleClose}
      title={getTitle()}
      action={getActionButtons(handleFormClose, handleSaveClick)}
      maxWidthProp={'lg'}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="row"
        spacing={1}
        mt={1}
        sx={{ height: '50vh' }}
        data-testid="role-grid"
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="column"
          spacing={1}
          sx={{ width: '100%' }}
        >
          {error && (
            <Box sx={{ padding: '15px' }}>
              <Typography color="error" variant="h6">
                Please add all required field values
              </Typography>
            </Box>
          )}
          <FormText
            name="name"
            label="Role Name"
            required={true}
            setFormData={setRoleFormData}
            formData={roleFormData}
            error={error && !roleFormData.name}
          />
          <FormAutocomplete
            name="practice"
            label="Practice *"
            options={practiceOptions}
            setFormData={setRoleFormData}
            formData={roleFormData}
            error={error && !roleFormData.practice}
          />
          <AdminAccessTable
            {...{
              title: 'Role Owner',
              rows: rowsOwner,
              handleSave: handleRoleTeamSave,
              handleDelete: handleRoleTeamDelete,
              solo: true,
              usersType: 'owner',
            }}
          />
          <AdminAccessTable
            {...{
              title: 'Role Co-Owners',
              rows: rowsCoOwners,
              handleSave: handleRoleTeamSave,
              handleDelete: handleRoleTeamDelete,
              usersType: 'coOwners',
            }}
          />
          <AdminAccessTable
            {...{
              title: 'Role Testers',
              rows: rowsTesters,
              handleSave: handleRoleTeamSave,
              handleDelete: handleRoleTeamDelete,
              usersType: 'testers',
            }}
          />
          <FormCheckbox
            name="projectManager"
            label="Project Manager"
            setFormData={setRoleFormData}
            formData={roleFormData}
          />
          <FormAutocomplete
            name="cdwRateId"
            label="CDW Rate ID"
            options={cdwRatesOptions}
            setFormData={setRoleFormData}
            formData={roleFormData}
            error={error && !roleFormData.cdwRateId}
          />
          <FormTextControlled
            name="cost"
            disabled={true}
            label="Cost"
            setFormData={setCost}
            formData={{ cost: Number(cost).toFixed(2) }}
          />
          <FormText
            name="rate"
            required={true}
            label="Rate"
            type="number"
            setFormData={setRoleFormData}
            formData={roleFormData}
            decimal={2}
            error={error && !roleFormData.rate}
          />
          <FormAutocomplete
            name="agiloftRole"
            label="Agiloft Role"
            options={agiloftRolesOptions}
            setFormData={setRoleFormData}
            formData={roleFormData}
          />
        </Grid>
      </Grid>
    </DialogModal>
  );
}

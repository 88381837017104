import { Task, TaskNote } from '@cdw-selline/common/types';
import React, { FormEvent, useEffect, useState } from 'react';
import DialogModal from '../../../dialog-modal/DialogModal';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { titleStrings, labelStrings } from '@cdw-selline/ui/constants';
import { ApolloError } from '@apollo/client';
import { useAddRemoveCustomTask } from '@cdw-selline/ui/hooks';
import { getActionButtons } from '../../../formHelperFunctions';

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  TextField,
} from '@mui/material';

export interface TaskProposalDescriptionProps {
  task: Task;
  isOpen: boolean;
  handleClose: () => void;
  handleTaskSave: (task: Task) => void;
}

export function TaskProposalDescriptionDialog({
  isOpen,
  handleClose,
  task,
}: TaskProposalDescriptionProps) {
  const [proposalDescription, setProposalDescription] = useState('');
  const [fullSiteLoad, setFullSiteLoad] = useState(true);

  const { handleSaveNoAdminTask } = useAddRemoveCustomTask(setFullSiteLoad);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProposalDescription(event.target.value);
  };

  const handleSaveClick = async (e: FormEvent) => {
    handleSaveNoAdminTask({ id: task.id, proposalDescription });
    setProposalDescription('');
    handleClose();
  };

  const handleCloseDialog = () => {
    if (task.proposalDescription) {
      setProposalDescription(task.proposalDescription);
    } else {
      setProposalDescription('');
    }

    handleClose();
  };

  useEffect(() => {
    if (task.proposalDescription) {
      setProposalDescription(task.proposalDescription);
    }
  }, [task.proposalDescription]);

  return (
    <DialogModal
      {...{ isOpen, handleClose }}
      title={titleStrings.TASK_PROPOSAL_DESCRIPTION}
      action={getActionButtons(handleCloseDialog, handleSaveClick)}
      maxWidthProp={'lg'}
      fullWidth={true}
    >
      <Grid container>
        <form aria-label="new note form" style={{ width: '100%' }}>
          <Grid item xs={12} sx={{ margin: '1em 0' }}>
            <FormControl
              fullWidth
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <InputLabel shrink>
                {labelStrings.TASK_PROPOSAL_DESCRIPTION}
              </InputLabel>
              <TextField
                multiline
                rows={3}
                InputLabelProps={{ shrink: true }}
                label={labelStrings.TASK_PROPOSAL_DESCRIPTION}
                value={proposalDescription}
                onChange={handleInputChange}
                aria-label="task proposal description"
                fullWidth
                sx={{ width: '100%' }}
              />
            </FormControl>
          </Grid>
        </form>
      </Grid>
    </DialogModal>
  );
}

export default TaskProposalDescriptionDialog;

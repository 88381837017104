import {
  Button,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import React from 'react';
import DialogModal from '../dialog-modal/DialogModal';
import { TaskGroup } from '@cdw-selline/common/types';

/* eslint-disable-next-line */
export interface ProjectSowDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  generateSow: (e) => void;
  taskGroups: TaskGroup[];
  pickTaskGroupSow: (e) => void;
  sowGeneration: boolean;
  taskGroupId?: string;
  outcomeSow?: boolean;
}

export function ProjectSowDialog({
  isOpen,
  handleClose,
  generateSow,
  taskGroups,
  pickTaskGroupSow,
  sowGeneration,
  taskGroupId,
  outcomeSow,
}: ProjectSowDialogProps) {
  const taskGroupOptions = taskGroups.map((taskGroup) => {
    if (outcomeSow && !taskGroup.outcomeIncluded) {
      return false;
    }

    return (
      <MenuItem key={taskGroup.id} value={taskGroup.id}>
        {taskGroup.name}
      </MenuItem>
    );
  });

  return (
    <DialogModal
      {...{
        isOpen,
        handleClose,
        maxWidth: 'sm',
      }}
      title="Pick Task Group"
    >
      <form onSubmit={generateSow}>
        <FormControl sx={{ marginTop: '10px', width: '100%' }}>
          <InputLabel>Select Template:</InputLabel>
          <Select
            label="Select Template:"
            value={taskGroupId}
            sx={{ width: '100%' }}
            onChange={pickTaskGroupSow}
          >
            {taskGroupOptions}
          </Select>
        </FormControl>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '1em',
          }}
        >
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            aria-label="submit site info"
          >
            {outcomeSow
              ? 'Generate Outcome Based Sow'
              : sowGeneration
              ? 'Generate Sow'
              : 'Compare Sow'}
          </LoadingButton>
        </DialogActions>
      </form>
    </DialogModal>
  );
}

export default ProjectSowDialog;

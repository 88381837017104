import { gql } from '@apollo/client';

export const ADD_PROJECT_ITEM_QUOTE_MUTATION = gql`
  mutation Mutation($params: ProjectItemQuoteInput!) {
    addProjectItemQuote(params: $params) {
      id
      success
    }
  }
`;

/*
  VARIABLES:
    {
        "params": {
            "projectItemId": "12345",
            "quoteLines": [],
            "systems": [],
            "globalQuoteHardwareSettings": null,
            "globalQuoteServiceSettings": null,
            "globalQuoteSettings": null,
            "globalQuoteSupportSettings": null,
            "projectProductsId": null
        }
    }
*/

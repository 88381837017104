import { gql } from '@apollo/client';

export const GET_COLLECTIONS_QUERY = gql`
  query getCollectionsQuery {
    getCollections {
      count
      lastModified
      collections {
        name
        count
        lastModified
      }
    }
  }
`;

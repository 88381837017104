import { gql } from '@apollo/client';

export const GET_INVESTMENT_SUMMARY_PROJECT_QUERY = gql`
  query getInvestmentSummaryProjectQuery($id: ID!) {
    getInvestmentSummaryByProjectId(projectId: $id) {
      hwsw
      servicesTotal
      managedServices
      tAndETotal
      total
    }
  }
`;

import { gql } from '@apollo/client';
export const GET_EMAIL_TEMPLATES = gql`
  query GetEmailTemplates($limit: Int
    $offset: Int
    $filters: MongoFilter) {
    getEmailTemplates(limit: $limit
        offset: $offset
        filters: $filters) {
      emailTemplates {
        name
        id
        subject
        body
      }
    }
  }
`;
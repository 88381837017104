import { Practice, TaskGroup } from '@cdw-selline/common/types';
import { ChangeEvent, useEffect, useState } from 'react';

import { useTaskGroupsByPractice } from '..';

export const useDrawerSearch = (practices: Practice[]) => {
  const [searchInput, setSearchInput] = useState('');
  //TODO Convert to only pull task group names and not the whole object
  const { taskGroupsByPractice, error: taskGroupsError } =
    useTaskGroupsByPractice(
      practices,
      searchInput ? { $text: { $search: searchInput } } : {}
    );
  const [displayedResults, setDisplayedResults] =
    useState<TaskGroup[]>(taskGroupsByPractice);

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  useEffect(() => {
    if (displayedResults !== taskGroupsByPractice) {
      if (!searchInput) {
        setDisplayedResults(taskGroupsByPractice);
      } else {
        const newResults = taskGroupsByPractice.filter((group) => {
          return (
            group.name
              .toLowerCase()
              .includes(searchInput.trim()?.toLowerCase()) ||
            group.taskGroupCategories.some((category) => {
              category.id
                ?.toLowerCase()
                .includes(searchInput.trim()?.toLowerCase());
            })
          );
        });
        setDisplayedResults(newResults);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskGroupsByPractice?.length === displayedResults.length]);

  return {
    taskGroupsLoading: false, // to prevent unnecessary rerender
    taskGroupsError,
    displayedResults,
    handleSearchInput,
    setDisplayedResults,
  };
};

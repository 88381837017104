import { gql } from '@apollo/client';

export const GET_ADMIN_SOW_SECTIONS_SCHEMA_QUERY = gql`
  query getSowSectionsSchemaQuery($getSchemaId: ID!) {
    getSchema(id: $getSchemaId) {
      uiSchema: uiSchemaV2
      schema {
        type
        title
        required
        description
        properties {
          ... on SowSectionProperties {
            name {
              type
              title
            }
            title {
              type
              title
            }
            help
            highLevel
            scope
            customerResponsibilities
            customerResponsibilitiesGlobal {
              type
              title
            }
            assumptionsGlobal {
              type
              title
            }
            outOfScopeGlobal {
              type
              title
            }
            assumptions
            outOfScope
          }
        }
      }
    }
  }
`;

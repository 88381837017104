/* eslint-disable @nx/enforce-module-boundaries */
import {
  Box,
  Card,
  CardHeader,
  Container,
  Divider,
  Grid,
  Typography,
  Button,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  CircularProgress,
} from '@mui/material';
import { DialogConfirm } from '../../dialog-confirm/DialogConfirm';
import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useOpenState } from '@cdw-selline/ui/hooks';
import { FixedFeeMilestoneLineItem } from './FixedFeeMilestoneLineItem';
import omitDeep from 'omit-deep-lodash';
import { cloneDeep } from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import { ProjectItemEstimators } from '@cdw-selline/common/types';
import { useServiceSummary } from '@cdw-selline/ui/hooks';
import { formatCurrency } from '@cdw-selline/ui/helpers';
import { BILLING_TYPES } from '@cdw-selline/ui/constants';

export interface FixedFeeMilestonesProps {
  itemData: ProjectItemEstimators;
  handleUpdate: (e, fixed) => void;
}

export const FixedFeeMilestones: React.FC<FixedFeeMilestonesProps> = ({
  itemData,
  handleUpdate,
}) => {
  const theme = useTheme();
  const { serviceSummary, error, loading } = useServiceSummary();

  const [fixedFeeTable, setFixedFeeTable] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  let serviceSummaryTotal;
  serviceSummary.roles.forEach((r) => {
    if (
      ['total', 'risk', 'travelTimeTotal', 'customServiceTotal'].includes(r.id)
    ) {
      serviceSummaryTotal = r.total;
    }
  });

  const {
    isOpen: deleteDialogConfirmOpen,
    handleClose: handleDeleteDialogConfirmClose,
    handleOpen: handleDeleteDialogConfirmOpen,
  } = useOpenState();

  const getRowWithId = (data) => {
    const rowAddId = cloneDeep(data);
    if (rowAddId?.length > 0) {
      rowAddId.map((f, i) => (f.id = i));
    }

    getPercentTotal(rowAddId, false);
    return rowAddId;
  };

  const getPercentTotal = (data, initialCall = false) => {
    let total = 0;

    data.forEach((d) => (total += Number(d.percent)));
    if (!initialCall) setPercentTotal(Boolean(total === 100));
    return total;
  };

  const rowDefault = [
    {
      id: '1',
      name: 'Signed Sow',
      percent: 25,
    },
    {
      id: '2',
      name: 'Completion of Design',
      percent: 25,
    },
    {
      id: '3',
      name: 'Completion of Implementation',
      percent: 50,
    },
  ];

  const [percentTotal, setPercentTotal] = useState(
    itemData?.fixedFeeMilestones.length
      ? Boolean(getPercentTotal(itemData?.fixedFeeMilestones, true) === 100)
      : true
  );
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(
      getRowWithId(
        itemData?.fixedFeeMilestones.length
          ? itemData?.fixedFeeMilestones
          : rowDefault
      )
    );
    if (itemData?.fixedFeeMilestones.length) {
      setFixedFeeTable(true);
    }

    if (
      itemData?.billingType === BILLING_TYPES.FIXED_FEE &&
      !itemData?.fixedFeeMilestones.length
    ) {
      buildFixedFeeTable();
    }
  }, [serviceSummaryTotal, itemData]);

  const handleDialogDelete = (event) => {
    event.stopPropagation();
    const fixedFeeTable = rows.filter((r) => r.id !== deleteId);
    setDeleteId(null);
    setRows(getRowWithId(fixedFeeTable));
    handleUpdate(
      'fixedFeeMilestones',
      omitDeep(fixedFeeTable, 'id', 'cost', '__typename')
    );
    if (!fixedFeeTable.length) {
      setFixedFeeTable(false);
    }
    handleDeleteDialogConfirmClose();
  };

  const buildFixedFeeTable = () => {
    if (!rows.length) {
      setRows(getRowWithId(rowDefault));
    }
    setFixedFeeTable(true);
    handleUpdate(
      'fixedFeeMilestones',
      omitDeep(rows, 'id', 'cost', '__typename')
    );
  };

  const handleSave = (id, name, percent) => {
    const fixedFeeTable = rows.map((r) => {
      if (r.id === id) {
        r.name = name;
        r.percent = Number(percent);
        return r;
      }
      return r;
    });
    setRows(getRowWithId(fixedFeeTable));
    handleUpdate(
      'fixedFeeMilestones',
      omitDeep(fixedFeeTable, 'id', 'cost', '__typename', 'isNewRowAdded')
    );
  };

  const addMilestone = () => {
    const max = rows.length ? Math.max(...rows.map((r) => r.id)) : 0;
    setRows([
      ...rows,
      { id: max + 1, name: '', percent: 0, cost: 0, isNewRowAdded: true },
    ]);
  };

  const getFixedFeeTotal = () => {
    const total = rows.reduce((total, currentRow) => {
      total += currentRow.cost;

      return total;
    }, 0);

    return formatCurrency(total);
  };

  return (
    <Container maxWidth={false}>
      {loading && <CircularProgress sx={{ float: 'right' }} />}

      {!loading && !error && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                action={
                  fixedFeeTable ? (
                    <Button
                      variant="text"
                      size="small"
                      style={{
                        marginRight: '1em',
                        marginTop: '.5em',
                        color: theme.palette.common.white,
                      }}
                      endIcon={
                        <AddIcon sx={{ color: theme.palette.common.white }} />
                      }
                      onClick={addMilestone}
                      data-testid="add-milestone"
                    >
                      Add Milestone
                    </Button>
                  ) : null
                }
                title="Fixed Fee Milestones"
                sx={{
                  backgroundColor: theme.palette.primary.dark,
                  padding: '5px 15px',
                }}
                titleTypographyProps={{
                  variant: 'h6',
                  color: theme.palette.common.white,
                }}
              />
              <Divider />
              <Box sx={{ minWidth: 400, marginBottom: '40px' }}>
                  {fixedFeeTable && (
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell align="right">Percent</TableCell>
                          <TableCell align="right">Cost</TableCell>
                          <TableCell sx={{ width: '50px' }}></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, i) => (
                          <FixedFeeMilestoneLineItem
                            row={row}
                            handleSave={handleSave}
                            handleDeleteDialogConfirmOpen={
                              handleDeleteDialogConfirmOpen
                            }
                            setDeleteId={setDeleteId}
                            key={`${row.name}-${i}`}
                          />
                        ))}
                        <DialogConfirm
                          title="Delete?"
                          isOpen={deleteDialogConfirmOpen}
                          handleClose={handleDeleteDialogConfirmClose}
                          handleYes={handleDialogDelete}
                        >
                          <Typography>
                            Are you sure you want to delete this item?
                          </Typography>
                        </DialogConfirm>
                        <TableRow
                          sx={{
                            borderTop: `2px solid ${theme.palette.common.black}`,
                          }}
                        >
                          <TableCell>Total</TableCell>
                          <TableCell></TableCell>
                          <TableCell align="right">
                            {getFixedFeeTotal()}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                  {fixedFeeTable && !percentTotal && (
                    <Box sx={{ padding: '10px' }}>
                      <Typography color={theme.palette.error.main}>
                        (Percent total should be 100%)
                      </Typography>
                    </Box>
                  )}
              </Box>
            </Card>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default FixedFeeMilestones;

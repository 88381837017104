import { gql } from '@apollo/client';

export const GET_SALES_REPS_TRENDING_QUERY = gql`
  query GetSalesRepsTrendingQuery {
    getSalesRepsTrending {
      salesReps {
        id
        imageUrl
        updatedAt
        name
      }
      count
    }
  }
`;

export default GET_SALES_REPS_TRENDING_QUERY;

import {
  CollectionsDataTable,
  ProposalTemplateForm,
} from '@cdw-selline/ui/components';
import {
  useAdminProposalTemplates,
  useProposalTemplateById,
} from '@cdw-selline/ui/hooks';
import { Box } from '@mui/material';
import React from 'react';

/* eslint-disable-next-line */
export interface ProposalTemplatesCollectionPageProps {}

export const ProposalTemplatesCollectionPage = (
  props: ProposalTemplatesCollectionPageProps
) => {
  const dataTableProps = useAdminProposalTemplates();

  const {
    data: proposalTemplate,
    loading: proposalTemplateLoading,
    error: proposalTemplateError,
  } = useProposalTemplateById(dataTableProps.editProposalTemplateId);

  const openForm =
    dataTableProps.proposalTemplateFormOpen &&
    ((dataTableProps.editProposalTemplateId && proposalTemplate.id) ||
      !dataTableProps.editProposalTemplateId);

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <CollectionsDataTable
        {...dataTableProps}
        height="calc(100vh - 13em)"
        paginationMode="server"
        onRowClick={(o) => dataTableProps.handleEdit(o.id)}
        parent="ProposalTemplatesCollectionPage"
      />
      {openForm && (
        <ProposalTemplateForm
          proposalTemplate={proposalTemplate}
          isOpen={dataTableProps.proposalTemplateFormOpen}
          handleClose={dataTableProps.handleProposalTemplateFormClose}
          handleSubmit={dataTableProps.handleProposalTemplateFormSave}
          proposalTemplateId={dataTableProps.editProposalTemplateId}
        />
      )}
    </Box>
  );
};

export default ProposalTemplatesCollectionPage;

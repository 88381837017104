import React, { useState } from 'react';
import { getActionButtons, FormSelect } from '../formHelperFunctions';
import {
  Grid,
  CircularProgress,
  Box,
  Typography,
  MenuItem,
} from '@mui/material';
import DialogModal from '../dialog-modal/DialogModal';
import { ApolloError } from '@apollo/client';
import { Opportunity } from '@cdw-selline/common/types';
import { UsersAutocomplete } from '../users-autocomplete/UsersAutocomplete';
import { opportunityTeamTypes } from '@cdw-selline/ui/constants';

export interface UserAccessFormProps {
  isOpen: boolean;
  handleClose: () => unknown;
  project: Opportunity;
  updateProjectError: ApolloError;
  updateProjectLoading: boolean;
  handleSave: (formData) => unknown;
}

export const UserAccessForm = ({
  isOpen,
  handleClose,
  project,
  updateProjectError,
  updateProjectLoading,
  handleSave,
}: UserAccessFormProps) => {
  const [value, setValue] = useState({ email: '', type: '' });
  const [error, setError] = useState(false);

  const handleFormClose = () => {
    setError(false);
    setValue({ email: '', type: '' });
    handleClose();
  };

  const handleSaveClick = () => {
    if (value.email === '') {
      setError(true);
    } else {
      handleSave(value);
      handleClose();
      setError(false);
      setValue({ email: '', type: '' });
    }
  };

  if (updateProjectError)
    return <>Error loading form: {updateProjectError.message}</>;

  if (updateProjectLoading) return <CircularProgress />;

  return (
    <DialogModal
      fullWidth={true}
      isOpen={isOpen}
      handleClose={handleClose}
      title={'Add Opportunity Team Member'}
      action={getActionButtons(handleFormClose, handleSaveClick)}
      maxWidthProp={'sm'}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="row"
        spacing={1}
        mt={1}
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="column"
          spacing={1}
          sx={{ width: '100%' }}
        >
          <Grid container spacing={1} item xs={12} sx={{ width: '100%' }}>
            {error && (
              <Box sx={{ padding: '8px' }}>
                <Typography color="error" variant="h6">
                  Please select a user to add to the opportunity
                </Typography>
              </Box>
            )}
            <UsersAutocomplete
              setProjectFormData={setValue}
              projectFormData={value}
              freeSolo={true}
            />
            <FormSelect
              name="type"
              label="Role"
              setFormData={setValue}
              formData={value}
            >
              <MenuItem key="none" value="">
                --NONE--
              </MenuItem>
              {opportunityTeamTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
        </Grid>
      </Grid>
    </DialogModal>
  );
};

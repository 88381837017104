import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { SiteBreakdown } from '@cdw-selline/common/types';
import { GET_SITE_BREAKDOWN_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from '.';

export const useSiteBreakdown = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { projectItemId } = useParams<{ projectItemId: string }>();

  const { loading, error, data } = useQuery<{
    getSiteBreakdownByProjectId: SiteBreakdown;
  }>(GET_SITE_BREAKDOWN_QUERY, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    variables: { id: projectItemId },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Site Breakdown!'),
  });

  return {
    siteBreakdown: data?.getSiteBreakdownByProjectId ?? {
      collections: [],
      count: 0,
    },
    error,
    loading,
  };
};

import { gql } from '@apollo/client';

export const RELOAD_PROJECT_PROPOSAL_MUTATION = gql`
  mutation Mutation($id: ID!) {
    reloadProjectProposal(id: $id) {
      data
      success
      error
    }
  }
`;
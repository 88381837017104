import { useQuery } from '@apollo/client';
import { GET_SOW_SECTION_BY_ID_QUERY } from '@cdw-selline/ui/queries';
import { useApolloErrors } from '.';

export const useSowSectionById = (id: string) => {
  const { handleErrorResponse } = useApolloErrors();

  const { loading, error, data, refetch } = useQuery(
    GET_SOW_SECTION_BY_ID_QUERY,
    {
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      skip: !id,
      onError: (error) =>
        handleErrorResponse(error, 'Failed to fetch Sow Section!'),
    }
  );

  return {
    data: data?.getSowSectionById ?? {},
    loading,
    error,
    refetch,
  };
};

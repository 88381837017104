import { useQuery } from '@apollo/client';
import { GET_HEALTH_CHECK_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from './useApolloErrors';

export const useHealthCheck = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery(GET_HEALTH_CHECK_QUERY, {
    onError: (error) =>
      handleErrorResponse(error, 'Health Check Request Failed'),
  });

  return {
    data,
    error,
    loading,
  };
};

export default useHealthCheck;

import {
  getGlobalQuoteSettingsTableData,
  getTotalCDWCost,
  getTotalCustomerCost,
} from '@cdw-selline/ui/helpers';
import { useGetProjectItemQuotesByProjectItemId, useProjectItem } from '..';

export const useProjectItemQuote = (projectItemId: string) => {
  const {
    data: quoteData,
    loading: quoteLoading,
    error: quoteError,
  } = useGetProjectItemQuotesByProjectItemId(projectItemId);
  const {
    data: projectItemData,
    loading: projectItemLoading,
    error: projectItemError,
  } = useProjectItem();
  const loading = quoteLoading || projectItemLoading;
  const error = quoteError || projectItemError;

  const cdwCost = getTotalCDWCost(quoteData);
  const customerCost = getTotalCustomerCost(quoteData);

  const globalSettingsTableData = getGlobalQuoteSettingsTableData(quoteData);

  return {
    quoteName: projectItemData?.name,
    cdwCost,
    customerCost,
    globalSettingsTableData,
    quoteData,
    loading,
    error,
  };
};

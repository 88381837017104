// eslint-disable-next-line @nx/enforce-module-boundaries
import { labelStrings } from '@cdw-selline/ui/constants';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, DialogActions, TextField } from '@mui/material';
import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import DialogModal from '../../dialog-modal/DialogModal';

/* eslint-disable-next-line */
export interface EditProposalNameDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  currentProposalName: string;
  handleNameChange: (newName: string) => void;
}

export function EditProposalNameDialog({
  isOpen,
  handleClose,
  currentProposalName,
  handleNameChange,
}: EditProposalNameDialogProps) {
  const dialogStrings = {
    EDIT_NAME: 'Edit Proposal Name',
    PROPOSAL_NAME: 'Proposal Name',
  };

  const [nameInput, setNameInput] = useState<string>(currentProposalName);
  const handleNameInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNameInput(e.target.value);
  };

  useEffect(() => {
    setNameInput(currentProposalName);
  }, [currentProposalName]);

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleNameChange(nameInput || currentProposalName);
    handleClose();
  };

  return (
    <DialogModal
      title={dialogStrings.EDIT_NAME}
      maxWidth={'lg'}
      {...{ isOpen, handleClose }}
    >
      <form onSubmit={handleFormSubmit}>
        <TextField
          label={dialogStrings.PROPOSAL_NAME}
          value={nameInput}
          onChange={handleNameInputChange}
          data-testid="proposal-name-input"
          sx={{ margin: '1em' }}
        />

        <DialogActions>
          <Button
            color="primary"
            onClick={handleClose}
            data-testid="edit-name-cancel-button"
          >
            {labelStrings.CANCEL}
          </Button>
          <LoadingButton
            type="submit"
            aria-label="edit proposal name"
            data-testid="edit-name-submit-button"
            variant="contained"
            loading={false}
          >
            {labelStrings.SUBMIT}
          </LoadingButton>
        </DialogActions>
      </form>
    </DialogModal>
  );
}

export default EditProposalNameDialog;

import { useQuery } from '@apollo/client';
import { GET_CDW_RATE_BY_ID_QUERY } from '@cdw-selline/ui/queries';
import { useApolloErrors } from '.';

export const useCdwRateById = (id: string) => {
  const { handleErrorResponse } = useApolloErrors();

  const { loading, error, data, refetch } = useQuery(GET_CDW_RATE_BY_ID_QUERY, {
    variables: {
      id,
    },
    skip: !id,
    fetchPolicy: 'cache-and-network',
    onError: (error) => handleErrorResponse(error, 'Failed to fetch CdwRate!'),
  });

  return {
    data: data?.getCdwRateById ?? {},
    loading,
    error,
    refetch,
  };
};

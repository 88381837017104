import {
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from '@mui/material';
import { CSSProperties } from '@mui/styles';
import React from 'react';

export interface DialogModalProps {
  children: JSX.Element | JSX.Element[];
  isOpen: boolean;
  handleClose: () => void;
  title: string | JSX.Element;
  maxWidth?: string | number;
  maxWidthProp?: false | Breakpoint;
  fullWidth?: boolean | null;
  customStyles?: CSSProperties;
  action?: JSX.Element | JSX.Element[];
  disableScrollLock?: boolean;
  disableEnforceFocus?: boolean;
}

//TODO Evaluate need for maxWidth as it causes dialog to be positioned left
export function DialogModal({
  children,
  isOpen,
  handleClose,
  title,
  maxWidth,
  maxWidthProp,
  fullWidth,
  customStyles,
  action,
  disableScrollLock,
  disableEnforceFocus,
}: DialogModalProps) {
  const theme = useTheme();
  const titleAccentColor = theme.palette.secondary.main;
  return (
    <Dialog
      disableScrollLock={disableScrollLock ?? false}
      open={isOpen}
      onClose={handleClose}
      scroll="paper"
      sx={{ ...customStyles }}
      maxWidth={maxWidthProp}
      fullWidth={fullWidth}
      disableEnforceFocus={disableEnforceFocus ?? false}
    >
      <DialogTitle
        sx={{
          borderBottom: `solid 2px ${titleAccentColor}`,
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {action && <DialogActions>{action}</DialogActions>}
    </Dialog>
  );
}

export default DialogModal;

import react, {
  useEffect,
  useRef,
  EffectCallback,
  DependencyList,
} from 'react';

export const useNonInitialEffect = (
  effect: EffectCallback,
  deps?: DependencyList
) => {
  const initialRender = useRef(true);
  useEffect(() => {
    let effectReturns: void | (() => void | undefined) = () => {
      //do nothing
    };
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      effectReturns = effect();
    }
    if (effectReturns && typeof effectReturns === 'function') {
      return effectReturns;
    }
  }, deps);
};

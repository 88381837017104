import { gql } from '@apollo/client';

export const GET_SOW_SECTION_BY_ID_QUERY = gql`
  query getSowSectionById($id: ID!) {
    getSowSectionById(id: $id) {
      id
      name
      help
      highLevel
      scope
      customerResponsibilities
      customerResponsibilitiesGlobal
      assumptions
      assumptionsGlobal
      outOfScope
      outOfScopeGlobal
      version
      title
      owner {
        email
      }
      coOwners {
        email
      }
      testers {
        email
      }
      status
      originalSowSectionId
      inTestingSowSectionId
    }
  }
`;

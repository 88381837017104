import {
  CollectionsDataTable,
  SowSectionForm,
} from '@cdw-selline/ui/components';
import { useAdminSowSections, useSowSectionById } from '@cdw-selline/ui/hooks';
import { Box } from '@mui/material';
import React from 'react';

/* eslint-disable-next-line */
export interface SowSectionsCollectionPageProps {}

export const SowSectionsCollectionPage = (
  props: SowSectionsCollectionPageProps
) => {
  const dataTableProps = useAdminSowSections();

  const {
    data: sowSection,
    loading: sowSectionLoading,
    error: sowSectionError,
  } = useSowSectionById(dataTableProps.editSowSectionId);

  const openForm =
    dataTableProps.sowSectionFormOpen &&
    ((dataTableProps.editSowSectionId && sowSection.id) ||
      !dataTableProps.editSowSectionId);

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <CollectionsDataTable
        {...dataTableProps}
        height="calc(100vh - 13em)"
        paginationMode="server"
        sortingMode="server"
        onRowClick={(o) => dataTableProps.handleEdit(o.id)}
        parent="SowSectionsCollectionPage"
      />
      {openForm && (
        <SowSectionForm
          isOpen={dataTableProps.sowSectionFormOpen}
          handleClose={dataTableProps.handleSowSectionFormClose}
          handleSubmit={dataTableProps.handleSowSectionFormSave}
          sowSection={sowSection}
        />
      )}
    </Box>
  );
};

export default SowSectionsCollectionPage;

import { gql } from '@apollo/client';

export const GET_NEW_PROJECT_SCHEMA_QUERY = gql`
  query getNewProjectSchemaQuery($getSchemaId: ID!, $searchTerm: String) {
    getSchema(id: $getSchemaId, searchTerm: $searchTerm) {
      uiSchema: uiSchemaV2
      schema {
        type
        title
        required
        description
        properties {
          ... on NewProjectProperties {
            customerName {
              enum(searchTerm: $searchTerm)
              minLength
              title
              type
            }
            name {
              required
              title
              type
            }
          }
        }
      }
    }
  }
`;

// VARIABLES
// {
//   getSchemaId: "new-project"
// }

import { useSearchCustomers } from '@cdw-selline/ui/hooks';
import React, { useState } from 'react';
import { Grid, TextField, Autocomplete } from '@mui/material';

export interface CustomerAutoCompleteProps {
  setProjectFormData;
  projectFormData;
  onKeyUp?(event: React.KeyboardEvent<HTMLInputElement>): void;
  required?: boolean;
}

export function CustomerAutoComplete({
  setProjectFormData,
  projectFormData,
  onKeyUp,
  required,
}: CustomerAutoCompleteProps) {
  const [searchTerm, setSearchTerm] = useState(
    projectFormData?.customerName ?? ''
  );

  const {
    data: customers,
    loading: customersLoading,
    error: customersError,
  } = useSearchCustomers(searchTerm);
  const customerOptions =
    customers.map((customer) => {
      return {
        id: customer.id,
        label: `${customer.customerDescription} (${customer.customerCode})`,
      };
    }) ?? [];

  const handleAutocompleteChange = (value: string, name: string) => {
    setProjectFormData({
      ...projectFormData,
      [name]: value,
    });
  };

  const getAutocompleteDefaultValue = (name: string, options: unknown) => {
    if (!Array.isArray(options) || options.length === 0) {
      return '';
    }

    if (
      !projectFormData[name] ||
      projectFormData[name] == 0 ||
      projectFormData[name] === null
    ) {
      return '';
    }

    return options.find((option) => option.id === projectFormData[name])?.label;
  };

  return (
    <Grid item xs={12} sx={{ width: '100%' }}>
      <Autocomplete
        onKeyUp={onKeyUp}
        tabIndex={2}
        id="customerId"
        options={customerOptions}
        sx={{ width: '100%' }}
        noOptionsText={`Type Customer Name or SPS#`}
        value={searchTerm}
        onInputChange={(event, newValue) => {
          setSearchTerm(newValue);
        }}
        onChange={(event, newValue) => {
          handleAutocompleteChange(newValue?.id ?? '', 'customerId');
        }}
        defaultValue={getAutocompleteDefaultValue(
          'customerId',
          customerOptions
        )}
        data-testid={`new-project-customer-input`}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Customer"
            required={required ? true : false}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={props.id}>
              {option?.label}
            </li>
          );
        }}
      />
    </Grid>
  );
}

export default CustomerAutoComplete;

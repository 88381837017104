import { Button, Grid, TextField, Typography, Box } from '@mui/material';
import React, { FocusEvent, useState } from 'react';
import DialogModal from '../dialog-modal/DialogModal';
import { Opportunity } from '@cdw-selline/common/types';
import { CustomerAutoComplete } from './CustomerAutoComplete';
import {getSalesforceIDFromUrl, isValidSalesforceOpportunityUrl} from '@cdw-selline/ui/helpers'
import { ALERT_SEVERITY, ALERT_TYPE, useAlertsState } from '@cdw-selline/ui/state';
export interface ProjectFormProps {
  isOpen;
  handleClose;
  handleSubmit;
  projectId: string | null;
}

export function ProjectForm({
  isOpen,
  handleClose,
  handleSubmit,
  projectId,
}: ProjectFormProps) {
  const newProjectDefaults: Opportunity = {};
  const [projectFormData, setProjectFormData] = useState(newProjectDefaults);
  const [error, setError] = useState(false);
  const alertState = useAlertsState();

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setProjectFormData({
      ...projectFormData,
      [event.target.name]: event.target.value,
    });
  };

  const handleFormClose = () => {
    setError(false);
    setProjectFormData({});
    handleClose();
  };

  const handleSaveClick = () => {
    if (
      Object.keys(projectFormData).some((k) => k === 'name') &&
      projectFormData?.name !== '' &&
      Object.keys(projectFormData).some(
        (k) => k === 'customerId' && projectFormData?.customerId !== ''
      )
    ) {
      setError(false);
      if(projectFormData.salesForceUrl){
        if(isValidSalesforceOpportunityUrl(projectFormData.salesForceUrl)){
          projectFormData.salesForceId = getSalesforceIDFromUrl(projectFormData.salesForceUrl)
        } else {
          alertState.setAlert({
            type: ALERT_TYPE.MODAL,
            severity: ALERT_SEVERITY.ERROR,
            message: 'Salesforce link must be for the opportunity only.',
          });
          return false;
        }
      }
      handleSubmit(projectFormData);
      handleFormClose();
    } else {
      setError(true);
    }
  };

  const getActionButtons = () => {
    return (
      <Grid container justifyContent="flex-end" spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFormClose}
            data-testid="new-project-cancel-button"
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveClick}
            data-testid="new-project-submit-button"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    );
  };

  const ProjectText = (props) => (
    <Grid item xs={12} sx={{ width: '100%' }}>
      <TextField
        name={props.name}
        id={props.name}
        defaultValue={projectFormData[props.name] ?? ''}
        onBlur={handleBlur}
        label={props?.label}
        type="text"
        sx={{ width: '100%' }}
        data-testid={`new-project-${props?.label}-input`}
        multiline={props?.multiline ?? false}
      />
    </Grid>
  );

  return (
    <DialogModal
      fullWidth={true}
      isOpen={isOpen}
      handleClose={handleClose}
      title={'Add Opportunity'}
      action={getActionButtons()}
      maxWidthProp={'sm'}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="row"
        spacing={1}
        mt={1}
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="column"
          spacing={1}
          sx={{ width: '100%' }}
        >
          {error && (
            <Box data-testid="error-message" sx={{ padding: '15px' }}>
              <Typography color="error" variant="h6">
                Please add Opportunity name and select a customer
              </Typography>
            </Box>
          )}
          <ProjectText name="name" label="Name" required={true} />
          <ProjectText name="salesForceUrl" label="Salesforce Opportunity URL"/>
          <CustomerAutoComplete
            setProjectFormData={setProjectFormData}
            projectFormData={projectFormData}
            required={true}
          />
          <ProjectText
            name="description"
            multiline={true}
            label="Description"
            required={true}
          />
        </Grid>
      </Grid>
    </DialogModal>
  );
}

export default ProjectForm;

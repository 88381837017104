import { gql } from '@apollo/client';

export const REMOVE_TASK_GROUP_TEAM_MEMBER_MUTATION = gql`
  mutation removeTaskGroupTeamMemberMutation(
    $email: String!
    $taskGroupId: ID!
  ) {
    removeTaskGroupTeamMember(email: $email, taskGroupId: $taskGroupId) {
      success
    }
  }
`;

export default REMOVE_TASK_GROUP_TEAM_MEMBER_MUTATION;

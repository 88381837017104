import { gql } from '@apollo/client';

export const REMOVE_PROJECT_ITEMS_ESTIMATOR = gql`
  mutation removeProjectItemsEstimatorMutation($id: ID!) {
    removeProjectItemEstimator(id: $id) {
      success
    }
  }
`;

// SAMPLE VARIABLES
// {
//   "id": "1234"
// }

import { useQuery } from '@apollo/client';
import { GET_PROJECT_ITEM_SOLUTIONS } from '@cdw-selline/ui/queries';

import { useApolloErrors } from '.';

export const useProjectItemSolutions = () => {
  const { handleErrorResponse } = useApolloErrors();

  const { loading, error, data, refetch } = useQuery<{
    getDistinctSolutions: string[];
  }>(GET_PROJECT_ITEM_SOLUTIONS, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Project Item Solutions!'),
  });

  return {
    solutions: data?.getDistinctSolutions ?? [],
    error,
    loading,
    refetch,
  };
};

import { ApolloProvider } from '@apollo/client';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { MockedProvider, MockedProviderProps } from '@apollo/client/testing';
import { setContext } from '@apollo/client/link/context';
import { fetch } from 'cross-fetch';
import * as React from 'react';
import { MemoryRouter, BrowserRouterProps, MemoryRouterProps } from 'react-router-dom';

const httpLink = createHttpLink({ fetch });

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
  credentials: 'include',
});

export const AppProviders = ({ children }) => (
  <MemoryRouter>
    <ApolloProvider client={client}>{children}</ApolloProvider>
  </MemoryRouter>
);

interface MockedProviderWithBrowserWrapperProps {
  children: JSX.Element | JSX.Element[];
  mockProviderConfig?: MockedProviderProps;
  mockBrowserConfig?: MemoryRouterProps;
}

export const MockedProviderWithBrowserWrapper = ({
  children,
  mockProviderConfig = {},
  mockBrowserConfig = {},
}: MockedProviderWithBrowserWrapperProps) => (
  <MemoryRouter {...mockBrowserConfig}>
    <MockedProvider {...mockProviderConfig}>{children}</MockedProvider>
  </MemoryRouter>
);

import { gql } from '@apollo/client';

export const SEARCH_CUSTOMERS_QUERY = gql`
  query SearchCustomers($searchTerm: String!) {
    searchCustomers {
      customers(searchTerm: $searchTerm) {
        customerCode
        customerDescription
        id
        listName
      }
    }
  }
`;

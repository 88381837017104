import { gql } from '@apollo/client';

export const GET_ADMIN_PRACTICES_SCHEMA_QUERY = gql`
  query getServiceLocationsSchemaQuery($getSchemaId: ID!) {
    getSchema(id: $getSchemaId) {
      uiSchema: uiSchemaV2
      schema {
        type
        title
        required
        description
        properties {
          ... on PracticesProperties {
            serviceLocationId {
              type
              title
              enum
              enumNames
            }
            psm {
              title
              type
            }
            name {
              type
              title
            }
            managedSvc {
              type
              title
            }
            peerReviewEmail {
              title
              type
            }
            practiceUsernames {
              type
              title
              items {
                default
                type
              }
            }
          }
        }
      }
    }
  }
`;

import { useQuery } from '@apollo/client';
import {
  GET_USER_IS_CLOUD_FULFILLMENT_QUERY,
} from '@cdw-selline/ui/queries';


export const useUserIsCloudFulfillment = () => {
  const { loading, error, data } = useQuery<{
    getUserIsCloudFulfillment: boolean;
  }>(GET_USER_IS_CLOUD_FULFILLMENT_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  return {
    userIsCloudFulfillment: data?.getUserIsCloudFulfillment ?? false,
    loading,
    error,
  };
};
import { useMutation } from '@apollo/client';
import {
  DefaultAddResponse,
  DefaultMutationResponse,
  Tier,
} from '@cdw-selline/common/types';
import {
  ADD_TIER_MUTATION,
  GET_TIERS_QUERY,
  GET_TIER_BY_ID_QUERY,
  REMOVE_ADMIN_TIER_MUTATION,
  UPDATE_TIER_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import {
  GridColDef,
  GridPaginationModel,
  GridFilterModel,
} from '@mui/x-data-grid';
import { useState } from 'react';
import { useApolloErrors } from './useApolloErrors';
import { useTiers } from './useTiers';
import { useOpenState } from './estimator/useOpenState';
import omitDeep from 'omit-deep-lodash';
import {
  getFilters,
  getFilterModelFromLocalStorage,
} from '@cdw-selline/ui/helpers';
import moment from 'moment';
import { PAGES } from '@cdw-selline/ui/constants';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Tier ID',
    width: 180,
    editable: false,
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Tier Name',
    width: 180,
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Created Date',
    type: 'dateTime',
    width: 180,
    editable: false,
    valueGetter: (value) => value && new Date(value),
    valueFormatter: (value) =>
      value ? moment(value).format('MM/DD/YYYY') : '',
  },
];

export const useAdminTiers = () => {
  const [sortState, setSortState] = useState();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const { data, loading, error, refetch } = useTiers({
    filters: getFilters(PAGES.TIERS_COLLECTION),
    offset: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize,
    sort: sortState,
  });
  const filterModel: GridFilterModel = getFilterModelFromLocalStorage(`${PAGES.TIERS_COLLECTION}-filter`);
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();
  const [editTierId, setEditTierId] = useState(null);
  const {
    isOpen: tierFormOpen,
    handleClose: closeTierForm,
    handleOpen: openTierForm,
  } = useOpenState();

  const [addTier, { loading: addTierLoading, error: addTierError }] =
    useMutation<{ addTier: DefaultAddResponse }>(ADD_TIER_MUTATION, {
      refetchQueries: [GET_TIERS_QUERY, GET_TIER_BY_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to create new tier section'),
      onCompleted: (data) => {
        if (data.addTier.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully added tier section',
          });
        }
      },
    });

  const [removeTier, { loading: removeTierLoading, error: removeTierError }] =
    useMutation<{ removeTier: DefaultMutationResponse }>(
      REMOVE_ADMIN_TIER_MUTATION,
      {
        refetchQueries: [GET_TIERS_QUERY],
        onError: (error) =>
          handleErrorResponse(error, 'Failed to remove tier section'),
        onQueryUpdated(observableQuery) {
          return observableQuery.refetch();
        },
        onCompleted: (data) => {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully removed tier section',
          });
        },
      }
    );

  const [updateTier, { loading: updateTierLoading, error: updateTierError }] =
    useMutation<{ updateTier: DefaultMutationResponse }>(UPDATE_TIER_MUTATION, {
      refetchQueries: [GET_TIERS_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update tier section'),
      onCompleted: (data) => {
        if (data.updateTier.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully updated tier section',
          });
        }
      },
    });

  const handleAdd = () => {
    openTierForm();
  };

  const handleTierFormClose = () => {
    setEditTierId(null);
    closeTierForm();
  };

  const handleDelete = (pid: string) =>
    removeTier({
      variables: { removeTierId: pid },
    });
  const handleEdit = (id: string) => {
    setEditTierId(id);
    openTierForm();
  };

  const handleTierFormSave = (tier: Tier) => {
    if (!tier.id) {
      addTier({
        variables: {
          params: omitDeep(tier, ['__typename']),
        },
      });
    }

    if (tier.id) {
      updateTier({
        variables: {
          params: omitDeep(tier, ['__typename']),
        },
      });
    }
  };

  const onFilterModelChange = (filterModel) => {
    refetch({ filters: getFilters(PAGES.TIERS_COLLECTION) });
  };

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    const pageSize = model?.pageSize ?? paginationModel?.pageSize;
    const page = model?.page ?? paginationModel?.page;
    setPaginationModel({
      page,
      pageSize,
    });
  };

  const handleSort = (args) => {
    let newSort;
    if (args.length) newSort = { [args[0].field]: args[0].sort };
    setSortState(newSort);
  };

  const returnValue = {
    columns,
    rows: data.tiers || [],
    handleAdd,
    handleDelete,
    handleEdit,
    handlePaginationModelChange,
    handleSort,
    onFilterModelChange,
    filterModel,
    tiers: data.tiers,
    editTierId,
    addTierLoading,
    addTierError,
    removeTierLoading,
    removeTierError,
    updateTierLoading,
    updateTierError,
    loading:
      loading || addTierLoading || removeTierLoading || updateTierLoading,
    error,
    tierFormOpen,
    handleTierFormClose,
    handleTierFormSave,
    rowCount: data.count,
    addTier,
  };
  if (loading || addTierLoading || removeTierLoading || updateTierLoading) {
    return {
      ...returnValue,
      paginationModel: { pageSize: 100, page: 0 },
      rowCount: 0,
      rows: [],
    };
  } else {
    return { ...returnValue, paginationModel };
  }
};

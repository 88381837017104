import { useMutation, useQuery } from '@apollo/client';
import {
  GetProjectRatesResponse,
  Practice,
  RoleAndRate,
} from '@cdw-selline/common/types';
import {
  GET_PROJECT_PRACTICES_RATES,
  SET_PROJECT_PRACTICES_AND_RATES_MUTATION,
  GET_PROJECT_QUERY,
} from '@cdw-selline/ui/queries';
import omitDeep from 'omit-deep-lodash';
import { useEstimatorCalculation } from './useEstimatorCalculation';

import { useApolloErrors } from '..';

export const useProjectPracticesAndRatesQuery = (projectItemId: string) => {
  const { handleErrorResponse } = useApolloErrors();

  const { data, loading, error } = useQuery<{
    getProjectItemEstimatorRates: GetProjectRatesResponse;
  }>(GET_PROJECT_PRACTICES_RATES, {
    fetchPolicy: 'no-cache',
    variables: {
      getProjectItemEstimatorRatesProjectId: projectItemId,
    },
    onError: (error) =>
      handleErrorResponse(
        error,
        'Failed to fetch Project Practices and Rates!'
      ),
  });

  return {
    data: data?.getProjectItemEstimatorRates ?? {
      practices: [],
      rates: [],
    },
    loading,
    error,
    currentPractices: data?.getProjectItemEstimatorRates?.practices ?? [],
    currentRolesAndRates: data?.getProjectItemEstimatorRates?.rates ?? [],
  };
};

export const useProjectPracticesAndRatesMutation = (
  projectItemId: string,
  currentPractices: Practice[],
  currentRolesAndRates: RoleAndRate[]
) => {
  const { handleErrorResponse } = useApolloErrors();
  const { updateProjectItemEstimatorCalculation } = useEstimatorCalculation();

  const [setProjectPracticesAndRates, { loading, error, client }] = useMutation(
    SET_PROJECT_PRACTICES_AND_RATES_MUTATION,
    {
      refetchQueries: [
        GET_PROJECT_PRACTICES_RATES,
      ],
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: () => {
        client.refetchQueries({
          include: [GET_PROJECT_QUERY],
        });
  
        updateProjectItemEstimatorCalculation({
          variables: {
            params: {
              projectItemId,
            },
          },
        });
      },
      onError: (error) =>
        handleErrorResponse(error, 'Failed to add Practices!'),
    }
  );

  const handleAddPractice = (practice: Practice) => {
    setProjectPracticesAndRates({
      variables: omitDeep(
        {
          practices: [...currentPractices, practice],
          projectItemId,
        },
        ['__typename', 'lastModified']
      ),
    });
  };

  const handleRemovePractice = (practice: Practice) => {
    setProjectPracticesAndRates({
      variables: omitDeep(
        {
          practices: currentPractices.filter(
            (i: Practice) => i.id !== practice.id
          ),
          projectItemId: projectItemId,
          rates: currentRolesAndRates.filter(
            (i: RoleAndRate) => i.practice !== practice.id
          ),
        },
        ['__typename', 'lastModified']
      ),
    });
  };

  const handleUpdateRole = (updatedRole: RoleAndRate) => {
    const targetRoleIndex = currentRolesAndRates.findIndex(
      (role) => role.id === updatedRole.id
    );

    if (targetRoleIndex === -1) {
      console.log('No matching role found, nothing updated');
      return;
    } else {
      const updatedRolesAndRates = [
        ...currentRolesAndRates.slice(0, targetRoleIndex),
        updatedRole,
        ...currentRolesAndRates.slice(targetRoleIndex + 1),
      ];

      setProjectPracticesAndRates({
        variables: omitDeep(
          {
            practices: currentPractices,
            projectItemId: projectItemId,
            rates: updatedRolesAndRates,
          },
          ['__typename', 'lastModified']
        ),
      });
    }
  };

  return {
    handleAddPractice,
    handleRemovePractice,
    handleUpdateRole,
    loading,
    error,
  };
};

import { gql } from '@apollo/client';

export const UPDATE_EMAIL_TEMPLATES_MUTATION = gql`
  mutation updateEmailTemplates(
    $params: EmailTemplatesInput!
  ) {
    updateEmailTemplates(params: $params) {
      success
    }
  }
`;
import { useMutation } from '@apollo/client';
import {
  DefaultAddResponse,
  DefaultMutationResponse,
  AgiloftExpenseType,
} from '@cdw-selline/common/types';
import {
  ADD_AGILOFT_EXPENSE_TYPE_MUTATION,
  GET_AGILOFT_EXPENSE_TYPES_QUERY,
  REMOVE_AGILOFT_EXPENSE_TYPE_MUTATION,
  UPDATE_AGILOFT_EXPENSE_TYPE_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import {
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridRowsProp,
} from '@mui/x-data-grid';
import { useState } from 'react';
import { PAGES } from '@cdw-selline/ui/constants';
import { useApolloErrors } from '../useApolloErrors';
import { useAgiloftExpenseTypes } from './useAgiloftExpenseTypes';
import { useOpenState } from '../estimator/useOpenState';
import omitDeep from 'omit-deep-lodash';
import {
  getFilters,
  getFilterModelFromLocalStorage,
} from '@cdw-selline/ui/helpers';

const columns: GridColDef[] = [
  {
    field: 'expenseTypeName',
    headerName: 'Agiloft Expense Type',
    width: 180,
    flex: 1,
  },
  {
    field: 'expenseClauses',
    headerName: 'Agiloft Expense Clauses',
    width: 180,
    flex: 1,
  },
  {
    field: 'agiloftDefaultExpenseClause',
    headerName: 'Default Agiloft Expense Clauses',
    width: 180,
    flex: 1,
  },
];

export const useAdminAgiloftExpenseTypes = () => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  const [sortState, setSortState] = useState();

  const filterModel: GridFilterModel = getFilterModelFromLocalStorage(`${PAGES.AGILOFT_EXPENSE_TYPES_COLLECTION}-filter`);

  const { data, loading, error, refetch } = useAgiloftExpenseTypes({
    filters: getFilters(PAGES.AGILOFT_EXPENSE_TYPES_COLLECTION),
    offset: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize,
    sort: sortState,
    // });
  });
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();
  const [editAgiloftExpenseTypeId, setEditAgiloftExpenseTypeId] =
    useState(null);
  const {
    isOpen: agiloftExpenseTypeFormOpen,
    handleClose: closeAgiloftExpenseTypeForm,
    handleOpen: openAgiloftExpenseTypeForm,
  } = useOpenState();

  const [
    addAgiloftExpenseType,
    {
      loading: addAgiloftExpenseTypeLoading,
      error: addAgiloftExpenseTypeError,
    },
  ] = useMutation<{ addAgiloftExpenseType: DefaultAddResponse }>(
    ADD_AGILOFT_EXPENSE_TYPE_MUTATION,
    {
      refetchQueries: [GET_AGILOFT_EXPENSE_TYPES_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to create new Agiloft Expense Type'),
      onCompleted: (data) => {
        if (data.addAgiloftExpenseType.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully added Agiloft Expense Type',
          });
        }
      },
    }
  );

  const [
    removeAgiloftExpenseType,
    {
      loading: removeAgiloftExpenseTypeLoading,
      error: removeAgiloftExpenseTypeError,
    },
  ] = useMutation<{ removeAgiloftExpenseType: DefaultMutationResponse }>(
    REMOVE_AGILOFT_EXPENSE_TYPE_MUTATION,
    {
      refetchQueries: [GET_AGILOFT_EXPENSE_TYPES_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to remove Agiloft Expense Type'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.removeAgiloftExpenseType.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully removed Agiloft Expense Type',
          });
        }
      },
    }
  );

  const [
    updateAgiloftExpenseType,
    {
      loading: updateAgiloftExpenseTypeLoading,
      error: updateAgiloftExpenseTypeError,
    },
  ] = useMutation<{ updateAgiloftExpenseType: DefaultMutationResponse }>(
    UPDATE_AGILOFT_EXPENSE_TYPE_MUTATION,
    {
      refetchQueries: [GET_AGILOFT_EXPENSE_TYPES_QUERY],
      awaitRefetchQueries: true,
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update Agiloft Expense Type'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.updateAgiloftExpenseType.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully updated Agiloft Expense Type',
          });
        }
      },
    }
  );

  const handleAdd = () => {
    openAgiloftExpenseTypeForm();
  };

  const handleAgiloftExpenseTypeFormClose = () => {
    setEditAgiloftExpenseTypeId(null);
    closeAgiloftExpenseTypeForm();
  };

  const handleDelete = (pid: string) =>
    removeAgiloftExpenseType({
      variables: { removeAgiloftExpenseTypeId: pid },
    });
  const handleEdit = (id: string) => {
    setEditAgiloftExpenseTypeId(id);
    openAgiloftExpenseTypeForm();
  };

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    const pageSize = model?.pageSize ?? paginationModel?.pageSize;
    const page = model?.page ?? paginationModel?.page;
    setPaginationModel({
      page,
      pageSize,
    });
  };

  const onFilterModelChange = (filterModel) => {
    refetch({ filters: getFilters(PAGES.AGILOFT_EXPENSE_TYPES_COLLECTION) });
  };

  const handleAgiloftExpenseTypeFormSave = (
    agiloftExpenseType: AgiloftExpenseType
  ) => {
    if (!agiloftExpenseType.id) {
      addAgiloftExpenseType({
        variables: {
          params: omitDeep(agiloftExpenseType, ['__typename']),
        },
      });
    }

    if (agiloftExpenseType.id) {
      updateAgiloftExpenseType({
        variables: {
          params: omitDeep(agiloftExpenseType, ['__typename']),
        },
      });
    }
  };

  const handleSort = (args) => {
    let newSort;
    if (args.length) newSort = { [args[0].field]: args[0].sort };
    setSortState(newSort);
  };
  const returnValue = {
    columns,
    rows: data.agiloftExpenseTypes || ([] as GridRowsProp),
    handleAdd,
    handleDelete,
    handleEdit,
    handlePaginationModelChange,
    onFilterModelChange,
    handleSort,
    filterModel: filterModel,
    agiloftExpenseTypes: data.agiloftExpenseTypes,
    editAgiloftExpenseTypeId,
    addAgiloftExpenseTypeLoading,
    addAgiloftExpenseTypeError,
    removeAgiloftExpenseTypeLoading,
    removeAgiloftExpenseTypeError,
    loading:
      loading ||
      addAgiloftExpenseTypeLoading ||
      removeAgiloftExpenseTypeLoading,
    error,
    agiloftExpenseTypeFormOpen,
    handleAgiloftExpenseTypeFormClose,
    handleAgiloftExpenseTypeFormSave,
    rowCount: data.count,
  };

  if (
    loading ||
    addAgiloftExpenseTypeLoading ||
    removeAgiloftExpenseTypeLoading
  ) {
    return {
      ...returnValue,
      paginationModel: { pageSize: 100, page: 0 },
      rowCount: 0,
      rows: [],
    };
  } else {
    return { ...returnValue, paginationModel };
  }
};

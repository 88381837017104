import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  GET_AGILOFT_TYPE_OPTIONS_QUERY,
} from '@cdw-selline/ui/queries';
import { useApolloErrors } from '..';

export const useAgiloftTypeOptions = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { id, projectItemId } =
    useParams<{ id: string; projectItemId: string }>();
  const {
    loading,
    error,
    data,
  } = useQuery(GET_AGILOFT_TYPE_OPTIONS_QUERY, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    variables: {
      projectItemId,
    },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Task Groups Print Templates options!'),
  });

  return {
    error,
    loading,
    data : data?.getAgiloftTypeOptionsByProjectItemId?.agiloftPrintTemplateTypeOptions || [],
    agiloftDefaultTemplates: data?.getAgiloftTypeOptionsByProjectItemId?.agiloftDefaultTemplates || [],
  };
}

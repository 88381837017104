import { gql } from '@apollo/client';

export const GET_SCOPE_ITEMS_QUERY = gql`
  query GetScopeItems($filters: MongoFilter, $limit: Int, $offset: Int, $sort: MongoFilter) {
    getScopeItems {
      count(filters: $filters)
      scopeItems(filters: $filters, limit: $limit, offset: $offset, sort: $sort) {
        category
        collateral {
          description
          link
          title
        }
        id
        legacyId
        notes
        order
        parentItemDataId
        parentItemDataValue
        parentItemId
        practiceId
        createdAt
        questions {
          createdBy
          createdOn
          id
          list {
            name
            value
          }
          name
          order
          scopeItemId
          title
          type
        }
        scopeGroupId
        title
        trainings {
          description
          link
          title
        }
        version
      }
    }
  }
`;

import { CollectionsDataTable, RoleForm } from '@cdw-selline/ui/components';
import { useAdminRoles, useRoleById } from '@cdw-selline/ui/hooks';
import { Box } from '@mui/material';
import React from 'react';

/* eslint-disable-next-line */
export interface RolesCollectionPageProps {}

export const RolesCollectionPage = (props: RolesCollectionPageProps) => {
  const dataTableProps = useAdminRoles();

  const {
    data: role,
    loading: roleLoading,
    error: roleError,
  } = useRoleById(dataTableProps.editRoleId);

  const openForm =
    dataTableProps.roleFormOpen &&
    ((dataTableProps.editRoleId && role.id) || !dataTableProps.editRoleId);

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <CollectionsDataTable
        {...dataTableProps}
        height="calc(100vh - 13em)"
        paginationMode="server"
        sortingMode="server"
        onRowClick={(o) => dataTableProps.handleEdit(o.id)}
        parent="RolesCollectionPage"
      />

      {openForm && (
        <RoleForm
          isOpen={dataTableProps.roleFormOpen}
          handleClose={dataTableProps.handleRoleFormClose}
          handleSubmit={dataTableProps.handleRoleFormSave}
          role={role}
        />
      )}
    </Box>
  );
};

export default RolesCollectionPage;

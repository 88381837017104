import { useQuery } from "@apollo/client";
import { ProjectProposal } from "@cdw-selline/common/types";
import { useApolloErrors } from "../../useApolloErrors";
import { GET_SYSTEM_PROPOSAL_BY_ID_QUERY } from "@cdw-selline/ui/queries";

export const useSystemProposal = (proposalId: string, projectItemId: string, projectId: string) => {
  const { handleErrorResponse } = useApolloErrors();

  const { loading, error, data } = useQuery<{
    getSystemProposalById: ProjectProposal;
  }>(GET_SYSTEM_PROPOSAL_BY_ID_QUERY, {
    variables: { proposalId, projectItemId, projectId },
    fetchPolicy: 'network-only',
    skip: !proposalId || !projectItemId || !projectId,
    onError: (error) =>
      handleErrorResponse(
        error,
        'Failed to fetch Project Proposals by project id!'
      ),
  });
  return {
    proposal: data?.getSystemProposalById ?? {},
    loading,
    error,
  };
}
import { useMutation, useQuery } from '@apollo/client';
import {
  DefaultMutationResponse,
  CiscoAnnuity,
} from '@cdw-selline/common/types';
import {
  ADD_CISCO_ANNUITY_MUTATION,
  GET_CISCO_ANNUITY_BY_PROJECT_ITEM_ID_QUERY,
  REMOVE_CISCO_ANNUITY_QUOTE_BY_ID_MUTATION,
  RELOAD_CISCO_ANNUITY_QUOTE_BY_ID_MUTATION,
  GET_CISCO_ANNUITY_QUOTE_BY_PROJECT_ITEM_ID_QUERY,
  UPDATE_INCLUDE_IN_QUOTE_BY_PROJECT_ITEM_ID_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';

import { useApolloErrors } from '..';

export const useCiscoAnnuity = ({ projectItemId }) => {
  const alertState = useAlertsState();
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getCiscoAnnuityByProjectItemId: CiscoAnnuity;
  }>(GET_CISCO_ANNUITY_BY_PROJECT_ITEM_ID_QUERY, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    variables: { projectItemId },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Cisco Annuity!'),
  });

  const [
    removeCiscoQuoteById,
    { loading: removeCiscoQuoteLoading, error: removeCiscoQuoteError },
  ] = useMutation<{ removeCiscoAnnuityQuoteById: DefaultMutationResponse }>(
    REMOVE_CISCO_ANNUITY_QUOTE_BY_ID_MUTATION,
    {
      refetchQueries: [GET_CISCO_ANNUITY_BY_PROJECT_ITEM_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to remove Cisco Quote'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.removeCiscoAnnuityQuoteById.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully removed Cisco Quote',
          });
        }
      },
    }
  );

  const [
    reloadCiscoQuoteById,
    { loading: reloadCiscoQuoteLoading, error: reloadCiscoQuoteError },
  ] = useMutation<{ reloadCiscoAnnuityQuoteById: DefaultMutationResponse }>(
    RELOAD_CISCO_ANNUITY_QUOTE_BY_ID_MUTATION,
    {
      refetchQueries: [GET_CISCO_ANNUITY_BY_PROJECT_ITEM_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to reload Cisco Quote'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.reloadCiscoAnnuityQuoteById.success) {
          alertState.setAlert({
            type: ALERT_TYPE.MODAL,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully reloaded Cisco Quote',
          });
        }
      },
    }
  );

  const [
    updateIncludeInQuoteById,
    { loading: updateIncludeInQuoteLoading, error: updateIncludeInQuoteError },
  ] = useMutation<{ updateIncludeInQuoteById: DefaultMutationResponse }>(
    UPDATE_INCLUDE_IN_QUOTE_BY_PROJECT_ITEM_ID_MUTATION,
    {
      refetchQueries: [GET_CISCO_ANNUITY_QUOTE_BY_PROJECT_ITEM_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update Cisco Quote Lines'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
    }
  );

  return {
    ciscoAnnuity: data?.getCiscoAnnuityByProjectItemId ?? {},
    loading,
    error,
    refetch,
    removeCiscoQuoteById,
    reloadCiscoQuoteById,
    reloadCiscoQuoteLoading,
    reloadCiscoQuoteError,
    updateIncludeInQuoteById,
    updateIncludeInQuoteLoading,
    updateIncludeInQuoteError,
  };
};

export const useCiscoAnnuityAddItem = () => {
  const alertState = useAlertsState();
  const { handleErrorResponse } = useApolloErrors();

  const [
    addCiscoAnnuityItem,
    { loading: addCiscoAnnuityItemLoading, error: addCiscoAnnuityItemError },
  ] = useMutation(ADD_CISCO_ANNUITY_MUTATION, {
    onError: (error) =>
      handleErrorResponse(error, 'Failed to create a new Cisco Annuity Item'),
    onCompleted: (data) => {
      alertState.setAlert({
        type: ALERT_TYPE.SNACKBAR,
        severity: ALERT_SEVERITY.SUCCESS,
        message: 'Successfully Created New Cisco Annuity Item',
      });
    },
  });

  const handleAddCiscoAnnuityItem = async (
    newCiscoAnnuityItem: Partial<CiscoAnnuity>
  ) => {
    const addCiscoAnnuityResponse = await addCiscoAnnuityItem({
      variables: { params: newCiscoAnnuityItem },
    });
    return addCiscoAnnuityResponse;
  };

  return {
    addCiscoAnnuityItemError,
    addCiscoAnnuityItemLoading,
    handleAddCiscoAnnuityItem,
  };
};

// import { useApolloErrors } from './useApolloErrors';

// export const useProjectItemsTable = () => {
//   const [paginationState, setPaginationState] = useState({
//     offset: 0,
//     limit: 100,
//     page: 0,
//   });
//   const { data, error, loading, refetch } = useProjectItems();
//   const router = useHistory();
//   const { handleErrorResponse } = useApolloErrors();
//   const alertState = useAlertsState();

//   const [addPrjectItem, { loading: addRoleLoading, error: addRoleError }] =
//     useMutation<{ addProjectItem: DefaultAddResponse }>(
//       ADD_PROJECT_ITEM_MUTATION,
//       {
//         refetchQueries: [GET_PROJECT_ITEMS_QUERY, GET_PROJECT_ITEM_BY_ID],
//         onError: (error) =>
//           handleErrorResponse(error, 'Failed to create new project item'),
//         onCompleted: (data) => {
//           //@todo handle add complete
//           return null;
//         },
//       }
//     );

//   const [
//     removeProjectItem,
//     { loading: removeRoleLoading, error: removeRoleError },
//   ] = useMutation<{ removeProjectItemById: DefaultMutationResponse }>(
//     REMOVE_PROJECT_ITEM_BY_ID,
//     {
//       refetchQueries: [GET_PROJECT_ITEMS_QUERY],
//       onError: (error) =>
//         handleErrorResponse(error, 'Failed to remove project item'),
//       onQueryUpdated(observableQuery) {
//         return observableQuery.refetch();
//       },
//       onCompleted: (data) => {
//         if (data.removeProjectItemById.success) {
//           alertState.setAlert({
//             type: ALERT_TYPE.SNACKBAR,
//             severity: ALERT_SEVERITY.SUCCESS,
//             message: 'Successfully removed project item',
//           });
//         }
//       },
//     }
//   );

//   const handleAddPrjectItem = () => {
//     addPrjectItem({ variables: {} });
//   };

//   const handleRemoveProjectItem = (pid: string) =>
//     removeProjectItem({
//       variables: { removeProjectItemByIdId: pid },
//     });

//   // const handleEdit = (id: string) => router.push(`${ROUTES.ADMIN_ROLES}/${id}`);

//   // const handlePageChange = (page: number) => {
//   //   if (page < paginationState.page) return handlePageBack(page);
//   //   sessionStorage.offset = paginationState.offset + paginationState.limit;
//   //   sessionStorage.page = paginationState.page + 1;
//   //   setPaginationState((p) => ({
//   //     ...p,
//   //     offset: Number(sessionStorage.offset),
//   //     page: Number(sessionStorage.page),
//   //   }));
//   //   refetch({ offset: paginationState.offset, limit: paginationState.limit });
//   // };

//   // const handlePageBack = (page: number) => {
//   //   sessionStorage.offset = paginationState.offset - paginationState.limit;
//   //   sessionStorage.page = paginationState.page - 1;
//   //   setPaginationState((p) => ({
//   //     ...p,
//   //     offset: Number(sessionStorage.offset),
//   //     page: Number(sessionStorage.page),
//   //   }));
//   //   refetch({
//   //     offset: Number(sessionStorage.offset),
//   //     limit: paginationState.limit,
//   //   });
//   // };

//   // const handlePageSizeChange = (size: number) => {
//   //   setPaginationState((p) => ({
//   //     ...p,
//   //     limit: size,
//   //   }));
//   //   refetch({ offset: paginationState.offset, limit: paginationState.limit });
//   // };

//   // const handleSort = (args) => console.log(args);

//   // billingyCycle?: Maybe<Scalars['String']>;
//   // cost?: Maybe<Scalars['Float']>;
//   // createdBy?: Maybe<Scalars['String']>;
//   // createdOn?: Maybe<Scalars['DATE']>;
//   // description?: Maybe<Scalars['String']>;
//   // id: Scalars['ID'];
//   // includeInForcast?: Maybe<Scalars['Boolean']>;
//   // includeInProposal?: Maybe<Scalars['Boolean']>;
//   // includeInSow?: Maybe<Scalars['Boolean']>;
//   // mrrCost?: Maybe<Scalars['Float']>;
//   // mrrPrice?: Maybe<Scalars['Float']>;
//   // name?: Maybe<Scalars['String']>;
//   // price?: Maybe<Scalars['Float']>;
//   // projectId?: Maybe<Scalars['ID']>;
//   // quantity?: Maybe<Scalars['Int']>;
//   // terms?: Maybe<Scalars['Int']>;
//   // type?: Maybe<Scalars['String']>;
//   // vendor?: Maybe<Scalars['String']>;

//   const rows = data.map(({ id, name, type, vendor, quantity, price }) => ({
//     id,
//     name,
//     type,
//     vendor,
//     quantity,
//     price,
//     total: quantity * price,
//   }));

//   return {
//     rows: rows || ([] as GridRowsProp),
//     loading: loading,
//     error,
//     handleAddPrjectItem,
//     handleRemoveProjectItem,
//   };
// };

import React from 'react';
import { TaskGroup } from '@cdw-selline/common/types';
import { Button, Typography, Box } from '@mui/material';
import { ItemsProvidedLineItems } from '../items-provided-line-items/ItemsProvidedLineItems';
import omitDeep from 'omit-deep-lodash';

export interface ItemsProvidedToCustomersProps {
  currentTaskGroup: TaskGroup;
  handleEditorChange: (params) => void;
}

export function ItemsProvidedToCustomers(props: ItemsProvidedToCustomersProps) {
  const [lineItems, setLineItems] = React.useState(
    props.currentTaskGroup?.itemsProvidedToCustomers ?? []
  );

  const addRow = () => {
    const lineItemIds = lineItems.map((item) => item.id);
    const lastID = Math.max(0, ...lineItemIds) ?? 1;
    const emptyLineItem = {
      item: '',
      description: '',
      format: '',
      id: lastID + 1,
    };
    setLineItems([...lineItems, emptyLineItem]);
  };

  const removeRow = (key) => {
    const lineItemsCopy = [...lineItems];
    const newLineItems = lineItemsCopy.filter((item) => item.id !== key);
    setLineItems([...newLineItems]);
    props.handleEditorChange({
      itemsProvidedToCustomers: omitDeep([...newLineItems], ['__typename']),
    });
  };

  return (
    <>
      <Box sx={{ marginTop: 2, marginBottom: 2 }}>
        <Typography variant="h6">Item(s) Provided to Customers</Typography>
      </Box>
      {lineItems.map((item) => (
        <ItemsProvidedLineItems
          lineItem={item}
          removeRow={removeRow}
          handleChange={props.handleEditorChange}
          lineItemsArray={lineItems}
          setLineItems={setLineItems}
          key={`items-provided-${item.id}`}
        />
      ))}
      <Button
        variant="contained"
        sx={{ marginTop: 2, marginBottom: 2 }}
        onClick={addRow}
      >
        Add Row
      </Button>
    </>
  );
}

export default ItemsProvidedToCustomers;

import { gql } from '@apollo/client';

export const ADD_PROJECT_ITEM_MUTATION = gql`
  mutation addProjectItemMutation($params: ProjectItemInput!) {
    addProjectItem(params: $params) {
      success
      id
    }
  }
`;

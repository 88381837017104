import { gql } from '@apollo/client';

export const GET_TASK_GROUP_BY_ID_AND_VERSION_QUERY = gql`
  query getTaskGroupByIdAndVersionQuery($id: ID!, $version: Int!) {
    getTaskGroupByIdAndVersion(id: $id, version: $version) {
      name
      order
      practice
      published
      riskPercent
      sowScopeFooter
      sowScopeHeader
      lastReviewedOn
      lastReviewedBy
      id
      fixedFee
      createdAt
      createdBy
      outcomeIncluded
      outcomeSowHeader
      outcomeSowFooter
      executiveSummaryOverview
      executiveSummaryOverviewHelp
      executiveSummaryDrivers
      executiveSummaryDriversHelp
      businessOutcome
      businessOutcomeHelp
      technicalOutcome
      technicalOutcomeHelp
      outcomeProjectScope
      outcomeProjectScopePhases
      outcomeProjectScopePhasesHelp
      agiloftServiceCategory
      agiloftScopeType
      agiloftOutcomeScopeType
      agiloftDefaultPrintTemplate
      agiloftPrintTemplateTypeOptions
      agiloftManagedScopeType
      agiloftBlendedScopeType
      agiloftRecurringServicesFees
      agiloftClauses
      agiloftExhibits
      bidAssuranceUrl
      bidAssuranceGroup
      pmPercent
      pmRole
      minPmHours
      setPm
      executiveSummaryOverviewIncluded
      executiveSummaryDriversIncluded
      businessOutcomeIncluded
      technicalOutcomeIncluded
      outcomeProjectScopeIncluded
      outcomeProjectScopePhasesIncluded
      proposalContent
      taskGroupTeam {
        email
      }
      owner {
        email
      }
      coOwners {
        email
      }
      testers {
        email
      }
      itemsProvidedToCustomers {
        item
        description
        format
        id
        taskId
        operator
        value
      }
      sowSections
      status
      version
      originalTaskGroupId
      inTestingTaskGroupId
      proposalTemplateIds
    }
  }
`;

export default GET_TASK_GROUP_BY_ID_AND_VERSION_QUERY;
import {
  ProjectDetailsForm,
  ProjectItemsTable,
  ProjectStatusStepper,
  UserAccessTable,
} from '@cdw-selline/ui/components';
import { getReadOnlyMode } from '@cdw-selline/ui/helpers';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tooltip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { ProjectComments } from '../../activity/ProjectComments';
import type { ProjectPageOutletContext } from '@cdw-selline/common/types';
import { ProjectPageDrawer } from '../../ProjectPageDrawer';

export const ProjectDetailTab = () => {
  const {
    project,
    updateProject,
    updateProjectError,
    updateProjectLoading,
    id,
    toggleDrawer,
    drawerState,
  } = useOutletContext<ProjectPageOutletContext>()
  const theme = useTheme();
  const tabColor = theme.palette.secondary.main;

  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  

  if (!project) return null;
  const isReadOnly = getReadOnlyMode(project);

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        margin: '0',
        flexWrap: 'wrap',
        justifyContent: 'left',
      }}
    >
      <Grid
        item
        sx={{
          justifyContent: 'center',
          display: 'flex',
          mb: '1em',
          maxWidth: '100%',
          flexBasis: '100%',
        }}
        xs={12}
      ></Grid>

      <Grid
        item
        sx={{
          justifyContent: 'center',
          display: 'flex',
          mb: '1em',
          maxWidth: '100%',
          flexBasis: '100%',
        }}
        xs={12}
      >
        <Tooltip
          title=" View Status History"
          sx={{ position: 'absolute', right: '1em', mt: '0' }}
        >
          <IconButton onClick={() => toggleDrawer(true)}>
            <MenuOpenIcon />
          </IconButton>
        </Tooltip>
        <Card sx={{ width: '100%', p: '1em', mb: '.5em' }}>
          <CardHeader title="Status" />
          <Box sx={{ width: '100%' }}>
            <ProjectStatusStepper project={project} />
            <ProjectPageDrawer
              drawerState={drawerState}
              toggleDrawer={toggleDrawer}
            />
          </Box>
        </Card>
      </Grid>

      <Grid
        item
        xs={8}
        sx={{
          justifyContent: 'center',
          display: 'flex',
          mb: '1em',
          maxWidth: '100%',
          flexBasis: '100%',
        }}
      >
        <Card sx={{ width: '100%', p: '1em', mb: '.5em' }}>
          <Box sx={{ width: '100%' }}>
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  width: '100%',
                  display: 'flex',
                }}
              >
                <TabList
                  TabIndicatorProps={{
                    style: {
                      background: `${tabColor}`,
                    },
                  }}
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="Items"
                    value="1"
                    data-testid="project-offerings-tab-button"
                  />
                  <Tab
                    label="Details"
                    value="2"
                    data-testid="project-details-tab-button"
                  />
                  <Tab label="Chatter" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ width: '100%' }}>
                <ProjectItemsTable isReadOnly={isReadOnly}></ProjectItemsTable>
              </TabPanel>
              <TabPanel value="2" sx={{ width: '100%' }}>
                <ProjectDetailsForm project={project}></ProjectDetailsForm>
              </TabPanel>
              <TabPanel value="3" sx={{ width: '100%' }}>
                <ProjectComments projectId={id} />
              </TabPanel>
            </TabContext>
          </Box>
        </Card>
      </Grid>

      <Grid
        item
        xs={4}
        sx={{
          justifyContent: 'center',
          display: 'flex',
          mb: '1em',
          maxWidth: '100%',
          flexBasis: '100%',
        }}
      >
        <Card sx={{ width: '100%', p: '1em', mb: '.5em', ml: '.5em' }}>
          <CardHeader title="Opportunity Team" sx={{ paddingBottom: '35px' }} />
          <Box sx={{ width: '100%', display: 'flex' }}>
            <UserAccessTable
              {...{
                project,
                updateProject,
                updateProjectError,
                updateProjectLoading,
                isReadOnly
              }}
            />
          </Box>
        </Card>
      </Grid>

      <Grid
        item
        xs={12}
        md={12}
        style={{ display: 'flex', flexDirection: 'row', marginBottom: '1em' }}
      >
        <Divider />
      </Grid>
    </Grid>
  );
};

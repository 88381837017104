import { useMutation } from '@apollo/client';
import {
  ADD_PROJECT_CATEGORY_TASK_MUTATION,
  GET_PROJECT_ITEM_ESTIMATOR_TASKS_QUERY,
  GET_PROJECT_ITEM_ESTIMATOR_TASKS_TOTALS_QUERY,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';

import { useApolloErrors } from '..';

export const useAddProjectTaskCategory = () => {
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();

  const [addProjectTaskCategory, { loading, error }] = useMutation(
    ADD_PROJECT_CATEGORY_TASK_MUTATION,
    {
      refetchQueries: [
        GET_PROJECT_ITEM_ESTIMATOR_TASKS_QUERY,
        GET_PROJECT_ITEM_ESTIMATOR_TASKS_TOTALS_QUERY,
      ],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to add Project Task Category!'),
      onCompleted: (data) => {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully Added Task Category',
        });
      },
    }
  );
  interface AddTaskCategoryInput {
    projectItemId: string;
    site: string;
    category: string;
  }

  const handleAddTaskCategory = ({
    projectItemId,
    site,
    category,
  }: AddTaskCategoryInput) => {
    addProjectTaskCategory({
      variables: {
        addTasksByCategoryCategoryId: category,
        addTasksByCategoryProjectId: projectItemId,
        addTasksByCategorySite: site,
      },
    });
  };

  return {
    handleAddTaskCategory,
    loading,
    error,
  };
};

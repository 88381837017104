import { gql } from '@apollo/client';

export const UPDATE_SCOPE_ITEM_QUESTION_MUTATION = gql`
  mutation UpdateScopeItemQuestion(
    $updateScopeItemQuestionId: ID!
    $modifiedQuestion: QuestionInput!
  ) {
    updateScopeItemQuestion(
      id: $updateScopeItemQuestionId
      modifiedQuestion: $modifiedQuestion
    ) {
      success
    }
  }
`;

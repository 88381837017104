import { Task, TaskNote } from '@cdw-selline/common/types';
import React, { FormEvent, useEffect, useState } from 'react';
import DialogModal from '../../../../dialog-modal/DialogModal';
import NewNoteForm from './new-note-form/NewNoteForm';
import TaskNoteList from './task-note-list/TaskNoteList';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { titleStrings } from '@cdw-selline/ui/constants';
import { ApolloError } from '@apollo/client';
import { useTaskNoteForm } from '@cdw-selline/ui/hooks';
import { Button, Grid } from '@mui/material';

export interface TaskNoteDialogProps {
  task: Task;
  isOpen: boolean;
  handleClose: () => void;
  isNewNote?: boolean;
  projectItemId: string;
}

//TODO Convert task notest dialog to pull notes
export const TaskNoteDialog = ({
  isOpen,
  handleClose,
  task,
  isNewNote = false,
  projectItemId,
}: TaskNoteDialogProps) => {
  const [newNote, setNewNote] = useState<boolean>(isNewNote);

  const {
    handleNoteSubmit,
    addNoteLoading,
    addNoteError,
    noteType,
    handleNoteTypeChange,
    noteInput,
    handleNoteInput,
    handleFormReset,
    taskNotes,
    taskNotesLoading,
    taskNotesError,
  } = useTaskNoteForm(
    task,
    () => setNewNote(false),
    task.siteId,
    projectItemId
  );
  const [addNote, setAddNote] = useState(false);
  const [emptyNote, setEmptyNote] = useState(false);

  const handleFormClose = () => {
    handleFormReset();
    setAddNote(false);
    handleClose();
    setEmptyNote(false);
  };

  const handleNewNoteCancel = () => {
    setAddNote(false);
    setEmptyNote(false);
  };

  const handleSaveClick = (e: FormEvent) => {
    if (!noteInput) {
      setEmptyNote(true);
      return;
    }

    setEmptyNote(false);
    e.preventDefault();
    handleNoteSubmit();
    setAddNote(false);
    handleFormReset();
  };

  const getActionButtons = () => {
    return (
      <Grid container justifyContent="flex-end" spacing={2}>
        {addNote ? (
          <>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNewNoteCancel}
                data-testid="action-cancel-button"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveClick}
                data-testid="action-save-button"
              >
                Save
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleFormClose}
                data-testid="action-cancel-button"
              >
                Close
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setAddNote(true);
                }}
                data-testid="action-save-button"
              >
                Add
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  useEffect(() => {
    if (task.noteCount === 0) {
      setAddNote(true);
    }
  }, [task.noteCount, task.id]);

  return (
    <DialogModal
      {...{ isOpen, handleClose }}
      title={titleStrings.TASK_NOTES}
      action={getActionButtons()}
      maxWidthProp={'lg'}
      fullWidth={true}
    >
      {!addNote ? (
        <TaskNoteList
          {...{
            taskNotes,
            setNewNote,
            handleClose,
            taskNotesLoading,
            taskNotesError,
          }}
        />
      ) : (
        <NewNoteForm
          {...{
            addNoteLoading,
            addNoteError,
            noteType,
            handleNoteTypeChange,
            noteInput,
            handleNoteInput,
            emptyNote,
          }}
        />
      )}
    </DialogModal>
  );
};

export default TaskNoteDialog;

import { gql } from '@apollo/client';

export const UPDATE_SCOPING_SESSION_MUTATION = gql`
  mutation UpdateScopingSession(
    $updateScopingSessionId: ID!
    $modifiedScopingSession: ScopingSessionInput
  ) {
    updateScopingSession(
      id: $updateScopingSessionId
      modifiedScopingSession: $modifiedScopingSession
    ) {
      success
    }
  }
`;

import { AgiloftExpenseType } from '@cdw-selline/common/types';
import React, { useEffect, useRef, useState } from 'react';
import DialogModal from '../../dialog-modal/DialogModal';
import { Grid, Typography, Box, MenuItem } from '@mui/material';
import {
  FormCheckbox,
  FormMultiSelectAutocomplete,
  FormSelect,
  FormText,
  getActionButtons,
} from '../../formHelperFunctions';
import { ApolloError } from '@apollo/client';
import { startCase } from 'lodash';
import { useValidateAgiloftExpenseType } from '@cdw-selline/ui/hooks';

export interface AgiloftExpenseTypeFormProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (agiloftExpenseType: AgiloftExpenseType) => void;
  agiloftExpenseType?: AgiloftExpenseType | null;
  agiloftExpenseTypeLoading?: boolean;
  agiloftExpenseTypeError?: ApolloError;
}

const newAgiloftExpenseTypeDefaults: AgiloftExpenseType = {
  id: '',
  expenseTypeName: '',
  expenseClauses: [], 
};

export function AgiloftExpenseTypeForm({
  isOpen,
  handleClose,
  handleSubmit,
  agiloftExpenseType,
  agiloftExpenseTypeLoading,
  agiloftExpenseTypeError,
}: AgiloftExpenseTypeFormProps) {
  const [agiloftExpenseTypeFormData, setAgiloftExpenseTypeFormData] = useState(
    agiloftExpenseType ?? newAgiloftExpenseTypeDefaults
  );
  const [errorArray, setErrorArray] = useState([]);
  const { validateAgiloftExpenseType } = useValidateAgiloftExpenseType();
  const formRef = useRef(null);


  useEffect(() => {
    if (agiloftExpenseType) {
      setAgiloftExpenseTypeFormData({
        ...newAgiloftExpenseTypeDefaults,
        ...agiloftExpenseType,
        expenseClauses: agiloftExpenseType.expenseClauses || [], 
      });
    }
  }, [agiloftExpenseType]);

  const handleFormClose = () => {
    setAgiloftExpenseTypeFormData(newAgiloftExpenseTypeDefaults);
    setErrorArray([]);
    handleClose();
  };

  const handleSaveClick = async () => {
    const validationMessages = await validateAgiloftExpenseType(agiloftExpenseTypeFormData);
    if (validationMessages?.length > 0) {
      setErrorArray(validationMessages);
      formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      return;
    } else {
      setErrorArray([]);
      handleSubmit(agiloftExpenseTypeFormData);
      handleClose();
    }
  };

  if (agiloftExpenseTypeLoading) return <Typography>Loading form</Typography>;

  if (agiloftExpenseTypeError)
    return <Typography>Error loading form</Typography>;

  const getTitle = () => {
    return (
      (agiloftExpenseTypeFormData.id && `Edit Agiloft Expense Type`) ||
      'Add Agiloft Expense Type'
    );
  };

  return (
    <DialogModal
      fullWidth={true}
      isOpen={isOpen}
      handleClose={handleClose}
      title={getTitle()}
      action={getActionButtons(handleFormClose, handleSaveClick)}
      maxWidthProp={'lg'}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="row"
        spacing={1}
        mt={1}
        sx={{ height: '50vh' }}
        data-testid="agiloftExpenseType-grid"
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="column"
          spacing={1}
          sx={{ width: '100%' }}
          ref={formRef}
        >
          {(errorArray && errorArray.length > 0 ) && (
            <Box sx={{ padding: '5px' }}>
              {errorArray.map((error) => (
                <Typography key={`${error}`} color="error" variant="h6">
                  {startCase(error) ?? 'Please fill out missing field(s)'}
                </Typography>
              ))}
            </Box>
          )}
          <FormText
            name="expenseTypeName"
            label="Agiloft Expense Type"
            required={true}
            setFormData={setAgiloftExpenseTypeFormData}
            formData={agiloftExpenseTypeFormData}
            error={errorArray.length > 0 && !agiloftExpenseTypeFormData.expenseTypeName}
          />
          <FormMultiSelectAutocomplete
            name="expenseClauses"
            label="Agiloft Expense Clause Options"
            required={true}
            setFormData={setAgiloftExpenseTypeFormData}
            formData={agiloftExpenseTypeFormData}
            options={(agiloftExpenseTypeFormData?.expenseClauses || []).map(val => (
              { value: val, label: val }
            ))}
            error={errorArray.length > 0 && !agiloftExpenseTypeFormData.expenseClauses?.length}
          >
            {(agiloftExpenseTypeFormData?.expenseClauses || []).map((val, k) => (
              <MenuItem key={k} value={val}>
                {val}
              </MenuItem>
            ))}
          </FormMultiSelectAutocomplete>
          <FormSelect
            name="agiloftDefaultExpenseClause"
            label="Default Agiloft Expense Clause"
            setFormData={setAgiloftExpenseTypeFormData}
            formData={agiloftExpenseTypeFormData}
            required={true}
            error={errorArray.length > 0 && !agiloftExpenseTypeFormData.agiloftDefaultExpenseClause}
            >
              {(agiloftExpenseTypeFormData?.expenseClauses || []).map((value, type) => (
                <MenuItem key={type} value={value}>
                  {value}
                </MenuItem>
              ))}
          </FormSelect>
          <FormCheckbox
            name="showExpenseMiles"
            label="Show Expense Miles?"
            setFormData={setAgiloftExpenseTypeFormData}
            formData={agiloftExpenseTypeFormData}
          />
          <FormSelect
            name="managedServicesExpenseClause"
            label="Default Managed Services Expense Clause"
            setFormData={setAgiloftExpenseTypeFormData}
            formData={agiloftExpenseTypeFormData}
            required={true}
            error={errorArray.length > 0 && !agiloftExpenseTypeFormData.managedServicesExpenseClause}
            >
              {(agiloftExpenseTypeFormData?.expenseClauses || []).map((value, type) => (
                <MenuItem key={type} value={value}>
                  {value}
                </MenuItem>
              ))}
          </FormSelect>
        </Grid>
      </Grid>
    </DialogModal>
  );
}

import { gql } from '@apollo/client';

export const UPDATE_TASK_MUTATION = gql`
  mutation updateTaskMutation($params: TaskInput) {
    updateTask(params: $params) {
      success
    }
  }
`;

export const UPDATE_TASKS_MUTATION = gql`
  mutation updateTasksMutation($params: [TaskInput]) {
    updateTasks(params: $params) {
      success
    }
  }
`;

export const CREATE_TEST_VERSION_TASK_MUTATION = gql`
  mutation createTestVersionByTaskMutation($taskId: ID!) {
    createTestVersionByTask(taskId: $taskId) {
      success
    }
  }
`;

import React from 'react';
import { TaskGroup } from '@cdw-selline/common/types';
import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  List,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import {
  difference,
  find,
  isArray,
  isEqual,
  keys,
  omit,
  replace,
  snakeCase,
  startCase,
  toString,
  values,
} from 'lodash';
import detailedSow from '../../project-sow/detailed-sow.str.css';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import RichTextEditorNoState from '../../rich-text-editor/RichTextEditorNoState';
import {
  useProposalTemplates,
  useSowSections,
} from '@cdw-selline/ui/hooks';
import DialogModal from '../../dialog-modal/DialogModal';
import {
  getActionButtons
} from '../../formHelperFunctions';

export interface TaskGroupComparisonTableProps {
  originalTaskGroup: TaskGroup;
  changedTaskGroup: TaskGroup;
  roleOptions: { id: string; label: string }[];
  confirmShowChanges: boolean;
  handleShowChangesConfirmClose: () => void;
}

export function TaskGroupComparisonTable(props: TaskGroupComparisonTableProps) {
  const {
    originalTaskGroup,
    changedTaskGroup,
    roleOptions,
    confirmShowChanges,
    handleShowChangesConfirmClose
  } = props;
  const comparisonTableCellCssProps = {
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: '#ccc',
    height: '100%',
  };
  const comparisonTableCellDivCssProps = {
    maxWidth: '50%',
    display: 'block',
    TextAlign: 'center',
  };
  const comparisonTableCellInnerParent = {
    display: 'flex',
    'flex-direction': 'row',
    'justify-content': 'space-around',
  };
  const taskGroupKeyToBeExcluded = [
    'id',
    'originalTaskGroupId',
    'changedTaskGroupId',
    'status',
    'taskGroupTeam',
    'owner',
    'coOwners',
    'testers',
    'inTestingTaskGroupId',
    'version',
  ];
  const {
    data: sowSections,
  } = useSowSections({ filters: {}, offset: 0, limit: 10000 });
  const sowSectionOptions =
    sowSections.sowSections.map((sowSection) => {
      return {
        id: sowSection.id,
        label: sowSection.name,
      };
    }) ?? [];
  const {
    data: proposalTemplates,
  } = useProposalTemplates({ filters: {
    isSystem: {$ne: true}
  }, offset: 0, limit: 10000 });
  const proposalTemplatesOptions =
    proposalTemplates.proposalTemplates.map((proposalTemplate) => {
      return {
        id: proposalTemplate.id,
        label: proposalTemplate.name,
      };
    }) ?? [];
  
  const taskGroupDataSchema = {
    pmRole: {
      type: 'ref',
      dataList: roleOptions,
    },
    businessOutcome: {
      type: 'richtext',
    },
    businessOutcomeHelp: {
      type: 'richtext',
    },
    executiveSummaryOverviewHelp: {
      type: 'richtext',
    },
    executiveSummaryOverview: {
      type: 'richtext',
    },
    executiveSummaryDriversHelp: {
      type: 'richtext',
    },
    executiveSummaryDrivers: {
      type: 'richtext',
    },
    sowScopeHeader: {
      type: 'richtext',
    },
    sowScopeFooter: {
      type: 'richtext',
    },
    outcomeSowHeader: {
      type: 'richtext',
    },
    outcomeSowFooter: {
      type: 'richtext',
    },
    outcomeProjectScopePhasesHelp: {
      type: 'richtext',
    },
    outcomeProjectScopePhases: {
      type: 'richtext',
    },
    outcomeProjectScope: {
      type: 'richtext',
    },
    technicalOutcomeHelp: {
      type: 'richtext',
    },
    technicalOutcome: {
      type: 'richtext',
    },
    proposalContent: {
      type: 'richtext',
    },
    agiloftRecurringServicesFees: {
      type: 'richtext',
    },
    agiloftClauses: {
      type: 'richtext',
    },
    itemsProvidedToCustomers: {
      type: 'list',
    },
    sowSections: {
      type: 'ref',
      dataList: sowSectionOptions,
    },
    proposalTemplateIds: {
      type: 'ref',
      dataList: proposalTemplatesOptions
    }
  };

  const handleChangeOfRichTextEditor = () => {
    return;
  };

  const createListItem = (items) =>
    items.map((item, index) => (
      <ListItem key={`item-key-${index}`}>{values(item).join(' | ')}</ListItem>
    ));

  const createListItemOfArray = (items) =>
    items.map((item, index) => (
      <ListItem key={`item-key-${index}`}>{item}</ListItem>
    ));

  const omitTypeName = (item) => omit(item, ['__typename']);

  const findRelatedLabel = (dataList, taskGroupValue) =>
    (find(dataList, ['id', taskGroupValue]) || {}).label;

  const createComponentForReferenceType = (
    key,
    originalTaskGroupValue,
    changedTaskGroupValue
  ) => {
    const dataList = taskGroupDataSchema[key].dataList;
    if (isArray(originalTaskGroupValue)) {
      originalTaskGroupValue = originalTaskGroupValue.map((groupValue) =>
        findRelatedLabel(dataList, groupValue)
      );
      changedTaskGroupValue = changedTaskGroupValue.map((groupValue) =>
        findRelatedLabel(dataList, groupValue)
      );
      originalTaskGroupValue = (
        <List>{createListItemOfArray(originalTaskGroupValue)}</List>
      );
      changedTaskGroupValue = (
        <List>{createListItemOfArray(changedTaskGroupValue)}</List>
      );
    } else {
      originalTaskGroupValue = findRelatedLabel(
        dataList,
        originalTaskGroupValue
      );
      changedTaskGroupValue = findRelatedLabel(dataList, changedTaskGroupValue);
    }
    return (
      <TableRow key={key}>
        <TableCell sx={comparisonTableCellCssProps}>
          <div style={comparisonTableCellDivCssProps}>
            {startCase(replace(snakeCase(key), ' '))}
          </div>
        </TableCell>
        <TableCell sx={comparisonTableCellCssProps}>
          <div style={comparisonTableCellInnerParent}>
            <div style={comparisonTableCellDivCssProps}>
              {originalTaskGroupValue}
            </div>
            <div style={comparisonTableCellDivCssProps}>
              {' '}
              {changedTaskGroupValue}
            </div>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  const createRichTextEditorComponent = (
    key,
    originalTaskGroupValue,
    changedTaskGroupValue
  ) => {
    const richTextEditorOptions = {
      content_style: detailedSow,
      body_class: 'projectSowScope',
      menubar: '',
      width: '450px',
      toolbar: '',
    };
    const taskGroupAccordion = (
      <Accordion>
        <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
          {key}
        </AccordionSummary>
        <AccordionDetails
          sx={{ ...comparisonTableCellCssProps, height: 300, padding: 0 }}
        >
          <div style={comparisonTableCellInnerParent}>
            <div style={comparisonTableCellDivCssProps}>
              <RichTextEditorNoState
                documentId={`${key}original`}
                customEditorOptions={richTextEditorOptions}
                editorState={originalTaskGroupValue}
                disabled={true}
                handleChange={handleChangeOfRichTextEditor}
              />
            </div>
            <div style={comparisonTableCellDivCssProps}>
              <RichTextEditorNoState
                documentId={`${key}changed`}
                customEditorOptions={richTextEditorOptions}
                editorState={changedTaskGroupValue}
                disabled={true}
                handleChange={handleChangeOfRichTextEditor}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    );
    return (
      <TableRow key={key}>
        <TableCell sx={comparisonTableCellCssProps}>
          <div style={comparisonTableCellDivCssProps}>
            {startCase(replace(snakeCase(key), ' '))}
          </div>
        </TableCell>
        <TableCell sx={comparisonTableCellCssProps}>
          {taskGroupAccordion}
        </TableCell>
      </TableRow>
    );
  };

  const createListComponent = (
    key,
    originalTaskGroupValue,
    changedTaskGroupValue
  ) => {
    originalTaskGroupValue = originalTaskGroupValue.map(omitTypeName);
    changedTaskGroupValue = changedTaskGroupValue.map(omitTypeName);
    originalTaskGroupValue = (
      <List>{createListItem(originalTaskGroupValue)}</List>
    );
    changedTaskGroupValue = (
      <List>{createListItem(changedTaskGroupValue)}</List>
    );
    return (
      <TableRow key={key}>
        <TableCell sx={comparisonTableCellCssProps}>
          <div style={comparisonTableCellDivCssProps}>
            {startCase(replace(snakeCase(key), ' '))}
          </div>
        </TableCell>
        <TableCell sx={comparisonTableCellCssProps}>
          <div style={comparisonTableCellInnerParent}>
            <div style={comparisonTableCellDivCssProps}>
              {originalTaskGroupValue}
            </div>
            <div style={comparisonTableCellDivCssProps}>
              {' '}
              {changedTaskGroupValue}
            </div>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  const createComponentforAnyType = (
    key,
    originalTaskGroupValue,
    changedTaskGroupValue
  ) => {
    originalTaskGroupValue = toString(originalTaskGroupValue);
    changedTaskGroupValue = toString(changedTaskGroupValue);
    return (
      <TableRow key={key}>
        <TableCell sx={comparisonTableCellCssProps}>
          <div style={comparisonTableCellDivCssProps}>
            {startCase(replace(snakeCase(key), ' '))}
          </div>
        </TableCell>
        <TableCell sx={comparisonTableCellCssProps}>
          <div style={comparisonTableCellInnerParent}>
            <div style={comparisonTableCellDivCssProps}>
              {originalTaskGroupValue}
            </div>
            <div style={comparisonTableCellDivCssProps}>
              {' '}
              {changedTaskGroupValue}
            </div>
          </div>
        </TableCell>
      </TableRow>
    );
  };
  const keysWithArrayOfObjects = ['itemsProvidedToCustomers'];
  const taskGroupKeys = keys(originalTaskGroup);
  const taskGroupAndTestingVersionChanges = taskGroupKeys.filter((key) => {
    //Filter excluded keys
    if (taskGroupKeyToBeExcluded.includes(key)) {
      return false;
    }
    let originalTaskGroupValue = originalTaskGroup[key];
    let changedTaskGroupValue = changedTaskGroup[key];
    //Check if value is an array, hence handle the difference
    if (isArray(originalTaskGroupValue)) {
      if (keysWithArrayOfObjects.includes(key)) {
        originalTaskGroupValue = originalTaskGroupValue.map(omitTypeName);
        changedTaskGroupValue = changedTaskGroupValue.map(omitTypeName);
        if (!isEqual(originalTaskGroupValue, changedTaskGroupValue)) {
          return true;
        } else {
          return false;
        }
      } else {
        const originalGroupDifferenceValues = difference(
          originalTaskGroupValue,
          changedTaskGroupValue
        );
        const changedGroupDifferenceValues = difference(
          changedTaskGroupValue,
          originalTaskGroupValue
        );
        if (originalGroupDifferenceValues.length > 0 || changedGroupDifferenceValues.length > 0 ) {
          return true;
        } else {
          return false;
        }
      }
    }
    //Check equality for objects, strings, numbers
    if (!isEqual(originalTaskGroupValue, changedTaskGroupValue)) {
      return true;
    }
    return false;
  });
  const taskGroupComparisontableRows = taskGroupAndTestingVersionChanges.map(
    (key, index) => {
      //itemsProvidedToCustomers
      const originalTaskGroupValue = originalTaskGroup[key];
      const changedTaskGroupValue = changedTaskGroup[key];
      let data: JSX.Element;
      const taskgroupDataSchemaValue = taskGroupDataSchema[key] || {};
      switch (taskgroupDataSchemaValue.type) {
        case 'ref':
          data = createComponentForReferenceType(
            key,
            originalTaskGroupValue,
            changedTaskGroupValue
          );
          break;
        case 'richtext':
          data = createRichTextEditorComponent(
            key,
            originalTaskGroupValue,
            changedTaskGroupValue
          );
          break;
        case 'list':
          data = createListComponent(
            key,
            originalTaskGroupValue,
            changedTaskGroupValue
          );
          break;
        default:
          data = createComponentforAnyType(
            key,
            originalTaskGroupValue,
            changedTaskGroupValue
          );
          break;
      }
      return {
        id: index,
        key,
        data: data,
      };
    }
  );

  const originalVersion = originalTaskGroup.version
    ? `(Version : ${originalTaskGroup.version})`
    : ``;
  const inTestVersion = changedTaskGroup.version
    ? `(Version : ${changedTaskGroup.version})`
    : ``;
  return (
    <DialogModal
          fullWidth={true}
          title="Show changes"
          isOpen={confirmShowChanges}
          handleClose={handleShowChangesConfirmClose}
          action={getActionButtons(handleShowChangesConfirmClose)}
          maxWidthProp={'lg'}
        >
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={comparisonTableCellCssProps}>
                    <div style={comparisonTableCellDivCssProps}></div>
                  </TableCell>
                  <TableCell sx={comparisonTableCellCssProps}>
                    <div style={comparisonTableCellInnerParent}>
                      <div style={comparisonTableCellDivCssProps}>
                        Original Values {originalVersion}
                      </div>
                      <div style={comparisonTableCellDivCssProps}>
                        {' '}
                        Changed Values in test {inTestVersion}
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              {taskGroupComparisontableRows.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography>
                        No Difference in test and original task group comparison
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {taskGroupComparisontableRows.length > 0 && (
                <TableBody>
                  {taskGroupComparisontableRows.map((row, index) => (
                    <> {row.data}</>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </DialogModal>
  );
}

export default TaskGroupComparisonTable;

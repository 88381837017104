import React, { useRef } from 'react';
import 'handsontable/dist/handsontable.full.min.css';
import { HotTable, HotTableProps } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import { CellChange, ChangeSource } from 'handsontable/common';
import { HandsonTableSelectionData } from '@cdw-selline/common/types';

export interface TableTemplateProps {
  tableData: unknown[];
  tableRef: React.MutableRefObject<any>;
  customSettings?: HotTableProps;
  handleCellDataChange?: (
    changeData: CellChange,
    changeSource: ChangeSource
  ) => void;
  handleCurrentSelection?: (
    currentSelection: HandsonTableSelectionData[]
  ) => void;
}

export function TableTemplate({
  tableData,
  tableRef,
  customSettings,
  handleCellDataChange,
  handleCurrentSelection,
}: TableTemplateProps) {
  registerAllModules();
  const defaultSettings: HotTableProps = {};
  const tableSettings = { ...defaultSettings, ...customSettings };

  const handleSelectionChange = () => {
    const currentSelection = tableRef?.current?.hotInstance.getSelected();
    handleCurrentSelection?.(currentSelection);
  };

  return (
    <HotTable
      {...{
        ...tableSettings,
        ref: tableRef,
        data: tableData,
        // TODO determine if this needs to change for production
        licenseKey: 'non-commercial-and-evaluation',
        afterChange: handleCellDataChange,
        afterSelectionEnd: handleSelectionChange,
        afterDeselect: handleSelectionChange,
        tableClassName: 'table-template',
        className: 'table-cell',
      }}
    />
  );
}

export default TableTemplate;

import { gql } from '@apollo/client';

export const REMOVE_PROPOSAL_TEMPLATE_MUTATION = gql`
  mutation removeProposalTemplateMutation($id: ID!) {
    removeProposalTemplate(id: $id) {
      success
    }
  }
`;

// VARIABLES
// {
//   "id": "id"
// }

import { useMutation } from '@apollo/client';
import {
  DefaultAddResponse,
  DefaultMutationResponse,
  ScopeGroup,
} from '@cdw-selline/common/types';
import { getFilters } from '@cdw-selline/ui/helpers';
import {
  ADD_SCOPE_GROUP_MUTATION,
  GET_SCOPE_GROUPS_QUERY,
  GET_SCOPE_GROUP_BY_ID_QUERY,
  REMOVE_SCOPE_GROUP_MUTATION,
  UPDATE_SCOPE_GROUP_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { GridColDef, GridPaginationModel, GridRowsProp } from '@mui/x-data-grid';
import moment from 'moment';
import omitDeep from 'omit-deep-lodash';
import { useState } from 'react';
import { useOpenState } from '../estimator/useOpenState';
import { useApolloErrors } from '../useApolloErrors';
import { useScopeGroups } from '../useScopeGroups';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 180,
    editable: false,
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Scope Group Name',
    width: 180,
    flex: 1,
  },
  {
    field: 'practiceId',
    headerName: 'Practice',
    width: 220,
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Created Date',
    type: 'dateTime',
    width: 180,
    editable: false,
    valueGetter: (value) => value && new Date(value),
    valueFormatter: (value) =>
      value? moment(value).format('MM/DD/YYYY'): '',
  },
];

export const useAdminScopeGroups = () => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [sortState, setSortState] = useState();
  const { data, loading, error, refetch } = useScopeGroups({
    filters: getFilters('ScopeGroupsCollectionPage'),
    offset: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize,
    sort: sortState,
});
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();
  const [editScopeGroupId, setEditScopeGroupId] = useState(null);
  const {
    isOpen: scopeGroupFormOpen,
    handleClose: closeScopeGroupForm,
    handleOpen: openScopeGroupForm,
  } = useOpenState();

  const [
    addScopeGroup,
    { loading: addScopeGroupLoading, error: addScopeGroupError },
  ] = useMutation<{ addScopeGroup: DefaultAddResponse }>(
    ADD_SCOPE_GROUP_MUTATION,
    {
      refetchQueries: [GET_SCOPE_GROUPS_QUERY, GET_SCOPE_GROUP_BY_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to create new scope group!'),
      onCompleted: (data) => {
        if (data.addScopeGroup.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully added Scope Group',
          });
        }
      },
    }
  );

  const [
    removeScopeGroup,
    { loading: removeScopeGroupLoading, error: removeScopeGroupError },
  ] = useMutation<{ removeScopeGroup: DefaultMutationResponse }>(
    REMOVE_SCOPE_GROUP_MUTATION,
    {
      refetchQueries: [GET_SCOPE_GROUPS_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to remove scope group'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.removeScopeGroup.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully removed scope group',
          });
        }
      },
    }
  );

  const [
    updateScopeGroup,
    { loading: updateScopeGroupLoading, error: updateScopeGroupError },
  ] = useMutation<{ updateScopeGroup: DefaultMutationResponse }>(
    UPDATE_SCOPE_GROUP_MUTATION,
    {
      refetchQueries: [GET_SCOPE_GROUPS_QUERY, GET_SCOPE_GROUP_BY_ID_QUERY],
      awaitRefetchQueries: true,
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update scope group'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.updateScopeGroup.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully updated scope group',
          });
        }
      },
    }
  );

  const handleAdd = () => {
    openScopeGroupForm();
  };

  const handleScopeGroupFormClose = () => {
    setEditScopeGroupId(null);
    closeScopeGroupForm();
  };

  const handleDelete = (pid: string) =>
    removeScopeGroup({
      variables: { removeScopeGroupId: pid },
    });
  const handleEdit = (id: string) => {
    setEditScopeGroupId(id);
    openScopeGroupForm();
  };

  const onFilterModelChange = (filterModel) => {
    refetch({ filters: getFilters('ScopeGroupsCollectionPage') });
  };

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    const pageSize = model?.pageSize ?? paginationModel?.pageSize;
    const page = model?.page ?? paginationModel?.page;
    setPaginationModel({
      page,
      pageSize
    });
  };

  const handleScopeGroupFormSave = (scopeGroup: ScopeGroup) => {
    if (!scopeGroup.id) {
      addScopeGroup({
        variables: {
          params: omitDeep(scopeGroup, ['__typename']),
        },
      });
    }

    if (scopeGroup.id) {
      updateScopeGroup({
        variables: {
          id: scopeGroup.id,
          modifiedScopeGroup: omitDeep(scopeGroup, ['__typename', 'id']),
        },
      });
    }
  };

  const handleSort = (args) => {
    let newSort;
    if (args.length) newSort = { [args[0].field]: args[0].sort };
    setSortState(newSort);
  };
  const returnValue = {
    columns,
    rows: data.scopeGroups || [],
    handleAdd,
    handleDelete,
    handleEdit,
    handlePaginationModelChange,
    handleSort,
    onFilterModelChange,
    scopeGroups: data.scopeGroups,
    editScopeGroupId,
    addScopeGroupLoading,
    addScopeGroupError,
    removeScopeGroupLoading,
    removeScopeGroupError,
    updateScopeGroupLoading,
    updateScopeGroupError,
    loading:
      loading ||
      addScopeGroupLoading ||
      removeScopeGroupLoading ||
      updateScopeGroupLoading,
    error,
    scopeGroupFormOpen,
    handleScopeGroupFormClose,
    handleScopeGroupFormSave,
    rowCount: data.count,
  };

  if (
    loading ||
    addScopeGroupLoading ||
    removeScopeGroupLoading ||
    updateScopeGroupLoading
  ) {
    return {...returnValue, paginationModel: { pageSize: 100, page: 0 }, rowCount: 0, rows: [] };
  } else {
    return { ...returnValue, paginationModel };
  }

};

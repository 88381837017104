import { gql } from '@apollo/client';

export const GET_PROJECT_TASK_NOTES_BY_TASK_ID = gql`
  query Query($taskId: ID!) {
    getEstimatorTaskNotesByTaskId(taskId: $taskId) {
      id
      note
      noteType
      taskId
      noteType
      projectItemId
      createdAt
      createdBy
    }
  }
`;

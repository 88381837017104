import { Opportunity, OpportunityStatusEnum, BidAssuranceStatusType, ProjectItemEstimators } from '@cdw-selline/common/types';
import { getCurrentUser } from '@cdw-selline/ui/state';

export const getReadOnlyMode = (
  project: Opportunity,
  projectItem?: ProjectItemEstimators
): boolean => {
    const currentUser = getCurrentUser();
    return project?.status === OpportunityStatusEnum.Close
      || !project?.projectTeam.some(owner => owner.email === currentUser.email)
      || projectItem?.bidAssuranceStatus === BidAssuranceStatusType.Requested;
};
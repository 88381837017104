import { gql } from '@apollo/client';

export const GET_ROLE_BY_ID_QUERY = gql`
  query getRoleByIdQuery($id: ID!) {
    getRoleById(id: $id) {
      cdwRateId
      id
      practice
      projectManager
      rate
      id
      name
      agiloftRole
      owner {
        email
      }
      coOwners {
        email
      }
      testers {
        email
      }
    }
  }
`;

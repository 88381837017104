import { useQuery } from '@apollo/client';
import { CiscoSubscriptionLine } from '@cdw-selline/common/types';
import { GET_CISCO_SUBSCRIPTION_LINES } from '@cdw-selline/ui/queries';

import { useApolloErrors } from '..';

export const useCiscoSubscriptionLines = (subscriptionId: string) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getCiscoSubscriptionLinesBySubscriptionId: CiscoSubscriptionLine[];
  }>(GET_CISCO_SUBSCRIPTION_LINES, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    variables: { subscriptionId },
    skip: !subscriptionId,
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Cisco Subscription Lines!'),
  });

  return {
    lines: data?.getCiscoSubscriptionLinesBySubscriptionId,
    refetch,
    loading,
    error,
  };
};
import { useQuery } from '@apollo/client';
import { SearchUsersResponse } from '@cdw-selline/common/types';
import { SEARCH_USERS_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from '../useApolloErrors';

export const useSearchUsers = (searchTerm: string, { skip = false } = {}) => {
  const { handleErrorResponse } = useApolloErrors();
  const { data, loading, error } = useQuery<{
    searchUsers: SearchUsersResponse;
  }>(SEARCH_USERS_QUERY, {
    variables: {
      searchTerm,
    },
    skip: skip || !searchTerm,
    onError: (error) =>
      handleErrorResponse(error, 'Failed to search for Users!'),
  });

  return {
    data: data?.searchUsers?.users ?? [],
    loading,
    error,
  };
};

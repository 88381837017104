import { useState } from 'react';
import { useEstimatorTasksSowSections } from '../useEstimatorTasksSowSections';

export const useOutcomeSow = (id: string, projectItemId: string) => {
  const [outcomeSowModalOpen, setOutcomeSowModalOpen] = useState(false);
  const { updateProjectSow, updateProjectSowLoading } =
    useEstimatorTasksSowSections(id, projectItemId);

  const handleOutcomeSowSubmit = (data) => {
    updateProjectSow({
      variables: {
        params: data,
      },
    });
    setOutcomeSowModalOpen(false);
  };

  return {
    outcomeSowModalOpen,
    setOutcomeSowModalOpen,
    handleOutcomeSowSubmit,
    updateProjectSowLoading,
  };
};

export default useOutcomeSow;

import { gql } from '@apollo/client';

export const GET_ADMIN_SERVICE_SKILLS = gql`
  query getServiceSkillsQuery(
    $filters: MongoFilter
    $limit: Int
    $offset: Int
    $sort: MongoFilter
  ) {
    getServiceSkills(
      filters: $filters
      limit: $limit
      offset: $offset
      sort: $sort
    ) {
      count(filters: $filters)
      serviceSkills {
        id
        name
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const GET_PROJECT_ITEM_ESTIMATOR_TASKS_QUERY = gql`
  query getProjectItemEstimatorTasks(
    $getProjectItemEstimatorTasksProjectId: ID!
  ) {
    getProjectItemEstimatorTasks(
      projectItemId: $getProjectItemEstimatorTasksProjectId
    ) {
      sites {
        name
        address
        floor
        quantity
        tasks {
          id
          allowMarkup
          category
          comment
          cost
          costType
          defaultCost
          defaultGrossProfit
          edc
          excludeToggle
          exclude
          grossProfit
          minGrossProfit
          dropDownIndex
          dropDownIndexFormula
          disableQuantity
          disableHours
          disableQuantityFormula
          disableCost
          disableCostFormula
          disableHoursFormula
          hideShowFormula
          quantityFormula
          hoursFormula
          costFormula
          volumePricing {
            minQuantity
            maxQuantity
            cost
          }
          volumePricingEnabled
          hide
          hours
          id
          lastReviewedOn
          listNames
          listValues
          name
          noValue
          yesValue
          order
          overtime
          # phases {
          #   name
          #   taskPercent
          # }
          practice
          practiceId
          primaryPercent
          primaryRole
          primaryRoleId
          primaryRoleName
          proposalNotes
          quantity
          quantityMin
          quantityMax
          rate
          renderIndex
          roles
          secondaryPercent
          secondaryRole
          secondaryRoleName
          secondaryRoleId
          tertiaryPercent
          tertiaryRole
          tertiaryRoleName
          tertiaryRoleId
          sowSectionId
          tiers
          tier
          managedServices
          subType
          taskGroupId
          taskId
          totalHours
          type
          units
          custom
          version
        }
      }
    }
  }
`;

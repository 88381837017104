import React, { useState } from 'react';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { usePractices } from '@cdw-selline/ui/hooks';

/* eslint-disable-next-line */
export interface PracticesSelectProps {
  selectedPracticeId: string;
  handlePracticeSelect: (e: SelectChangeEvent) => void;
}

export function PracticesSelect({
  selectedPracticeId,
  handlePracticeSelect,
}: PracticesSelectProps) {
  const {
    data: practicesData,
    loading: practicesLoading,
    error: practicesError,
  } = usePractices({});

  return (
    <FormControl fullWidth>
      <InputLabel id="practice-select-label">Practice</InputLabel>
      {practicesLoading ? (
        <CircularProgress />
      ) : practicesError ? (
        <Typography color="error">Error loading practices</Typography>
      ) : (
        <Select
          labelId="practice-select-label"
          id="practice-select"
          label="Practice"
          value={selectedPracticeId}
          onChange={handlePracticeSelect}
          inputProps={{ 'data-testid': 'scope-item-practice-select' }}
        >
          {practicesData.practices.length > 0 &&
            practicesData.practices.map((practice) => (
              <MenuItem
                key={practice.id}
                value={practice.id}
                data-testid="practices-select-option"
              >
                {practice.name}
              </MenuItem>
            ))}
        </Select>
      )}
    </FormControl>
  );
}

export default PracticesSelect;

import React, { useState, useEffect } from 'react';
import {
  TableCell,
  TableRow,
  TextField,
  Typography,
  InputAdornment,
} from '@mui/material';
import { RmsGpAnalysisTableDataLineItems } from '@cdw-selline/common/types';
import { formatCurrency } from '@cdw-selline/ui/helpers';

export interface RmsGPAnalysisLineItemProps {
  lineItem: RmsGpAnalysisTableDataLineItems;
  classes: Record<string, string>;
  unitGPUpdate: (updatedGP) => void;
  isManagedServiceAdmin: boolean;
  siteId: string;
  isReadOnly?: boolean;
}

export function RmsGPAnalysisLineItem({
  lineItem,
  classes,
  unitGPUpdate,
  isManagedServiceAdmin,
  siteId,
  isReadOnly,
}: RmsGPAnalysisLineItemProps) {
  const [gp, setGp] = useState(lineItem.unitGP);
  const [gpPercent, setGpPercent] = useState(lineItem.unitGPPercent);

  useEffect(() => {
    setGp(lineItem.unitGP);
    setGpPercent(lineItem.unitGPPercent);
  }, [lineItem]);

  return (
    <TableRow key={`${lineItem.name}`}>
      <TableCell className={classes.border1} align="left">
        <Typography variant="body1">{lineItem.name}</Typography>
      </TableCell>
      <TableCell
        className={classes.border1}
        align="center"
        sx={{ width: '8%' }}
      >
        {lineItem.sku}
      </TableCell>
      <TableCell className={classes.border1} align="right" sx={{ width: '8%' }}>
        {formatCurrency(lineItem.unitCost)}
      </TableCell>
      <TableCell className={classes.border1} align="right" sx={{ width: '8%' }}>
        {!lineItem.minGrossProfit ||
        lineItem.grossProfit > 0 ||
        !isManagedServiceAdmin ? (
          `${formatCurrency(lineItem.unitGP)}`
        ) : (
          <TextField
            size="small"
            type="number"
            onWheel={(event) => (event.target as HTMLInputElement).blur()}
            value={gp}
            onChange={(e) => setGp(Number(e.target.value))}
            onBlur={() => {
              unitGPUpdate({
                minGrossProfit: gp,
                id: lineItem.id,
                cost: lineItem.unitCost,
                siteId: siteId,
              });
            }}
            disabled={isReadOnly}
            name="unitGP"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        )}
      </TableCell>
      <TableCell className={classes.border1} align="right" sx={{ width: '8%' }}>
        {!lineItem.minGrossProfit ||
        lineItem.grossProfit > 0 ||
        !isManagedServiceAdmin ? (
          `${lineItem.unitGPPercent}%`
        ) : (
          <TextField
            size="small"
            type="number"
            onWheel={(event) => (event.target as HTMLInputElement).blur()}
            value={gpPercent}
            onChange={(e) => setGpPercent(Number(e.target.value))}
            onBlur={() => {
              unitGPUpdate({
                minGrossProfitPercent: gpPercent,
                id: lineItem.id,
                siteId: siteId,
              });
            }}
            disabled={isReadOnly}
            name="unitGPPercent"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        )}
      </TableCell>
      <TableCell className={classes.border1} align="right" sx={{ width: '8%' }}>
        {formatCurrency(lineItem.unitPrice)}
      </TableCell>
      <TableCell
        className={classes.border1}
        align="center"
        sx={{ width: '4%' }}
      >
        {lineItem.qty}
      </TableCell>
      <TableCell className={classes.border1} align="right" sx={{ width: '8%' }}>
        {formatCurrency(lineItem.totalCost)}
      </TableCell>
      <TableCell className={classes.border1} align="right" sx={{ width: '8%' }}>
        {formatCurrency(lineItem.totalGP)}
      </TableCell>
      <TableCell className={classes.border1} align="right" sx={{ width: '8%' }}>
        {formatCurrency(lineItem.totalPrice)}
      </TableCell>
    </TableRow>
  );
}

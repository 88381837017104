import {
  getAxiosInstance,
  getFirebaseApp,
  getFeatureFlags,
} from '@cdw-selline/ui/common';
import { createState, none, State, useState } from '@hookstate/core';
import axios, { Canceler } from 'axios';
import {
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  SAMLAuthProvider,
} from 'firebase/auth';
import { omit } from 'lodash';
import { TENANT } from '@cdw-selline/ui/constants';
import { client } from '@cdw-selline/ui/apollo-client';
import { User } from '@cdw-selline/common/types';
import { GET_MY_USER, UPDATE_USER_MUTATION } from '@cdw-selline/ui/queries';
import omitDeep from 'omit-deep-lodash';

// should be global variable
const UserStatePersistenceId = Symbol('UserStatePersistenceId');
export const PERSISTENCE_KEY_USER = `state-user-data`;
const SAML_PROVIDER_ID = process.env.NX_AZURE_SAML_PROVIDER_ID;
// const STATE_REFERENCE_USER = `User State`;

// Items we will need across the lib in general
const axiosInstance = getAxiosInstance();
export const fbApp = getFirebaseApp();
export const fbAuth = getAuth(fbApp);

export interface StateUserData {
  loginPromise: boolean;
  userDataPromise: boolean;
  errors?: string[];
  loggedIn?: boolean;
  displayName: string | null;
  email: string | null;
  phoneNumber: string | null;
  photoURL: string | null;
  uid: string | null;
  // User data from database
  settings: {
    darkMode: boolean;
  } | null; // TODO : Settings needs to be typed properly
  featureFlags?: Record<string, boolean>;
  admin?: boolean;
  managedServicesAdmin?: boolean;
  contentAdmin?: boolean;
}

const getEmptyUserData = (): StateUserData => ({
  loginPromise: false,
  userDataPromise: false,
  loggedIn: false,
  displayName: '',
  email: '',
  phoneNumber: '',
  photoURL: '',
  uid: '',
  settings: null,
});

// Data that is not persisted across sessions
const doNotPersist: Partial<keyof StateUserData>[] = [
  'loginPromise',
  'userDataPromise',
];

export function getPersistedUserState(
  key = PERSISTENCE_KEY_USER,
  initState = getEmptyUserData()
): StateUserData {
  const persisted = localStorage?.getItem(PERSISTENCE_KEY_USER);
  if (!persisted) {
    savePersistedUserState(initState, key);
  }
  return persisted ? JSON.parse(persisted) : getEmptyUserData();
}

function savePersistedUserState(newState: StateUserData, key: string) {
  localStorage?.setItem(key, JSON.stringify(omit(newState, doNotPersist)));
}

// Set up state object
export const userState = createState<StateUserData>(
  getPersistedUserState(PERSISTENCE_KEY_USER)
);
userState.attach(() => ({
  id: UserStatePersistenceId,
  init: (s) => {
    return {
      onSet: (data) => {
        savePersistedUserState(data.state, PERSISTENCE_KEY_USER);
      },
    };
  },
}));
export const useUserState = () => useState(userState);

export const getUserState = (userState: State<StateUserData>) => ({
  get: () => {
    let currentUserObj: StateUserData = getEmptyUserData();

    if (process.env.NX_CI_TEST || process.env.NX_CI_E2E_TEST) {
      return {
        ...currentUserObj,
        displayName: 'Test User',
        email: 'test@test.com',
      };
    }

    try {
      currentUserObj = JSON.parse(JSON.stringify(userState.get()));
    } catch (e) {
      console.error(e);
    }

    return currentUserObj;
  },
});

export const getCurrentUser = () => getUserState(userState).get();

const updateUserData = async (user: Partial<User>) => {
  const result = await client.mutate({
    mutation: UPDATE_USER_MUTATION,
    variables: {
      params: {
        ...omitDeep(user, '__typename'),
      },
    },
  });

  return result;
};

// Set up change listener
onAuthStateChanged(fbAuth, async (user) => {
  if (user?.uid) {
    const dbUser = await getDbUserData();

    const token = await getToken();
    localStorage.token = token;
    const { email, phoneNumber, photoURL, uid } = user;
    let { displayName } = user;

    if (
      user.providerData[0].providerId &&
      user.providerData[0].providerId === SAML_PROVIDER_ID
    ) {
      const idTokenResult = await user.getIdTokenResult();
      const claims = idTokenResult.claims.firebase['sign_in_attributes'];
      displayName = claims.displayName;
    }

    const featureFlags = await getFeatureFlags();

    userState.merge({
      loggedIn: true,
      displayName,
      email,
      phoneNumber,
      photoURL,
      uid,
      featureFlags,
      admin: dbUser?.admin ?? false,
      managedServicesAdmin: dbUser?.managedServicesAdmin ?? false,
      contentAdmin: dbUser?.contentAdmin ?? false,
    });

    await updateUserData({
      email: email,
      displayName: displayName,
    });
  } else {
    if (userState.loggedIn.value) {
      // TODO Set up some sort of alert/snackbar
      // emitAppStatus({
      //   message: 'You have been signed out.',
      //   reference: STATE_REFERENCE_USER,
      //   type: APP_STATUS_TYPE.NOTIFICATION,
      // });
    }
    signOut().catch(console.error);
  }
});

export async function signOut(
  initState: Partial<StateUserData> = { loggedIn: false }
) {
  if (userState.loggedIn.value) {
    userState.merge({
      ...omit(getEmptyUserData(), doNotPersist),
      ...initState,
    });
    if (fbAuth.currentUser) {
      await fbAuth.signOut().catch((err) => {
        console.error(err);
        // TODO: Emit a snackbar
      });
    }
  }
}

export async function getToken(
  forceRefresh = false
): Promise<string | undefined> {
  const token = await fbAuth.currentUser?.getIdToken(forceRefresh);
  // if (token) localStorage.token = token;
  return token;
}

let cancelGetUser: Canceler | undefined = undefined;
export async function getUserData(): Promise<void> {
  if (cancelGetUser) {
    cancelGetUser();
    cancelGetUser = undefined;
  }
  userState.userDataPromise.set(true);
  if (!fbAuth.currentUser?.uid) return;
  try {
    const result = await axiosInstance.get<any>('/account', {
      responseType: 'json',
      cancelToken: new axios.CancelToken((c) => (cancelGetUser = c)),
    });
    // const userData = UserZ.parse(result.data.payload); // TODO : Good use for Zod validation
    const userData = result.data.payload;
    userState.merge({
      userDataPromise: false,
      settings: userData || none,
    });
  } catch (err) {
    // TODO: Emit a warning/snackbar
    // emitAppStatus({
    //   message: 'Cannot read account settings',
    //   details: err.message,
    //   reference: STATE_REFERENCE_USER,
    //   type: APP_STATUS_TYPE.ERROR,
    // });
  }
}

const getDbUserData = async () => {
  const result = await client.query({
    query: GET_MY_USER,
    fetchPolicy: 'network-only',
  });

  return result.data.me;
};

//TODO Clean up bellow lines for oAuth once not needed
// export const msProvider = new OAuthProvider('microsoft.com');
// msProvider.setCustomParameters({
//   tenant: TENANT,
// });
export async function signInMicrosoft() {
  await signOut({
    loginPromise: true,
    loggedIn: false,
  });
  await signInWithPopup(fbAuth, new SAMLAuthProvider(SAML_PROVIDER_ID))
    .then(async (result) => {
      userState.loginPromise.set(false);
      localStorage.setItem('token', await getToken());
    })
    .catch((err) => {
      userState.loginPromise.set(false);
      // TODO : Emit a warning/snackbar
      console.error(err);
    });
}

export async function signInGoogle() {
  await signOut({
    loginPromise: true,
    loggedIn: false,
  });
  await signInWithPopup(fbAuth, new GoogleAuthProvider())
    .then(async () => {
      userState.loginPromise.set(false);
      localStorage.setItem('token', await getToken());
    })
    .catch((err) => {
      userState.loginPromise.set(false);
      // TODO : Emit a warning/snackbar
      console.error(err);
      // emitAppStatus({
      //   message: 'Unable to sign in',
      //   details: 'Invalid email or password', // TODO Make better messages with i18
      //   reference: STATE_REFERENCE_USER,
      //   type: APP_STATUS_TYPE.ERROR,
      // });
    });
}

//////  LEGACY DATA  //////
export interface UserSchema {
  id: string;
  fetchInProgress: boolean;
  username?: string;
  name: string;
  roles: string[];
  scopes: string[];
  token?: string;
  tokenLastRefreshed?: number; // Unix datetime of when the token was last refreshed
  error?: Error;
  isLoading: boolean;
  photo?: string;
  accessLevel?: number;
  exp?: number;
  code?: string;
  idToken?: string;
  email?: string;
}

export const initialUserState: UserSchema = JSON.parse(
  JSON.stringify({
    id: '',
    fetchInProgress: false,
    username: '',
    name: '',
    roles: [],
    scopes: [],
    token: null,
    // parsedToken: null,
    error: null,
    isLoading: false,
    photo: '',
    exp: null,
    accessLevel: null,
    code: null,
    idToken: null,
    email: null,
  })
);

import { gql } from '@apollo/client';

export const UPDATE_PROJECT_STATUS_MUTATION = gql`
  mutation Mutation($updateProjectStatusParams: UpdateProjectStatusInput!) {
    updateProjectStatus(params: $updateProjectStatusParams) {
      success
    }
  }
`;

export default UPDATE_PROJECT_STATUS_MUTATION;

// Variables
// {
//   "updateProjectStatusParams": {
//     "id": "61395b26810c3051786cdd7b",
//     "status": "peerReview"
//   }
// }

import { useMutation } from '@apollo/client';
import {
    AdminConfigSettingsInput,
} from '@cdw-selline/common/types';
import {
    GET_ADMIN_CONFIG_SETTINGS,
    UPDATE_ADMIN_CONFIG_SETTINGS_MUTATION,
} from '@cdw-selline/ui/queries';
import { useState, useEffect } from 'react';
import { ADMIN_CONFIG_SETTINGS_KEYS } from '@cdw-selline/ui/constants';

import { useApolloErrors } from '..';
import { datagridPagination } from '@cdw-selline/ui/helpers';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { useOpenState } from '../estimator/useOpenState';
import { useProjectConfigSettings } from '../useProjectConfigSettings'

const columns: GridColDef[] = [
  {
    field: 'category',
    headerName: 'Category',
    width: 350,
    flex: 1,
  },
  {
    field: 'key',
    headerName: 'Key',
    width: 350,
    flex: 1,
  },
];

export const useAdminConfigSettings = () => {
  const [editAdminConfigId, setEditAdminConfigId] = useState(null);
  const alertState = useAlertsState();

  const {
    isOpen: formOpen,
    handleClose: closeForm,
    handleOpen: openForm,
  } = useOpenState();

  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useProjectConfigSettings(
    {},
    true
  );

  const getTableRows= (adminConfig: AdminConfigSettingsInput[])=> (
    adminConfig.map((config)=>[config.category, config.key])
  );

  const [tableData, setTableData] = useState([]);
  const [rows, setRows] = useState(ADMIN_CONFIG_SETTINGS_KEYS);

  useEffect(() => {
    if (!loading) {
      const adminConfigValues = data?.adminConfigSettings || [];
      const adminConfigsList = ADMIN_CONFIG_SETTINGS_KEYS.map((config)=>(
        {...config, value: adminConfigValues?.find(value=>value.category === config.category && value.key === config.key)?.value || ''}
      ));
      setRows(adminConfigsList);
      setTableData(getTableRows(adminConfigsList));
    }
  }, [data?.adminConfigSettings]);

  const [
    updateAdminConfigSettings,
    {
      loading: updateConfigSettingsLoading,
      error: updateConfigSettingsError,
    },
  ] = useMutation(UPDATE_ADMIN_CONFIG_SETTINGS_MUTATION, {
    refetchQueries: [GET_ADMIN_CONFIG_SETTINGS],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to update Admin Config Settings!'),
    onCompleted: (data) => {
      if (data.updateAdminConfigSettings.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully updated admin config settings!',
        });
      }
    },
  });

  const handleFormClose = () => {
    setEditAdminConfigId(null);
    closeForm();
  };

  const handleEdit = (id: string) => {
    setEditAdminConfigId(id);
    openForm();
  };


  const handleFormSave = (
    configSettings: AdminConfigSettingsInput
  ) => {
        updateAdminConfigSettings({
          variables: {
            params: {
              category: configSettings.category,
              key: configSettings.key,
              value: configSettings.value,
            },
          },
      });
  };

  const {
    handlePaginationModelChange,
    handleSort,
    paginationModel
  } = datagridPagination(refetch, {});

  const returnValue = {
    rows: rows || [],
    refetch,
    loading,
    error,
    tableData,
    handlePaginationModelChange,
    handleSort,
    handleEdit,
    handleFormSave,
    handleFormClose,
    columns,
    rowCount: rows?.length ?? 0,
    editAdminConfigId,
    formOpen,
    updateConfigSettingsLoading,
    updateConfigSettingsError,
  };

  if (loading || updateConfigSettingsLoading) {
    return {...returnValue, paginationModel: { pageSize: 100, page: 0 }, rowCount: 0, rows: [] };
  } else {
    return { ...returnValue, paginationModel };
  }

};
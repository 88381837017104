// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  CollectionsDataTable,
  EmailTemplatesForm,
} from '@cdw-selline/ui/components';
import { useEmailTemplates } from '@cdw-selline/ui/hooks';
import { Box } from '@mui/material';
import React from 'react';

export const EmailTemplatesCollectionPage = () => {
  const dataTableProps = useEmailTemplates({});

  const emailTemplateSettings: any = dataTableProps.rows.find(
    (row) => row.id === dataTableProps.editEmailTemplateId
  );
  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <CollectionsDataTable
        {...dataTableProps}
        height="calc(100vh - 13em)"
        parent="EmailTemplatesCollectionPage"
        paginationMode="server"
        onRowClick={(o) => dataTableProps.handleEdit(o.id)}
        handleAdd={dataTableProps.openForm}
        allowAddNew={true}
        allowFilter={false}
      />
      {dataTableProps.formOpen && (
        <EmailTemplatesForm
          isOpen={dataTableProps.formOpen}
          handleClose={dataTableProps.handleFormClose}
          handleSubmit={dataTableProps.handleFormSave}
          emailTemplateSettings={emailTemplateSettings}
        />
      )}
    </Box>
  );
};
export default EmailTemplatesCollectionPage;

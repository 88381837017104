import { useState } from 'react';

export const useOpenState = (state?: boolean) => {
  const [isOpen, setIsOpen] = useState(state || false);
  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  return {
    isOpen,
    handleClose,
    handleOpen,
  };
};

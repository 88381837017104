import { gql } from '@apollo/client';

export const SEARCH_SERVICE_ITEMS_QUERY = gql`
  query SearchServiceItems($searchTerm: String!) {
    searchServiceItems(searchTerm: $searchTerm) {
      serviceItems {
        id
        name
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const GET_CISCO_CCW_ESTIMATE_BY_ID_QUERY = gql`
  query GetCiscoCcwEstimateById(
    $ciscoAnnuityId: String!
    $ciscoEstimateId: String!
  ) {
    getCiscoCcwEstimateById(
      ciscoAnnuityId: $ciscoAnnuityId
      ciscoEstimateId: $ciscoEstimateId
    ) {
      error
      success
    }
  }
`;

import { SignIn } from '@cdw-selline/ui/components';
import firebase from 'firebase/auth';
import React from 'react';
import { useSignInPage } from './useLoginPage';

export interface LoginPageAdminProps {
  user?: firebase.User;
}

export const LoginPageAdmin = (props?: LoginPageAdminProps) => {
  const signInPageProps = useSignInPage();
  const pageProps = { ...props, ...signInPageProps };
  return <SignIn {...pageProps} appTitle={'Ultron Admin'} />;
};

export default LoginPageAdmin;

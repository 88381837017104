import { gql } from '@apollo/client';

export const ADD_CISCO_ANNUITY_COMMENT_MUTATION = gql`
  mutation addCiscoAnnuityComment(
    $ciscoAnnuityId: ID!
    $ciscoAnnuityComment: CiscoAnnuityCommentInput!
  ) {
    addCiscoAnnuityComment(
      ciscoAnnuityId: $ciscoAnnuityId
      ciscoAnnuityComment: $ciscoAnnuityComment
    ) {
      success
    }
  }
`;
import { useQuery } from '@apollo/client';
import { ProjectItemQuote } from '@cdw-selline/common/types';
import {
  GET_PROJECT_ITEM_QUOTES_BY_PROJECT_ITEM_ID_QUERY,
  GET_PROJECT_ITEM_QUOTE_BY_ID_QUERY,
} from '@cdw-selline/ui/queries';
import { useApolloErrors } from '../useApolloErrors';

export const useGetProjectItemQuotesByProjectItemId = (
  projectItemId: string
) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery<{
    getProjectItemQuotesByProjectItemId: ProjectItemQuote;
  }>(GET_PROJECT_ITEM_QUOTES_BY_PROJECT_ITEM_ID_QUERY, {
    variables: { projectItemId },
    onError: (error) =>
      handleErrorResponse(
        error,
        'Failed to fetch Project Quotes by project item id!'
      ),
  });
  return {
    data: data?.getProjectItemQuotesByProjectItemId ?? null,
    loading,
    error,
  };
};

export const useGetProjectItemQuoteById = (quoteId: string) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery<{
    getProjectItemQuoteById: ProjectItemQuote;
  }>(GET_PROJECT_ITEM_QUOTE_BY_ID_QUERY, {
    variables: { getProjectItemQuoteByIdId: quoteId },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Project Quote!'),
  });
  return {
    data: data?.getProjectItemQuoteById,
    loading,
    error,
  };
};

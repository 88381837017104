import React from 'react';
import { useTaskById, useAdminTasks} from '@cdw-selline/ui/hooks';
import { TaskForm } from '../../task-form/TaskForm';

const EditTaskForm = ({
  taskId,
  setTaskId,
}: {
  taskId: string;
  setTaskId: (v: undefined) => void;
}) => {
  const {
    data: task,
    loading: taskLoading,
    error: taskError,
  } = useTaskById(taskId);

  const { handleTaskFormSave, handleTaskFormTestVersion } = useAdminTasks();

  const handleCloseOpenMode = () => setTaskId(undefined);
  const handleSubmit = (task) =>{
    handleTaskFormSave(task)
    handleCloseOpenMode()
  }

  return (
    <TaskForm
      isOpen={true}
      handleClose={handleCloseOpenMode}
      handleSubmit={handleSubmit}
      handleTestVersion={handleTaskFormTestVersion}
      task={task}
      taskLoading={taskLoading}
      taskError={taskError}
    />
  );
};

export default EditTaskForm;

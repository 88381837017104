import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  DefaultMutationResponse,
  DefaultMutationResponseWithId,
  ProjectItem,
} from '@cdw-selline/common/types';
import {
  GET_PROJECT_ITEMS_QUERY,
  GET_PROJECT_ITEM_QUERY,
  ADD_PROJECT_ITEM_MUTATION,
  UPDATE_PROJECT_ITEM_MUTATION,
  GET_PROJECT_ITEM_BY_ID,
  GET_PROJECT_QUERY,
  GET_PROJECT_ITEM_VENDORS,
  GET_PROJECT_ITEM_SOLUTIONS,
} from '@cdw-selline/ui/queries';

import { useApolloErrors } from '.';

import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';

export const useProjectItems = () => {
  const alertState = useAlertsState();
  const { handleErrorResponse } = useApolloErrors();
  const { id } = useParams<{ id: string }>();

  const { loading, error, data, refetch } = useQuery<{
    getProjectItems: ProjectItem[];
  }>(GET_PROJECT_ITEMS_QUERY, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    variables: { projectId: id },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Project Items!'),
  });

  const [
    createProjectItem,
    { loading: createProjectLoading, error: createProjectError },
  ] = useMutation<{ addProjectItem: DefaultMutationResponseWithId }>(
    ADD_PROJECT_ITEM_MUTATION,
    {
      refetchQueries: [
        GET_PROJECT_ITEMS_QUERY,
        GET_PROJECT_QUERY,
        GET_PROJECT_ITEM_VENDORS,
        GET_PROJECT_ITEM_SOLUTIONS,
      ],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to create new project'),
    }
  );

  const [
    updateProjectItem,
    { loading: updateProjectLoading, error: updateProjectError },
  ] = useMutation<DefaultMutationResponse>(UPDATE_PROJECT_ITEM_MUTATION, {
    refetchQueries: [
      GET_PROJECT_ITEMS_QUERY,
      GET_PROJECT_ITEM_BY_ID,
      GET_PROJECT_QUERY,
      GET_PROJECT_ITEM_VENDORS,
      GET_PROJECT_ITEM_SOLUTIONS,
    ],
    awaitRefetchQueries: true,
    onError: (error) =>
      handleErrorResponse(
        error,
        `Failed to update project item: ${error.message}`,
        ALERT_TYPE.MODAL
      ),
    onCompleted: (data) => {
      if (data.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully Updated Project Item',
        });
      }
    },
  });

  return {
    data: data?.getProjectItems ?? [],
    error,
    loading,
    refetch,
    createProjectItem,
    updateProjectItem,
  };
};

// TODO update naming when updating routes for estimates and quotes to make this cleaner
export const useProjectItem = (id?: string) => {
  const { handleErrorResponse } = useApolloErrors();
  const projectItemId = id;

  const { loading, error, data } = useQuery<{
    getProjectItem: ProjectItem;
  }>(GET_PROJECT_ITEM_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: { id: projectItemId },
    skip: !projectItemId,
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Project Items!'),
  });

  return {
    data: data?.getProjectItem,
    error,
    loading,
  };
};

import { CiscoAnnuity, CiscoQuote } from '@cdw-selline/common/types';
import { Paper, Grid, MenuItem, TextField, Box } from '@mui/material';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import React, { useState } from 'react';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useCiscoAnnuityQuote } from '@cdw-selline/ui/hooks';
import { CiscoAnnuityQuote } from './CiscoAnnuityQuote';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  getCiscoAnnuityActiveQuote,
  formatDate,
} from '@cdw-selline/ui/helpers';
import { FormSelect, FormText, FormCheckbox } from '../../formHelperFunctions';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CISCO_ANNUITY_TERM_LENGTHS } from '@cdw-selline/ui/constants';

const useStyles = makeStyles(() =>
  createStyles({
    labelRoot: {
      right: 0,
    },
    shrink: {
      transformOrigin: 'top right',
    },
  })
);

export interface CiscoAnnuityQuoteInfoProps {
  ciscoAnnuity: CiscoAnnuity;
  isReadOnly: boolean;
}

export function CiscoAnnuityQuoteInfo({
  ciscoAnnuity,
  isReadOnly,
}: CiscoAnnuityQuoteInfoProps) {
  const activeQuote = getCiscoAnnuityActiveQuote(ciscoAnnuity);
  const theme = useTheme();
  const classes = useStyles();
  const { handleCiscoAnnuityQuoteUpdate } = useCiscoAnnuityQuote();

  const setFormData = (updatedFormData) => {
    handleUpdate(updatedFormData);
  };

  const handleUpdate = (updatedFormData: Partial<CiscoQuote>): void => {
    handleCiscoAnnuityQuoteUpdate(
      ciscoAnnuity.id,
      ciscoAnnuity.activeCiscoQuote,
      updatedFormData
    );
  };

  return (
    <>
      <TextField
        value={activeQuote.quoteId}
        label="Estimate/Deal ID"
        type="text"
        variant="standard"
        disabled={true}
        size="small"
        style={{ width: '100%' }}
      />
      <FormText
        name="name"
        label="Quote Name"
        setFormData={setFormData}
        formData={activeQuote}
        variant="standard"
        width="100%"
        disabled={isReadOnly}
      />
      <TextField
        value={activeQuote.status}
        label="Status"
        type="text"
        variant="standard"
        disabled={true}
        size="small"
        style={{ width: '100%' }}
      />
      <TextField
        value={formatDate(activeQuote.expires, 'MM/dd/yyyy')}
        label="Expires"
        type="text"
        variant="standard"
        disabled={true}
        size="small"
        style={{ width: '100%' }}
      />
    </>
  );
}

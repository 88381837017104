/* eslint-disable @nx/enforce-module-boundaries */
import { useTravelExpenseSummary } from '@cdw-selline/ui/hooks';
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Typography,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { formatCurrency } from '@cdw-selline/ui/helpers';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TravelExpenseSummaryProps {}

export const TravelExpenseSummary: React.FC<TravelExpenseSummaryProps> = (
  props
) => {
  const { travelExpenseSummary, error, loading } = useTravelExpenseSummary();
  const theme = useTheme();

  if (!travelExpenseSummary || travelExpenseSummary.travelExpenseTotal <= 0) {
    return null;
  }

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3} data-testid="travel-expense-summary-grid">
        <Grid item xs={12}>
          <Card {...props}>
            <CardHeader
              title="Travel and Expense Summary"
              sx={{
                backgroundColor: theme.palette.primary.dark,
                padding: '5px 15px',
              }}
              titleTypographyProps={{
                variant: 'h6',
                color: theme.palette.common.white,
              }}
            />
            <Divider />
            <Box sx={{ minWidth: 400 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Role</TableCell>
                      <TableCell align="right">Unit Rate</TableCell>
                      <TableCell align="right">Billable Units</TableCell>
                      <TableCell align="right">Subtotal</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {error && (
                      <Typography color="error" variant="h6">
                        {error.message}
                      </Typography>
                    )}
                    {loading && (
                      <TableRow>
                        <TableCell>
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}
                    {!error && !loading && (
                      <>
                        {Object.keys(travelExpenseSummary?.travelTimeRole)
                          .length > 0 && (
                          <TableRow
                            hover
                            key="travelTimeHeader"
                            sx={{
                              borderTop: `${theme.palette.primary.dark} 2px solid`,
                            }}
                          >
                            <TableCell>
                              <Typography
                                sx={{
                                  color: theme.palette.primary.dark,
                                  fontWeight: 'bold',
                                }}
                              >
                                Travel Time
                              </Typography>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        )}
                        {Array.isArray(travelExpenseSummary?.travelTimeRole) &&
                          travelExpenseSummary?.travelTimeRole.map(
                            (data, i) => (
                              <TableRow
                                hover
                                key={`services-${data.name}-${i}`}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>
                                  {data.name !== null
                                    ? data.name
                                    : data.type === 'pmRole'
                                    ? 'PM Role (No PM Selected)'
                                    : ''}
                                  {data.type === 'otRole'
                                    ? ' - OT per hour'
                                    : ' - per hour'}
                                </TableCell>
                                <TableCell align="right">
                                  {formatCurrency(data.rate)}
                                </TableCell>
                                <TableCell align="right">{data.unit}</TableCell>
                                <TableCell align="right">
                                  {formatCurrency(data.total)}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        {Array.isArray(travelExpenseSummary?.travelTimeTotal) &&
                          travelExpenseSummary?.travelTimeTotal.map((data) => (
                            <TableRow hover key={data.rate}>
                              <TableCell>
                                <Typography
                                  sx={{
                                    color: theme.palette.primary.dark,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {data.name}
                                </Typography>
                              </TableCell>
                              <TableCell></TableCell>
                              <TableCell align="right">
                                <Typography
                                  sx={{
                                    color: theme.palette.primary.dark,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {data.unit}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography
                                  sx={{
                                    color: theme.palette.primary.dark,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {formatCurrency(data.total)}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        {travelExpenseSummary?.isExpenseTotal > 0 && (
                          <>
                            <TableRow
                              hover
                              key="travelTimeHeader"
                              sx={{
                                borderTop: `${theme.palette.primary.dark} 2px solid`,
                              }}
                            >
                              <TableCell>
                                <Typography
                                  sx={{
                                    color: theme.palette.primary.dark,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  Expense
                                </Typography>
                              </TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                            <TableRow hover key="expense">
                              <TableCell>Expense</TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell align="right">
                                {formatCurrency(
                                  travelExpenseSummary?.isExpenseTotal
                                )}
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                        <TableRow
                          hover
                          key="total"
                          sx={{
                            borderTop: `${theme.palette.primary.dark} 2px solid`,
                          }}
                        >
                          <TableCell>
                            <Typography
                              sx={{
                                color: theme.palette.primary.dark,
                                fontWeight: 'bold',
                              }}
                            >
                              Travel & Expense Total
                            </Typography>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>

                          <TableCell align="right">
                            <Typography
                              sx={{
                                color: theme.palette.primary.dark,
                                fontWeight: 'bold',
                              }}
                            >
                              {formatCurrency(
                                travelExpenseSummary?.travelExpenseTotal
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TravelExpenseSummary;

import { CollectionsDataTable, CustomerForm } from '@cdw-selline/ui/components';
import { useAdminCustomers, useCustomerById } from '@cdw-selline/ui/hooks';
import { Box } from '@mui/material';
import React from 'react';

/* eslint-disable-next-line */

export const CustomersCollectionPage = () => {
  const dataTableProps = useAdminCustomers();
  const { data: customer } = useCustomerById(dataTableProps.editCustomerId);

  const openForm =
    dataTableProps.customerFormOpen &&
    ((dataTableProps.editCustomerId && customer.id) ||
      !dataTableProps.editCustomerId);
  
  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <CollectionsDataTable
        {...dataTableProps}
        height="calc(100vh - 13em)"
        parent="CustomersCollectionPage"
        paginationMode="server"
        onRowClick={(o) => dataTableProps.handleEdit(o.id)}
      />
      {openForm && (
        <CustomerForm
          isOpen={dataTableProps.customerFormOpen}
          handleClose={dataTableProps.handleCustomerFormClose}
          handleSubmit={dataTableProps.handleCustomerFormSave}
          customer={customer}
          customerCodes={dataTableProps.customerCodes}
          apiErrorMessage={dataTableProps.apiErrorMessage}
        />
      )}
    </Box>
  );
};

export default CustomersCollectionPage;

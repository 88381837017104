import { useQuery } from '@apollo/client';
import { useApolloErrors } from '..';
import {
    GET_ESTIMATOR_SOW_QUERY,
  } from '@cdw-selline/ui/queries';

export const useEstimatorSow = (
  projectItemId: string
) => {

const { handleErrorResponse } = useApolloErrors();
const {
    data: estimatorSow,
    loading: projectSowLoading,
    error: projectSowError,
    refetch,
  } = useQuery(GET_ESTIMATOR_SOW_QUERY, {
    variables: { projectItemId: projectItemId },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch estimator sow!'),
  });


  return {
    projectSow: estimatorSow?.getSowByProjectItemId ?? {},
    projectSowLoading,
    projectSowError,
    refetch,
  };
}
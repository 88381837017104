// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  CollectionsDataTable,
  AdminConfigSettingsForm,
} from '@cdw-selline/ui/components';
import { useAdminConfigSettings } from '@cdw-selline/ui/hooks';
import { Box } from '@mui/material';
import React from 'react';

export const AdminConfigSettingsCollectionPage = () => {
  const dataTableProps = useAdminConfigSettings();

  const adminConfigSettings = dataTableProps.rows.find(
    (row) => row.id === dataTableProps.editAdminConfigId
  );
  const openForm =
    dataTableProps.formOpen &&
    dataTableProps.editAdminConfigId &&
    adminConfigSettings.id;
  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <CollectionsDataTable
        {...dataTableProps}
        height="calc(100vh - 13em)"
        parent="AdminConfigSettingsCollectionPage"
        paginationMode="server"
        onRowClick={(o) => dataTableProps.handleEdit(o.id)}
      />
      {openForm && (
        <AdminConfigSettingsForm
          isOpen={dataTableProps.formOpen}
          handleClose={dataTableProps.handleFormClose}
          handleSubmit={dataTableProps.handleFormSave}
          adminConfigSettings={adminConfigSettings}
        />
      )}
    </Box>
  );
};

export default AdminConfigSettingsCollectionPage;

import { CiscoAnnuityPartNumberInput } from '@cdw-selline/common/types';
  import {
    Box,
    Button,
    Grid,
  } from '@mui/material';
  import React, { useRef } from 'react';
  import { HyperFormula } from 'hyperformula';
  import 'handsontable/dist/handsontable.full.min.css';
  import { HotTable } from '@handsontable/react';
  import { registerAllModules } from 'handsontable/registry';
  import { GridRowsProp } from '@mui/x-data-grid';
  import { uniqBy, differenceWith, isEqual, omit } from 'lodash';
  import { useNavigate } from 'react-router-dom';
  import {
    ALERT_SEVERITY,
    ALERT_TYPE,
    useAlertsState,
  } from '@cdw-selline/ui/state';
  
  const hf = HyperFormula.buildEmpty({
    licenseKey: 'gpl-v3',
  });
  
  const sheetName = hf.addSheet('ciscoAnnuityLines');
  const sheetId = hf.getSheetId(sheetName);
  
  /* eslint-disable-next-line */
  export interface CiscoAnnuityPartNumbersListProps {
    categories?: GridRowsProp;
    tableData?: string[][];
    categoryList?: string[];
    handleCategorySave?: (values)=> void;
    handleDelete?: (value)=> void;
  }
  
  export const CiscoAnnuityPartNumbersList = (props: CiscoAnnuityPartNumbersListProps) => {
    registerAllModules();
    const alertState = useAlertsState();
    const tableRef = useRef(null);
    const navigate = useNavigate();

    const tableColumns = [
        {},
        {},
        {
          type: 'dropdown',
          source: props.categoryList
        },
      ];
  
    hf.setCellContents(
      {
        row: 0,
        col: 0,
        sheet: sheetId,
      },
      props.tableData
    );

    const getUpdatedPartNumbers =(updatedCategories: CiscoAnnuityPartNumberInput[])=> {
      const shapedCategories = props.categories.map(category=>omit(category, ['__typename']));
      const changedCategories = differenceWith(updatedCategories, shapedCategories, isEqual);
      return changedCategories;
    };
  
    const handleSave = () => {
      const categoriesTableRef = tableRef.current?.hotInstance.getData();
      const updatedCategories = categoriesTableRef.map((row) => {
          return {
            id: row[0],
            partNumber: row[1],
            category: row[2],
          } as CiscoAnnuityPartNumberInput;
        });
  
      const duplicatePartNumberFound = uniqBy(updatedCategories, 'partNumber').length !== updatedCategories.length;
      if (duplicatePartNumberFound) {
        alertState.setAlert({
          type: ALERT_TYPE.MODAL,
          severity: ALERT_SEVERITY.WARNING,
          message: 'Duplicate Part Numbers Found!',
        });
        return;
      }

      const emptyValues =updatedCategories.filter(partNumber=>!partNumber.partNumber || !partNumber.category);
      if (emptyValues.length) {
        alertState.setAlert({
          type: ALERT_TYPE.MODAL,
          severity: ALERT_SEVERITY.WARNING,
          message: 'Empty Value Found!',
        });
        return;
      }

      const categoriedToUpdate = getUpdatedPartNumbers(updatedCategories);
      if (categoriedToUpdate.length > 0) {
        props.handleCategorySave(categoriedToUpdate);
      } else {
        alertState.setAlert({
          type: ALERT_TYPE.MODAL,
          severity: ALERT_SEVERITY.WARNING,
          message: 'No changes to save!',
        });
      }
    };
  
    const handleExportClick = () => {
      const exportPlugin = tableRef.current?.hotInstance.getPlugin('exportFile');
  
      exportPlugin.downloadFile('csv', {
        bom: false,
        columnDelimiter: ',',
        columnHeaders: true,
        exportHiddenColumns: true,
        exportHiddenRows: true,
        fileExtension: 'csv',
        filename: 'Cisco Annuity Categories' + '-CSV-file_[YYYY]-[MM]-[DD]',
        mimeType: 'text/csv',
        rowDelimiter: '\r\n',
        rowHeaders: true,
      });
    };
  
    return (
      <Grid container sx={{ width: '100%' }}>
        <Grid item xs={12}>
          <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Grid container alignItems={'flex-end'}>
              <Button onClick={handleSave}>Save</Button>
              <Button onClick={handleExportClick}>Export</Button>
              <Button onClick={()=> navigate('/')}>Back</Button>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
                sx={{
                overflow: 'hidden',
                width: '100vw - 5px',
                height: '84vh',
                bgcolor: 'background.paper',
                }}
            >
            <HotTable
                {...{
                  id: 'admin-cisco-annuity-lines-table',
                  rowHeaders: true,
                  autoColumnSize: false,
                  autoRowSize: false,
                  colHeaders: ['ID','Part Number', 'Category'],
                  columns: tableColumns,
                  manualColumnFreeze: true,
                  stretchH: 'all',
                  manualRowMove: true,
                  manualColumnResize: true,
                  manualColumnHide: true,
                  hiddenColumns: {
                    columns: [0],
                  },
                  persistentState: true,
                  ref: tableRef,
                  data: props.tableData,
                  licenseKey: 'non-commercial-and-evaluation',
                  tableClassName: 'table-template',
                  className: 'table-cell',
                  contextMenu: {
                    items: {
                      'row_above': {
                        name: 'Insert row above this one'
                      },
                      "delete_part_number": {
                        name: 'Delete Part Number',
                        callback: function(key, options) {
                          const row = this.getDataAtRow(options[0].start.row);
                          props.handleDelete(row[1]);
                        },
                      },
                    }
                  },
                }}
                filters={true}
                dropdownMenu={true}
            />
          </Box>
        </Grid>
      </Grid>
    );
  };
  
  export default CiscoAnnuityPartNumbersList;

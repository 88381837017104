import { gql } from '@apollo/client';

export const GET_RMS_ONE_TIME_COST_TEMPLATE_QUERY = gql`
  query getRmsOneTimeCostTemplateByItemId($projectItemId: String!) {
    getRmsOneTimeCostTemplateByItemId(projectItemId: $projectItemId) {
      rmsOneTimeCostItems {
        siteId        
        siteName
        categories {
          category
          lineItems {
            id
            order
            quantity
            sellPrice
            totalSellPrice
            tier
            tierId
            type
            costType
            name
            proposalNotes
            proposalDescription
          }
          totalSellPrice
        }
        totalSellPrice
      }
      totalMonthlyCharges
    }
  }
`;

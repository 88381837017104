import React from 'react';
import { TaskGroup } from '@cdw-selline/common/types';
import { Button, Typography, Box, Autocomplete, TextField, Grid } from '@mui/material';
import omitDeep from 'omit-deep-lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSowSections } from '@cdw-selline/ui/hooks';
import { mapValues, keyBy } from 'lodash';

export interface TaskGroupSowSectionsProps {
  currentTaskGroup: TaskGroup;
  handleEditorChange: (params) => void;
}

export function TaskGroupSowSections(props: TaskGroupSowSectionsProps) {
  const [taskGroupSowSections, setTaskGroupSowSections] = React.useState(
    props.currentTaskGroup?.sowSections ?? []
  );

  const {
    data: sowSections,
    loading: sowSectionsLoading,
    error: sowSectionsError,
  } = useSowSections({ filters: {}, offset: 0, limit: 10000 });
  const sowSectionOptions =
    sowSections.sowSections.map((sowSection) => {
      return {
        id: sowSection.id,
        label: sowSection.name,
      };
    }) ?? [];

  const sowSectionMap = keyBy(sowSectionOptions, 'id');

  const addRow = () => {
    setTaskGroupSowSections([...taskGroupSowSections, '']);
  };

  const selectSowSection = (index: number, value) => {
    const sowSectionsCopy = [...taskGroupSowSections];
    sowSectionsCopy[index] = value.id;
    setTaskGroupSowSections([...sowSectionsCopy]);
    props.handleEditorChange({
      sowSections: [...sowSectionsCopy],
    });
  };

  const removeRow = (key: string) => {
    const sowSectionsCopy = [...taskGroupSowSections];
    const newSowSections = sowSectionsCopy.filter((item) => item !== key);
    setTaskGroupSowSections([...newSowSections]);
    props.handleEditorChange({
        sowSections: [...newSowSections],
    });
  };

  return (
    <>
      <Box sx={{ marginTop: 2, marginBottom: 2 }}>
        <Typography variant="h6">Sow Sections</Typography>
      </Box>
      {!sowSectionsLoading && taskGroupSowSections.map((item, index) => (
        <Grid container xl={12} key={`${item}`} sx={{ width: '100%',  marginBottom: 2 }} alignItems= 'space-between'>
            <Autocomplete
              sx={{ width: '95%' }}
              freeSolo={true}
              options={sowSectionOptions ?? []}
              onChange={(event, newValue) => {
                selectSowSection(index, newValue);
              }}
              value={sowSectionMap[item]}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sow Section"
                />
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={props.id}>
                    {option.label}
                  </li>
                );
              }}
            /> 
          <DeleteIcon
            sx={{ marginTop: 2, marginLeft: 1 }}
            onClick={()=>removeRow(item)}
          />
        </Grid>
      ))}
      <Button
        variant="contained"
        sx={{ marginTop: 2, marginBottom: 2 }}
        onClick={addRow}
      >
        Add Sow Section
      </Button>
    </>
  );
}

export default TaskGroupSowSections;
import { CiscoAnnuityQuoteSummary } from '@cdw-selline/common/types';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Typography,
} from '@mui/material';
import React from 'react';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { formatCurrency } from '@cdw-selline/ui/helpers';
import { round } from 'lodash';

export interface CiscoAnnuityQuoteSummaryTableProps {
  summary: CiscoAnnuityQuoteSummary;
  term: number;
  autoRenew: boolean;
  removeBidAdders?: boolean;
}

export function CiscoAnnuityQuoteSummaryTable({
  summary,
  term,
  autoRenew,
  removeBidAdders,
}: CiscoAnnuityQuoteSummaryTableProps) {
  return (
    <TableContainer component={Paper} sx={{ width: 'fit-content' }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell title={`${term}`}>
              Inital Term ({round(term, 2)} months)
            </TableCell>
            {autoRenew && <TableCell>Renewal Term (12 months)</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Customer Price:</TableCell>
            <TableCell align="right">
              {formatCurrency(summary.customerPriceInitial)}
            </TableCell>
            {autoRenew && (
              <TableCell align="right">
                {formatCurrency(summary.customerPriceRenewal)}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell>CDW Raw Cost :</TableCell>
            <TableCell align="right">
              {formatCurrency(summary.cdwRawCostInitial)}
            </TableCell>
            {autoRenew && (
              <TableCell align="right">
                {formatCurrency(summary.cdwRawCostRenewal)}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell>CDW Cost* :</TableCell>
            <TableCell align="right">
              {formatCurrency(summary.cdwCostInitial)}
            </TableCell>
            {autoRenew && (
              <TableCell align="right">
                {formatCurrency(summary.cdwCostRenewal)}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell>GP* :</TableCell>
            <TableCell align="right">
              {formatCurrency(summary.gpInitial)}
            </TableCell>
            {autoRenew && (
              <TableCell align="right">
                {formatCurrency(summary.gpRenewal)}
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell>GP* :</TableCell>
            <TableCell align="right">
              {summary.gpPercentInitial.toFixed(2)} %
            </TableCell>
            {autoRenew && (
              <TableCell align="right">
                {summary.gpPercentRenewal.toFixed(2)} %
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell> Commission Tier:</TableCell>
            <TableCell align="right">
              {summary.commissionTierInitial.toFixed(2)} %
            </TableCell>
            {autoRenew && (
              <TableCell align="right">
                {summary.commissionTierRenewal.toFixed(2)} %
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell> GP% Per Cisco (CCW):</TableCell>
            <TableCell align="right">
              {summary.gpPercentPerCiscoInitial.toFixed(2)} %
            </TableCell>
            {autoRenew && (
              <TableCell align="right">
                {summary.gpPercentPerCiscoRenewal.toFixed(2)} %
              </TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell>
              {!removeBidAdders ? (
                <Typography variant="caption">* Includes CDW Adders</Typography>
              ) : (
                <Typography variant="caption">CDW Adders Removed</Typography>
              )}
            </TableCell>
            <TableCell align="right"></TableCell>
            {autoRenew && <TableCell align="right"></TableCell>}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import { Box, Container, Grid } from '@mui/material';
import React, { SetStateAction } from 'react';
import ActiveProjects from '../dashboard/ActiveProjects';
import RecentPipelines from '../dashboard/RecentPipelines';
import TasksProgress from '../dashboard/TasksProgress';
import TotalCustomers from '../dashboard/TotalCustomers';
import TotalProfit from '../dashboard/TotalProfit';
import TotalOfferings from '../dashboard/total-offerings/TotalOfferings';
import TotalManagedServices from '../dashboard/total-managed-services/TotalManagedServices';
import LeadsList from '../dashboard/leads-list/LeadsList';
import CustomerList from '../dashboard/customer-list/CustomerList';

export interface DashboardCardsContainerProps {
  routeTo: (id: string, location?: string) => void;
  routeToArbitraryRoute: (route: string) => void;
  createProject: () => void;
  projectModalOpen?: boolean;
  setProjectModalOpen: React.Dispatch<SetStateAction<boolean>>;
  handleProjectSubmit: (formData: Record<string, string>) => void;
  createProjectLoading: boolean;
  createLead: () => void;
  leadModalOpen?: boolean;
  setLeadModalOpen: React.Dispatch<SetStateAction<boolean>>;
  handleLeadSubmit: (formData: Record<string, string>) => void;
  createLeadLoading: boolean;
}

export interface DashboardCardsContainerColumnProps
  extends DashboardCardsContainerProps {
  status: string;
}

export const DashboardCardsContainer = (
  props: DashboardCardsContainerProps
) => {
  return (
    <Box
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <Grid container spacing={1}>
        {process.env.NODE_ENV !== 'production' && (
          <Grid item lg={4} sm={6} xl={2} xs={12}>
            <TotalProfit sx={{ height: '100%' }} />
          </Grid>
        )}
        {process.env.NODE_ENV !== 'production' && (
          <Grid item lg={4} sm={6} xl={2} xs={12}>
            <TotalManagedServices sx={{ height: '100%' }} />
          </Grid>
        )}
        {process.env.NODE_ENV !== 'production' && (
          <Grid item lg={4} sm={6} xl={2} xs={12}>
            <ActiveProjects />
          </Grid>
        )}
        {process.env.NODE_ENV !== 'production' && (
          <Grid item lg={4} sm={6} xl={2} xs={12}>
            <TotalOfferings sx={{ height: '100%' }} />
          </Grid>
        )}
        {process.env.NODE_ENV !== 'production' && (
          <Grid item lg={4} sm={6} xl={2} xs={12}>
            <TotalCustomers sx={{ height: '100%' }} />
          </Grid>
        )}
        {process.env.NODE_ENV !== 'production' && (
          <Grid item lg={4} sm={6} xl={2} xs={12}>
            <TasksProgress />
          </Grid>
        )}
        {/* <Grid item lg={7} md={12} xl={8} xs={12}>
          <Sales sx={{ height: '100%' }} />
        </Grid>
        <Grid item lg={5} md={6} xl={4} xs={12}>
          <SalesRepsTrending sx={{ height: '100%' }} />
        </Grid> */}
        <Grid container spacing={1} item lg={5} md={12} xl={5} xs={12}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <CustomerList
              routeTo={props.routeTo}
              routeToArbitraryRoute={props.routeToArbitraryRoute}
            />
          </Grid>
          {process.env.NODE_ENV !== 'production' && (
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <LeadsList
                routeTo={props.routeTo}
                routeToArbitraryRoute={props.routeToArbitraryRoute}
                createLead={props.createLead}
                leadModalOpen={props.leadModalOpen}
                setLeadModalOpen={props.setLeadModalOpen}
                handleLeadSubmit={props.handleLeadSubmit}
                createLeadLoading={props.createLeadLoading}
              />
            </Grid>
          )}
        </Grid>
        <Grid sx={{ height: '100%' }} item lg={7} md={6} xl={7} xs={12}>
          <RecentPipelines
            routeTo={props.routeTo}
            routeToArbitraryRoute={props.routeToArbitraryRoute}
            createProject={props.createProject}
            projectModalOpen={props.projectModalOpen}
            setProjectModalOpen={props.setProjectModalOpen}
            handleProjectSubmit={props.handleProjectSubmit}
            createProjectLoading={props.createProjectLoading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

import React, { Fragment } from 'react';
import { CiscoAnnuityContact } from '@cdw-selline/common/types';
import { TextField, Grid } from '@mui/material';
import { FormSelect, FormText, FormCheckbox } from '../../formHelperFunctions';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useCiscoAnnuityContact } from '@cdw-selline/ui/hooks';
export interface AnnuityContactProps {
  ciscoAnnuityId: string;
  ciscoAnnuityContact: CiscoAnnuityContact;
  isReadOnly?: boolean;
}

//TODO Wireup add/update
export function AnnuityContact({
  ciscoAnnuityId,
  ciscoAnnuityContact,
  isReadOnly,
}: AnnuityContactProps) {
  const {
    handleCiscoAnnuityContactUpdate,
    updateCiscoAnnuityContactByIdLoading,
    updateCiscoAnnuityContactByIdError,
    handleAddCiscoAnnuityContact,
    addCiscoAnnuityContactLoading,
    addCiscoAnnuityContactError,
  } = useCiscoAnnuityContact();

  const setFormData = (updatedFormData) => {
    if (ciscoAnnuityContact.id) {
      handleCiscoAnnuityContactUpdate(
        ciscoAnnuityId,
        ciscoAnnuityContact.id,
        updatedFormData
      );
    } else {
      handleAddCiscoAnnuityContact(ciscoAnnuityId, updatedFormData);
    }
  };

  return (
    <Grid
      container
      spacing={1}
      justifyContent="flex-start"
      style={{ width: '80%' }}
    >
      <Grid item xs={12}>
        <FormText
          name="name"
          label="Name"
          setFormData={setFormData}
          formData={ciscoAnnuityContact}
          disabled={isReadOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <FormText
          name="email"
          label="Email"
          setFormData={setFormData}
          formData={ciscoAnnuityContact}
          disabled={isReadOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <FormText
          name="phone"
          label="Phone"
          setFormData={setFormData}
          formData={ciscoAnnuityContact}
          disabled={isReadOnly}
        />
      </Grid>
    </Grid>
  );
}

import { CollectionsDataTable, CdwRateForm } from '@cdw-selline/ui/components';
import { useAdminCdwRates, useCdwRateById } from '@cdw-selline/ui/hooks';
import { Box } from '@mui/material';
import React from 'react';

/* eslint-disable-next-line */
export interface CdwRatesCollectionPageProps {}

export const CdwRatesCollectionPage = (props: CdwRatesCollectionPageProps) => {
  const dataTableProps = useAdminCdwRates();

  const {
    data: cdwRate,
    loading: cdwRateLoading,
    error: cdwRateError,
  } = useCdwRateById(dataTableProps.editCdwRateId);

  const openForm =
    dataTableProps.cdwRateFormOpen &&
    ((dataTableProps.editCdwRateId && cdwRate.id) ||
      !dataTableProps.editCdwRateId);

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <CollectionsDataTable
        {...dataTableProps}
        height="calc(100vh - 13em)"
        paginationMode="server"
        onRowClick={(o) => dataTableProps.handleEdit(o.id)}
        parent="CdwRatesCollectionPage"
        handleSync={dataTableProps.syncCdwRates}
      />

      {openForm && (
        <CdwRateForm
          isOpen={dataTableProps.cdwRateFormOpen}
          handleClose={dataTableProps.handleCdwRateFormClose}
          handleSubmit={dataTableProps.handleCdwRateFormSave}
          cdwRate={cdwRate}
        />
      )}
    </Box>
  );
};

export default CdwRatesCollectionPage;

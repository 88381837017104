// eslint-disable-next-line @nx/enforce-module-boundaries
import { useOpenState } from '@cdw-selline/ui/hooks';
import {
  Grid,
  Typography,
  Button,
  IconButton,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TextField,
} from '@mui/material';
import * as React from 'react';
import DialogConfirm from '../../dialog-confirm/DialogConfirm';
import { ProposalTemplate } from '@cdw-selline/common/types';
import { useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import { cloneDeep } from 'lodash';

export interface DataCollectionTableProps {
  currentProposalTemplate: ProposalTemplate;
  handleSave: (params) => void;
  handleDelete: (value) => void;
}

export const DataCollectionTable = ({
  currentProposalTemplate,
  handleSave,
  handleDelete,
}: DataCollectionTableProps) => {
  const {
    isOpen: deleteDialogConfirmOpen,
    handleClose: handleDeleteDialogConfirmClose,
    handleOpen: handleDeleteDialogConfirmOpen,
  } = useOpenState();

  const [rowToDelete, setRowToDelete] = useState(null);
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  useEffect(() => {
    setRows(currentProposalTemplate.dataCollection || []);
  }, [currentProposalTemplate.dataCollection]);

  const handleSaveClick = (event: React.MouseEvent<unknown>, rowToSave) => {
    event.stopPropagation();
    if (rowToSave.name && rowToSave.key) {
      handleSave(rowToSave);
    }
  };
  const handleDeleteClick = (event: React.MouseEvent<unknown>, id: string) => {
    event.stopPropagation();
    handleDeleteDialogConfirmOpen();
    setRowToDelete(id);
  };

  const handleEditClick = (event: React.MouseEvent<unknown>, id: number) => {
    event.stopPropagation();
    const newRows = rows.map((row) =>
      row.id === id ? { ...row, isEdit: true } : { ...row, isEdit: false }
    );
    setRows(newRows);
  };

  const handleAddRow = () => {
    setRows([
      ...rows.map((row) => ({ ...row, isEdit: false, isNew: false })),
      {
        id: rows.length ? rows[rows.length - 1].id + 1 : 1,
        name: '',
        key: '',
        isEdit: true,
        isNew: true,
      },
    ]);
  };

  const handleDialogDelete = () => {
    handleDelete(rowToDelete);

    handleDeleteDialogConfirmClose();
    setRowToDelete(null);
  };

  const handleDialogCancel = () => {
    setRowToDelete(null);
    handleDeleteDialogConfirmClose();
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEditCell = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowId: number,
    dataKey: string
  ) => {
    const updatedRows = cloneDeep(rows);
    const row = updatedRows.find((r) => r.id === rowId);
    row[dataKey] = e.target.value;

    setRows(updatedRows);
  };

  return (
    <Grid container sx={{ paddingLeft: '8px', marginTop: '8px' }}>
      <TableContainer
        sx={{ width: '100%', marginBottom: 2, marginTop: 2 }}
        component={Paper}
        data-testid="data-collection-table"
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>DATA COLLECTION</TableCell>
              <TableCell></TableCell>
              <TableCell align="right">
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleAddRow}
                  data-testid={`add-data-collection`}
                >
                  Add
                </Button>
              </TableCell>
            </TableRow>
            <TableRow sx={{ backgroundColor: 'rgba(66, 165, 245, 0.08)' }}>
              <TableCell width="40%">NAME</TableCell>
              <TableCell width="40%">KEY</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {rows.length === 0 && (
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography>
                    No data collection records. Click "ADD" to start creating
                    one
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          {rows.length > 0 && (
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell width="40%" sx={{ fontSize: '14px' }}>
                      {row.isEdit ? (
                        <TextField
                          size="small"
                          variant="standard"
                          InputProps={{
                            disableUnderline: true,
                            style: { fontSize: 'inherit' },
                          }}
                          label={'data-collection-name' + row.id}
                          value={row.name}
                          onChange={(e) => handleEditCell(e, row.id, 'name')}
                          data-testid={`data-collection-name${row.id}`}
                        />
                      ) : (
                        row.name
                      )}
                    </TableCell>
                    <TableCell width="40%" sx={{ fontSize: '14px' }}>
                      {row.isEdit ? (
                        <TextField
                          size="small"
                          variant="standard"
                          InputProps={{
                            disableUnderline: true,
                            style: { fontSize: 'inherit' },
                          }}
                          label={'data-collection-key' + row.id}
                          value={row.key}
                          onChange={(e) => handleEditCell(e, row.id, 'key')}
                          data-testid={`data-collection-key${row.id}`}
                        />
                      ) : (
                        row.key
                      )}
                    </TableCell>
                    <TableCell align="right" key={row.id}>
                      {!row.isEdit && (
                        <IconButton
                          size="small"
                          aria-label="edit"
                          onClick={(event) => handleEditClick(event, row.id)}
                          data-testid={`data-collection-edit${row.id}`}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      )}
                      {row.isEdit && (
                        <IconButton
                          size="small"
                          aria-label="edit"
                          onClick={(event) => handleSaveClick(event, row)}
                          data-testid={`data-collection-save${row.id}`}
                        >
                          <SaveIcon fontSize="small" />
                        </IconButton>
                      )}
                      <IconButton
                        size="small"
                        aria-label="delete"
                        onClick={(event) => handleDeleteClick(event, row.key)}
                        data-testid={`data-collection-delete${row.id}`}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
        {rows.length > 10 && (
          <>
            <Divider light />
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </TableContainer>
      {deleteDialogConfirmOpen && (
        <DialogConfirm
          title="Delete?"
          isOpen={deleteDialogConfirmOpen}
          handleClose={handleDialogCancel}
          handleYes={handleDialogDelete}
        >
          <Typography>Are you sure you want to remove this data?</Typography>
        </DialogConfirm>
      )}
    </Grid>
  );
};

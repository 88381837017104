import { gql } from '@apollo/client';

export const GET_ESTIMATOR_SITES_BY_PROJECT_ITEM_ID_QUERY = gql`
  query GetEstimatorSitesByProjectItemId($projectItemId: ID!) {
    getEstimatorSitesByProjectItemId(projectItemId: $projectItemId) {
      address
      floor
      id
      name
      order
      quantity
      hideZeroQuantity
      expanded
    }
  }
`;

export const GET_ACTIVE_SITES_BY_PROJECT_ITEM_ID_QUERY = gql`
query GetActiveSitesAndTasksByProjectItemId($projectItemId: String!) {
  getActiveSitesAndTasksByProjectItemId(projectItemId: $projectItemId) {
    siteId
    siteName
    siteTotalHours
    tasks {
      id
      taskId
      siteId
      quantity
      category
      name
      primaryRole
      secondaryRole
      overtime
      hours
      totalHours
      noteCount
    }
  }
}
`;

export const GET_SITES_WITH_TASKS_BY_PROJECT_ITEM_ID_QUERY = gql`
query GetSitesWithTasksByProjectItemId($projectItemId: String!, $siteId: String) {
  getSitesWithTasksByProjectItemId(projectItemId: $projectItemId, siteId: $siteId) {
    siteId
    siteName
    tasks {
      id
      taskId
      siteId
      quantity
      type
      category
      name
      taskGroupId
      listNames
      dropDownIndex
    }
  }
}
`;

export const UPDATE_ESTIMATOR_SITE_CARDS_MUTATION = gql`
  mutation updateEstimatorSiteCards($projectItemId: ID!, $sites: [SiteInput]!) {
    updateEstimatorSiteCards(projectItemId: $projectItemId, sites: $sites) {
      success
    }
  }
`;

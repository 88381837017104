import { useState } from 'react';

import useProjects from './useProjects';
import useLead from './useLead';

export const useNavTabs = () => {
  const [projectModalOpen, setProjectModalOpen] = useState(false);
  const { createProject, createProjectLoading } = useProjects({});

  const handleProjectClick = () => {
    setProjectModalOpen(true);
  };
  const handleProjectSubmit = (formData) => {
    createProject({ variables: { createProjectParams: formData } });
    setProjectModalOpen(false);
  };

  const [leadModalOpen, setLeadModalOpen] = useState(false);
  const { createLead, createLeadLoading } = useLead({});

  const handleLeadClick = () => {
    setLeadModalOpen(true);
  };
  const handleLeadSubmit = (formData) => {
    createLead({ variables: { createLeadParams: formData } });
    setLeadModalOpen(false);
  };

  return {
    projectModalOpen,
    handleProjectClick,
    setProjectModalOpen,
    handleProjectSubmit,
    createProjectLoading,
    leadModalOpen,
    handleLeadClick,
    setLeadModalOpen,
    handleLeadSubmit,
    createLeadLoading,
  };
};

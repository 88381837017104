import { useQuery, useMutation } from '@apollo/client';
import {
  GetCiscoAnnuityPartNumbersResponse,
  CiscoAnnuityPartNumberInput,
  CiscoAnnuityCategory,
} from '@cdw-selline/common/types';
import {
  GET_CISCO_ANNUITY_PART_NUMBERS,
  UPDATE_CISCO_ANNUITY_PART_NUMBERS_MUTATION,
  GET_CISCO_ANNUITY_CATEGORIES,
  REMOVE_CISCO_ANNUITY_PART_NUMBER_MUTATION,
} from '@cdw-selline/ui/queries';
import { useState, useEffect } from 'react';

import { useApolloErrors } from '..';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';

export const useCiscoAnnuityPartNumbers = () => {
  const alertState = useAlertsState();

  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getCiscoAnnuityPartNumbers: GetCiscoAnnuityPartNumbersResponse;
  }>(GET_CISCO_ANNUITY_PART_NUMBERS, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    variables: { filters: {} },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Cisco Annuity Part Numbers!'),
  });

  const {
    loading: categoryLoading,
    error: categoryError,
    data: categories,
  } = useQuery<{
    getCiscoAnnuityCategories: CiscoAnnuityCategory[];
  }>(GET_CISCO_ANNUITY_CATEGORIES, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    variables: { filters: {} },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Cisco Annuity Categories!'),
  });

  const getTableRows = (partNumbers: CiscoAnnuityPartNumberInput[]) =>
    partNumbers.map((number) => [
      number.id,
      number.partNumber,
      number.category,
    ]);

  const [tableData, setTableData] = useState(
    getTableRows(data?.getCiscoAnnuityPartNumbers?.partNumbers || [])
  );

  useEffect(() => {
    if (data?.getCiscoAnnuityPartNumbers?.partNumbers?.length > 0) {
      setTableData(getTableRows(data?.getCiscoAnnuityPartNumbers?.partNumbers));
    }
  }, [data?.getCiscoAnnuityPartNumbers?.partNumbers]);

  const [
    updateCiscoAnnuityPartNumbers,
    {
      loading: updateCiscoAnnuityPartNumbersLoading,
      error: updateCiscoAnnuityPartNumbersError,
    },
  ] = useMutation(UPDATE_CISCO_ANNUITY_PART_NUMBERS_MUTATION, {
    refetchQueries: [GET_CISCO_ANNUITY_PART_NUMBERS],
    onError: (error) =>
      handleErrorResponse(
        error,
        'Failed to update Cisco Annuity Part Numbers!'
      ),
    onCompleted: (data) => {
      if (data.updateCiscoAnnuityPartNumbers.success) {
        alertState.setAlert({
          type: ALERT_TYPE.MODAL,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully updated cisco annuity part numbers!',
        });
      }
    },
  });

  const handleSaveAll = (
    ciscoAnnuityPartNumbers: CiscoAnnuityPartNumberInput[]
  ) => {
    updateCiscoAnnuityPartNumbers({
      variables: {
        params: ciscoAnnuityPartNumbers.map((partNumber) => ({
          id: partNumber.id,
          partNumber: partNumber.partNumber,
          category: partNumber.category,
        })),
      },
    });
  };

  const [
    removeCiscoAnnuityPartNumber,
    {
      loading: removeCiscoAnnuityPartNumberLoading,
      error: removeCiscoAnnuityPartNumberError,
    },
  ] = useMutation(REMOVE_CISCO_ANNUITY_PART_NUMBER_MUTATION, {
    refetchQueries: [GET_CISCO_ANNUITY_PART_NUMBERS],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to remove Cisco Annuity Part Number!'),
    onCompleted: (data) => {
      if (data.removeCiscoAnnuityPartNumber.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully removed cisco annuity part number!',
        });
      }
    },
  });

  const handleDelete = (partNumber: string) => {
    removeCiscoAnnuityPartNumber({
      variables: {
        partNumber,
      },
    });
  };

  return {
    rows: data?.getCiscoAnnuityPartNumbers.partNumbers ?? [],
    categoryList: categories?.getCiscoAnnuityCategories?.map(category=>category.category),
    refetch,
    loading,
    error,
    tableData,
    handleSaveAll,
    handleDelete,
    rowCount: data?.getCiscoAnnuityPartNumbers.count ?? 0,
  };
};

import { Opportunity, ProjectItemEstimators } from '@cdw-selline/common/types';
import { BILLING_TYPES } from '@cdw-selline/ui/constants';
import { useSaveEstimateToPsa } from '@cdw-selline/ui/hooks';
import {
  Button,
  CircularProgress,
  Container,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material';
import { filter, sortBy } from 'lodash';
import moment from 'moment';
import TravelExpenseSummary from '../../output-tables/travel-expense-summary/TravelExpenseSummary';
import * as React from 'react';
import { FormDateField } from '../../formHelperFunctions';
import LoadingOverlay from '../../LoadingOverlay';
import {
  EnrollmentSummary,
  EstimatorVariables,
  FixedFeeMilestones,
  InvestmentSummary,
  ManagedServicesRenewal,
  ManagedServicesSummary,
  PsaBreakdownSummary,
  ServiceSummary,
  SiteBreakdown,
  SkillSummary
} from '../../output-tables';

export interface OutputProps {
  error;
  loading;
  handleUpdate: (e, fixed) => void;
  project: Opportunity
  projectItemEstimator: ProjectItemEstimators;
  includesManagedServices: boolean;
  isReadOnly: boolean;
}

export const Output: React.FC<OutputProps> = ({
  error,
  loading,
  handleUpdate,
  project,
  projectItemEstimator,
  includesManagedServices = false,
  isReadOnly,
}: OutputProps) => {
  const {
    saveEstimateToPsa,
    loading: saveEstimateToPsaLoading,
    error: saveEstimateToPsaError,
  } = useSaveEstimateToPsa();

  const handleSaveToPSA = () => {
    saveEstimateToPsa();
  };


  let lastPsaSyncedDateTime;

  if (!loading && projectItemEstimator?.psaSyncHistories && projectItemEstimator?.psaEstimateId) {
    const filteredArray = filter(projectItemEstimator.psaSyncHistories, (obj) => obj.isSynced);
    if (filteredArray?.length > 0) {
      const recentSyncedDateTime = sortBy(
        filteredArray,
        (obj) => new Date(obj.updatedAt)
      ).reverse()[0].updatedAt;
      lastPsaSyncedDateTime = new Date(
        recentSyncedDateTime
      ).toLocaleDateString();
    }
  }

  if (error)
    return (
      <Typography color="error">
        {' '}
        Error loading form! {error?.message ?? 'Error'}
      </Typography>
    );
  const startDateKey = 'startDate';
  const endDateKey = 'endDate';

  const formGroupCssConfig = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    marginTop: '10px',
    flexWrap: 'wrap'
  };
  const formDateFieldContainerCssConfig = {
    width: '150px',
    marginRight: '10px',
    marginTop: '10px'
  };

  const handleDateUpdate = (key: string, e) => {
    if (key === endDateKey && moment(e[endDateKey]).isBefore(e[startDateKey], 'day')) {
      return;
    }
    if (key === startDateKey && moment(e[startDateKey]).isBefore(new Date(), 'day')) {
      return;
    }
    handleUpdate(key, e[key]);
  }

  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={2} paddingBottom="50px">
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            {loading && <CircularProgress sx={{ float: 'right' }} />}
            {!error && !loading && !isReadOnly && (
              <EstimatorVariables
                handleUpdate={handleUpdate}
                projectItemEstimator={projectItemEstimator}
                isReadOnly={isReadOnly}
                loading={loading}
              />
            )}
            {project.salesForceId && (
              <FormGroup sx={formGroupCssConfig}>
                <Grid item sx={formDateFieldContainerCssConfig}>
                  <FormDateField
                    name={startDateKey}
                    label="Start Date"
                    setFormData={(e) => handleDateUpdate(startDateKey, e)}
                    formData={projectItemEstimator}
                    type="date"
                  />
                </Grid>
                <Grid item sx={formDateFieldContainerCssConfig}>
                  <FormDateField
                    name={endDateKey}
                    label="End Date"
                    setFormData={(e) => handleDateUpdate(endDateKey, e)}
                    formData={projectItemEstimator}
                    type="date"
                  />
                </Grid>
                <Grid
                  item
                  sx={{
                    marginTop: '10px',
                  }}
                >
                  <Button
                    onClick={handleSaveToPSA}
                    variant="outlined"
                    sx={{ marginRight: '10px', height: '55px' }}
                  >
                    {projectItemEstimator?.psaEstimateId
                      ? 'Re-Sync to PSA'
                      : 'Sync to PSA'}
                  </Button>
                  {lastPsaSyncedDateTime && (
                    <Typography variant="body2" color="textSecondary">
                      Last Synced: {lastPsaSyncedDateTime}
                    </Typography>
                  )}
                </Grid>
              </FormGroup>
            )}
          </Grid>
          <Grid item lg={6} sm={12} xl={6} xs={12}>
            <ServiceSummary />
          </Grid>
          {project.salesForceId && (
            <Grid item lg={6} sm={12} xl={6} xs={12}>
              <PsaBreakdownSummary
                handleUpdate={handleUpdate}
                projectItemEstimator={projectItemEstimator}
                isReadOnly={isReadOnly}
              />
            </Grid>
          )}
          <Grid item lg={6} sm={12} xl={6} xs={12}>
            <SkillSummary />
          </Grid>
          <Grid item lg={6} sm={12} xl={6} xs={12}>
            <InvestmentSummary />
          </Grid>

          <Grid item lg={6} sm={12} xl={6} xs={12}>
            <TravelExpenseSummary />
          </Grid>

          {includesManagedServices && (
            <>
              <Grid item lg={6} sm={12} xl={6} xs={12}>
                <ManagedServicesSummary />
              </Grid>
              {projectItemEstimator?.managedServicesContractLength > 0 && (
                <EnrollmentSummary />
              )}
              {projectItemEstimator?.isManagedServicesRenewal && (
                <Grid item lg={6} sm={12} xl={6} xs={12}>
                  <ManagedServicesRenewal />
                </Grid>
              )}
            </>
          )}
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <SiteBreakdown />
          </Grid>
          <Grid item lg={6} sm={12} xl={6} xs={12}>
            {!loading &&
              !error &&
              projectItemEstimator?.billingType === BILLING_TYPES.FIXED_FEE &&
              !isReadOnly && (
                <FixedFeeMilestones
                  itemData={projectItemEstimator}
                  handleUpdate={handleUpdate}
                />
              )}
          </Grid>
        </Grid>
      </Container>

      {saveEstimateToPsaLoading && <LoadingOverlay />}
    </>
  );
};

export default Output;

import { gql } from '@apollo/client';

export const COPY_PROJECT_SITE_MUTATION = gql`
  mutation Mutation($projectItemId: ID!, $site: String!, $siteName: String!) {
    copySiteAndTasks(
      projectItemId: $projectItemId
      site: $site
      siteName: $siteName
    ) {
      success
    }
  }
`;

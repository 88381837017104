import { CollectionsDataTable } from '@cdw-selline/ui/components';
import { useDashboardCustomers } from '@cdw-selline/ui/hooks';
import {
  Box,
  Card,
  Divider,
  CardHeader,
  FormControlLabel,
  Switch,
} from '@mui/material';
import React, { useState } from 'react';
import { getCurrentUser } from '@cdw-selline/ui/state';
/* eslint-disable-next-line */
export interface CustomersCollectionPageProps {}

export const CustomersCollectionPage = (
  props: CustomersCollectionPageProps
) => {
  const currentUser = getCurrentUser();
  const userEmail = currentUser ? currentUser.email : {};
  const localValue = JSON.parse(
    localStorage.getItem(`CustomersCollectionPage-toggle`)
  );
  const [userFilter, setUserFilter] = useState(
    localValue ?? {
      $or: [
        { projectTeam: { $elemMatch: { email: userEmail } } },
        { projectOwner: userEmail },
      ],
    }
  );
  const dataTableProps = useDashboardCustomers(userFilter);

  const handleToggleChange = (e) => {
    if (e.target.checked) {
      setUserFilter({});
      localStorage.setItem(
        `CustomersCollectionPage-toggle`,
        JSON.stringify({})
      );
    } else {
      setUserFilter({
        $or: [
          { projectTeam: { $elemMatch: { email: userEmail } } },
          { projectOwner: userEmail },
        ],
      });
      localStorage.setItem(
        `CustomersCollectionPage-toggle`,
        JSON.stringify({
          $or: [
            { projectTeam: { $elemMatch: { email: userEmail } } },
            { projectOwner: userEmail },
          ],
        })
      );
    }
  };

  return (
    <Box
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      ></Box>
      <Box></Box>
      <Card
        style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          height: '90vh',
        }}
      >
        <CardHeader
          title="Accounts"
          action={
            <FormControlLabel
              control={
                <Switch
                  checked={Object.keys(userFilter).length === 0 ? true : false}
                  onChange={handleToggleChange}
                />
              }
              label="View all accounts"
            />
          }
          sx={{ width: '100%' }}
        />
        <Divider />
        <CollectionsDataTable
          {...dataTableProps}
          gridMargin={0}
          paginationMode="server"
          parent="CustomersCollectionPage"
          getRowId={(row) => row.id}
          hideActions={true}
          height="95%"
        />
      </Card>
    </Box>
  );
};

export default CustomersCollectionPage;

import React from 'react';
import { RmsProposalTemplateStandardSites } from '@cdw-selline/common/types';
import {
  Box,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Paper,
} from '@mui/material';

import { formatCurrency } from '@cdw-selline/ui/helpers';
import { makeStyles } from '@mui/styles';

interface RMSOneTimeCostTemplateProps {
  costItems: RmsProposalTemplateStandardSites[];
  totalCharges: number;
}

export const RMSOneTimeCostTemplate = ({
  costItems,
  totalCharges,
}: RMSOneTimeCostTemplateProps) => {
  const useStyles = makeStyles({
    sectionHeader: {
      backgroundColor: '#cc0000',
      padding: '5px',
      minHeight: '25px',
      borderTop: '10px solid #D3D3D3',
      borderBottom: '10px solid #D3D3D3',
      textTransform: 'uppercase',
    },
    siteHeader: {
      backgroundColor: '#cc0000',
      padding: '5px',
      textTransform: 'uppercase',
      border: '1px solid black',
    },
    border1: {
      border: '1px solid black',
      padding: '5px',
    },
    border2: {
      border: '2px solid black',
      padding: '5px',
    },
    category: {
      transformText: 'uppercase',
      fontWeight: 'bold',
    },
  });

  const classes = useStyles();
  const categoriesLength = costItems.length;

  if(costItems.length === 0)
    return null;

  return (
    <>
      <Box sx={{ mt: 2, border: '1px solid #000000' }}>
        <Box className={classes.sectionHeader}>
          <Typography variant="h6" color="#FFFFFF">
            One-Time Cost:
          </Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: 650,
            }}
            aria-label="monthy-cost-table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.border1}
                  sx={{ width: '70%' }}
                ></TableCell>
                <TableCell
                  className={classes.border1}
                  sx={{ width: '10%', textAlign: 'center' }}
                >
                  Fee Per Unit
                </TableCell>
                <TableCell
                  className={classes.border1}
                  sx={{ width: '10%', textAlign: 'center' }}
                >
                  Qty
                </TableCell>
                <TableCell
                  className={classes.border1}
                  sx={{ width: '10%', textAlign: 'center' }}
                >
                  Fee Total
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {costItems.map((site, idx) => (
                <React.Fragment key={`${site.siteName}-${idx}`}>
                  {categoriesLength > 1 && (
                    <TableRow sx={{ background: '#D3D3D3' }}>
                      <TableCell
                        className={classes.siteHeader}
                        colSpan={10}
                        align="left"
                      >
                        <Typography
                          variant="body1"
                          className={classes.category}
                          color="#FFFFFF"
                        >
                          {site.siteName}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {site.categories.map((category, idx) => {
                    return (
                      <React.Fragment key={`${category.category}-${idx}`}>
                        <TableRow sx={{ background: '#D3D3D3' }}>
                          <TableCell
                            className={classes.border1}
                            colSpan={10}
                            align="left"
                          >
                            <Typography
                              variant="body1"
                              className={classes.category}
                            >
                              {category.category}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {category.lineItems.map((lineItem, idx) => (
                          <TableRow key={`${lineItem.name}-${idx}`}>
                            <TableCell className={classes.border1} align="left">
                              <Typography variant="body1">
                                {lineItem.name}
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{ fontStyle: 'italic' }}
                              >
                                {lineItem.proposalNotes
                                  ? `(${lineItem.proposalNotes})`
                                  : ''}
                              </Typography>
                              <Typography variant="body1">
                                {lineItem.proposalDescription
                                  ? `- ${lineItem.proposalDescription}`
                                  : ''}
                              </Typography>
                            </TableCell>
                            <TableCell
                              className={classes.border1}
                              align="right"
                              sx={{ width: '10%' }}
                            >
                              {formatCurrency(lineItem.sellPrice)}
                            </TableCell>
                            <TableCell
                              className={classes.border1}
                              align="center"
                              sx={{ width: '10%' }}
                            >
                              {lineItem.quantity}
                            </TableCell>
                            <TableCell
                              className={classes.border1}
                              align="right"
                              sx={{ width: '10%' }}
                            >
                              {formatCurrency(lineItem.totalSellPrice)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    );
                  })}
                  {categoriesLength > 1 && (
                    <TableRow>
                      <TableCell
                        className={classes.border1}
                        colSpan={1}
                        align="left"
                      >
                        <Typography
                          variant="body1"
                          className={classes.category}
                        >
                          {`${site?.siteName ?? ''} - Total`}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={classes.border1}
                        align="right"
                        colSpan={3}
                      >
                        <Typography
                          variant="body1"
                          className={classes.category}
                        >
                          {formatCurrency(site?.totalSellPrice ?? 0)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ textAlign: 'right', mt: 1 }}>
            <Typography variant="h6">
              Total One-Time Charges: {formatCurrency(totalCharges)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default RMSOneTimeCostTemplate;

import React from 'react';
import {
  FormAutocomplete,
  FormText,
  FormAutocompleteOption,
  FormCheckbox,
  FormTextControlled,
  FormSelect,
} from '../formHelperFunctions';
import { TASK_COST_TYPES, TASK_PRODUCT_TYPES } from '@cdw-selline/ui/constants';
import { MenuItem } from '@mui/material';


export interface ProductTaskFormProps {
  taskFormData: any;
  setTaskFormData: (value) => void;
  vendorOptions: FormAutocompleteOption[];
  practiceOptions: FormAutocompleteOption[];
  taskGroupOptions: FormAutocompleteOption[];
  isCustom: boolean;
  errorsArray: any;
}

export function ProductTaskForm({
  taskFormData,
  setTaskFormData,
  vendorOptions,
  practiceOptions,
  taskGroupOptions,
  isCustom,
  errorsArray,
}: ProductTaskFormProps) {
  return (
    <>
      <FormSelect
        name="productType"
        label="Product Type"
        setFormData={setTaskFormData}
        formData={taskFormData}
        required={true}
        error={errorsArray.length > 0 && !taskFormData.productType}
      >
        {Object.keys(TASK_PRODUCT_TYPES).map((type) => (
          <MenuItem key={type} value={TASK_PRODUCT_TYPES[type]}>
            {TASK_PRODUCT_TYPES[type]}
          </MenuItem>
        ))}
      </FormSelect>
      <FormSelect
        name="costType"
        label="Cost Type"
        setFormData={setTaskFormData}
        formData={taskFormData}
        required={true}
        error={errorsArray.length > 0 && !taskFormData.costType}
      >
        {Object.keys(TASK_COST_TYPES).map((type) => (
          <MenuItem key={type} value={TASK_COST_TYPES[type]}>
            {TASK_COST_TYPES[type]}
          </MenuItem>
        ))}
      </FormSelect>
      <FormText
        name="name"
        label="Name"
        setFormData={setTaskFormData}
        formData={taskFormData}
        required={true}
        error={errorsArray.length > 0 && !taskFormData.name}
      />
      {!isCustom && (
        <FormSelect
          name="estimatorLocation"
          label="Estimator Location"
          setFormData={setTaskFormData}
          formData={taskFormData}
          required={true}
          error={errorsArray.length > 0 && !taskFormData.estimatorLocation}
        >
          <MenuItem value="site">Site</MenuItem>
          <MenuItem value="summary">Summary</MenuItem>
        </FormSelect>
      )}
      <FormAutocomplete
        name="practiceId"
        label="Practice"
        options={practiceOptions}
        setFormData={setTaskFormData}
        formData={taskFormData}
        data-testid="task-practices"
        required={true}
        error={errorsArray.length > 0 && !taskFormData.practiceId}
      />
      {!isCustom && (
        <>
          <FormAutocomplete
            name="taskGroupId"
            label="Task Group"
            options={taskGroupOptions}
            setFormData={setTaskFormData}
            formData={taskFormData}
            required={true}
            error={errorsArray.length > 0 && !taskFormData.taskGroupId}
          />
          <FormTextControlled
            name="taskId"
            label="Task Id"
            setFormData={setTaskFormData}
            formData={taskFormData}
            data-testid="task-id"
            required={true}
            error={errorsArray.length > 0 && !taskFormData.taskId}
          />
        </>
      )}
      <FormCheckbox
        name="managedServices"
        label="Managed Services?"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      {taskFormData.managedServices && (
        <>
          <FormCheckbox
            name="isManagedServicesCostCalculation"
            label={'Is MS Cost Calc?'}
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          <FormText
            name="managedServicesFteFormula"
            label={'managedServicesFteFormula'}
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          <FormText
            name="managedServicesArcRrcFormula"
            label={'managedServicesArcRrcFormula'}
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          <FormText
            name="managedServicesTransitionFormula"
            label={'managedServicesTransitionFormula'}
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
        </>
      )}
      <FormText
        name="category"
        label="Category"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      <FormAutocomplete
        name="vendor"
        label="Vendor"
        options={vendorOptions}
        setFormData={setTaskFormData}
        formData={taskFormData}
        freeSolo={true}
        required={true}
        error={errorsArray.length > 0 && !taskFormData.vendor}
      />
      <FormTextControlled
        name="comment"
        label="Comment"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      {taskFormData.managedServices && (
        <>
          <FormCheckbox
            name="alwaysShowOnProposal"
            label="Always Show on Proposal?"
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          <FormText
            name="proposalNotes"
            label="Proposal Notes"
            setFormData={setTaskFormData}
            formData={taskFormData}
            multiline={true}
          />
        </>
      )}
      <FormText
        name="quantity"
        label="Quantity"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      <FormText
        name="cost"
        label="Cost"
        setFormData={setTaskFormData}
        formData={taskFormData}
        type="number"
        required={true}
        error={errorsArray.length > 0 && !taskFormData.cost}
      />
      <FormText
        name="grossProfit"
        label="Gross Profit %"
        setFormData={setTaskFormData}
        formData={taskFormData}
        type="number"
        required={true}
        error={errorsArray.length > 0 && !taskFormData.grossProfit}
      />
    </>
  );
}

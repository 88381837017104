import { useQuery } from '@apollo/client';
import { GetRolesResponse } from '@cdw-selline/common/types';
import { GET_ROLES_QUERY } from '@cdw-selline/ui/queries';
import { useParams } from 'react-router-dom';

import { useApolloErrors } from './useApolloErrors';

export interface UseRoles {
  filters?: any;
  offset?: number;
  limit?: number;
  sort?: unknown;
}

export const useRoles = ({ filters, offset, limit, sort }: UseRoles) => {
  const { id } = useParams<{ id: string }>();
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getRoles: GetRolesResponse;
  }>(GET_ROLES_QUERY, {
    variables: {
      offset: offset ?? 0,
      limit: limit ?? 2000,
      filters: filters ?? {},
      sort: sort ?? { updatedAt: -1 },
    },
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    // skip: !!id,
    onError: (error) => handleErrorResponse(error, 'Failed to fetch Roles!'),
  });

  return {
    data: data?.getRoles ?? { roles: [], count: 0 },
    error,
    loading,
    refetch,
  };
};

export default useRoles;

import { gql } from '@apollo/client';

export const UPDATE_PROPOSAL_TEMPLATE_MUTATION = gql`
  mutation updateProposalTemplateMutation($params: ProposalTemplateInput) {
    updateProposalTemplate(params: $params) {
      success
    }
  }
`;

// VARIABLES
// {
//   ...ProposalTemplateInput
// }

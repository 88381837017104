import React from 'react';
import {
  FormAutocomplete,
  FormText,
  FormCheckbox,
  FormSelect,
  FormTextControlled,
  FormAutocompleteOption,
} from '../formHelperFunctions';
import { MenuItem, Grid } from '@mui/material';
import FormFieldAutoComplete from '../autocomplete-form-field/FormFieldAutoComplete';
import { useSearchServiceItems, useSearchServiceSkills } from '@cdw-selline/ui/hooks';
import { PrimaryRoleOverrideInclude } from './PrimaryRoleOverrideInclude';
import { SecondaryRoleOverrideInclude } from './SecondaryRoleOverrideInclude';

export interface YesNoTaskFormProps {
  taskFormData: any;
  setTaskFormData: (value) => void;
  practiceOptions: FormAutocompleteOption[];
  taskGroupOptions: FormAutocompleteOption[];
  vendorOptions: FormAutocompleteOption[];
  sowSectionOptions: FormAutocompleteOption[];
  roleOptions: FormAutocompleteOption[];
  currentUser: any;
  isCustom: boolean;
  errorsArray: any
}

export function YesNoTaskForm({
  taskFormData,
  setTaskFormData,
  practiceOptions,
  taskGroupOptions,
  vendorOptions,
  sowSectionOptions,
  roleOptions,
  currentUser,
  isCustom,
  errorsArray,
}: YesNoTaskFormProps) {
  return (
    <>
      <FormTextControlled
        name="name"
        label="Name"
        setFormData={setTaskFormData}
        formData={taskFormData}
        required={true}
        error={errorsArray.length > 0 && !taskFormData.name}
      />
      {!isCustom && (
        <FormSelect
          name="estimatorLocation"
          label="Estimator Location"
          setFormData={setTaskFormData}
          formData={taskFormData}
          required={true}
          error={errorsArray.length > 0 && !taskFormData.estimatorLocation}
        >
          <MenuItem value="site">Site</MenuItem>
          <MenuItem value="summary">Summary</MenuItem>
        </FormSelect>
      )}
      <FormAutocomplete
        name="practiceId"
        label="Practice"
        options={practiceOptions}
        setFormData={setTaskFormData}
        formData={taskFormData}
        data-testid="task-practices"
        required={true}
        error={errorsArray.length > 0 && !taskFormData.practiceId}
      />
      {!isCustom && (
        <>
          <FormAutocomplete
            name="taskGroupId"
            label="Task Group"
            options={taskGroupOptions}
            setFormData={setTaskFormData}
            formData={taskFormData}
            required={true}
            error={errorsArray.length > 0 && !taskFormData.taskGroupId}
          />
          <FormTextControlled
            name="taskId"
            label="Task Id"
            setFormData={setTaskFormData}
            formData={taskFormData}
            data-testid="task-id"
          />
        </>
      )}
      <>
        <FormCheckbox
          name="isProjectManagement"
          label={'Project Management?'}
          setFormData={setTaskFormData}
          formData={taskFormData}
          disabled={!!taskFormData.isTravelTime}
        />
        <FormCheckbox
          name="isTravelTime"
          label={'Is Travel Time ?'}
          setFormData={setTaskFormData}
          formData={taskFormData}
          disabled={!!taskFormData.isProjectManagement}
        />
        <FormText
          name="units"
          label="Units"
          setFormData={setTaskFormData}
          formData={taskFormData}
        />
        <FormText
          name="category"
          label="Category"
          setFormData={setTaskFormData}
          formData={taskFormData}
        />
        <FormText
          name="sprint"
          label="Sprint"
          setFormData={setTaskFormData}
          formData={taskFormData}
        />
        <FormAutocomplete
          name="vendor"
          label="Vendor"
          options={vendorOptions}
          setFormData={setTaskFormData}
          formData={taskFormData}
          freeSolo={true}
        />
        <FormTextControlled
          name="comment"
          label="Comment"
          setFormData={setTaskFormData}
          formData={taskFormData}
        />
        {!isCustom && (
          <Grid item xs={12} sx={{ width: '100%' }}>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={6}>
                <FormText
                  name="sku"
                  label="SKU"
                  setFormData={setTaskFormData}
                  formData={taskFormData}
                />
              </Grid>
              <Grid item xs={6}>
                <FormText
                  name="edc"
                  label="EDC"
                  setFormData={setTaskFormData}
                  formData={taskFormData}
                />
              </Grid>
            </Grid>
          </Grid>
        )}

        <FormText
          name="yesValue"
          label="Yes Value"
          type="number"
          setFormData={setTaskFormData}
          formData={taskFormData}
          required={true}
          error={errorsArray.length > 0 && !taskFormData.yesValue}
        />
        <FormText
          name="noValue"
          label="No Value"
          type="number"
          setFormData={setTaskFormData}
          formData={taskFormData}
          required={true}
          error={errorsArray.length > 0 && !taskFormData.noValue}
        />
        {!isCustom && (
          <>
            <FormText
              name="hoursFormula"
              label="Hours Formula"
              setFormData={setTaskFormData}
              formData={taskFormData}
            />
            <FormCheckbox
              name="exclude"
              label="Exclude task from total?"
              setFormData={setTaskFormData}
              formData={taskFormData}
            />
            {currentUser.admin && (
              <>
                <FormFieldAutoComplete
                  name="skill"
                  label="Skill"
                  setTaskFormData={setTaskFormData}
                  taskFormData={taskFormData}
                  useSearchHook={useSearchServiceSkills}
                  idColumnName="skillId"
                  isUpdateIdAndName={true}
                />
                <FormText
                  name="skillFormula"
                  label="Skill Formula"
                  setFormData={setTaskFormData}
                  formData={taskFormData}
                />
                <FormFieldAutoComplete
                  name="serviceItem"
                  label="Service Item"
                  setTaskFormData={setTaskFormData}
                  taskFormData={taskFormData}
                  useSearchHook={useSearchServiceItems}
                  idColumnName="serviceItemId"
                  isUpdateIdAndName={true}
                />
                <FormText
                  name="serviceItemFormula"
                  label="Service Item Formula"
                  setFormData={setTaskFormData}
                  formData={taskFormData}
                />
              </>
            )}
            <FormAutocomplete
              name="sowSectionId"
              label="SOW Section"
              options={sowSectionOptions}
              freeSolo={false}
              setFormData={setTaskFormData}
              formData={taskFormData}
            />
          </>
        )}
        <FormCheckbox
          name="overtime"
          label="Use overtime rates?"
          setFormData={setTaskFormData}
          formData={taskFormData}
        />
        <FormAutocomplete
          name="primaryRole"
          label="Primary Role"
          options={roleOptions}
          setFormData={setTaskFormData}
          formData={taskFormData}
          required={true}
          error={errorsArray.length > 0 && !taskFormData.primaryRole}
        />
        <FormText
          name="primaryPercent"
          label="Primary Hours %"
          setFormData={setTaskFormData}
          formData={taskFormData}
          required={true}
          error={errorsArray.length > 0 && !taskFormData.primaryPercent}
        />
        <PrimaryRoleOverrideInclude
          setFormData={setTaskFormData}
          formData={taskFormData}
          isCustom={isCustom}
          />
        <FormAutocomplete
          name="secondaryRole"
          label="Secondary Role"
          options={roleOptions}
          setFormData={setTaskFormData}
          formData={taskFormData}
        />
        <FormText
          name="secondaryPercent"
          label="Secondary Hours %"
          setFormData={setTaskFormData}
          formData={taskFormData}
        />
        <SecondaryRoleOverrideInclude
          setFormData={setTaskFormData}
          formData={taskFormData}
          isCustom={isCustom}
        />
      </>
    </>
  );
}

import { useQuery, useMutation } from '@apollo/client';
import { 
    GET_ESTIMATOR_SUMMARY_TASKS_QUERY,
    UPDATE_ESTIMATOR_TASK_MUTATION,
    GET_SITES_WITH_TASKS_BY_PROJECT_ITEM_ID_QUERY,
    GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY,
   } from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { Task } from '@cdw-selline/common/types';
import { useApolloErrors } from '..';

export const useSiteListView = (projectItemId: string) => {
  const alertState = useAlertsState();
  const { handleErrorResponse } = useApolloErrors();

  const {loading: sitesLoading, error: sitesError, data, refetch } = useQuery(
    GET_SITES_WITH_TASKS_BY_PROJECT_ITEM_ID_QUERY,
    {
      fetchPolicy: 'network-only', // Used for first execution
      nextFetchPolicy: 'network-only', // Used for subsequent executions
      variables: {
        projectItemId,
        siteId: '',
      },
      skip: !projectItemId,
      onError: (error) =>
        handleErrorResponse(
          error,
          'Failed to fetch Estimator Sites With Tasks!'
        ),
    }
  );

  const [updateSiteEstimatorTask, { client }] = useMutation(
    UPDATE_ESTIMATOR_TASK_MUTATION,
    {
      awaitRefetchQueries: true,
      onError: (error) => {
        alertState.setAlert({
          type: ALERT_TYPE.MODAL,
          severity: ALERT_SEVERITY.WARNING,
          message: error.message,
        });

        client.refetchQueries({
          include: [GET_SITES_WITH_TASKS_BY_PROJECT_ITEM_ID_QUERY],
        });
      },
      onCompleted: (data) => {
          client.refetchQueries({
            include: [GET_ESTIMATOR_SUMMARY_TASKS_QUERY],
          });
          refetch({projectItemId, siteId: data?.updateEstimatorTask?.updatedTask?.siteId});
      },
    }
  );

  const handleSiteTaskUpdate = (updatedTask: Task | Task[] | []) => {
      updateSiteEstimatorTask({
        variables: {
          params: updatedTask,
        },
      });
    }

  return {
    sitesWithTasks: data?.getSitesWithTasksByProjectItemId ?? [],
    sitesLoading,
    sitesError,
    handleSiteTaskUpdate,
  };
};
import { useQuery } from '@apollo/client';
import { GetPracticesResponse } from '@cdw-selline/common/types';
import { GET_PRACTICES_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from './useApolloErrors';

export interface UsePractices {
  filters?: any;
  offset?: number;
  limit?: number;
  sort?: unknown;
}

export const usePractices = ({
  filters,
  offset,
  limit,
  sort,
}: UsePractices) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getPractices: GetPracticesResponse;
  }>(GET_PRACTICES_QUERY, {
    variables: {
      offset: offset ?? 0,
      limit: limit ?? 500,
      filters: filters ?? {},
      sort: sort ?? { name: 'asc' },
    },
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Practices!'),
  });

  return {
    data: data?.getPractices ?? { practices: [], count: 0 },
    error,
    loading,
    refetch,
  };
};

export default usePractices;

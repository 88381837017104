import { useMutation } from '@apollo/client';
import { BUILD_AGILOFT_FEE_TABLE_MUTATION } from '@cdw-selline/ui/queries';
import { AgiloftApiResponse } from '@cdw-selline/common/types';
import { useEffect, useState } from 'react';
import { useApolloErrors } from '../../useApolloErrors';

export interface useBuildAgiloftFeeTable {
  progress: number;
  setProgress: (progress: number) => void;
  startProgress: number;
  endProgress: number;
  setProgressMessage: (message: string) => void;
  agiloftSOWId: string;
  projectId: string;
  projectItemId: string;
}

export const useBuildAgiloftFeeTable = ({
  progress,
  setProgress,
  startProgress,
  endProgress,
  setProgressMessage,
  agiloftSOWId,
  projectId,
  projectItemId,
}: useBuildAgiloftFeeTable) => {
  const { handleErrorResponse } = useApolloErrors();

  const [buildAgiloftFeeTableRunning, setBuildAgiloftFeeTableRunning] =
    useState(false);

  const [buildAgiloftFeeTable, { error: buildAgiloftFeeTableError }] =
    useMutation<{
      buildAgiloftFeeTable: AgiloftApiResponse;
    }>(BUILD_AGILOFT_FEE_TABLE_MUTATION, {
      onError: (error) => {
        setProgress(100);
        setBuildAgiloftFeeTableRunning(false);
        handleErrorResponse(error, 'Failed to build agiloft fee table');
      },
      onCompleted: (data) => {
        if (data.buildAgiloftFeeTable.buildContinue) {
          buildAgiloftFeeTable({
            variables: {
              params: {
                buildIndex: data.buildAgiloftFeeTable.buildIndex,
                id: agiloftSOWId,
                projectId,
                projectItemId,
              },
            },
          });
        }

        if (!data.buildAgiloftFeeTable.buildContinue) {
          setProgress(endProgress);
          setBuildAgiloftFeeTableRunning(false);
        }
      },
    });

  useEffect(() => {
    if (progress === startProgress) {
      setProgressMessage('Building Agiloft Fee Table ..');
      setBuildAgiloftFeeTableRunning(true);
      buildAgiloftFeeTable({
        variables: {
          params: {
            id: agiloftSOWId,
            projectId: projectId,
            projectItemId: projectItemId,
          },
        },
      });
    }
  }, [
    progress,
    startProgress,
    setProgressMessage,
    agiloftSOWId,
    projectId,
    projectItemId,
    buildAgiloftFeeTable,
  ]);

  useEffect(() => {
    if (progress < 100 && buildAgiloftFeeTableRunning) {
      const timer = setTimeout(() => {
        if (buildAgiloftFeeTableRunning && progress < endProgress - 1) {
          setProgress(progress + 1);
        }
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [buildAgiloftFeeTableRunning, endProgress, progress, setProgress]);

  return {
    buildAgiloftFeeTableRunning,
    buildAgiloftFeeTableError,
  };
};

import { gql } from '@apollo/client';

export const GET_AGILOFT_SOW_BY_ID_QUERY = gql`
  query GetAgiloftSOWById($id: ID!) {
    getAgiloftSOWById(id: $id) {
      id
      customerLegalName
      projectName
      solutionArchitect
    }
  }
`;

import { gql } from '@apollo/client';

export const GET_AGILOFT_TRAVEL_NOTICE_TYPES_QUERY = gql`
  query GetAgiloftTravelNoticeTypesQuery(
    $filters: MongoFilter
    $limit: Int
    $offset: Int
    $sort: MongoFilter
  ) {
    getAgiloftTravelNoticeTypes(filters: $filters, limit: $limit, offset: $offset, sort: $sort) {
      count
      agiloftTravelNoticeTypes {
        id
        travelNoticeTypeName
        travelNoticeClauses
        showTravelNoticePenaltyAmount
        agiloftDefaultTravelNoticeClause
      }
    }
  }
`;
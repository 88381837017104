import { gql } from '@apollo/client';

export const GET_CDW_ROLES_RATES_BY_PRACTICE_IDS_QUERY = gql`
  query getCdwRolesAndRatesByPracticeIdsQuery(
    $getCdwRolesAndRatesByPracticeIdsPracticeIds: [String]!
  ) {
    getCdwRolesAndRatesByPracticeIds(
      practiceIds: $getCdwRolesAndRatesByPracticeIdsPracticeIds
    ) {
      count
      rolesAndRates {
        cdwRateId
        id
        # locationId
        name
        # cost
        # otCost
        practice
        projectManager
        rate
        # roleId
        agiloftRole
      }
    }
  }
`;

export default GET_CDW_ROLES_RATES_BY_PRACTICE_IDS_QUERY;

// SAMPLE VARIABLES
// {
//   "getCdwRolesAndRatesByPracticeIdsPracticeIds": ["63"]
// }

import { useMutation } from '@apollo/client';
import { CiscoAnnuityLine } from '@cdw-selline/common/types';
import {
  GET_CISCO_ANNUITY_QUOTE_BY_PROJECT_ITEM_ID_QUERY,
  UPDATE_CISCO_ANNUITY_LINE_MUTATION,
  REMOVE_CISCO_ANNUITY_LINE_MUTATION,
} from '@cdw-selline/ui/queries';
import omitDeep from 'omit-deep-lodash';

import { useApolloErrors } from '..';

export const useCiscoAnnuityLineUpdate = (completionCallback?: () => void) => {
  const { handleErrorResponse } = useApolloErrors();

  const [updateCiscoAnnuityLine, { loading, error }] = useMutation(
    UPDATE_CISCO_ANNUITY_LINE_MUTATION,
    {
      refetchQueries: [GET_CISCO_ANNUITY_QUOTE_BY_PROJECT_ITEM_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update Cisco Annuity Line!'),
      onCompleted: completionCallback,
    }
  );

  const [removeCiscoAnnuityLine, { loading: deleteLoading, error: deleteError }] = useMutation(
    REMOVE_CISCO_ANNUITY_LINE_MUTATION,
    {
      refetchQueries: [GET_CISCO_ANNUITY_QUOTE_BY_PROJECT_ITEM_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to delete Cisco Annuity Line!'),
      onCompleted: completionCallback,
    }
  );

  const handleCiscoAnnuityLineUpdate = (
    updatedCiscoAnnuityLine: CiscoAnnuityLine
  ) => {
    updateCiscoAnnuityLine({
      variables: {
        params: omitDeep(updatedCiscoAnnuityLine, [
          '__typename',
          'lastModified',
          'cdwPrice',
          'cdwTotalPrice',
          'customerPrice',
          'customerTotalPrice',
          'customerTermTotal',
          'gp',
        ]),
      },
    });
  };

  const handleCiscoAnnuityLineRemove = (
    lineId: string
  ) => {
    removeCiscoAnnuityLine({
      variables: {
        id: lineId,
      }
    });
  };

  return { handleCiscoAnnuityLineUpdate, loading, handleCiscoAnnuityLineRemove };
};

import { gql } from '@apollo/client';


export const UPDATE_CISCO_ANNUITY_PART_NUMBERS_MUTATION = gql`
  mutation updateCiscoAnnuityPartNumbers(
    $params: [CiscoAnnuityPartNumberInput]
  ) {
    updateCiscoAnnuityPartNumbers(params: $params) {
      success
    }
  }
`;

import * as React from 'react';
import Logo from './salesforce-logo.png';
interface SalesforceLogoProps{
  height?:string
  className?:string,
  onClick?: any
}
export function SalesforceSvgLogo(props: SalesforceLogoProps) {
  return <img alt='Salesforce logo' src={Logo} {...props} />;
}
export default SalesforceSvgLogo;

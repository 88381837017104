import { ApolloError } from '@apollo/client';
import { Practice, RoleAndRate } from '@cdw-selline/common/types';
import {
  EstimateControls,
  EstimateDetails,
  EstimateTotals,
  NewSiteDialog,
  EditSiteCard,
  SiteListView,
} from '@cdw-selline/ui/components';
import {
  useOpenState,
  useProjectSitesAndTasks,
} from '@cdw-selline/ui/hooks';
import { Button, Grid, FormControlLabel, Switch, useTheme, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

export interface EstimatorTabProps {
  projectItemId: string;
  currentPractices: Practice[];
  currentRolesAndRates: RoleAndRate[];
  practicesAndRatesLoading: boolean;
  practicesAndRatesError: ApolloError;
  isReadOnly: boolean;
}

export const EstimatorTab = React.memo(
  ({
    projectItemId,
    currentPractices,
    currentRolesAndRates,
    practicesAndRatesLoading,
    practicesAndRatesError,
    isReadOnly
  }: EstimatorTabProps) => {
    const {
      isOpen: newSiteDialogOpen,
      handleClose: handleNewSiteDialogClose,
      handleOpen: handleNewSiteDialogOpen,
    } = useOpenState();
    const localValue = JSON.parse(localStorage.getItem(`SiteListView-estimator-${projectItemId}`)) ?? false as boolean;
    const loading = practicesAndRatesLoading;
    const [isEditSites, setIsEditSites] = useState(false);
    const [isSiteListView, setIsSiteListView] = useState(localValue);
    const theme = useTheme();
    const [TabView,setTabView] = useState(localStorage.getItem("TabView") === "true" ? true : false);
    const { sites, sitesLoading, sitesError } = useProjectSitesAndTasks(projectItemId);

    //TODO: Update charCode to not use deprecated event.which
    const handleKeyDown = (event: KeyboardEvent) => {
      const charCode = String.fromCharCode(event.which).toLowerCase();

      if(event.ctrlKey && charCode === 'a') {
        handleNewSiteDialogOpen();
      }
    }

    const handleSetSiteListView = ()=> {
      localStorage.setItem(`SiteListView-estimator-${projectItemId}`, JSON.stringify(Boolean(!isSiteListView)));
      setIsSiteListView(!isSiteListView);
    }

    useEffect(() => {
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }, []);

    const {
      isOpen: drawerOpen,
      handleClose: handleDrawerClose,
      handleOpen: handleDrawerOpen,
    } = useOpenState();

    const [clickedSiteId, setClickedSiteId] = useState('');
    const handleAddTaskGroup = (siteId: string) => {
      setClickedSiteId(siteId);
      handleDrawerOpen();
    };

    return (
      <Grid
        container
        spacing={1}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          ml: '0em',
          mr: '0em',
          mb: '2em',
          mt: '1em',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            mb: '1em',
            flexWrap: 'wrap',
            paddingRight: '8px',
          }}
        >
          <EstimateTotals
            {...{
              projectItemId: projectItemId,
              loading,
            }}
          />
        </Grid>
        <Grid container spacing={1} ml={1}>
          <Grid item>
            {!isReadOnly && <Button
              size="large"
              style={{ height: '4em' }}
              data-testid="add-site-button"
              variant="outlined"
              onClick={handleNewSiteDialogOpen}
            >
              + Add Site
            </Button>}
            {!isReadOnly && <Button
              size="large"
              style={{ height: '4em' }}
              sx={{ marginLeft: 1 }}
              data-testid="edit-site-button"
              variant="outlined"
              onClick={()=>setIsEditSites(!isEditSites)}
            >
              + Edit Sites
            </Button>}
            {process.env.NX_PROJECT_ID === 'selline-dev' && !isReadOnly && <FormControlLabel
                sx={{ marginLeft: 1}}
                control={
                  <Switch
                    checked={isSiteListView}
                    onChange={handleSetSiteListView}
                  />
                }
                label={<Typography sx={{color: theme.palette.primary.main }}>Site List View</Typography>}
              />}
            <FormControlLabel
              sx={{ marginLeft: 1}}
              control={
                <Switch
                  checked={TabView}
                  onChange={() => {
                    localStorage.setItem("TabView",`${!TabView}`);
                    setTabView(!TabView);
                  }}
                />
              }
              label={<Typography sx={{color: theme.palette.primary.main }}>Tab View</Typography>}
            />
          </Grid>
          <Grid item>
            <EstimateControls
              {...{
                drawerOpen,
                handleDrawerClose,
                handleDrawerOpen,
                clickedSiteId,
                projectItemId: projectItemId,
                currentPractices: currentPractices,
                currentRolesAndRates: currentRolesAndRates,
                loading,
                handleNewSiteDialogOpen,
                sites,
              }}
            />
          </Grid>
        </Grid>
        <Grid item sx={{ marginBottom: '2em', paddingRight: '8px' }} xs={12}>
          {!isEditSites && !isSiteListView && <EstimateDetails
            handleAddTaskGroup={handleAddTaskGroup}
            projectItemId={projectItemId}
            currentRolesAndRates={currentRolesAndRates}
            currentPractices={currentPractices}
            sites={sites}
            sitesLoading={sitesLoading}
            sitesError={sitesError}
            isReadOnly={isReadOnly}
          />}
          {isEditSites && <EditSiteCard setIsEditSites={setIsEditSites}></EditSiteCard>}
          {isSiteListView && <SiteListView isReadOnly={isReadOnly}></SiteListView>}
        </Grid>
        <NewSiteDialog
          isOpen={newSiteDialogOpen}
          handleClose={handleNewSiteDialogClose}
          {...{ projectItemId: projectItemId }}
        />
      </Grid>
    );
  }
);

export default EstimatorTab;

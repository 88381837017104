import { gql } from '@apollo/client';

export const ADD_SOW_SECTION_MUTATION = gql`
  mutation addSowSectionMutation($params: SowSectionInput) {
    addSowSection(params: $params) {
      id
      success
    }
  }
`;

// VARIABLES
// {
//   ...SowSectionInput
// }

import { TaskNote } from '@cdw-selline/common/types';
import { ApolloError } from '@apollo/client';
import { estimatorStrings, labelStrings } from '@cdw-selline/ui/constants';
import {
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useOpenState, useRemoveTaskNote } from '@cdw-selline/ui/hooks';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { getCurrentUser } from '@cdw-selline/ui/state';
import { format } from 'date-fns';
import { DialogConfirm } from '../../../../../dialog-confirm/DialogConfirm';

export interface TaskNoteListProps {
  taskNotes: TaskNote[];
  setNewNote: React.Dispatch<React.SetStateAction<boolean>>;
  handleClose: () => void;
  taskNotesLoading: boolean;
  taskNotesError: ApolloError;
}

export function TaskNoteList({
  taskNotes,
  setNewNote,
  handleClose,
  taskNotesLoading,
  taskNotesError,
}: TaskNoteListProps) {
  const currentUser = getCurrentUser();
  const userEmail = currentUser ? currentUser.email : {};
  const [removeNote, setRemoveNote] = useState({});

  const {
    handleRemoveTaskNote,
    loading: removeNoteLoading,
    error: removeNoteError,
  } = useRemoveTaskNote();

  const removeTaskNote = (note, userEmail) => {
    handleRemoveTaskNote(note, userEmail);
  };

  const {
    isOpen: isConfirmOpen,
    handleClose: handleConfirmClose,
    handleOpen: handleConfirmOpen,
  } = useOpenState();
  const handleConfirmYes = () => {
    setRemoveNote({});
    handleConfirmClose();
    removeTaskNote(removeNote, userEmail);
  };

  const handleRemoveClick = (note) => {
    setRemoveNote(note);
    handleConfirmOpen();
  };

  const handleRemoveCancel = () => {
    setRemoveNote({});
    handleConfirmClose();
  };

  if (removeNoteLoading || taskNotesLoading) return <CircularProgress />;
  if (removeNoteError || taskNotesError)
    return <Typography>{estimatorStrings.FETCH_NOTE_ERROR}</Typography>;

  return (
    <List aria-label="task note list">
      {taskNotes.length > 0 ? (
        taskNotes.map((note: TaskNote) => (
          <ListItem key={note.id} divider>
            <Grid container spacing={2}>
              <Grid item xs={11}>
                <Stack>
                  <ListItemText primary={note.note} />
                  <Typography variant="body2" aria-label="note type">
                    {note.noteType}
                  </Typography>
                  <Typography variant="body1" aria-label="note author">
                    {note.createdBy}
                  </Typography>
                  <Typography variant="body1" aria-label="note created at">
                    {format(new Date(note.createdAt), "MM/dd/yyyy 'at' h:mm a")}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={1}>
                {userEmail === note.createdBy && (
                  <Button color="error" onClick={() => handleRemoveClick(note)}>
                    {labelStrings.REMOVE}
                  </Button>
                )}
              </Grid>
            </Grid>
          </ListItem>
        ))
      ) : (
        <Typography>{estimatorStrings.NO_NOTES}</Typography>
      )}
      <DialogConfirm
        {...{
          isOpen: isConfirmOpen,
          handleClose: handleRemoveCancel,
          title: 'Remove Note',
          handleYes: handleConfirmYes,
        }}
      >
        <Typography>Remove note?</Typography>
      </DialogConfirm>
    </List>
  );
}

export default TaskNoteList;

import { CollectionsDataTable, TierForm } from '@cdw-selline/ui/components';
import { useAdminTiers, useTierById } from '@cdw-selline/ui/hooks';
import { Box } from '@mui/material';
import React from 'react';

export const TiersCollectionPage = () => {
  const dataTableProps = useAdminTiers();

  const {
    data: tier,
    loading: tierLoading,
    error: tierError,
  } = useTierById(dataTableProps.editTierId);

  const openForm =
    dataTableProps.tierFormOpen &&
    ((dataTableProps.editTierId && tier.id) || !dataTableProps.editTierId);

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <CollectionsDataTable
        {...dataTableProps}
        height="calc(100vh - 13em)"
        paginationMode="server"
        onRowClick={(o) => dataTableProps.handleEdit(o.id)}
        parent="TiersCollectionPage"
      />
      {openForm && (
        <TierForm
          isOpen={dataTableProps.tierFormOpen}
          handleClose={dataTableProps.handleTierFormClose}
          handleSubmit={dataTableProps.handleTierFormSave}
          tier={tier}
        />
      )}
    </Box>
  );
};

export default TiersCollectionPage;

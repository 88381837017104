import { CollectionsDataTable, AgiloftTravelNoticeTypeForm } from '@cdw-selline/ui/components';
import { useAgiloftTravelNoticeTypeById, useAdminAgiloftTravelNoticeTypes } from '@cdw-selline/ui/hooks';
import { Box } from '@mui/material';
import React from 'react';

/* eslint-disable-next-line */
export interface AgiloftTravelNoticeTypesCollectionPageProps {}

export const AgiloftTravelNoticeTypesCollectionPage = (props: AgiloftTravelNoticeTypesCollectionPageProps) => {
  const dataTableProps = useAdminAgiloftTravelNoticeTypes();

  const {
    data: agiloftTravelNoticeType,
    loading: agiloftTravelNoticeTypeLoading,
    error: agiloftTravelNoticeTypeError,
  } = useAgiloftTravelNoticeTypeById(dataTableProps.editAgiloftTravelNoticeTypeId);

  const openForm =
    dataTableProps.agiloftTravelNoticeTypeFormOpen &&
    ((dataTableProps.editAgiloftTravelNoticeTypeId && agiloftTravelNoticeType.id) || !dataTableProps.editAgiloftTravelNoticeTypeId);

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <CollectionsDataTable
        {...dataTableProps}
        height="calc(100vh - 13em)"
        paginationMode="server"
        sortingMode="server"
        onRowClick={(o) => dataTableProps.handleEdit(o.id)}
        parent="AgiloftTravelNoticeTypesCollectionPage"
      />

      {openForm && (
        <AgiloftTravelNoticeTypeForm
          isOpen={dataTableProps.agiloftTravelNoticeTypeFormOpen}
          handleClose={dataTableProps.handleAgiloftTravelNoticeTypeFormClose}
          handleSubmit={dataTableProps.handleAgiloftTravelNoticeTypeFormSave}
          agiloftTravelNoticeType={agiloftTravelNoticeType}
        />
      )}
    </Box>
  );
};

export default AgiloftTravelNoticeTypesCollectionPage;

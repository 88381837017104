import { gql } from '@apollo/client';

export const GET_LEADS_QUERY = gql`
  query getLeads($limit: Int, $offset: Int, $filters: MongoFilter) {
    getLeads(limit: $limit, offset: $offset, filters: $filters) {
      id
      customerDescription
      name
      owner
      status
      primarySa
      secondarySa
      primarySales
      secondarySales
      additionalInformation
      associatedProjects
    }
  }
`;

export default GET_LEADS_QUERY;

import { useMutation } from '@apollo/client';
import { DefaultMutationResponseWithId } from '@cdw-selline/common/types';
import {
  GET_ESTIMATOR_SUMMARY_TASKS_QUERY,
  GET_ESTIMATOR_TOTALS_BY_ITEM_ID,
  UPDATE_PROJECT_ITEMS_ESTIMATOR_CALCULATION_MUTATION,
  GET_PROJECT_QUERY,
} from '@cdw-selline/ui/queries';

export const useEstimatorCalculation = () => {
  const [
    updateProjectItemEstimatorCalculation,
    {
      loading: updateProjectItemEstimatorCalculationLoading,
      error: updateProjectItemEstimatorCalculationError,
      client: updateProjectItemEstimatorCalculationClient,
    },
  ] = useMutation<{
    updateProjectItemEstimatorCalculation: DefaultMutationResponseWithId;
  }>(UPDATE_PROJECT_ITEMS_ESTIMATOR_CALCULATION_MUTATION, {
    refetchQueries: [
      GET_ESTIMATOR_SUMMARY_TASKS_QUERY,
      GET_ESTIMATOR_TOTALS_BY_ITEM_ID,
      GET_PROJECT_QUERY,
    ],
  });

  return {
    updateProjectItemEstimatorCalculation,
    updateProjectItemEstimatorCalculationLoading,
    updateProjectItemEstimatorCalculationError,
  };
};
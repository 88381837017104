import { gql } from '@apollo/client';

export const GET_ESTIMATOR_SOW_QUERY = gql`
  query getSowByProjectItemId($projectItemId: ID!) {
    getSowByProjectItemId(projectItemId: $projectItemId) {
      id
      version
      createdAt
      createdBy
      updatedAt
      updatedBy
      content
      taskGroup
      outcomeBased
      outcomeIncluded
      executiveSummaryOverview
      executiveSummaryOverviewHelp
      executiveSummaryDrivers
      executiveSummaryDriversHelp
      businessOutcome
      businessOutcomeHelp
      technicalOutcome
      technicalOutcomeHelp
      outcomeProjectScopePhases
      outcomeProjectScopePhasesHelp
      agiloftSowId
      agiloftSentStatus
    }
  }
`;

export default GET_ESTIMATOR_SOW_QUERY;

import { gql } from '@apollo/client';

export const UPDATE_PROJECT_SOW_MUTATION = gql`
  mutation updateProjectSowMutation($params: ProjectSowInput) {
    updateProjectSow(params: $params) {
      success
    }
  }
`;

// VARIABLES
// {
//   ...ProjectSow
// }

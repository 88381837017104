/* eslint-disable @nx/enforce-module-boundaries */
import { useManagedServicesSummary } from '@cdw-selline/ui/hooks';
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import { formatCurrency } from '@cdw-selline/ui/helpers';

export const ManagedServicesSummary = () => {
  const { managedServicesSummary, error, loading } =
    useManagedServicesSummary();
  const theme = useTheme();

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3} data-testid="managed-services-grid">
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="Managed Services Summary"
              sx={{
                backgroundColor: theme.palette.primary.dark,
                padding: '5px 15px',
              }}
              titleTypographyProps={{
                variant: 'h6',
                color: theme.palette.common.white,
              }}
            />
            <Divider />
            <Box sx={{ minWidth: 400 }}>
              <Table size="small">
                <TableBody>
                  {error && (
                    <Typography color="error" variant="h6">
                      {error.message}
                    </Typography>
                  )}
                  {loading && (
                    <TableRow>
                      <TableCell>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                  {!error && !loading && (
                    <>
                      <TableRow
                        hover
                        key={`gross-profit`}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>Gross Profit Dollar:</TableCell>
                        <TableCell align="right">
                          {formatCurrency(managedServicesSummary.grossProfit)}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        hover
                        key={`gross-profit-percent`}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>Gross Profit Percent:</TableCell>
                        <TableCell align="right">
                          {`${managedServicesSummary.grossProfitPercent}%`}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ManagedServicesSummary;

import { State, StateValueAtRoot } from '@hookstate/core';

const MyStateWatchPluginId = Symbol('MyStateWatchPlugin');

export function MyStateWatchPlugin() {
  return {
    id: MyStateWatchPluginId,
    init: (s: State<StateValueAtRoot>) => {
      console.info('plugin attached');
      return {
        onSet: (data) => {
          console.info('new state set', data.state);
          console.info('at path', data.path);
          console.info('to a new value', data.value);
          console.info('from old value', data.previous);
          console.info('merged with', data.merged);
        },
        onDestroy: (data) => {
          console.info('state detroyed', data.state);
        },
        onBatchStart: (data) => {
          console.info('batch started', data.state);
          console.info('at path', data.path);
          console.info('with context', data.context);
        },
        onBatchFinish: (data) => {
          console.info('batch finished', data.state);
          console.info('with context', data.context);
        },
      };
    },
  };
}

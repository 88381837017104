import { gql } from '@apollo/client';

export const GET_SCOPE_ITEM_BY_ID_QUERY = gql`
  query GetScopeItemById($getScopeItemByIdId: ID!) {
    getScopeItemById(id: $getScopeItemByIdId) {
      id
      category
      collateral {
        description
        link
        title
      }
      legacyId
      order
      notes
      parentItemDataId
      parentItemDataValue
      parentItemId
      practiceId
      questions {
        createdBy
        createdOn
        list {
          value
          name
        }
        name
        order
        title
        type
      }
      scopeGroupId
      title
      trainings {
        description
        link
        title
      }
      version
    }
  }
`;

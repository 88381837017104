import { useQuery } from '@apollo/client';
import { GetServicePracticesResponse } from '@cdw-selline/common/types';
import { GET_ADMIN_SERVICE_PRACTICES } from '@cdw-selline/ui/queries';

import { useApolloErrors } from './useApolloErrors';

export interface UseServicePractices {
  filters?: any;
  offset?: number;
  limit?: number;
  sort?: unknown;
}

export const useServicePractices = ({
  filters,
  offset,
  limit,
  sort,
}: UseServicePractices) => {
  const { handleErrorResponse } = useApolloErrors();

  const { loading, error, data, refetch } = useQuery<{
    getServicePractices: GetServicePracticesResponse;
  }>(GET_ADMIN_SERVICE_PRACTICES, {
    variables: {
        offset: offset ?? 0,
        limit: limit ?? 500,
        filters: filters ?? {},
        sort: sort ?? { name: 'asc' },
      },
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch ServicePractices!'),
  });

  return {
    data: data?.getServicePractices ?? { servicePractices: [], count: 0 },
    error,
    loading,
    refetch,
  };
};

export default useServicePractices;
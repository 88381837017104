import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CardContent,
  DialogActions,
  FormLabel,
  Stack,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import React, { FormEvent } from 'react';

/* eslint-disable-next-line */
export interface CollectionDetailFormProps {
  formTitle: string;
  handleCancel: () => void;
  handleSubmit: (e: FormEvent) => void;
  updateLoading: boolean;
  children: JSX.Element | JSX.Element[];
}

export function CollectionDetailForm({
  formTitle,
  handleCancel,
  handleSubmit,
  updateLoading,
  children,
}: CollectionDetailFormProps) {
  return (
    <Card
      sx={{
        flexWrap: 'wrap',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#fff',
        width: '90%',
        m: '2em',
        p: '1em',
      }}
    >
      <CardContent style={{ maxWidth: '50em' }}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <FormLabel>{formTitle}</FormLabel>
            {children}
          </Stack>
          <DialogActions>
            <Button color="primary" onClick={handleCancel}>
              Cancel
            </Button>
            <LoadingButton
              color="primary"
              type="submit"
              loading={updateLoading}
              endIcon={<SaveIcon />}
              variant="contained"
            >
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </CardContent>
    </Card>
  );
}

export default CollectionDetailForm;

import { CollectionsDataTable, AgiloftExpenseTypeForm } from '@cdw-selline/ui/components';
import { useAgiloftExpenseTypeById, useAdminAgiloftExpenseTypes } from '@cdw-selline/ui/hooks';
import { Box } from '@mui/material';
import React from 'react';

/* eslint-disable-next-line */
export interface AgiloftExpenseTypesCollectionPageProps {}

export const AgiloftExpenseTypesCollectionPage = (props: AgiloftExpenseTypesCollectionPageProps) => {
  const dataTableProps = useAdminAgiloftExpenseTypes();

  const {
    data: agiloftExpenseType,
    loading: agiloftExpenseTypeLoading,
    error: agiloftExpenseTypeError,
  } = useAgiloftExpenseTypeById(dataTableProps.editAgiloftExpenseTypeId);

  const openForm =
    dataTableProps.agiloftExpenseTypeFormOpen &&
    ((dataTableProps.editAgiloftExpenseTypeId && agiloftExpenseType.id) || !dataTableProps.editAgiloftExpenseTypeId);

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <CollectionsDataTable
        {...dataTableProps}
        height="calc(100vh - 13em)"
        paginationMode="server"
        sortingMode="server"
        onRowClick={(o) => dataTableProps.handleEdit(o.id)}
        parent="AgiloftExpenseTypesCollectionPage"
      />

      {openForm && (
        <AgiloftExpenseTypeForm
          isOpen={dataTableProps.agiloftExpenseTypeFormOpen}
          handleClose={dataTableProps.handleAgiloftExpenseTypeFormClose}
          handleSubmit={dataTableProps.handleAgiloftExpenseTypeFormSave}
          agiloftExpenseType={agiloftExpenseType}
        />
      )}
    </Box>
  );
};

export default AgiloftExpenseTypesCollectionPage;

import { gql } from '@apollo/client';

export const GET_CUSTOMERS_BY_OPPORTUNITIES_QUERY = gql`
  query getCustomersByOpportunities(
    $limit: Int
    $offset: Int
    $filters: MongoFilter
  ) {
    getCustomersByOpportunities(
      limit: $limit
      offset: $offset
      filters: $filters
    ) {
      count
      customers {
        id
        name
        customerCode
        companyCode
        region
        sector
        area
      }
    }
  }
`;

export default GET_CUSTOMERS_BY_OPPORTUNITIES_QUERY;

import { useQuery } from '@apollo/client';
import { GetTiersResponse } from '@cdw-selline/common/types';
import { GET_TIERS_QUERY } from '@cdw-selline/ui/queries';
import { useParams } from 'react-router-dom';

import { useApolloErrors } from './useApolloErrors';

export interface UseTiers {
  filters?: any;
  offset?: number;
  limit?: number;
  sort?: unknown;
}

export const useTiers = ({ filters, offset, limit, sort }: UseTiers) => {
  const { id } = useParams<{ id: string }>();
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getTiers: GetTiersResponse;
  }>(GET_TIERS_QUERY, {
    variables: {
      offset: offset ?? 0,
      limit: limit ?? 100,
      filters: filters ?? {},
      sort,
    },
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    skip: !!id,
    onError: (error) => handleErrorResponse(error, 'Failed to fetch Tiers!'),
  });

  return {
    data: data?.getTiers ?? { tiers: [], count: 0 },
    error,
    loading,
    refetch,
  };
};

export default useTiers;

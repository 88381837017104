import CloseIcon from '@mui/icons-material/Close';
import { Card, IconButton, Popover } from '@mui/material';
import React from 'react';
import { useGetProjectProposalById } from '@cdw-selline/ui/hooks';
export interface ProposalPreviewModalProps {
  isOpen: boolean;
  handleClose: () => void;
  proposalId: string;
}

export function ProposalPreviewModal({
  isOpen,
  handleClose,
  proposalId,
}: ProposalPreviewModalProps) {
  const infoStrings = {
    NO_PREVIEW: 'No preview found',
  };

  const {
    data: currentProposal,
    loading: currentProposalLoading,
    error: currentProposalError,
  } = useGetProjectProposalById(proposalId);
  return (
    <>
      {!currentProposalLoading && (
        <Popover
          open={isOpen}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          sx={{ width: '80vw', margin: '2em' }}
          BackdropProps={{ sx: { color: '#fff' } }}
        >
          <Card
            sx={{ display: 'flex', flexDirection: 'column', padding: '2em' }}
          >
            <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
              <CloseIcon />
            </IconButton>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  currentProposal?.previewContent ?? infoStrings.NO_PREVIEW,
              }}
            ></div>
          </Card>
        </Popover>
      )}
    </>
  );
}

export default ProposalPreviewModal;

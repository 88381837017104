import { useMutation, useQuery } from '@apollo/client';
import {
  DefaultAddResponse,
  DefaultMutationResponse,
  GetScopeItemsResponse,
  ScopeItem,
  ScopeItemInput,
} from '@cdw-selline/common/types';
import {
  ADD_SCOPE_ITEM_MUTATION,
  GET_SCOPE_ITEMS_QUERY,
  GET_SCOPE_ITEM_BY_ID_QUERY,
  REMOVE_SCOPE_ITEM_MUTATION,
  UPDATE_SCOPE_ITEM_MUTATION,
} from '@cdw-selline/ui/queries';
import { useApolloErrors } from '../useApolloErrors';

export const useGetScopeItems = (params?: {
  filters?: unknown;
  offset?: number;
  limit?: number;
  sort?: unknown;
}) => {
  const { handleErrorResponse } = useApolloErrors();
  const { data, loading, error, refetch } = useQuery<{
    getScopeItems: GetScopeItemsResponse;
  }>(GET_SCOPE_ITEMS_QUERY, {
    variables: params ?? {},
    fetchPolicy: 'cache-and-network',
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch scope items!'),
  });
  return {
    data: data?.getScopeItems ?? { count: 0, scopeItems: [] },
    loading,
    error,
    refetch,
  };
};

export const useGetScopeItemById = (id: string) => {
  const { handleErrorResponse } = useApolloErrors();
  const { data, loading, error } = useQuery<{ getScopeItemById: ScopeItem }>(
    GET_SCOPE_ITEM_BY_ID_QUERY,
    {
      variables: { getScopeItemByIdId: id },
      onError: (error) =>
        handleErrorResponse(error, 'Failed to fetch scope item!'),
    }
  );
  return {
    data: data?.getScopeItemById ?? null,
    loading,
    error,
  };
};

export const useAddScopeItem = (
  addScopeItemCallback?: (args?: unknown) => void
) => {
  const { handleErrorResponse } = useApolloErrors();

  const [addScopeItem, { loading, error }] = useMutation<{
    addScopeGroup: DefaultAddResponse;
  }>(ADD_SCOPE_ITEM_MUTATION, {
    refetchQueries: [GET_SCOPE_ITEMS_QUERY, GET_SCOPE_ITEM_BY_ID_QUERY],
    onError: (error) => handleErrorResponse(error, 'Failed to add scope item!'),
    onCompleted: addScopeItemCallback,
  });

  const handleAddScopeItem = (newScopeItem: ScopeItemInput) => {
    addScopeItem({ variables: { params: newScopeItem } });
  };

  return { handleAddScopeItem, loading, error };
};

export const useUpdateScopeItem = (
  updateScopeItemCallback?: (args?: unknown) => void
) => {
  const { handleErrorResponse } = useApolloErrors();

  const [updateScopeItem, { loading, error }] = useMutation<{
    updateScopeItem: DefaultMutationResponse;
  }>(UPDATE_SCOPE_ITEM_MUTATION, {
    refetchQueries: [GET_SCOPE_ITEMS_QUERY, GET_SCOPE_ITEM_BY_ID_QUERY],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to update scope item!'),
    onCompleted: updateScopeItemCallback,
  });

  const handleUpdateScopeItem = (
    updateScopeItemId: string,
    modifiedScopeItem: ScopeItemInput
  ) => {
    updateScopeItem({ variables: { updateScopeItemId, modifiedScopeItem } });
  };

  return { handleUpdateScopeItem, loading, error };
};

export const useRemoveScopeItem = (
  removeScopeItemCallback?: (args?: unknown) => void
) => {
  const { handleErrorResponse } = useApolloErrors();

  const [removeScopeItem, { loading, error }] = useMutation<{
    removeScopeItem: DefaultMutationResponse;
  }>(REMOVE_SCOPE_ITEM_MUTATION, {
    refetchQueries: [GET_SCOPE_ITEMS_QUERY, GET_SCOPE_ITEM_BY_ID_QUERY],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to remove scope item!'),
    onCompleted: removeScopeItemCallback,
  });

  const handleRemoveScopeItem = (removeScopeItemId: string) => {
    removeScopeItem({ variables: { removeScopeItemId } });
  };

  return { handleRemoveScopeItem, loading, error };
};

import { useMutation } from '@apollo/client';
import {
  CdwRate,
  DefaultAddResponse,
  DefaultMutationResponse,
} from '@cdw-selline/common/types';
import {
  ADD_CDW_RATE_MUTATION,
  GET_CDW_RATES_QUERY,
  GET_CDW_RATE_BY_ID_QUERY,
  REMOVE_CDW_RATE_MUTATION,
  SYNC_CDW_RATES_MUTATION,
  UPDATE_CDW_RATE_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import {
  GridColDef,
  GridPaginationModel,
  GridRowsProp,
  GridFilterModel,
} from '@mui/x-data-grid';
import { useState } from 'react';
import {
  formatCurrency,
  getFilters,
  getFilterModelFromLocalStorage,
} from '@cdw-selline/ui/helpers';
import omitDeep from 'omit-deep-lodash';
import { useOpenState } from './estimator/useOpenState';
import { useApolloErrors } from './useApolloErrors';
import { useCDWRates } from './useCDWRates';
import { PAGES } from '@cdw-selline/ui/constants';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    editable: false,
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'practiceName',
    headerName: 'Practice',
    flex: 1,
  },
  {
    field: 'cost',
    headerName: 'Cost',
    flex: 1,
    valueFormatter: (value) => {
      return `${formatCurrency(value) ?? 0.0}`;
    },
    type: 'number',
  },
];

export const useAdminCdwRates = () => {
  const [sortState, setSortState] = useState();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  const filterModel: GridFilterModel = getFilterModelFromLocalStorage(`${PAGES.CDW_RATES_COLLECTION}-filter`);


  const { data, loading, error, refetch } = useCDWRates({
    filters: getFilters(PAGES.CDW_RATES_COLLECTION),
    offset: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize,
    sort: sortState,
  });
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();
  const [editCdwRateId, setEditCdwRateId] = useState(null);
  const {
    isOpen: cdwRateFormOpen,
    handleClose: closeCdwRateForm,
    handleOpen: openCdwRateForm,
  } = useOpenState();

  const [addCdwRate, { loading: addCdwRateLoading, error: addCdwRateError }] =
    useMutation<{ addCdwRate: DefaultAddResponse }>(ADD_CDW_RATE_MUTATION, {
      refetchQueries: [GET_CDW_RATES_QUERY, GET_CDW_RATE_BY_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to create new cdwRate'),
      onCompleted: (data) => {
        if (data.addCdwRate.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully added cdwRate',
          });
        }
      },
    });

  const [
    removeCdwRate,
    { loading: removeCdwRateLoading, error: removeCdwRateError },
  ] = useMutation<{ removeCdwRate: DefaultMutationResponse }>(
    REMOVE_CDW_RATE_MUTATION,
    {
      refetchQueries: [GET_CDW_RATES_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to remove cdwRate'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.removeCdwRate.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully removed cdwRate',
          });
        }
      },
    }
  );

  const [
    updateCdwRate,
    { loading: updateCdwRateLoading, error: updateCdwRateError },
  ] = useMutation<{ updateCdwRate: DefaultMutationResponse }>(
    UPDATE_CDW_RATE_MUTATION,
    {
      refetchQueries: [GET_CDW_RATES_QUERY, GET_CDW_RATE_BY_ID_QUERY],
      awaitRefetchQueries: true,
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update cdwRate'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.updateCdwRate.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully updated cdwRate',
          });
        }
      },
    }
  );

  const handleAdd = () => {
    openCdwRateForm();
  };

  const handleCdwRateFormClose = () => {
    setEditCdwRateId(null);
    closeCdwRateForm();
  };

  const handleDelete = (pid: string) =>
    removeCdwRate({
      variables: { removeCdwRateId: pid },
    });
  const handleEdit = (id: string) => {
    setEditCdwRateId(id);
    openCdwRateForm();
  };

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    const pageSize = model?.pageSize ?? paginationModel?.pageSize;
    const page = model?.page ?? paginationModel?.page;
    setPaginationModel({
      page,
      pageSize,
    });
  };

  const onFilterModelChange = (filterModel) => {
    refetch({ filters: getFilters(PAGES.CDW_RATES_COLLECTION) });
  };

  const handleCdwRateFormSave = (cdwRate: CdwRate) => {
    if (!cdwRate.id) {
      addCdwRate({
        variables: {
          params: omitDeep(cdwRate, ['__typename']),
        },
      });
    }

    if (cdwRate.id) {
      updateCdwRate({
        variables: {
          params: omitDeep(cdwRate, ['__typename']),
        },
      });
    }
  };

  const handleSort = (args) => {
    let newSort;
    if (args.length) newSort = { [args[0].field]: args[0].sort };
    setSortState(newSort);
  };

  const [
    syncCdwRates,
    { loading: syncCdwRatesLoading, error: syncCdwRatesError },
  ] = useMutation(SYNC_CDW_RATES_MUTATION, {
    refetchQueries: [GET_CDW_RATES_QUERY],
    onError: (error) => handleErrorResponse(error, 'Failed to sync CDW rates!'),
    onCompleted: (data) => {
      if (data.syncCdwRates.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully synced CDW rates!',
        });
      }
    },
  });

  const returnValue = {
    columns,
    rows: data.rates || [],
    handleDelete,
    handleEdit,
    handlePaginationModelChange,
    onFilterModelChange,
    handleSort,
    filterModel: filterModel,
    cdwRates: data.rates,
    editCdwRateId,
    addCdwRateLoading,
    addCdwRateError,
    removeCdwRateLoading,
    removeCdwRateError,
    loading: loading || addCdwRateLoading || removeCdwRateLoading,
    error,
    cdwRateFormOpen,
    handleCdwRateFormClose,
    handleCdwRateFormSave,
    rowCount: data.count,
    syncCdwRates,
  };

  if (loading || addCdwRateLoading || removeCdwRateLoading) {
    return {
      ...returnValue,
      paginationModel: { pageSize: 100, page: 0 },
      rowCount: 0,
      rows: [],
    };
  } else {
    return { ...returnValue, paginationModel };
  }
};

export interface StashedRouteData {
  key?: string; // not with HashHistory!
  pathname: string;
  search: string;
  hash: string;
  state?: unknown;
}

const PERSISTENCE_KEY_REDIRECT = `login-redirect-route`;

export function getStashedRoute(
  clear = false
): StashedRouteData | null | undefined {
  const foundRoute = localStorage?.getItem(PERSISTENCE_KEY_REDIRECT);
  if (!foundRoute) return null;
  try {
    if (clear) {
      setStashedRoute(null);
    }
    return JSON.parse(foundRoute);
  } catch (e) {
    console.error(`Unusual error with state object:`, e);
    setStashedRoute(null);
    return null;
  }
}

export function setStashedRoute(route: StashedRouteData | null) {
  if (!route) {
    localStorage?.removeItem(PERSISTENCE_KEY_REDIRECT);
  } else {
    localStorage?.setItem(PERSISTENCE_KEY_REDIRECT, JSON.stringify(route));
  }
}

import { Stack, Typography, styled } from '@mui/material';
import React from 'react';

const RootContainer = styled(Stack)(
  ({ theme }) => `
  width: 100%;
`
);

/* eslint-disable-next-line */
export interface HomePageProps {}

// TODO this is technically in the app as the component for the /sign-in route but it is never seen? Determine whether it can be removed

export function HomePage(props: HomePageProps) {
  return (
    <RootContainer>
      <Typography variant="h1">Welcome to HomePage!</Typography>
      <Typography variant="body1">
        This will need to have the updated spacing above for app bar
      </Typography>
    </RootContainer>
  );
}

export default HomePage;

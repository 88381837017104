/*
    const STATUS_NEW = 1;
    const STATUS_QUOTED = 2;
    const STATUS_COMMITTING = 3;
    const STATUS_COMMITTED = 4;
    const STATUS_APPROVAL = 5;
    const STATUS_ORDERING = 6;
    const STATUS_ORDERED = 7;

    const STATUS_ASSIGNED = 8;
    const STATUS_SETUP = 9;
    const STATUS_ONBOARDING = 10;
    const STATUS_LIFECYCLE = 11;
    const STATUS_RETENTION = 12;
*/

export enum ciscoAnnuityStatus {
  'Created'='Created',
  'Quoted'='Quoted',
  'COF Delivered' = 'COF Delivered',
  'COF Signed' = 'COF Signed',
  'Ordered' = 'Ordered',
}

export enum ciscoAnnuityStatusActiveStep {
  'Created' = 1,
  'Quoted' = 2,
  'COF Delivered' = 3,
  'COF Signed' = 4,
  'Ordered' = 5,
}

export enum ciscoAnnuityStatusActionLabel {
  'Created' = 'Quoted',
  'Quoted' = 'COF Delivered',
  'COF Delivered' = 'COF Signed',
  'COF Signed' = 'Order Complete',
}

interface StatusStep {
  name: string;
  cloudFulfillmentOnly: boolean;
}

export const ciscoAnnuityStatusSteps: Readonly<StatusStep[]> = Object.freeze([
  {name: 'Created', cloudFulfillmentOnly:false },
  {name: 'Quoted', cloudFulfillmentOnly:false },
  //COF Requested
  {name: 'COF Delivered', cloudFulfillmentOnly:false }, //COF Created
  {name: 'COF Signed', cloudFulfillmentOnly:false }, 
  {name: 'Ordered', cloudFulfillmentOnly:true },
]);

export const CISCO_ANNUITY_TERM_LENGTHS = [
  { label: '12 Months', value: 12 },
  { label: '24 Months', value: 24 },
  { label: '36 Months', value: 36 },
  { label: '48 Months', value: 48 },
  { label: '60 Months', value: 60 },
];

import { gql } from '@apollo/client';

export const GET_NEW_LEAD_SCHEMA_QUERY = gql`
  query getNewLeadSchemaQuery($getSchemaId: ID!, $searchTerm: String) {
    getSchema(id: $getSchemaId, searchTerm: $searchTerm) {
      uiSchema: uiSchemaV2
      schema {
        type
        title
        required
        description
        properties {
          ... on NewLeadProperties {
            customerDescription {
              enum(searchTerm: $searchTerm)
              minLength
              title
              type
            }
            name {
              required
              title
              type
            }
            owner {
              required
              title
              type
            }
            status {
              required
              title
              type
            }
          }
        }
      }
    }
  }
`;

// VARIABLES
// {
//   getSchemaId: "new-Lead"
// }

/* eslint-disable @nx/enforce-module-boundaries */
import { useAdminDashboard, useUsers } from '@cdw-selline/ui/hooks';
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import * as React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from '@cdw-selline/ui/state';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CollectionsTableProps {}

export const CollectionsTable: React.FC<CollectionsTableProps> = (props) => {
  const { collections, error, loading } = useAdminDashboard();
  const navigate = useNavigate();

  const routeTo = (collectionName: string) => {
    navigate(`/admin/collection/${collectionName}`);
  };

  const currentUser = getCurrentUser();
  const { data } = useUsers({ email: currentUser.email });

  if (!data[0]?.admin && !data[0]?.contentAdmin)
    return (
      <Typography>
        Admin user status required to access Admin Collections
      </Typography>
    );

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card {...props}>
            <CardHeader title="Manage - Collections" />
            <Divider />
            <PerfectScrollbar>
              <Box sx={{ minWidth: 800 }}>
                {error ? (
                  <Typography color="error" variant="h6">
                    {error.message}
                  </Typography>
                ) : loading ? (
                  <CircularProgress />
                ) : (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Collection Name</TableCell>
                        <TableCell sortDirection="desc">
                          <Tooltip enterDelay={300} title="Sort">
                            <TableSortLabel active direction="desc">
                              LastModified
                            </TableSortLabel>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(collections) &&
                        collections.map((collection) => (
                          <TableRow
                            hover
                            key={collection.name}
                            onClick={() => routeTo(collection.name)}
                            style={{ cursor: 'pointer' }}
                            data-testid={`test-${collection.name}`}
                          >
                            <TableCell>{collection.name}</TableCell>
                            <TableCell>
                              {new Date(
                                new Date(Number(collection.lastModified))
                              ).toLocaleString()}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                )}
              </Box>
            </PerfectScrollbar>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                p: 2,
              }}
            >
              <Typography>Total Collections: {collections.length}</Typography>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CollectionsTable;

import { gql } from '@apollo/client';

export const GET_PROJECT_COMMENTS_BY_PROJECT_ID_QUERY = gql`
  query GetProjectCommentsByProjectId($projectId: ID!) {
    getProjectCommentsByProjectId(projectId: $projectId) {
      comment
      createdAt
      createdBy
      id
    }
  }
`;

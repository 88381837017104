import { useMutation } from '@apollo/client';
import {
  ADD_PROJECT_ITEM_QUOTE_MUTATION,
  GET_PROJECT_ITEM_QUOTES_BY_PROJECT_ITEM_ID_QUERY,
} from '@cdw-selline/ui/queries';
import { useApolloErrors } from '../useApolloErrors';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { ProjectItemQuote } from '@cdw-selline/common/types';

export const useAddProjectItemQuote = (
  completeCallback?: (data: unknown) => void
) => {
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();
  const [addProjectItemQuote, { loading, error }] = useMutation(
    ADD_PROJECT_ITEM_QUOTE_MUTATION,
    {
      refetchQueries: [GET_PROJECT_ITEM_QUOTES_BY_PROJECT_ITEM_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to add Opportunity Item Quote!'),
      onCompleted: (data) => {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully Added Project Item Quote!',
        });
        completeCallback?.(data);
      },
    }
  );

  const handleAddProjectItemQuote = async (
    newQuote: Partial<ProjectItemQuote>
  ) => {
    const addQuoteResponse = await addProjectItemQuote({
      variables: { params: newQuote },
    });
    return addQuoteResponse;
  };

  return {
    handleAddProjectItemQuote,
    loading,
    error,
  };
};

import React, { useCallback, useState } from 'react';
import { Grid, TextField, Autocomplete } from '@mui/material';
import { debounce, startCase } from 'lodash';

export interface FormFieldAutoCompleteProps {
  setTaskFormData?;
  taskFormData?;
  onKeyUp?(event: React.KeyboardEvent<HTMLInputElement>): void;
  label?: string;
  name?: string;
  freeSolo?: boolean;
  useSearchHook?: any;
  idColumnName?: string;
  isUpdateIdAndName?: boolean;
}

export function FormFieldAutoComplete({
  setTaskFormData,
  taskFormData,
  onKeyUp,
  label,
  name,
  freeSolo,
  useSearchHook,
  idColumnName,
  isUpdateIdAndName,
}: FormFieldAutoCompleteProps) {
  const value = name ? taskFormData[name] : taskFormData?.name ?? '';
  const [searchTerm, setSearchTerm] = useState(value ?? '');

  const { data, loading, error } = useSearchHook(searchTerm);
  const fieldOptions =
    data?.map((item) => {
      return {
        id: item.id,
        label: item.name,
        idColumnName: idColumnName,
        isUpdateIdAndName: isUpdateIdAndName,
      };
    }) ?? [];

  const handleAutocompleteChange = (value, name: string) => {
    setTaskFormData({
      ...taskFormData,
      [value?.idColumnName]: value?.id,
      [name]: value?.label,
    });
  };

  const getAutocompleteDefaultValue = (name: string, options: unknown) => {
    if (!Array.isArray(options) || options.length === 0) {
      return '';
    }

    if (
      !taskFormData[name] ||
      taskFormData[name] === 0 ||
      taskFormData[name] === null
    ) {
      return '';
    }
    return options.find((option) => option.id === taskFormData[name])?.label;
  };

  const handleInputChange = (event, newValue) => {
    if (event?.type === 'change') {
      handleAutocompleteChange(newValue, name ?? '');
    }
    handleDebounce(event, newValue);
  };

  const handleDebounce = useCallback(
    debounce((event, newValue) => {
      setSearchTerm(newValue);
    }, 500),
    []
  );

  return (
    <Grid item xs={12} sx={{ width: '100%' }}>
      <Autocomplete
        freeSolo={freeSolo ?? false}
        onKeyUp={onKeyUp}
        tabIndex={2}
        id={name}
        options={fieldOptions}
        sx={{ width: '100%' }}
        noOptionsText={`Type ${startCase(name)}`}
        value={searchTerm}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        onInputChange={handleInputChange}
        onChange={(event, newValue) => {
          handleAutocompleteChange(newValue ?? '', name ?? '');
        }}
        defaultValue={getAutocompleteDefaultValue(name, fieldOptions)}
        data-testid={`new-project-${name}-input`}
        renderInput={(params) => (
          <TextField {...params} label={label ?? `${startCase(name)}`} />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={props.id}>
              {option?.label}
            </li>
          );
        }}
      />
    </Grid>
  );
}

export default FormFieldAutoComplete;

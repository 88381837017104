import { gql } from '@apollo/client';

export const UPDATE_PROJECT_SALESFORCE_DATA_MUTATION = gql`
  mutation updateProjectSalesforceDataMutation($projectId: String!, $salesforceData: SalesforceDataInput!) {
    updateProjectSalesforceData(projectId: $projectId, salesforceData: $salesforceData) {
      success
    }
  }
`;

export const CLEAR_PROJECT_SALESFORCE_DATA_MUTATION = gql`
  mutation clearProjectSalesforceDataMutation($projectId: String!) {
    clearProjectSalesforceData(projectId: $projectId) {
      success
    }
  }
`;

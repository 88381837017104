import { gql } from '@apollo/client';

export const GET_AGILOFT_EXPENSE_TYPES_QUERY = gql`
  query GetAgiloftExpenseTypesQuery(
    $filters: MongoFilter
    $limit: Int
    $offset: Int
    $sort: MongoFilter
  ) {
    getAgiloftExpenseTypes(filters: $filters, limit: $limit, offset: $offset, sort: $sort) {
      count
      agiloftExpenseTypes {
        id
        expenseTypeName
        expenseClauses
        showExpenseMiles
        agiloftDefaultExpenseClause
        managedServicesExpenseClause
      }
    }
  }
`;
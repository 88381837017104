import { gql } from '@apollo/client';

export const GET_SCOPE_GROUP_BY_ID_QUERY = gql`
  query Query($id: ID!) {
    getScopeGroupById(id: $id) {
      id
      legacyId
      name
      practiceId
    }
  }
`;

import {
  Box,
  Card,
  CardHeader,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  useTheme,
} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React from 'react';

/* eslint-disable-next-line */
export interface OutputTableProps {
  title: string;
  children: JSX.Element | JSX.Element[];
  tableHead?: JSX.Element;
  testId?: string;
}

// TODO use this parent component for the several places the same shape appears
export function OutputTable({
  title,
  children,
  tableHead,
  testId,
}: OutputTableProps) {
  const theme = useTheme();
  const headerBackgroundColor = theme.palette.secondary.main;
  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={title}
              sx={{
                backgroundColor: `${headerBackgroundColor}`,
                color: 'white',
                padding: '5px 15px',
              }}
              titleTypographyProps={{ variant: 'h6' }}
            />
            <Divider />
            <PerfectScrollbar>
              <Box sx={{ minWidth: 400 }}>
                <Table size="small" data-testid={testId}>
                  {tableHead}
                  <TableBody>{children}</TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default OutputTable;

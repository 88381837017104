import { useQuery } from '@apollo/client';
import { GetRolesAndRatesResponse } from '@cdw-selline/common/types';
import { GET_CDW_ROLES_RATES_BY_PRACTICE_IDS_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from './useApolloErrors';

export const useRolesAndRates = ({ practiceIds = [] }) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery<{
    getCdwRolesAndRatesByPracticeIds: GetRolesAndRatesResponse;
  }>(GET_CDW_ROLES_RATES_BY_PRACTICE_IDS_QUERY, {
    skip: !practiceIds?.length,
    variables: {
      getCdwRolesAndRatesByPracticeIdsPracticeIds: practiceIds,
    },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Roles and Rates!'),
  });

  return {
    data: data?.getCdwRolesAndRatesByPracticeIds ?? {
      rolesAndRates: [],
      count: 0,
    },
    error,
    loading,
  };
};

export default useRolesAndRates;

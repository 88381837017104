import { gql } from '@apollo/client';

export const GET_ADMIN_CONFIG_SETTINGS = gql`
  query GetAdminConfigSettings($limit: Int
    $offset: Int
    $filters: MongoFilter) {
    getAdminConfigSettings(limit: $limit
        offset: $offset
        filters: $filters) {
      count
      adminConfigSettings {
        category
        valueType
        key
        value
      }
    }
  }
`;
import { gql } from '@apollo/client';

export const REMOVE_SCOPE_ITEM_QUESTION_MUTATION = gql`
  mutation RemoveScopeItemQuestion($scopeItemId: ID!, $questionId: ID!) {
    removeScopeItemQuestion(
      scopeItemId: $scopeItemId
      questionId: $questionId
    ) {
      success
    }
  }
`;

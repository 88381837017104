import { gql } from '@apollo/client';

export const GET_MANAGED_SERVICES_RENEWAL_QUERY = gql`
  query getManagedServicesRenewalQuery($id: ID!) {
    getManagedServicesRenewalByEstimatorId(projectItemId: $id) {
      currentPrice
      currentCost
      currentPriceGP
      proposedGP
      changeInGP
      changeInPrice
      changeinPricePercent
      changeReason
    }
  }
`;

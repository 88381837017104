import { gql } from '@apollo/client';

export const GET_ESTIMATOR_TASK_GROUP_SCOPE_QUERY = gql`
  query getEstimatorTaskGroupScope($projectItemId: ID!, $projectId: ID!, $taskGroupId: ID!) {
    getEstimatorTaskGroupScope(
      projectItemId: $projectItemId
      projectId: $projectId
      taskGroupId: $taskGroupId
    ) {
      sow
      rawSowContent
    }
  }
`;

export default GET_ESTIMATOR_TASK_GROUP_SCOPE_QUERY;

import { CiscoAnnuity } from '@cdw-selline/common/types';
import { ciscoAnnuityStatus } from '@cdw-selline/ui/constants';
import { getCurrentUser } from '@cdw-selline/ui/state';

export const getCiscoAnnuityReadOnlyMode = (
  project: CiscoAnnuity,
  isCloudFulfillmentOnly: boolean,
): boolean => {
  const currentUser = getCurrentUser();
  
  if (isCloudFulfillmentOnly || currentUser.admin) return false;

  return project.status === ciscoAnnuityStatus['COF Signed'];
};
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { HyperFormula } from 'hyperformula';
import 'handsontable/dist/handsontable.full.min.css';
import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import {
    ALERT_SEVERITY,
    ALERT_TYPE,
    useAlertsState,
  } from '@cdw-selline/ui/state';
import { useEditSiteCards } from '@cdw-selline/ui/hooks';
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Grid,
    Typography,
    } from '@mui/material';
import { SiteInput } from '@cdw-selline/common/types';


const hf = HyperFormula.buildEmpty({
    licenseKey: 'gpl-v3',
});
  
const sheetName = hf.addSheet('siteCards');
const sheetId = hf.getSheetId(sheetName);

/* eslint-disable-next-line */
export interface EditSiteCardProps {
    setIsEditSites: (value: boolean) => void;
}

export const EditSiteCard = (props: EditSiteCardProps) => {
    const { projectItemId } = useParams<{ projectItemId: string }>();

    registerAllModules();
    const alertState = useAlertsState();
    const tableRef = useRef(null);

    const {
        sites,
        sitesLoading,
        updateLoading,
        sitesError,
        tableData,
        tableColumns,
        handleSitesSave,
      } = useEditSiteCards(projectItemId, props.setIsEditSites);

  
    if (sitesLoading) {
      return (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress />
        </Backdrop>
      );
    }
  
    if (sitesError)
      return <Typography>Error loading Site Cards for project id {projectItemId}</Typography>;
  
    hf.setCellContents(
      {
        row: 0,
        col: 0,
        sheet: sheetId,
      },
      tableData
    );

    const handleSave = () => {
        const sitesTableRef = tableRef.current?.hotInstance.getData();
        const updatedSites = sitesTableRef.map((row, ind) => {
            return {
              id: row[0],
              order: ind + 1,
              quantity: Number(row[2]),
              name: row[3],
              address: row[4],
              floor: row[5],
            } as SiteInput;
          });
    
        if (updatedSites) {
            handleSitesSave(updatedSites);
        } else {
          alertState.setAlert({
            type: ALERT_TYPE.MODAL,
            severity: ALERT_SEVERITY.WARNING,
            message: 'No changes to save!',
          });
        }
    };

    const handleExportClick = () => {
        const exportPlugin = tableRef.current?.hotInstance.getPlugin('exportFile');
    
        exportPlugin.downloadFile('csv', {
          bom: false,
          columnDelimiter: ',',
          columnHeaders: true,
          exportHiddenColumns: true,
          exportHiddenRows: true,
          fileExtension: 'csv',
          filename: 'Site Cards' + '-CSV-file_[YYYY]-[MM]-[DD]',
          mimeType: 'text/csv',
          rowDelimiter: '\r\n',
          rowHeaders: true,
        });
      };

      return (
        <Grid container sx={{ width: '100%' }}>
          {(sitesLoading || updateLoading) && (
            <Backdrop
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
            >
              <CircularProgress />
            </Backdrop>
          )}
          <Grid item xs={12}>
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
              <Grid container alignItems={'flex-end'}>
                <Button onClick={()=>props.setIsEditSites(false)}>Back</Button>
                <Button onClick={handleSave}>Save</Button>
                <Button onClick={handleExportClick}>Export</Button>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
                  sx={{
                  overflow: 'hidden',
                  width: '100vw - 5px',
                  height: '84vh',
                  bgcolor: 'background.paper',
                  }}
              >
              <HotTable
                  {...{
                  id: 'site-cards-table',
                  rowHeaders: true,
                  autoColumnSize: false,
                  autoRowSize: false,
                  colHeaders: tableColumns,
                  manualColumnFreeze: true,
                  contextMenu: true,
                  stretchH: 'all',
                  manualRowMove: true,
                  manualColumnResize: true,
                  manualColumnHide: true,
                  hiddenColumns: {
                    columns: [0, 1],
                  },
                  columnSorting: {
                    initialConfig: {
                      column: 1,
                      sortOrder: 'asc',
                    },
                  },
                  ref: tableRef,
                  data: tableData,
                  licenseKey: 'non-commercial-and-evaluation',
                  tableClassName: 'table-template',
                  className: 'table-cell',
                  }}
              />
            </Box>
          </Grid>
        </Grid>
      );
};

export default EditSiteCard;
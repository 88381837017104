import { gql } from '@apollo/client';

export const GET_PROJECT_STATUS_QUERY = gql`
  query Query($getProjectStatusId: ID!) {
    getProjectStatus(id: $getProjectStatusId) {
      customerDescription
      lastModifiedBy
      lastModifiedByUsername
      lastModifiedByUserId
      projectId
      projectName
      status
      timestamp
    }
  }
`;

export default GET_PROJECT_STATUS_QUERY;

// Variables
// {"getProjectStatusId": "uuid"}

import { useQuery } from '@apollo/client';
import { GetSowSectionsResponse } from '@cdw-selline/common/types';
import { GET_SOW_SECTIONS_QUERY } from '@cdw-selline/ui/queries';
import { useParams } from 'react-router-dom';

import { useApolloErrors } from './useApolloErrors';

export interface UseSowSections {
  filters?: any;
  offset?: number;
  limit?: number;
  sort?: any;
}

export const useSowSections = ({ filters, offset, limit, sort }: UseSowSections) => {
  const { id } = useParams<{ id: string }>();
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getSowSections: GetSowSectionsResponse;
  }>(GET_SOW_SECTIONS_QUERY, {
    variables: {
      offset: offset ?? 0,
      limit: limit ?? 100,
      filters: filters ?? {},
      sort: sort ?? {},
    },
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    skip: !!id,
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch SowSections!'),
  });

  return {
    data: data?.getSowSections ?? { sowSections: [], count: 0 },
    error,
    loading,
    refetch,
  };
};

export default useSowSections;

import { gql } from '@apollo/client';

export const REMOVE_SCOPE_GROUP_MUTATION = gql`
  mutation RemoveScopeGroup($removeScopeGroupId: ID!) {
    removeScopeGroup(id: $removeScopeGroupId) {
      success
    }
  }
`;

/*
VARIABLES
{
  "removeScopeGroupId": "62323f4e2aaa7db09715f9c3"
}
*/

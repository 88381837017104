import { gql } from '@apollo/client';

export const UPDATE_PROJECT_ITEMS_ESTIMATOR_MUTATION = gql`
  mutation updateProjectItemEstimatorMutation(
    $params: ProjectItemEstimatorsInput
  ) {
    updateProjectItemEstimator(params: $params) {
      success
    }
  }
`;

export const UPDATE_PROJECT_ITEMS_ESTIMATOR_VARIABLES_MUTATION = gql`
  mutation updateProjectItemEstimatorVariablesMutation(
    $params: ProjectItemEstimatorsInput
  ) {
    updateProjectItemEstimatorVariables(params: $params) {
      success
    }
  }
`;

export const UPDATE_PROJECT_ITEMS_ESTIMATOR_CALCULATION_MUTATION = gql`
  mutation updateProjectItemEstimatorCalculationMutation(
    $params: ProjectItemEstimatorsInput
  ) {
    updateProjectItemEstimatorCalculation(params: $params) {
      success
    }
  }
`;

// VARIABLES
// {
//   ...ProjectItemEstimatorsInput
// }

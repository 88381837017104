import { useSearchUsers } from '@cdw-selline/ui/hooks';
import React, { useState } from 'react';
import { Grid, TextField, Autocomplete } from '@mui/material';

export interface UserAutoCompleteProps {
  setProjectFormData?;
  projectFormData?;
  onKeyUp?(event: React.KeyboardEvent<HTMLInputElement>): void;
  label?: string;
  name?: string;
  freeSolo?: boolean;
}

export function UsersAutocomplete({
  setProjectFormData,
  projectFormData,
  onKeyUp,
  label,
  name,
  freeSolo,
}: UserAutoCompleteProps) {
  const value = name ? projectFormData[name] : projectFormData?.userName ?? '';
  const [searchTerm, setSearchTerm] = useState(value ?? '');

  const {
    data: users,
    loading: usersLoading,
    error: usersError,
  } = useSearchUsers(searchTerm);

  const userOptions = 
    users.map((user) => {
      if (user.email === searchTerm) {
        setSearchTerm(`${user.email}` + ` (${user.displayName || ''})`);
      }
      return {
        id: user._id,
        email: user.email,
        label:
          `${user.email}` +
          (user.displayName !== null ? ` (${user.displayName})` : ``),
      };
    }) ?? [];

  const handleAutocompleteChange = (value: string, name: string) => {
    setProjectFormData({
      ...projectFormData,
      [name]: value?.toLowerCase(),
    });
  };


  const getAutocompleteDefaultValue = (name: string, options: unknown) => {
    if (!Array.isArray(options) || options.length === 0) {
      return '';
    }

    if (
      !projectFormData[name] ||
      projectFormData[name] === 0 ||
      projectFormData[name] === null
    ) {
      return '';
    }
    return options.find((option) => option.id === projectFormData[name])?.label;
  };

  return (
    <Grid item xs={12} sx={{ width: '100%' }}>
      <Autocomplete
        freeSolo={freeSolo ?? false}
        onKeyUp={onKeyUp}
        tabIndex={2}
        id="email"
        options={userOptions}
        sx={{ width: '100%' }}
        noOptionsText={`Type User Name`}
        value={searchTerm}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        onInputChange={(event, newValue) => {
          if(event?.type === "change") {
            handleAutocompleteChange(newValue, name ?? 'email')
          }
          
          setSearchTerm(newValue);
        }}
        onChange={(event, newValue) => {
          handleAutocompleteChange(newValue?.email ?? '', name ?? 'email');
        }}
        defaultValue={getAutocompleteDefaultValue('email', userOptions)}
        data-testid={`new-project-user-input`}
        renderInput={(params) => (
          <TextField {...params} label={label ?? 'User Email'} />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={props.id}>
              {option?.label}
            </li>
          );
        }}
      />
    </Grid>
  );
}

export default UsersAutocomplete;

import { gql } from '@apollo/client';

export const UPDATE_SOW_SECTION_MUTATION = gql`
  mutation updateSowSectionMutation($params: SowSectionInput) {
    updateSowSection(params: $params) {
      success
    }
  }
`;

export const CREATE_TEST_VERSION_SOW_SECTION_MUTATION = gql`
  mutation createTestVersionBySowSectionMutation($sowSectionId: ID!) {
    createTestVersionBySowSection(sowSectionId: $sowSectionId) {
      success
    }
  }
`;
// VARIABLES
// {
//   ...SowSectionInput
// }

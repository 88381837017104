import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  ManagedServicesRenewalResponse,
  DefaultMutationResponseWithId,
} from '@cdw-selline/common/types';
import {
  GET_MANAGED_SERVICES_RENEWAL_QUERY,
  UPDATE_PROJECT_ITEMS_ESTIMATOR_MUTATION,
} from '@cdw-selline/ui/queries';

import { useApolloErrors } from '../..';

export const useManagedServicesRenewal = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { projectItemId } = useParams<{ projectItemId: string }>();
  const { loading, error, data } = useQuery<{
    getManagedServicesRenewalByEstimatorId: ManagedServicesRenewalResponse;
  }>(GET_MANAGED_SERVICES_RENEWAL_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: { id: projectItemId },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Manages Services Renewal!'),
  });

  const [
    updateManagedServicesRenewal,
    {
      loading: updateManagedServicesRenewalLoading,
      error: updateManagedServicesRenewalError,
      client,
    },
  ] = useMutation<{
    updateProjectItem: DefaultMutationResponseWithId;
  }>(UPDATE_PROJECT_ITEMS_ESTIMATOR_MUTATION, {
    refetchQueries: [GET_MANAGED_SERVICES_RENEWAL_QUERY],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to updateProject Item Estimator'),
  });

  return {
    managedServicesRenewal: data?.getManagedServicesRenewalByEstimatorId ?? {},
    error,
    loading,
    updateManagedServicesRenewal,
    updateManagedServicesRenewalLoading,
    updateManagedServicesRenewalError,
  };
};

import { AgiloftExpenseType } from '@cdw-selline/common/types';
import { upperFirst } from 'lodash';
import { ValidationError, array, object, string } from 'yup';

const agiloftExpenseTypeBaseSchema = object({
  expenseTypeName: string().required(),
  expenseClauses: array().of(string().required()).min(1),
  agiloftDefaultExpenseClause: string().required(),
  managedServicesExpenseClause: string().required(),
});

export const useValidateAgiloftExpenseType = () => {
  const validateAgiloftExpenseType = async (agiloftExpenseType: AgiloftExpenseType) => {
    const errorArray = [];
    try {
      await agiloftExpenseTypeBaseSchema.validate(agiloftExpenseType, { abortEarly: false });
    } catch (error) {
      if (error && error instanceof ValidationError) {
        error.inner.forEach((err) => {
          errorArray.push(upperFirst(err.message));
        });
      }
    }

    return errorArray;
  };

  return {
    validateAgiloftExpenseType,
  };
};

/* eslint-disable @nx/enforce-module-boundaries */
import { ROUTES } from '@cdw-selline/ui/constants';
import { useLeads } from '@cdw-selline/ui/hooks';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import React, { SetStateAction } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getCurrentUser } from '@cdw-selline/ui/state';

export interface LeadsListProps {
  routeTo: (id: string) => void;
  routeToArbitraryRoute: (route: string) => void;
  createLead: () => void;
  leadModalOpen?: boolean;
  setLeadModalOpen: React.Dispatch<SetStateAction<boolean>>;
  handleLeadSubmit: (formData: Record<string, string>) => void;
  createLeadLoading: boolean;
}

export const LeadsList: React.FC<LeadsListProps> = (props) => {
  const currentUser = getCurrentUser();
  const userEmail = currentUser ? currentUser.email : {};

  const filter = { owner: [userEmail] };

  const { data, error, loading } = useLeads({
    limit: 10,
    offset: 0,
    filters: filter,
  });
  return (
    <Card>
      <CardHeader
        title="My Leads"
        action={
          <Button
            variant="text"
            size="small"
            color="secondary"
            endIcon={<AddIcon color="secondary" />}
            onClick={props.createLead}
          >
            Add Lead
          </Button>
        }
      />
      <Divider />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Call to Action</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Assigned To</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell>Project(s)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {error && (
                <Typography color="error" variant="h6">
                  {error.message}
                </Typography>
              )}
              {loading && (
                <TableRow>
                  <TableCell>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
              {!error &&
                !loading &&
                Array.isArray(data) &&
                data.map((d) => (
                  <TableRow hover key={d.id} style={{ cursor: 'pointer' }}>
                    <TableCell>{d.name}</TableCell>
                    <TableCell>{d.customerDescription}</TableCell>
                    <TableCell>{d.owner}</TableCell>
                    <TableCell align="right">
                      <Chip color="primary" label={d.status} size="small" />
                    </TableCell>
                    <TableCell>{d.associatedProjects}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 2,
        }}
      >
        <Button
          color="secondary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
          onClick={() => props.routeToArbitraryRoute(ROUTES.LEADS)}
        >
          View all
        </Button>
      </Box>
    </Card>
  );
};

export default LeadsList;

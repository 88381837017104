import { gql } from '@apollo/client';

export const UPDATE_ROLE_MUTATION = gql`
  mutation updateRoleMutation($params: AdminRoleInput) {
    updateRole(params: $params) {
      success
    }
  }
`;

// VARIABLES
// {
//   ...AdminRoleInput
// }

import { gql } from '@apollo/client';

export const GET_CISCO_ANNUITY_BY_ID_QUERY = gql`
  query GetCiscoAnnuityById($getCiscoAnnuityByIdId: ID!) {
    getCiscoAnnuityById(id: $getCiscoAnnuityByIdId) {
      activeCiscoQuote
      actualStartDate
      changeType
      ciscoCapital
      ciscoQuotes {
        dealId
        name
        quoteId
        autoRenew
        billingCycle
        userBillingCycle
        term
        userTerm
        start
        status
        expires
        change
        subscriptionReferenceId
        newCofRequired
        hideCustomerViewTotal
        disableAdders
        isEstimate
        hideIncludedSkus
        hideMonthlyFeeBreakdown
        committedAudioMarkup
        removeBidAdders
      }
      ciscoSubscriptionId
      cofCreatedAt
      cofCreatedBy
      cofRequestAt
      cofRequestedBy
      cofSignedAt
      cofSignedBy
      cofDeniedReason
      comments {
        createdAt
        createdBy
        id
        note
      }
      contacts {
        id
        type
        name
        email
        phone
      }
      createdAt
      createdBy
      customerPo
      id
      lastModifiedAt
      lastModifiedBy
      orderPlacedAt
      orderPlacedBy
      processVersion
      projectItemId
      quotedAt
      quotedBy
      requestWordCopy
      smartAccount
      stateContract
      status
      subscriptionNumber
      type
      virtualAccount
      webOrderId
      cdwBillingId
      customerBillingAccount
      customerAdminSameAsBilling
      orderDeniedReason
    }
  }
`;
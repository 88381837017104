export enum ROUTES {
  HOME = '/',
  SIGN_IN = '/sign-in',
  PROFILE = '/user-account',
  NOTIFICATIONS = '/user-account/notifications',
  PROJECT = '/project',
  DASHBOARD = '/dashboard',
  ACTIVITY = '/dashboard/activity',
  LEADS = '/dashboard/leads',
  KANBAN = '/dashboard/kanban',
  PIPELINES = '/dashboard/pipelines',
  CUSTOMERS = '/dashboard/customers',
  ADMIN_DASHBOARD = '/admin/dashboard',
  ADMIN_CUSTOMERS = '/admin/collection/customers',
  ADMIN_PRACTICES = '/admin/collection/practices',
  ADMIN_ROLES = '/admin/collection/roles',
  ADMIN_CDW_RATES = '/admin/collection/cdw-rates',
  ADMIN_TASKS = '/admin/collection/tasks',
  ADMIN_TASK_GROUPS = '/admin/collection/task-groups',
  ADMIN_PHASES = '/admin/collection/phases',
  ADMIN_SOW_SECTIONS = '/admin/collection/sow-sections',
  ADMIN_TIERS = '/admin/collection/tiers',
  ADMIN_SCOPE_GROUPS = '/admin/collection/scope-groups',
  ADMIN_SCOPE_ITEMS = '/admin/collection/scope-items',
  NOT_FOUND = '*',
  ADMIN_LIST_TASKS = '/admin/listtasks',
  ADMIN_PROPOSAL_TEMPLATES = '/admin/collection/proposal-templates',
  ADMIN_CISCO_ANNUITY_PART_NUMBERS = '/admin/collection/cisco-annuity-part-numbers',
  ADMIN_CONFIG_SETTINGS = '/admin/collection/admin-config-settings',
  EMAIL_TEMPLATES = '/admin/collection/email-templates',
  ADMIN_SERVICE_PRACTICES = '/admin/collection/service-practices',
  ADMIN_SERVICE_ITEMS = '/admin/collection/service-items',
  ADMIN_SERVICE_SKILLS = '/admin/collection/service-skills',
  AGILOFT_EXPENSE_TYPES = '/admin/collection/agiloft-expense-types',
  AGILOFT_TRAVEL_NOTICE_TYPES = '/admin/collection/agiloft-travel-notice-types'
}

export const BYPASS_AUTH = !!process.env.NX_CI_E2E_TEST;
export const TENANT =
  process.env.NODE_ALIAS === 'prod'
    ? process.env.NX_AZURE_TENANT_ID_PROD
    : process.env.NX_AZURE_TENANT_ID_DEV;


    export const PAGES = {
      CUSTOMER_PIPELINE: 'customer-pipeline',
      CDW_RATES_COLLECTION: 'CdwRatesCollectionPage',
      CUSTOMERS_COLLECTION: 'CustomersCollectionPage',
      PRACTICES_COLLECTION: 'PracticesCollectionPage',
      PROPOSAL_TEMPLATES_COLLECTION: 'ProposalTemplatesCollectionPage',
      ROLES_COLLECTION: 'RolesCollectionPage',
      SOW_SECTIONS_COLLECTION: 'SowSectionsCollectionPage',
      TASKS_COLLECTION: 'TasksCollectionPage',
      TASK_GROUPS_COLLECTION: 'TaskGroupsCollectionPage',
      TIERS_COLLECTION: 'TiersCollectionPage',
      PHASES_COLLECTION: 'PhasesCollectionPage',
      EMAIL_TEMPLATES_COLLECTION: 'EmailTemplatesCollectionPage',
      SERVICE_PRACTICES_COLLECTION: 'ServicePracticesCollectionPage',
      SERVICE_ITEMS_COLLECTION: 'ServiceItemsCollectionPage',
      SERVICE_SKILLS_COLLECTION: 'ServiceSkillsCollectionPage',
      AGILOFT_EXPENSE_TYPES_COLLECTION: 'AgiloftExpenseTypesCollectionPage',
      AGILOFT_TRAVEL_NOTICE_TYPES_COLLECTION: 'AgiloftTravelNoticeTypesCollectionPage',
      OPPORTUNITY_VIEW: 'OpportunityView',
    };
    

import { gql } from '@apollo/client';

export const UPDATE_SCOPE_GROUP_MUTATION = gql`
  mutation UpdateScopeGroup($id: ID!, $modifiedScopeGroup: ScopeGroupInput) {
    updateScopeGroup(id: $id, modifiedScopeGroup: $modifiedScopeGroup) {
      success
    }
  }
`;

/*
VARIABLES
{
  "id": "62323bfc026307adc6242013",
  "modifiedScopeGroup": {
    "legacyId": "12345678",
    "name": cool new name",
    "practiceId": "89"
  }
}
*/

import { useQuery } from '@apollo/client';
import { CiscoAnnuityQuoteResponse } from '@cdw-selline/common/types';
import { GET_CISCO_ANNUITY_QUOTE_BY_PROJECT_ITEM_ID_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from '..';

export const useCiscoAnnuityLines = ({ projectItemId }) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getCiscoAnnuityQuoteByProjectItemId: CiscoAnnuityQuoteResponse;
  }>(GET_CISCO_ANNUITY_QUOTE_BY_PROJECT_ITEM_ID_QUERY, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    variables: { projectItemId },
    skip: !projectItemId,
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Cisco Annuity!'),
  });

  return {
    data: data?.getCiscoAnnuityQuoteByProjectItemId ?? {},
    refetch,
    loading,
    error,
  };
};

import { gql } from '@apollo/client';

export const GET_TASKS_DEPENDENCIES_BY_ID_QUERY = gql`
  query getTaskDependenciesByIdQuery($id: ID!) {
    getTaskDependenciesById(id: $id)
  }
`;

export const GET_TASK_GROUP_DEPENDENCIES_BY_ID_QUERY = gql`
  query getTaskGroupDependenciesByIdQuery($id: ID!) {
    getTaskGroupDependenciesById(id: $id)
  }
`;

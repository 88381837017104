import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';

export function PhasesCollectionPage() {
  return (
    <div>
      <h1>Welcome to PhasesCollectionPage!</h1>

      <ul>
        <li>
          <Link to="/">PhasesCollectionPage root</Link>
        </li>
      </ul>
      <Routes>
        <Route
          path="/"
          element={<div>This is the PhasesCollectionPage root route.</div>}
        />
      </Routes>
    </div>
  );
}

export default PhasesCollectionPage;

import { gql } from '@apollo/client';

export const ADD_ESTIMATOR_TASKS_BY_TASKS_GROUP_MUTATION = gql`
  mutation AddEstimatorTasksByTaskGroup(
    $itemId: String!
    $siteId: String!
    $taskGroupId: String!
  ) {
    addEstimatorTasksByTaskGroup(
      itemId: $itemId
      siteId: $siteId
      taskGroupId: $taskGroupId
    ) {
      success
      hasBillingTypeChanged
    }
  }
`;

import { gql } from '@apollo/client';

export const UPDATE_PROJECT_COMMENT_MUTATION = gql`
  mutation UpdateProjectComment(
    $comment: ProjectCommentInput!
    $commentId: ID!
    $projectId: ID!
  ) {
    updateProjectComment(
      comment: $comment
      commentId: $commentId
      projectId: $projectId
    ) {
      success
    }
  }
`;

import { gql } from '@apollo/client';

export const GET_TOTAL_SALES_QUERY = gql`
  query getTotalSalesQuery {
    getTotalSales {
      totalSales
      totalSalesSinceLastMonthPercent
    }
  }
`;

export default GET_TOTAL_SALES_QUERY;

// SAMPLE RESPONSE:
// {
//   "data": {
//     "getTotalSales": {
//       "totalSales": 23200,
//       "totalSalesSinceLastMonthPercent": 100
//     }
//   }
// }

import { RawEditorOptions } from 'tinymce';

export const proposalMaxWidth = '806px';

export type TinyMCEEditorOptions = Omit<RawEditorOptions, 'selector' | 'target'>;

export const defaultEditorOptions: RawEditorOptions = {
  custom_elements: 'replace',
  extended_valid_elements: 'replace[*]',
  valid_children: '+p[replace[*]],+div[replace]',
  content_style: 'replace { display: inline; }',
  toolbar:
    'print add-sections add-global-sections sitetable preview copy | ' +
    'undo redo | ' +
    'formatselect fontselect fontsizeselect | ' +
    'bold italic | ' +
    'alignleft aligncenter ' +
    'alignright alignjustify | ' +
    'bullist numlist outdent indent | ' +
    'image | forecolor backcolor | paste |' +
    'fullscreen | nonbreaking',
  plugins: [
    'print',
    'advlist',
    'code',
    'lists',
    'link',
    'image',
    'charmap',
    'pagebreak',
    'paste',
    'visualblocks',
    'insertdatetime',
    'help',
    'wordcount',
    'save',
    'table',
    'fullscreen',
    'nonbreaking',
    'noneditable',
  ],
};
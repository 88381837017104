/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Stack,
  Box,
  Button,
  CssBaseline,
  Grid,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { FunctionComponent } from 'react';
import CdwSvgLogo from '../cdw-svg-logo/CdwSvgLogo';
export interface SignInProps {
  redirectUrl: string;
  handleGoogleClick: () => Promise<void>;
  handleMicrosoftClick: () => Promise<void>;
  appTitle?: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    // TODO: refactor to use emotion or styled components
    root: {
      height: '100vh',
    },
    image: {
      backgroundImage: `url(https://source.unsplash.com/1600x900/?computer,data,warehouse,cdw)`,
      backgroundRepeat: 'no-repeat',
      // backgroundColor: theme.palette.grey[50],
      // theme.palette.type === 'light'
      // ? theme.palette.grey[50]
      // : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      // margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      // margin: theme.spacing(1),
      // backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      // marginTop: theme.spacing(1),
    },
    submit: {
      // margin: theme.spacing(3, 0, 2),
    },
    link: {
      textDecoration: 'none',
      borderBottom: 'none',
      // color: theme.palette.primary.main,
    },
    logo: {
      width: '75%',
    },
  })
);

function Copyright() {
  const classes = useStyles();
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#" className={classes.link}>
        CDW
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export const SignInForm = ({
  handleGoogleClick,
  handleMicrosoftClick,
  appTitle = 'Ultron',
}: SignInProps) => {
  const classes = useStyles();
  return (
    <div className={classes.paper}>
      <CdwSvgLogo className={classes.logo}/>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          p: {
            xs: 2,
            md: 4,
          },
        }}
      >
        <Button
          variant="contained"
          sx={{ mt: 4 }}
          onClick={handleMicrosoftClick}
        >
          Login with CDW SSO
        </Button>
        {process.env.NODE_ENV !== 'production' && (
          <Button
            variant="contained"
            sx={{ mt: 4 }}
            onClick={handleGoogleClick}
          >
            Login with Google
          </Button>
        )}
      </Stack>
      <div className={classes.form}>
        <Box mt={5}>
          <Copyright />
        </Box>
      </div>
    </div>
  );
};
export const SignIn: FunctionComponent<SignInProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SignInForm {...props} />
      </Grid>
    </Grid>
  );
};

export default SignIn;

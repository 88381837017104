import { GlobalQuoteSettingsFields } from '@cdw-selline/common/types';
import {
  GlobalQuoteSettingsTable,
  OutputTable,
  QuotesTable,
} from '@cdw-selline/ui/components';
import { useProjectItemQuote } from '@cdw-selline/ui/hooks';
import {
  CircularProgress,
  Grid,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';

/* eslint-disable-next-line */
export interface ProjectItemQuoteTabProps {}

export function ProjectItemQuoteTab(props: ProjectItemQuoteTabProps) {
  const { projectItemId } = useParams<{ id: string; projectItemId: string }>();

  const {
    quoteName,
    cdwCost,
    customerCost,
    globalSettingsTableData,
    quoteData,
    loading,
    error,
  } = useProjectItemQuote(projectItemId);

  const {
    TAX_SETTINGS,
    HARDWARE_SETTINGS,
    SOFTWARE_SETTINGS,
    SERVICES_SETTINGS,
    SUPPORT_SETTINGS,
  } = GlobalQuoteSettingsFields;

  if (loading) return <CircularProgress />;
  if (error) return <Typography>{error.message}</Typography>;

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <GlobalQuoteSettingsTable
              aria-label="tax rate variables"
              tableData={globalSettingsTableData.addlCosts}
              quoteData={quoteData}
              setting={TAX_SETTINGS}
            />
          </Grid>
          <Grid item>
            <GlobalQuoteSettingsTable
              aria-label="global hardware variables"
              tableData={globalSettingsTableData.hardware}
              quoteData={quoteData}
              setting={HARDWARE_SETTINGS}
            />
          </Grid>
          <Grid item>
            <GlobalQuoteSettingsTable
              aria-label="global software variables"
              tableData={globalSettingsTableData.software}
              quoteData={quoteData}
              setting={SOFTWARE_SETTINGS}
            />
          </Grid>
          <Grid item>
            <GlobalQuoteSettingsTable
              aria-label="global services variables"
              tableData={globalSettingsTableData.services}
              quoteData={quoteData}
              setting={SERVICES_SETTINGS}
            />
          </Grid>
          <Grid item>
            <GlobalQuoteSettingsTable
              aria-label="global support variables"
              tableData={globalSettingsTableData.support}
              quoteData={quoteData}
              setting={SUPPORT_SETTINGS}
            />
          </Grid>
          <Grid item>
            <OutputTable title="Calculated Total Costs">
              <TableRow>
                <TableCell>CDW Cost</TableCell>
                <TableCell>${cdwCost}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Customer Cost</TableCell>
                <TableCell>${customerCost}</TableCell>
              </TableRow>
            </OutputTable>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <QuotesTable
          aria-label="quote line items"
          {...{ quoteData, loading, error }}
        />
      </Grid>
    </Grid>
  );
}

export default ProjectItemQuoteTab;

import {
  CiscoAnnuityServiceFeeSchedule,
  CiscoQuote,
} from '@cdw-selline/common/types';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Typography,
  Divider,
} from '@mui/material';
import React from 'react';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { formatCurrency } from '@cdw-selline/ui/helpers';
import { round } from 'lodash';

export interface CiscoAnnuityServiceFeesProps {
  serviceFeeSchedule: CiscoAnnuityServiceFeeSchedule[];
  billingCycle: string;
  loading?: boolean;
}

export function CiscoAnnuityServiceFeesTable({
  serviceFeeSchedule,
  billingCycle,
  loading,
}: CiscoAnnuityServiceFeesProps) {
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Paper sx={{ maxWidth: '600px' }}>
      <Typography variant="body1" sx={{ padding: '5px' }}>
        SERVICE FEES SCHEDULE
      </Typography>
      {billingCycle === 'Annual' && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Period</TableCell>
                <TableCell align="right">Total Service Fees (GP*)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {serviceFeeSchedule.map((serviceFee, index) => (
                <TableRow key={`${serviceFee.period}`}>
                  <TableCell title={`${serviceFee.period}`}>
                    {serviceFee.period}
                  </TableCell>
                  <TableCell align="right">
                    {formatCurrency(serviceFee.customerPrice)} (
                    {formatCurrency(serviceFee.gp)})
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {billingCycle === 'Monthly' && (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Period</TableCell>
                <TableCell>Monthly Service Fees</TableCell>
                <TableCell>Months</TableCell>
                <TableCell align="right">Total Service Fees (GP*)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {serviceFeeSchedule.map((serviceFee, index) => (
                <TableRow key={`${serviceFee.period}`}>
                  <TableCell title={`${serviceFee.period}`}>
                    {serviceFee.period}
                  </TableCell>
                  <TableCell align="right">
                    {serviceFee.period !== 'Total Service Fee' && (
                      <>{formatCurrency(serviceFee.customerPriceMonthly)}</>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {serviceFee.monthQuantity}
                  </TableCell>
                  <TableCell align="right">
                    {formatCurrency(serviceFee.customerPrice)} (
                    {formatCurrency(serviceFee.gp)})
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useProjectProposal } from '@cdw-selline/ui/hooks';
import { Box } from '@mui/material';
/* eslint-disable */
//const proposalCSS = require('./proposal-print-view.css');
/* eslint-enable */


export const ProposalPrintView = () => {
    const { proposalId } = useParams<{ proposalId: string }>();
    const {
        currentProposal,
        loading,
        error,
      } = useProjectProposal(proposalId);
      const iframeRef = React.useRef(null);


      useEffect(() => {
        if (iframeRef.current && !loading) {
          const doc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
          doc.open();
          doc.write(currentProposal?.content);
          const style = document.createElement('style');
          style.appendChild(document.createTextNode(''));
          doc.head.appendChild(style);
          doc.close();
          window.print();
        }
      }, [currentProposal, loading]);

      return (
        <Box
            sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 10000,
            background: 'white',
            height: '200vh',
            width: '100%',
            padding: 2
            }}
        >
              {!loading && <iframe
                ref={iframeRef}
                id="proposalPrintView"
                style={{ width: '100%', height: '100%', border: 'none' }}
                title="Proposal Print Preview"
              ></iframe>}
        </Box>
      );
};

export default ProposalPrintView;
import { gql } from '@apollo/client';

export const GET_TIER_RMS_PROPOSAL_TEMPLATE_QUERY = gql`
  query getTierRmsProposalTemplateByItemId($projectItemId: String!) {
    getTierRmsProposalTemplateByItemId(projectItemId: $projectItemId) {
      rmsProposalItems {
        siteId
        siteName
        categories {
          category
          lineItems {
            tier
            tasks {
              id
              order
              quantity
              sellPrice
              totalSellPrice
              tier
              tierId
              type
              costType
              name
              proposalNotes
              proposalDescription
            }
            totalSellPrice
          }
          totalSellPrice
        }
        totalSellPrice
      }
      totalMonthlyCharges
      bronzeTotal
      silverTotal
      goldTotal
    }
  }
`;

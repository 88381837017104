import { gql } from '@apollo/client';

export const GET_PROJECT_PROPOSALS_BY_ESTIMATOR_ID_QUERY = gql`
  query GetProjectProposalsByEstimatorId($projectId: ID!, $estimatorId: ID!) {
    getProjectProposalsByEstimatorId(
      projectId: $projectId
      estimatorId: $estimatorId
    ) {
      content
      id
      name
      projectId
      estimatorId
    }
  }
`;

/*
VARIABLES:
{
  "projectId": "1234"
}
*/

/*
RETURNS:
{
  "data": {
    "getProjectProposalsByProjectId": [
      {
        "content": "<p>shiny new content</p>",
        "id": "61c36a22727e20b961ebd407",
        "name": "A New Name",
        "projectId": "1234"
      }
    ]
  }
}
*/

import { gql } from '@apollo/client';

export const GET_PRACTICES_QUERY = gql`
  query getPracticesQuery(
    $filters: MongoFilter
    $limit: Int
    $offset: Int
    $sort: MongoFilter
  ) {
    getPractices(
      filters: $filters
      limit: $limit
      offset: $offset
      sort: $sort
    ) {
      count(filters: $filters)
      practices {
        id
        name: name
        name
        practiceUsernames
        managedSvc
        peerReviewEmail
        practiceId
        psm
        serviceLocationId
        lastModified
        createdAt
        salesForceId
      }
    }
  }
`;

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GetEstimateToPsaResponse } from '@cdw-selline/common/types';
import { GET_PSA_BREAKDOWN_QUERY } from '@cdw-selline/ui/queries';

interface PsaBreakdownRow {
  name: string;
  cost: number;
  hours: number;
  indent: number;
}

export const usePsaBreakdownSummary = () => {
  const { projectItemId } = useParams<{ projectItemId: string }>();
  const { loading, error, data } = useQuery<{
    getPsaBreakdownByProjectId: GetEstimateToPsaResponse;
  }>(GET_PSA_BREAKDOWN_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: { id: projectItemId },
  });

  const wrapperEstimate = data?.getPsaBreakdownByProjectId?.inputs
    ? data?.getPsaBreakdownByProjectId?.inputs[0].wrapperestimate
    : {};

  const getRoleRequestNetBillAmount = (roleRequest) => {
    return roleRequest.hours * roleRequest.bill_Rate_Override;
  };

  const getRoleRequests = (
    estimateProduct: string,
    estimateLine: string
  ): PsaBreakdownRow[] => {
    const psaBreakdownRows: PsaBreakdownRow[] = [];

    wrapperEstimate.estimateRoleRequests.forEach((roleRequest) => {
      if (roleRequest.estimateProduct === estimateProduct) {
        psaBreakdownRows.push({
          name: roleRequest.role,
          cost: getRoleRequestNetBillAmount(roleRequest),
          hours: roleRequest.hours,
          indent: 2,
        });
      }
    });

    return psaBreakdownRows;
  };

  const getEstimateLines = (estimateProduct: string): PsaBreakdownRow[] => {
    const psaBreakdownRows: PsaBreakdownRow[] = [];

    wrapperEstimate.estimateLines.forEach((estimateLine) => {
      if(estimateLine.estimateProduct !== estimateProduct) {
        return;
      }
      
      if (estimateLine.lineSetType === 'Labor') {
        const roleRequests = getRoleRequests(
          estimateProduct,
          estimateLine.name
        );
        const roleRequestCostTotal = roleRequests.reduce(
          (acc, roleRequest) => acc + roleRequest.cost,
          0
        );

        psaBreakdownRows.push({
          name: estimateLine.name,
          cost: roleRequestCostTotal,
          hours: 0,
          indent: 1,
        });

        psaBreakdownRows.push(...roleRequests);
      }

      if (estimateLine.lineSetType === 'Billing') {
        psaBreakdownRows.push({
          name: estimateLine.name,
          cost: estimateLine.netBillAmount,
          hours: 0,
          indent: 1,
        });
      }
    });

    return psaBreakdownRows;
  };

  const createPsaBreakdownRows = (): PsaBreakdownRow[] => {
    if (!wrapperEstimate || Object.keys(wrapperEstimate).length === 0) {
      return [];
    }

    const psaBreakdownRows: PsaBreakdownRow[] = [];

    wrapperEstimate.estimateProducts.forEach((estimateProduct) => {
      const estimateProductRow = {
        name: estimateProduct.Name,
        cost: null,
        hours: 0,
        indent: 0,
      };

      psaBreakdownRows.push(estimateProductRow);

      const estimateLines = getEstimateLines(estimateProduct.Name);

      psaBreakdownRows.push(...estimateLines);
    });

    return psaBreakdownRows;
  };

  return {
    estimateToPsaResponse: createPsaBreakdownRows(),
    error,
    loading,
    estimateProducts:
      data?.getPsaBreakdownByProjectId?.estimateProductList || [],
  };
};

import { gql } from '@apollo/client';

export const DELETE_CISCO_ANNUITY_COMMENT_MUTATION = gql`
  mutation removeCiscoAnnuityCommentById(
    $ciscoAnnuityId: ID!
    $ciscoAnnuityCommentId: ID!
  ) {
    removeCiscoAnnuityCommentById(
      ciscoAnnuityId: $ciscoAnnuityId
      ciscoAnnuityCommentId: $ciscoAnnuityCommentId
    ) {
      success
    }
  }
`;
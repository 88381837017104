import { createStyles, makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Typography,
} from '@mui/material';
import * as React from 'react';
import CdwSvgLogo from '../cdw-svg-logo/CdwSvgLogo';

const useStyles = makeStyles(() =>
  createStyles({
    logo: {
      width: '150px',
      height: '70px',
    },
  })
);

export const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const classes = useStyles();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        minWidth: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CssBaseline />
      <Grid item xs={12} sx={{ mt: '3em', mb: '2em' }}>
        <Typography variant="h4" component="h3" color="error" gutterBottom>
          Uh Oh... Something went wrong
        </Typography>
      </Grid>
      <Grid
        sx={{
          background: '#444',
          mb: '1em',
          padding: '1em',
          maxWidth: 'calc(70vw - 30em)',
        }}
      >
        <Typography variant="h5" component="h2" color="error" gutterBottom>
          <code>{error?.message}</code>.
        </Typography>
      </Grid>
      <Button onClick={resetErrorBoundary} variant="contained" size="large">
        Try again
      </Button>
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 'auto',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container
          sx={{
            width: '100vw',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography style={{ marginRight: '5em' }}>
            NOTE: You may also need to restart/resave the selline-gateway if you
            see a econnrefused error in the terminal. For invalid react child
            errors make sure you pass null instead of undefined values or arrays
            instead of objects. Check your empty/loading states.
          </Typography>
          <CdwSvgLogo className={classes.logo}/>
        </Container>
      </Box>
    </Box>
  );
};

export const getSalesforceIDFromUrl =  (salesForceUrl) =>{
    const urlInstance = new URL(salesForceUrl);
    urlInstance.pathname.endsWith('/') && urlInstance.pathname.slice(0, -1);
    const urlArray = urlInstance.pathname.split('/');
    return urlArray[urlArray.length - 2];
}

export const isValidSalesforceOpportunityUrl = (url: string): boolean => {
    try {
      const parsedUrl = new URL(url);
      return /opportunity/i.test(parsedUrl.pathname);
    } catch (error) {
      return false;
    }
  };
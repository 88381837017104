import { gql } from '@apollo/client';

export const GET_OPPORTUNITIES_QUERY = gql`
  query getOpportunities(
    $limit: Int
    $offset: Int
    $filters: MongoFilter
    $sort: MongoFilter
  ) {
    getOpportunities(
      limit: $limit
      offset: $offset
      filters: $filters
      sort: $sort
    ) {
      count
      opportunities {
        area
        cdwCustomerAccountNumber
        cewProvided
        customerId
        customerName
        customerSegment
        customerRegion
        customerSector
        customerArea
        closedStatus
        closedReason
        demoProvided
        estClose
        id
        lastModified
        name
        projectOwner
        projectOwnerDisplayName
        organization
        probability
        projectTeam {
          email
          type
          name
        }
        serviceLocation
        status
        updatedAt
        closedOn
        customerId
        createdBy
        serviceTotal
        productTotal
        subscriptionTotal
        managedServiceTotal
        opportunityTotal
      }
    }
  }
`;

export default GET_OPPORTUNITIES_QUERY;

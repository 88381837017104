import { gql } from '@apollo/client';

export const REMOVE_ADMIN_TIER_MUTATION = gql`
  mutation removeTierMutation($removeTierId: String) {
    removeTier(id: $removeTierId) {
      success
    }
  }
`;

// VARIABLES
// {
//   "removeRole": "id"
// }

import { gql } from '@apollo/client';

export const REMOVE_CISCO_ANNUITY_QUOTE_BY_ID_MUTATION = gql`
  mutation RemoveCiscoAnnuityQuoteById(
    $ciscoAnnuityId: ID!
    $ciscoQuoteId: ID!
  ) {
    removeCiscoAnnuityQuoteById(
      ciscoAnnuityId: $ciscoAnnuityId
      ciscoQuoteId: $ciscoQuoteId
    ) {
      success
    }
  }
`;

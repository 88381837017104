import { useQuery } from '@apollo/client';
import {
  GET_ESTIMATOR_INCLUDES_SUMMARY_TASKS_QUERY,
  GET_ESTIMATOR_SUMMARY_TASKS_QUERY,
  GET_ESTIMATOR_TOTALS_BY_ITEM_ID,
} from '@cdw-selline/ui/queries';
import { useApolloErrors, useTaskWriteQueue } from '../';
import { useState } from 'react';

export const useIncludesSummaryTasks = (projectItemId: string) => {
  const { handleErrorResponse } = useApolloErrors();

  const { data, loading, error } = useQuery(
    GET_ESTIMATOR_INCLUDES_SUMMARY_TASKS_QUERY,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: {
        projectItemId: projectItemId,
      },
      onError: (error) =>
        handleErrorResponse(error, 'Failed to fetch Summary Tasks flag!'),
    }
  );

  return {
    includesSummaryTasks: data?.getEstimatorIncludesSummaryTasks ?? false,
    includesSummaryTasksLoading: loading,
    includesSummaryTasksError: error,
  };
};

export const useSummaryTasks = (
  siteId: string,
  taskId = '',
  includesSummaryTasks: boolean,
  fullSiteLoad: boolean,
  setFullSiteLoad: (value: boolean) => void
) => {
  const { addTaskToWriteQueue, updateTaskLoading } = useTaskWriteQueue();
  const { handleErrorResponse } = useApolloErrors();
  const [tasks, setTasks] = useState([]);

  const { client } = useQuery(GET_ESTIMATOR_SUMMARY_TASKS_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    skip: !siteId || !includesSummaryTasks,
    variables: {
      siteId: siteId,
      firstRequest: fullSiteLoad || tasks.length === 0 ? true : false,
      taskId,
    },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Estimator Tasks!'),
    onCompleted: (data) => {
      if (tasks.length === 0 || fullSiteLoad) {
        setTasks(data.getEstimatorTasksBySiteId);
      } else {
        const updatedTasks = data.getEstimatorTasksBySiteId;
        const mergedTasks = [...tasks];

        updatedTasks.forEach((updatedTask) => {
          const existingIndex = mergedTasks.findIndex(
            (task) => task.id === updatedTask.id
          );

          if (existingIndex !== -1) {
            mergedTasks[existingIndex] = updatedTask;
          } else {
            mergedTasks.push(updatedTask);
          }
        });

        setTasks(mergedTasks);
      }

      setFullSiteLoad(false);

      client.refetchQueries({
        include: [GET_ESTIMATOR_TOTALS_BY_ITEM_ID],
      });
    },
  });

  return {
    estimatorTasks: tasks ?? [],
    addTaskToWriteQueue,
    updateTaskLoading,
  };
};

import { useQuery } from '@apollo/client';
import { GET_SCOPE_GROUP_BY_ID_QUERY } from '@cdw-selline/ui/queries';
import { useApolloErrors } from '.';

export const useScopeGroupById = (id: string) => {
  const { handleErrorResponse } = useApolloErrors();

  const { loading, error, data, refetch } = useQuery(
    GET_SCOPE_GROUP_BY_ID_QUERY,
    {
      variables: {
        id,
      },
      skip: !id,
      fetchPolicy: 'network-only', // Used for first execution
      nextFetchPolicy: 'cache-first', // Used for subsequent executions
      onError: (error) =>
        handleErrorResponse(error, 'Failed to fetch Scope Group!'),
    }
  );

  return {
    data: data?.getScopeGroupById ?? {},
    loading,
    error,
    refetch,
  };
};

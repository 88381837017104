import { useMutation, useQuery } from '@apollo/client';
import { UpdateProjectResponse, Task } from '@cdw-selline/common/types';
import {
  GET_ESTIMATOR_SOW_QUERY,
  GET_ESTIMATOR_TASK_GROUP_SCOPE_QUERY,
  UPDATE_ESTIMATOR_SOW_MUTATION,
  GET_ESTIMATOR_TASK_GROUPS_BY_PROJECT_ITEM_ID,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';

import { useApolloErrors } from './useApolloErrors';
import { useEstimatorSow } from './estimator/useEstimatorSow';

export const useEstimatorTasksSowSections = (
  projectId: string,
  projectItemId: string
) => {
  const alertState = useAlertsState();
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery(
    GET_ESTIMATOR_TASK_GROUP_SCOPE_QUERY,
    {
      variables: { projectItemId: projectItemId, projectId: projectId, taskGroupId: "" },
      skip: !projectItemId,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      onError: (error) =>
        handleErrorResponse(error, 'Failed to fetch sow sections!'),
    }
  );

  const {
    projectSow,
    projectSowLoading,
    projectSowError,
    refetch: projectSowRefetch
  } = useEstimatorSow(projectItemId);

  const [
    updateEstimatorSow,
    { loading: updateEstimatorSowLoading, error: updateEstimatorSowError },
  ] = useMutation<UpdateProjectResponse>(UPDATE_ESTIMATOR_SOW_MUTATION, {
    refetchQueries: [
      GET_ESTIMATOR_TASK_GROUP_SCOPE_QUERY,
      GET_ESTIMATOR_SOW_QUERY,
    ],
    onError: (error) =>
      handleErrorResponse(
        error,
        `Failed to update estimator sow: ${error.message}`,
        ALERT_TYPE.MODAL
      ),
    onCompleted: (data) => {
      if (data.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully Updated Estimator Sow',
        });
      }
    },
  });

  const estimatorSowSections = [] as Task[];

  const {
    data: taskGroups,
    loading: taskGroupsLoading,
    error: taskGroupsError,
  } = useQuery(GET_ESTIMATOR_TASK_GROUPS_BY_PROJECT_ITEM_ID, {
    variables: { projectItemId: projectItemId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch task groups!'),
  });

  return {
    loading,
    error,
    taskGroupSow: data?.getEstimatorTaskGroupScope?.sow ?? '',
    taskGroupRawSow: data?.getEstimatorTaskGroupScope?.rawSowContent ?? '',
    taskGroupSowRefetch: refetch,
    taskGroups: taskGroups?.getEstimatorTaskGroupsByProjectItemId ?? [],
    taskGroupLoading: taskGroupsLoading,
    taskGroupError: taskGroupsError,
    projectSow,
    projectSowRefetch,
    updateProjectSow: updateEstimatorSow,
    projectSowLoading,
    projectSowError,
    updateProjectSowLoading: updateEstimatorSowLoading,
    updateProjectSowError: updateEstimatorSowError,
  };
};

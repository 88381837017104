import { ApolloError } from '@apollo/client';
import { useCiscoAnnuity, useProjects, useUserIsCloudFulfillment } from '@cdw-selline/ui/hooks';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Card, CardHeader, Grid, Tab, useTheme, Tooltip, IconButton } from '@mui/material';
import { CiscoAnnuityDetails } from './details/CiscoAnnuityDetails';
import { CiscoAnnuityQuote } from './quote/CiscoAnnuityQuote';
import { CiscoAnnuityProposal } from './proposal/CiscoAnnuityProposal';
import { CiscoAnnuityOrderForm } from './order-form/CiscoAnnuityOrderForm';
import { CiscoAnnuityStatusStepper } from './status-stepper/CiscoAnnuityStatusStepper';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { CiscoAnnuityStatusHistoryDrawer } from './status-history-drawer/CiscoAnnuityStatusHistoryDrawer'
import {
  getCiscoAnnuityReadOnlyMode,
  getCiscoAnnuityActiveQuote
} from '@cdw-selline/ui/helpers';

export interface CiscoAnnuityProps {
  loading?: boolean;
  error?: ApolloError;
}

export function CiscoAnnuity(props: CiscoAnnuityProps) {
  const theme = useTheme();
  const { id, projectItemId } =
    useParams<{ id: string; projectItemId: string }>();
  const [value, setValue] = useState('1');
  const [drawerState, setDrawerState] = React.useState(false);
  const { userIsCloudFulfillment } = useUserIsCloudFulfillment();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    data: project,
  } = useProjects({ id: id });

  const { ciscoAnnuity, loading, error } = useCiscoAnnuity({
    projectItemId,
  });

  const activeQuote = getCiscoAnnuityActiveQuote(ciscoAnnuity);

  const isReadOnly = getCiscoAnnuityReadOnlyMode(
    ciscoAnnuity,
    userIsCloudFulfillment
  );

  //TODO Convert tabs to use theme
  return (
    <Grid
      item
      xl={12}
      xs={12}
      sx={{
        justifyContent: 'center',
        display: 'flex',
        mb: '1em',
        maxWidth: '100%',
        flexBasis: '100%',
      }}
    >
      <Card sx={{ width: '100%', mb: '.5em' }}>
        <CardHeader />
        <Box sx={{ width: '100%' }}>
          <TabContext value={value}>
            <Card sx={{ width: '100%', marginBottom: 6 }}>
              <Tooltip
                  title=" View Status History"
                  sx={{ position: 'absolute', right: '1em', mt: '0' }}
                >
                  <IconButton onClick={() => setDrawerState(!drawerState)}>
                    <MenuOpenIcon />
                  </IconButton>
                </Tooltip>
                  {drawerState && <CiscoAnnuityStatusHistoryDrawer
                    drawerState={drawerState}
                    toggleDrawer={setDrawerState}
                    statusHistories={ciscoAnnuity?.statusHistory}
                  />}
            </Card>
            <Box
              sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}
            >
              <CiscoAnnuityStatusStepper
                ciscoAnnuity={ciscoAnnuity}
                project={project}
                projectItemId={projectItemId}
                userIsCloudFulfillment={userIsCloudFulfillment}
              />
            </Box>
            <Box
              sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}
            >
              <TabList
                TabIndicatorProps={{
                  style: {
                    color: 'red',
                    background: `${theme.palette.secondary.main}`,
                  },
                }}
                onChange={handleChange}
              >
                <Tab
                  style={{ color: '#444' }}
                  label="Variables"
                  value="1"
                  data-testid="cisco-annuity-variables-tab-button"
                />
                <Tab
                  style={{ color: '#444' }}
                  label="Working"
                  value="2"
                  data-testid="cisco-annuity-working-tab-button"
                />
                <Tab
                  style={{ color: '#444' }}
                  label="Proposal"
                  value="3"
                  data-testid="cisco-annuity-proposal-tab-button"
                />
                {!activeQuote?.isEstimate && <Tab
                  style={{ color: '#444' }}
                  label="COF"
                  value="4"
                  data-testid="cisco-annuity-cof-tab-button"
                />}
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ width: '100%' }}>
              <CiscoAnnuityDetails
                projectItemId={projectItemId}
                project={project}
                ciscoAnnuity={ciscoAnnuity}
                userIsCloudFulfillment={userIsCloudFulfillment}
                isReadOnly={isReadOnly}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ width: '100%' }}>
              <CiscoAnnuityQuote
                id={id}
                projectItemId={projectItemId}
                ciscoAnnuity={ciscoAnnuity}
                isReadOnly={isReadOnly}
                userIsCloudFulfillment={userIsCloudFulfillment}
              />
            </TabPanel>
            <TabPanel value="3" sx={{ width: '100%' }}>
              <CiscoAnnuityProposal 
                ciscoAnnuity={ciscoAnnuity}
              />
            </TabPanel>
            {!activeQuote?.isEstimate && <TabPanel value="4" sx={{ width: '100%' }}>
              <CiscoAnnuityOrderForm />
            </TabPanel>}
          </TabContext>
        </Box>
      </Card>
    </Grid>
  );
}

export default CiscoAnnuity;

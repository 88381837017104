import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getToken } from '@cdw-selline/ui/state';
import { fetch } from 'cross-fetch';

const httpLink = createHttpLink({ fetch });

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem('token') || (await getToken());
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
  credentials: 'include',
});

export default client;

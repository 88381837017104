import { getAnalytics } from 'firebase/analytics';
import { FirebaseApp, getApp, initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectDatabaseEmulator, getDatabase } from 'firebase/database';
// import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

export const firebaseConfig = {
  projectId: process.env.NX_PROJECT_ID,
  apiKey: process.env.NX_APP_API_KEY,
  authDomain: process.env.NX_AUTH_DOMAIN,
  databaseURL: process.env.NX_DATABASE_URL,
  storageBucket: process.env.NX_STORAGE_BUCKET,
  messagingSenderId: process.env.NX_MESSAGING_SENDER_ID,
  appId: process.env.NX_APP_ID,
  measurementId: process.env.NX_MEASUREMENT_ID,
};

export function getFirebaseApp(name = 'SellineApp') {
  let app: FirebaseApp;
  try {
    app = getApp(name);
    getAnalytics(app);
  } catch (e) {
    if (e.code === 'app/no-app') {
      app = initializeApp(firebaseConfig, name);
      const fbAuth = getAuth(app);
      const db = getDatabase(app);
      if (
        typeof window === 'undefined' ||
        (String(process.env.NX_USE_EMULATORS) === 'true' &&
          window.location.protocol.includes('http:'))
        && process.env.NODE_ENV !== 'production'
      ) {
        const emulatorUrl = `http://${process.env.NX_AUTH_EMULATOR_HOST ||
          process.env.FIREBASE_AUTH_EMULATOR_HOST ||
          'localhost:9099'
          }`;
        console.log(`Using emulators for ${name} at ${emulatorUrl}`);
        connectAuthEmulator(fbAuth, emulatorUrl);
        connectDatabaseEmulator(db, 'localhost', 9022);
      }
    } else {
      throw e;
    }
  }
  return app;
}

import { CiscoAnnuityPaymentSchedule } from '@cdw-selline/common/types';
import { formatCurrency, formatPercent } from '@cdw-selline/ui/helpers';
import { TableCell, TableRow } from '@mui/material';
import React from 'react';

export interface CiscoAnnuityQuotePaymentScheduleRowProps {
  ciscoAnnuityPaymentSchedule: CiscoAnnuityPaymentSchedule;
}

export function CiscoAnnuityQuotePaymentScheduleRow({
  ciscoAnnuityPaymentSchedule,
}: CiscoAnnuityQuotePaymentScheduleRowProps) {
  if (ciscoAnnuityPaymentSchedule.id === 'RAW GP PERCENT') {
    return (
      <TableRow>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {ciscoAnnuityPaymentSchedule.label}
        </TableCell>
        {ciscoAnnuityPaymentSchedule.values.map((value, index) => (
          <TableCell
            sx={{ whiteSpace: 'nowrap' }}
            key={ciscoAnnuityPaymentSchedule.id + index.toString()}
            align="right"
          >
            {formatPercent(value)}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {ciscoAnnuityPaymentSchedule.label}
      </TableCell>
      {ciscoAnnuityPaymentSchedule.values.map((value, index) => (
        <TableCell
          sx={{ whiteSpace: 'nowrap' }}
          key={ciscoAnnuityPaymentSchedule.id + index.toString()}
          align="right"
        >
          {formatCurrency(value)}
        </TableCell>
      ))}
    </TableRow>
  );
}

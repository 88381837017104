import { gql } from '@apollo/client';

export const ADD_PROJECT_ITEMS_ESTIMATOR_MUTATION = gql`
  mutation addProjectItemsEstimatorMutation(
    $params: ProjectItemEstimatorsInput
  ) {
    addProjectItemEstimator(params: $params) {
      id
      success
    }
  }
`;

// VARIABLES
// {
//   ...ProjectItemEstimatorsInput
// }

// {"_id":{"$oid":"61d783ff62e0e0d9e10069c8"},"id":"1","projectItemId":2,"sites":[{"site":"Test Site","address":"","floor":"","quantity":1,"tasks":[{"id":"26300","site":"HQ","allowMarkup":"0","category":"Webex Edge Video Mesh","comment":"","cost":"0.0","costType":"0","createdAt":{"$date":"2021-10-29T15:46:51.725Z"},"exclude":false,"excludeToggle":false,"formula":"","hours":"8.0","lastReviewedOn":{"$date":"2000-01-01T00:00:00.000Z"},"markup":"0.0","minMarkup":"0.0","name":"Test added to Webex Edge Video Mesh","order":"10","overtime":false,"practice":"41","primaryPercent":"75","primaryRole":"161","projectId":"61bd088cb4dd342330f5e924","quantity":"1","secondaryPercent":"25","secondaryRole":"-1","sowSection":"0","subType":"0","taskGroups":["177"],"type":"0","units":"Per node"},{"id":"24111","site":"HQ","allowMarkup":"0","category":"Video - Edge & Gateways","comment":"","cost":"0.0","costType":"0","createdAt":{"$date":"2021-10-29T15:46:51.725Z"},"exclude":false,"excludeToggle":false,"formula":"","hours":"16.0","lastReviewedOn":{"$date":"2000-01-01T00:00:00.000Z"},"markup":"0.0","minMarkup":"0.0","name":"Expressway-E (first node in cluster) (B2B, MRA, CMR, Webex Hybrid Call Traversal)","order":"3","overtime":false,"practice":"41","primaryPercent":"75","primaryRole":"172","projectId":"61bd088cb4dd342330f5e924","quantity":"1","secondaryPercent":"25","secondaryRole":"0","sowSection":"0","subType":"0","taskGroupId":["177"],"type":"0","units":"Servers"}]}]}

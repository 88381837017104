import { CdwSvgLogo, DarkMode, ProjectForm } from '@cdw-selline/ui/components';
import { ROUTES } from '@cdw-selline/ui/constants';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  getCurrentUser,
  signOut,
  useAlertsState,
} from '@cdw-selline/ui/state';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchIcon from '@mui/icons-material/Search';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  IconButton,
  InputBase,
  MenuItem,
  ClickAwayListener,
  Divider,
  Grid,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuList,
  Paper,
  Popper,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  alpha,
  styled,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { ContentRequestForm } from '@cdw-selline/ui/components';
import { useOpenState, useProjects } from '@cdw-selline/ui/hooks';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: `${alpha(theme.palette.common.white, 0.15)} !important`,
  '&:hover': {
    backgroundColor: `${alpha(theme.palette.common.white, 0.25)} !important`,
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    border: `1px solid ${theme.palette.secondary}`,
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'flex-start',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  // marginBottom: theme.spacing(2),
  // Override media queries injected by theme.mixins.toolbar
  // '@media all': {
  // minHeight: 100,
  // },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 2,
}));

function stringToColor(string) {
  let hash = 0;
  let i: number;
  for (i = 0; i < string?.length; i += 1) {
    hash = string?.charCodeAt?.(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value?.toString?.(16)}`?.substr?.(-2);
  }
  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: name
      ? `${name?.split(' ')[0][0]}${
          name?.split(' ').length > 1 ? name.split(' ')[1][0] : ''
        }`
      : '',
  };
}
const getTabs = (isAdmin) => {
  return isAdmin ?
  [
    { label: 'Dashboard', href: ROUTES.HOME },
  ] :
   [
    { label: 'Dashboard', href: ROUTES.HOME },
    { label: 'Opportunities', href: ROUTES.PIPELINES },
    { label: 'Accounts', href: ROUTES.CUSTOMERS },
    /* { label: 'Leads', href: ROUTES.LEADS }, // process.env.NODE_ENV === 'production' ? const menus = useNavMenu() :  const menus = {} */
  ];
}

const LinkTab = (props) => {
  const navigate = useNavigate();
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
        props.href && navigate(props.href);
      }}
      {...props}
    />
  );
};

export interface PrimaryAppBarProps {
  showSearch?: boolean;
  toggleDrawer: (forceClose?: boolean) => void;
  appBarTitle: string;
  handleProjectClick: () => void;
  projectModalOpen?: boolean;
  setProjectModalOpen: React.Dispatch<SetStateAction<boolean>>;
  handleProjectSubmit: (formData: Record<string, string>) => void;
  handleClose: () => void;
  createProjectLoading: boolean;
  handleLeadClick: () => void;
  leadModalOpen?: boolean;
  setLeadModalOpen: React.Dispatch<SetStateAction<boolean>>;
  handleLeadSubmit: (formData: Record<string, string>) => void;
  createLeadLoading: boolean;
  setTheme?: React.Dispatch<SetStateAction<'light' | 'dark'>>;
  theme?: 'light' | 'dark';
}

export const PrimaryAppBar: React.FC<PrimaryAppBarProps> = ({
  showSearch,
  toggleDrawer,
  handleProjectClick,
  projectModalOpen,
  setProjectModalOpen,
  handleProjectSubmit,
  createProjectLoading,
  handleLeadClick,
  leadModalOpen,
  setLeadModalOpen,
  handleLeadSubmit,
  createLeadLoading,
  setTheme,
  theme: darkMode,
}) => {
  const alertState = useAlertsState();
  const notifications = alertState.getNotifications() || [];
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    isOpen: contentRequestOpen,
    handleClose: closeContentRequestForm,
    handleOpen: openContentRequestForm,
  } = useOpenState();
  const { createJiraIssue } = useProjects({});
  const currentUser = getCurrentUser();
  const isAdmin = window.location.href.includes('admin');
  const tabs = getTabs(isAdmin);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleToggleProfileMenu = () => {
    setOpen((p) => !p);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
    setOpen(false);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleSignOut = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    handleMenuClose();
    await signOut();
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleSubmitRequestForm = (request)=> {
   createJiraIssue({
     variables: {
           params: {
            project: { key: 'PUS' },
            priority: {
              name: "Medium"
            },
            summary: 'Content Request',
            description: request,
            issuetype: { name: 'Bug' },
            reporter: {
              id: '62e8124dec6b328032f0c954', // create a pull for all reporters with specific jira ids
              name: 'Katrina Manoshin'
            },
            assignee: {
              name: '' //unassigned
            },
          }
    }});
  };
  const location = useLocation();
  const tabLocation = tabs.findIndex((t) => location?.pathname === t.href);
  const [value, setValue] = useState<number | boolean>(
    tabLocation > 0 ? tabLocation : 0
  );

  useEffect(() => {
    setValue(tabLocation !== -1 ? tabLocation : false);
  }, [tabLocation]);

  const menuId = 'primary-search-account-menu';
  const mobileMenuId = 'primary-search-account-menu-mobile';

  const avatarProps =
    currentUser?.displayName || currentUser?.email
      ? { ...stringAvatar(currentUser?.displayName) }
      : {};

  return (
    <Box sx={{ flexGrow: 1, width: '100%' }}>
      <AppBar
        position="fixed"
        style={{
          boxShadow:
            '0px 3px 1px -2px red,0px 2px 2px 0px rgba(100,0,0,0.9),0px 1px 5px 0px rgba(0,0,0,0.12)',
        }}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <StyledToolbar>
          {process.env.NODE_ENV !== 'production' && (
            <IconButton
              size="medium"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2, mt: '.25em' }}
              onClick={() => toggleDrawer()}
              data-testid="nav-toggle-drawer"
            >
              <MenuIcon />
            </IconButton>
          )}
          <Grid
            sx={{
              display: 'flex',
              alignItems: 'center',
              minHeight: '4em',
              width: '100px',
              mr: 5,
            }}
          >
            <CdwSvgLogo height='24px' />
          </Grid>
          <Tabs
            value={value}
            aria-label="nav tabs"
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="secondary"
            textColor="inherit"
            sx={{
              minHeight: '3.5em',
              alignItems: 'unset',
              paddingTop: '.5em',
              marginTop: '0em',
            }}
          >
            {tabs.map((t, idx) => (
              <LinkTab
                data-testid={`nav-tab-${idx.toString()}`}
                label={t.label}
                href={t.href}
                key={`link-tab-${idx}`}
              />
            ))}
          </Tabs>
          {!isAdmin && <Tooltip title="Add Opportunity">
            <IconButton
              sx={{ mt: '.5em' }}
              size="medium"
              aria-label="Add Opportunity"
              color="inherit"
              onClick={handleProjectClick}
            >
              <AddBoxIcon data-testid="AddBoxIcon" />
            </IconButton>
          </Tooltip>}
          {process.env.NODE_ENV !== 'production' && (
            <Tooltip title="Add Lead">
              <IconButton
                sx={{ mt: '.5em' }}
                size="medium"
                aria-label="Add Lead"
                color="inherit"
                onClick={handleLeadClick}
              >
                <AddBusinessIcon />
              </IconButton>
            </Tooltip>
          )}
          {!showSearch && (
            <Search sx={{ marginTop: '.5em' }}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
            {process.env.NODE_ENV !== 'production' && (
                <IconButton
                  size="medium"
                  aria-label="Create Content Request"
                  color="inherit"
                  onClick={openContentRequestForm}
                >
                   <ContactMailIcon />
                </IconButton>
              )}
            <Paper>
              <ProjectForm
                isOpen={projectModalOpen}
                handleClose={() => setProjectModalOpen(false)}
                handleSubmit={handleProjectSubmit}
                projectId={null}
              />
            </Paper>
            <Grid sx={{ mt: '.5em' }}>
              <DarkMode
                checked={darkMode === 'dark'}
                onChange={(e) => {
                  setTheme(() => {
                    if (e.target.checked) {
                      localStorage.darkMode = 'true';
                      return 'dark';
                    }
                    delete localStorage.darkMode;
                    return 'light';
                  });
                }}
              />
            </Grid>

            {process.env.NODE_ENV !== 'production' && (
              <IconButton
                size="medium"
                aria-label="show 4 new mails"
                color="inherit"
                onClick={() =>
                  alertState.setAlert({
                    type: ALERT_TYPE.MODAL,
                    severity: ALERT_SEVERITY.WARNING,
                    title: 'Not Implemented',
                    message: 'Message are not implemented currently',
                  })
                }
              >
                <Badge badgeContent={4} color="error">
                  <MailIcon />
                </Badge>
              </IconButton>
            )}
            {process.env.NODE_ENV !== 'production' && (
              <IconButton
                size="medium"
                aria-label={`show ${notifications?.length} new notifications`}
                color="inherit"
                onClick={() =>
                  alertState.setAlert({
                    type: ALERT_TYPE.SNACKBAR,
                    severity: ALERT_SEVERITY.SUCCESS,
                    message: 'You successfully pressed the button',
                  })
                }
              >
                <Badge badgeContent={notifications?.length} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            )}
            <IconButton
              size="small"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleToggleProfileMenu}
              color="inherit"
              ref={!isMobile ? anchorRef : null}
            >
              <Avatar {...avatarProps} src={currentUser.photoURL} />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
            <IconButton
              size="medium"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleToggleProfileMenu}
              color="inherit"
              ref={isMobile ? anchorRef : null}
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </StyledToolbar>
      </AppBar>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 1203 }}
      >
        {({ TransitionProps, placement }) => (
          <ClickAwayListener onClickAway={handleMenuClose}>
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
                marginTop: '.5em',
              }}
            >
              <StyledPaper>
                <Box sx={{ p: '1em' }}>
                  <Typography variant="subtitle2">
                    {currentUser?.displayName ?? 'User'}
                  </Typography>
                </Box>
                <Divider />
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  onClick={handleMenuClose}
                >
                  {process.env.NODE_ENV !== 'production' && (
                    <MenuItem
                      onClick={handleMenuClose}
                      component={RouterLink}
                      to={ROUTES.PROFILE}
                    >
                      <ListItemIcon>
                        <AccountBoxIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Profile" />
                    </MenuItem>
                  )}
                  <MenuItem onClick={handleSignOut}>
                    <ListItemIcon>
                      <ExitToAppIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Sign out" />
                  </MenuItem>
                </MenuList>
              </StyledPaper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
      {contentRequestOpen && (
        <ContentRequestForm
          isOpen={contentRequestOpen}
          handleClose={closeContentRequestForm}
          handleSubmit={handleSubmitRequestForm}
        />
      )}
    </Box>
  );
};
export default PrimaryAppBar;

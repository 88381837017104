import { useState, Dispatch, SetStateAction, useRef } from 'react';
import {
  HandsonTableSelectionData,
  VolumePricing,
} from '@cdw-selline/common/types';
import { CellChange, ChangeSource } from 'handsontable/common';
import { cloneDeep } from 'lodash';

export interface HandsonTable {
  data: Record<string | number, unknown>;
  columnFields: Record<number, string>;
  setFormData: Dispatch<SetStateAction<Record<string, unknown>>>;
  formData: Record<string, string>;
  key: string;
  useDefaultRows?: boolean;
}

export const useHandsonTable = (
  data,
  columnFields,
  setFormData,
  formData,
  key,
  useDefaultRows
) => {
  const tableRef = useRef(null);

  const getTableRows = (data: Array<unknown>[], headers: boolean) => {
    return data.map((line) => {
      const tableRow = {};
      const columnFieldsArr = Object.values(columnFields);
      columnFieldsArr.forEach((field: number, idx) => {
        headers ? (tableRow[field] = line[idx]) : (tableRow[idx] = line[field]);
      });
      return tableRow;
    });
  };

  if (data?.length < 1 && useDefaultRows) {
    data = [[], [], [], [], []];
  }
  const [tableRows, setTableRows] = useState(getTableRows(data, false));
  const [selections, setSelections] = useState<HandsonTableSelectionData[]>([]);

  const getTasksWithHandsonTable = () => {
    const taskTableArray = tableRef.current?.hotInstance
      .getData()
      .filter((row) => !(!row[0] && !row[1] && !row[2]))
      .map((row) => {
        return {
          minQuantity: Number(row[0]),
          maxQuantity: Number(row[1]),
          cost: Number(row[2]),
        } as VolumePricing;
      });

    return taskTableArray;
  };

  const handleLineChange = (
    changeData: CellChange,
    source: ChangeSource
  ): void => {
    if (source === 'loadData') return;
    if (!changeData) return;

    const [change] = changeData;
    const columnNumber = change[1];
    const rowNumber = change[0];
    const tableRowsCopy = cloneDeep(tableRows);
    const newValue = Number(change[3]);
    const editRow = tableRowsCopy[rowNumber];
    editRow[columnNumber] = newValue;
    tableRowsCopy[rowNumber] = editRow;

    setTableRows(tableRowsCopy);
    setFormData({
      ...formData,
      [key]: getTasksWithHandsonTable(),
    });
  };

  const handleAddEmptyLine = () => {
    setTableRows([...tableRows, {}]);
  };

  const handleRemoveSelectedRows = () => {
    if (selections.length === 0) return;
    const newTableRows = [];
    const selectionIds = selections.map((s) => s[0]);
    tableRows.forEach((tr, idx) => {
      if (!selectionIds.includes(idx)) {
        newTableRows.push(tr);
      }
    });
    setTableRows(newTableRows);
    setFormData({
      ...formData,
      [key]: getTableRows(newTableRows as Array<unknown>[], true),
    });
  };

  const getColumns = (columnNames) => {
    const configureColumn = (colName: string) => {
      return {};
    };
    return columnNames.map(configureColumn);
  };

  return {
    handleRemoveSelectedRows,
    handleLineChange,
    handleAddEmptyLine,
    getColumns,
    tableRows,
    setTableRows,
    selections,
    setSelections,
    tableRef,
  };
};

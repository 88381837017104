import { gql } from '@apollo/client';

export const ADD_SCOPE_ITEM_MUTATION = gql`
  mutation Mutation($params: ScopeItemInput!) {
    addScopeItem(params: $params) {
      success
      id
    }
  }
`;

/*
VARIABLES
{
  "params": {
    "category": null,
    "collateral": [
      {
        "description": null,
        "link": null,
        "title": null
      }
    ],
    "legacyId": null,
    "notes": null,
    "order": null,
    "parentItemDataId": null,
    "parentItemDataValue": null,
    "parentItemId": null,
    "practiceId": null,
    "questions": [
      {
        "createdOn": null,
        "createdBy": null,
        "list": [
          {
            "options": [
              {
                "value": null,
                "name": null
              }
            ],
            "selectIndex": null
          }
        ],
        "name": null,
        "order": null,
        "title": null,
        "type": null
      }
    ]
  }
}
*/

import { gql } from '@apollo/client';

export const GET_SCOPING_SESSIONS_BY_PROJECT_QUERY = gql`
  query Query($projectItemId: ID!) {
    getScopingSessionsByProjectItemId(projectItemId: $projectItemId) {
      createdBy
      createdOn
      customerId
      id
      name
      notes
      projectItemId
      updatedBy
      updatedOn
      section {
        name
        order
        questions {
          createdBy
          createdOn
          scopeQuestionId
          value
        }
      }
    }
  }
`;

// eslint-disable-next-line @nx/enforce-module-boundaries
import { useOpenState } from '@cdw-selline/ui/hooks';
import {
  Grid,
  Typography,
  Button,
  IconButton,
  Divider,
  Select,
  MenuItem,
} from '@mui/material';
import * as React from 'react';
import { DialogConfirm } from '../..';
import { AdminAccessForm } from './AdminAccessForm';
import { GridRowsProp } from '@mui/x-data-grid';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

interface RowProps {
  id?: string;
  email: string;
  name?: string;
}

export interface AdminAccessTableProps {
  title?: string;
  rows?: RowProps[];
  handleSave: (params, usersType?) => void;
  handleDelete: (value, usersType) => void;
  solo?: boolean;
  usersType?: string;
}

export const AdminAccessTable = ({
  title,
  rows,
  handleSave,
  handleDelete,
  solo,
  usersType,
}: AdminAccessTableProps) => {
  const {
    isOpen: usersFormOpen,
    handleClose: closeUsersForm,
    handleOpen: openUsersForm,
  } = useOpenState();

  const {
    isOpen: deleteDialogConfirmOpen,
    handleClose: handleDeleteDialogConfirmClose,
    handleOpen: handleDeleteDialogConfirmOpen,
  } = useOpenState();

  const [userToDelete, setUserToDelete] = useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handleDeleteClick = (
    event: React.MouseEvent<unknown>,
    email: string
  ) => {
    event.stopPropagation();
    setUserToDelete(email);
    handleDeleteDialogConfirmOpen();
  };

  const handleDialogDelete = () => {
    handleDelete(userToDelete, usersType);

    handleDeleteDialogConfirmClose();
    setUserToDelete(null);
  };

  const handleDialogCancel = () => {
    handleDeleteDialogConfirmClose();
    setUserToDelete(null);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Grid container sx={{ paddingLeft: '8px' }}>
      <TableContainer
        sx={{ width: '100%', marginBottom: 2, marginTop: 2 }}
        component={Paper}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{title}</TableCell>
              <TableCell align="right">
                {(!solo || rows.length === 0) && (
                  <Button
                    startIcon={<AddIcon />}
                    onClick={openUsersForm}
                    data-testid={`add-record-team`}
                  >
                    Add
                  </Button>
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          {rows.length > 0 && (
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell sx={{ fontSize: '14px' }}>{row.email}</TableCell>
                    <TableCell align="right" key={row.id}>
                      <IconButton
                        size="small"
                        aria-label="delete"
                        onClick={(event) => handleDeleteClick(event, row.email)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
        {!solo && rows.length > 10 && (
          <>
            <Divider light />
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </TableContainer>

      {usersFormOpen && (
        <AdminAccessForm
          isOpen={usersFormOpen}
          handleClose={closeUsersForm}
          {...{
            handleSave,
          }}
          usersType={usersType}
        />
      )}
      {deleteDialogConfirmOpen && (
        <DialogConfirm
          title="Delete?"
          isOpen={deleteDialogConfirmOpen}
          handleClose={handleDialogCancel}
          handleYes={handleDialogDelete}
        >
          <Typography>Are you sure you want to remove this user?</Typography>
        </DialogConfirm>
      )}
    </Grid>
  );
};

import React from 'react';
import {
  useStandrdRmsProposalTemplate,
  useTierRmsProposalTemplate,
  useRmsOneTimeCostTemplate,
} from '@cdw-selline/ui/hooks';

import TierRMSProposalTemplate from './rms-proposal-template/TierRMSProposalTemplate';
import StandardRMSProposalTemplate from './rms-proposal-template/StandardRMSProposalTemplate';
import RMSOneTimeCostTemplate from './rms-proposal-template/RMSOneTimeCost';
import { RmsProposal } from '@cdw-selline/common/types';

interface RMSProposalTableProps {
  isTierTemplate: boolean;
  updateProposalData: (rmsProposal: RmsProposal) => void;
  rmsProposal: RmsProposal;
}

export const RMSProposalTable = ({
  isTierTemplate,
  updateProposalData,
  rmsProposal,
}: RMSProposalTableProps) => {
  const { rmsProposalTemplate } = useStandrdRmsProposalTemplate();
  const { rmsProposalTemplateTier } = useTierRmsProposalTemplate();
  const { rmsOneTimeCostTemplate } = useRmsOneTimeCostTemplate();

  return (
    <React.Fragment>
      {isTierTemplate ? (
        <TierRMSProposalTemplate
          costItems={rmsProposalTemplateTier?.rmsProposalItems || []}
          totalMonthlyCharges={
            rmsProposalTemplateTier?.totalMonthlyCharges || 0.0
          }
          bronzeTotal={rmsProposalTemplateTier?.bronzeTotal || 0.0}
          silverTotal={rmsProposalTemplateTier?.silverTotal || 0.0}
          goldTotal={rmsProposalTemplateTier?.goldTotal || 0.0}
          updateProposalData={updateProposalData}
          rmsProposal={rmsProposal}
        />
      ) : (
        <StandardRMSProposalTemplate
          costItems={rmsProposalTemplate?.rmsProposalItems || []}
          totalMonthlyCharges={rmsProposalTemplate?.totalMonthlyCharges || 0.0}
        />
      )}
      <RMSOneTimeCostTemplate
        costItems={rmsOneTimeCostTemplate?.rmsOneTimeCostItems || []}
        totalCharges={rmsOneTimeCostTemplate?.totalMonthlyCharges || 0.0}
      />
    </React.Fragment>
  );
};

export default RMSProposalTable;

import { gql } from '@apollo/client';

export const GET_TIER_BY_ID_QUERY = gql`
  query getTierById($id: ID!) {
    getTierById(id: $id) {
      id
      name
    }
  }
`;


import { useMutation } from '@apollo/client';
import {
  GET_PROJECT_ITEM_ESTIMATORS_QUERY,
  SAVE_ESTIMATE_TO_PSA_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';

import { useApolloErrors } from '..';
import { useParams } from 'react-router-dom';

export const useSaveEstimateToPsa = () => {
  const { projectItemId } = useParams<{ projectItemId: string }>();
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();


  const [saveEstimateToPsa, { loading, error, client }] =
    useMutation(SAVE_ESTIMATE_TO_PSA_MUTATION, {
      variables: {
        projectItemId,
      },
      refetchQueries: [
      GET_PROJECT_ITEM_ESTIMATORS_QUERY
    ],
      onError: (error) => {
        alertState.setAlert({
          type: ALERT_TYPE.MODAL,
          severity: ALERT_SEVERITY.ERROR,
          message: `Failed to save estimate to PSA!\n${error.message}`,
        });
      },
      onCompleted: (data) => {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully Saved Estimate to PSA',
        });
      },
    });

  return {
    saveEstimateToPsa,
    loading,
    error,
  };
};

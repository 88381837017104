import { useQuery } from '@apollo/client';
import { Lead } from '@cdw-selline/common/types';
import { GET_LEADS_QUERY } from '@cdw-selline/ui/queries';
import { useParams } from 'react-router-dom';

import { useApolloErrors } from './useApolloErrors';

export const useLeads = ({ limit = 10, offset = 0, filters = {} }) => {
  const params = {
    limit,
    offset,
    filters,
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getLeads: Lead[];
  }>(GET_LEADS_QUERY, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    variables: params,
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch opportunities!'),
  });

  return {
    data: data?.getLeads ?? null,
    error,
    loading,
    refetch,
  };
};

export default useLeads;

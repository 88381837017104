import {
    FormText,
    FormCheckbox,
    FormTextControlled,
  } from '../formHelperFunctions';

import React, { Dispatch, SetStateAction } from 'react';

export interface PrimaryRoleOverrideIncludeProps {
    setFormData: Dispatch<SetStateAction<Record<string, unknown>>>;
    formData: Record<string, string>;
    isCustom: boolean;
}

export function PrimaryRoleOverrideInclude({
    setFormData,
    formData,
    isCustom,
}: PrimaryRoleOverrideIncludeProps) {


  return (
    <>
        <FormTextControlled
            name="primaryRoleRateOverride"
            label="Primary Role Rate Override"
            type='number'
            setFormData={setFormData}
            formData={formData}
        />
        {!isCustom && <FormText
            name="primaryRoleRateOverrideFormula"
            label="Primary Role Rate Override Formula"
            setFormData={setFormData}
            formData={formData}
        />}
        {!isCustom && <FormCheckbox
            name="allowModifyPrimaryRoleRate"
            label={'Allow Modify Primary Role Rate'}
            setFormData={setFormData}
            formData={formData}
        />}
    </>
  );
}

export default PrimaryRoleOverrideInclude;

import React from 'react';
import { useTaskGroupById, useAdminTaskGroups } from '@cdw-selline/ui/hooks';
import { TaskGroupForm } from '../task-groups/TaskGroupForm';

const EditTaskGroupForm = ({
  taskGroupId,
  setTaskGroupId,
}: {
  taskGroupId: string;
  setTaskGroupId: (v: undefined) => void;
}) => {
    const {
        data: taskGroup,
        loading: taskGroupLoading,
        error: taskGroupError,
      } = useTaskGroupById(taskGroupId);
      const { handleTaskGroupFormSave,
        handleTaskGroupFormDuplicate,
        handleTaskGroupFormTestVersion,
        handleTaskGroupFormApproveTestVersion,
        handleTaskGroupFormDeleteTestVersion 
      } = useAdminTaskGroups();
  

  const handleCloseOpenMode = () => setTaskGroupId(undefined);

  return (
    <TaskGroupForm
      isOpen={true}
      taskGroup={taskGroup}
      handleClose={handleCloseOpenMode}
      handleSubmit={handleTaskGroupFormSave}
      handleDuplicate={handleTaskGroupFormDuplicate}
      handleTestVersion={handleTaskGroupFormTestVersion}
      handleDeleteTestVersion={handleTaskGroupFormDeleteTestVersion}
      handleApproveTestVersion={handleTaskGroupFormApproveTestVersion}
      taskGroupLoading={taskGroupLoading}
      taskGroupError={taskGroupError}
    />
  );
};

export default EditTaskGroupForm;

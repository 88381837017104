import { useQuery } from '@apollo/client';
import { GetServiceLocationsResponse } from '@cdw-selline/common/types';
import { GET_SERVICE_LOCATIONS_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from './useApolloErrors';


export const useServiceLocations = (sort?: unknown) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery<{
    getServiceLocations: GetServiceLocationsResponse;
  }>(GET_SERVICE_LOCATIONS_QUERY, {
    variables:{
      sort
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Service Locations!'),
  });

  return {
    error,
    loading,
    data: data?.getServiceLocations.locations ?? [],
  };
};

export default useServiceLocations;

import { useMutation } from '@apollo/client';
import {
  HandledMutationResponse,
  ProposalTemplate,
} from '@cdw-selline/common/types';
import {
  getFilters,
  getFilterModelFromLocalStorage,
} from '@cdw-selline/ui/helpers';
import {
  ADD_PROPOSAL_TEMPLATE_MUTATION,
  REMOVE_PROPOSAL_TEMPLATE_MUTATION,
  UPDATE_PROPOSAL_TEMPLATE_MUTATION,
  GET_PROPOSAL_TEMPLATES_QUERY,
  GET_PROPOSAL_TEMPLATE_BY_ID_QUERY,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import {
  GridColDef,
  GridPaginationModel,
  GridFilterModel,
} from '@mui/x-data-grid';
import { useState } from 'react';
import { useApolloErrors } from './useApolloErrors';
import { useProposalTemplates } from './proposals/useProposalTemplates';
import { useOpenState } from './estimator/useOpenState';
import { omit, pick } from 'lodash';
import moment from 'moment';
import { PAGES } from '@cdw-selline/ui/constants';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Template ID',
    width: 180,
    editable: false,
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Template Name',
    width: 180,
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Created Date',
    type: 'dateTime',
    width: 180,
    editable: false,
    valueGetter: (value) => value && !isNaN(value) && new Date(parseInt(value)),
    valueFormatter: (value) =>
      value ? moment(value).format('MM/DD/YYYY') : '',
  },
];

export const useAdminProposalTemplates = () => {
  const [sortState, setSortState] = useState();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  const filterModel: GridFilterModel = getFilterModelFromLocalStorage(`${PAGES.PROPOSAL_TEMPLATES_COLLECTION}-filter`);

  const { data, loading, error, refetch } = useProposalTemplates({
    filters: getFilters(PAGES.PROPOSAL_TEMPLATES_COLLECTION),
    offset: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize,
    sort: sortState,
  });
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();
  const [editProposalTemplateId, setEditProposalTemplateId] = useState(null);
  const {
    isOpen: proposalTemplateFormOpen,
    handleClose: closeProposalTemplateForm,
    handleOpen: openProposalTemplateForm,
  } = useOpenState();

  const [
    addProposalTemplate,
    { loading: addProposalTemplateLoading, error: addProposalTemplateError },
  ] = useMutation<{ addProposalTemplate: HandledMutationResponse }>(
    ADD_PROPOSAL_TEMPLATE_MUTATION,
    {
      refetchQueries: [
        GET_PROPOSAL_TEMPLATES_QUERY,
        GET_PROPOSAL_TEMPLATE_BY_ID_QUERY,
      ],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to create new proposal template'),
      onCompleted: (data) => {
        if (data.addProposalTemplate.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully added Proposal Template',
          });
        }
      },
    }
  );

  const [
    removeProposalTemplate,
    {
      loading: removeProposalTemplateLoading,
      error: removeProposalTemplateError,
    },
  ] = useMutation<{ removeProposalTemplate: HandledMutationResponse }>(
    REMOVE_PROPOSAL_TEMPLATE_MUTATION,
    {
      refetchQueries: [GET_PROPOSAL_TEMPLATES_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to remove sproposal template'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.removeProposalTemplate.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully removed proposal template',
          });
        }
      },
    }
  );

  const [
    updateProposalTemplate,
    {
      loading: updateProposalTemplateLoading,
      error: updateProposalTemplateError,
    },
  ] = useMutation<{ updateProposalTemplate: HandledMutationResponse }>(
    UPDATE_PROPOSAL_TEMPLATE_MUTATION,
    {
      refetchQueries: [GET_PROPOSAL_TEMPLATES_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update proposal template'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.updateProposalTemplate.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully updated proposal template',
          });
        }
      },
    }
  );

  const handleAdd = () => {
    openProposalTemplateForm();
  };

  const handleProposalTemplateFormClose = () => {
    setEditProposalTemplateId(null);
    closeProposalTemplateForm();
  };

  const handleEdit = (id: string) => {
    setEditProposalTemplateId(id);
    openProposalTemplateForm();
  };

  const handleDelete = (pid: string) =>
    removeProposalTemplate({
      variables: { id: pid },
    });

  const handleProposalTemplateFormSave = (
    proposalTemplate: ProposalTemplate
  ) => {
    const filteredDatacollection = proposalTemplate?.dataCollection?.map(
      (row) => omit(row, '__typename')
    );

    const filteredProposalTemplate = {
      ...proposalTemplate,
      dataCollection: filteredDatacollection,
    };

    if (!filteredProposalTemplate.id) {
      addProposalTemplate({
        variables: {
          params: pick(filteredProposalTemplate, [
            'content',
            'dataCollection',
            'isDataCollectionIncluded',
            'isSystem',
            'name',
          ]),
        },
      });
    } else {
      updateProposalTemplate({
        variables: {
          params: pick(filteredProposalTemplate, [
            'id',
            'content',
            'dataCollection',
            'isDataCollectionIncluded',
            'isSystem',
            'name',
          ]),
        },
      });
    }
  };

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    const pageSize = model?.pageSize ?? paginationModel?.pageSize;
    const page = model?.page ?? paginationModel?.page;
    setPaginationModel({
      page,
      pageSize,
    });
  };

  const onFilterModelChange = (filterModel) => {
    refetch({ filters: getFilters(PAGES.PROPOSAL_TEMPLATES_COLLECTION) });
  };

  const handleSort = (args) => {
    let newSort;
    if (args.length) newSort = { [args[0].field]: args[0].sort };
    setSortState(newSort);
  };

  const returnValue = {
    columns,
    rows: data?.proposalTemplates || [],
    handleAdd,
    handleDelete,
    handleEdit,
    handlePaginationModelChange,
    onFilterModelChange,
    handleSort,
    filterModel: filterModel,
    editProposalTemplateId,
    addProposalTemplateLoading,
    addProposalTemplateError,
    removeProposalTemplateLoading,
    removeProposalTemplateError,
    updateProposalTemplateLoading,
    updateProposalTemplateError,
    loading:
      loading ||
      addProposalTemplateLoading ||
      removeProposalTemplateLoading ||
      updateProposalTemplateLoading,
    error,
    proposalTemplateFormOpen,
    handleProposalTemplateFormClose,
    handleProposalTemplateFormSave,
    rowCount: data?.count,
  };
  if (
    loading ||
    addProposalTemplateLoading ||
    removeProposalTemplateLoading ||
    updateProposalTemplateLoading
  ) {
    return {
      ...returnValue,
      paginationModel: { pageSize: 100, page: 0 },
      rowCount: 0,
      rows: [],
    };
  } else {
    return { ...returnValue, paginationModel };
  }
};

import { gql } from '@apollo/client';

export const ADD_PROPOSAL_TEMPLATE_MUTATION = gql`
  mutation Mutation($params: ProposalTemplateInput!) {
    addProposalTemplate(params: $params) {
      data
      success
      error
    }
  }
`;

/*
VARIABLES:
{
  "params": {
    "content": "<p>HI</p>",
    "name": "test proposal",
  }
}
*/

/*
RETURNS:
{
  "data": {
    "addProposalTemplate": {
      "data": "123",  id for created record 
      "success": "true",
      "error": error message if not successfull 
    }
  }
}
*/

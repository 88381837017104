import { Task } from '@cdw-selline/common/types';
import { SelectChangeEvent } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useAddTaskNote } from './useAddTaskNote';
import { useTaskNotes } from './useTaskNotes';
import { NOTE_TYPES } from '@cdw-selline/ui/constants';

export const useTaskNoteForm = (
  task: Task,
  noteStateCallback: () => void,
  siteId,
  projectItemId
) => {
  const {
    data: taskNotes,
    loading: taskNotesLoading,
    error: taskNotesError,
  } = useTaskNotes(task.id);

  const [noteInput, setNoteInput] = useState<string>('');
  const handleNoteInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNoteInput(e.target.value);
  };

  const [noteType, setNoteType] = useState<string>(NOTE_TYPES[0]);
  const handleNoteTypeChange = (e: SelectChangeEvent) => {
    setNoteType(e.target.value);
  };

  const handleFormReset = () => {
    setNoteType(NOTE_TYPES[0]);
    setNoteInput('');
  };

  const handleComplete = () => {
    handleFormReset();
    noteStateCallback();
  };

  const { id: taskId } = task;
  const {
    handleAddTaskNote,
    loading: addNoteLoading,
    error: addNoteError,
  } = useAddTaskNote(handleComplete);

  const handleNoteSubmit = () => {
    handleAddTaskNote({
      projectItemId,
      siteId,
      taskId,
      noteType,
      note: noteInput,
    });
  };

  return {
    handleNoteSubmit,
    addNoteLoading,
    addNoteError,
    noteType,
    handleNoteTypeChange,
    noteInput,
    handleNoteInput,
    handleFormReset,
    handleComplete,
    taskNotes,
    taskNotesLoading,
    taskNotesError,
  };
};

import { createState, useState } from '@hookstate/core';

import { Persistence } from './persistence.plugin';
import { SnackbarSchema, initialSnackbarState } from './snackbar.state';
import { UserSchema, initialUserState } from './user.state';

export interface GlobalStateSchema {
  error?: Error;
  loadingMessage: string;
  user: UserSchema;
  snackbar: SnackbarSchema;
  loading: boolean;
  lastRoute: string;
}

export const initialState: GlobalStateSchema = JSON.parse(
  JSON.stringify({
    error: null,
    loadingMessage: '',
    user: initialUserState,
    snackbar: initialSnackbarState,
    loading: false,
    lastRoute: '',
  })
);

export const globalState = createState(initialState);

export const useGlobalState = () => useState(globalState);

typeof window !== 'undefined' &&
  globalState.attach(Persistence('global-state'));

export default globalState;

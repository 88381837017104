// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  Button,
  Grid,
  TextField,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState } from 'react';
import { useOpenState } from '@cdw-selline/ui/hooks';
import { DialogConfirm } from '../dialog-confirm/DialogConfirm';
import { formatDateDistanceToNow } from '@cdw-selline/ui/helpers';

export interface MessageListProps {
  label: string;
  placeHolder: string;
  messages: any[];
  saveComment: (message: MessageInput) => void;
  deleteComment: (messageId: string) => void;
}

export interface MessageInput {
  id?: string;
  createdBy?: string;
  createdAt?: string;
  note: string;
}

export const MessageList = ({
  label,
  placeHolder,
  messages,
  saveComment,
  deleteComment,
}: MessageListProps) => {
  const defaultMessage: MessageInput = {
    id: '',
    note: '',
  };
  const [message, setMessage] = useState(defaultMessage);

  const {
    isOpen: dialogDeleteOpen,
    handleClose: handleDialogDeleteClose,
    handleOpen: handleDialogDeleteOpen,
  } = useOpenState();

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage({ ...message, note: event.target.value });
  };

  const handleSaveComment = () => {
    saveComment(message);
    setMessage(defaultMessage);
  };

  const handleCommentDelete = () => {
    deleteComment(message.note);
    setMessage(defaultMessage);
    handleDialogDeleteClose();
  };

  const handleDialogDelete = (comment: MessageInput) => {
    setMessage(comment);
    handleDialogDeleteOpen();
  };

  return (
    <Grid container spacing={1} justifyContent="flex-start" paddingTop={1}>
      <TextField
        name=""
        label={label}
        multiline={true}
        placeholder={placeHolder}
        style={{ width: '100%' }}
        value={message.note}
        onChange={handleMessageChange}
      />
      <Grid container mt={1} mb={2} justifyContent="flex-end">
        <Button onClick={handleSaveComment}>Save</Button>
      </Grid>
      <Grid item xs={12} sx={{ maxHeight: '50vh', overflow: 'auto' }}>
        <List>
          {messages.map((message) => (
            <div key={message.id}>
              <ListItem>
                <ListItemText>
                  <Grid container>
                    <Grid item xs={12} sx={{ height: '1em' }}>
                      <Grid container spacing={0}>
                        <Grid item xs={10}>
                          <Typography variant="body2">
                            {message.createdBy}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <DeleteIcon
                            sx={{ cursor: 'pointer', height: '1em' }}
                            onClick={(event) => handleDialogDelete(message)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        {formatDateDistanceToNow(message.createdAt)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">{message.note}</Typography>
                    </Grid>
                  </Grid>
                </ListItemText>
              </ListItem>
              <Divider variant="inset" component="li" sx={{ marginLeft: 0 }} />
            </div>
          ))}
        </List>
      </Grid>
      <DialogConfirm
        title="Delete comment?"
        isOpen={dialogDeleteOpen}
        handleClose={handleDialogDeleteClose}
        handleYes={handleCommentDelete}
      ></DialogConfirm>
    </Grid>
  );
};

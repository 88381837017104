import { gql } from '@apollo/client';

export const GET_SKILL_SUMMARY_QUERY = gql`
  query getSkillSummaryQuery($id: ID!) {
    getSkillSummaryByEstimatorId(projectItemId: $id) {
      skillSummaryRows {
        skill
        totalHours
      }
    }
  }
`;

import { GridPaginationModel } from '@mui/x-data-grid';
import { useState } from 'react';
import { GridFilterModel, GridFilterItem } from '@mui/x-data-grid';

export const datagridPagination = (refetch, filter, sort?: any) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [sortState, setSortState] = useState<any>(sort || {});

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    const pageSize = model?.pageSize ?? paginationModel?.pageSize;
    const page = model?.page ?? paginationModel?.page;
    setPaginationModel({
      page,
      pageSize
    });
    refetch({
      offset: page * pageSize,
      limit: pageSize,
      filters: filter,
      sort: sortState,
    });
  };

  const handleSort = (args) => {
    let newSort = sort ||{};
    if(args.length)
    newSort = {
      [args[0].field]: args[0].sort === "asc" ? 1 : -1,
    };
    setSortState(newSort);
    refetch({
      offset: paginationModel.page * paginationModel.pageSize,
      limit: paginationModel.pageSize,
      filters: filter,
      sort: newSort,
    });
  };

  return {
    handlePaginationModelChange,
    handleSort,
    paginationModel,
  };
};

const isGridFilterItem = (item: any): item is GridFilterItem => {
  return (
    item &&
    typeof item.field === 'string' &&
    typeof item.operator === 'string'
  );
};

const isGridFilterModel = (model: any): model is GridFilterModel => {
  return (
    model && Array.isArray(model.items) && model.items.every(isGridFilterItem)
  );
};

export const getFilterModelFromLocalStorage = (page) => {
  const storedFilter = localStorage.getItem(page);
  if (storedFilter) {
    try {
      const parsedFilter = JSON.parse(storedFilter);
      if (isGridFilterModel(parsedFilter)) {
        return parsedFilter;
      } else {
        console.warn('Stored filter model does not match the expected type');
      }
    } catch (error) {
      console.error('Error parsing stored filter model:', error);
    }
  }
  return null;
}


import { gql } from '@apollo/client';

export const UPDATE_CISCO_ANNUITY_QUOTE_MUTATION = gql`
  mutation updateCiscoAnnuityQuote(
    $ciscoAnnuityId: String!
    $ciscoActiveQuote: String!
    $ciscoAnnuityQuote: CiscoQuoteInput!
  ) {
    updateCiscoAnnuityQuote(
      ciscoAnnuityId: $ciscoAnnuityId
      ciscoActiveQuote: $ciscoActiveQuote
      ciscoAnnuityQuote: $ciscoAnnuityQuote
    ) {
      success
    }
  }
`;

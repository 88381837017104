import { gql } from '@apollo/client';

export const GET_PROJECT_SOW_QUERY = gql`
  query getSowByProjectId($projectId: ID!) {
    getSowByProjectId(projectId: $projectId) {
      id
      projectId
      version
      created
      createdBy
      modified
      modifiedBy
      content
      taskGroup
      outcomeBased
      outcomeIncluded
      executiveSummaryOverview
      executiveSummaryOverviewHelp
      executiveSummaryDrivers
      executiveSummaryDriversHelp
      businessOutcome
      businessOutcomeHelp
      technicalOutcome
      technicalOutcomeHelp
      outcomeProjectScopePhases
      outcomeProjectScopePhasesHelp
      agiloftSowId
      agiloftSentStatus
    }
  }
`;

export default GET_PROJECT_SOW_QUERY;

import { gql } from '@apollo/client';

export const GET_PROJECT_ITEM_QUOTE_BY_ID_QUERY = gql`
  query GetProjectItemQuoteById($getProjectItemQuoteByIdId: ID!) {
    getProjectItemQuoteById(id: $getProjectItemQuoteByIdId) {
      globalQuoteSettings {
        shippingTaxRate
        adderTaxRate
      }
      globalQuoteHardwareSettings {
        markup
        margin
        discount
      }
      globalQuoteSoftwareSettings {
        markup
        margin
        discount
      }
      globalQuoteServiceSettings {
        markup
        margin
        discount
      }
      globalQuoteSupportSettings {
        markup
        margin
        discount
      }
      id
      projectItemId
      projectProductsId
      quoteLines {
        billingModel
        billingTerm
        billingType
        credit
        creditProvidedBy
        customerCost
        customerDiscount
        customerTotalCost
        description
        discount
        edc
        id
        includeHwSwCost
        includeProposal
        listUnitCost
        margin
        markup
        order
        parentId
        partNumber
        productId
        projectId
        projectQuoteSystemId
        quantity
        selectIndex
        totalCost
        totalGp
        totalSalesGp
        type
        unitCost
        unitGp
        unitSalesGp
        userDescription
        vendor
        version
      }
      systems {
        cdwTotal
        customerTotal
        include
        includeHwSwCost
        includeProposal
        order
        sortByType
      }
    }
  }
`;

/*
  VARIABLES:
    {
        "getProjectItemQuoteByIdId": "123456789"
    }
*/

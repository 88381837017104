import { gql } from '@apollo/client';

export const ADD_SCOPE_GROUP_MUTATION = gql`
  mutation Mutation($params: ScopeGroupInput!) {
    addScopeGroup(params: $params) {
      id
      success
    }
  }
`;

import { useQuery, useMutation } from '@apollo/client';
import { GET_ESTIMATOR_INCLUDES_MANAGED_SERVICES_QUERY } from '@cdw-selline/ui/queries';
import { useApolloErrors } from '../';

export const useManagedServices = (projectItemId: string) => {
  const { handleErrorResponse } = useApolloErrors();

  const { data, loading, error } = useQuery(GET_ESTIMATOR_INCLUDES_MANAGED_SERVICES_QUERY, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    variables: {
      projectItemId: projectItemId,
    },
    notifyOnNetworkStatusChange: true,
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Managed Services flag!'),
  });

  return {
    includesManagedServices: data?.getEstimatorIncludesManagedServices ?? false,
    includeManagedServicesLoading: loading,
    includeManagedServicesError: error,
  };
};

import { ScopeGroup } from '@cdw-selline/common/types';
import React, { useEffect, useState } from 'react';
import DialogModal from '../../dialog-modal/DialogModal';
import { Grid, Typography, Box } from '@mui/material';
import {
  FormText,
  getActionButtons,
  FormAutocomplete,
} from '../../formHelperFunctions';
import { ApolloError } from '@apollo/client';
import { usePractices } from '@cdw-selline/ui/hooks';

export interface ScopeGroupFormProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (scope: ScopeGroup) => void;
  scopeGroup?: ScopeGroup | null;
  scopeGroupLoading?: boolean;
  scopeGroupError?: ApolloError;
}

const newScopeGroupDefaults: ScopeGroup = {
  id: '',
  name: '',
  practiceId: '',
};

export function ScopeGroupForm({
  isOpen,
  handleClose,
  handleSubmit,
  scopeGroup,
  scopeGroupLoading,
  scopeGroupError,
}: ScopeGroupFormProps) {
  const [scopeGroupFormData, setScopeGroupFormData] = useState(
    scopeGroup ?? newScopeGroupDefaults
  );
  const [error, setError] = useState(false);

  useEffect(() => {
    if (scopeGroup && scopeGroup.id) {
      setScopeGroupFormData(scopeGroup);
    }
  }, [scopeGroup]);

  const {
    data: practices,
    loading: practiceLoading,
    error: practiceError,
  } = usePractices({});
  const practiceOptions =
    practices.practices.map((practice) => {
      return {
        id: practice.id,
        label: practice.name,
      };
    }) ?? [];

  const handleFormClose = () => {
    setScopeGroupFormData(newScopeGroupDefaults);
    setError(false);
    handleClose();
  };

  const handleSaveClick = () => {
    if (scopeGroupFormData.name) {
      handleSubmit(scopeGroupFormData);
      setError(false);
      handleClose();
    } else {
      setError(true);
    }
  };

  if (scopeGroupLoading) return <Typography>Loading form</Typography>;

  if (scopeGroupError) return <Typography>Error loading form</Typography>;

  const getTitle = () => {
    return (scopeGroupFormData.id && `Edit Scope Group`) || 'Add Scope Group';
  };

  return (
    <DialogModal
      fullWidth={true}
      isOpen={isOpen}
      handleClose={handleClose}
      title={getTitle()}
      action={getActionButtons(handleFormClose, handleSaveClick)}
      maxWidthProp={'sm'}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="row"
        spacing={1}
        mt={1}
        sx={{ width: '60vh' }}
        data-testid="scope-grid"
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="column"
          spacing={1}
          sx={{ width: '100%' }}
        >
          {error && (
            <Box sx={{ padding: '15px' }}>
              <Typography color="error" variant="h6">
                Please add all required field values
              </Typography>
            </Box>
          )}
          <FormText
            name="name"
            label="Scope Group Name"
            required={true}
            setFormData={setScopeGroupFormData}
            formData={scopeGroupFormData}
          />
          <FormAutocomplete
            name="practiceId"
            label="Practice *"
            options={practiceOptions}
            setFormData={setScopeGroupFormData}
            formData={scopeGroupFormData}
          />
        </Grid>
      </Grid>
    </DialogModal>
  );
}

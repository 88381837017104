import { useMutation } from '@apollo/client';
import {
  REMOVE_ESTIMATOR_SITE_BY_ID_MUTATION,
  GET_ESTIMATOR_SITES_BY_PROJECT_ITEM_ID_QUERY,
  GET_ESTIMATOR_TOTALS_BY_ITEM_ID,
  GET_PROJECT_QUERY,
  GET_ESTIMATOR_INCLUDES_MANAGED_SERVICES_QUERY,
  GET_ESTIMATOR_SUMMARY_TASKS_QUERY,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { useApolloErrors } from '..';

export const useRemoveTasksBySite = () => {
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();

  const [removeTasksBySite, { loading, error, client }] = useMutation(
    REMOVE_ESTIMATOR_SITE_BY_ID_MUTATION,
    {
      onError: (error) =>
        handleErrorResponse(error, 'Failed to remove Project Site!'),
      onCompleted: () => {
        client.refetchQueries({
          include: [
            GET_ESTIMATOR_SITES_BY_PROJECT_ITEM_ID_QUERY,
            GET_ESTIMATOR_TOTALS_BY_ITEM_ID,
            GET_ESTIMATOR_INCLUDES_MANAGED_SERVICES_QUERY,
            GET_PROJECT_QUERY,
            GET_ESTIMATOR_SUMMARY_TASKS_QUERY,
          ],
        });

        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully Removed Project Site',
        });
      },
    }
  );

  const handleRemoveSite = (projectItemId: string, siteId: string) => {
    removeTasksBySite({
      variables: {
        projectItemId: projectItemId,
        siteId: siteId,
      },
    });
  };

  return {
    handleRemoveSite,
    loading,
    error,
  };
};

import { GridColDef, GridFilterModel } from '@mui/x-data-grid';

import { useCustomers } from './useCustomers';
import type { Customer } from '@cdw-selline/common/types';
import { getFilters } from '@cdw-selline/ui/helpers';
import {
  datagridPagination,
  getFilterModelFromLocalStorage,
} from '@cdw-selline/ui/helpers';
import { PAGES } from '@cdw-selline/ui/constants';

export type CustomersColumn = GridColDef & {
  field: keyof Customer;
  hide?: boolean;
};

const columns: CustomersColumn[] = [
  {
    field: 'customerCode',
    headerName: 'SPS Number',
    flex: 1,
  },
  {
    field: 'customerDescription',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'region',
    headerName: 'Region',
    flex: 1,
    editable: true,
  },
  {
    field: 'sector',
    headerName: 'Sector',
    flex: 1,
  },
  {
    field: 'area',
    headerName: 'Area',
    flex: 1,
  },
];

export const useDashboardCustomers = (userFilter) => {
  const { data, loading, error, refetch } = useCustomers(
    {
      offset: 0,
      limit: 100,
      filters: getFilters(PAGES.CUSTOMERS_COLLECTION, userFilter),
    },
    true
  );

  const filterModel: GridFilterModel = getFilterModelFromLocalStorage(`${PAGES.CUSTOMERS_COLLECTION}-filter`);

  const { handlePaginationModelChange, handleSort, paginationModel } =
    datagridPagination(refetch, {});

  const onFilterModelChange = (filterModel) => {
    refetch({ filters: getFilters(PAGES.CUSTOMERS_COLLECTION, userFilter) });
  };

  const returnValue = {
    columns,
    rows: data?.customers ?? [],
    handlePaginationModelChange,
    handleSort,
    customers: data ? data?.customers : {},
    loading: loading,
    error,
    rowCount: data ? data?.count : 0,
    onFilterModelChange,
    filterModel,
  };

  if (loading) {
    return {
      ...returnValue,
      paginationModel: { pageSize: 100, page: 0 },
      rowCount: 0,
      rows: [],
    };
  } else {
    return { ...returnValue, paginationModel };
  }
};

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  GET_STANDRD_RMS_PROPOSAL_TEMPLATE_QUERY,
  GET_TIER_RMS_PROPOSAL_TEMPLATE_QUERY,
  GET_RMS_ONE_TIME_COST_TEMPLATE_QUERY,
} from '@cdw-selline/ui/queries';

import { useApolloErrors } from '../';

export const useStandrdRmsProposalTemplate = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { projectItemId } = useParams<{ projectItemId: string }>();

  const {
    loading: rmsProposalTemplateLoading,
    error: rmsProposalTemplateError,
    data: rmsProposalTemplate,
  } = useQuery(GET_STANDRD_RMS_PROPOSAL_TEMPLATE_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: { projectItemId },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch RMS Proposal Template.'),
  });

  return {
    rmsProposalTemplateLoading,
    rmsProposalTemplateError,
    rmsProposalTemplate:
      rmsProposalTemplate?.getStandardRmsProposalTemplateByItemId ?? [],
  };
};

export const useTierRmsProposalTemplate = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { projectItemId } = useParams<{ projectItemId: string }>();

  const {
    loading: rmsProposalTemplateTierLoading,
    error: rmsProposalTemplateTierError,
    data: rmsProposalTemplateTier,
  } = useQuery(GET_TIER_RMS_PROPOSAL_TEMPLATE_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: { projectItemId },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch RMS Proposal Template.'),
  });

  return {
    rmsProposalTemplateTierLoading,
    rmsProposalTemplateTierError,
    rmsProposalTemplateTier:
      rmsProposalTemplateTier?.getTierRmsProposalTemplateByItemId ?? [],
  };
};

export const useRmsOneTimeCostTemplate = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { projectItemId } = useParams<{ projectItemId: string }>();

  const {
    loading: rmsOneTimeCostTemplateLoading,
    error: rmsOneTimeCostTemplateError,
    data: rmsOneTimeCostTemplate,
  } = useQuery(GET_RMS_ONE_TIME_COST_TEMPLATE_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: { projectItemId },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch RMS Proposal Template.'),
  });

  return {
    rmsOneTimeCostTemplateLoading,
    rmsOneTimeCostTemplateError,
    rmsOneTimeCostTemplate:
      rmsOneTimeCostTemplate?.getRmsOneTimeCostTemplateByItemId ?? [],
  };
};

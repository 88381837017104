import { gql } from '@apollo/client';

export const GET_PROJECT_ITEM_ESTIMATOR_TASKS_TOTALS_QUERY = gql`
  query getProjectItemEstimatorTaskTotals(
    $getProjectItemEstimatorTasksProjectId: ID!
  ) {
    getProjectItemEstimatorTasks(
      projectItemId: $getProjectItemEstimatorTasksProjectId
    ) {
      pmHours
      projectItemId
      serviceEstimate
      taskHours
      managedServices
      cdwCost
      grossProfit
    }
  }
`;

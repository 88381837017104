import { useMutation } from '@apollo/client';
import { CLEAR_AGILOFT_FEE_TABLE_MUTATION } from '@cdw-selline/ui/queries';
import { AgiloftApiResponse, DefaultMutationResponse } from '@cdw-selline/common/types';
import { useEffect, useState } from 'react';
import { useApolloErrors } from '../../useApolloErrors';

export interface useClearAgiloftFeeTable {
  progress: number;
  setProgress: (progress: number) => void;
  startProgress: number;
  endProgress: number;
  setProgressMessage: (message: string) => void;
  agiloftSOWId: string;
  projectId: string;
  projectItemId: string;
}

export const useClearAgiloftFeeTable = ({
  progress,
  setProgress,
  startProgress,
  endProgress,
  setProgressMessage,
  agiloftSOWId,
  projectId,
  projectItemId,
}: useClearAgiloftFeeTable) => {
  const { handleErrorResponse } = useApolloErrors();

  const [clearAgiloftFeeTableRunning, setClearAgiloftFeeTableRunning] =
    useState(false);

  const [clearAgiloftFeeTable, { error: clearAgiloftFeeTableError }] =
    useMutation<{
      clearAgiloftFeeTable: AgiloftApiResponse;
    }>(CLEAR_AGILOFT_FEE_TABLE_MUTATION, {
      onError: (error) => {
        setProgress(100);
        setClearAgiloftFeeTableRunning(false);
        handleErrorResponse(error, 'Failed to clear agiloft fee table');
      },
      onCompleted: (data) => {
        if(!data.clearAgiloftFeeTable.success) {
          setProgress(100);
          setClearAgiloftFeeTableRunning(false);
          setProgressMessage('Failed to clear agiloft fee table');

          return;
        }

        setProgress(endProgress);
        setClearAgiloftFeeTableRunning(false);
      },
    });

  useEffect(() => {
    if (progress === startProgress) {
      setProgressMessage('Adding Agiloft Sow Content ..');
      setClearAgiloftFeeTableRunning(true);
      clearAgiloftFeeTable({
        variables: {
          params: {
            id: agiloftSOWId,
            projectId: projectId,
            projectItemId: projectItemId,
          },
        },
      });
    }
  }, [
    progress,
    startProgress,
    setProgressMessage,
    agiloftSOWId,
    projectId,
    projectItemId,
    clearAgiloftFeeTable,
  ]);

  useEffect(() => {
    if (progress < 100 && clearAgiloftFeeTableRunning) {
      const timer = setTimeout(() => {
        if (clearAgiloftFeeTableRunning && progress < endProgress - 1) {
          setProgress(progress + 1);
        }
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [clearAgiloftFeeTableRunning, endProgress, progress, setProgress]);

  return {
    clearAgiloftFeeTableRunning,
    clearAgiloftFeeTableError,
  };
};

import { cloneDeep, debounce } from 'lodash';
import { EmailTemplate } from '@cdw-selline/common/types';
import React, { useEffect, useState } from 'react';
import DialogModal from '../../dialog-modal/DialogModal';
import { Grid, Typography, Box } from '@mui/material';
import {
  FormText,
  getActionButtons,
  FormRichTextEditor,
} from '../../formHelperFunctions';

export interface EmailTemplateSettingsFormProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (emailTemplateSettings: EmailTemplate) => void;
  emailTemplateSettings?: EmailTemplate | null;
  formAction?:string;
}

export function EmailTemplatesForm({
  isOpen,
  handleClose,
  handleSubmit,
  emailTemplateSettings,
}: EmailTemplateSettingsFormProps) {
  const defaultEmailTemplate:EmailTemplate = {
    name : "",
    id : "",
    subject: "",
    body : ""
  }

  const [formData, setFormData] = useState(emailTemplateSettings || defaultEmailTemplate);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [customEditorOptions, setCustomEditorOptions] = useState(null);

  useEffect(() => {
    if (emailTemplateSettings?.id) {
      setFormData(emailTemplateSettings);
    }
  }, [emailTemplateSettings]);

  const handleFormClose = () => {
    setFormData(defaultEmailTemplate);
    setError(false);
    handleClose();
  };

  const handleEditorChange = (params) => {
    if(params?.body){
      setFormData({...formData, ...{body: params.body}});
    }
  };

  const debouncedHandleEditorChange = debounce(handleEditorChange, 1000);

  const handleSaveClick = () => {
    const updatedProperties = cloneDeep(formData);
    handleSubmit(updatedProperties);
    handleFormClose();
  };

  return (
    <DialogModal
      fullWidth={true}
      isOpen={isOpen}
      handleClose={handleClose}
      title={'Upsert Email Template'}
      action={getActionButtons(handleFormClose, handleSaveClick)}
      maxWidthProp={'lg'}
      disableEnforceFocus={true}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="row"
        spacing={1}
        mt={1}
        sx={{ height: '50vh' }}
        data-testid="config-grid"
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="column"
          spacing={1}
          sx={{ width: '100%' }}
        >
          {error && (
            <Box sx={{ padding: '15px' }}>
              <Typography color="error" variant="h6">
                {errorMessage}
              </Typography>
            </Box>
          )}
          <FormText
            name="name"
            label="Name"
            setFormData={setFormData}
            formData={formData}
          />
          <FormText
            name="id"
            label="Id"
            setFormData={setFormData}
            formData={formData}
            disabled={true}
          />
          <FormText
            name="subject"
            label="Subject"
            setFormData={setFormData}
            formData={formData}
          />
          <FormRichTextEditor
            name="body"
            label="Content"
            value={formData.body}
            handleEditorChange={debouncedHandleEditorChange}
            customEditorOptions={customEditorOptions}
          />
        </Grid>
      </Grid>
    </DialogModal>
  );
}

import { Editor } from 'tinymce';
import { DataCollectionItem } from '@cdw-selline/common/types';
import { defaultEditorOptions } from '../tinymce';

const menuStrings = {
  SELECT_SECTION: 'Select Section',
  DYNAMIC_CONTENT: 'Dynamic Content',
  SERVICES_SUMMARY: 'Estimated Professional Services Summary',
  SOLUTION_INCLUDES: 'Proposed Solution Includes',
  INVESTMENT_SUMMARY: 'Investment Summary',
  CURRENT_DATE: 'Current Date',
  CUSTOMER_NAME: 'Customer Name',
  PROJECT_NAME: 'Project Name',
  USER_NAME: 'User Name',
  CDW_HEADER_IMG: 'CDW Header Image',
  PROJECT_ITEM_NAME: 'Project Item Name',
  QUOTE_EXPIRATION_DATE: `Cisco Annuity Quote Expiration Date`,
  BILLING_FREQUENCY: `Cisco Annuity Billing Frequency`,
  SUBSCRIPTION_TERM: `Cisco Annuity Subscription Term`,
  AUTO_RENEWAL: `Cisco Annuity Auto-Renewal`,
  CISCO_ANNUITY_LINES: `Cisco Annuity Lines`,
  CISCO_ANNUITY_TERMS: `Cisco Annuity Terms`,
  CISCO_ANNUITY_TERM_TOTAL: `Cisco Annuity Term Total`,
  CISCO_ANNUITY_RATE_TABLES: `Cisco Annuity Rate Tables`,
  TASK_GROUP_PROPOSAL: `Task Group Proposal Content`,
  CISCO_ANNUITY_CHANGE_LINES: `Cisco Annuity Change Lines`,
  GLOBAL_SECTION: 'Select Global Section',
  BUSINESS_OUTCOME: `Business Outcome`,
  TECHNICAL_OUTCOME: `Technical Outcome`,
  OUTCOME_PROJECT_SCOPE_PHASES: `Outcome Project Scope Phases`,
  EXECUTIVE_SUMMARY_OVERVIEW: `Executive Summary Overview`,
  EXECTUIVE_SUMMARY_DRIVERS: `Exectuive Summary Drivers`,
  CUSTOMER: `Customer`,
  ITEMS_PROVIDED_TO_CUSOMTERS: `Items Provided to Customers`,
  HIGH_LEVEL_SCOPE: 'High Level Scope',
  CUSTOMER_RESPONSIBILITIES: `Customer Responsibilities`,
  OUT_OF_SCOPE: `Out of Scope`,
  ASSUMPTION: `Assumption`,
  RMS_RECURRING_TABLE: `RMS Recurring Table`,
  RMS_ONETIME_TABLE: `RMS One Time Table`,
  AGILOFT_RECURRING_SERVICES_FEES: `Agiloft Recurring Services Fees`,
  AGILOFT_CLAUSES: `Agiloft Clauses`,
  AGILOFT_EXHIBITS: `Agiloft Exhibits`,
  TOTAL_TRAVEL_HOURS: `Total Travel Hours`,
  TRAVEL_RATE: `Travel Rate`,
  TOTAL_EXPENSE_PRICE: `Total Expense Price`,
  SITE_TABLE: `Site Table`,
};

const shortCodes = {
  SERVICES_SUMMARY: `<replace class="mceNonEditable" data-type="Estimated Professional Services Summary">{{ section id='1' name='Estimated Professional Services Summary' }}</replace>`,
  SOLUTION_INCLUDES: `<replace class="mceNonEditable" data-type="Proposed Solution Includes">{{ section id='2' name='Proposed Solution Includes' }}</replace>`,
  INVESTMENT_SUMMARY: `<replace class="mceNonEditable" data-type="Investment Summary">{{ section id='3' name='Investment Summary' }}</replace>`,
  CURRENT_DATE: `<replace class="mceNonEditable" data-type="Current Date">{{ section id='4' name='Current Date' }}</replace>`,
  CUSTOMER_NAME: `<replace class="mceNonEditable" data-type="Customer Name">{{ section id='5' name='Customer Name' }}</replace>`,
  PROJECT_NAME: `<replace class="mceNonEditable" data-type="Project Name">{{ section id='6' name='Project Name' }}</replace>`,
  USER_NAME: `<replace class="mceNonEditable" data-type="User Name">{{ section name='User Name' }}</replace>`,
  CDW_HEADER_IMG: `<replace class="mceNonEditable" data-type="CDW Header Image">{{ section name='CDW Header Image' }}</replace>`,
  QUOTE_EXPIRATION_DATE: `<replace class="mceNonEditable" data-type="Expiration Date">{{ section id='8' name='Expiration Date' }}</replace>`,
  BILLING_FREQUENCY: `<replace class="mceNonEditable" data-type="Billing Frequency">{{ section id='9' name='Billing Frequency' }}</replace>`,
  SUBSCRIPTION_TERM: `<replace class="mceNonEditable" data-type="Subscription Term">{{ section id='10' name='Subscription Term' }}</replace>`,
  AUTO_RENEWAL: `<replace class="mceNonEditable" data-type="Auto-Renewal">{{ section id='11' name='Auto-Renewal' }}</replace>`,
  CISCO_ANNUITY_LINES: `<replace class="mceNonEditable" data-type="Cisco Annuity Lines">{{ section id='12' name='Cisco Annuity Lines' }}</replace>`,
  CISCO_ANNUITY_TERMS: `<replace class="mceNonEditable" data-type="Cisco Annuity Terms">{{ section name='Cisco Annuity Terms' }}</replace>`,
  CISCO_ANNUITY_TERM_TOTAL: `<replace class="mceNonEditable" data-type="Cisco Annuity Term Total">{{ section name='Cisco Annuity Term Total' }}</replace>`,
  CISCO_ANNUITY_RATE_TABLES: `<replace class="mceNonEditable" data-type="Cisco Annuity Rate Tables">{{ section name='Cisco Annuity Rate Tables' }}</replace>`,
  TASK_GROUP_PROPOSAL: `<p><replace class="mceNonEditable" data-type="Task Group Proposal Content">{{ section name='Task Group Proposal Content' }}</replace></p>`,
  CISCO_ANNUITY_CHANGE_LINES: `<replace class="mceNonEditable" data-type="Cisco Annuity Change Lines">{{ section name='Cisco Annuity Change Lines' }}</replace>`,
  BUSINESS_OUTCOME: `<replace class="mceNonEditable" data-type="business_outcome">{{ outcome-global type="business_outcome" }}</replace>`,
  TECHNICAL_OUTCOME: `<replace class="mceNonEditable" data-type="technical_outcome">{{ outcome-global type="technical_outcome" }}</replace>`,
  OUTCOME_PROJECT_SCOPE_PHASES: `<replace class="mceNonEditable" data-type="outcome_project_scope_phases">{{ outcome-global type="outcome_project_scope_phases" }}</replace>`,
  EXECUTIVE_SUMMARY_OVERVIEW: `<replace class="mceNonEditable" data-type="executive_summary_overview">{{ outcome-global type="executive_summary_overview" }}</replace>`,
  EXECTUIVE_SUMMARY_DRIVERS: `<replace class="mceNonEditable" data-type="executive_summary_drivers">{{ outcome-global type="executive_summary_drivers" }}</replace>`,
  CUSTOMER: `<replace class="mceNonEditable" data-type="customer">{{ global type="customer" }}</replace>`,
  ITEMS_PROVIDED_TO_CUSOMTERS: `<replace class="mceNonEditable" data-type="items_provided_to_customer">{{ global type="items_provided_to_customer" }}</replace>`,
  HIGH_LEVEL_SCOPE: `<replace class="mceNonEditable" data-type="high_level_scope"">{{ global type="high_level_scope"}}</replace>`,
  CUSTOMER_RESPONSIBILITIES: `<replace class="mceNonEditable" data-type="customer_responsibility">{{ global type="customer_responsibility"}}</replace>`,
  OUT_OF_SCOPE: `<replace class="mceNonEditable" data-type="outofscope">{{ global type="outofscope"}}</replace>`,
  ASSUMPTION: `<replace class="mceNonEditable" data-type="assumption">{{ global type="assumption"}}</replace>`,
  RMS_RECURRING_TABLE: `<replace class="mceNonEditable" type="rms_recurring_table">{{ global type="rms_recurring_table" }}</replace>`,
  RMS_ONETIME_TABLE: `<replace class="mceNonEditable" type="rms_onetime_table">{{ global type="rms_onetime_table" }}</replace>`,
  PROJECT_ITEM_NAME: `<replace class="mceNonEditable" data-type="Project Item Name">{{ section id='18' name='Project Item Name' }}</replace>`,
  AGILOFT_RECURRING_SERVICES_FEES: `<replace class="mceNonEditable" data-type="Agiloft Recurring Services Fees">{{ section name='Agiloft Recurring Services Fees' }}</replace>`,
  TOTAL_TRAVEL_HOURS: `<replace class="mceNonEditable" data-type="Total Travel Hours">{{ section name='total_travel_hours' }}</replace>`,
  TRAVEL_RATE: `<replace class="mceNonEditable" data-type="Travel Rate">{{ section name='travel_rate' }}</replace>`,
  TOTAL_EXPENSE_PRICE: `<replace class="mceNonEditable" data-type="Total Expense Price">{{ section name='total_expense_price' }}</replace>`,
  AGILOFT_CLAUSES: `<replace class="mceNonEditable" data-type="Agiloft Clauses">{{ section name='Agiloft Clauses' }}</replace>`,
  AGILOFT_EXHIBITS: `<replace class="mceNonEditable" data-type="Agiloft Exhibits">{{ section name='Agiloft Exhibits' }}</replace>`,
};

export const getCustomEditorOptions = (
  handlePreviewOpen?: () => void,
  handleContentChange?: (value, reload) => void,
  dataCollection?: DataCollectionItem[],
  siteModal?: (editor: Editor) => void
) => {
  const handleOnInsert = (editor: Editor, shortCode: string) => {
    editor.insertContent(shortCode);
  };

  const getMenuItems = (editor) => {
    const menuItems = [
      {
        type: 'nestedmenuitem',
        text: menuStrings.DYNAMIC_CONTENT,
        getSubmenuItems: () => {
          return [
            {
              type: 'menuitem',
              text: menuStrings.SITE_TABLE,
              onAction: () => {
                siteModal(editor);
              },
            },
            {
              type: 'menuitem',
              text: menuStrings.SERVICES_SUMMARY,
              onAction: () =>
                handleOnInsert(editor, shortCodes.SERVICES_SUMMARY),
            },
            {
              type: 'menuitem',
              text: menuStrings.SOLUTION_INCLUDES,
              onAction: () =>
                handleOnInsert(editor, shortCodes.SOLUTION_INCLUDES),
            },
            {
              type: 'menuitem',
              text: menuStrings.INVESTMENT_SUMMARY,
              onAction: () =>
                handleOnInsert(editor, shortCodes.INVESTMENT_SUMMARY),
            },
            {
              type: 'menuitem',
              text: menuStrings.QUOTE_EXPIRATION_DATE,
              onAction: () =>
                handleOnInsert(editor, shortCodes.QUOTE_EXPIRATION_DATE),
            },
            {
              type: 'menuitem',
              text: menuStrings.BILLING_FREQUENCY,
              onAction: () =>
                handleOnInsert(editor, shortCodes.BILLING_FREQUENCY),
            },
            {
              type: 'menuitem',
              text: menuStrings.SUBSCRIPTION_TERM,
              onAction: () =>
                handleOnInsert(editor, shortCodes.SUBSCRIPTION_TERM),
            },
            {
              type: 'menuitem',
              text: menuStrings.AUTO_RENEWAL,
              onAction: () => handleOnInsert(editor, shortCodes.AUTO_RENEWAL),
            },
            {
              type: 'menuitem',
              text: menuStrings.CISCO_ANNUITY_LINES,
              onAction: () =>
                handleOnInsert(editor, shortCodes.CISCO_ANNUITY_LINES),
            },
            {
              type: 'menuitem',
              text: menuStrings.CISCO_ANNUITY_TERMS,
              onAction: () =>
                handleOnInsert(editor, shortCodes.CISCO_ANNUITY_TERMS),
            },
            {
              type: 'menuitem',
              text: menuStrings.CISCO_ANNUITY_TERM_TOTAL,
              onAction: () =>
                handleOnInsert(editor, shortCodes.CISCO_ANNUITY_TERM_TOTAL),
            },
            {
              type: 'menuitem',
              text: menuStrings.CISCO_ANNUITY_RATE_TABLES,
              onAction: () =>
                handleOnInsert(editor, shortCodes.CISCO_ANNUITY_RATE_TABLES),
            },
            {
              type: 'menuitem',
              text: menuStrings.TASK_GROUP_PROPOSAL,
              onAction: () =>
                handleOnInsert(editor, shortCodes.TASK_GROUP_PROPOSAL),
            },         
            {
              type: 'menuitem',
              text: menuStrings.CISCO_ANNUITY_CHANGE_LINES,
              onAction: () =>
                handleOnInsert(editor, shortCodes.CISCO_ANNUITY_CHANGE_LINES),
            },
          ];
        },
      },
      {
        type: 'nestedmenuitem',
        text: 'Common',
        getSubmenuItems: () => {
          return [
            {
              type: 'menuitem',
              text: menuStrings.CURRENT_DATE,
              onAction: () => handleOnInsert(editor, shortCodes.CURRENT_DATE),
            },
            {
              type: 'menuitem',
              text: menuStrings.CUSTOMER_NAME,
              onAction: () => handleOnInsert(editor, shortCodes.CUSTOMER_NAME),
            },
            {
              type: 'menuitem',
              text: menuStrings.PROJECT_NAME,
              onAction: () => handleOnInsert(editor, shortCodes.PROJECT_NAME),
            },
            {
              type: 'menuitem',
              text: menuStrings.PROJECT_ITEM_NAME,
              onAction: () => handleOnInsert(editor, shortCodes.PROJECT_ITEM_NAME),
            },
            {
              type: 'menuitem',
              text: menuStrings.USER_NAME,
              onAction: () => handleOnInsert(editor, shortCodes.USER_NAME),
            },
            {
              type: 'menuitem',
              text: menuStrings.CDW_HEADER_IMG,
              onAction: () => handleOnInsert(editor, shortCodes.CDW_HEADER_IMG),
            },
          ];
        },
      },
    ];

    if (dataCollection?.length) {
      const dynamicMenuItems = {
        type: 'nestedmenuitem',
        text: 'Data Collection',
        getSubmenuItems: () => {
          return dataCollection.map((item) => ({
            type: 'menuitem',
            text: item.name,
            onAction: () => 
              handleOnInsert(editor, `<replace class="mceNonEditable" data-type="dynamic-data" data-id="${item.key}">{ section id=${item.key} }</replace>`),
          }));
        },
      };

      menuItems.push(dynamicMenuItems);
    }
    return menuItems;
  };
  return {
    ...defaultEditorOptions,
    setup: (editor: Editor) => {
      if (handlePreviewOpen) {
        editor.ui.registry.addButton('preview', {
          text: 'Preview',
          onAction: handlePreviewOpen,
        });
      }
      editor.ui.registry.addButton('sitetable', {
        text: 'Site Table',
        onAction: () => {
          siteModal(editor);
        },
      });
      editor.ui.registry.addMenuButton('add-sections', {
        text: menuStrings.SELECT_SECTION,
        fetch: (callback) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          callback(getMenuItems(editor) as any);
        },
      });
      editor.ui.registry.addMenuButton('add-global-sections', {
        text: menuStrings.GLOBAL_SECTION,
        fetch: (callback) => {
          const menuItems = [
            {
              type: 'menuitem',
              text: menuStrings.BUSINESS_OUTCOME,
              onAction: () => handleOnInsert(editor, shortCodes.BUSINESS_OUTCOME),
            },
            {
              type: 'menuitem',
              text: menuStrings.TECHNICAL_OUTCOME,
              onAction: () => handleOnInsert(editor, shortCodes.TECHNICAL_OUTCOME),
            },
            {
              type: 'menuitem',
              text: menuStrings.EXECUTIVE_SUMMARY_OVERVIEW,
              onAction: () => handleOnInsert(editor, shortCodes.EXECUTIVE_SUMMARY_OVERVIEW),
            },
            {
              type: 'menuitem',
              text: menuStrings.EXECTUIVE_SUMMARY_DRIVERS,
              onAction: () => handleOnInsert(editor, shortCodes.EXECTUIVE_SUMMARY_DRIVERS),
            },
            {
              type: 'menuitem',
              text: menuStrings.OUTCOME_PROJECT_SCOPE_PHASES,
              onAction: () => handleOnInsert(editor, shortCodes.OUTCOME_PROJECT_SCOPE_PHASES),
            },
            {
              type: 'menuitem',
              text: menuStrings.CUSTOMER,
              onAction: () => handleOnInsert(editor, shortCodes.CUSTOMER),
            },
            {
              type: 'menuitem',
              text: menuStrings.ITEMS_PROVIDED_TO_CUSOMTERS,
              onAction: () => handleOnInsert(editor, shortCodes.ITEMS_PROVIDED_TO_CUSOMTERS),
            },
            {
              type: 'menuitem',
              text: menuStrings.HIGH_LEVEL_SCOPE,
              onAction: () => handleOnInsert(editor, shortCodes.HIGH_LEVEL_SCOPE),
            },
            {
              type: 'menuitem',
              text: menuStrings.CUSTOMER_RESPONSIBILITIES,
              onAction: () => handleOnInsert(editor, shortCodes.CUSTOMER_RESPONSIBILITIES),
            },
            {
              type: 'menuitem',
              text: menuStrings.ASSUMPTION,
              onAction: () => handleOnInsert(editor, shortCodes.ASSUMPTION),
            },
            {
              type: 'menuitem',
              text: menuStrings.OUT_OF_SCOPE,
              onAction: () => handleOnInsert(editor, shortCodes.OUT_OF_SCOPE),
            },
            {
              type: 'menuitem',
              text: menuStrings.RMS_RECURRING_TABLE,
              onAction: () => handleOnInsert(editor, shortCodes.RMS_RECURRING_TABLE),
            },
            {
              type: 'menuitem',
              text: menuStrings.RMS_ONETIME_TABLE,
              onAction: () => handleOnInsert(editor, `${shortCodes.RMS_ONETIME_TABLE}`),
            },            
            {
              type: 'menuitem',
              text: menuStrings.AGILOFT_RECURRING_SERVICES_FEES,
              onAction: () => handleOnInsert(editor, shortCodes.AGILOFT_RECURRING_SERVICES_FEES),
            },
            {
              type: 'menuitem',
              text: menuStrings.AGILOFT_CLAUSES,
              onAction: () => handleOnInsert(editor, shortCodes.AGILOFT_CLAUSES),
            },
            {
              type: 'menuitem',
              text: menuStrings.AGILOFT_EXHIBITS,
              onAction: () => handleOnInsert(editor, shortCodes.AGILOFT_EXHIBITS),
            },      
            {
              type: 'menuitem',
              text: menuStrings.TOTAL_TRAVEL_HOURS,
              onAction: () =>
                editor.insertContent(shortCodes.TOTAL_TRAVEL_HOURS),
            },
            {
              type: 'menuitem',
              text: menuStrings.TRAVEL_RATE,
              onAction: () =>
                editor.insertContent(shortCodes.TRAVEL_RATE),
            },
            {
              type: 'menuitem',
              text: menuStrings.TOTAL_EXPENSE_PRICE,
              onAction: () =>
                editor.insertContent(shortCodes.TOTAL_EXPENSE_PRICE),
            },
          ];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          callback(menuItems as any);
        },
      });
    },
  };
};

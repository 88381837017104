import { Tier } from '@cdw-selline/common/types';
import React, { useEffect, useState } from 'react';
import DialogModal from '../dialog-modal/DialogModal';
import { Grid, Typography, Box } from '@mui/material';
import { FormText, getActionButtons } from '../formHelperFunctions';
import { ApolloError } from '@apollo/client';

export interface TierFormProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (tier: Tier) => void;
  tier?: Tier | null;
  tierLoading?: boolean;
  tierError?: ApolloError;
}

const newTierDefaults: Tier = {
  id: '',
  name: '',
};

export function TierForm({
  isOpen,
  handleClose,
  handleSubmit,
  tier,
  tierLoading,
  tierError,
}: TierFormProps) {
  const [tierFormData, setTierFormData] = useState(tier ?? newTierDefaults);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (tier && tier.id) {
      setTierFormData(tier);
    }
  }, [tier]);

  const handleFormClose = () => {
    setTierFormData(newTierDefaults);
    setError(false);
    handleClose();
  };

  const handleSaveClick = () => {
    if (tierFormData.name) {
      handleSubmit(tierFormData);
      setError(false);
      handleClose();
    } else {
      setError(true);
    }
  };

  if (tierLoading) return <Typography>Loading form</Typography>;

  if (tierError) return <Typography>Error loading form</Typography>;

  const getTitle = () => {
    return (tierFormData.id && `Edit Tier`) || 'Add Tier';
  };

  return (
    <DialogModal
      fullWidth={true}
      isOpen={isOpen}
      handleClose={handleClose}
      title={getTitle()}
      action={getActionButtons(handleFormClose, handleSaveClick)}
      maxWidthProp={'sm'}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="row"
        spacing={1}
        mt={1}
        sx={{ width: '60vh' }}
        data-testid="tier-grid"
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="column"
          spacing={1}
          sx={{ width: '100%' }}
        >
          {error && (
            <Box sx={{ padding: '15px' }}>
              <Typography color="error" variant="h6">
                Please add all required field values
              </Typography>
            </Box>
          )}
          <FormText
            name="name"
            label="Tier Name"
            required={true}
            setFormData={setTierFormData}
            formData={tierFormData}
          />
        </Grid>
      </Grid>
    </DialogModal>
  );
}

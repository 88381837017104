import React, { forwardRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  StandardRMSProposalTemplate,
  TierRMSProposalTemplate,
  CoverPage,
  RMSOneTimeCostTemplate,
} from '@cdw-selline/ui/components';
import {
  useStandrdRmsProposalTemplate,
  useTierRmsProposalTemplate,
  useRmsProposal,
  useProjects,
  useRmsOneTimeCostTemplate,
} from '@cdw-selline/ui/hooks';
import { CircularProgress, Box, Typography } from '@mui/material';
import {
  MANAGED_SERVICES_READINESS_ASSESSMENT,
  MANAGED_SERVICES_ONBOARDING,
} from '@cdw-selline/ui/constants';
import { makeStyles } from '@mui/styles';
import { formatCurrency } from '@cdw-selline/ui/helpers';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const useStyles = makeStyles({
  root: {
    fontFamily: 'Times New Roman',
  },
  header: {
    padding: '5px',
    fontWeight: 'bold',
  },
  body: {
    padding: '5px',
    fontStyle: 'italic',
  },
  sectionHeader: {
    backgroundColor: '#cc0000',
    padding: '5px',
    minHeight: '25px',
    borderTop: '10px solid #D3D3D3',
    borderBottom: '10px solid #D3D3D3',
    textTransform: 'uppercase',
  },
});

const theme = createTheme({
  typography: {
    fontFamily: 'Calibri, Arial, sans-serif',
    htmlFontSize: 16,
    fontSize: 10,
    h1: {
      fontSize: '2.7rem',
    },
    h2: {
      fontSize: '2.2rem',
    },
    h3: {
      fontSize: '1.6rem',
      lineHeight: 1,
    },
  },
});

export const RMSProposalPrintView = () => {
  const { id } = useParams<{ id: string }>();
  const date = new Date().toLocaleDateString('en-US');
  const classes = useStyles();

  const {
    rmsProposal,
    rmsProposalLoading,
    rmsProposalError,
    updateRmsProposal,
  } = useRmsProposal();
  const {
    rmsProposalTemplate,
    rmsProposalTemplateLoading,
    rmsProposalTemplateError,
  } = useStandrdRmsProposalTemplate();
  const {
    rmsProposalTemplateTier,
    rmsProposalTemplateTierLoading,
    rmsProposalTemplateTierError,
  } = useTierRmsProposalTemplate();
  const { rmsOneTimeCostTemplate } = useRmsOneTimeCostTemplate();

  const { data: project, loading, error } = useProjects({ id });

  const RMS_PROPOSAL_PRICING_NOTES = `
  <ol>
    <li contenteditable='false'>Please review the server and device types and quantities in the pricing detail section above. This is the list of servers, devices and/or infrastructure CDW assumes you are considering placing under CDW Managed Services. Please review this list for accuracy.</li>
    <li contenteditable='false'>The customer is responsible for the purchase and maintenance of manufacturer hardware and software support for servers and devices placed under CDW Managed Services. Delivery of certain aspects of CDW Managed Services assumes customer has a manufacturer maintenance contract.</li>
    <li contenteditable='false'>CDW’s Managed Services supports software versions that are supported by the manufacturer. If you have any servers or devices with software that is “End of Support” or “End of Life,” further discussion will be necessary and CDW support caveats may exist. Time and materials rates or other pricing modifications may apply.</li>
    <li contenteditable='false'>This is not a contract. Final pricing is subject to a technical review of your infrastructure and further discussion to validate your business, technical, financial and other requirements.</li>
    <li contenteditable='false'>Unit pricing assumes the quantities indicated in the pricing detail section of this document. Changes to unit pricing is subject to a signed change order by the customer.</li>
    <li contenteditable='false'>Pricing assumes a <span>${rmsProposal.term}</span>-month term.
    <li contenteditable='false'>Pricing is valid for 30 days from the date on the cover page.</li>
    <li contenteditable='false'>Billing is based on the quantity of units under service each month, subject to any agreed upon monthly minimum. If the customer does not meet the minimum quantity of users then seller reserves the right to adjust prices once annually and will be documented in a change order.</li>
  </ol>`;

  const [listItems, setListItems] = useState([]);
  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(RMS_PROPOSAL_PRICING_NOTES, 'text/html');
    const doc2 = parser.parseFromString(rmsProposal.pricingNotes, 'text/html');
    const items = Array.from(doc.querySelectorAll('li')).map(
      (li) => li.innerHTML
    );
    const items2 = Array.from(doc2.querySelectorAll('li')).map(
      (li) => li.innerHTML
    );
    const pricingItems = items.concat(items2);
    setListItems(pricingItems);
  }, [RMS_PROPOSAL_PRICING_NOTES, rmsProposal.pricingNotes]);

  const pricingNoteItems = listItems
    .map((item) => {
      if (item && item !== '&nbsp;') {
        return `<li>${item}</li>`;
      }
      return '';
    })
    .join('');

  useEffect(() => {
    if (
      !rmsProposalLoading &&
      !rmsProposalTemplateLoading &&
      !rmsProposalTemplateTierLoading &&
      !loading
    )
      window.print();
  }, [
    rmsProposalLoading,
    rmsProposalTemplateLoading,
    rmsProposalTemplateTierLoading,
    loading,
  ]);

  if (
    rmsProposalLoading ||
    rmsProposalTemplateLoading ||
    rmsProposalTemplateTierLoading ||
    loading
  )
    return <CircularProgress />;
  if (
    rmsProposalTemplateError ||
    rmsProposalTemplateTierError ||
    rmsProposalError ||
    error
  )
    return <div>Error</div>;

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 10000,
          background: 'white',
        }}
      >
        <Box sx={{ height: '100vh', background: 'white' }}>
          <CoverPage
            date={date}
            customerName={project.customerName}
            rmsProposal={rmsProposal}
          />
        </Box>
        <Box sx={{ minHeight: '100vh', background: 'white' }}>
          {rmsProposal.isTierTemplate ? (
            <TierRMSProposalTemplate
              costItems={rmsProposalTemplateTier?.rmsProposalItems || []}
              totalMonthlyCharges={
                rmsProposalTemplateTier?.totalMonthlyCharges || 0.0
              }
              bronzeTotal={rmsProposalTemplateTier?.bronzeTotal || 0.0}
              silverTotal={rmsProposalTemplateTier?.silverTotal || 0.0}
              goldTotal={rmsProposalTemplateTier?.goldTotal || 0.0}
              updateProposalData={rmsProposal.updateProposalData}
              rmsProposal={rmsProposal}
              printView={true}
            />
          ) : (
            <StandardRMSProposalTemplate
              costItems={rmsProposalTemplate?.rmsProposalItems || []}
              totalMonthlyCharges={
                rmsProposalTemplate?.totalMonthlyCharges || 0.0
              }
              printView={true}
            />
          )}
          <RMSOneTimeCostTemplate
            costItems={rmsOneTimeCostTemplate?.rmsOneTimeCostItems || []}
            totalCharges={rmsOneTimeCostTemplate?.totalMonthlyCharges || 0.0}
          />
          {rmsProposal.showReadinessVerbage && (
            <Box sx={{ marginBottom: '15px' }}>
              <Box
                sx={{
                  borderTop: '.5px black solid',
                  borderBottom: '.5px black solid',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="h5" className={classes.header}>
                  CDW Managed Services Readiness Assessment
                </Typography>
                <Typography variant="h5" className={classes.header}>
                  TBD
                </Typography>
              </Box>
              <Box sx={{ width: '75%' }}>
                <Typography variant="caption" className={classes.body}>
                  {MANAGED_SERVICES_READINESS_ASSESSMENT}
                </Typography>
              </Box>
            </Box>
          )}
          {rmsProposal.showOnboardingVerbage && (
            <Box>
              <Box
                sx={{
                  borderTop: '.5px black solid',
                  borderBottom: '.5px black solid',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="h5" className={classes.header}>
                  CDW Managed Services Onboarding
                </Typography>
                <Typography variant="h5" className={classes.header}>
                  {rmsProposal.priceOverride
                    ? formatCurrency(rmsProposal.priceOverride)
                    : 'Included'}
                </Typography>
              </Box>
              <Box sx={{ width: '75%' }}>
                <Typography variant="caption" className={classes.body}>
                  {MANAGED_SERVICES_ONBOARDING}
                </Typography>
              </Box>
            </Box>
          )}
          <Box sx={{ mt: 1, mb: 1 }}>
            <Typography>
              Time and Materials Support - Managed Services Engineering and
              Consulting - $ 240.00 per Hour
            </Typography>
          </Box>
        </Box>
        <Box sx={{ background: 'white' }}>
          <Box className={classes.sectionHeader}>
            <Typography variant="h6" color="white">
              Pricing Notes:
            </Typography>
          </Box>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: `<ol>${pricingNoteItems}</ol>` }}
          ></Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default RMSProposalPrintView;

import {
  CiscoAnnuity,
  CiscoAnnuityPaymentSchedule,
} from '@cdw-selline/common/types';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  Box,
  Grid,
  TableCell,
} from '@mui/material';
import React, { Fragment } from 'react';
import { CiscoAnnuityQuotePaymentScheduleRow } from './CiscoAnnuityQuotePaymentScheduleRow';

export interface CiscoAnnuityQuotePaymentScheduleProps {
  paymentSchedule: CiscoAnnuityPaymentSchedule[];
  terms: number;
}

export function CiscoAnnuityQuotePaymentSchedule({
  paymentSchedule,
  terms,
}: CiscoAnnuityQuotePaymentScheduleProps) {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {paymentSchedule[0].values.map((value, index) => (
              <TableCell key={paymentSchedule[0].id + index}>
                Month {index + 1}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={terms + 1} variant="head">
              List Price
            </TableCell>
          </TableRow>
          {paymentSchedule.map((paymentScheduleRow) => {
            if (paymentScheduleRow.id === 'LIST TOTAL')
              return (
                <Fragment key={paymentScheduleRow.id}>
                  <CiscoAnnuityQuotePaymentScheduleRow
                    ciscoAnnuityPaymentSchedule={paymentScheduleRow}
                  />
                  <TableRow>
                    <TableCell colSpan={terms + 1} variant="head">
                      CDW Price
                    </TableCell>
                  </TableRow>
                </Fragment>
              );

            if (paymentScheduleRow.id === 'CDW TOTAL')
              return (
                <Fragment key={paymentScheduleRow.id}>
                  <CiscoAnnuityQuotePaymentScheduleRow
                    ciscoAnnuityPaymentSchedule={paymentScheduleRow}
                  />
                  <TableRow>
                    <TableCell colSpan={terms + 1} variant="head">
                      Customer Price
                    </TableCell>
                  </TableRow>
                </Fragment>
              );

            return (
              <CiscoAnnuityQuotePaymentScheduleRow
                key={paymentScheduleRow.id}
                ciscoAnnuityPaymentSchedule={paymentScheduleRow}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

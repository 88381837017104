import { gql } from '@apollo/client';

export const UPDATE_INCLUDE_IN_QUOTE_BY_PROJECT_ITEM_ID_MUTATION = gql`
  mutation UpdateIncludeInQuoteById(
    $quoteId: ID!
    $includeInQuote: Boolean!
    $category: String
  ) {
    updateIncludeInQuoteById(
      quoteId: $quoteId
      includeInQuote: $includeInQuote
      category: $category
    ) {
      success
    }
  }
`;

import { gql } from '@apollo/client';

export const GET_NEW_PROJECT_ITEM_SCHEMA_QUERY = gql`
  query getNewProjectItemSchemaQuery($getSchemaId: ID!) {
    getSchema(id: $getSchemaId) {
      uiSchema: uiSchemaV2
      schema {
        type
        title
        required
        description
        properties {
          ... on NewProjectItemProperties {
            name {
              type
              title
            }
            type {
              type
              title
              enum
            }
            description {
              type
              title
            }
            vendor {
              type
              title
            }
            quantity {
              type
              title
            }
            cost {
              type
              title
            }
            price {
              type
              title
            }
          }
        }
      }
    }
  }
`;

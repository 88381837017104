import { gql } from '@apollo/client';

export const UPDATE_CISCO_ANNUITY_LINE_MUTATION = gql`
  mutation updateCiscoAnnuityLine($params: CiscoAnnuityLineInput) {
    updateCiscoAnnuityLine(params: $params) {
      id
    }
  }
`;

export const REMOVE_CISCO_ANNUITY_LINE_MUTATION = gql`
  mutation removeCiscoAnnuityLine($id: ID!) {
    removeCiscoAnnuityLineById(id: $id) {
      success
    }
  }
`;

export const UPDATE_CISCO_ANNUITY_LINES_MUTATION = gql`
  mutation updateCiscoAnnuityLines($params: [CiscoAnnuityLineInput]) {
    updateCiscoAnnuityLines(params: $params) {
      success
    }
  }
`;

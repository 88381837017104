import { useMutation } from '@apollo/client';
import {
  GET_PROJECT_ITEM_ESTIMATOR_TASKS_QUERY,
  ADD_ESTIMATOR_TASKS_BY_TASKS_GROUP_MUTATION,
  GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY,
  GET_PROJECT_QUERY,
  GET_ESTIMATOR_INCLUDES_MANAGED_SERVICES_QUERY,
  GET_ESTIMATOR_SUMMARY_TASKS_QUERY,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';

import { useApolloErrors } from '..';

export const useAddProjectTaskGroup = () => {
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();

  //TODO Convert to use tasks(aka estimator) MUTATION
  const [addEstimatorTasksByTaskGroup, { loading, error, client }] =
    useMutation(ADD_ESTIMATOR_TASKS_BY_TASKS_GROUP_MUTATION, {
      refetchQueries: [
        GET_PROJECT_ITEM_ESTIMATOR_TASKS_QUERY,
        GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY,
        GET_ESTIMATOR_INCLUDES_MANAGED_SERVICES_QUERY,
        GET_ESTIMATOR_SUMMARY_TASKS_QUERY,
      ],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to add Project Task Group!'),
      onCompleted: (data) => {
        client.refetchQueries({
          include: [GET_PROJECT_QUERY],
        });

        if (data?.addEstimatorTasksByTaskGroup?.hasBillingTypeChanged) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.INFO,
            message:
              'Please note that the billing type has been changed by your task group selection.',
          });
        } else {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully Added Task Group',
          });
        }

      }
    });

  interface AddTaskGroupInput {
    itemId: string;
    siteId: string;
    taskGroupId: string;
  }

  const handleAddTaskGroup = ({
    itemId,
    siteId,
    taskGroupId,
  }: AddTaskGroupInput) => {
    addEstimatorTasksByTaskGroup({
      variables: {
        itemId: itemId,
        siteId: siteId,
        taskGroupId: taskGroupId,
      },
    });
  };

  return {
    handleAddTaskGroup,
    loading,
    error,
  };
};

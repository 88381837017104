import { gql } from '@apollo/client';

export const UPDATE_PROJECT_TASK_MUTATION = gql`
  mutation Mutation(
    $updateEstimatorTaskParams: TaskInput
    $projectItemId: String!
    $site: String!
  ) {
    updateEstimatorTask(
      projectItemId: $projectItemId
      site: $site
      params: $updateEstimatorTaskParams
    ) {
      sites {
        name
        tasks {
          id
          allowMarkup
          category
          comment
          defaultGrossProfit
          cost
          costType
          edc
          exclude
          excludeToggle
          minGrossProfit
          hours
          id
          lastReviewedOn
          name
          noValue
          yesValue
          order
          overtime
          phases {
            name
            taskPercent
          }
          practice
          practiceId
          primaryPercent
          primaryRole
          primaryRoleId
          primaryRoleName
          proposalNotes
          quantity
          rate
          roles
          secondaryPercent
          secondaryRole
          secondaryRoleId
          secondaryRoleName
          tertiaryPercent
          tertiaryRole
          tertiaryRoleId
          tertiaryRoleName
          sowSectionId
          subType
          taskGroupId
          taskGroups
          taskId
          tiers
          totalHours
          type
          units
        }
      }
    }
  }
`;

/*
updateEstimatorTaskParams: {
   ...TaskInput
}
*/

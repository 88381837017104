import { gql } from '@apollo/client';

export const ADD_CUSTOM_TASK_MUTATION = gql`
  mutation addCustomTaskToSiteMutation(
    $projectItemId: ID!
    $site: String!
    $task: TaskInput!
  ) {
    addCustomTaskToSite(
      projectItemId: $projectItemId
      site: $site
      task: $task
    ) {
      success
    }
  }
`;

// SAMPLE VARIABLES;
// {
//   "projectId": "61805a86e6047cfe04ae4085",
//   "site": "hq1",
//   "task": {
//     "type": "Hours",
//     "taskGroups": [
//       "220",
//       "186",
//       "273",
//       "261",
//       "259"
//     ],
//     "allowMarkup": true,
//     "category": "cat name",
//     "edc": "wrwerer",
//     "excludeToggle": true,
//     "exclude": false,
//     "hours": 44,
//     "id": "6170405bfd6ba55239b45453",
//     "name": "test task",
//     "practice": "55",
//     "primaryPercent": 0,
//     "primaryRole": "240",
//     "quantity": "333",
//     "secondaryPercent": 0,
//     "secondaryRole": "188",
//     "alwaysShowOnProposal": false
//   }
// }

import { GridPaginationModel } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';

export const useCollectionPagination = (
  refetch: (options: { offset: number; limit: number }) => void
) => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageParams, setPageParams] = useState<{
    offset: number;
    limit: number;
  }>({
    offset: 0,
    limit: 100,
  });

  useEffect(() => {
    // TODO sessionStorage doesn't appear to be actually useful in existing tables, but including for sake of reflecting current behavior
    sessionStorage.page = currentPage;
    sessionStorage.offset = pageParams.offset;
    refetch(pageParams);
  }, [currentPage, pageParams, refetch]);

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    const size = model?.pageSize ?? pageParams?.limit;
    const page = model?.page ?? currentPage;
    let offset = pageParams.offset;
    if (size !== pageParams.limit) {
      offset = (pageParams.offset / pageParams.limit) * size;
    }
    if (page < currentPage) {
      offset -= size;
    } else if (page > currentPage) {
      offset += size;
    }
    setPageParams({
      limit: size,
      offset: Number(offset),
    });
    setCurrentPage(Number(page));
  };

  return {
    handlePaginationModelChange,
    page: currentPage,
    ...pageParams,
  };
};

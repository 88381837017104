export const offerings = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

export enum statusActiveStep {
  'Discovery' = 0,
  'Solution' = 1,
  'Propose' = 2,
  'Close' = 3,
  'CloseWin' = 4,
  'CloseLost' = 4,
  'ClosedDidNotPurchased' = 4,
}
export enum closeStatus {
  'ClosedWon' = 'Won',
  'ClosedLost' = 'Lost',
  'ClosedDidNotPurchase' = 'Did Not Purchase',
}

export const probabilityOfClose = [
  { label: '10% (New Entry)', value: 10 },
  { label: '25% (High Risk Opportunity)', value: 25 },
  { label: '50% (Medium Risk Opportunity)', value: 50 },
  { label: '75% (Low Risk Opportunity)', value: 75 },
  { label: '90% (Mininal Risk Opportunity)', value: 90 },
  { label: '100% (No Risk)', value: 100 },
];
export const customerSegment = [{ label: 'CDW' }, { label: 'CDWG' }];

export const statusSteps: Readonly<string[]> = Object.freeze([
  'Discovery',
  'Solution',
  'Propose',
  'Close',
]);

export const opportunityTeamTypes: Readonly<string[]> = Object.freeze([
  'Primary Account Owner',
  'Secondary Account Owner',
  'Sales Manager',
  'Solution Architect',
  'Business Development Manager',
  'Field Rep',
  'Additional Account Owner',
  'Business Development',
  'Collaborator',
  'Specialist',
  'Advisor',
  'Technical Case Owner',
  'Technical Case Collaborator',
]);

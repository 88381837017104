// eslint-disable-next-line @nx/enforce-module-boundaries
import type { Opportunity } from '@cdw-selline/common/types';
import {
  Button,
  Grid,
  Typography,
  Card,
  IconButton,
  Paper,
  MenuItem,
  DialogActions,
  Menu,
  useTheme,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LoadingButton } from '@mui/lab';
import * as React from 'react';
import DialogModal from '../dialog-modal/DialogModal';
import { useProjects } from '@cdw-selline/ui/hooks';
import { KanbanDetailsForm } from '../kanban-details-form/KanbanDetailsForm';
import moment from 'moment';
import { formatCurrency } from '@cdw-selline/ui/helpers';
import { pick } from 'lodash';
import { ROUTES } from '@cdw-selline/ui/constants';

export interface DashboardCardsProps {
  opportunity: Partial<Opportunity>;
  routeTo: (id: string, location: string) => void;
}

export const DashboardCard = (props: DashboardCardsProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();

  const [showCommentsForm, setShowCommentsForm] = React.useState(false);
  const o = props.opportunity;
  const id = o.id;
  const { updateProject, data, error, loading } = useProjects({ id });
  const today = new Date();
  const diff = moment(o.estClose).diff(today.toISOString(), 'days');
  const [formData, setFormData] = React.useState(data);

  let bgColor = 'white';
  if (diff > 7) {
    bgColor = theme.palette.success.main;
  } else if (diff <= 7 && diff >= 0) {
    bgColor = theme.palette.warning.main;
  } else if (diff < 0) {
    bgColor = theme.palette.error.main;
  }

  const handleProjectCommentClick = () => {
    setShowCommentsForm(true);
  };

  const handleProjectCommentClose = () => {
    setShowCommentsForm(false);
  };

  const handleProjectCommentSave = () => {
    const updateRequest = pick({ ...formData, id }, [
      'estClose',
      'probability',
      'id',
    ]);
    updateProject({
      variables: { updateProjectParams: updateRequest },
    });
    setShowCommentsForm(false);
  };

  const mailTo = props.opportunity?.projectTeam
    ? props.opportunity?.projectTeam.find((o) => o.type === 'Sales Manager')
        ?.email
      ? props.opportunity?.projectTeam.find((o) => o.type === 'Sales Manager')
          .email
      : props.opportunity.projectOwner
    : props.opportunity.projectOwner;

  if (error) return <>Error loading form: {error.message}</>;

  return (
    <Card
      sx={{
        marginBottom: '15px',
        width: '95%',
        cursor: 'move',
        height: 100,
      }}
    >
      <Grid
        container
        style={{
          height: '100%',
          padding: 3.5,
          paddingLeft: 7,
          position: 'relative',
        }}
      >
        <div
          style={{
            backgroundColor: bgColor,
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: 5,
          }}
        ></div>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={11}>
              <Typography
                component="div"
                onClick={() => props.routeTo(o.id, ROUTES.PIPELINES)}
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
              >
                {o.name}
              </Typography>
            </Grid>
            <Grid item xs={1} textAlign="right">
              <IconButton id="long-button" onClick={handleClick} size="small">
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                disableScrollLock={true}
                PaperProps={{
                  style: {
                    width: '20ch',
                  },
                }}
              >
                <MenuItem
                  onClick={(e) => {
                    handleClose();
                    window.location.href =
                      'mailto:' +
                      mailTo +
                      '?subject=' +
                      o.name +
                      '&body=https://selline-prod.web.app/project/' +
                      o.id;
                    e.preventDefault();
                  }}
                >
                  EMAIL
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    handleClose();
                    handleProjectCommentClick();
                    e.preventDefault();
                  }}
                >
                  COMMENTS
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography component="div">{o.customerName}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="right"></Grid>
          </Grid>
        </Grid>

        {1 == 1 && ( //Update to check if opertunity has amount
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="body1">Amount</Typography>
              </Grid>
              <Grid item xs={6} textAlign="right">
                {formatCurrency(
                  o?.serviceTotal + o?.productTotal + o?.subscriptionTotal
                )}
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography component="div">
                Est. Close {new Date(o.estClose).toLocaleDateString()}
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Typography component="div">{o.probability}%</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Paper>
        <DialogModal
          title="Opportunity Section"
          isOpen={showCommentsForm}
          handleClose={handleProjectCommentClose}
          maxWidthProp={'lg'}
          disableScrollLock={true}
        >
          <KanbanDetailsForm
            project={o}
            formData={formData}
            setFormData={setFormData}
          ></KanbanDetailsForm>

          <DialogActions>
            <Button color="secondary" onClick={handleProjectCommentClose}>
              Cancel
            </Button>
            <LoadingButton
              color="primary"
              type="submit"
              loading={loading}
              variant="contained"
              onClick={handleProjectCommentSave}
              data-testid="kanban-project-submit-button"
            >
              Save
            </LoadingButton>
          </DialogActions>
        </DialogModal>
      </Paper>
    </Card>
  );
};

import { gql } from '@apollo/client';

export const GET_PROJECT_ITEM_ESTIMATORS_QUERY = gql`
  query getProjectItemEstimatorByItemId($projectItemId: String!) {
    getProjectItemEstimatorByItemId(projectItemId: $projectItemId) {
      id
      projectItemId
      overtimePercent
      billingType
      pmPercent
      pmRole
      hours
      includeTAndEFixedFee
      riskMitigation
      tAndETotal
      managedServicesContractLength
      startDate
      endDate
      isManagedServicesRenewal
      fixedFeeMilestones {
        name
        percent
        cost
      }
      rmsProposal {
        primaryName
        primaryTitle
        primaryEmail
        primaryOrg
        primaryPhone
        altName
        altTitle
        altEmail
        altOrg
        altPhone
        showReadinessVerbage
        isTierTemplate
        showOnboardingVerbage
        priceOverride
        pricingNotes
        bronze
        silver
        gold
        includeMonthlySubtotals
        includeMonthlyTotal
        term
      }
      currentProposalId
      actualSellPrice
      agiloftExpenseType
      agiloftExpenseClause
      agiloftExpenseMiles
      agiloftTravelType
      agiloftTravelClause
      agiloftTravelPenaltyAmount
      agiloftGenerateDocuments
      agiloftPrintTemplateType
      agiloftServiceFeeClause
      agiloftPmClause
      agiloftFeeStructure
      agiloftSummaryTable
      agiloftMultiFeeTables
      agiloftFundingAmount
      agiloftUrl
      bidAssuranceStatusHistories {
        taskGroupId
        status
        updatedAt
        updatedBy
      }
      bidAssuranceStatus
      psaEstimateId
      psaSyncHistories {
        updatedAt
        isSynced
      }
      psaSyncCombined
      psaEstimateProduct
      includePartnerServicesInFixedFee
      managedServicesCustomerType,
      managedServicesDealType,
      managedServicesCustomDeal,
      managedServicesTransitionStartDate,
      managedServicesTransitionEstimatedDuration,
      managedServicesTransitionFeeType,
    }
  }
`;

import { gql } from '@apollo/client';

export const ADD_CISCO_ANNUITY_MUTATION = gql`
  mutation addCiscoAnnuity($params: CiscoAnnuityInput!) {
    addCiscoAnnuity(params: $params) {
      success
      id
    }
  }
`;

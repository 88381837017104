import { gql } from '@apollo/client';

export const GET_PROJECT_PRACTICES_RATES = gql`
  query getProjectItemEstimatorRates(
    $getProjectItemEstimatorRatesProjectId: String!
  ) {
    getProjectItemEstimatorRates(
      projectItemId: $getProjectItemEstimatorRatesProjectId
    ) {
      practices {
        id
        name
        peerReviewEmail
        practiceId
        psm
        serviceLocationId
        managedSvc
      }
      rates {
        cdwRateId
        cost
        id
        locationId
        name
        otCost
        practice
        projectManager
        rate
        roleId
        agiloftRole
      }
    }
  }
`;

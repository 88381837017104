import { RoleAndRate, Task, Site } from '@cdw-selline/common/types';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { estimatorStrings, TASK_TYPES } from '@cdw-selline/ui/constants';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useSummaryTasks } from '@cdw-selline/ui/hooks';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';

import React from 'react';

import { MemorizedTask } from '../../estimate-details/task-item/TaskItem';

export interface SummaryTaskListProps {
  projectItemId: string;
  site: Site;
  expanded?: boolean;
  hideZeroQuantity?: boolean;
  handleTaskEdit: (taskId: string, sideId: string) => void;
  handleVersionSyncOpen: (task: Task) => void;
  handleNotesOpen: (currentTask: Task) => void;
  currentRolesAndRates: RoleAndRate[];
  handleRemoveCustomTask: (taskId: string) => void;
  activeTaskId: string;
  fullSiteLoad: boolean;
  setFullSiteLoad: (value: boolean) => void;
  handleTextTaskEdit: (taskId: string, sideId: string) => void;
  handleProposalDescriptionDialogOpen: (task: Task) => void;
  isReadOnly: boolean;
}

export function SummaryTaskList({
  projectItemId,
  site,
  expanded,
  hideZeroQuantity,
  handleTaskEdit,
  handleVersionSyncOpen,
  handleNotesOpen,
  currentRolesAndRates,
  handleRemoveCustomTask,
  activeTaskId,
  fullSiteLoad,
  setFullSiteLoad,
  handleTextTaskEdit,
  handleProposalDescriptionDialogOpen,
  isReadOnly,
}: SummaryTaskListProps) {
  const theme = useTheme();
  const { TASK_COLUMNS } = estimatorStrings;

  const { estimatorTasks, addTaskToWriteQueue, updateTaskLoading } =
    useSummaryTasks(site.id, activeTaskId, true, fullSiteLoad, setFullSiteLoad);

  const visibleCategory = [];
  const visibleTaskGroups = [];
  estimatorTasks
    ?.filter(
      (task) =>
        !task.hide &&
        !(
          hideZeroQuantity &&
          (([TASK_TYPES.YES_NO, TASK_TYPES.HOURS, TASK_TYPES.COST].includes(
            task.type
          ) &&
            !task.quantity) ||
            (task.type === TASK_TYPES.LIST && !task.dropDownIndex))
        )
    )
    .forEach((task, index, map) => {
      if (index === 0 || task.category !== map[index - 1].category) {
        visibleCategory.push(task.category);
      }

      if (index === 0 || task.taskGroupId !== map[index - 1].taskGroupId) {
        visibleTaskGroups.push(task.taskGroupName);
      }
    });

  if (!expanded) return null;

  return (
    <Table size="small" data-testid="task-list-table">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Qty/Section</TableCell>
          <TableCell></TableCell>
          <TableCell>Task</TableCell>
          <TableCell>Units</TableCell>
          <TableCell>Primary Role</TableCell>
          <TableCell>Secondary Role</TableCell>
          <TableCell>Overtime/Add'l GP</TableCell>
          <TableCell>Hours/MSRP</TableCell>
          <TableCell sx={{ minWidth: '120px' }}>Total</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {estimatorTasks?.map?.((task, index, map) => (
          <React.Fragment key={index}>
            {visibleTaskGroups.length > 1 &&
              (index === 0 ||
                (task.taskGroupId !== map[index - 1].taskGroupId &&
                  visibleTaskGroups.includes(task.taskGroupName))) && (
                <TableRow
                  key={`${site.id}-${task.taskGroupId}-${index}`}
                  sx={{ backgroundColor: theme.palette.grey[500] }}
                >
                  <TableCell
                    colSpan={TASK_COLUMNS.length + 1}
                    sx={{
                      color: theme.palette.grey[50],
                      fontSize: '18px',
                      fontWeight: 'bold',
                    }}
                  >
                    {task.taskGroupName}
                  </TableCell>
                </TableRow>
              )}
            {(index === 0 ||
              (task.category !== map[index - 1].category &&
                visibleCategory.includes(task.category))) && (
              <TableRow
                key={`${site.id}-${task.category}-${index}`}
                sx={{ backgroundColor: theme.palette.grey[600] }}
              >
                <TableCell
                  colSpan={TASK_COLUMNS.length + 1}
                  sx={{ color: theme.palette.common.white, fontSize: '15px' }}
                >
                  {task.category}
                </TableCell>
              </TableRow>
            )}
            <MemorizedTask
              {...{
                task,
                taskIndex: index,
                projectItemId,
                key: `${task.id}`,
                expanded,
                hideZeroQuantity,
                handleTaskEdit,
                handleVersionSyncOpen,
                handleNotesOpen,
                currentRolesAndRates,
                handleProposalDescriptionDialogOpen,
                addTaskToWriteQueue,
                handleRemoveCustomTask,
                updateTaskLoading,
                handleTextTaskEdit,
                isReadOnly,
              }}
            />
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  );
}

export default SummaryTaskList;

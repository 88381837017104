import { gql } from '@apollo/client';

export const GET_CISCO_ANNUITY_CATEGORIES = gql`
  query GetCiscoAnnuityCategories($filters: MongoFilter) {
    getCiscoAnnuityCategories(filters: $filters) {
      id
      category
      order
      allowCdwDiscountEdit
      allowCustomerDiscountEdit
    }
  }
`;

import { gql } from '@apollo/client';

export const UPDATE_QUOTE_LINES_MUTATION = gql`
  mutation Mutation(
    $projectItemId: ID!
    $updatedQuoteLines: [ProjectItemQuoteLineInput]
  ) {
    updateQuoteLines(
      projectItemId: $projectItemId
      updatedQuoteLines: $updatedQuoteLines
    ) {
      success
    }
  }
`;

/* VARIABLES
{
  "projectItemId": "6220f12100fbfcda32ab82ec",
  "updatedQuoteLines": [
    {
          "id": "6220f12bb8dcd3da3ed3ab93",
          "type": 1,
          "description": "description one"
        },
        {
          "id": "6220f12cb8dcd3da3ed3ab97",
          "description": "description two"
        },
        {
          "id": "6220f2ceb8dcd3da3ed3aba7",
          "description": "description three"
        }
  ]
}
*/

import { gql } from '@apollo/client';

export const UPDATE_TASK_GROUP_MUTATION = gql`
  mutation updateTaskGroupMutation($params: AdminTaskGroupInput) {
    updateTaskGroup(params: $params) {
      success
    }
  }
`;

export const DUPLICATE_TASK_GROUP_MUTATION = gql`
  mutation duplicateTaskGroupMutation($taskGroup: AdminTaskGroupInput, $includeAllTasks: Boolean) {
    duplicateTaskGroup(taskGroup: $taskGroup, includeAllTasks: $includeAllTasks) {
      success
    }
  }
`;

export const CREATE_TEST_VERSION_TASK_GROUP_MUTATION = gql`
  mutation createTestVersionByTaskGroupMutation($taskGroupId: ID!) {
    createTestVersionByTaskGroup(taskGroupId: $taskGroupId) {
      success
    }
  }
`;

export const REMOVE_TASK_GROUP_TEST_VERSION_MUTATION = gql`
  mutation removeTaskGroupTestVersionMutation($id: ID!) {
    removeTaskGroupTestVersion(id: $id) {
      success
    }
  }
`;

export const APPROVE_TASK_GROUP_TEST_VERSION_MUTATION = gql`
  mutation approveTaskGroupTestVersionMutation($id: ID!) {
    approveTaskGroupTestVersion(id: $id) {
      success
    }
  }
`;

// VARIABLES
// {
//   "params": {}
// }

import { useQuery } from '@apollo/client';
import { SearchCustomersResponse } from '@cdw-selline/common/types';
import { SEARCH_CUSTOMERS_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from './useApolloErrors';

export const useSearchCustomers = (
  searchTerm: string,
  { skip = false } = {}
) => {
  const { handleErrorResponse } = useApolloErrors();
  const { data, loading, error } = useQuery<{
    searchCustomers: SearchCustomersResponse;
  }>(SEARCH_CUSTOMERS_QUERY, {
    variables: {
      searchTerm,
    },
    skip: skip || !searchTerm,
    onError: (error) =>
      handleErrorResponse(error, 'Failed to search for Customers!'),
  });

  return {
    data: data?.searchCustomers?.customers ?? [],
    loading,
    error,
  };
};

import { TASK_COST_TYPES } from '@cdw-selline/ui/constants';
import { Grid, MenuItem } from '@mui/material';
import React from 'react';
import {
  FormAutocomplete,
  FormText,
  FormCheckbox,
  FormSelect,
  FormTextControlled,
  FormAutocompleteOption,
} from '../formHelperFunctions';
import FormFieldAutoComplete from '../autocomplete-form-field/FormFieldAutoComplete';
import { useSearchServiceItems, useSearchServiceSkills } from '@cdw-selline/ui/hooks';

export interface CustomCostTaskFormProps {
  taskFormData: any;
  setTaskFormData: (value) => void;
  vendorOptions: FormAutocompleteOption[];
  roleOptions: FormAutocompleteOption[];
  errorsArray: any;
  practiceOptions: FormAutocompleteOption[];
}

export function CustomCostTaskForm({
  taskFormData,
  setTaskFormData,
  vendorOptions,
  roleOptions,
  errorsArray,
  practiceOptions,
}: CustomCostTaskFormProps) {
  return (
    <>
      <FormSelect
        name="costType"
        label="Cost Type"
        setFormData={setTaskFormData}
        formData={taskFormData}
        required={true}
        error={errorsArray.length > 0 && !taskFormData.costType}
      >
        {Object.keys(TASK_COST_TYPES).map((type) => (
          <MenuItem key={type} value={TASK_COST_TYPES[type]}>
            {TASK_COST_TYPES[type]}
          </MenuItem>
        ))}
      </FormSelect>
      <FormText
        name="name"
        label="Name"
        setFormData={setTaskFormData}
        formData={taskFormData}
        required={true}
        error={errorsArray.length > 0 && !taskFormData.name}
      />
      <FormAutocomplete
        name="practiceId"
        label="Practice"
        options={practiceOptions}
        setFormData={setTaskFormData}
        formData={taskFormData}
        data-testid="task-practices"
        required={true}
        error={errorsArray.length > 0 && !taskFormData.practiceId}
      />
      {taskFormData.costType === TASK_COST_TYPES.ONE_TIME && (
        <FormCheckbox
          name="isExpense"
          label={'Is Expense ?'}
          setFormData={setTaskFormData}
          formData={taskFormData}
          data-testid="isExpense"
        />
      )}
      {taskFormData.costType !== TASK_COST_TYPES.FUNDING && !taskFormData.isExpense && (
        <FormCheckbox
          name="managedServices"
          label="Managed Services?"
          setFormData={setTaskFormData}
          formData={taskFormData}
        />
      )}
      <FormText
        name="units"
        label="Units"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      <FormText
        name="category"
        label="Category"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      {!taskFormData.isExpense && (
        <FormAutocomplete
          name="vendor"
          label="Vendor"
          options={vendorOptions}
          setFormData={setTaskFormData}
          formData={taskFormData}
          freeSolo={true}
        />
      )}
      <FormTextControlled
        name="comment"
        label="Comment"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      {!taskFormData.isExpense && (
        <Grid item xs={12} sx={{ width: '100%' }}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={6}>
              <FormText
                name="sku"
                label="SKU"
                setFormData={setTaskFormData}
                formData={taskFormData}
              />
            </Grid>
            <Grid item xs={6}>
              <FormText
                name="edc"
                label="EDC"
                setFormData={setTaskFormData}
                formData={taskFormData}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {taskFormData.managedServices && (
        <>
          <FormCheckbox
            name="alwaysShowOnProposal"
            label="Always Show on Proposal?"
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          <FormText
            name="proposalNotes"
            label="Proposal Notes"
            setFormData={setTaskFormData}
            formData={taskFormData}
            multiline={true}
          />
        </>
      )}
      <FormText
        name="quantity"
        label="Quantity"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      {taskFormData.costType !== TASK_COST_TYPES.MISC_PER_UNIT ? (
        <>
          <FormText
            name="cost"
            label="Cost"
            setFormData={setTaskFormData}
            formData={taskFormData}
            type="number"
            required={true}
            error={errorsArray.length > 0 && !taskFormData.cost}
          />
          {!taskFormData.isExpense && (
            <>
              <FormText
                name="minGrossProfit"
                label="Min Gross Profit"
                setFormData={setTaskFormData}
                formData={taskFormData}
                type="number"
              />
              <FormText
                name="grossProfit"
                label="Default Gross Profit"
                setFormData={setTaskFormData}
                formData={taskFormData}
                type="number"
              />
            </>
          )}
        </>
      ) : (
        <FormText
          name="sellPrice"
          label="Sell Price"
          setFormData={setTaskFormData}
          formData={taskFormData}
          type="number"
        />
      )}

      {taskFormData.costType === TASK_COST_TYPES.MISC_PER_UNIT && (
        <>
          <FormFieldAutoComplete
            name="skill"
            label="Skill"
            setTaskFormData={setTaskFormData}
            taskFormData={taskFormData}
            useSearchHook={useSearchServiceSkills}
            idColumnName="skillId"
            isUpdateIdAndName={true}
          />
          <FormFieldAutoComplete
            name="serviceItem"
            label="Service Item"
            setTaskFormData={setTaskFormData}
            taskFormData={taskFormData}
            useSearchHook={useSearchServiceItems}
            idColumnName="serviceItemId"
            isUpdateIdAndName={true}
          />
          <FormCheckbox
            name="totalHoursNoRounding"
            label="Disable Total Hours Rounding?"
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          <FormCheckbox
            name="hideHours"
            label="Hide hours?"
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          <FormCheckbox
            name="allowTotalHoursAdjustment"
            label="Allow Total Hours Adjustments?"
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          <FormText
            name="hours"
            label="Hours"
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          <FormText
            name="hoursFormula"
            label="Hours Formula"
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          <FormCheckbox
            name="disableHours"
            label="Disable Hours?"
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          {!taskFormData.disableHours && (
            <FormText
              name="disableHoursFormula"
              label="Disable Hours Formula"
              setFormData={setTaskFormData}
              formData={taskFormData}
            />
          )}
          <FormAutocomplete
            name="primaryRole"
            label="Primary Role"
            options={roleOptions}
            setFormData={setTaskFormData}
            formData={taskFormData}
            required={true}
            error={errorsArray.length > 0 && !taskFormData.primaryRole}
          />
          {taskFormData?.costType !== TASK_COST_TYPES.MISC_PER_UNIT && (
            <>
              <FormText
                name="primaryPercent"
                label="Primary Hours %"
                setFormData={setTaskFormData}
                formData={taskFormData}
                required={true}
                error={errorsArray.length > 0 && !taskFormData.primaryPercent}
              />
              <FormAutocomplete
                name="secondaryRole"
                label="Secondary Role"
                options={roleOptions}
                setFormData={setTaskFormData}
                formData={taskFormData}
              />
              <FormText
                name="secondaryPercent"
                label="Secondary Hours %"
                setFormData={setTaskFormData}
                formData={taskFormData}
                required={true}
                error={errorsArray.length > 0 && !taskFormData.secondaryPercent}
              />
            </>
          )}
        </>
      )}
    </>
  );
}

import { gql } from '@apollo/client';

export const GET_USERS_QUERY = gql`
  query getUsersQuery($filters: MongoFilter) {
    getUsers(filters: $filters) {
      count
      users {
        name
        username
        picture
        iss
        aud
        auth_time
        user_id
        sub
        iat
        exp
        email
        email_verified
        department
        displayName
        title
        organization
        employeeId
        managerEmail
        admin
        managedServicesAdmin
        contentAdmin
      }
    }
  }
`;

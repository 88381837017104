import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { InvestmentSummary } from '@cdw-selline/common/types';
import { GET_INVESTMENT_SUMMARY_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from '.';

export const useItemSummary = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { projectItemId } = useParams<{ projectItemId: string }>();
  const { loading, error, data } = useQuery<{
    getInvestmentSummaryByEstimatorId: InvestmentSummary;
  }>(GET_INVESTMENT_SUMMARY_QUERY, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    variables: { id: projectItemId },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Investment Summary!'),
  });

  return {
    itemSummary:
      data?.getInvestmentSummaryByEstimatorId ?? ([] as InvestmentSummary),
    error,
    loading,
  };
};

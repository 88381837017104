import React, { useState } from 'react';
import { getActionButtons, FormSelect } from '../formHelperFunctions';
import {
  Grid,
  Box,
  Typography,
} from '@mui/material';
import DialogModal from '../dialog-modal/DialogModal';
import { UsersAutocomplete } from '../users-autocomplete/UsersAutocomplete';

export interface AdminAccessFormProps {
  isOpen: boolean;
  handleClose: () => unknown;
  handleSave: (formData, usersType?) => unknown;
  usersType?: string;
}

export const AdminAccessForm = ({
  isOpen,
  handleClose,
  handleSave,
  usersType,
}: AdminAccessFormProps) => {
  const [value, setValue] = useState({
    email: '',
  });
  const [error, setError] = useState(false);

  const handleFormClose = () => {
    setError(false);
    setValue({ email: '' });
    handleClose();
  };

  const handleSaveClick = () => {
    if (value.email === '') {
      setError(true);
    } else {
      handleSave(value, usersType);
      handleClose();
      setError(false);
    }
  };

  return (
    <DialogModal
      fullWidth={true}
      isOpen={isOpen}
      handleClose={handleClose}
      title={'Add User'}
      action={getActionButtons(handleFormClose, handleSaveClick)}
      maxWidthProp={'sm'}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="row"
        spacing={1}
        mt={1}
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="column"
          spacing={1}
          sx={{ width: '100%' }}
        >
          <Grid container spacing={1} item xs={12} sx={{ width: '100%' }}>
            {error && (
              <Box sx={{ padding: '8px' }}>
                <Typography color="error" variant="h6">
                  Please select a user to add to the task group
                </Typography>
              </Box>
            )}
            <UsersAutocomplete
              setProjectFormData={setValue}
              projectFormData={value}
            />
          </Grid>
        </Grid>
      </Grid>
    </DialogModal>
  );
};

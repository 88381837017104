import { gql } from '@apollo/client';

export const GET_CUSTOMERS_QUERY = gql`
  query getCustomers($limit: Int, $offset: Int, $filters: MongoFilter, $sort: MongoFilter) {
    getCustomers(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
      count
      customers {
        customerDescription
        customerCode
        name
        region
        sector
        area
        stateCode
        id
        onedriveLink
        onedriveId
        updatedAt
        createdAt
      }
    }
  }
`;

export const GET_CUSTOMER_BY_ID_QUERY = gql`
  query getCustomerById($id: ID!) {
    getCustomer(id: $id) {
        id
        customerCode
        name
        customerDescription
        addressLine1
        addressLine2
        city
        stateCode
        zipCode
        onedriveLink
        onedriveId
      }
    }
`;

export const ADD_CUSTOMER_MUTATION = gql`
  mutation addCustomerMutation($params: CustomerInput) {
    addCustomer(params: $params) {
      id
      success
    }
  }
`;

export const UPDATE_CUSTOMER_MUTATION = gql`
  mutation updateCustomerMutation($params: CustomerInput) {
    updateCustomer(params: $params) {
      success
    }
  }
`;



import { gql } from '@apollo/client';

export const SET_PROJECT_PRACTICES_AND_RATES_MUTATION = gql`
  mutation Mutation(
    $practices: [PracticeInput]
    $projectItemId: String!
    $rates: [RoleAndRateInput]
  ) {
    setProjectItemEstimatorRates(
      practices: $practices
      projectItemId: $projectItemId
      rates: $rates
    ) {
      success
    }
  }
`;

// VARIABLES
// {
//   "projectId": "12345678",
//   "practices": [{ ...full practice object }],
//   "rates": [{ ...full role and rate object }]
// }

/* eslint-disable @nx/enforce-module-boundaries */
import {
  Button,
  DialogActions,
  FormLabel,
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import React from 'react';
import DialogModal from '../../dialog-modal/DialogModal';
import { useAgiloftSowById } from '@cdw-selline/ui/hooks';

/* eslint-disable-next-line */
export interface AgiloftSowModalProps {
  isOpen?: boolean;
  handleConfirm: () => void;
  handleCancel: () => void;
  agiloftSOWId: string;
}

export const AgiloftSowModal = (props: AgiloftSowModalProps) => {
  const {
    data: agiloftSOW,
    loading,
    error,
  } = useAgiloftSowById(props.agiloftSOWId);

  return (
    <DialogModal
      isOpen={props.isOpen}
      handleClose={props.handleCancel}
      title={'Agiloft SOW Record'}
      maxWidth="sm"
      data-testid="agiloft-modal-dialog"
    >
      {!agiloftSOW.id && !error && (
        <Box sx={{ margin: '100px', display: 'flex' }}>
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Box
          sx={{ margin: '100px', display: 'flex' }}
          data-testid="agiloft-invalid-sow-id"
        >
          <Typography color="textPrimary" variant="h6">
            Invalid Agiloft SOW Id
          </Typography>
        </Box>
      )}

      {agiloftSOW.id && (
        <div data-testid="agiloft-modal-data">
          <Box sx={{ padding: '10px' }}>
            <FormLabel>ID:</FormLabel>
            <FormLabel sx={{ paddingLeft: '5px', fontWeight: 'bold' }}>
              {agiloftSOW.id}
            </FormLabel>
          </Box>
          <Box sx={{ padding: '10px' }}>
            <FormLabel>Customer Legal Name:</FormLabel>
            <FormLabel sx={{ paddingLeft: '5px', fontWeight: 'bold' }}>
              {agiloftSOW.customerLegalName}
            </FormLabel>
          </Box>
          <Box sx={{ padding: '10px' }}>
            <FormLabel>Project Name:</FormLabel>
            <FormLabel sx={{ paddingLeft: '5px', fontWeight: 'bold' }}>
              {agiloftSOW.projectName}
            </FormLabel>
          </Box>
          <Box sx={{ padding: '10px' }}>
            <FormLabel>Solution Architect:</FormLabel>
            <FormLabel sx={{ paddingLeft: '5px', fontWeight: 'bold' }}>
              {agiloftSOW.solutionArchitect}
            </FormLabel>
          </Box>
        </div>
      )}

      <DialogActions>
        <Button
          color="secondary"
          onClick={props.handleCancel}
          data-testid="agiloft-modal-cancel-button"
        >
          {(error && 'OK') || 'Cancel'}
        </Button>
        {!error && (
          <LoadingButton
            color="primary"
            type="submit"
            variant="contained"
            data-testid="agiloft-modal-submit-button"
            onClick={() => props.handleConfirm()}
          >
            Confirm
          </LoadingButton>
        )}
      </DialogActions>
    </DialogModal>
  );
};

export default AgiloftSowModal;

import { gql } from '@apollo/client';

export const REQUEST_WORD_COF_BY_ID_MUTATION = gql`
  mutation RequestWordCofById(
    $ciscoAnnuityId: ID!
  ) {
    requestWordCofById(
      ciscoAnnuityId: $ciscoAnnuityId
    ) {
      success
    }
  }
`;

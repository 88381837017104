import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { TravelExpenseSummaryResponse } from '@cdw-selline/common/types';
import { GET_TRAVEL_EXPENSE_SUMMARY_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from '../..';

export const useTravelExpenseSummary = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { projectItemId } = useParams<{ projectItemId: string }>();
  const { loading, error, data } = useQuery<{
    getTravelExpenseSummaryByEstimatorId: TravelExpenseSummaryResponse;
  }>(GET_TRAVEL_EXPENSE_SUMMARY_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: { id: projectItemId },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Travel & Expense Summary!'),
  });

  return {
    travelExpenseSummary: data?.getTravelExpenseSummaryByEstimatorId,
    error,
    loading,
  };
};

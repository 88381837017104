import { useMutation } from '@apollo/client';
import {
  ADD_PROJECT_PROPOSAL_MUTATION,
  GET_PROJECT_PROPOSALS_BY_ESTIMATOR_ID_QUERY,
} from '@cdw-selline/ui/queries';
import { useApolloErrors } from '../useApolloErrors';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { AddProjectProposalInput } from '@cdw-selline/common/types';

export const useAddProjectProposal = (completeCallback: (data) => void) => {
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();

  const [addProjectProposal, { loading, error }] = useMutation(
    ADD_PROJECT_PROPOSAL_MUTATION,
    {
      refetchQueries: [GET_PROJECT_PROPOSALS_BY_ESTIMATOR_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to add Project Proposal!'),
      onCompleted: (data) => {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully Added Project Proposal!',
        });
        completeCallback?.(data);
      },
    }
  );

  const handleAddProjectProposal = async ({
    name,
    projectId,
    projectItemId,
    estimatorId,
    templateId,
  }: AddProjectProposalInput) => {
    const addProposalResponse = await addProjectProposal({
      variables: {
        params: {
          name,
          projectId,
          projectItemId,
          estimatorId,
          templateId,
        },
      },
    });
    return addProposalResponse;
  };

  return {
    handleAddProjectProposal,
    loading,
    error,
  };
};

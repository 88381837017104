/* eslint-disable @typescript-eslint/no-empty-interface */
import { State, createState, useState } from '@hookstate/core';
import { nanoid } from 'nanoid';

import { StatePersistance } from './persistence';

const PERSISTENCE_KEY = `state-alerts-data`;

export enum ALERT_TYPE {
  MODAL = 'modal',
  SNACKBAR = 'snackbar',
  NOTIFICATION = 'notification',
}

export enum ALERT_SEVERITY {
  LOG = 'log',
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export enum ALERT_ACTION {
  CLOSE = 'close',
  DISMISS = 'dismiss',
}

export interface AlertAction {
  text: string;
  action: ALERT_ACTION;
  emit?: boolean;
}

export interface AlertData {
  id: string;
  type: ALERT_TYPE;
  severity: ALERT_SEVERITY;
  title?: string;
  message: string;
  hasViewed: boolean;
  lifespan: number | null;
  actions?: AlertAction[];
}

export interface AlertCreate
  extends Partial<Omit<AlertData, 'message' | 'type'>> {
  type: ALERT_TYPE;
  message: string;
}

export interface AlertsState {
  alerts: AlertData[];
  currentSnackbar: Partial<AlertData>;
  currentModal: Partial<AlertData>;
}

function generateAlert({
  type,
  severity,
  lifespan,
  ...data
}: AlertCreate): AlertData {
  const baseAlert: AlertData = {
    id: nanoid(),
    type: type,
    severity: severity || ALERT_SEVERITY.INFO,
    title: undefined,
    message: '',
    hasViewed: false,
    lifespan: lifespan === undefined ? 5000 : lifespan === 0 ? null : lifespan,
  };
  updateCurrent();
  switch (type) {
    case ALERT_TYPE.MODAL:
      return {
        ...baseAlert,
        actions: [{ text: 'Ok', action: ALERT_ACTION.CLOSE }],
        ...data,
      };
    case ALERT_TYPE.SNACKBAR:
      return {
        ...baseAlert,
        actions: [{ text: 'Ok', action: ALERT_ACTION.CLOSE }],
        ...data,
      };
    case ALERT_TYPE.NOTIFICATION:
      return {
        ...baseAlert,
        actions: [{ text: 'Dismiss', action: ALERT_ACTION.CLOSE }],
        ...data,
      };
  }
}

const getInitialState = (): AlertsState => ({
  alerts: [],
  currentModal: {},
  currentSnackbar: {},
});

// Create a persistance object for the alerts state
const statePersistance = new StatePersistance<AlertsState>(PERSISTENCE_KEY, []);

// Create the state object
const alertsState = createState<AlertsState>(
  statePersistance.getPersistedState(getInitialState())
);
// Attach the persistence object to the state
alertsState.attach(statePersistance.attachPersistence(getInitialState()));

function setAlert(alert: AlertCreate): string {
  const newAlert: AlertData = generateAlert(alert);
  if(newAlert.type === ALERT_TYPE.SNACKBAR) {
    clearAllSnackbars();
  }
  alertsState.alerts[alertsState.alerts?.length].set(newAlert);
  // alertsState.alerts[alertsState.alerts?.length - 1].actions.attach(Downgraded);
  updateCurrent();
  return newAlert.id;
}

function getAlert(id: string): State<AlertData> | undefined {
  return alertsState.alerts.find((a) => a.value.id === id);
}

function getOldestAlert(type?: ALERT_TYPE): State<AlertData> | undefined {
  return type
    ? alertsState.alerts.find((alert) => alert.value.type === type)
    : alertsState.alerts[0];
}

function clearAlert(id: string): void {
  const alertIndex = alertsState.alerts.value.findIndex((a) => a.id === id);
  if (alertIndex > -1) {
    alertsState.alerts[alertIndex].merge({ hasViewed: true });
    updateCurrent();
  }
}

function clearAllSnackbars(): void {
  alertsState.alerts.forEach((alert) => {
    if(alert.get().type === "snackbar") {
      deleteAlert(alert.get().id);      
    }    
  });

  updateCurrent();
}

function deleteAlert(id: string): void {
  const alertIndex = alertsState.alerts.value.findIndex((a) => a.id === id);
  if (alertIndex > -1) {
    alertsState.alerts.set((p) => {
      p.splice(alertIndex, 1);
      return p;
    });
    updateCurrent();
  }
}

function updateCurrent() {
  const foundModal: AlertData =
    getOldestAlert(ALERT_TYPE.MODAL)?.value || ({ hasViewed: true } as never);
  alertsState.currentModal.set((s) =>
    JSON.parse(
      JSON.stringify({
        ...s,
        ...foundModal,
      })
    )
  );
  // alertsState.currentModal.actions.attach(Downgraded);

  const foundSnackbar: AlertData =
    getOldestAlert(ALERT_TYPE.SNACKBAR)?.value ||
    ({ hasViewed: true } as never);
  alertsState.currentSnackbar.set((s) =>
    JSON.parse(
      JSON.stringify({
        ...s,
        ...foundSnackbar,
      })
    )
  );
  // alertsState.currentSnackbar.actions.attach(Downgraded);
}

const wrapState = (s: State<AlertsState>) => {
  return {
    alertState: s,
    currentSnackbar: s.currentSnackbar,
    currentModal: s.currentModal,

    getModals: (): State<AlertData>[] =>
      s.alerts.filter((alert) => alert.type.value === ALERT_TYPE.MODAL),
    getSnackbars: (): State<AlertData>[] =>
      s.alerts.filter((alert) => alert.type.value === ALERT_TYPE.SNACKBAR),
    getNotifications: (): State<AlertData>[] =>
      s.alerts.filter((alert) => alert.type.value === ALERT_TYPE.NOTIFICATION),
    setAlert,
    getAlert,
    getOldestAlert,
    clearAlert,
    deleteAlert,
    deleteAllAlerts: () => s.set(getInitialState()),
  };
};

export const useAlertsState = () =>
  wrapState(useState<AlertsState>(alertsState));
export const accessAlertsState = () => wrapState(alertsState);

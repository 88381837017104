import { gql } from '@apollo/client';

export const GET_MANAGED_SERVICES_SUMMARY_QUERY = gql`
  query getManagedServicesSummaryQuery($id: ID!) {
    getManagedServicesSummaryByEstimatorId(projectItemId: $id) {
      grossProfit
      grossProfitPercent
    }
  }
`;

import { Typography, Drawer, Box, IconButton, Divider, DrawerProps} from '@mui/material';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import MoneyIcon from '@mui/icons-material/Money';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SellIcon from '@mui/icons-material/Sell';
import CloseIcon from '@mui/icons-material/Close';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import * as React from 'react';
import { capitalCase } from 'text-case';
import { BidAssuranceStatusHistory, BidAssuranceStatusType } from '@cdw-selline/common/types';

export interface BidAssuranceStatusHistoryProps {
  toggleDrawer: (open: boolean) => void;
  drawerState?: boolean;
  history?: BidAssuranceStatusHistory[];
}

export const ProjectBidAssuranceStatusHistory = ({toggleDrawer, drawerState, history}: BidAssuranceStatusHistoryProps) => {

  const getIcon = (status: string) => {
    const iconMap = {
      [BidAssuranceStatusType.Started]: <PlayArrowIcon />,
      [BidAssuranceStatusType.Requested]: <PeopleAltIcon />,
      [BidAssuranceStatusType.Denied]: <MoodBadIcon />,
      [BidAssuranceStatusType.Cancelled]: <CancelPresentationIcon />,
      [BidAssuranceStatusType.Escalated]: <SellIcon />,
      [BidAssuranceStatusType.Completed]: <MoneyIcon />,
    };
    return iconMap[status] || <LaptopMacIcon />;
  };

  const historyItems = () => (
    <Timeline position="alternate">
      {Array.isArray(history) &&
        history.map((item, idx) => {
            return idx % 2 !== 0 ? (<TimelineItem key={`history-${idx}`}>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                variant="body2"
                color="text.secondary"
              >
                {new Date(item.updatedAt).toLocaleString()}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="secondary">{getIcon(item.status)}</TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                  {capitalCase(item.status)}
                </Typography>
                <Typography>{item.updatedBy}</Typography>
              </TimelineContent>
            </TimelineItem>) : (
              <TimelineItem key={`project-history-${idx}`}>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                variant="body2"
                color="text.secondary"
              >
                <Typography variant="h6" component="span">
                  {capitalCase(item.status)}
                </Typography>
                <Typography>{item.updatedBy}</Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary">{getIcon(item.status)}</TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                {new Date(item.updatedAt).toLocaleString()}
              </TimelineContent>
            </TimelineItem>
            )
          }
        )}
    </Timeline>
  );

  const list = () => (
    <Box
      sx={{
        width: 400,
        marginTop: '5em',
      }}
      role="presentation"
    >
      <Box
        sx={{
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}
        >
          <Box>
            <Typography variant="h6">Status History</Typography>
          </Box>
          <Box
            onClick={() => toggleDrawer(false)}
            sx={{
              justifyContent: 'flex-end',
              display: 'flex',
              right: 5,
              position: 'absolute',
            }}
          >
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="close drawer"
              onClick={() => toggleDrawer(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box>
          <Divider variant="fullWidth" sx={{ color: '#444', mt: '1em' }} />
          {historyItems()}
        </Box>
      </Box>
    </Box>
  );

  return (
    <Drawer
      anchor={'right' as DrawerProps['anchor']}
      open={drawerState}
      onClose={() => toggleDrawer(false)}
      disableScrollLock={true}
    >
      {list()}
    </Drawer>
  );
};

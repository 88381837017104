import { useMutation } from '@apollo/client';
import {
  Customer,
  DefaultAddResponse,
  DefaultMutationResponse,
} from '@cdw-selline/common/types';
import {
  ADD_CUSTOMER_MUTATION,
  GET_CUSTOMERS_QUERY,
  GET_CUSTOMER_BY_ID_QUERY,
  UPDATE_CUSTOMER_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import {
  GridColDef,
  GridPaginationModel,
  GridRowsProp,
  GridFilterModel,
} from '@mui/x-data-grid';
import moment from 'moment';
import { useApolloErrors } from './useApolloErrors';
import { useCustomers } from './useCustomers';
import { useState } from 'react';
import { useOpenState } from './estimator/useOpenState';
import omitDeep from 'omit-deep-lodash';
import {
  getFilters,
  getFilterModelFromLocalStorage,
} from '@cdw-selline/ui/helpers';
import { uniq } from 'lodash';
import { PAGES } from '@cdw-selline/ui/constants';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Customer ID',
    width: 200,
    editable: false,
    flex: 1,
  },
  { field: 'name', headerName: 'Customer Name', width: 250 },
  {
    field: 'customerCode',
    headerName: 'Code',
    width: 200,
    editable: false,
    flex: 1,
  },
  {
    field: 'updatedAt',
    headerName: 'Last Modified',
    type: 'dateTime',
    width: 220,
    editable: false,
    flex: 1,
    valueGetter: (value) => value && !isNaN(value) && new Date(parseInt(value)),
    valueFormatter: (value) =>
      value ? moment(value).format('MM/DD/YYYY') : '',
  },
  {
    field: 'createdAt',
    headerName: 'Created Date',
    type: 'dateTime',
    width: 180,
    editable: false,
    valueGetter: (value) => value && !isNaN(value) && new Date(parseInt(value)),
    valueFormatter: (value) =>
      value ? moment(value).format('MM/DD/YYYY') : '',
  },
];

export const useAdminCustomers = () => {
  const [sortState, setSortState] = useState();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const { data, loading, error, refetch } = useCustomers({
    filters: getFilters(PAGES.CUSTOMERS_COLLECTION),
    offset: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize,
    sort: sortState,
  });
  const filterModel: GridFilterModel = getFilterModelFromLocalStorage(`${PAGES.CUSTOMERS_COLLECTION}-filter`);
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();

  const [editCustomerId, setEditCustomerId] = useState(null);
  const {
    isOpen: customerFormOpen,
    handleClose: closeCustomerForm,
    handleOpen: openCustomerForm,
  } = useOpenState();

  const [
    addCustomer,
    { loading: addCustomerLoading, error: addCustomerError },
  ] = useMutation<{ addCustomer: DefaultAddResponse }>(ADD_CUSTOMER_MUTATION, {
    refetchQueries: [GET_CUSTOMERS_QUERY],
    onError: (error) => {
      if (error.message.includes('dup key: { customerCode:')) {
        setApiErrorMessage('Customer code has to be unique !');
      } else {
        handleErrorResponse(error, 'Failed to create new customer');
      }
    },
    onCompleted: (data) => {
      if (data.addCustomer.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully added customer',
        });
        handleCustomerFormClose();
      }
    },
  });

  const [
    updateCustomer,
    { loading: updateCustomerLoading, error: updateCustomerError },
  ] = useMutation<{ updateCustomer: DefaultMutationResponse }>(
    UPDATE_CUSTOMER_MUTATION,
    {
      refetchQueries: [GET_CUSTOMERS_QUERY, GET_CUSTOMER_BY_ID_QUERY],
      awaitRefetchQueries: true,
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update customer'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.updateCustomer.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully updated customer',
          });
          handleCustomerFormClose();
        }
      },
    }
  );

  const handleAdd = () => {
    openCustomerForm();
  };
  const handleCustomerFormClose = () => {
    setEditCustomerId(null);
    closeCustomerForm();
    setApiErrorMessage('');
  };
  const handleEdit = (id: string) => {
    setEditCustomerId(id);
    openCustomerForm();
  };

  const handleCustomerFormSave = (customer: Customer) => {
    if (!customer.customerDescription) {
      customer.customerDescription = customer.name;
    }

    if (!customer.id) {
      addCustomer({
        variables: {
          params: omitDeep(customer, ['__typename']),
        },
      });
    } else {
      updateCustomer({
        variables: {
          params: omitDeep(customer, ['__typename']),
        },
      });
    }
  };

  const handlePaginationModelChange = (model: GridPaginationModel) => {
    const pageSize = model?.pageSize ?? paginationModel?.pageSize;
    const page = model?.page ?? paginationModel?.page;
    setPaginationModel({
      page,
      pageSize,
    });
  };

  const onFilterModelChange = (filterModel) => {
    refetch({ filters: getFilters(PAGES.CUSTOMERS_COLLECTION) });
  };

  const customerCodes = uniq<string>(
    data?.customers
      .filter((customer) => !!customer.customerCode)
      .map((customer) => customer.customerCode)
  );

  const handleSort = (args) => {
    let newSort;
    if (args.length) newSort = { [args[0].field]: args[0].sort };
    setSortState(newSort);
  };

  const returnValue = {
    columns,
    rows: data?.customers || [],
    customerCodes,
    handleAdd,
    handleEdit,
    handleSort,
    handlePaginationModelChange,
    onFilterModelChange,
    filterModel,
    editCustomerId,
    addCustomerLoading,
    addCustomerError,
    customerFormOpen,
    handleCustomerFormClose,
    handleCustomerFormSave,
    rowCount: data?.count,
    apiErrorMessage,
    loading: loading || addCustomerLoading,
  };

  if (loading || addCustomerLoading) {
    return {
      ...returnValue,
      paginationModel: { pageSize: 100, page: 0 },
      rowCount: 0,
      rows: [],
    };
  } else {
    return { ...returnValue, paginationModel };
  }
};

import { useQuery } from '@apollo/client';
import { GetAgiloftRolesResponse } from '@cdw-selline/common/types';
import { GET_AGILOFT_ROLES_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from './useApolloErrors';

export const useAgiloftRoles = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery<{
    getAgiloftRoles: GetAgiloftRolesResponse;
  }>(GET_AGILOFT_ROLES_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Agiloft Roles!'),
  });

  return {
    error,
    loading,
    data: data?.getAgiloftRoles.roles ?? [],
  };
};

export default useAgiloftRoles;

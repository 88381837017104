import { gql } from '@apollo/client';

export const GET_SYSTEM_PROPOSAL_BY_ID_QUERY = gql`
  query GetSystemProposalById($proposalId: ID!, $projectItemId: ID!, $projectId: ID!) {
    getSystemProposalById(proposalId: $proposalId, projectItemId: $projectItemId, projectId: $projectId) {
      content
      id
      name
    }
  }
`;
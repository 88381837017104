import { gql } from '@apollo/client';

export const ADD_TASK_NOTE_MUTATION = gql`
  mutation Mutation($taskId: ID!, $taskNote: TaskNoteInput!) {
    addEstimatorTaskNote(taskId: $taskId, note: $taskNote) {
      success
    }
  }
`;

/*
VARIABLES:
{
    projectId: "1234",
    taskNote: {
        note: "This is a note",
        noteType: "??",
        projectId: "1234",
        site: "Widget Co HQ",
        taskId: "9700"
    }
}
*/

import {
  cloneDeep,
  forOwn,
  isArray,
  isEmpty,
  isNaN,
  isNull,
  isObject,
  isUndefined,
  pull,
} from 'lodash';

export const pruneEmpty = (obj) => {
  return (function prune(current) {
    forOwn(current, function (value, key) {
      if (
        isUndefined(value) ||
        isNull(value) ||
        isNaN(value) ||
        (isObject(value) && isEmpty(prune(value)))
      ) {
        delete current[key];
      }
    });
    // remove any leftover undefined values from the delete
    // operation on an array
    // if (isArray(current)) pull(current, undefined);

    return current;
  })(cloneDeep(obj));
};

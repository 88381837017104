import { gql } from '@apollo/client';

export const UPDATE_CDW_RATE_MUTATION = gql`
  mutation updateCdwRateMutation($params: CdwRateInput) {
    updateCdwRate(params: $params) {
      success
    }
  }
`;

// VARIABLES
// {
//   ...AdminTaskInput
// }

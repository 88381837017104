import { useQuery } from '@apollo/client';
import { GetCdwRatesResponse } from '@cdw-selline/common/types';
import { GET_CDW_RATES_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from './useApolloErrors';

export interface UseCDWRates {
  filters?: Record<string, unknown>;
  offset?: number;
  limit?: number;
  sort?: Record<string, unknown>;
}

export const useCDWRates = ({ offset, limit, sort, filters }: UseCDWRates) => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getCdwRates: GetCdwRatesResponse;
  }>(GET_CDW_RATES_QUERY, {
    variables: {
      offset: offset ?? 0,
      limit: limit ?? 500,
      sort: sort ?? {},
      filters: filters ?? {},
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch CDW Rates!'),
  });

  return {
    data: data?.getCdwRates ?? { rates: [], count: 0 },
    error,
    loading,
    refetch,
  };
};

export default useCDWRates;

import { Task } from '@cdw-selline/common/types';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { labelStrings, titleStrings } from '@cdw-selline/ui/constants';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Button, DialogActions, Grid, Typography } from '@mui/material';
import React, { useState, Dispatch, SetStateAction } from 'react';

import { FormCheckbox, getActionButtons } from '../formHelperFunctions';
import DialogModal from '../dialog-modal/DialogModal';
import { useCopyProject } from '@cdw-selline/ui/hooks';
import { useParams } from 'react-router-dom';

export interface CopyProjectDialogProps {
  isOpen: boolean;
  handleClose: () => void;
}

export function CopyProjectDialog({
  isOpen,
  handleClose,
}: CopyProjectDialogProps) {
  const { id } = useParams<{ id: string }>();
  const [practiceFormData, setPracticeFormData] = useState({
    includeProjectItems: false,
  });
  const { handleCopyProject } = useCopyProject();
  const handleSaveClick = () => {
    handleCopyProject(id, practiceFormData.includeProjectItems);
    handleClose();
  };

  const handleFormClose = () => {
    handleClose();
  };
  return (
    <DialogModal
      {...{ isOpen, handleClose, maxWidth: 'sm' }}
      title={titleStrings.COPY_OPPORTUNITY}
      action={getActionButtons(handleFormClose, handleSaveClick)}
    >
      <Grid container sx={{ paddingTop: '15px' }}>
        <Typography>
          {'Are you sure you want to copy this opportunity?'}
          <br />
          {
            'If you wish to include opportunity items in the copy, please select the checkbox.'
          }
        </Typography>
        <FormCheckbox
          name="includeProjectItems"
          label="Copy Project Item"
          setFormData={setPracticeFormData}
          formData={practiceFormData}
        />
      </Grid>
    </DialogModal>
  );
}

export default CopyProjectDialog;

import { gql } from '@apollo/client';

export const GET_PROJECT_SCHEMA_QUERY = gql`
  query Query($getSchemaId: ID!) {
    getSchema(id: $getSchemaId) {
      schema {
        description
        properties {
          ... on ProjectProperties {
            cdwCustomerAccountNumber {
              type
              title
              readOnly
            }
            cewProvided {
              default
              title
              type
            }
            customerName {
              title
              type
            }
            customerSegment {
              enum
              title
              type
            }
            demoProvided {
              type
              title
              default
            }
            estClose {
              format
              title
              type
            }
            name {
              type
              title
            }
            projectOwner {
              type
              title
            }
            probability {
              enum
              title
              type
            }
            serviceLocation {
              title
              type
              enum
              enumNames
            }
          }
        }
        required
        title
        type
      }
      uiSchema: uiSchemaV2
    }
  }
`;

export default GET_PROJECT_SCHEMA_QUERY;

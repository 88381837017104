import { CiscoAnnuityLine } from '@cdw-selline/common/types';
import { formatCurrency } from '@cdw-selline/ui/helpers';
import { TableRow, TableCell } from '@mui/material';
import React from 'react';

export interface CiscoAnnuityQuoteLineTotalProps {
  ciscoAnnuityLine: CiscoAnnuityLine;
}

export function CiscoAnnuityQuoteLineTotal({
  ciscoAnnuityLine,
}: CiscoAnnuityQuoteLineTotalProps) {
  return (
    <TableRow sx={{fontWeight: 'bold', borderTop: '2px solid black',}}>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell>Total Service Fees</TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell align="right">
        {formatCurrency(ciscoAnnuityLine.customerPrice)}
      </TableCell>
      <TableCell></TableCell>
      <TableCell align="right">
        {formatCurrency(ciscoAnnuityLine.customerTotalPrice)}
      </TableCell>
      <TableCell align="right">
        {formatCurrency(ciscoAnnuityLine.customerTermTotal)}
      </TableCell>
      <TableCell align="right">
        {formatCurrency(ciscoAnnuityLine.cdwPrice)}
      </TableCell>
      <TableCell align="right">
        {formatCurrency(ciscoAnnuityLine.cdwTotalPrice)}
      </TableCell>
      <TableCell align="right">{formatCurrency(ciscoAnnuityLine.gp)}</TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
    </TableRow>
  );
}

import { gql } from '@apollo/client';

export const GET_PROPOSAL_TEMPLATE_BY_ID_QUERY = gql`
  query GetProposalTemplateById($id: ID!) {
    getProposalTemplateById(id: $id) {
      content
      createdBy
      createdAt
      id
      name
      version
      isSystem
      isDataCollectionIncluded
      dataCollection {
        id
        key
        name
      }
    }
  }
`;

import { useMutation } from '@apollo/client';
import {
  COPY_PROJECT_MUTATION,
  GET_PROJECT_ITEMS_QUERY,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { useNavigate } from 'react-router-dom';
import { useApolloErrors } from '..';

export const useCopyProject = () => {
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();

  const navigate = useNavigate();

  const [copyProject, { loading, error }] = useMutation(COPY_PROJECT_MUTATION, {
    refetchQueries: [GET_PROJECT_ITEMS_QUERY],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to copy Opportunity!'),
    onCompleted: (data) => {
      navigate(`/dashboard`);
      alertState.setAlert({
        type: ALERT_TYPE.SNACKBAR,
        severity: ALERT_SEVERITY.SUCCESS,
        message: 'Successfully Copied Opportunity',
      });
    },
  });

  const handleCopyProject = (projectId: string, includeProjectItems: boolean) => {
    copyProject({
      variables: {
        projectId,
        includeProjectItems
      },
    });
  };

  return { handleCopyProject, loading, error };
};

import { gql } from '@apollo/client';

export const GET_PROFILE_SCHEMA_QUERY = gql`
  query Query($getSchemaId: ID!) {
    getSchema(id: $getSchemaId) {
      schema {
        properties {
          ... on ProfileProperties {
            displayName {
              title
              type
            }
            email {
              title
              type
            }
            firstName {
              title
              type
            }
            lastName {
              title
              type
            }
          }
        }
        title
        type
        required
        description
      }
      uiSchema: uiSchemaV2
    }
  }
`;

export default GET_PROFILE_SCHEMA_QUERY;

// Variables
// {"getSchemaId": "user-profile"}

import { gql } from '@apollo/client';

export const GET_CISCO_ANNUITY_LINES_BY_PROJECT_ITEM_ID_QUERY = gql`
  query GetCiscoAnnuityLinesByProjectItemId($projectItemId: ID!) {
    getCiscoAnnuityLinesByProjectItemId(projectItemId: $projectItemId) {
      annuityAudio
      annuityAutoRenewTerm
      annuityBillingCycle
      annuityTerm
      audioTier
      category
      cdwDiscount
      creditJson
      customerDiscount
      description
      discount
      discountJson
      extendedNetPrice
      extentionDatetime0
      extentionDatetime1
      extentionNumber0
      extentionNumber1
      extentionValue0
      extentionValue1
      extentionValue2
      extentionValue3
      extentionValue4
      extentionValue5
      id
      lineId
      isDiscount
      leadTime
      line
      parent
      partNumber
      quantity
      quoteId
      serviceDuration
      timeBoundCreditAmount
      timeBoundCreditDuration
      timeBoundCreditFreeToCustomer
      timeBoundCreditFrom
      timeBoundCreditKeep
      timeBoundCreditName
      timeBoundCreditType
      unitListPrice
      unitNetPrice
      unitOfMeasure
      isRateTable
      rateTableName
      offerDetailsJson
      rateTableLink
    }
  }
`;

import { gql } from '@apollo/client';

export const GET_CUSTOM_TASKS_SCHEMA_FORM_QUERY = gql`
  query getSchemaQuery($getSchemaId: ID!) {
    getSchema(id: $getSchemaId) {
      uiSchema: uiSchemaV2
      schema {
        type
        required
        properties {
          ... on AdminTaskProperties {
            name {
              type
              title
            }
            overtime {
              title
              type
            }
            practice {
              type
              title
              enumNames
              enum
            }
            quantity {
              type
              title
            }
            primaryRole {
              title
              type
              enumNames
              enum
            }
            primaryPercent {
              title
              type
            }
            secondaryRole {
              title
              type
              enumNames
              enum
            }
            secondaryPercent {
              title
              type
            }
            type
          }
        }
        definitions {
          ... on AdminTaskDefinition {
            type {
              type
              required
              properties {
                type {
                  type
                  title
                  enum
                  default
                }
              }
              dependencies {
                type {
                  title
                  oneOf {
                    properties {
                      listNames {
                        type
                        title
                      }
                      listValues {
                        type
                        title
                      }
                      allowMarkup {
                        type
                        title
                      }
                      alwaysShowOnProposal {
                        title
                        type
                      }
                      cost {
                        title
                        type
                      }
                      costType {
                        enum
                        enumNames
                        title
                        type
                      }
                      defaultCost {
                        title
                        type
                      }
                      defaultGrossProfit {
                        title
                        type
                      }
                      grossProfit {
                        type
                        title
                      }
                      minGrossProfit {
                        type
                        title
                      }
                      hours {
                        type
                        title
                      }
                      noValue {
                        title
                        type
                      }
                      type {
                        enum
                        title
                      }
                      yesValue {
                        title
                        type
                      }
                    }
                    require
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

import { SelectChangeEvent } from '@mui/material';
import { useState } from 'react';

export const useSelect = () => {
  const [selectedString, setSelectedString] = useState<string>('');
  const handleSelectChangeEvent = (e: SelectChangeEvent) => {
    setSelectedString(e.target.value);
  };
  const handleSelectValueChange = (newValue: string) => {
    setSelectedString(newValue);
  };
  return { selectedString, handleSelectChangeEvent, handleSelectValueChange };
};

import { gql } from '@apollo/client';

export const GET_SOW_SECTIONS_QUERY = gql`
  query getSowSectionsQuery($limit: Int, $offset: Int, $filters: MongoFilter, $sort: MongoFilter) {
    getSowSections {
      count(filters: $filters)
      sowSections(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
        id
        name
        highLevel
        title
        scope
        version
        customerResponsibilities
        assumptions
        outOfScope
        help
        createdBy
        createdOn
        lastReviewedOn
        lastReviewedBy
        assumptionsGlobal
        outOfScopeGlobal
        helpGlobal
        customerResponsibilitiesGlobal
        outcomeUnit
        outcomeUnitDescription
      }
    }
  }
`;

import { gql } from '@apollo/client';

export const GET_CISCO_ANNUITY_ADMIN_QUOTE_BY_PROJECT_ITEM_ID_QUERY = gql`
  query GetCiscoAnnuityAdminQuoteByProjectItemId($projectItemId: ID!) {
    getCiscoAnnuityAdminQuoteByProjectItemId(projectItemId: $projectItemId) {
        annuityAudio
        annuityAutoRenewTerm
        annuityBillingCycle
        annuityTerm
        audioTier
        category
        cdwDiscount
        cdwPrice
        cdwTotalPrice
        creditJson
        customerDiscount
        customerPrice
        customerTotalPrice
        customerTermTotal
        description
        discount
        discountJson
        extendedNetPrice
        extentionDatetime0
        extentionDatetime1
        extentionNumber0
        extentionNumber1
        extentionValue0
        extentionValue1
        extentionValue2
        extentionValue3
        extentionValue4
        extentionValue5
        gp
        id
        lineId
        isDiscount
        leadTime
        line
        parent
        partNumber
        quantity
        quoteId
        serviceDuration
        timeBoundCreditAmount
        timeBoundCreditDuration
        timeBoundCreditFreeToCustomer
        timeBoundCreditFrom
        timeBoundCreditKeep
        timeBoundCreditName
        timeBoundCreditType
        unitListPrice
        unitNetPrice
        unitOfMeasure
        newBillingAmount
        billingAmountNetChange
        currentContractAmount
        newContractAmount
        contractAmountNetChange
        oldQuantity
        isRateTable
        rateTableName
        offerDetailsJson
        rateTableLink
        noGPImpact
        term
        start
        billingCycle
        chargeType
    }
  }
`;

import { useMutation } from '@apollo/client';
import { User } from '@cdw-selline/common/types';
import { UPDATE_USER_MUTATION } from '@cdw-selline/ui/queries';
import omitDeep from 'omit-deep-lodash';

import { useApolloErrors } from '..';

export const useUserUpdate = (completionCallback?: () => void) => {
  const { handleErrorResponse } = useApolloErrors();

  const [updateUser, { loading, error }] = useMutation(UPDATE_USER_MUTATION, {
    onError: (error) => handleErrorResponse(error, 'Failed to update User!'),
    onCompleted: completionCallback,
  });

  const handleUserUpdate = (user: Partial<User>) => {
    updateUser({
      variables: {
        params: {
          ...omitDeep(user, '__typename'),
        },
      },
    });
  };

  return { handleUserUpdate, loading, error };
};

import '@cdw-selline/ui/theme';
import { Skeleton } from '@cdw-selline/ui/components';
import { useOpportunities } from '@cdw-selline/ui/hooks';
import * as React from 'react';
import { Box, Stack } from '@mui/material';

/* eslint-disable-next-line */
export interface ActivityTabProps {}

export const ActivityTab = (props: ActivityTabProps) => {
  const { loading, error } = useOpportunities({ limit: 99999999999999 });

  if (error) return <>Error</>;

  if (loading) return <Skeleton loading />;

  return (
    <Box sx={{ maxWidth: '100%', marginLeft: '2em' }}>
      <Stack direction="column" spacing={2}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        ></Box>
        <Box>TODO: Add Activity Table!</Box>
      </Stack>
    </Box>
  );
};

export default ActivityTab;

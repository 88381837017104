import { DashboardTable } from '@cdw-selline/ui/components';
import { ROUTES } from '@cdw-selline/ui/constants';
import { getCurrentUser } from '@cdw-selline/ui/state';
import '@cdw-selline/ui/theme';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const PipelinesTab = () => {
  const currentUser = getCurrentUser();
  const navigate = useNavigate()

  const userEmail = currentUser ? currentUser.email : '';
  const localValue = JSON.parse(localStorage.getItem(`OpportunityView-toggle`));
  const [userFilter, setUserFilter] = useState(
    localValue ?? {
      $or: [
        { projectTeam: { $elemMatch: { email: userEmail } } },
        { projectOwner: userEmail },
      ],
    }
  );

  return (
    <Box
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      ></Box>
      <Box>
        <DashboardTable
          routeTo={(id: string, location: string) => {
            navigate({
              pathname: `/project/${id}`,
            }, { state: { from: location ?? ROUTES.HOME } });
          }}
          setUserFilter={setUserFilter}
          userFilter={userFilter}
          userState={Object.keys(userFilter).length === 0 ? true : false}
          userEmail={userEmail}
        />
      </Box>
    </Box>
  );
};

export default PipelinesTab;

import { Practice } from '@cdw-selline/common/types';
import { getCurrentUser } from '@cdw-selline/ui/state';
import { useTaskGroupsWithCategories } from '..';

export const useTaskGroupsByPractice = (
  practices: Practice[],
  filters = {},
  limit = 50,
  sort = {},
) => {
  const currentUser = getCurrentUser();
  const userEmail = currentUser ? currentUser.email : {};

  const { data, loading, error, refetch } = useTaskGroupsWithCategories({
    limit: limit,
    filters: {
      practice: { $in: practices.map((practice) => practice.id) },
      $or: [
        { taskGroupTeam: { $elemMatch: { email: userEmail } } },
        { owner: { $elemMatch: { email: userEmail } } },
        { coOwners: { $elemMatch: { email: userEmail } } },
        { testers: { $elemMatch: { email: userEmail } } },
        { published: { $eq: true } },
      ],
      ...filters,
    },
    sort: sort,
  });
  return { taskGroupsByPractice: data.taskGroups, loading, error, refetch };
};

import { useQuery } from '@apollo/client';
import { SowSection } from '@cdw-selline/common/types';
import {
    GET_SOW_SECTION_BY_ID_AND_VERSION_QUERY,
} from '@cdw-selline/ui/queries';

import { useApolloErrors } from './useApolloErrors';

export const useAdminSowSectionVersion = (id, version) => {
  const { handleErrorResponse } = useApolloErrors();
  const {
    loading,
    error,
    data,
  } = useQuery<{ getSowSectionByIdAndVersion: SowSection }>(GET_SOW_SECTION_BY_ID_AND_VERSION_QUERY, {
    fetchPolicy: 'no-cache',
    skip: !id || !version,
    variables: { id, version },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch sow section !'),
  });

  return {
    loading,
    error,
    sowSection: data?.getSowSectionByIdAndVersion ?? {},
  };
};
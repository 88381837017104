import axios, { AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';
import { getFirebaseApp } from './app-firebase';
import { getAuth, getIdToken } from 'firebase/auth';

let axiosSelline: AxiosInstance;
export function getAxiosInstance(): AxiosInstance {
  if (axiosSelline) return axiosSelline;

  const app = getFirebaseApp();
  const auth = getAuth(app);

  axiosSelline = axios.create({
    baseURL: '/api', // TODO : This needs to set form a global config
  });
  axiosRetry(axiosSelline, { retries: 3 });
  axiosSelline.interceptors.request.use(async (config) => {
    if (auth.currentUser) {
      const token = await getIdToken(auth.currentUser);
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  return axiosSelline;
}

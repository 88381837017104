import { useQuery } from '@apollo/client';
import { CiscoAnnuityCategory } from '@cdw-selline/common/types';
import { GET_CISCO_ANNUITY_CATEGORIES } from '@cdw-selline/ui/queries';

import { useApolloErrors } from '..';

export const useCiscoAnnuityCategories = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getCiscoAnnuityCategories: CiscoAnnuityCategory[];
  }>(GET_CISCO_ANNUITY_CATEGORIES, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    variables: { filters: {} },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Cisco Annuity Categories!'),
  });

  return {
    data: data?.getCiscoAnnuityCategories ?? [],
    refetch,
    loading,
    error,
  };
};

import { useMutation, useQuery } from '@apollo/client';
import {
  GetProjectsResponse,
  UpdateProjectResponse,
  GetOpportunityResponse,
} from '@cdw-selline/common/types';
import {
  GET_OPPORTUNITIES_QUERY,
  GET_PROJECT_QUERY,
  UPDATE_PROJECT_MUTATION,
  GET_PROJECTS_COUNT_QUERY,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';

import { useApolloErrors } from './useApolloErrors';

export const useOpportunities = (
  { limit = 10, offset = 0, filters = {}, sort = {} },
  notifyOnNetworkStatusChange = false
  ) => {
  const params = {
    limit,
    offset,
    filters,
    sort,
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  const alertState = useAlertsState();
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getOpportunities: GetOpportunityResponse;
  }>(GET_OPPORTUNITIES_QUERY, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    variables: params,
    notifyOnNetworkStatusChange,
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch opportunities!'),
  });

  const [
    updateProject,
    { loading: updateProjectLoading, error: updateProjectError },
  ] = useMutation<UpdateProjectResponse>(UPDATE_PROJECT_MUTATION, {
    refetchQueries: [GET_OPPORTUNITIES_QUERY, GET_PROJECT_QUERY],
    awaitRefetchQueries: true,
    onError: (error) =>
      handleErrorResponse(
        error,
        `Failed to update project: ${error.message}`,
        ALERT_TYPE.MODAL
      ),
    onCompleted: (data) => {
      if (data.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully Updated Project',
        });
      }
    },
  });

  return {
    data: data?.getOpportunities.opportunities ?? null,
    count: data?.getOpportunities.count ?? 0,
    error,
    loading,
    refetch,
    project: { updateProject, updateProjectError, updateProjectLoading },
  };
};

export const useActiveProjects = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getProjects: GetProjectsResponse;
  }>(GET_PROJECTS_COUNT_QUERY, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    variables: {},
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch active projects!'),
  });

  return {
    data: data?.getProjects?.count ?? 0,
    error,
    loading,
    refetch,
  };
};

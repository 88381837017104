import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { ServiceSummaryResponse } from '@cdw-selline/common/types';
import { GET_SERVICE_SUMMARY_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from '../..';

export const useServiceSummary = () => {
  const { handleErrorResponse } = useApolloErrors();
  const { projectItemId } = useParams<{ projectItemId: string }>();
  const { loading, error, data } = useQuery<{
    getServiceSummaryByEstimatorId: ServiceSummaryResponse;
  }>(GET_SERVICE_SUMMARY_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: { id: projectItemId },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Service Summary!'),
  });

  return {
    serviceSummary: data?.getServiceSummaryByEstimatorId ?? {
      roles: [],
      pmPercent: 0,
      riskMitigation: 0,
    },
    error,
    loading,
  };
};

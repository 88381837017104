import { Site } from '@cdw-selline/common/types';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useAddSiteInfo } from './useAddSiteInfo';

export const useNewSiteDialog = (projectItemId?: string) => {
  const {
    handleAddSite,
    loading: addSiteLoading,
    error: addSiteError,
  } = useAddSiteInfo();

  const blankSite = {
    name: '',
    address: '',
    floor: '',
  };

  const [newSite, setNewSite] = useState<Site>(blankSite);

  const handleSiteNameChange = (e: ChangeEvent<HTMLTextAreaElement>) =>
    setNewSite({ ...newSite, name: e.target.value });
  const handleaddressChange = (e: ChangeEvent<HTMLTextAreaElement>) =>
    setNewSite({ ...newSite, address: e.target.value });
  const handlefloorChange = (e: ChangeEvent<HTMLTextAreaElement>) =>
    setNewSite({ ...newSite, floor: e.target.value });

  const handleSiteSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleAddSite(projectItemId, newSite);
    setNewSite(blankSite);
  };

  return {
    newSite,
    handleSiteNameChange,
    handleaddressChange,
    handlefloorChange,
    handleSiteSubmit,
    addSiteLoading,
    addSiteError,
  };
};

import { gql } from '@apollo/client';

export const GET_PROJECT_QUERY = gql`
  query getProject($getProjectId: ID!) {
    getProject(id: $getProjectId) {
      area
      cdwCustomerAccountNumber
      cewProvided
      customerName
      customerSegment
      closedStatus
      closedReason
      demoProvided
      estClose
      id
      lastModified
      name
      projectOwner
      projectOwnerDisplayName
      probability
      serviceLocation
      status
      updatedAt
      projectTeam {
        email
        type
        name
      }
      closedOn
      customerId
      onedriveLink
      serviceTotal
      productTotal
      subscriptionTotal
      description
      managedServiceTotal
      salesForceId
      salesForceUrl
    }
  }
`;

export default GET_PROJECT_QUERY;

// Variables
// {"getProjectId": "uuid"}

import { gql } from '@apollo/client';

export const ADD_PROJECT_PROPOSAL_MUTATION = gql`
  mutation Mutation($params: AddProjectProposalInput!) {
    addProjectProposal(params: $params) {
      data
      success
      error
    }
  }
`;

/*
VARIABLES:
{
  "params": {
    "content": "<p>HI</p>",
    "name": "test proposal",
    "projectId": "1234"
  }
}
*/

/*
RETURNS:
{
  "data": {
    "addProjectProposal": {
      "id": "61c36a22727e20b961ebd407",
      "success": true
    }
  }
}
*/

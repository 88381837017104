/* eslint-disable @nx/enforce-module-boundaries */
import { useItemSummary } from '@cdw-selline/ui/hooks';
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { formatCurrency } from '@cdw-selline/ui/helpers';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InvestmentSummaryProps {}

export const InvestmentSummary: React.FC<InvestmentSummaryProps> = (props) => {
  const { itemSummary, error, loading } = useItemSummary();

  const hwsw = itemSummary['hwsw'];
  const servicesTotal = itemSummary['servicesTotal'];
  const tAndETotal = itemSummary['tAndETotal'];
  const managedServicesName = itemSummary['managedServicesName'];
  const managedServicesTotal = itemSummary['managedServicesTotal'];
  const total = itemSummary['total'];
  const theme = useTheme();

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3} data-testid="investment-summary-grid">
        <Grid item xs={12}>
          <Card {...props}>
            <CardHeader
              title="Investment Summary"
              sx={{
                backgroundColor: theme.palette.primary.dark,
                padding: '5px 15px',
              }}
              titleTypographyProps={{
                variant: 'h6',
                color: theme.palette.common.white,
              }}
            />
            <Divider />
            <Box sx={{ minWidth: 400 }}>
                <Table size="small">
                  <TableBody>
                    {error && (
                      <Typography color="error" variant="h6">
                        {error.message}
                      </Typography>
                    )}
                    {loading && (
                      <TableRow>
                        <TableCell>
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}
                    {!error && !loading && (
                      <>
                        {hwsw > 0 && (
                          <TableRow hover key="hwsw">
                            <TableCell>Hardware/Software</TableCell>
                            <TableCell align="right">
                              {formatCurrency(hwsw)}
                            </TableCell>
                          </TableRow>
                        )}
                        {/* TODO: Add in Managed Services row */}
                        <TableRow hover key="professionalServices">
                          <TableCell>Estimated Professional Services</TableCell>
                          <TableCell align="right">
                            {formatCurrency(servicesTotal)}
                          </TableCell>
                        </TableRow>
                        <TableRow hover key="tAndE">
                          <TableCell>Estimated Travel & Expense</TableCell>
                          <TableCell align="right">
                            {formatCurrency(tAndETotal)}
                          </TableCell>
                        </TableRow>
                        {managedServicesName && (
                          <TableRow hover key="managedServices">
                            <TableCell>{managedServicesName}</TableCell>
                            <TableCell align="right">
                              {formatCurrency(managedServicesTotal)}
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow hover key="total">
                          <TableCell>Investment Total</TableCell>
                          <TableCell align="right">
                            {formatCurrency(total)}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InvestmentSummary;

import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import { orange } from '@mui/material/colors';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import * as React from 'react';

export const TasksProgress = (props) => (
  <Card sx={{ height: '100%' }} {...props}>
    <CardContent>
      <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
        <Grid item>
          <Typography color="textSecondary" gutterBottom variant="button">
            TOTAL CISCO ANNUITY
          </Typography>
          <Typography color="textPrimary" variant="h6">
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(0)}
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: orange[600],
              height: 46,
              width: 46,
            }}
          >
            <AttachMoneyIcon />
          </Avatar>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default TasksProgress;

import { CiscoAnnuityLine } from '@cdw-selline/common/types';
import { useCiscoAnnuityLineUpdate } from './useCiscoAnnuityLineUpdate';

export const useCiscoAnnuityLineState = (
  ciscoAnnuityLine?: CiscoAnnuityLine
) => {
  const { handleCiscoAnnuityLineUpdate, handleCiscoAnnuityLineRemove } = useCiscoAnnuityLineUpdate();

  const handleChange = (e) => {
    const targetProperty = e.target.name;
    let newValue;

    if (e.target.type === 'checkbox') {
      if (e.target.checked) {
        newValue = true;
      } else {
        newValue = false;
      }
    }

    if(e.target.type !== 'checkbox') {
      newValue = e.target.type === 'number' ?  Number(e.target.value) :  e.target.value;
    }
    
    let updatedCiscoAnnuityLine = {
      id: ciscoAnnuityLine?.id,
      quoteId: ciscoAnnuityLine?.quoteId,
      [targetProperty]: newValue,
    };

    if(targetProperty === 'includeInQuote') {
      updatedCiscoAnnuityLine['line'] = ciscoAnnuityLine?.line;
    }

    if (ciscoAnnuityLine?.category === 'cdw-credit') {
      updatedCiscoAnnuityLine['creditAmount'] = newValue;
      updatedCiscoAnnuityLine = {...ciscoAnnuityLine, ...updatedCiscoAnnuityLine};
    }

    handleCiscoAnnuityLineUpdate(updatedCiscoAnnuityLine);
  }

  const handleRemoveCdwCredit = (id: string) => {
    handleCiscoAnnuityLineRemove(id);
  }

  return {
    handleChange,
    handleRemoveCdwCredit,
  };
};
